import React, { useState, useEffect } from 'react'
import {connect} from "react-redux";
import { bindActionCreators } from "redux";
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { 
    Grid, IconButton, Snackbar, Dialog, DialogActions,
    DialogTitle, DialogContentText, DialogContent, Button 
} from '@mui/material'
import { tss } from "tss-react";
import theme from '../../_theme'
  
import CloseImgIcon from '../../Assets/close.svg';
import BasePageContainer from '../../_common/basePageContainer'
import BasePageToolbar from '../../_common/basePageToolbar'
import TableComponent from "../table/index"
import CreateEditViewProduct from "./createEditProduct"
import { 
  fetchProductDetails, fetchBrandDetails,
  fetchMasterProductCategories, 
  clearProductRes, clearBrandDetail,
  createProduct, modifyProduct
} from "./actions";
import {uploadDocument, resetUploadDocumentResp} from "../../sharedApi/actions";
import { floatRegex, nameRegExp, descrRegex} from "../../shared/utility";
import {fetchCountryDetails} from '../../manageScheme/actions/index'

const headCells = [
  { id: 'name', type: 'text', numeric: false, disablePadding: false, label: 'Product Name' },
  { id: 'productCode', type: 'text', numeric: true, disablePadding: false, label: 'Product Code' },
  { id: 'countryName', type: 'text', numeric: true, disablePadding: false, label: 'Country Name' },
  { id: 'brandName', type: 'text', numeric: false, disablePadding: false, label: 'Brand Name' },
  { id: 'productCategoryName', type: 'text', numeric: true, disablePadding: false, label: 'Product Category' },
  { id: 'status', type: 'status', numeric: true, disablePadding: false, label: 'Status' },
  { id: 'createdOn', type: 'createdOn', numeric: true, disablePadding: false, label: 'Created On' },
  { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];

const ProductObj = {
  "id": "",
  "name": "",
  "description": "",
  "brandName": "",
  "brandId": "",
  "productCategoryId": "",
  "productCategoryName": "",
  "productSubCategoryId": "",
  "productSubCategoryName": "",
  "isoCountryCodeNum": "",
  "countryName": "",
  "level1": "",
  "level2": "",
  "level3": "",
  "status": "ACTIVE",
  "price": "",
  "skuCode": "",
  "lowAmount": "",
  "highAmount": "",
  "createdBy": "",
  "images": [
      {
          "screenType": "LIST_LOGO",
          "screenTypeCode": "LIST_LOGO",
          "resourceId": "",
          "baseURL": ""
      },
      {
          "screenType": "HOME_LOGO",
          "screenTypeCode": "HOME_LOGO",
          "resourceId": "",
          "baseURL": ""
      },
      {
          "screenType": "SUMMARY_LOGO",
          "screenTypeCode": "SUMMARY_LOGO",
          "resourceId": "",
          "baseURL": ""
      }
  ],
  amountRangeToBeCompared: false,
  isSerialNumberRequired: false,
}

const ImageDataArr = [
  {
  "screenType": "SCREEN_TYPE_PRODUCT_LIST",
  "screenTypeCode": "200",
  "resourceId": "",
  "originalUrl": "",
  "baseURL": "",
  "file": null,
  "actionKey": "",
  },
  {
    "screenType": "SCREEN_TYPE_PRODUCT_DETAIL",
    "screenTypeCode": "300",
    "resourceId": "",
    "originalUrl": "",
    "baseURL": "",
    "file": null,
    "actionKey": "",
  },
  {
    "screenType": "SCREEN_TYPE_PRODUCT_REVIEW",
    "screenTypeCode": "600",
    "resourceId": "",
    "originalUrl": "",
    "baseURL": "",
    "file": null,
    "actionKey": "",
  }
]

const filterCells = [
  { id: 'isoCountryCodeNum', type: 'select', label: 'Country' },
  { id: 'brandId', type: 'select', label: 'Brand name' },
  // { id: 'productCategoryName', type: 'select', label: 'Category Name' },
  // { id: 'name', type: 'text', label: 'Product Name' },
  { id: 'productCode', type: 'text', label: 'Product Code' },
  { id: 'status', type: 'select', label: 'Status' },
];

const filterobj = {
  // name: "",
  pageNo: 1,
  pageSize: 10,
	brandName: "",
  sortBy:"createdOn",
  sortDirection:"DESC",
  status: "",
  productCode: "",
  isoCountryCodeNum: "",
  brandId: ""
	// productCategoryName: "",
}

const sortArr = [
  {key:"createdOn", label: "Create Date"},
  {key:"countryName", label: "Country Name"},
  {key:"productCategoryName", label: "Category Name"},
  {key:"brandName", label: "Brand Name"},
  {key:"name", label: "Product Name"}
]

const Product = (props) => {
  const {classes} = useStyles();
  const { 
    productList, countryList, brandList, categoryList, totalRecord,
    createProductRes, productLoader, uploadDocumentResp
  } = props;
  const [imageAction, setImageActionType] = useState("Upload")
  const [currentForm, setCurrentForm] = useState("list");
  const [clientRow, setClientRow] = useState(null);
  const [productState, setProductState] = useState(ProductObj)
  const [snackbarMsg, setSnackBarMsg] = useState(null);
  const [openSnackbar, SetOpenSnackbar] = useState(false);
  const [openPopover, setopenPopover] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [imageData, setImageData] = useState([])
  const [openDeletePopover, setOpenDeletePopover] = useState(false);
  const [screenIndex, setScreenIndex] = useState(-1);
  const [docsSnackbarMsg, setDocsSnackBarMsg] = useState(null);
  const [openDocsSnackbar, SetOpenDocsSnackbar] = useState(false);
  const currentPage = localStorage.getItem('currentPage')
  const [compInitaited, setCompInitiated] = useState(false);
  const [pageNo, setPage] = useState(1)
  const [sortName, setsortName] = useState('createdOn')
  const [sortDir, setsortDir] = useState('DESC');
  const [filterState, setFilterState] = useState(filterobj)
  
  // const userid = localStorage.getItem("userid")
  let permissions = localStorage.getItem("permissions")
  permissions = permissions && permissions !== null ? JSON.parse(permissions) : []
  const userName = localStorage.getItem("userName")

  const createDisableToken = currentForm !== "view" && productState && productState.countryName !== "" && productState.countryName !== null && 
    productState.brandName !== "" && productState.brandName !== null && productState.name !== null && productState.name !== "" && nameRegExp.test(productState.name) && 
    productState.description !== null && productState.description !== "" && descrRegex.test(productState.description) && 
    productState.productCategoryName !== "" && productState.productCategoryName !== null &&
    productState.productSubCategoryName !== "" && productState.productSubCategoryName !== null &&
    productState.price !== "" && productState.price !== null && parseFloat(productState.price) > 0 && 
      floatRegex.test(productState.price) && parseFloat(productState.price) >= parseFloat(productState.lowAmount) &&
      parseFloat(productState.price) <= parseFloat(productState.highAmount) &&
    productState.lowAmount !== "" && productState.lowAmount !== null && parseFloat(productState.lowAmount) > 0 && 
      floatRegex.test(productState.lowAmount) && parseFloat(productState.lowAmount) <= parseFloat(productState.highAmount) &&
    productState.highAmount !== "" && productState.highAmount !== null && parseFloat(productState.highAmount) > 0 &&
      floatRegex.test(productState.highAmount) && parseFloat(productState.lowAmount) <= parseFloat(productState.highAmount)

  const productArray = productList && productList !== null ? 
    productList.map(obj => {
      let productObj = {
          ...obj,
          searchColumn: headCells.map((cell, index) => 
            cell.id === "action" ? "" : obj[cell.id] && obj[cell.id]
            ).join(" ")
      }
      return productObj
  }) : []

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  });

  const onChangeView = async (inpval, row) => {
    
      setCurrentForm(inpval);
      //setImageData([...ImageDataArr]);
      if(inpval === "view") {
        setImageData([])
        setClientRow(row)
        initImageStateForEdit(row);

      } else if(inpval === "edit") {
          setImageData([])
          setClientRow(row)
          await props.clearBrandDetail()
          await props.fetchCountryDetails({})
          await props.fetchProductDetails({ id: row.id, "pageNo": 1, "pageSize":10, "sortBy": "createdOn", "sortDirection": "DESC"});
          await props.fetchMasterProductCategories();
          await props.fetchBrandDetails({"isoCountryCodeNum": row.isoCountryCodeNum, status: "ACTIVE",
            "pageNo": 1, "pageSize":1000, "sortBy": "name", "sortDirection": "ASC"})
          props.clearProductRes(null)

      } else if(inpval === "create") {
          props.clearBrandDetail()
          setProductState(ProductObj)
          await props.fetchCountryDetails({})
          await props.fetchMasterProductCategories();
          props.clearProductRes(null)
          
      } else {
          await setImageData([]);
          await setProductState(ProductObj)
          await props.fetchProductDetails({...filterState,
            "pageNo": 1, "sortBy": sortName, "sortDirection": sortDir});
          await props.clearProductRes(null)
      }
  }

  const initImageStateForEdit = (row) => {
    let imageArr = [];
    const imageObjArr = row && row.images && row.images.length > 0 ? 
      row.images.filter((obj) => {
        if(obj.screenTypeCode && obj.screenTypeCode !== "") {
          imageArr.push(obj.screenTypeCode)
        } 
        return obj.screenTypeCode && obj.screenTypeCode !== ""
      }) : []
    let tempImageData = []

    const ind200 = imageArr.indexOf("200");

    if(ind200 !== -1) {
      const tempObj0 = {
        "screenType": "SCREEN_TYPE_PRODUCT_LIST",
        "screenTypeCode": "200",
        "resourceId": imageObjArr[ind200].resourceId && imageObjArr[ind200].resourceId !== "" ? imageObjArr[ind200].resourceId : "",
        "originalUrl": imageObjArr[ind200].baseURL && imageObjArr[ind200].baseURL !== "" ? imageObjArr[ind200].baseURL : "",
        "baseURL": imageObjArr[ind200].baseURL && imageObjArr[ind200].baseURL !== "" ? imageObjArr[ind200].baseURL : "",
        "file": null,
        "actionKey": "",
      }
      tempImageData.push(tempObj0)
    } else {
      const tempObj0 = {
        "screenType": "SCREEN_TYPE_PRODUCT_LIST",
        "screenTypeCode": "200",
        "resourceId": "",
        "originalUrl": "",
        "baseURL": "",
        "file": null,
        "actionKey": "",
      }
      tempImageData.push(tempObj0)
    }

    const ind300 = imageArr.indexOf("300");
    
    if(ind300 !== -1) {
      const tempObj = {
        "screenType": "SCREEN_TYPE_PRODUCT_DETAIL",
        "screenTypeCode": "300",
        "resourceId": imageObjArr[ind300].resourceId && imageObjArr[ind300].resourceId !== "" ? imageObjArr[ind300].resourceId : "",
        "originalUrl": imageObjArr[ind300].baseURL && imageObjArr[ind300].baseURL !== "" ? imageObjArr[ind300].baseURL : "",
        "baseURL": imageObjArr[ind300].baseURL && imageObjArr[ind300].baseURL !== "" ? imageObjArr[ind300].baseURL : "",
        "file": null,
        "actionKey": "",
      }
      tempImageData.push(tempObj)
    } else {
      const tempObj = {
        "screenType": "SCREEN_TYPE_PRODUCT_DETAIL",
        "screenTypeCode": "300",
        "resourceId": "",
        "originalUrl": "",
        "baseURL": "",
        "file": null,
        "actionKey": "",
      }
      tempImageData.push(tempObj)
    }

    const ind600 = imageArr.indexOf("600");
    
    if(ind600 !== -1) {
      const tempObj = {
        "screenType": "SCREEN_TYPE_PRODUCT_REVIEW",
        "screenTypeCode": "600",
        "resourceId": imageObjArr[ind600].resourceId && imageObjArr[ind600].resourceId !== "" ? imageObjArr[ind600].resourceId : "",
        "originalUrl": imageObjArr[ind600].baseURL && imageObjArr[ind600].baseURL !== "" ? imageObjArr[ind600].baseURL : "",
        "baseURL": imageObjArr[ind600].baseURL && imageObjArr[ind600].baseURL !== "" ? imageObjArr[ind600].baseURL : "",
        "file": null,
        "actionKey": "",
      }
      tempImageData.push(tempObj)
    } else {
      const tempObj = {
        "screenType": "SCREEN_TYPE_PRODUCT_REVIEW",
        "screenTypeCode": "600",
        "resourceId": "",
        "originalUrl": "",
        "baseURL": "",
        "file": null,
        "actionKey": "",
      }
      tempImageData.push(tempObj)
    }
    
    setImageData([...tempImageData]);
  }
  
  useEffect(() => {
    if(currentPage && currentPage !== null && currentPage !== undefined && currentPage === "/product" && compInitaited){
      setCurrentForm('list')
      setPage(1);
      setsortDir("DESC");
      setsortName("createdOn")
      setFilterState({...filterobj})
      props.fetchProductDetails({ "pageNo": 1, "pageSize":10, "sortBy": sortName, "sortDirection": sortDir});
      localStorage.removeItem('currentPage')
    }
  }, [currentPage])

  useEffect(() => {
    const handleInitialCall = async () => {
      await setCompInitiated(true);
      await props.fetchProductDetails({...filterState, "pageNo": pageNo, "sortBy": sortName, "sortDirection": sortDir});
      await localStorage.removeItem('currentPage')
    }
    handleInitialCall();
    return () => {
      props.clearBrandDetail()
    }
  }, []);

  useEffect(() => {
    if(createProductRes && createProductRes !== null && createProductRes.st) {
        if(currentForm === "create" ) {
                setopenPopover(true)
        } else {
            SetOpenSnackbar(true)
            setSnackBarMsg(`Product Modified Successfully!`)
            onChangeView("list")
        }
        //reset user response
    } else if(createProductRes && createProductRes !== null && !createProductRes.st) {
      if(createProductRes && createProductRes.status && (createProductRes.status).toString() === "500"){
        setSnackBarMsg(createProductRes.error && createProductRes.error)

      } else {
        setSnackBarMsg(createProductRes.msg && createProductRes.msg)
      }
      SetOpenSnackbar(true)
      onChangeView("list")
        //reset user response
    }
  }, [createProductRes])

  useEffect(() => {
    if(uploadDocumentResp && uploadDocumentResp !== null && uploadDocumentResp.st) {
      SetOpenDocsSnackbar(true)
      setDocsSnackBarMsg(imageAction === "Upload" ? "Image Uploaded Successfully!" :
      "Image Removed Successfully!");
      
      //reset user response
      props.resetUploadDocumentResp(null)
    } else if(createProductRes && createProductRes !== null && !createProductRes.st) {
      SetOpenDocsSnackbar(true)
      setDocsSnackBarMsg(uploadDocumentResp.msg && uploadDocumentResp.msg)
        
      //reset user response
      props.resetUploadDocumentResp(null)
    } else if(createProductRes && createProductRes !== null && createProductRes.status) {
      SetOpenDocsSnackbar(true)
      setDocsSnackBarMsg(createProductRes.error && createProductRes.error)
        
      //reset user response
      props.resetUploadDocumentResp(null)
    } 
    clientRow && clientRow.id && props.fetchProductDetails({ 
      id: clientRow.id, "pageNo": 1, "pageSize":10, "sortBy": "createdOn", "sortDirection": "DESC"});
  }, [uploadDocumentResp])

  useEffect(() => {
    const prodListfetchCall = async () => {
      await setProductState(productList[0])
      await initImageStateForEdit(productList[0]);
    }
    prodListfetchCall();
  }, [productList])

  const handleInputChange = (key, value) => {
    let updatedProductState = {...productState};
    if(key === "country") {
  
      updatedProductState = {
        ...updatedProductState,
        countryName: value[0],
        isoCountryCodeNum: value[1],
        brandName: "",
        brandId: ""
      }
      props.fetchBrandDetails({"isoCountryCodeNum": value[1], status: "ACTIVE",
        "pageNo": 1, "pageSize":1000, "sortBy": "name", "sortDirection": "ASC"})
    } else if (key === "brandName") {
        updatedProductState = {
          ...updatedProductState,
          brandName: value[0],
          brandId: value[1]
        }
    } else if (key === "productCategory") {

      updatedProductState = {
        ...updatedProductState,
        productCategoryName: value[0],
        productCategoryId: value[1],
        productSubCategoryId: "",
        productSubCategoryName: "",
      }
      
    } else if (key === "productSubCategory") {
      updatedProductState = {
          ...updatedProductState,
          productSubCategoryName: value[0],
          productSubCategoryId: value[1]
        }
    } else {
      updatedProductState = {
          ...updatedProductState,
          [key]: value,
        }
    }

    setProductState(updatedProductState)
  }

  const handleProductCreateUpdate = () => {
    let req = {
        ...productState,
        "price": productState.price !== "" ? 
          parseFloat(parseFloat(productState.price).toFixed(3)) : "",
        "lowAmount": productState.lowAmount !== "" ? 
          parseFloat(parseFloat(productState.lowAmount).toFixed(3)) : "",
        "highAmount": productState.highAmount !== "" ? 
          parseFloat(parseFloat(productState.highAmount).toFixed(3)) : "",
        "createdBy": currentForm === "create" ? userName : null,
        "modifiedBy": currentForm === "edit" ? userName : null,
    }

    if(currentForm === "create") {
      props.createProduct(req)
    } else {
      props.modifyProduct(req)
    }
  }

  const handleSnackBarAction = () => {
    SetOpenSnackbar(false)
    setSnackBarMsg(null)
    props.clearProductRes(null)
  }

  const handleDialogAction = async (type) => {
    setopenPopover(false)
    setProductState(ProductObj)
    if(type === "exit" ){
      onChangeView('list')
    } else {
      await onChangeView('create')
    }
  }

  const handleSetFilterData = (filterArray, searchValue) => {
    setFilterData(filterArray)
    setSearchValue(searchValue)
  }

  const imageHandler = (e, key) => {
    
    if(e.target.files && e.target.files[0]) {
      let file = e.target.files[0]
      setImageActionType("Upload");
      if(file.size > 2097152) {
        window.alert("Image File Size limit is 2MB!")
        let tempElement = document.getElementById(key !== "SCREEN_TYPE_PRODUCT_LIST" ? 
          key === "SCREEN_TYPE_PRODUCT_DETAIL" ? `input1` : `input2` : "input");
        if( tempElement && tempElement !== null ) {
           tempElement.value = null;
        }

      } else {
        let img = new Image()
        img.src = URL.createObjectURL(file)
        img.onload = function () {
          const DynWidth = key !== "SCREEN_TYPE_PRODUCT_LIST" ? 
            key === "SCREEN_TYPE_PRODUCT_DETAIL" ? 528 : 400 : 441;
          const DynHeight = key !== "SCREEN_TYPE_PRODUCT_LIST" ? 
            key === "SCREEN_TYPE_PRODUCT_DETAIL" ? 762 : 330 : 500;

          if(img.width !== DynWidth || img.height !== DynHeight) {
            window.alert(`Image Resolution should be ${DynHeight} X ${DynWidth} Pixel.`)
            let tempElement = document.getElementById(key !== "SCREEN_TYPE_PRODUCT_LIST" ? 
              key === "SCREEN_TYPE_PRODUCT_DETAIL" ? `input1` : `input2` : "input");
            tempElement.value = null;

          } else {
            const imageUrl = URL.createObjectURL(file)
            
            if(key === "SCREEN_TYPE_PRODUCT_LIST") {
              
              let tempData = imageData && imageData !== null && imageData.length > 0 ?
                imageData.map((obj, index) => {
                  if(index === 0) {
                    return {
                      ...obj,
                      "baseURL": imageUrl,
                      "file": file,
                      "actionKey": "UPLOAD",
                    }
                  } else 
                  return obj
                }) : ImageDataArr
                setImageData(tempData)
            } else if(key === "SCREEN_TYPE_PRODUCT_DETAIL") { 
      
              let tempData = imageData && imageData !== null && imageData.length > 0 ?
                imageData.map((obj, index) => {
                  if(index === 1) {
                    return {
                      ...obj,
                      "baseURL": imageUrl,
                      "file": file,
                      "actionKey": "UPLOAD",
                    }
                  } else 
                  return obj
                }) : ImageDataArr
                setImageData(tempData)
            } else if(key === "SCREEN_TYPE_PRODUCT_REVIEW") {
              
              let tempData = imageData && imageData !== null && imageData.length > 0 ?
                imageData.map((obj, index) => {
                  if(index === 2) {
                    return {
                      ...obj,
                      "baseURL": imageUrl,
                      "file": file,
                      "actionKey": "UPLOAD",
                    }
                  } else 
                  return obj
                }) : ImageDataArr
                setImageData(tempData)
            }
          }
        }
      }
    }
  }

  const handleUploadImage = async (key, type, indx) => {
    let formData = new FormData()

      if ( type === "UPLOAD" && imageData[indx].resourceId === "") {
        await formData.append('productId', productState.id)
        await formData.append('operation', "ADD")
        await formData.append('uuid', "")
        await formData.append('documentSubCategory', imageData[indx].screenType)
        await formData.append('file', imageData[indx].file)
        
        await props.uploadDocument(formData, imageData[indx].screenType)

        let tempData = imageData && imageData !== null && imageData.length > 0 ?
          imageData.map((obj, index) => {
            if(index === indx) {
              return {
                ...obj,
                "actionKey": "",
              }
            } else 
            return obj
          }) : ImageDataArr
        setImageData(tempData)
      } else if ( type === "UPLOAD" && imageData[indx].resourceId !== "" ) {
        await formData.append('productId', productState.id)
        await formData.append('operation', "EDIT")
        await formData.append('uuid', imageData[indx].resourceId)
        await formData.append('documentSubCategory', imageData[indx].screenType)
        await formData.append('file', imageData[indx].file)

        await props.uploadDocument(formData, imageData[indx].screenType)
        
        let tempData = imageData && imageData !== null && imageData.length > 0 ?
          imageData.map((obj, index) => {
            if(index === indx) {
              return {
                ...obj,
                "actionKey": "",
              }
            } else 
            return obj
          }) : ImageDataArr
        setImageData(tempData)
      } else if( type === "CANCEL" ) {

        let tempElement = document.getElementById(indx !== 0 ? `input${indx}` : "input");
        if( tempElement && tempElement !== null ) {
           tempElement.value = null;
        }
        
        let tempData = imageData && imageData !== null && imageData.length > 0 ?
          imageData.map((obj, index) => {
            if(index === indx) {
              return {
                ...obj,
                "resourceId": "",
                "originalUrl": "",
                "baseURL": "",
                "file": null,
                "actionKey": "",
              }
            } else {
              return obj
            }
          }) : ImageDataArr
        setImageData(tempData)
      } else if( type === "DELETE_ACTION" ) {
        let tempElement = document.getElementById(indx !== 0 ? `input${indx}` : "input");
        if( tempElement && tempElement !== null ) {
           tempElement.value = null;
        }
        setOpenDeletePopover(true);
        setScreenIndex(indx)
      } 
  }

  const handleDeleteDialogAction = async (type) => {
    setOpenDeletePopover(false)
    if(type === "Delete" ){
      setImageActionType("Delete");
      let formData = new FormData()
      await formData.append('productId', productState.id)
      await formData.append('operation', "DELETE")
      await formData.append('uuid', imageData[screenIndex].resourceId)
      await formData.append('documentSubCategory', imageData[screenIndex].screenType)
      await formData.append('file', imageData[screenIndex].file)

      await props.uploadDocument(formData, imageData[screenIndex].screenType)

      let tempData = imageData && imageData !== null && imageData.length > 0 ?
        imageData.map((obj, index) => {
          if(index === screenIndex) {
            return {
              "resourceId": "",
              "originalUrl": "",
              "baseURL": "",
              "file": null,
              "actionKey": "",
            }
          } else 
          return obj
        }) : ImageDataArr

      setImageData(tempData)
      setScreenIndex(-1);
    } else {
      setScreenIndex(-1);
    }
  }

  const handleFilterStateChange = (key, value) => {
    let tempFilter = {
        ...filterState,
        [key]: value
    }
    if(key === "isoCountryCodeNum") {
      tempFilter = {
        ...tempFilter,
        name: "",
        brandName: ""
      }
    }
    setFilterState(tempFilter);
  }

  const resetFilter = () => {
    setFilterState(filterobj);
    setPage(1);
    setsortDir("DESC");
    setsortName("createdOn")
    props.fetchProductDetails({
      pageNo: 1,
      pageSize: 10,
      sortBy:"createdOn",
      sortDirection:"DESC"
    })
    props.fetchMasterProductCategories()
  }

  const FilterApply = () => {
    setPage(1)
    props.fetchProductDetails({ ...filterState, pageNo: 1, sortBy: sortName, sortDirection: sortDir})
    setFilterState({...filterState});
  }

  const handleDocsSnackBarAction = () => {
    SetOpenDocsSnackbar(false)
    setDocsSnackBarMsg(null)
  }

  const handlePageNo = (val) => {
    setPage(val)
    props.fetchProductDetails({ ...filterState,
      pageNo: val, pageSize: 10, sortBy: sortName, sortDirection: sortDir
    });
  }

  const handleSortDir = (value) => {
    setsortDir(value)
    setPage(1)
  }

  const handleSortName = (value) => {
    setsortName(value)
    setPage(1)
  }

  return (
    <>
      <BasePageToolbar currentForm={currentForm}  create={permissions.includes("37")} onChangeView={onChangeView} resetFilter={resetFilter}
        title={currentForm === 'list' ? "Product Details" :  currentForm === 'view' ? "View Product" : currentForm === "edit" ? "Modify Product" : "Create Product"}
        handleFilterStateChange={handleFilterStateChange} filterState={filterState} filterCells={filterCells} FilterApply={FilterApply}
        setsortName={handleSortName} setsortDir={handleSortDir} sortDir={sortDir} sortName={sortName} sortArr={sortArr}
        arrayToFilter={productArray} handleSetFilterData={handleSetFilterData} isTableSearchReq={false} searchValue={searchValue}
      ></BasePageToolbar>
        <BasePageContainer>
            {currentForm === 'list' ?
            <Grid container className={classes.container}>
              <TableComponent
                sortOrder="desc"
                sortOrderBy="createdOn"
                loading={productLoader}
                headCells={headCells}
                rows={searchValue && searchValue !== "" ? filterData : productArray}
                onChangeView={onChangeView}
                showEdit={permissions.includes("38")}
                activeTab={filterState}
                totalRecord={totalRecord}
                handlePageNo={handlePageNo}
              />
            </Grid>
            : currentForm === 'create' ?
              <Grid container className={classes.formContainer}>
                <CreateEditViewProduct 
                  currentForm={currentForm}
                  productState={productState}
                  countryList={countryList}
                  brandList={brandList}
                  categoryList={categoryList}
                  handleInputChange={handleInputChange}
                  createDisableToken={!createDisableToken}
                  handleProductCreateUpdate={handleProductCreateUpdate}
                />
              </Grid>
              :
              currentForm === "edit" ?
              <Grid container className={classes.formContainer}>
                  <CreateEditViewProduct 
                    currentForm={currentForm}
                    productState={productState}
                    countryList={countryList}
                    brandList={brandList}
                    categoryList={categoryList}
                    handleInputChange={handleInputChange}
                    createDisableToken={!createDisableToken}
                    handleProductCreateUpdate={handleProductCreateUpdate}
                    handleUploadImage={handleUploadImage}
                    imageHandler={imageHandler}
                    imageData={imageData}
                  />
              </Grid> :
                <Grid container className={classes.formContainer}>
                    <CreateEditViewProduct 
                      currentForm={currentForm} 
                      productDetails={clientRow}
                      onChangeView={onChangeView}
                      imageData={imageData}
                    />
                </Grid>
            }

            <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => handleSnackBarAction()}
            action={
                <React.Fragment>
                    <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
                    <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
            >
                <Alert onClose={() => handleSnackBarAction()} severity={
                  createProductRes && createProductRes !== null && !createProductRes.st ? "info" : "success"
                }>
                  {snackbarMsg}
                </Alert>
            </Snackbar>
            <Dialog
              open={openPopover}
              onClose={() => handleDialogAction("exit")}
              className={"delete_dialog"}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
                <div className="close_dialog" onClick={() => handleDialogAction("exit")}><img src={CloseImgIcon} alt="close" /></div>
                <DialogTitle id="alert-dialog-title">
                    <h2>{"Product Created Successfully!"}</h2>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`Do you want to Create new Product?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" className="yes" autoFocus
                        onClick={() => handleDialogAction("create")} 
                    >
                        Create New
                    </Button>
                    <Button color="primary" className="no"
                        onClick={() => handleDialogAction("exit")} 
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
              open={openDeletePopover}
              onClose={() => handleDeleteDialogAction("exit")}
              className={"delete_dialog"}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
                <div className="close_dialog" onClick={() => handleDeleteDialogAction("exit")}><img src={CloseImgIcon} alt="close" /></div>
                <DialogTitle id="alert-dialog-title">
                    <h2>{"Delete Confirmation!"}</h2>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`Are you sure do you want to delete ?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" className="yes" autoFocus
                        onClick={() => handleDeleteDialogAction("Delete")} 
                    >
                        Delete
                    </Button>
                    <Button color="primary" className="no"
                        onClick={() => handleDeleteDialogAction("exit")} 
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar key="dsocument-upload"
              anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
              }}
              open={openDocsSnackbar}
              autoHideDuration={6000}
              onClose={() => handleDocsSnackBarAction()}
              action={
                  <React.Fragment>
                      <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleDocsSnackBarAction()}>
                      <CloseIcon fontSize="small" />
                      </IconButton>
                  </React.Fragment>
              }
            >
                <Alert onClose={() => handleDocsSnackBarAction()} severity={
                  uploadDocumentResp && uploadDocumentResp !== null && !uploadDocumentResp.st ? "error" : "success"
                }>
                  {docsSnackbarMsg}
                </Alert>
            </Snackbar>

        </BasePageContainer>
    </>)
}
const useStyles = tss.create({
  formContainer: {
    paddingTop: theme.spacing(0),
  },
  container: {
    paddingTop: theme.spacing(2),
  },
});

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
        fetchProductDetails,
        fetchCountryDetails, fetchBrandDetails,
        fetchMasterProductCategories, clearProductRes,
        uploadDocument, resetUploadDocumentResp,
        clearBrandDetail,
        createProduct, modifyProduct
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    productList: state.ProductReducer.productDetails && state.ProductReducer.productDetails !== null && state.ProductReducer.productDetails.productList? 
      state.ProductReducer.productDetails.productList : [],
    totalRecord:  state.ProductReducer.productDetails && state.ProductReducer.productDetails !== null && state.ProductReducer.productDetails.totalRecord? 
      state.ProductReducer.productDetails.totalRecord : 0,
    countryList: state.SchemeReducer.countryDetails && state.SchemeReducer.countryDetails !== null 
      && state.SchemeReducer.countryDetails.countryList ? state.SchemeReducer.countryDetails.countryList : [],
    brandList: state.ProductReducer.brandDetails && state.ProductReducer.brandDetails !== null && state.ProductReducer.brandDetails.brands ? 
      state.ProductReducer.brandDetails.brands : [],
    categoryList: state.ProductReducer.productCategoryDetails && state.ProductReducer.productCategoryDetails !== null && state.ProductReducer.productCategoryDetails.category ? 
      state.ProductReducer.productCategoryDetails.category : [],
    createProductRes: state.ProductReducer.createProductRes ,
    productLoader: state.ProductReducer.productLoader ,
    uploadDocumentResp: state.SharedReducer.uploadDocumentResp
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Product);
