import { createRequestActionTypes } from "../../../shared/utility";

export const BrandActions = {
    handleCountryChangeForCreateBrand: createRequestActionTypes('HANDLE_COUNTRY_CHANGE_FOR_CREATE_BRAND'),
    handleBrandNameChangeForCreateBrand: createRequestActionTypes('HANDLE_BRAND_NAME_CHANGE_FOR_CREATE_BRAND'),
    handleDisplayBrandNameChangeForCreateBrand: createRequestActionTypes('HANDLE_DISPLAY_BRAND_NAME_CHANGE_FOR_CREATE_BRAND'),
    handleDescriptionChangeForCreateBrand: createRequestActionTypes('HANDLE_DESCRIPTION_CHANGE_FOR_CREATE_BRAND'),
    handleIsSerialNumberRequiredCheckForCreateBrand: createRequestActionTypes('HANDLE_IS_SERIAL_NUMBER_REQUIRED_CHANGE_FOR_CREATE_BRAND'),
    handleScreenTypeChangeForCreateBrand: createRequestActionTypes('HANDLE_SCREEN_TYPE_CHANGE_FOR_CREATE_BRAND'),
    handleCountryDetailsFetchForBrand: createRequestActionTypes('HANDLE_COUNTRY_DETAILS_FETCH_FOR_BRAND'),
    handleScreenTypeDetailsFetchForBrand: createRequestActionTypes('HANDLE_SCREEN_TYPE_DETAILS_FETCH_FOR_BRAND'),
    handleBrandDetailsFetch: createRequestActionTypes('HANDLE_BRAND_DETAILS_FETCH'),
    handleIndividualBrandDetailsFetchForBrand: createRequestActionTypes('HANDLE_INDIVIDUAL_BRAND_DETAILS_FETCH_FOR_BRAND'),
    handleCreateOrEditBrandSubmit: createRequestActionTypes('HANDLE_CREATE_OR_EDIT_BRAND_SUBMIT'),
    uploadDocumentForBrand: createRequestActionTypes('UPLOAD_DOCUMENT_FOR_BRAND'),
}