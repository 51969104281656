export const primaryColor = "#3CB349"
export const secondaryColor = "#3f9248"

export const BASE_URL = document.location.host && 
    //PROD environment UI IP
    document.location.host === "adminplmci.pinelabs.com" ? 'https://plmci.pinelabs.com' 
        //MIGRATION environment UI IP
        : document.location.host && document.location.host === 'plmcimigration.pinelabs.com' ? 'https://plmcimigration.pinelabs.com/gateway'
            //UAT environment UI IP
            : document.location.host && document.location.host === 'adminuatplmci.pinelabs.com' ? 'https://plmciuat.pinelabs.com'
                // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
                //Replace with DEV environment remote machine API IP when using in DEVELOPMENT
                : 'https://plmcitest.pinelabs.com'
                
export const HONG_KONG = "344"
export const PRODUCT_FILE_BASE_NAME = "brand_product_details"

export const SpecialCharRegex = /^[A-Za-z0-9 ]+$/
export const SpecialCharRegex1 = /^[A-Za-z ]+$/
export const SpecialCharRegex2 = /^[A-Za-z0-9-, ]+$/
export const addRegex = /^[ a-zA-Z0-9!@#\%\^\&*\)\(+=.,_-]+$/
export const EmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
// /\S+@\S+\.\S+/
export const numbRegex = /^\d+$/
export const floatRegex = /^[0-9.]+$/
export const allZeroRegex = /^0*$/
export const spaceRegexp = /^\s+$/
export const timeRegex = /^(2[0-3]|[0-1]?[\d]):[0-5][\d]:[0-5][\d]$/;
export const passRegExp = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,15}$/;
// export const nameRegExp = /^[ a-zA-Z0-9@\&\)\(|/'.,_-]+$/;
export const nameRegExp = /^[ a-zA-Z0-9@\&\}\{\]\[\)\(|/'.,_-]+$/;
export const roleNameRegex = /^[A-Za-z. ]+$/
export const descrRegex = /^[ a-zA-Z0-9!@#\%\^\&*\]\[\}\{\)\;:'\(+=._-]+$/
// export const tNcRegex = /^[ a-zA-Z0-9!@#\%\^\&*\]\\[\}\{\)\(+=.,-:/;'"]+$/
export const tNcRegex = /[|\"<>`\\$?~]/
export const installmentDescRegex = /^[ a-zA-Z0-9\]\[\)\(_-]+$/
export const urlRegex = /^(https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.)[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/i
export const usernameRegex = /^[A-Za-z0-9]+$/

export function createRequestActionTypes(base) {
    return ["REQUEST", "SUCCESS", "FAILURE"].reduce((requestTypes, type) => {
        requestTypes[type] = `${base}_${type}`
        return requestTypes
    }, {})
}

export function actionCreator(actionType, data) {
    return {
        type: actionType,
        payload: data
    }
}

export function downloadFileFromBase64Str(base64, fileName) {

    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const contentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([byteArray], {type: contentType});

    const blobUrl = URL.createObjectURL(blob);
 
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}