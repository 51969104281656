import React from 'react'
import PropTypes from 'prop-types'
import { tss } from "tss-react";
import theme from '../../_theme';
import { Link } from 'react-router-dom'
import Logo from '../../Assets/logo.svg'
import LogoSmall from '../../Assets/logo_small.svg'
import SidebarNav from './sidebarNav'
const Sidebar = props => {
  const { isCollapsed } = props
  const {classes} = useStyles(props)
  return (
    <aside className={classes.sidebar}>
      <div className={classes.sidebarBackground} />
      <div className={classes.sidebarBody}>
        <div className={classes.sidebarHeader}>
          <Link to="/" className={classes.sidebarTitleLink}>
            {isCollapsed ? <img size={30} src={LogoSmall} alt="PayLater" className={classes.logo} />
              : <img size={30} src={Logo} alt="PayLater" className={classes.logo} />}
          </Link>
        </div>
        <SidebarNav isCollapsed={isCollapsed} />
      </div>
    </aside>
  )
}

Sidebar.defaultProps = {
  isCollapsed: false,
}

Sidebar.propTypes = {
  isCollapsed: PropTypes.bool,
}

const useStyles = tss.create({
  sidebar: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    color: theme.sidebar.color,
    background: theme.sidebar.background,
  },
  sidebarBackground: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "inset -1px 0 0 0 rgba(0,0,0,0.06), 10px 0 20px 0 rgba(0,0,0,0.02)",
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat',
    backgroundSize: 'cover',
  },
  sidebarBody: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  sidebarHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  sidebarTitleLink: {
    textDecoration: 'none',
    color: '#757575',
    display: 'flex',
  },
  logo: {
    color: theme.palette.secondary.main,
    zIndex: 10,
  },
  // title: props => ({
  //   position: 'relative',
  //   overflow: 'visible',
  //   marginLeft: '5px',
  //   display: props.isCollapsed ? 'none' : 'block',
  //   fontSize: '1.5rem',
  //   // fontSize: '1.1rem',
  //   letterSpacing: '.015em',
  //   // fontWeight: 'bold',
  // }),
  name: {},
  tagline: {
    // fontSize: 8,
    fontSize: 12,
    fontWeight: 'bold',
    position: 'absolute',
    top: '100%',
    marginTop: -5,
    background: theme.palette.primary.main,
    color: '#fff',
    borderRadius: 2,
    padding: '1px 3px',
    right: 0,
  },
});

export default Sidebar
