import { actionCreator, BASE_URL } from '../../../shared/utility';
import { PSPAcquirerActions } from '../consts/index';

export function fetchPSPAcquirerDetails(payload) {

    const id = payload.id && payload.id !== "" ? `&id=${payload.id}` : ""
    const pspId = payload.pspId && payload.pspId !== "" ? `&pspId=${payload.pspId}` : ""
    const acquirerObjId = payload.acquirerObjId && payload.acquirerObjId !== "" ? `&acquirerObjId=${payload.acquirerObjId}` : ""
    const isoCountryCodeNum = payload.isoCountryCodeNum && payload.isoCountryCodeNum !== "" ? `&isoCountryCodeNum=${payload.isoCountryCodeNum}` : ""
    const status = payload.status && payload.status !== "" ? `&status=${payload.status}` : ""
    const pspIntegrationMode = payload.pspIntegrationMode && payload.pspIntegrationMode !== "" ? `&pspIntegrationMode=${payload.pspIntegrationMode}` : ""
    const endURL = `?pageNo=${payload.pageNo}&pageSize=${payload.pageSize}&sortDirection=${payload.sortDirection}&sortBy=${payload.sortBy}`
        + pspId + acquirerObjId + pspIntegrationMode + status + id + isoCountryCodeNum ;

    return (dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(PSPAcquirerActions.fetchPSPAcquirerDetails.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/psp-acquirers${endURL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                } else {
                    dispatch(actionCreator(PSPAcquirerActions.fetchPSPAcquirerDetails.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(PSPAcquirerActions.fetchPSPAcquirerDetails.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function createPSPAcquirerMapping(payload) {
    return (dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(PSPAcquirerActions.createAndUpdatePSPAcquirerMapping.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/psp-acquirers`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                } else {
                    dispatch(actionCreator(PSPAcquirerActions.createAndUpdatePSPAcquirerMapping.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(PSPAcquirerActions.createAndUpdatePSPAcquirerMapping.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function updatePSPAcquirerMapping(payload) {
    return (dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(PSPAcquirerActions.createAndUpdatePSPAcquirerMapping.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/psp-acquirers`, {
            method: 'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                } else {
                    dispatch(actionCreator(PSPAcquirerActions.createAndUpdatePSPAcquirerMapping.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(PSPAcquirerActions.createAndUpdatePSPAcquirerMapping.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetCreatePSPAcquirerMapping() {
    return(dispatch) => {
        dispatch(actionCreator(PSPAcquirerActions.createAndUpdatePSPAcquirerMapping.FAILURE, null))
    }
}

export function fetchPSPMasterData(payload) {

    const endURL = payload && payload.countryId && payload.countryId !== "" ? `?countryId=${payload.countryId}` : ""
    return (dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(PSPAcquirerActions.fetchPSPMasterData.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/psps/masterdata${endURL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                } else {
                    dispatch(actionCreator(PSPAcquirerActions.fetchPSPMasterData.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(PSPAcquirerActions.fetchPSPMasterData.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchAcqMasterData(payload) {

    const endURL = payload && payload.countryId && payload.countryId !== "" ? `?countryId=${payload.countryId}` : ""
    return (dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(PSPAcquirerActions.fetchAcqMasterData.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/acquirers/masterdata${endURL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                } else {
                    dispatch(actionCreator(PSPAcquirerActions.fetchAcqMasterData.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(PSPAcquirerActions.fetchAcqMasterData.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchPspIntegrationModeMasterData(payload) {

    return (dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(PSPAcquirerActions.fetchPspIntegrationModeMasterData.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/pspIntegrationMode/masterdata`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                } else {
                    dispatch(actionCreator(PSPAcquirerActions.fetchPspIntegrationModeMasterData.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(PSPAcquirerActions.fetchPspIntegrationModeMasterData.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}
