import React from 'react'
import { tss } from "tss-react";
import theme from '../../../_theme';
import List from '@mui/material/List' 
import IconDashboard from '@mui/icons-material/Dashboard' //
import StorefrontIcon from '@mui/icons-material/Storefront';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import {Typography} from '@mui/material'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LanguageIcon from '@mui/icons-material/Language';
import CategoryIcon from '@mui/icons-material/Category';
import ReportIcon from '@mui/icons-material/Report';
import ListAltIcon from '@mui/icons-material/ListAlt';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';

import IconScheme_outline from '../../../Assets/scheme_outline.svg';
import IconScheme_filled from '../../../Assets/scheme_filled.svg';
import NavList from './navList'

const SidebarNav = props => {
  const permissions = localStorage.getItem("permissions")
  const { isCollapsed } = props
  const [itemsCore, setitemsCore] = React.useState([])
  const currentPage = localStorage.getItem('currentPage')
  const {classes} = useStyles()
  const curPageCheck = currentPage && currentPage !== null && (currentPage === "/bankschemes" || 
    currentPage === "/merchantschemes" || currentPage === "/brandschemes")

  const SchemeIcon = () => <Typography><img size={5} src={IconScheme_outline} alt="" /></Typography>
  const SchemeIcon_filled = () => <Typography><img size={5} src={IconScheme_filled} alt="" /></Typography>
  let itemsCoreTemp = [
    {
      name: 'Dashboard',
      link: '/',
      Icon: IconDashboard,
    },
    {
      name: 'Manage Scheme',
      // link: '/brandscheme',
      Icon: curPageCheck ? SchemeIcon_filled : SchemeIcon, 
      items: [
        {
          key:"1",
          name: 'Bank Schemes',
          link: '/bankschemes',
        },
        {
          key:"4",
          name: 'Merchant Schemes',
          link: '/merchantschemes',
        },
        {
          key:"8",
          name: 'Brand Schemes',
          link: '/brandschemes',
        },
      ],
    },
    // {
    //   name: 'Scheme Request',
    //   // link: '/store',
    //   Icon: HourglassEmptyIcon,  
    //   items: [
    //     {
    //       key:"4",
    //       name: 'MIB Scheme',
    //       link: '/mibschemerequest',
    //     },
    //     {
    //       key:"8",
    //       name: 'Brand Scheme',
    //       link: '/brandschemerequest',
    //     },
    //   ],
    // },
    {
      name: 'Manage Merchant',
      // link: '/store',
      Icon: StorefrontIcon,  
      items: [
        {
          key:"58",
          name: 'Acquirers',
          link: '/acquirer',
        },
        {
          key:"12",
          name: 'Payment Provider',
          link: '/psp',
        },
        {
          key:"61",
          name: 'PSP Acquirer Mapping',
          link: '/pspAcquirer',
        },
        {
          key:"15",
          name: 'Merchants',
          link: '/merchant',
        },
        {
          key:"18",
          name: 'Stores',
          link: '/store',
        },
        {
          key:"21",
          name: 'Terminals',
          link: '/terminal',
        },
      ],
    },
    {
      key:"24",
      name: 'Manage Program',
      link: '/program',
      Icon: LoyaltyIcon,  
    },
    {
      name: 'Manage Users',
      // link: '/users',
      Icon: PersonRoundedIcon,  
      items: [
        {
          key: "30",
          name: 'Roles',
          link: '/roles',
        },
        {
          key: "27",
          name: 'Users',
          link: '/users',
        },
      ],
    },
    {
      key:"33",
      name: 'Manage Issuer',
      link: '/issuers',
      Icon: AccountBalanceIcon,  
    },
	  {
      key:"45",
      name: 'Manage Country',
      link: '/country',
      Icon: LanguageIcon
    },
    {
      name: 'Manage Product',
      Icon: GroupWorkIcon, 
      items: [
        {
          key: "36",
          name: 'Product',
          link: '/product',
        },
        {
          key: "42",
          name: 'Brand',
          link: '/brand',
        },
        {
          key: "39",
          name: 'Product Category',
          link: '/category',
        },
        {
          key: "67",
          name: 'Product Group',
          link: '/productGroup',
        },
        {
          key: "70",
          name: 'Product Code Mapping',
          link: '/productCodeMapping',
        },
      ],
    },
	  // {
    //   key:"52",
    //   name: 'Manage Product Type',
    //   link: '/productype',
    //   Icon: CategoryIcon
    // },
    {
      key:"48",
      name: 'Manage MCI Report',
      link: '/mci',
      Icon: ReportIcon
    },
    {
      key:"55",
      name: 'Client Log Mapping',
      link: "/paxconfig",
      Icon: ListAltIcon
    },
    {
      key:"80",
      name: 'Key Management',
      link: "/keyManagement",
      Icon: EnhancedEncryptionIcon
    }
  ]

  const handleItemListGen = () => {
    if(permissions && permissions !== null){
      const perm = JSON.parse(permissions)
      let result = itemsCoreTemp.slice(1).map(obj => {
        if(obj.items) {
          return {
            ...obj,
            items: (obj.items).filter(item => {
              return perm.includes(item.key) 
            })
          }
        } else {
          if(permissions.includes(obj.key)) {
            return obj
          }
        }
      }).filter(finalObj => finalObj && finalObj.items && finalObj.items.length > 0 ? true 
          : finalObj && finalObj.items === undefined ? true : false)
      setitemsCore([itemsCoreTemp[0], ...result])
    }
  }

  React.useEffect(() => {
    handleItemListGen()
  }, [])

  React.useEffect(() => {
    curPageCheck !== null && handleItemListGen()
  }, [curPageCheck])

  return (
    <div>
      <List className={classes.navList} disablePadding>
        <NavList isCollapsed={isCollapsed} items={itemsCore} />
      </List> 
    </div>
  )
}

const useStyles = tss.create({
  navList: {
    width: theme.sidebar.width,
    // fontSize: '1.1em',
    fontSize: '1.5em',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
  navListHeader: {
    textAlign: 'center',
    color: 'rgba(255,255,255,0.5)',
  },
  iconFeatures: {
    color: '#95de3c',
  },
  iconDocs: {
    color: '#f8cda9',
  },
  iconSupporters: {
    color: '#e3b546',
  },
  iconDiscuss: {
    color: '#ccc',
  },
});

export default SidebarNav
