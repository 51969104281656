import React from 'react'
import PropTypes from 'prop-types'

import { Typography,Grid } from '@mui/material'
import { tss } from "tss-react";
import theme from '../../../_theme'
const AuthHeader = ({ title = '', text = "" }) => {
  const {classes} = useStyles()

  return (
    <React.Fragment>
      <Grid container className="MuiGrid-align-items-xs-center grid_button login_header" >
        <Typography component="h1" variant="h4" className={classes.head}>
          {title}
        </Typography>
        <p className={classes.para}>
          {text}
        </p>
      </Grid>
    </React.Fragment>
  )
}

AuthHeader.propTypes = {
  title: PropTypes.string,
}

const useStyles = tss.create({
  logo: {
    color: theme.palette.primary.main,
    position: 'relative',
    top: '1px',
  },
  head: {
    margin: "0",
    textAlign: "left",
    width: "100%",
  },
  para: {
    margin: "0",
    textAlign: "left",
    width: "100%"
  }
});

export default AuthHeader
