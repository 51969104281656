import { ProductActions } from "../consts/index";

const initialState = {
    productDetails: null,
    countryDetails: null,
    brandDetails: null,
    productCategoryDetails: null,
    createProductRes: null,
    productLoader: false
}

export default (state = initialState, {type, payload}) => {
    switch(type) {
        case ProductActions.handleProductDetailsFetch.REQUEST:
            return {
                ...state,
                productLoader: true,
            }
        case ProductActions.handleProductDetailsFetch.SUCCESS:
            return {
                ...state,
                productDetails: payload,
                productLoader: false,
            }
        case ProductActions.handleProductDetailsFetch.FAILURE:
            return {
                ...state,
                productDetails: null,
                productLoader: false,
            }

        case ProductActions.handleCountryDetailsFetchForProduct.REQUEST:
            return {
                ...state,
                productLoader: true,
            }
        case ProductActions.handleCountryDetailsFetchForProduct.SUCCESS:
            return {
                ...state,
                countryDetails: payload,
                productLoader: false,
            }
        case ProductActions.handleCountryDetailsFetchForProduct.FAILURE:
            return {
                ...state,
                countryDetails: null,
                productLoader: false,
            }

        case ProductActions.handleBrandDetailsFetchForProduct.REQUEST:
            return {
                ...state,
                productLoader: true,
            }
        case ProductActions.handleBrandDetailsFetchForProduct.SUCCESS:
            return {
                ...state,
                brandDetails: payload,
                productLoader: false,
            }
        case ProductActions.handleBrandDetailsFetchForProduct.FAILURE:
            return {
                ...state,
                brandDetails: null,
                productLoader: false,
            }

        case ProductActions.handleCreateOrEditProductSubmit.REQUEST: 
            return {
                ...state,
                productLoader: true,
            }
        case ProductActions.handleCreateOrEditProductSubmit.SUCCESS: 
            return {
                ...state,
                createProductRes: payload,
                productLoader: false,
            }
        case ProductActions.handleCreateOrEditProductSubmit.FAILURE: 
            return {
                ...state,
                createProductRes: payload,
                productLoader: false,
            }

        case ProductActions.fetchMasterProductCategories.REQUEST:
            return {
                ...state,
                productLoader: true,
                }
        case ProductActions.fetchMasterProductCategories.SUCCESS:
            return {
                ...state,
                productCategoryDetails: payload,
                productLoader: false,
            }
        case ProductActions.fetchMasterProductCategories.FAILURE:
            return {
                ...state,
                productCategoryDetails: null,
                productLoader: false,
            }

        default:
            return state;
    }
}