import React, {useEffect} from 'react'
import {connect} from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from 'react-router-dom'
import { tss } from "tss-react";
import theme from '../../_theme'
import Menu from '@mui/material/Menu'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; 
import IconLogout from '@mui/icons-material/ExitToApp';
import LockIcon from '@mui/icons-material/Lock';
import {
    Grid, MenuItem, Button, Snackbar, 
    Dialog, DialogActions,
    DialogTitle, DialogContent,
}   from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';

import CloseImgIcon from '../../Assets/close.svg';
import {passRegExp} from "../../shared/utility";
import {resetUserPassword, resetChangePasswordResp
} from "../../manageUser/actions/index";
import {handleUserLogout} from "../../auth/actions/index";

const AppHeaderProfile = (props) => {
  const {changePasswordRes } = props;
  const {classes, cx} = useStyles()
  // const history = useHistory(); 
  const userName = localStorage.getItem("userName");
  const [anchorEl, setAnchorEl] = React.useState(null)
  const user = {
    firstName: `Hi, ${userName ? userName : "Admin" } `,
  }
  const [openPopover, setopenPopover] = React.useState(false);
  const [passwordState, setPasswordState] = React.useState({
    password: '',
    showPassword: false,
    confirmPassword: "",
    showConfirmPassword: false,
    currentPassword: '',
    showCurrentpass: false
  });    
  const [passwordValObj, setPasswordValObj] = React.useState({
    passwordErr: false,
    confirmPasswordErr: false,
    passwordMatchErr: false,
    currentPasswordErr: false
  });
  const [openSnackbar, SetOpenSnackbar] = React.useState(false);
  const [snackbarMsg, setSnackBarMsg] = React.useState(null);

  const inCorrectPassMsg = `Please enter password between 8 to 15 Char, including atleast 1 Capital letter, 
  1 small letter, 1 digit and one special char!`;

  const firstTimeLogin = localStorage.getItem('firstTimeLogin') ? 
    JSON.parse(localStorage.getItem('firstTimeLogin')) : false;
      
  const [chngPassIncUserName, setchngPassIncUserName] = React.useState(false);
  const [chngCnfPassIncUserName, setchngCnfPassIncUserName] = React.useState(false);

  const resetPasswordFlag = passwordState && passwordState.password !== "" && 
    passwordState.confirmPassword !== "" && passwordState.password === passwordState.confirmPassword && 
    passRegExp.test(passwordState.password) && passRegExp.test(passwordState.confirmPassword) && 
    !chngPassIncUserName && !chngCnfPassIncUserName && 
    //(firstTimeLogin || (!firstTimeLogin && 
    passwordState.currentPassword !== "";
    // ));

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  });

  useEffect(() => {
    if(firstTimeLogin && !openPopover) {
      setopenPopover(true)
    }
  }, [firstTimeLogin])

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  async function handleLogOut() {
    await props.handleUserLogout({})
    // await localStorage.clear();
    // await history.go(0);
  }

  const handleDialogAction = (type) => {
    if(type === "ChangePassword") {
        if(passwordState.password === "") {
            setPasswordValObj({...passwordValObj, passwordErr: true})
        } else if(passwordState.confirmPassword === "") {
            setPasswordValObj({...passwordValObj, confirmPasswordErr: true})
        } else if(passwordState.confirmPassword !== passwordState.password) {
            setPasswordValObj({...passwordValObj, passwordMatchErr: true})
        } else if(passwordState.currentPassword === "") {
          setPasswordValObj({...passwordValObj, currentPasswordErr: true})
        } else {
          setopenPopover(false)
          props.resetUserPassword({
            "userName" : userName, "password": passwordState.password, 
            "oldPassword": passwordState.currentPassword
          })
        }
    } else {
        setopenPopover(false)
    }
  }

  const validateResetConfirmPassword = (value) => {
    if(passwordState.password === value){
        setPasswordValObj({...passwordValObj, passwordMatchErr: false, confirmPasswordErr: false})
    } else {
        setPasswordValObj({...passwordValObj, passwordMatchErr: true, confirmPasswordErr: false})
    } 
  };

  const handleChangePasswordChange = (key, value) => {
    if(key === "password") {
        setPasswordValObj({...passwordValObj, passwordErr: false})
        setPasswordState({ ...passwordState, password: value});
        if(userName && userName !== "") {
            const userArr =  [...(userName.toUpperCase()).split(" ")]
            let flag = false;
              
            userArr.map(name => {
              if(((value).toUpperCase()).includes(name) && !flag) {
                flag = true
              }
              return null;
            })

            const str = userName.toUpperCase();
            const str1 = value.toUpperCase();
            let i = 0;
            while (i < str.length) {
                if(str1.includes(`${str}${str}`.slice(i, (i+4)))
                ) {
                    flag = true
                    break;
                }
                i++;
            }
            setchngPassIncUserName(flag)
        }

    } else if(key === "confirmPassword") {
        validateResetConfirmPassword(value)
        setPasswordState({ ...passwordState, confirmPassword: value});
        if(userName && userName !== "") {
            const userArr =  [...(userName.toUpperCase()).split(" ")]
            let flag = false;
              
            userArr.map(name => {
              if(((value).toUpperCase()).includes(name) && !flag) {
                flag = true
              }
              return null;
            })

            const str = userName.toUpperCase();
            const str1 = value.toUpperCase();
            let i = 0;
            while (i < str.length) {
                if(str1.includes(`${str}${str}`.slice(i, (i+4)))
                ) {
                    flag = true
                    break;
                }
                i++;
            }
            setchngCnfPassIncUserName(flag)
        }
    } else {
      setPasswordState({ ...passwordState, currentPassword: value});
      setPasswordValObj({...passwordValObj, currentPasswordErr: false})
    }
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowChangePassword = (key) => {
    if(key === "password") {
        setPasswordState({ ...passwordState, showPassword: !passwordState.showPassword });
    } else if(key === "confirmPassword") {
        setPasswordState({ ...passwordState, showConfirmPassword: !passwordState.showConfirmPassword });
    } else {
      setPasswordState({ ...passwordState, showCurrentpass: !passwordState.showCurrentpass });
    }
  };

  const handleSnackBarAction = () => {
    SetOpenSnackbar(false)
    setSnackBarMsg(null)
    //logout only if successfully changed password.
    if(changePasswordRes && changePasswordRes !== null && firstTimeLogin) {
      handleLogOut();
    } else {
      setPasswordState({
        password: '',
        showPassword: false,
        confirmPassword: "",
        showConfirmPassword: false,
      });
    }
    props.resetChangePasswordResp();
  }
  
  useEffect(() => {
    if(changePasswordRes && changePasswordRes !== null && changePasswordRes.st) {
      SetOpenSnackbar(true)
      setSnackBarMsg(`Password Changed Successfully! Please Re-login Again.`);
    } else if (changePasswordRes && changePasswordRes !== null && !changePasswordRes.st) {
      SetOpenSnackbar(true)
      setSnackBarMsg(changePasswordRes.msg && changePasswordRes.msg)
    }
  }, [changePasswordRes])

  return (
    <div className={cx('headerProfile', classes.headerProfile)}>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="Search"
        className={classes.profileButton}
        onClick={(e) => handleClick(e)}
      >
        <span className={classes.profileName}>{user.firstName}</span>
        <ExpandMoreIcon className={classes.expandIcon}/>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
        elevation={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        classes={{
          paper: classes.profileMenu,
        }}
      >
        <MenuItem 
          onClick={(e) => setopenPopover(true)}
        >
          <ListItemIcon className={classes.profileMenuItemIcon}>
            <LockIcon className={classes.ItemIcon}/>
          </ListItemIcon>
          <ListItemText primary="Change Password" className={classes.itemFont}/>
        </MenuItem>
        <MenuItem onClick={(e) => handleLogOut(e)} component={Link} to="/auth">
          <ListItemIcon className={classes.profileMenuItemIcon}>
            <IconLogout className={classes.ItemIcon}/>
          </ListItemIcon>
          <ListItemText primary="Logout" className={classes.itemFont}/>
        </MenuItem>
      </Menu>

      <Dialog
        open={openPopover}
        onClose={() => !firstTimeLogin ? handleDialogAction("exit") : null}
        className={"delete_dialog"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!firstTimeLogin && 
          <div className="close_dialog" onClick={() => handleDialogAction("exit")} >
            <img src={CloseImgIcon} alt="close" />
          </div>
        }
        <DialogTitle id="alert-dialog-title">
          <h2>{`Change Password`}</h2>
        </DialogTitle>
        <DialogContent dividers>
          <div>
            <Grid container rowSpacing={2} columnSpacing={2}>
              <Grid item xs={12}> 
                {firstTimeLogin && <h4 style={{color: "#1BA785"}}>
                  {`Logging in for the first time? Needs password change.`}
                </h4>}
              </Grid>
              {/* {!firstTimeLogin &&  */}
              <Grid item xs={12}>
                <FormControl 
                  fullWidth 
                  required
                  variant="outlined"
                  className={cx(classes.marginTopBottom)}
                >
                  <InputLabel htmlFor="outlined-adornment-password1" > Enter Current Password </InputLabel>
                  <OutlinedInput 
                    name="currentPassword" id="currentPassword" 
                    // id="outlined-adornment-new-password"
                    type={passwordState.showCurrentpass ? 'text' : 'password'}
                    labelwidth={150}
                    label="Enter Current Password"
                    value={passwordState.currentPassword} 
                    onChange={(e) => handleChangePasswordChange('currentPassword', e.target.value)} 
                    endAdornment={
                    <InputAdornment position="end">
                      <IconButton 
                        aria-label="toggle password visibility" className="password_icon"
                        onClick={() => handleClickShowChangePassword("currentPassword")} edge="end"
                        onMouseDown={(e) => handleMouseDownPassword(e)} 
                      >
                        {passwordState.showCurrentpass ? <Visibility /> : <VisibilityOff />} 
                      </IconButton>
                    </InputAdornment>
                    }
                  />
                  {passwordValObj.currentPasswordErr ? 
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                      <span className="error_message"><InfoOutlinedIcon /> {"Password is Required!"}</span>
                    </p>
                    : null 
                  }
                </FormControl>
              </Grid>
              {/* } */}

              <Grid item xs={12}>
                <FormControl 
                  fullWidth 
                  required
                  variant="outlined"
                  className={cx(classes.marginTopBottom)}
                >
                  <InputLabel htmlFor="outlined-adornment-password1" > Enter New Password </InputLabel>
                  <OutlinedInput 
                    name="userPassword" id="userPassword" 
                    // id="outlined-adornment-new-password"
                    type={passwordState.showPassword ? 'text' : 'password'}
                    labelwidth={150} 
                    label="Enter New Password"
                    value={passwordState.password} 
                    onChange={(e) => handleChangePasswordChange('password', e.target.value)} 
                    endAdornment={
                    <InputAdornment position="end">
                      <IconButton 
                        aria-label="toggle password visibility" className="password_icon"
                        onClick={() => handleClickShowChangePassword("password")} edge="end"
                        onMouseDown={(e) => handleMouseDownPassword(e)} 
                      >
                        {passwordState.showPassword ? <Visibility /> : <VisibilityOff />} 
                      </IconButton>
                    </InputAdornment>
                    }
                  />
                  {passwordValObj.passwordErr ? 
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                      <span className="error_message"><InfoOutlinedIcon /> {"Password is Required!"}</span>
                    </p>
                    : passwordState.password && !passRegExp.test(passwordState.password) ?
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                        <span className="error_message"><InfoOutlinedIcon /> {inCorrectPassMsg}</span>
                      </p> 
                      : passwordState.password && chngPassIncUserName ?
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                          <span className="error_message"><InfoOutlinedIcon /> {"Password should not contain any part of user name."}</span>
                        </p> : null 
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl 
                  fullWidth 
                  variant="outlined"
                  required
                >
                  <InputLabel htmlFor="outlined-adornment-password1" > Confirm New Password </InputLabel>
                  <OutlinedInput 
                    name="confirmPassword"  id="confirmPassword"
                    // id="outlined-adornment-confirm-new-password"
                    type={passwordState.showConfirmPassword ? 'text' : 'password'}
                    labelwidth={160} 
                    label="Confirm New Password"
                    value={passwordState.confirmPassword} 
                    onChange={(e) => handleChangePasswordChange('confirmPassword', e.target.value)} 
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton 
                        aria-label="toggle password visibility" className="password_icon"
                        onClick={() => handleClickShowChangePassword("confirmPassword")} edge="end"
                        onMouseDown={(e) => handleMouseDownPassword(e)} 
                        >
                        {passwordState.showConfirmPassword ? <Visibility /> : <VisibilityOff />} 
                        </IconButton>
                    </InputAdornment>
                    }
                  />
                  {passwordValObj.confirmPasswordErr  ?
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                      <span className="error_message"><InfoOutlinedIcon /> {"Confirm Password is Required!"}</span>
                    </p>
                    : passwordValObj.passwordMatchErr ? 
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                        <span className="error_message"><InfoOutlinedIcon /> {"Confirm password should be same as Password"}</span>
                      </p>
                      : passwordState.confirmPassword && !passRegExp.test(passwordState.confirmPassword) ?
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                          <span className="error_message"><InfoOutlinedIcon /> {inCorrectPassMsg}</span>
                        </p> 
                        : passwordState.password && chngCnfPassIncUserName ?
                          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message"><InfoOutlinedIcon /> {"Confirm password should not contain any part of user name."}</span>
                          </p> : null 
                  }
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" className="yes" autoFocus
            onClick={() => handleDialogAction("ChangePassword")} 
            disabled={!resetPasswordFlag}
          >
            Change Password
          </Button>
          <Button color="primary" className="no"
            onClick={() => handleDialogAction("exit")} 
            disabled={firstTimeLogin}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
                  
      <Snackbar
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => handleSnackBarAction()}
        action={
          <React.Fragment>
            <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert onClose={() => handleSnackBarAction()} 
          severity={
            changePasswordRes && changePasswordRes !== null && !changePasswordRes.st ? "warning" : "success"
          }
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </div>
  )
}

const useStyles = tss.create({
  headerProfile: {
    display: 'inline-flex',
  },
  profileButton: {
    padding: 8,
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  profileAvatar: {
    width: 35,
    height: 35,
    marginRight: 10,
  },
  profileName: {
    color: "#424242",
    fontFamily: "Quicksand-SemiBold",
    // fontSize: "16px", 
    fontSize: "18px",
    letterSpacing: "0",
    lineHeight: "20px",
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  profileMenu: {
    marginLeft: '-16px',
    width: "200px", border: "1px solid #EEEEEE",
    borderRadius: "3px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 6px 12px 0 rgba(0,0,0,0.08)"
  
  },
  profileMenuItemIcon: {
    // color: theme.palette.primary.main,
  },
  ItemIcon: {
    width: "16px",
    height: "16px",
  },
  itemFont: {
    // fontSize:"14px",
    fontSize: "16px",
    color: "#757575",
    fontFamily: "Quicksand-Medium"
  },
  expandIcon: {
    width: "20px",
    height: "20px"
  }
});


function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
        resetUserPassword, resetChangePasswordResp,
        handleUserLogout
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    changePasswordRes: state.UserReducer.changePasswordRes
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppHeaderProfile);
