import React, { useEffect } from "react";
import {
  Grid, TextField, Paper, Button, Typography, Snackbar,
  Accordion, AccordionSummary, AccordionDetails, IconButton
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SchemeType from "../steps/schemeType";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

import DeleteIcon from '../../../Assets/delete.svg';

const addMainForm = {
  range: [{ lowRange: "", highRange: "", }],
  tenure: [{
    numberofInstallments: "", rateofInterest: "",
    feeofInstallments: "", cashbackType: "", issueCashbackPercentage: "",
    issueCashbackValue: "", startDate: "", endDate: "",
  }]
};

function AmountStepper(props) {
  const {
    id, classes, handleAmountRangeAdd, handleTenureAdd, amountValCheck,
    currentObject, AmountCollapseExpand, TenureCollapseExpand,
    handleDeleteAmount, handleDeleteTenure, handleInputChange, currentForm,
    disableToken, handleNext, showFooter, nextButtonFlag, handleStepback,
    handleRangeCheckForAmt, amountRangeRecord, instDuplicateCheck,
    handleTenureCheckForAmt, deleteToken, TenureRecord, schemeType,
    programType, subventionType, bankTemplTenureList, bankTemplAmountRangeCheck, 
    partialCofundedRecord, handlePartialCofundedValidCheck, isPLManaged
  } = props;
  const DisableFlag = disableToken ? disableToken : false;
  const [lowAmount, setLowAmount] = React.useState({amount: 0, error: ""});
  const [highAmount, setHighAmount] = React.useState({amount: 0, error: ""});
  const [openValAlert, setOpenValAlert] = React.useState(false)
  const isCurrentError = (amountRangeRecord && (amountRangeRecord.length === 0 || amountRangeRecord.includes(id)))

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  });

  useEffect(() => {
    const elemNum0 = document.querySelector('.number_field0')
    const elemNum1 = document.querySelector('.number_field1')
    elemNum0 && elemNum0.addEventListener('keydown', function(e) {
      if (e.keyCode === 38 || e.keyCode === 40) {
          e.preventDefault();
      }
    });
    elemNum1 && elemNum1.addEventListener('keydown', function(e) {
      if (e.keyCode === 38 || e.keyCode === 40) {
          e.preventDefault();
      }
    });
    return() => {
      elemNum0 && elemNum0.removeEventListener('keydown', function(e) {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
      })
      elemNum1 && elemNum1.removeEventListener('keydown', function(e) {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
      })
    }
  })

  useEffect(() => {
    if(currentForm === "edit") {
      setLowAmount({...lowAmount, amount: currentObject.lowAmount})
      setHighAmount({...highAmount, amount: currentObject.highAmount})
    }
  }, [])

  const handleRangeChange = (type, id, field, value) => {
    if(isCurrentError) {
      handleInputChange(type, id, field, value)
      let lowRangeAddCheck = null
      let highRangeAddCheck = null
      if(field === "lowAmount") {
        if(highAmount.amount !== 0 && parseFloat(value) >= parseFloat(highAmount.amount)) {
          setLowAmount({amount: parseFloat(value), error: "Invalid Ammount! Value Should be less than High Amount"})
        } else {
          setLowAmount({amount: parseFloat(value), error: ""})
          
          let bankTemplateErr = false;
          if(bankTemplAmountRangeCheck) {
            const lowBankRangeArr = bankTemplAmountRangeCheck['lowAmount']
            const highBankRangeArr = bankTemplAmountRangeCheck['highAmount']
            
            lowBankRangeArr.map((lwAmt, index) => {
              if((lowRangeAddCheck === null) || (lowRangeAddCheck !== null && lowRangeAddCheck)) {
                
                lowRangeAddCheck = parseFloat(value) < parseFloat(lwAmt) || parseFloat(value) > parseFloat(highBankRangeArr[index]) || 
                  (parseFloat(highAmount.amount) !== 0 && ((parseFloat(highAmount.amount) > parseFloat(highBankRangeArr[index])) || (parseFloat(highAmount.amount) < parseFloat(lwAmt))))
                
              }
              return null;
            })

            if((lowRangeAddCheck === null) || (lowRangeAddCheck !== null && lowRangeAddCheck)) {
              lowRangeAddCheck && setLowAmount({amount: parseFloat(value), error: "Amount range must be between Bank Tamplate amount ranges."})
              lowRangeAddCheck && handleRangeCheckForAmt && handleRangeCheckForAmt(id, "add")
              bankTemplateErr = true;
            } else {
              setHighAmount({...highAmount, error: ""})
              handleRangeCheckForAmt && handleRangeCheckForAmt(id, "remove")
              bankTemplateErr = false;
            }
            lowRangeAddCheck = false
          }
          if(!bankTemplateErr && amountValCheck){
            const lowRangeArr = amountValCheck['lowAmount'].filter((val, i) => i !== id)
            const highRangeArr = amountValCheck['highAmount'].filter((val, i) => i !== id)
            
            lowRangeArr.map((lwAmt, index) => {
              if((lowRangeAddCheck === null) || (lowRangeAddCheck !== null && !lowRangeAddCheck)) {
                
                lowRangeAddCheck = (parseFloat(value) >= parseFloat(lwAmt) && parseFloat(value) <= parseFloat(highRangeArr[index])) ||
                  (parseFloat(highAmount.amount) !== 0 && (parseFloat(highAmount.amount) > parseFloat(highRangeArr[index])) && 
                    (parseFloat(value) >= parseFloat(lwAmt) && parseFloat(value) <= parseFloat(highRangeArr[index]))) ||
                  (parseFloat(value) < parseFloat(lwAmt) && 
                    (parseFloat(highAmount.amount) !== 0 && parseFloat(highAmount.amount) >= parseFloat(lwAmt) && parseFloat(highAmount.amount) <= parseFloat(highRangeArr[index]))) ||
                  (parseFloat(value) < parseFloat(lwAmt) && parseFloat(highAmount.amount) !== 0 && parseFloat(highAmount.amount) > parseFloat(highRangeArr[index]))
                lowRangeAddCheck && setLowAmount({amount: parseFloat(value), error: "Amount range Already Added."})
                lowRangeAddCheck && handleRangeCheckForAmt && handleRangeCheckForAmt(id, "add")
                lowRangeAddCheck && setOpenValAlert(true)
              }
              return null;
            })

            if((lowRangeAddCheck === null) || (lowRangeAddCheck !== null && !lowRangeAddCheck)) {
              setHighAmount({...highAmount, error: ""})
              handleRangeCheckForAmt && handleRangeCheckForAmt(id, "remove")
              setOpenValAlert(false)
            }
          }
        }

      } else if(field === "highAmount") {
        if(lowAmount.amount !== 0 && parseFloat(value) <= parseFloat(lowAmount.amount)) {
          setHighAmount({amount: parseFloat(value), error: "Invalid Amount! Value Should be More than Low Amount"})
        } else {
          setHighAmount({amount: parseFloat(value), error: ""})

          let bankTemplateErr = false;
          if(bankTemplAmountRangeCheck) {
            const lowBankRangeArr = bankTemplAmountRangeCheck['lowAmount']
            const highBankRangeArr = bankTemplAmountRangeCheck['highAmount']
            
            lowBankRangeArr.map((lwAmt, index) => {
              if((highRangeAddCheck === null) || (highRangeAddCheck !== null && highRangeAddCheck)) {
                
                highRangeAddCheck = parseFloat(value) < parseFloat(lwAmt) || parseFloat(value) > parseFloat(highBankRangeArr[index]) ||
                  (parseFloat(lowAmount.amount) !== 0 && ((parseFloat(lowAmount.amount) > parseFloat(highBankRangeArr[index])) || (parseFloat(lowAmount.amount) < parseFloat(lwAmt))))
                
              }
              return null;
            })

            if((highRangeAddCheck === null) || (highRangeAddCheck !== null && highRangeAddCheck)) {
              highRangeAddCheck && setHighAmount({amount: parseFloat(value), error: "Amount range must be between Bank Tamplate amount ranges."})
                highRangeAddCheck && handleRangeCheckForAmt && handleRangeCheckForAmt(id, "add")
              bankTemplateErr = true;
            } else {
              setLowAmount({...lowAmount, error: ""})
              handleRangeCheckForAmt && handleRangeCheckForAmt(id, "remove")
              bankTemplateErr = false;
            }
            highRangeAddCheck = false
          }

          if(!bankTemplateErr && amountValCheck){
            const lowRangeArr = amountValCheck['lowAmount'].filter((val, i) => i !== id)
            const highRangeArr = amountValCheck['highAmount'].filter((val, i) => i !== id)

            lowRangeArr.map((lwAmt, index) => {
              if((highRangeAddCheck === null) || (highRangeAddCheck !== null && !highRangeAddCheck)) { 

                highRangeAddCheck = (parseFloat(value) >= parseFloat(lwAmt) && parseFloat(value) <= parseFloat(highRangeArr[index])) ||
                  (parseFloat(lowAmount.amount) !== 0 && (parseFloat(lowAmount.amount) < parseFloat(lwAmt)) && 
                    (parseFloat(value) >= parseFloat(lwAmt) && parseFloat(value) <= parseFloat(highRangeArr[index]))) ||
                  (parseFloat(value) > parseFloat(highRangeArr[index]) && 
                    (parseFloat(lowAmount.amount) !== 0 && parseFloat(lowAmount.amount) >= parseFloat(lwAmt) && parseFloat(lowAmount.amount) <= parseFloat(highRangeArr[index]))) ||
                  (parseFloat(lowAmount.amount) !== 0 && (parseFloat(lowAmount.amount) < parseFloat(lwAmt)) && parseFloat(value) > parseFloat(highRangeArr[index]))
                highRangeAddCheck && setHighAmount({amount: parseFloat(value), error: "Amount range Already Added."})
                highRangeAddCheck && handleRangeCheckForAmt && handleRangeCheckForAmt(id, "add")
                highRangeAddCheck && setOpenValAlert(true)
              }
              return null;
            })

            if((highRangeAddCheck === null) || (highRangeAddCheck !== null && !highRangeAddCheck)) {
              setLowAmount({...lowAmount, error: ""})
              handleRangeCheckForAmt && handleRangeCheckForAmt(id, "remove")
              setOpenValAlert(false)
            }
          }
        }
      }
    } else {
      setOpenValAlert(true)
    }
  }
  
  const handlevalidationAlert = () => {
    setOpenValAlert(false)
  }

  return (
    <React.Fragment>
      <React.Fragment>
          <div className={"paper_scroll"}>
            <Accordion className={classes.accordion} key={0} 
              expanded={DisableFlag ? true : currentObject.flag} 
              onChange={() => !DisableFlag && AmountCollapseExpand(id)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content" id={"panel" + 1}>
                <Typography className={classes.heading}>
                  { "Amount Detail - "}  {id + 1}
                </Typography>
                {(!DisableFlag && deleteToken > 1 && isCurrentError)?
                  <div className="deleteAccordIcon" 
                    onClick={() => handleDeleteAmount(id)}
                  >
                    <img alt="" src={DeleteIcon} />
                  </div>
                  : null
                }
              </AccordionSummary>
              
              {((DisableFlag && currentObject) || (!DisableFlag && currentObject && currentObject.flag)) && 
                <AccordionDetails>
                  <Grid container rowSpacing={2} columnSpacing={2}>
                    <Paper className={classes.subRangePaper}>
                      <React.Fragment key={0}>
                        <Grid container rowSpacing={2} columnSpacing={2}>
                          <Grid item xs={12} sm={4}>
                            <TextField 
                              InputLabelProps={{ shrink: true }}
                              value={currentObject['lowAmount']} disabled={DisableFlag} type="number"
                              name={`lowAmount${id}`} variant="outlined"  
                              id={`lowAmount${id}`} fullWidth label="Low Range" required
                              onChange={
                                e => e.target.value.length < 16 && (e.target.value.split(".").length < 2 || e.target.value.split(".")[1].length < 4) && 
                                handleRangeChange('amount', id, 'lowAmount', e.target.value) 
                              }
                            />
                            {currentObject['lowAmount'] && currentObject['lowAmount'] !== "" && 
                              currentObject['lowAmount'] !== null && parseFloat(currentObject['lowAmount']) <= 0 ?
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                  <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! Range Cannot be zero(0) or less."}</span>
                                </p>
                              : (amountRangeRecord && amountRangeRecord.includes(id) && (lowAmount.error === "Amount range Already Added." || lowAmount.error === "Amount range must be between Bank Tamplate amount ranges.")) || 
                                (amountRangeRecord && !amountRangeRecord.includes(id) && lowAmount.error !== "") ? 
                                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {lowAmount.error}</span>
                                  </p>
                                  :null
                            }
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField 
                              InputLabelProps={{ shrink: true }} 
                              value={currentObject['highAmount']} disabled={DisableFlag} type="number"
                              name={`highAmount${id}`} variant="outlined" 
                              fullWidth id={`highAmount${id}`} label="High Range" required
                              onChange={
                                e => e.target.value.length < 16 && (e.target.value.split(".").length < 2 || e.target.value.split(".")[1].length < 4) && 
                                handleRangeChange('amount', id, 'highAmount', e.target.value)
                              }
                            />
                            {currentObject['highAmount'] && currentObject['highAmount'] !== "" && 
                              currentObject['highAmount'] !== null && parseFloat(currentObject['highAmount']) <= 0 ?
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                  <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! Range Cannot be zero(0) or less."}</span>
                                </p>
                              : (amountRangeRecord && amountRangeRecord.includes(id) && (highAmount.error === "Amount range Already Added." || highAmount.error === "Amount range must be between Bank Tamplate amount ranges.")) || 
                                (amountRangeRecord && !amountRangeRecord.includes(id) && highAmount.error !== "" ) ? 
                                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {highAmount.error}</span>
                                  </p>
                                  :null
                            }
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4}/>
                      </React.Fragment>
                    </Paper>
                    
                    <React.Fragment>
                        {(((DisableFlag && currentObject) || (!DisableFlag && currentObject && currentObject.flag)) && currentObject.paymentFormatData) && 
                          currentObject.paymentFormatData.map((obj, index) => {
                          return <Grid item xs={12} sm={12} key={index}>
                            <Paper className={classes.subPaper}>
                              <Grid container >
                                <Accordion 
                                  expanded={DisableFlag ? true : obj.flag}
                                  className={classes.subAccordion} id={"panel" + 1}
                                  onChange={() => !DisableFlag && TenureCollapseExpand(id, obj.id)}
                                >
                                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography className={classes.heading}>Tenure Details {index+1}</Typography>
                                    {(!DisableFlag && currentObject.paymentFormatData.length > 1 && partialCofundedRecord && (partialCofundedRecord.length === 0 || partialCofundedRecord.includes(`${id}${obj.id}`))

                                      ) ? 
                                      <div className="deleteAccordIcon" 
                                        onClick={() => handleDeleteTenure(id,obj.id, index)}
                                      >
                                        <img alt="" src={DeleteIcon} />
                                      </div> 
                                      : null
                                    }
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <SchemeType 
                                      id={id}
                                      obj={obj}
                                      activeStep={0} 
                                      schemeType={schemeType}
                                      programType={programType}
                                      index={index} subIndex={1}
                                      TenureRecord={TenureRecord}
                                      subventionType={subventionType}
                                      subItem={addMainForm.tenure[0]} 
                                      handleInputChange={handleInputChange}
                                      instDuplicateCheck={instDuplicateCheck}
                                      handleTenureCheckForAmt={handleTenureCheckForAmt}
                                      DisableFlag={DisableFlag || (amountRangeRecord && amountRangeRecord.length !== 0)}
                                      bankTemplTenureList={bankTemplTenureList}
                                      lowRangeAmount={currentObject['lowAmount']}
                                      partialCofundedRecord={partialCofundedRecord}
                                      handlePartialCofundedValidCheck={handlePartialCofundedValidCheck}
                                      isPLManaged={isPLManaged}
                                    />
                                  </AccordionDetails>
                                </Accordion>
                              </Grid>
                                {!DisableFlag && programType !== "CASHBACK ONLY" && partialCofundedRecord && partialCofundedRecord.length === 0 &&
                                <Grid container justifyContent="flex-end" className={"last_grid_row"}>
                                  <Grid item xs={12}>
                                    <Button className="btn_addForm" 
                                      onClick={() => handleTenureAdd(id)}
                                    >
                                      <AddCircleOutlineRoundedIcon className={classes.iconCircleOutline} />   Add New Tenure
                                    </Button>
                                  </Grid>
                                </Grid>}
                            </Paper>
                          </Grid>
                        })}
                      </React.Fragment>
                  </Grid>
                </AccordionDetails>
              } 
            </Accordion>
          </div>
            
          {showFooter && <Grid container className="step_action">
            <Grid item xs={8} justify="flex-start" className={classes.addRange}>
              {!DisableFlag && amountRangeRecord && amountRangeRecord.length === 0 && partialCofundedRecord && partialCofundedRecord.length === 0 &&
               <Button className={"btn_addForm"} onClick={() => handleAmountRangeAdd()} >
                  <AddCircleOutlineRoundedIcon className={classes.iconCircleOutline} />  Add New Range
              </Button>}
            </Grid>
            <Grid item xs={4} style={{ display: "flex", justifyContent: "space-evenly"  }} justify="flex-end">
              <Button 
                  onClick={handleStepback} 
                  disabled={((amountRangeRecord && amountRangeRecord.length !== 0) || (partialCofundedRecord && partialCofundedRecord.length !== 0 )
                    )}
                  className={`action_login_button`}
                  style={{color: "#fff"}}
              >
                  Back
              </Button>
              <Button 
                onClick={handleNext} disabled={!nextButtonFlag}
                className={`action_login_button ${!nextButtonFlag ? "disabled_login_button" : ""}`}
              >
                {"Next"}
              </Button>
            </Grid>
          </Grid>}
      </React.Fragment>
      <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        open={openValAlert}
        autoHideDuration={10000}
        onClose={() => handlevalidationAlert()}
        action={
        <React.Fragment>
          <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handlevalidationAlert()}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
        }
      >
        <Alert onClose={() => handlevalidationAlert()} severity={"info"}>
          {"Please Correct The invalid Range Entered! Other fields are disabled"}
        </Alert>
      </Snackbar>
    </React.Fragment>
  )
}

export default AmountStepper;