import React, { useState, useEffect } from 'react'
import {connect} from "react-redux";
import { bindActionCreators } from "redux";
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { 
    Grid, IconButton, Snackbar, Dialog, DialogActions,
    DialogTitle, DialogContentText, DialogContent, Button 
} from '@mui/material'
import { tss } from "tss-react";
import theme from '../../_theme'
  
import CloseImgIcon from '../../Assets/close.svg';
import BasePageContainer from '../../_common/basePageContainer'
import BasePageToolbar from '../../_common/basePageToolbar'
import TableComponent from "../table/index"
import CreateEditViewUser from "./createEditViewUser/index";
import { 
    fetchUserDetails, createOrEditUser,
    resetUserCreateResp, fetchRoleDetails,
    resetRoleDetails, resetUserPassword,
    resetChangePasswordResp
} from "./../actions/index"
import {EmailRegex, SpecialCharRegex1, numbRegex, passRegExp, usernameRegex} from "../../shared/utility";

const headCells = [
    { id: 'firstName', type: 'text', numeric: false, disablePadding: false, label: 'First Name' },
    { id: 'lastName', type: 'text', numeric: false, disablePadding: false, label: 'Last Name' },
    { id: 'userName', type: 'text', numeric: false, disablePadding: false, label: 'User Name' },
    { id: 'email', type: 'text', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'roleName', type: 'text', numeric: false, disablePadding: false, label: 'Role' },
    { id: 'createdOn', type: 'createdOn', numeric: false, disablePadding: false, label: 'Created On' },
    { id: 'status', type: 'text', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];

const UserObj = {
    "id": "",
    "firstName": "",
    "lastName": "",
    "userName": "",
    "userId": "",
    "email": "",
    "mobile": null,
    "encyptedPassword": "",
    "roleName": [],
    "status": "ACTIVE",
    "roleId": [],
    "password": "",
    "showPassword": false,
    "confirmPassword": "",
    "showConfirmPassword": false
}

const filterCells = [
    { id: 'firstName', type: 'text', label: 'First Name' },
    { id: 'lastName', type: "text", label: "Last Name"},
    { id: 'userName', type: "text", label: "User Name"},
    { id: 'email', type: "text", label: "Email Address"}
  ];
  
const filterobj = {
    email: "",
    // pageNo: 1,
    // pageSize: 10,
    lastName: "",
    userName: "",
    firstName: "",
    // sortBy:"createdOn",
    // sortDirection:"DESC",
}

//const sortArr = [
    // {key:"createdOn", label: "Create Date"},
    // {key:"firstName", label: "First Name"},
    // {key:"lastName", label: "Last Name"},
    // {key:"userName", label: "User Name"},
    // {key:"roleName", label: "Role Name"},
    // {key:"email", label: "Email Address"},
    // {key:"status", label: "Status"},
//];

const User = (props) => {
    const {classes} = useStyles();
    const { userList, roleList, createUserRes, userLoader, changePasswordRes} = props;

    // const [pageNo, setPage] = useState(1);
    // const [sortDir, setsortDir] = useState('DESC');
    const [filterData, setFilterData] = useState([]);
    const [clientRow, setClientRow] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [userState, setUserState] = useState(UserObj);
    const [snackbarMsg, setSnackBarMsg] = useState(null);
    // const [sortName, setsortName] = useState('createdOn');
    const [openPopover, setopenPopover] = useState(false);
    const [currentForm, setCurrentForm] = useState("list");
    const [openSnackbar, SetOpenSnackbar] = useState(false);
    const [compInitaited, setCompInitiated] = useState(false);
    const [filterState, setFilterState] = useState(filterobj);
    const [passIncUserName, setPassIncUserName] = React.useState(false);
    const [cnfPassIncUserName, setcnfPassIncUserName] = React.useState(false);
    
    const currentPage = localStorage.getItem('currentPage');
    let permissions = localStorage.getItem("permissions")
    permissions = permissions && permissions !== null ? JSON.parse(permissions) : []

    const createDisableToken = currentForm !== "view" && userState && userState.roleName !== "" && 
        userState.firstName !== "" && userState.lastName !== "" && userState.userName !== "" && usernameRegex.test(userState.userName) && 
        userState.email !== "" && userState.password !== "" &&  userState.confirmPassword !== "" && 
        passRegExp.test(userState.password) && passRegExp.test(userState.confirmPassword) && 
        userState.password === userState.confirmPassword && SpecialCharRegex1.test(userState.firstName) && 
        SpecialCharRegex1.test(userState.lastName) && EmailRegex.test(userState.email) && 
        (userState.mobile === null || userState.mobile === "" || numbRegex.test(userState.mobile))

    const editDisableToken = currentForm !== "view" && userState && userState.roleName !== "" && userState.firstName !== "" && 
        userState.lastName !== "" && userState.userName !== "" && userState.email !== "" && SpecialCharRegex1.test(userState.firstName) && 
        SpecialCharRegex1.test(userState.lastName) && EmailRegex.test(userState.email) && (userState.mobile === null || userState.mobile === "" || numbRegex.test(userState.mobile))

    const userArray = userList && userList !== null ? 
        userList.map(obj => {

            let userObj = {
                ...obj,
                createdOn: obj.createdOn && obj.createdOn,
                //  !== "" ? `${obj.createdOn.slice(0,10)} ${obj.createdOn.slice(11,19)}` : "",
                searchColumn: headCells.map((cell, index) => 
                    cell.id === "createdOn" && obj.createdOn && obj.createdOn !== "" ? 
                        `${obj.createdOn.slice(0,10)} ${obj.createdOn.slice(11,19)}`
                        : cell.id !== "action" &&  obj[cell.id] &&  obj[cell.id] !== "" ?  
                            obj[cell.id] : "" 
                ).join(" ")
            }
            return userObj
        }) : []
    
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    });
        
    const onChangeView = (inpval, row) => {
        setCurrentForm(inpval);
        setPassIncUserName(false);
        setcnfPassIncUserName(false);
        if(inpval === "view") {
            setClientRow({...row, password: "********"})
        } else if(inpval === "edit") {
            setUserState({
                ...row,
                "password": "********",
                "showPassword": false,
                "confirmPassword": "",
                "showConfirmPassword": false
            })
            //fetch details for edit drop down
            props.fetchRoleDetails({})
    
        } else if(inpval === "create") {
            setUserState(UserObj)
            props.fetchRoleDetails({})
        
        } else {
            props.fetchUserDetails({...filterState,
                // pageNo: 1, sortBy: sortName, sortDirection: sortDir
            })
        }
    }

    useEffect(() => {
        if(currentPage && currentPage !== null && currentPage !== undefined && currentPage === "/users" && compInitaited){
            setCurrentForm('list')
            setFilterState({...filterobj});
            // setPage(1);
            // setsortDir("DESC");
            // setsortName("createdOn")
            // props.fetchUserDetails({
            //     // pageNo: 1, pageSize: 10,
            //     // sortBy:"createdOn", sortDirection:"DESC"
            // })
            localStorage.removeItem('currentPage')
        }
    }, [currentPage])

    useEffect(() => {
        const handleInitialCall = async () => {
            await setCompInitiated(true);
            await props.fetchUserDetails({...filterState,
                // pageNo: pageNo, sortBy: sortName, sortDirection: sortDir
            });
            await localStorage.removeItem('currentPage')
        }
        handleInitialCall();

        return () => {
            //add all list reset API here
            props.resetRoleDetails()
        }
    }, [])

    useEffect(() => {
        if(createUserRes && createUserRes !== null && createUserRes.st) {
            if(currentForm === "create" ) {
                    setopenPopover(true)
            } else {
                SetOpenSnackbar(true)
                setSnackBarMsg(`User Modified Successfully!`)
                onChangeView("list")
            }
            //reset user response
            props.resetUserCreateResp()
        } else if(createUserRes && createUserRes !== null && !createUserRes.st) {
            SetOpenSnackbar(true)
            setSnackBarMsg(createUserRes.msg && createUserRes.msg)
            onChangeView("list")
            //reset user response
            props.resetUserCreateResp()
        }
    }, [createUserRes])

    const handleInputChange = (key, value) => {

        let updatedUserState = {...userState};
        if(key === "role") {
      
            updatedUserState = {
              ...updatedUserState,
              roleName: value[0],
              roleId: value[1],
            }
        } else {
            updatedUserState = {
                ...updatedUserState,
                [key]: value,
            }

            if(key === "password" && userState['userName'] && userState['userName'] !== "") {
                const userArr =  [...(userState['userName'].toUpperCase()).split(" ")]
                let flag = false;
                  
                userArr.map(name => {
                  if((value.toUpperCase()).includes(name) && !flag) {
                    flag = true
                  }
                  return null;
                })

                const str = userState['userName'].toUpperCase();
                const str1 = value.toUpperCase();
                let i = 0;
                while (i < str.length) {
                    if(str1.includes(`${str}${str}`.slice(i, (i+4)))
                    ) {
                        flag = true
                        break;
                    }
                    i++;
                }

                setPassIncUserName(flag)
            } else if (key === "confirmPassword" && userState['userName'] && userState['userName'] !== "") {
                const userArr =  [...(userState['userName'].toUpperCase()).split(" ")]
                let flag = false;
                  
                userArr.map(name => {
                  if(((value).toUpperCase()).includes(name) && !flag) {
                    flag = true
                  }
                  return null;
                })
                
                const str = userState['userName'].toUpperCase();
                const str1 = value.toUpperCase();
                let i = 0;
                while (i < str.length) {
                    if(str1.includes(`${str}${str}`.slice(i, (i+4)))
                    ) {
                        flag = true
                        break;
                    }
                    i++;
                }

                setcnfPassIncUserName(flag)
            }

        }

        setUserState(updatedUserState)
    }

    const handleClickShowPassword = (key) => {
        if(key === "password") {
            setUserState({ ...userState, showPassword: !userState.showPassword });
        } else if(key === "confirmPassword") {
            setUserState({ ...userState, showConfirmPassword: !userState.showConfirmPassword });
        }
    };

    const handleUserCreateUpdate = () => {
        let req = {
            ...userState,
            status: userState.status === "ACTIVE" ? 0 : userState.status === "BLOCKED" ? 1 : 2
        }
        delete req["encyptedPassword"];
        delete req["showPassword"];
        delete req["showConfirmPassword"];

        props.createOrEditUser(req)
    }

    const handleSnackBarAction = () => {
        SetOpenSnackbar(false)
        setSnackBarMsg(null)
    }

    const handleDialogAction = async (type) => {
        setopenPopover(false)
        setUserState(UserObj)
        if(type === "exit" ){
          onChangeView('list')
        } else {
          await onChangeView('list')
          await onChangeView('create')
        }
    }
    
    const handleSetFilterData = (filterArray, searchValue) => {
        setFilterData(filterArray)
        setSearchValue(searchValue)
    }

    const handleFilterStateChange = (key, value) => {
        let tempFilter = {
            ...filterState,
            [key]: value
        }
        setFilterState(tempFilter);
    }
    
    const resetFilter = () => {
        setFilterState(filterobj);
        // setPage(1);
        // setsortDir("DESC");
        // setsortName("createdOn");
        const payloadObj = {
            // pageNo: 1,
            // pageSize: 10,
            // sortBy:"createdOn",
            // sortDirection:"DESC"
        }
        props.fetchUserDetails(payloadObj)
    }

    const FilterApply = () => {
        // setPage(1)
        const payloadObj = { ...filterState, 
            // pageNo: 1, sortBy: sortName, sortDirection: sortDir
        }
        props.fetchUserDetails(payloadObj)
        setFilterState({...filterState});
    }

    // const handlePageNo = (val) => {
    //     setPage(val)
    //     const payloadObj = { ...filterState, pageNo: val, sortBy: sortName, sortDirection: sortDir}
    //     props.fetchUserDetails(payloadObj)
    // }

    // const handleSortDir = (value) => {
    //     setsortDir(value)
    //     setPage(1)
    // }

    // const handleSortName = (value) => {
    //     setsortName(value)
    //     setPage(1)
    // }

    return (
        <>
            <BasePageToolbar currentForm={currentForm}  create={permissions.includes("28")} onChangeView={onChangeView} resetFilter={resetFilter} 
                title={currentForm === 'list' ? "Users" :  currentForm === 'view' ? "View User" : currentForm === "edit" ? "Modify User" : "Create User"}
                handleFilterStateChange={handleFilterStateChange} filterState={filterState} filterCells={filterCells} FilterApply={FilterApply}
                // setsortName={handleSortName} setsortDir={handleSortDir} sortDir={sortDir} sortName={sortName} sortArr={sortArr}
                arrayToFilter={userArray} handleSetFilterData={handleSetFilterData} isTableSearchReq={false} searchValue={searchValue}
            ></BasePageToolbar>
            <BasePageContainer>
                {currentForm === 'list' ?
                <Grid container className={classes.container}>
                <TableComponent
                    sortOrder="desc" sortOrderBy="createdOn" type={"user"}
                    onChangeView={onChangeView} 
                    // handlePageNo={handlePageNo} totalRecord={totalRecords}
                    showEdit={permissions.includes("29")} 
                    rows={searchValue && searchValue !== "" ? filterData : userArray}
                    loading={userLoader} activeTab={filterState} headCells={headCells}
                />
                </Grid>
                : currentForm === 'create' ?
                <Grid container className={classes.formContainer}>
                    <CreateEditViewUser 
                        currentForm={currentForm}
                        userState={userState}
                        roleList={roleList}
                        passIncUserName={passIncUserName}
                        handleInputChange={handleInputChange}
                        cnfPassIncUserName={cnfPassIncUserName}
                        createDisableToken={!createDisableToken}
                        handleUserCreateUpdate={handleUserCreateUpdate}
                        handleClickShowPassword={handleClickShowPassword}
                    />
                </Grid>
                : currentForm === "edit" ?
                <Grid container className={classes.formContainer}>
                    <CreateEditViewUser 
                        currentForm={currentForm} 
                        userState={userState}
                        roleList={roleList}
                        handleInputChange={handleInputChange}
                        createDisableToken={!editDisableToken}
                        handleUserCreateUpdate={handleUserCreateUpdate}
                        handleClickShowPassword={handleClickShowPassword}
                        changePasswordRes={changePasswordRes}
                        resetUserPassword={props.resetUserPassword}
                        resetChangePasswordResp={props.resetChangePasswordResp}
                    />
                </Grid> :
                    <Grid container className={classes.formContainer}>
                        <CreateEditViewUser 
                            currentForm={currentForm} 
                            userDetails={clientRow}
                            onChangeView={onChangeView}
                        />
                    </Grid>
                }

                <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => handleSnackBarAction()}
                action={
                    <React.Fragment>
                        <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
                        <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
                >
                    <Alert onClose={() => handleSnackBarAction()} severity={
                        createUserRes && createUserRes !== null && !createUserRes.st ? "error" : "success"
                    }>
                        {snackbarMsg}
                    </Alert>
                </Snackbar>
                <Dialog
                    open={openPopover}
                    onClose={() => handleDialogAction("exit")}
                    className={"delete_dialog"}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="close_dialog" onClick={() => handleDialogAction("exit")}><img src={CloseImgIcon} alt="close" /></div>
                    <DialogTitle id="alert-dialog-title">
                        <h2>{"User Created Successfully!"}</h2>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`Do you want to Create new User?`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" className="yes" autoFocus
                            onClick={() => handleDialogAction("create")} 
                        >
                            Create New
                        </Button>
                        <Button color="primary" className="no"
                            onClick={() => handleDialogAction("exit")} 
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </BasePageContainer>
        </>
    )
}

const useStyles = tss.create({
    formContainer: {
        paddingTop: theme.spacing(0),
    },
    container: {
        paddingTop: theme.spacing(2),
    },
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            fetchUserDetails, createOrEditUser,
            resetUserCreateResp, fetchRoleDetails,
            resetRoleDetails, resetUserPassword,
            resetChangePasswordResp
        }, dispatch)
    }
}

function mapStateToProps(state) {
  return {
    userList: state.UserReducer.userList !== null && state.UserReducer.userList.webUserList ? 
        state.UserReducer.userList.webUserList : [],
    totalRecords: state.UserReducer.userList !== null && state.UserReducer.userList.totalRecords ? 
        state.UserReducer.userList.totalRecords : 0,
    roleList: state.UserReducer.roleList !== null && state.UserReducer.roleList.rolesList ? 
        state.UserReducer.roleList.rolesList : [],
    createUserRes: state.UserReducer.createUserRes,
    userLoader: state.UserReducer.userLoader,
    changePasswordRes: state.UserReducer.changePasswordRes
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(User);
