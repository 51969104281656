import { actionCreator, BASE_URL } from "../../shared/utility";
import { CountryActions } from "../consts/index";

export function fetchCountryList(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(CountryActions.fetchCountryList.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/getCountryList`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(CountryActions.fetchCountryList.FAILURE))
                }
            } else {
                dispatch(actionCreator(CountryActions.fetchCountryList.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function createUpdateCountry(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(CountryActions.createUpdateCountry.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/addOrEditCountry`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(CountryActions.createUpdateCountry.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(CountryActions.createUpdateCountry.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export const resetCountryCreateResp = () => {
    return(dispatch) => {
        dispatch(actionCreator(CountryActions.createUpdateCountry.FAILURE))
    }
}

export function fetchMasterCountryList(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(CountryActions.fetchMasterCountryList.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/getMasterCountryList`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(CountryActions.fetchMasterCountryList.FAILURE))
                }
            } else {
                dispatch(actionCreator(CountryActions.fetchMasterCountryList.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}