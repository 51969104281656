import React, {useState} from 'react'
import {
    Grid, MenuItem, Button, TextField, Paper
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const UploadProductFile = (props) => {

    const { 
        classes, countryArr, countryArrObj, merchantArr, merchantList, uploadFileState, handleUploadProductFileChange, submitProductFileUpload, disableUploadProductFile
    } = props
    const [errObj, setErrObj] = useState({
        countryErr: false, merchantErr: false, modeErr: false
    })

    const handleChange = (key, errKey, value) => {

        if(key === "country") {
            countryArr.indexOf(value) !== -1 && handleUploadProductFileChange(key, [
                countryArrObj[countryArr.indexOf(value)]['isoCountryCodeNum'],
                value
            ])
            setErrObj({...errObj, [errKey]: false })
        } else if(key === "merchant") {
            merchantArr.indexOf(value) !== -1 && handleUploadProductFileChange(key, [
                merchantList[merchantArr.indexOf(value)]['id'],
                value
            ])
            setErrObj({...errObj, [errKey]: false })
        } else {
            handleUploadProductFileChange(key, value)
            setErrObj({...errObj, [errKey]: false })
        }
    }

    const handleOnBlur = (field, value) => {
        if(value === null || value === ""){
            setErrObj({...errObj, [field]: true })
        } else {
            setErrObj({...errObj, [field]: false })
        }
    }

    return (
        <Paper className={classes.paper}>
            <form className="action_form merchant_form" noValidate autoComplete="off">
                <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            InputLabelProps={{ shrink: true }} 
                            variant="outlined" select required fullWidth
                            id='uploadProductFile-country' name='country' label='Country'
                            value={uploadFileState && uploadFileState.country && uploadFileState.country}
                            onBlur={(e) => handleOnBlur("countryErr", e.target.value)}
                            onChange={(e) => countryArr.length > 0 && handleChange('country', 'countryErr', e.target.value)}
                        >
                            {countryArr && countryArr.length === 0 && 
                            <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                            {countryArr && countryArr.map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                            })}
                        </TextField>
                        {errObj.countryErr ? 
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                        </p>    :   null}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            InputLabelProps={{ shrink: true }} 
                            variant="outlined" select required fullWidth
                            id='uploadProductFile-merchant' name='merchant' label='Merchant'
                            value={uploadFileState && uploadFileState.merchant && uploadFileState.merchant}
                            onBlur={(e) => handleOnBlur("merchantErr", e.target.value)}
                            onChange={(e) => merchantArr.length > 0 && handleChange('merchant', 'merchantErr', e.target.value)}
                        >
                            {merchantArr && merchantArr.length === 0 && 
                            <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                            {merchantArr && merchantArr.map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                            })}
                        </TextField>
                        {errObj.merchantErr ? 
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                        </p>    :   null}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            InputLabelProps={{ shrink: true }} 
                            variant="outlined" select required fullWidth
                            id='uploadProductFile-mode' name='mode' label='Mode'
                            value={uploadFileState && uploadFileState.mode && uploadFileState.mode}
                            onBlur={(e) => handleOnBlur("modeErr", e.target.value)}
                            onChange={(e) => handleChange('mode', 'modeErr', e.target.value)}
                        >
                            {["CREATE", "MODIFY"].map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                            })}
                        </TextField>
                        {errObj.modeErr ? 
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                        </p>    
                        :   <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message" style={{color: "#33C4FF"}}><InfoOutlinedIcon /> {"CREATE: To upload file with new mappings only, MODIFY: To upload file after modifying existing mappings"}</span>
                            </p>}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            InputLabelProps={{ shrink: true }} 
                            variant="outlined" required fullWidth type='file'
                            id='upload' name='upload' label='Choose Product File'
                            accept={".xlsx, .docs, .doc"}
                            onChange={(e) => handleUploadProductFileChange("upload", e.target.files)}
                        >  
                        </TextField>
                    </Grid>
                </Grid>

                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Button fullWidth variant="contained"
                            color="primary" className={"action_login_button"}
                            disabled={disableUploadProductFile}
                            onClick={() => submitProductFileUpload()}
                        >
                            {"Submit"}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    )
}

export default UploadProductFile