import { ProgramActions } from "../consts/index";

const initialState = {
    programListDetails: null,
    programDetails: null,
    country: null,
    programType: null,
    startDate: null,
    endDate: null,
    programName: null,
    programDescription: null,
    brand: null,
    isDefault: false,
    showCrossBorder: false,
    schemes: null,
    countryListDetails: null,
    programTypeListDetails: null,
    brandSchemeListDetails: null,
    bankSchemeListDetails: null,
    brandListDetails: null,
    issuerCountryListDetails: null,
    createProgramRes: null,
    programLoader: null,
}

export default (state = initialState, {type, payload}) => {
    switch(type) {
        case ProgramActions.handleProgramDetailsFetchForProgram.REQUEST:
            return {
                ...state,
                programLoader: true,
                programListDetails: null,
            }
        case ProgramActions.handleProgramDetailsFetchForProgram.SUCCESS:
            return {
                ...state,
                programLoader: false,
                programListDetails: payload,
            }
        case ProgramActions.handleProgramDetailsFetchForProgram.FAILURE:
            return {
                ...state,
                programLoader: false,
                programListDetails: null,
            }
        case ProgramActions.handleIndividualProgramDetailsFetchForProgram.REQUEST:
            return {
                ...state,
                programLoader: true,
                programDetails: null,
            }
        case ProgramActions.handleIndividualProgramDetailsFetchForProgram.SUCCESS:
            return {
                ...state,
                programLoader: false,
                programDetails: payload,
            }
        case ProgramActions.handleIndividualProgramDetailsFetchForProgram.FAILURE:
            return {
                ...state,
                programLoader: false,
                programDetails: null,
            }
        case ProgramActions.handleCountryChangeForProgram.SUCCESS:
            return {
                ...state,
                country: payload,
            }
        case ProgramActions.handleProgramTypeChangeForProgram.SUCCESS:
            return {
                ...state,
                programType: payload,
            }
        case ProgramActions.handleStartDateChangeForProgram.SUCCESS:
            return {
                ...state,
                startDate: payload,
            }
        case ProgramActions.handleEndDateChangeForProgram.SUCCESS:
            return {
                ...state,
                endDate: payload,
            }
        case ProgramActions.handleProgramNameChangeForProgram.SUCCESS:
            return {
                ...state,
                programName: payload,
            }
        case ProgramActions.handleDescriptionChangeForProgram.SUCCESS:
            return {
                ...state,
                programDescription: payload,
            }
        case ProgramActions.handleBrandChangeForProgram.SUCCESS:
            return {
                ...state,
                brand: payload,
            }
        case ProgramActions.handleIsDefaultChangeForProgram.SUCCESS:
            return {
                ...state,
                isDefault: payload,
            }
        case ProgramActions.handleShowCrossBorderChangeForProgram.SUCCESS:
            return {
                ...state,
                showCrossBorder: payload,
            }
        case ProgramActions.handleSchemesChangeForProgram.SUCCESS:
            return {
                ...state,
                scheme: payload,
            }
        case ProgramActions.handleCountryDetailsFetchForProgram.REQUEST:
            return {
                ...state,
                programLoader: true,
                countryListDetails: null,
            }
        case ProgramActions.handleCountryDetailsFetchForProgram.SUCCESS:
            return {
                ...state,
                programLoader: false,
                countryListDetails: payload,
            }
        case ProgramActions.handleCountryDetailsFetchForProgram.FAILURE:
            return {
                ...state,
                programLoader: false,
                countryListDetails: null,
            }
        case ProgramActions.handlePtogramTypeDetailsFetchForProgram.REQUEST:
            return {
                ...state,
                programLoader: true,
                programListDetails: null,
            }
        case ProgramActions.handlePtogramTypeDetailsFetchForProgram.SUCCESS:
            return {
                ...state,
                programLoader: false,
                programListDetails: payload,
            }
        case ProgramActions.handlePtogramTypeDetailsFetchForProgram.FAILURE:
            return {
                ...state,
                programLoader: false,
                programListDetails: null,
            }
            
        case ProgramActions.handleSchemeDetailsFetchForProgram.REQUEST:
            return {
                ...state,
                programLoader: true,
                brandSchemeListDetails: null,
            }
        case ProgramActions.handleSchemeDetailsFetchForProgram.SUCCESS:
            return {
                ...state,
                programLoader: false,
                brandSchemeListDetails: payload,
            }
        case ProgramActions.handleSchemeDetailsFetchForProgram.FAILURE:
            return {
                ...state,
                programLoader: false,
                brandSchemeListDetails: null,
            }
        case ProgramActions.handleBankSchemeDetailsFetchForProgram.REQUEST:
            return {
                ...state,
                programLoader: true,
                bankSchemeListDetails: null,
            }
        case ProgramActions.handleBankSchemeDetailsFetchForProgram.SUCCESS:
            return {
                ...state,
                programLoader: false,
                bankSchemeListDetails: payload,
            }
        case ProgramActions.handleBankSchemeDetailsFetchForProgram.FAILURE:
            return {
                ...state,
                programLoader: false,
                bankSchemeListDetails: null,
            }
        case ProgramActions.handleBrandDetailsFetchForProgram.REQUEST:
            return {
                ...state,
                programLoader: true,
                brandListDetails: null,
            }
        case ProgramActions.handleBrandDetailsFetchForProgram.SUCCESS:
            return {
                ...state,
                programLoader: false,
                brandListDetails: payload,
            }
        case ProgramActions.handleBrandDetailsFetchForProgram.FAILURE:
            return {
                ...state,
                programLoader: false,
                brandListDetails: null,
            }
        case ProgramActions.handleIssuerCountryDetailsFetchForProgram.REQUEST:
            return {
                ...state,
                programLoader: true,
                issuerCountryListDetails: null,
            }
        case ProgramActions.handleIssuerCountryDetailsFetchForProgram.SUCCESS:
            return {
                ...state,
                programLoader: false,
                issuerCountryListDetails: payload,
            }
        case ProgramActions.handleIssuerCountryDetailsFetchForProgram.FAILURE:
            return {
                ...state,
                programLoader: false,
                issuerCountryListDetails: null,
            }
        case ProgramActions.handleCreateOrEditProgramSubmit.REQUEST: 
            return {
                ...state,
                programLoader: true,
                createProgramRes: null,
            }
        case ProgramActions.handleCreateOrEditProgramSubmit.SUCCESS: 
            return {
                ...state,
                programLoader: false,
                createProgramRes: payload,
            }
        case ProgramActions.handleCreateOrEditProgramSubmit.FAILURE: 
            return {
                ...state,
                programLoader: false,
                createProgramRes: payload,
            }

        default:
            return state;
    }
}