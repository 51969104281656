import { ClientLogAction } from "../consts/index";

const initialState = {
    logLevelData: null,
    clientLogList: null,
    directoryPath: null,
    dirPathLoader: false,
    clientLogLoader: false,
    createClientLogRes: null,
}

export default (state = initialState, {type, payload}) => {
    switch(type) {
        case ClientLogAction.fetchClientLogDetails.REQUEST:
            return {
                ...state,
                clientLogLoader: true
            }
        case ClientLogAction.fetchClientLogDetails.SUCCESS:
            return {
                ...state,
                clientLogLoader: false,
                clientLogList: payload
            }
        case ClientLogAction.fetchClientLogDetails.FAILURE:
            return {
                ...state,
                clientLogLoader: false,
                clientLogList: null,
            }
        
        case ClientLogAction.fetchDirectoryPath.REQUEST:
            return {
                ...state,
                dirPathLoader: true,
                directoryPath: null
            }
        case ClientLogAction.fetchDirectoryPath.SUCCESS:
            return {
                ...state,
                dirPathLoader: false,
                directoryPath: payload
            }
        case ClientLogAction.fetchDirectoryPath.FAILURE:
            return {
                ...state,
                dirPathLoader: false,
                directoryPath: payload,
            }

        case ClientLogAction.fetchLogLevel.REQUEST:
            return {
                ...state,
                logLevelData: null
            }
        case ClientLogAction.fetchLogLevel.SUCCESS:
            return {
                ...state,
                logLevelData: payload
            }
        case ClientLogAction.fetchLogLevel.FAILURE:
            return {
                ...state,
                logLevelData: null,
            }
        
        case ClientLogAction.addOrEditClientLog.REQUEST:
            return {
                ...state,
            }
        case ClientLogAction.addOrEditClientLog.SUCCESS:
            return {
                ...state,
                createClientLogRes: payload
            }
        case ClientLogAction.addOrEditClientLog.FAILURE:
            return {
                ...state,
                createClientLogRes: payload,
            }
            
        default:
            return state;
    }
}