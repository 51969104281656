import React, { useState, useEffect } from 'react'
import {connect} from "react-redux";
import { bindActionCreators } from "redux";
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { 
    Grid, IconButton, Snackbar, Dialog, DialogActions,
    DialogTitle, DialogContentText, DialogContent, Button 
} from '@mui/material'
import { tss } from "tss-react";
import theme from '../_theme'
import format from "date-fns/format";

import CloseImgIcon from '../Assets/close.svg';
import BasePageContainer from '../_common/basePageContainer'
import BasePageToolbar from '../_common/basePageToolbar'
import TableComponent from "./table/index"
import CreateEditViewMCI from "./createEditViewMCI";
import { 
    fetchMciList, createOrEditMci,
    resetMciCreateOrEditResp, deleteMci,
    resetMciDeleteResp, fetchFileTypeIdList,
    fetchTemplateIdList, 
    fetchMCIProgramTypesList, fetchMCIPaymentModelTypesList,
    fetchMCIProductTypesList,
} from "./actions/index"
import { numbRegex } from "../shared/utility";
import { addDays } from 'date-fns';

const headCells = [
    { id: 'id', type: 'text', numeric: true, disablePadding: false, label: 'Report Id' },
    { id: 'mciId', type: 'text', numeric: true, disablePadding: false, label: 'Issuer Id' },
    { id: 'fileTypeId', type: 'numeric', numeric: true, disablePadding: false, label: 'File Type' },
    { id: 'isPLManaged', type: 'boolean', numeric: false, disablePadding: false, label: 'PL Managed' },
    { id: 'createFileBasedOnIdentifierValue', type: 'text', numeric: true, disablePadding: false, label: 'Identifier Value' },
    { id: 'installmentProductType', type: 'text', numeric: false, disablePadding: false, label: 'Installment Product Type' },
    { id: 'monthendFileDownloadTimeInUtc', type: 'text', numeric: false, disablePadding: false, label: 'Monthend File Download Time' },
    { id: 'monthendFileUploadTimeInUtc', type: 'text', numeric: false, disablePadding: false, label: 'Monthend File Upload Time' },
    { id: 'rowInsertionDateTime', type: 'createdOn', numeric: false, disablePadding: false, label: 'Created On' },
    { id: 'creationTimeInUtc', type: 'createdOn', numeric: false, disablePadding: false, label: 'Creation Time' },
    { id: 'uploadTimeInUtc', type: 'createdOn', numeric: false, disablePadding: false, label: 'Upload Time' },
    { id: 'status', type: 'text', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];

const MciObj = {
    "id": null,
    "mciId": 0,
	"issuerIca": 0,
	"regionId": "1",
	"installmentProgramType": "0",
    "installmentProgramTypeName": "",
	"installmentProductType": "",
	"paymentModelType": "",
    "paymentModelTypeName": "",
	"creationTimeInUtc": "",
	"uploadTimeInUtc": "",
	"fileTypeId": "",
	"isAcknowledgementRequireq": true,
	"isBlankFileUploadAllowed": true,
	"formattedFileId": "3",
	"headerId": "1",
	"footerId": "1",
	"fileCreationServiceInstanceId": "1",
	"excelFileTemplateId": "",
    "templateFormat": "",
	"monthendFileDownloadTimeInUtc": null,
	"monthendFileUploadTimeInUtc": null,
	"isSftpUploadEnabled": false,
	"createFileBasedOnIdentifier": "NA",
	"createFileBasedOnIdentifierValue": "0",
    "secondaryIdentifier": "",
    "secondaryIdentifierValue": "",
    "isActive": true,
    "createFileFrequency": "1",
    "isSmtpUploadEnabled": false,
    "isPLManaged": false
}

const filterCells = [
    { id: 'id', type: 'text', label: 'Report Id' },
    { id: 'mciId', type: 'text', label: 'Issuer Id' },
    // { id: 'issuerIca', type: 'text', label: 'Issuer Ica' },
    { id: 'createFileBasedOnIdentifierValue', type: 'text', label: 'Create File Based On Identifier Value' },
    { id: 'installmentProductType', type: 'select', label: 'Installment Product Type'},
    { id: 'paymentModelType', type: 'select', label: 'Payment Model'},
    { id: 'installmentStartDate', type: 'date', label: 'Start Date' },
    { id: 'installmentEndDate', type: 'date', label: 'End Date' },
    { id: 'status', type: 'select', label: 'Status' },
  ];
  
  const filterobj = {
    id: "",
    mciId: "",
    issuerIca: "",
    status: null,
    pageNo: 1,
    pageSize: 10,
    sortBy:"rowInsertionDateTime",
    sortDirection:"DESC",
    installmentStartDate: null,
    installmentEndDate: null,
    createFileBasedOnIdentifierValue: "",
    installmentProductType: null,
    paymentModelType: null
  }
  
  const sortArr = [
    {key:"rowInsertionDateTime", label: "Create Date"},
    {key:"id", label: "Report Id"},
    {key:"mciId", label: "Issuer Id"},
    {key:"issuerIca", label: "Issuer Ica"},
    {key:"installmentProductType", label: "Product Type"},
  ]

  const BLANK_PAYMENT_MODEL = {
    id: 0,
    description: "Blank",
  }

const MCI = (props) => {
    const {classes} = useStyles();
    const { 
        mciList, mciCreateRes, mciDeleteRes, mciLoader, mciCreateLoader, mciDeleteLoader, 
        fileTypeList, fileTemplateList, programTypeList,
        paymentModelTypeList, productTypeList, totalRecord
    } = props;

    const [currentForm, setCurrentForm] = useState("list");
    const [clientRow, setClientRow] = useState(null);
    const [mciState, setMciState] = useState(MciObj)
    const [snackbarMsg, setSnackBarMsg] = useState(null);
    const [openSnackbar, SetOpenSnackbar] = useState(false);
    const [openPopover, setopenPopover] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const currentPage = localStorage.getItem('currentPage')
    const [compInitaited, setCompInitiated] = useState(false);
    const [deleteReqRow, setDelReqRow] = useState(null)
    const [delConfrimOpen, setDelConfrimOpen] = useState(false)
    const [deleteAlert, openDeleteAlert] = useState(false)
    const [alertMsg, setAlertMsg] = useState(null)
    const [filterState, setFilterState] = useState(filterobj)
    const [pageNo, setPage] = useState(1)
    const [sortName, setsortName] = useState('rowInsertionDateTime')
    const [sortDir, setsortDir] = useState('DESC');

    let permissions = localStorage.getItem("permissions")
    permissions = permissions && permissions !== null ? JSON.parse(permissions) : []

    const createDisableToken = currentForm !== "view" && mciState && mciState.mciId !== null && mciState.mciId !== "" && numbRegex.test(mciState.mciId) &&  
        ((mciState.createFileBasedOnIdentifier !== "ica_id" && (mciState.issuerIca === null || mciState.issuerIca === "" || (numbRegex.test(mciState.issuerIca)))) || 
        (mciState.issuerIca !== null && mciState.issuerIca !== "" && numbRegex.test(mciState.issuerIca))) &&
        mciState.installmentProductType !== null && mciState.installmentProductType !== "" &&
        mciState.fileTypeId !== null && mciState.fileTypeId !== "" && mciState.formattedFileId !== "" &&
        mciState.fileCreationServiceInstanceId !== null && mciState.fileCreationServiceInstanceId !== "" && numbRegex.test(mciState.fileCreationServiceInstanceId) && mciState.headerId !== null && mciState.headerId !== "" && numbRegex.test(mciState.headerId) && 
        mciState.footerId !== null && mciState.footerId !== "" && numbRegex.test(mciState.footerId) && mciState.templateFormat !== null && mciState.templateFormat !== "" && 
        mciState.createFileBasedOnIdentifierValue !== null && mciState.createFileBasedOnIdentifierValue !== "" && 
        mciState.monthendFileDownloadTimeInUtc !== null && mciState.monthendFileDownloadTimeInUtc !== "" && mciState.monthendFileUploadTimeInUtc !== null && mciState.monthendFileUploadTimeInUtc !== "" &&
        mciState.creationTimeInUtc !== null && mciState.creationTimeInUtc !== "" && mciState.uploadTimeInUtc !== null && mciState.uploadTimeInUtc !== "" &&
        (mciState.regionId && mciState.regionId !== "" ? numbRegex.test(mciState.regionId): true) && mciState.createFileFrequency !== null && mciState.createFileFrequency !== "" && numbRegex.test(mciState.createFileFrequency)
    
    const createEOMDisableToken = currentForm !== "view" && mciState && mciState.mciId !== null && mciState.mciId !== "" && numbRegex.test(mciState.mciId) &&
        ((mciState.createFileBasedOnIdentifier !== "ica_id" && (mciState.issuerIca === null || mciState.issuerIca === "" || (numbRegex.test(mciState.issuerIca)))) ||
        (mciState.issuerIca !== null && mciState.issuerIca !== "" && numbRegex.test(mciState.issuerIca))) &&
        mciState.installmentProductType !== null && mciState.installmentProductType !== "" &&
        mciState.fileTypeId !== null && mciState.fileTypeId !== "" && mciState.formattedFileId !== "" &&
        mciState.fileCreationServiceInstanceId !== null && mciState.fileCreationServiceInstanceId !== "" && numbRegex.test(mciState.fileCreationServiceInstanceId) && mciState.headerId !== null && mciState.headerId !== "" && numbRegex.test(mciState.headerId) &&
        mciState.footerId !== null && mciState.footerId !== "" && numbRegex.test(mciState.footerId) && mciState.templateFormat !== null && mciState.templateFormat !== "" && 
        mciState.createFileBasedOnIdentifierValue !== null && mciState.createFileBasedOnIdentifierValue !== "" &&
        mciState.creationTimeInUtc !== null && mciState.creationTimeInUtc !== "" && mciState.uploadTimeInUtc !== null && mciState.uploadTimeInUtc !== "" &&
        (mciState.regionId && mciState.regionId !== "" ? numbRegex.test(mciState.regionId) : true) && mciState.createFileFrequency !== null && mciState.createFileFrequency !== "" && numbRegex.test(mciState.createFileFrequency)
    
    const mciArr = mciList && mciList !== null ? 
        mciList.map(obj => {

            let countryObj = {
                ...obj,
                status: obj.isActive !== null && obj.isActive === true ? "ACTIVE" : obj.isActive !== null && obj.isActive === false ? "INACTIVE" : null,
                searchColumn: headCells.map((cell, index) => 
                    cell.id !== "action" &&  obj[cell.id] &&  obj[cell.id] !== "" ?  obj[cell.id] : "" 
                ).join(" ")
            }
            return countryObj
        }) : []

    let fileTemplateIdArr = [];
    const fileTemplateArr = fileTemplateList && fileTemplateList.length > 0 ?
        fileTemplateList.map(obj => {
            fileTemplateIdArr.push(obj.templateId)
            return obj.templateFormat
        }) : []

    let programTypeIdArr = [];
    const programTypeArr = programTypeList && programTypeList.length > 0 ?
        programTypeList.map(obj => {
            programTypeIdArr.push(obj.id)
            return obj.description
        }) : []

    const paymentModelTypeObjArr = paymentModelTypeList && paymentModelTypeList.length > 0 ?
        paymentModelTypeList.map(obj => obj) : []
    paymentModelTypeObjArr.push(BLANK_PAYMENT_MODEL)

    let productTypeIdArr = [];
    const productTypeArr = productTypeList && productTypeList.length > 0 ?
        productTypeList.map(obj => {
            productTypeIdArr.push(obj.id)
            return obj.name
        }) : []
    
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    });
        
    const onChangeView = (inpval, row) => {
        
        const tempFilterState = {
            ...filterState,
            installmentStartDate: filterState['installmentStartDate'] !== null ?
                format(new Date(filterState['installmentStartDate']), 'dd-MM-yyyy')
                : null,
            installmentEndDate: filterState['installmentEndDate'] !== null ?
                format(new Date(filterState['installmentEndDate']), 'dd-MM-yyyy')
                : null
        }
        setCurrentForm(inpval === "delete" ? "list" : inpval);
        if(inpval === "delete") {
            setDelReqRow({...row})
            setDelConfrimOpen(true)
        } else if(inpval === "view") {
            setClientRow({...row,
                "installmentProgramTypeName": row.installmentProgramType &&  row.installmentProgramType !== "" && programTypeIdArr.indexOf( row.installmentProgramType) !== -1 ? 
                    programTypeList[programTypeIdArr.indexOf( row.installmentProgramType)].description : "",
                "paymentModelTypeName": row.paymentModelType ? 
                    paymentModelTypeObjArr[paymentModelTypeObjArr.findIndex(val => val.id === row.paymentModelType)].description : "Blank",
                "templateFormat": row.excelFileTemplateId &&  row.excelFileTemplateId !== "" && fileTemplateIdArr.indexOf( row.excelFileTemplateId) !== -1 ? 
                    fileTemplateList[fileTemplateIdArr.indexOf( row.excelFileTemplateId)].templateFormat : "",
            })
            
        } else if(inpval === "edit") {
            setMciState({...row, 
                "installmentProgramTypeName": row.installmentProgramType &&  row.installmentProgramType !== "" && programTypeIdArr.indexOf( row.installmentProgramType) !== -1 ? 
                    programTypeList[programTypeIdArr.indexOf( row.installmentProgramType)].description : "",
                "paymentModelTypeName": row.paymentModelType ? 
                    paymentModelTypeObjArr[paymentModelTypeObjArr.findIndex(val => val.id === row.paymentModelType)].description : "Blank",
                "templateFormat": row.excelFileTemplateId &&  row.excelFileTemplateId !== "" && fileTemplateIdArr.indexOf( row.excelFileTemplateId) !== -1 ? 
                    fileTemplateList[fileTemplateIdArr.indexOf( row.excelFileTemplateId)].templateFormat : "",
            })

        } else if(inpval === "create") {
            setMciState(MciObj)
            
        } else {
            props.fetchMciList({...tempFilterState,
                pageNo: 1, sortBy: sortName, sortDirection: sortDir
            })
        }
    }

    useEffect(() => {
        if(currentPage && currentPage !== null && currentPage !== undefined && currentPage === "/mci" && compInitaited){
            setCurrentForm('list')
            setFilterState({...filterobj});
            setPage(1);
            setsortDir("DESC");
            setsortName("rowInsertionDateTime")
            props.fetchMciList({
                pageNo: 1,
                pageSize: 10,
                sortBy:"rowInsertionDateTime",
                sortDirection:"DESC"
            })
            localStorage.removeItem('currentPage')
        }
    }, [currentPage])

    useEffect(() => {
        const handleInitialCall = async () => {
            await setCompInitiated(true);
            await props.fetchMciList({
                ...filterState,
                pageNo: pageNo, sortBy: sortName, sortDirection: sortDir
            })
            await localStorage.removeItem('currentPage')
            handleMetaDataCall();
            return () => {
                //reset MCI response
                props.resetMciCreateOrEditResp()
            }
        }
        handleInitialCall();
    }, [])

    useEffect(() => {
        if(mciCreateRes && mciCreateRes.st) {
            if(currentForm === "create" ) {
                setopenPopover(true)
            } else {
                SetOpenSnackbar(true)
                setSnackBarMsg(`MCI Modified Successfully!`)
                onChangeView("list")
            }
            //reset MCI response
            props.resetMciCreateOrEditResp()
        } else if(mciCreateRes && !mciCreateRes.st) {
            SetOpenSnackbar(true)
            setSnackBarMsg(mciCreateRes.msg && mciCreateRes.msg)
            //reset MCI response
            props.resetMciCreateOrEditResp()
        } else if(mciCreateRes) {
            SetOpenSnackbar(true)
            setSnackBarMsg("Internal Server Error")
        }
    }, [mciCreateRes])

    useEffect(() => {
        if(mciDeleteRes && mciDeleteRes !== null && mciDeleteRes.st) {
            openDeleteAlert(true)
            setAlertMsg(`MCI Report Deleted Successfully!`)
            onChangeView("list")
            //reset delete response
            props.resetMciDeleteResp()
        } else if(mciDeleteRes && mciDeleteRes !== null && !mciDeleteRes.st) {
            openDeleteAlert(true)
            setAlertMsg(mciDeleteRes.msg && mciDeleteRes.msg)
            onChangeView("list")
            //reset delete response
            props.resetMciDeleteResp()
        }
    }, [mciDeleteRes])

    const handleMetaDataCall = () => {

        fileTypeList && fileTypeList.length === 0 && props.fetchFileTypeIdList()
        fileTemplateList && fileTemplateList.length === 0 && props.fetchTemplateIdList()
        programTypeList && programTypeList.length === 0 && props.fetchMCIProgramTypesList()
        paymentModelTypeList && paymentModelTypeList.length === 0 && props.fetchMCIPaymentModelTypesList()
        productTypeList && productTypeList.length === 0 && props.fetchMCIProductTypesList()
    }

    const handleFilterStateChange = (key, value) => {
        let tempFilter = {
            ...filterState,
            [key]: value
        }
       
        setFilterState(tempFilter);
    }
    
    const resetFilter = () => {
        setFilterState(filterobj);
        setPage(1);
        setsortDir("DESC");
        setsortName("rowInsertionDateTime")
        props.fetchMciList({
            pageNo: 1,
            pageSize: 10,
            sortBy:"rowInsertionDateTime",
            sortDirection:"DESC"
        })
    }

    const FilterApply = () => {
        setPage(1)
        const tempFilterState = {
            ...filterState,
            installmentStartDate: filterState['installmentStartDate'] !== null ?
                format(new Date(filterState['installmentStartDate']), 'dd-MM-yyyy')
                : null,
            installmentEndDate: filterState['installmentEndDate'] !== null ?
                format(new Date(filterState['installmentEndDate']), 'dd-MM-yyyy')
                : null
        }
        props.fetchMciList({ 
            ...tempFilterState, 
            pageNo: 1, 
            sortBy: sortName, 
            sortDirection: sortDir
        })
        setFilterState({...filterState});
    }

    const handleInputChange = (key, value) => {
        let updatedMciState = {...mciState};

        if(key === "createFileBasedOnIdentifier") {
            updatedMciState = {
                ...updatedMciState,
                createFileBasedOnIdentifier: value,
                createFileBasedOnIdentifierValue: value === "NA" ? "0" : "",
                secondaryIdentifier: value === "acquirer_id" ? "psp_id" : value === "psp_id" ? "acquirer_id" : "",
                mciId: 0,
                issuerIca: 0,
            }
        } else if (key === "installmentProgramType") {
            updatedMciState = {
                ...updatedMciState,
                installmentProgramTypeName: value,
                installmentProgramType: value && value !== "" && programTypeArr.indexOf(value) !== -1 ? 
                    programTypeList[programTypeArr.indexOf(value)].id : null,
            }
        } else if (key === "paymentModelType") {
            updatedMciState = {
                ...updatedMciState,
                paymentModelTypeName: value,
                paymentModelType: value && value !== "" ? 
                    paymentModelTypeObjArr[paymentModelTypeObjArr.findIndex(val => val.description === value)].id : 0,
            }
        } else if (key === "installmentProductType") {
            updatedMciState = {
                ...updatedMciState,
                installmentProductType: value,
            }
        } else if (key === "fileTypeId") {
            updatedMciState = {
                ...updatedMciState,
                fileTypeId: value
            }
        } else if (key === "formattedFileId") {
            updatedMciState = {
                ...updatedMciState,
                formattedFileId: value,
            }
        } else if (key === "excelFileTemplateId") {
            updatedMciState = {
                ...updatedMciState,
                templateFormat: value,
                excelFileTemplateId: value && value !== "" && fileTemplateArr.indexOf(value) !== -1 ? 
                    fileTemplateList[fileTemplateArr.indexOf(value)].templateId : null,
            }
        } else if (key === "isPLManaged") { 
            updatedMciState = {
                ...updatedMciState,
                isPLManaged: value,
                fileCreationServiceInstanceId: value !== false ? "2" : "1",
            }
        } else {
            updatedMciState = {
                ...updatedMciState,
                [key]: value
            } 
        }

        setMciState(updatedMciState)
    }

    const handleMciCreateUpdate = () => {
        let req = {
            ...mciState,
            issuerIca: mciState.issuerIca !== null && mciState.issuerIca !== "" ? mciState.issuerIca : 0,
            paymentModelType: mciState.paymentModelType !== null && mciState.paymentModelType !== "" ? mciState.paymentModelType : 0,
        }

        props.createOrEditMci(req)
    }

    const handleSnackBarAction = () => {
        SetOpenSnackbar(false)
        setSnackBarMsg(null)
    }

    const handleDialogAction = async (type) => {
        setopenPopover(false)
        setMciState(MciObj)
        if(type === "exit" ){
          onChangeView('list')
        } else {
          await onChangeView('list')
          await onChangeView('create')
        }
    }

    const handleDelConfirmAction = async (type) => {
        if(type === "delete") {
          props.deleteMci( 
            {"id": deleteReqRow.id && deleteReqRow.id !== "" ? deleteReqRow.id : ""}
          )
          setDelConfrimOpen(false)
        } else {
          setDelConfrimOpen(false)
          setDelReqRow(null)
        }
        props.fetchMciList({
            pageNo: 1,
            pageSize: 10,
            sortBy:"rowInsertionDateTime",
            sortDirection:"DESC"
        });
    }

    const handleAlertClose = () => {
        openDeleteAlert(false)
        setAlertMsg(null)
        //remove delete Res 
        props.resetMciDeleteResp()
    }
    
    const handleSetFilterData = (filterArray, searchValue) => {
        setFilterData(filterArray)
        setSearchValue(searchValue)
    }

    const handlePageNo = (val) => {
        setPage(val)
        const tempFilterState = {
            ...filterState,
            installmentStartDate: filterState['installmentStartDate'] !== null ?
                format(new Date(filterState['installmentStartDate']), 'dd-MM-yyyy')
                : null,
            installmentEndDate: filterState['installmentEndDate'] !== null ?
                format(new Date(filterState['installmentEndDate']), 'dd-MM-yyyy')
                : null
        }
        props.fetchMciList({ 
            ...tempFilterState, 
            pageNo: val, 
            sortBy: sortName, 
            sortDirection: sortDir
        })
    }

    const handleSortDir = (value) => {
        setsortDir(value)
        setPage(1)
    }

    const handleSortName = (value) => {
        setsortName(value)
        setPage(1)
    }
    
    return (
        <>
        <BasePageToolbar currentForm={currentForm}  create={permissions.includes("49")}
            title={currentForm === 'list' ? "MCI Report" :  currentForm === 'view' ? "View MCI Report" : currentForm === "edit" ? "Modify MCI Report" : "Create MCI Report"}
            onChangeView={onChangeView} resetFilter={resetFilter} 
            handleFilterStateChange={handleFilterStateChange} filterState={filterState} filterCells={filterCells} FilterApply={FilterApply}
            setsortName={handleSortName} setsortDir={handleSortDir} sortDir={sortDir} sortName={sortName} sortArr={sortArr}
            arrayToFilter={mciArr} handleSetFilterData={handleSetFilterData} isTableSearchReq={false} searchValue={searchValue}
        ></BasePageToolbar>
            <BasePageContainer>
                {currentForm === 'list' ?
                <Grid container className={classes.container}>
                    <TableComponent
                        sortOrder="desc"
                        sortOrderBy="rowInsertionDateTime"
                        loading={mciLoader}
                        activeTab={filterState}
                        headCells={headCells}
                        rows={searchValue && searchValue !== "" ? filterData : mciArr}
                        onChangeView={onChangeView}
                        showEdit={permissions.includes("50")}
                        showDelete={permissions.includes("51")}
                        totalRecord={totalRecord}
                        handlePageNo={handlePageNo}
                    />
                </Grid>
                : currentForm === 'create' ?
                <Grid container className={classes.formContainer}>
                    <CreateEditViewMCI 
                        currentForm={currentForm}
                        mciState={mciState}
                        handleInputChange={handleInputChange}
                        createDisableToken={!createDisableToken}
                        createEOMDisableToken={!createEOMDisableToken}
                        mciCreateLoader={mciCreateLoader}
                        handleMciCreateUpdate={handleMciCreateUpdate}
                        fileTypeList={fileTypeList}
                        fileTemplateArr={fileTemplateArr}
                        programTypeArr={programTypeArr}
                        paymentModelTypeArr={paymentModelTypeObjArr}
                        productTypeArr={productTypeArr}
                    />
                </Grid>
                : currentForm === "edit" ?
                <Grid container className={classes.formContainer}>
                    <CreateEditViewMCI 
                        currentForm={currentForm}
                        mciState={mciState}
                        handleInputChange={handleInputChange}
                        createDisableToken={!createDisableToken}
                        createEOMDisableToken={!createEOMDisableToken}
                        mciCreateLoader={mciCreateLoader}
                        handleMciCreateUpdate={handleMciCreateUpdate}
                        fileTypeList={fileTypeList}
                        fileTemplateArr={fileTemplateArr}
                        programTypeArr={programTypeArr}
                        paymentModelTypeArr={paymentModelTypeObjArr}
                        productTypeArr={productTypeArr}
                    />
                </Grid> :
                    <Grid container className={classes.formContainer}>
                        <CreateEditViewMCI 
                            currentForm={currentForm} 
                            mciDetails={clientRow}
                            onChangeView={onChangeView}
                            fileTypeList={fileTypeList}
                            fileTemplateArr={fileTemplateArr}
                        />
                    </Grid>
                }

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => handleSnackBarAction()}
                    action={
                        <React.Fragment>
                            <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
                            <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                >
                    <Alert onClose={() => handleSnackBarAction()} severity={
                        mciCreateRes && mciCreateRes !== null && !mciCreateRes.st ? "error" : "success"
                    }>
                        {snackbarMsg}
                    </Alert>
                </Snackbar>
                <Dialog
                    open={openPopover}
                    onClose={() => handleDialogAction("exit")}
                    className={"delete_dialog"}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="close_dialog" onClick={() => handleDialogAction("exit")}><img src={CloseImgIcon} alt="close" /></div>
                    <DialogTitle id="alert-dialog-title">
                        <h2>{"MCI Created Successfully!"}</h2>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`Do you want to Create new MCI?`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" className="yes" autoFocus
                            onClick={() => handleDialogAction("create")} 
                        >
                            Create New
                        </Button>
                        <Button color="primary" className="no"
                            onClick={() => handleDialogAction("exit")} 
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                open={delConfrimOpen}
                onClose={() => handleDelConfirmAction("exit")}
                className={"delete_dialog"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                    <div className="close_dialog" onClick={() => handleDelConfirmAction("exit")}><img src={CloseImgIcon} alt="close" /></div>
                    <DialogTitle id="alert-dialog-title">
                        <h2>{"Delete Confirmation!"}</h2>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`Are you sure do you want to delete ?`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" className="yes" autoFocus
                            onClick={() => handleDelConfirmAction("delete")} 
                        >
                            Delete
                        </Button>
                        <Button color="primary" className="no"
                            onClick={() => handleDelConfirmAction("exit")} 
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar key="dsocument-upload"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={deleteAlert}
                autoHideDuration={6000}
                onClose={() => handleAlertClose()}
                action={
                    <React.Fragment>
                        <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleAlertClose()}>
                        <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
                >
                    <Alert onClose={() => handleAlertClose()} severity={
                    mciDeleteRes && mciDeleteRes !== null && !mciDeleteRes.st ? "error" : "success"
                    }>
                    {alertMsg}
                    </Alert>
                </Snackbar>
            </BasePageContainer>
        </>
    )
}

const useStyles = tss.create({
    formContainer: {
        paddingTop: theme.spacing(0),
    },
    container: {
        paddingTop: theme.spacing(2),
    },
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            fetchMciList, createOrEditMci,
            resetMciCreateOrEditResp, deleteMci,
            resetMciDeleteResp, fetchFileTypeIdList,
            fetchTemplateIdList, 
            fetchMCIProgramTypesList, fetchMCIPaymentModelTypesList,
            fetchMCIProductTypesList,
        }, dispatch)
    }
}

function mapStateToProps(state) {
  return {
    mciList: state.MciReducer.mciList !== null && state.MciReducer.mciList.recordList ? 
        state.MciReducer.mciList.recordList : [],
    totalRecord: state.MciReducer.mciList !== null && state.MciReducer.mciList.totalRecords ? 
        state.MciReducer.mciList.totalRecords : [],
    mciCreateRes: state.MciReducer.mciCreateRes,
    mciDeleteRes: state.MciReducer.mciDeleteRes,
    mciLoader: state.MciReducer.mciLoader,
    mciCreateLoader: state.MciReducer.mciCreateLoader,
    mciDeleteLoader: state.MciReducer.mciDeleteLoader,
    fileTypeList: state.MciReducer.fileTypeIdList !== null && state.MciReducer.fileTypeIdList.fileTypeList ? 
        state.MciReducer.fileTypeIdList.fileTypeList : [],
    fileTemplateList: state.MciReducer.excelFileTemplateIdList !== null && state.MciReducer.excelFileTemplateIdList.fileTemplateList ? 
        state.MciReducer.excelFileTemplateIdList.fileTemplateList : [],
    programTypeList: state.MciReducer.programTypesList !== null && state.MciReducer.programTypesList.programTypeList ? 
        state.MciReducer.programTypesList.programTypeList : [],
    paymentModelTypeList: state.MciReducer.paymentModelTypesList !== null && state.MciReducer.paymentModelTypesList.paymentModelTypeList ? 
        state.MciReducer.paymentModelTypesList.paymentModelTypeList : [],
    productTypeList: state.MciReducer.productTypesList !== null && state.MciReducer.productTypesList.productTypeList ? 
        state.MciReducer.productTypesList.productTypeList : [],
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MCI);
