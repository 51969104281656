import { actionCreator, BASE_URL } from "../../../shared/utility";
import { PspActions } from "../consts/index";

export function fetchPSPDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(PspActions.fetchPSPDetails.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/getPSPList` , {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(PspActions.fetchPSPDetails.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(PspActions.fetchPSPDetails.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchPSPMetadata(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(PspActions.fetchPSPMetadata.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/getPSPSummaryList` , {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(PspActions.fetchPSPMetadata.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(PspActions.fetchPSPMetadata.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function createPSP(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(PspActions.createPSP.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/addOrEditPSP` , {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(PspActions.createPSP.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(PspActions.createPSP.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

// export function fetchStateNCity(payload) {
//     return(dispatch, getState) => {
//         const token = getState().AuthReducer.authResponse.authtoken
//         dispatch(actionCreator(PspActions.fetchStateNCity.REQUEST))
//         fetch(`https://api.worldpostallocations.com/pincode?postalcode=${payload.postalCode}&countrycode=${payload.country}` , {
//             method: 'GET',
//             // body: JSON.stringify(payload),
//             // headers: {
//             //     'Content-Type':'application/json',
//             //     'Authorization': `Bearer ${token}`
//             // }
//         })
//         .then(res => res.json())
//         .then(response => {
//             console.log("response >>> ", response)
//             if(!response.st) {
//                 if(response.responseCode && response.responseCode === "401") {
//                     localStorage.setItem("tokenExpired", "true")
//                     localStorage.removeItem('accessToken')
//                     window.location.reload()
//                 }  else {
//                     dispatch(actionCreator(PspActions.fetchStateNCity.FAILURE, response))
//                 }
//             } else {
//                 dispatch(actionCreator(PspActions.fetchStateNCity.SUCCESS, response))
//             }
//         })
//         .catch((error) => {
//             console.log("error >>> ", error)
//         })
//     }
// }

export function resetcreatePspRes() {
    return(dispatch) => {
        dispatch(actionCreator(PspActions.createPSP.FAILURE, null))
    }
}

export function resetPSPMetadata() {
    return(dispatch) => {
        dispatch(actionCreator(PspActions.fetchPSPMetadata.FAILURE))
    }
}