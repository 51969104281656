import React from 'react'
import {connect} from "react-redux";
import { bindActionCreators } from "redux";
import DashboardLayout from '_layouts/DashboardLayout';
import {BrowserRouter, Route, Redirect, Switch } from 'react-router-dom' //
import {Button, Dialog, DialogActions, DialogTitle, DialogContentText, DialogContent
} from '@mui/material';

import { Auth } from './auth' 
import Dashboard from './dashboard/dashboard';
import { BankSchemes, MerchantSchemes, BrandSchemes } from './manageScheme';
import {RefreshTokenFetch} from "./auth/actions/index"
import { Brand, Product, ProductCategory, ProductGroup, ProductCodeMapping } from './oemManagement/index';
import ManageProgram from './manageProgram/index';
import { PSP, Merchant, Store, Terminal, Acquirer, PSPAcquirer} from "./manageMerchant";
import {User, Role} from './manageUser/index';
import ManageIssuer from "./manageIssuer";
import Country from "manageCountry";
import MCI from "manageMCI";
import ResetPassword from "auth/resetPassword";
import PaxConfiguration from "./paxTerminal";
import {validateForgotPasswordToken} from "./auth/actions/index";
import {PublicKeyGenerator} from 'manageRSAEncryption/index'

let time = 30
let flag = false;
let timer;

class AppRouter extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      isUserLoggedIn: false,
      sessionTime: 30,
      calledOnce: false
    }
  }

  UNSAFE_componentWillMount() {
    let token = localStorage.getItem('accessToken')
    // this.props.RefreshTokenAction(token)
    if(token && token !== null) { this.props.RefreshTokenFetch(token) }
  }

  componentDidMount() {
    !this.state.isUserLoggedIn && this.handleUserLoginCheck();
  }

  componentDidUpdate() {
    !this.state.isUserLoggedIn && this.handleUserLoginCheck();
    if(this.props.openDialog && this.state.sessionTime === 30 && !flag) {
      flag = true;
      time === 30 && this.handleSessionTime()
    }
  }

  handleForgetPassVal = () => {
    this.setState({
      calledOnce: true
    }, () => {
      let uId = document.location.pathname && (document.location.pathname).split("/").length > 0 && 
        (document.location.pathname).split("/")[2];
      this.props.validateForgotPasswordToken({'token': uId});
    })
  }

  handleUserLoginCheck = () => {
    const {authResponse} = this.props;
    let token = localStorage.getItem('accessToken')
    if(token && token !== null && authResponse && authResponse !== null && token === JSON.stringify(authResponse.authtoken)) {
      this.setState({
        isUserLoggedIn: true
      });
    }
  }

  handleUserAction() {
    this.props.confirmSessionReset();
    time = 30;
    flag = false;
    clearTimeout(timer)
    this.setState({sessionTime: 30})
  }

  handleSessionTime() {
    if(time > 0) {
      timer = setTimeout(()=>{
        this.setState({
          sessionTime: time -1 
        }, async () => {
          time = await time - 1
          await this.handleSessionTime()
        })
      }, 1000)
    }
  }
  
  render() {
    const {openDialog, forPassVal} = this.props;

    let permissions = localStorage.getItem("permissions")
    permissions = permissions && permissions !== null ? JSON.parse(permissions) : [];

    // let forgotPasswordToken = localStorage.getItem("forgotPasswordToken") && 
    //   localStorage.getItem("forgotPasswordToken") !== null ? 
    //     JSON.parse(localStorage.getItem("forgotPasswordToken")) : false;

    const urlPath = document.location.pathname && (document.location.pathname).split("/").length > 0 && 
        (document.location.pathname).split("/")[1];
    if(urlPath === "reset" && !this.state.calledOnce) {
      this.handleForgetPassVal();
    }

    return (
      <BrowserRouter>
        {this.state.isUserLoggedIn ?
          <>
            <Dialog
              open={openDialog}
              onClose={() => this.handleUserAction()}
              className={"delete_dialog"}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <h2>{`Your session will be ended in ${this.state.sessionTime} seconds!`}</h2>
              </DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      {`Please Click button below to continue your session.`}
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button
                    style={{ color: "white", backgroundColor: `#1BA785`}}
                      onClick={() => this.handleUserAction()} 
                  >
                    Continue Session
                  </Button>
              </DialogActions>
            </Dialog>
          <Switch> 
            <Route path="/auth"> <Redirect to="/" /> </Route>
            <RouteWithLayout exact path={`/`} component={Dashboard} layout={DashboardLayout} />
            {permissions.includes("1") && 
              <RouteWithLayout exact path={`/bankschemes`} component={BankSchemes} layout={DashboardLayout} />}
            {permissions.includes("4") && 
              <RouteWithLayout exact path={`/merchantschemes`} component={MerchantSchemes} layout={DashboardLayout} /> }
            {permissions.includes("8") && 
              <RouteWithLayout exact path={`/brandschemes`} component={BrandSchemes} layout={DashboardLayout} /> }
            {permissions.includes("12") &&
              <RouteWithLayout exact path={`/psp`} component={PSP} layout={DashboardLayout} />}
            {permissions.includes("15") &&
              <RouteWithLayout exact path={`/merchant`} component={Merchant} layout={DashboardLayout} />}
            {permissions.includes("18") &&
              <RouteWithLayout exact path={`/store`} component={Store} layout={DashboardLayout} />}
            {permissions.includes("21") &&
              <RouteWithLayout exact path={`/terminal`} component={Terminal} layout={DashboardLayout} />}
            {permissions.includes("24") &&
              <RouteWithLayout exact path={`/program`} component={ManageProgram} layout={DashboardLayout} />}
            {permissions.includes("27") &&
              <RouteWithLayout exact path={`/users`} component={User} layout={DashboardLayout} />}
            {permissions.includes("30") &&
              <RouteWithLayout exact path={`/roles`} component={Role} layout={DashboardLayout} />}
            {permissions.includes("33") &&
              <RouteWithLayout exact path={`/issuers`} component={ManageIssuer} layout={DashboardLayout} />}
            {permissions.includes("36") &&
              <RouteWithLayout exact path={`/product`} component={Product} layout={DashboardLayout} />}
            {permissions.includes("39") &&
              <RouteWithLayout exact path={`/category`} component={ProductCategory} layout={DashboardLayout} />}
            {permissions.includes("42") &&
              <RouteWithLayout exact path={`/brand`} component={Brand} layout={DashboardLayout} />}
            {permissions.includes("45") &&
              <RouteWithLayout exact path={`/country`} component={Country} layout={DashboardLayout} />}
            {permissions.includes("48") &&
              <RouteWithLayout exact path={`/mci`} component={MCI} layout={DashboardLayout} />}
            {permissions.includes("55") &&
              <RouteWithLayout exact path={'/paxconfig'} component={PaxConfiguration} layout={DashboardLayout} />}
            {permissions.includes("58") &&
              <RouteWithLayout exact path={`/acquirer`} component={Acquirer} layout={DashboardLayout} />}
            {permissions.includes("61") &&
              <RouteWithLayout exact path={`/pspAcquirer`} component={PSPAcquirer} layout={DashboardLayout} />}
            {permissions.includes("67") &&
              <RouteWithLayout exact path={`/productGroup`} component={ProductGroup} layout={DashboardLayout} />}
            {permissions.includes("70") &&
              <RouteWithLayout exact path={`/productCodeMapping`} component={ProductCodeMapping} layout={DashboardLayout} />}
            {permissions.includes("80") &&
              <RouteWithLayout exact path={`/keyManagement`} component={PublicKeyGenerator} layout={DashboardLayout} />}
            <Route path="**"> <Redirect to="/" /> </Route>
          </Switch>
        </>
        : forPassVal && forPassVal !== null && !forPassVal.st ?
          <>
            <Switch> 
              <Route path="/reset">
                  <Redirect to="/" />
              </Route> 
              <Route exact path="/auth" component={Auth}/>
              <Route exact path="/">
                <Redirect to="/auth" />
              </Route>
              <Route path="**">
                <Redirect to="/auth" />
              </Route>
            </Switch>
          </>
          : forPassVal && forPassVal !== null && forPassVal.st  ? 
            <>
              <Switch> 
                <Route exact path="/reset/:id" component={ResetPassword}/>
                <Route exact path="/auth" component={Auth}/>
                <Route path="**">
                  <Redirect to="/auth" />
                </Route>
              </Switch>
            </> : 
              <Switch> 
                <Route path="**" component={Auth}/>
              </Switch> 
        }
      </BrowserRouter>
    )
  }
}

const RouteWithLayout = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (Layout) {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      } else {
        return <Component {...props} />
      }
    }}
  />
)

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({ 
        RefreshTokenFetch, validateForgotPasswordToken 
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
      authResponse: state.AuthReducer.authResponse,
      refreshToken: state.AuthReducer.refreshToken,
      forPassVal: state.AuthReducer.forPassVal,
      // resetPasswordRes: state.AuthReducer.resetPasswordRes,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRouter);
