import { PspActions } from "../consts/index";

const initialState = {
    PSPList: null,
    pspLoader: false,
    pspMetaData: null,
    createPspRes: null,
    // stateNCityRes: null
}

export default (state = initialState, {type, payload}) => {
    switch(type) {
        case PspActions.fetchPSPDetails.REQUEST:
            return {
                ...state,
                pspLoader: true
            }
        case PspActions.fetchPSPDetails.SUCCESS:
            return {
                ...state,
                pspLoader: false,
                PSPList: payload
            }
        case PspActions.fetchPSPDetails.FAILURE:
            return {
                ...state,
                pspLoader: false,
                PSPList: null
            }

            
        case PspActions.fetchPSPMetadata.REQUEST:
            return {
                ...state
            }
        case PspActions.fetchPSPMetadata.SUCCESS:
            return {
                ...state,
                pspMetaData: payload
            }
        case PspActions.fetchPSPMetadata.FAILURE:
            return {
                ...state,
                pspMetaData: null
            }
        
        case PspActions.createPSP.REQUEST:
            return {
                ...state
            }
        case PspActions.createPSP.SUCCESS:
            return {
                ...state,
                createPspRes: payload
            }
        case PspActions.createPSP.FAILURE:
            return {
                ...state,
                createPspRes: payload
            }

        // case PspActions.fetchStateNCity.REQUEST:
        //     return {
        //         ...state
        //     }
        // case PspActions.fetchStateNCity.SUCCESS:
        //     return {
        //         ...state,
        //         stateNCityRes: payload
        //     }
        // case PspActions.fetchStateNCity.FAILURE:
        //     return {
        //         ...state,
        //         stateNCityRes: payload
        //     }

        default:
            return state;
    }
}