import React, {useState, useEffect} from "react";
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { tss } from "tss-react";
import theme from '../../_theme'
import { Button, Grid, Paper, Typography } from "@mui/material";

import BasePageToolbar from "_common/basePageToolbar/basePageToolbar";
import BasePageContainer from "_common/basePageContainer";
import Filter from './filter'
import TableComponent from '../table/index'
import { fetchCountryDetails, resetCountryDetails } from '../../manageScheme/actions/index'
import { fetchPSPMetadata, resetPSPMetadata } from '../../manageMerchant/psp/actions/index'
import {
    fetchRSAKeyList, resetKeyListResp,
    createRSAKey, reCreateRSAKey,
    resetCreateKeyResp, downloadRSAPublicKey,
    resetDownloadPublicKeyResp, expireKeyPair,
    resetExpireKeyResp
} from '../actions/index'
import CustomDialogBox from './customDialogBox'
import SnackbarToast from "./snackebarToast";
import { downloadFileFromBase64Str } from '../../shared/utility'

const headCells = [
    { id: 'id', type: 'text', numeric: false, disablePadding: false, label: 'Key Pair Id' },
    { id: 'countryName', type: 'text', numeric: false, disablePadding: false, label: 'Country' },
    { id: 'pspName', type: 'text', numeric: false, disablePadding: false, label: 'Payment Provider' },
    { id: 'activationDateTime', type: 'datetime', numeric: true, disablePadding: false, label: 'Activation Date Time(UTC)' },
    { id: 'expiryDateTime', type: 'datetime', numeric: true, disablePadding: false, label: 'Expiry Date Time(UTC)' },
    { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];

const filterObj = {
    isoCountryCodeNum: "",
    countryName: "",
    pspId: "",
    pspName: "",
    expiryDateTime: null,
}

const PublicKeyGenerator = (props) => {
    const {classes} = useStyles()
    const { 
        countryList, pspList, loader, rsaKeyListResp, createRSAKeyResp, downloadKeyResp, expireKeyResp 
    } = props 
    
    const [compInitaited, setCompInitiated] = useState(false)
    const [filterState, setFilterState] = useState(filterObj)
    const [openDialog, setOpenDialog] = useState(false)
    const [dialogTitle, setDialogTitle] = useState("")
    const [dialogBody, setDialogBody] = useState("")
    const [dialogType, setDialogType] = useState("")
    const [snackbarType, setSnackbarType] = useState("")
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMsg, setSnackbarMsg] = useState("")
    const [fileName, setFileName] = useState("")
    const [fileContent, setFileContent] = useState("")

    const currentPage = localStorage.getItem('currentPage')
    let permissions = localStorage.getItem("permissions")
    permissions = permissions && permissions !== null ? JSON.parse(permissions) : []

    const countryArr = countryList && countryList !== null && countryList.length > 0 ?
        countryList.map(country => country.name) : []

    const pspArr = pspList && pspList !== null && pspList.length > 0 ?
        pspList.map(psp => psp.name) : []

    useEffect(() => {
        const handleInitialCall = async () => {
            setCompInitiated(true);
            await props.fetchCountryDetails()
            localStorage.removeItem('currentPage')
        }
        handleInitialCall();
          
        return () => {
            //add all list reset API here
            props.resetCountryDetails()
            props.resetPSPMetadata()
            props.resetKeyListResp()
            props.resetCreateKeyResp()
            props.resetDownloadPublicKeyResp()
          }
    }, [])

    useEffect(() => {
        if(currentPage && currentPage !== null && currentPage !== undefined && currentPage === "/rsaEncryption" && compInitaited){
            setFilterState({...filterObj});
            props.fetchCountryDetails()
            localStorage.removeItem('currentPage')
        }
    }, [currentPage])

    useEffect(() => {
        if(rsaKeyListResp && rsaKeyListResp !== null) {
            if(rsaKeyListResp.status && rsaKeyListResp.status === 'success') {
                const type = rsaKeyListResp && rsaKeyListResp !== null && rsaKeyListResp.keyPairList && 
                rsaKeyListResp.keyPairList.length !== 0 ?
                    "Re-Create" : "Create"
                setDialogType(type)
            } else if(rsaKeyListResp.status && rsaKeyListResp.status === 'fail') {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMsg(rsaKeyListResp.errors && rsaKeyListResp.errors.length > 0 ?
                    rsaKeyListResp.errors.map(err => err.message).join(", ")
                    :`Problem occured while fetching RSA key list!`)
            } else if(!rsaKeyListResp.st && rsaKeyListResp.msg !== null) {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMsg(rsaKeyListResp.msg)
            } else if (rsaKeyListResp.error) {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMsg(rsaKeyListResp.error)
            } else {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMsg("Unknown Error!")
            }
        }
    }, [rsaKeyListResp])

    useEffect(() => {
        if(createRSAKeyResp && createRSAKeyResp !== null) {
            if(createRSAKeyResp.status && createRSAKeyResp.status === 'success') {
                setOpenDialog(true)
                setDialogType("Download")
                setDialogTitle("Public Key Download Confirmation!")
                setDialogBody(
                    dialogType === "Download" ?
                        `Are you sure, you want to download the public key? Please click on Confirm if you want!`
                        : `Your key pair ${dialogType}d successfully. Please click on Confirm if you want to download the public key!`
                )
                setFileName(createRSAKeyResp.fileName)
                setFileContent(createRSAKeyResp.fileContent)

            } else if(createRSAKeyResp.status && createRSAKeyResp.status === 'fail') {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMsg(createRSAKeyResp.errors && createRSAKeyResp.errors.length > 0 ?
                    createRSAKeyResp.errors.map(err => err.message).join(", ")
                    :`Problem occured while creating RSA key pair!`)
            } else if(!createRSAKeyResp.st && createRSAKeyResp.msg !== null) {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMsg(createRSAKeyResp.msg)
            } else if (createRSAKeyResp.error) {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMsg(createRSAKeyResp.error)
            } else {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMsg("Unknown Error!")
            }
        }
    }, [createRSAKeyResp])

    useEffect(() => {
        if(downloadKeyResp && downloadKeyResp !== null) {
            if(downloadKeyResp.status && downloadKeyResp.status === 'success') {
                setOpenDialog(true)
                setDialogType("Download")
                setDialogTitle("Public Key Download Confirmation!")
                setDialogBody(
                    dialogType === "Download" ?
                        `Are you sure, you want to download the public key? Please click on Confirm if you want!`
                        : `Your key pair ${dialogType}d successfully. Please click on Confirm if you want to download the public key!`
                )
                setFileName(downloadKeyResp.fileName)
                setFileContent(downloadKeyResp.fileContent)

            } else  if(downloadKeyResp.status && downloadKeyResp.status === 'fail') {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMsg(downloadKeyResp.errors && downloadKeyResp.errors.length > 0 ?
                    downloadKeyResp.errors.map(err => err.message).join(", ")
                    :`Problem occured while downloading public key!`)
            } else if(!downloadKeyResp.st && downloadKeyResp.msg !== null) {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMsg(downloadKeyResp.msg)
            } else if (downloadKeyResp.error) {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMsg(downloadKeyResp.error)
            } else {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMsg("Unknown Error!")
            }
        }
    }, [downloadKeyResp])

    useEffect(() => {
        if(expireKeyResp && expireKeyResp !== null) {
            if(expireKeyResp.status && expireKeyResp.status === 'success') {
                setOpenSnackbar(true)
                setSnackbarType("success")
                setSnackbarMsg("Public key expired successfully!")

            } else  if(expireKeyResp.status && expireKeyResp.status === 'fail') {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMsg(expireKeyResp.errors && expireKeyResp.errors.length > 0 ?
                    expireKeyResp.errors.map(err => err.message).join(", ")
                    :`Problem occured while downloading public key!`)
            } else if(!expireKeyResp.st && expireKeyResp.msg !== null) {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMsg(expireKeyResp.msg)
            } else if (expireKeyResp.error) {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMsg(expireKeyResp.error)
            } else {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMsg("Unknown Error!")
            }
            props.resetExpireKeyResp()
            props.fetchRSAKeyList({
                isoCountryCodeNum: filterState.isoCountryCodeNum,
                pspId: filterState.pspId,
            })
        }
    }, [expireKeyResp])

    const onChangeView = (key, row) => {

        if(key === 'download') {
            props.downloadRSAPublicKey({id: row.id})
            setDialogType("Download")
        } else if(key === 'expire') {
            props.expireKeyPair({id: row.id})
        }
    }

    const handleFilter = (key, value) => {
        let updatedFilterState = {...filterState}
        if(key === "countryName") {
            const index = countryArr.indexOf(value)
            updatedFilterState = {
                ...updatedFilterState,
                [key]: value,
                isoCountryCodeNum: index !== -1 && countryList[index]['isoCountryCodeNum'] ? countryList[index]['isoCountryCodeNum'] : "",
            }
            props.resetPSPMetadata()
            props.resetKeyListResp()
            props.fetchPSPMetadata({
                "isoCountryCodeNum": index !== -1 && countryList[index]['isoCountryCodeNum'] ? countryList[index]['isoCountryCodeNum'] : "",
                "status": "ACTIVE"
            })
        } else if(key === "pspName") {
            const index = pspArr.indexOf(value)
            updatedFilterState = {
                ...updatedFilterState,
                [key]: value,
                pspId: index !== -1 && pspList[index].id ? pspList[index].id : "",
            }
            props.resetKeyListResp()
        } else if(key === "expiryDateTime") {
            updatedFilterState = {
                ...updatedFilterState,
                [key]: value,
            }
        } else {
            updatedFilterState = {
                ...updatedFilterState,
                [key]: value,
            }
        }
        setFilterState(updatedFilterState)
    }

    const handleApplyFilter = (key) => {
        if(key === "apply") {
            props.fetchRSAKeyList({
                // ...filterState,
                isoCountryCodeNum: filterState.isoCountryCodeNum,
                pspId: filterState.pspId,
            })
        } else {
            setFilterState({...filterObj})
            props.resetPSPMetadata()
            props.resetKeyListResp()
        }
    }

    const handleCreateButtonClick = () => {
        setOpenDialog(true)
        setDialogTitle(
            `${dialogType} Key Pair Confirmation!`
        )
        setDialogBody(
            dialogType === "Create" ?
                `Are you sure, you want to Create key pair? Please confirm!` 
                :   `Are you sure, you want to Re-Create the key pair? If yes, then please provide the expiry date-time for last key pair!`
        )
    }

    const handleDialogAction = (key) => {
        setOpenDialog(false) 
        if(key === 'confirm') {
            if(dialogType === "Create") {
                props.createRSAKey({
                    isoCountryCodeNum: filterState.isoCountryCodeNum,
                    countryName: filterState.countryName,
                    pspId: filterState.pspId,
                    pspName: filterState.pspName,
                })
            } else if(dialogType === "Re-Create") {
                props.reCreateRSAKey({
                    isoCountryCodeNum: filterState.isoCountryCodeNum,
                    countryName: filterState.countryName,
                    pspId: filterState.pspId,
                    pspName: filterState.pspName,
                    expiryDateTime: filterState.expiryDateTime,
                })
            } else if(dialogType === "Download") {
                downloadFileFromBase64Str(fileContent, fileName)
                setDialogType(
                    rsaKeyListResp && rsaKeyListResp !== null && rsaKeyListResp.keyPairList && 
                        rsaKeyListResp.keyPairList.length !== 0 ?
                            "Re-Create" : "Create"
                )
                props.fetchRSAKeyList({
                    isoCountryCodeNum: filterState.isoCountryCodeNum,
                    pspId: filterState.pspId
                })
            }
        } else if(key === 'close' && dialogType === "Download") {
            props.fetchRSAKeyList({
                isoCountryCodeNum: filterState.isoCountryCodeNum,
                pspId: filterState.pspId
            })
        }
        setDialogTitle("")
        setDialogBody("")
        props.resetCreateKeyResp()
        props.resetDownloadPublicKeyResp()
    }

    const handleSnackBarAction = () => {
        setOpenSnackbar(false)
        setSnackbarType("")
        setSnackbarMsg("")
    }

    return(
        <>
            <BasePageToolbar
                title={"Key Generator"}
            ></BasePageToolbar>
            <BasePageContainer>
                <Paper elevation={2}>
                    <Grid container className={"step_action"}>
                        <Filter
                            classes={classes}
                            filterState={filterState}
                            countryArr={countryArr}
                            pspArr={pspArr}
                            handleFilter={handleFilter}
                            handleApplyFilter={handleApplyFilter}
                        />
                    </Grid>
                    {rsaKeyListResp && rsaKeyListResp !== null && rsaKeyListResp.keyPairList && rsaKeyListResp.keyPairList !== null &&
                    <Grid container className={"step_action"}>
                        <Grid item xs={12} sm={9}>
                            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                                Keys Detail
                            </Typography>
                        </Grid>
                        {permissions.includes("81") &&
                        <Grid item xs={12} sm={3} style={{ display: "flex", justifyContent: "space-evenly" }} justify="flex-end">
                            <Button 
                                variant="contained" style={{color: '#ffffff !important'}}
                                onClick={() => handleCreateButtonClick()}
                            >
                                {rsaKeyListResp.keyPairList && rsaKeyListResp.keyPairList.length === 0 ? "Create Key": "Re-Create Key"}
                            </Button>
                        </Grid>}
                        <Grid item xs={12} sm={12} style={{paddingTop: "10px"}}>
                            <TableComponent
                                sortOrder="desc" sortOrderBy="activationDateTime"
                                onChangeView={onChangeView}  
                                rows={rsaKeyListResp.keyPairList && rsaKeyListResp.keyPairList !== null && rsaKeyListResp.keyPairList}
                                loading={loader} 
                                headCells={headCells}
                                showDownload={permissions.includes("82")}
                                showDelete={permissions.includes("81")}
                            />
                        </Grid>
                    </Grid>}
                </Paper>
            </BasePageContainer>
            <CustomDialogBox
                title={dialogTitle}
                body={dialogBody}
                openDialog={openDialog}
                dialogType={dialogType}
                handleDialogAction={handleDialogAction}
                handleExpiryDateTime={handleFilter}
                filterState={filterState}
            />
            <SnackbarToast
                type={snackbarType}
                openSnackbar={openSnackbar}
                snackbarMsg={snackbarMsg}
                handleSnackBarAction={handleSnackBarAction}
            />
        </>
    )
}

const useStyles = tss.create({
    formContainer: {
        paddingTop: theme.spacing(0),
    },
    container: {
        paddingTop: theme.spacing(2),
    },
    menu: {
        fontSize: "15px"
    },
    addRange: {
        alignItems: "center",
        display: "flex",
    },
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            fetchCountryDetails, resetCountryDetails,
            fetchPSPMetadata, resetPSPMetadata,
            fetchRSAKeyList, resetKeyListResp,
            createRSAKey, reCreateRSAKey,
            resetCreateKeyResp, downloadRSAPublicKey,
            resetDownloadPublicKeyResp, expireKeyPair,
            resetExpireKeyResp
        }, dispatch)
    }
}

function mapStateToProps(state) {
    return {
        countryList: state.SchemeReducer.countryDetails !== null &&
            state.SchemeReducer.countryDetails.countryList ?
                state.SchemeReducer.countryDetails.countryList : [],
        pspList: state.PspReducer.pspMetaData !== null && state.PspReducer.pspMetaData.pspList ?
            state.PspReducer.pspMetaData.pspList : [],
        rsaKeyListResp: state.EncryptionReducer.rsaKeyListResp ?
            state.EncryptionReducer.rsaKeyListResp : null,
        createRSAKeyResp: state.EncryptionReducer.createRSAKeyResp,
        downloadKeyResp: state.EncryptionReducer.downloadKeyResp,
        loader: state.EncryptionReducer.loader,
        expireKeyResp: state.EncryptionReducer.expireKeyResp,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PublicKeyGenerator)
