import { createTheme } from '@mui/material/styles'

const baseTheme = createTheme({
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 1,
      },
    },
    MuiMenu: {
      defaultProps: {
        elevation: 1,
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 0,
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
          '&:focus': {
            boxShadow: 'none',
          },
        },
        containedSecondary: {
          color: '#fff',
          '&:hover': {
            backgroundColor: 'rgb(118, 195, 21)',
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
          '&:focus': {
            boxShadow: 'none',
          },
        },
    },
  },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 40,
        },
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: 16,
          },
        },
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          background: '#1BA785',
        },
      }
    },
  },
  // props: {
  //   MuiPaper: {
  //     elevation: 0,
  //   },
  //   MuiAppBar: {
  //     elevation: 1,
  //   },
  //   MuiButton: {
  //     // elevation: 0,
  //   },
  //   MuiMenu: {
  //     elevation: 1,
  //   },
  //   MuiCard: {
  //     elevation: 0,
  //   },
  // },
  // overrides: {
  //   MuiButton: {
  //     root: {
  //       minWidth: 0,
  //     },
  //     contained: {
  //       boxShadow: 'none',
  //       '&:active': {
  //         boxShadow: 'none',
  //       },
  //       '&:focus': {
  //         boxShadow: 'none',
  //       },
  //     },
  //     containedSecondary: {
  //       color: '#fff',
  //       '&:hover': {
  //         backgroundColor: 'rgb(118, 195, 21)',
  //       },
  //     },
  //   },
  //   MuiButtonGroup: {
  //     root: {
  //       boxShadow: 'none',
  //     },
  //     contained: {
  //       boxShadow: 'none',
  //       '&:active': {
  //         boxShadow: 'none',
  //       },
  //       '&:focus': {
  //         boxShadow: 'none',
  //       },
  //     },
  //   },
  //   MuiListItemIcon: {
  //     root: {
  //       minWidth: 40,
  //     },
  //   },
  //   MuiCardContent: {
  //     root: {
  //       '&:last-child': {
  //         paddingBottom: 16,
  //       },
  //     },
  //   },
  //   MuiLinearProgress: {
  //     root: {
  //       background: '#1BA785',
  //     },
  //   },
  // },
  palette: {
    divider: 'rgba(30, 30, 30, 0.06)',
    secondary: {
      main: '#1BA785', //indigo[600],
    },
    primary: {
      main: '#1BA785', //'#619f30',
      // main: '#9027d1', //'#619f30',
      // main: blue[600], //'#619f30',
    },
    text: {
      secondary: 'rgba(102, 102, 102, 0.83)',
      positive: '#1BA785',
      negative: '#e35959',
    },
  },
  typography: {
    h1: {
      // fontSize: '2rem',
      fontSize: '2.2rem',
    },
    h2: {
      fontSize: '2.0rem',
      // fontSize: '1.8rem',
    },
    h3: {
      fontSize: '1.8rem',
      // fontSize: '1.6rem',
    },
    h4: {
      fontSize: '1.6rem',
      // fontSize: '1.4rem',
    },
    h5: {
      fontSize: '1.4rem',
      // fontSize: '1.2rem',
    },
    h6: {
      fontSize: '1.2rem',
      // fontSize: '1rem',
    },
  },
})

const adminTheme = {
  header: {
    background: '#fff',
  },
  sidebar: {
    width: "280px",
    widthCollapsed: baseTheme.spacing(7),
    background: '#fff;',
    color: '#fff',
  },
}

const theme = {
  ...baseTheme,
  ...adminTheme,
}

export default theme
