import React, {useState} from 'react';
import {connect} from "react-redux";
import { bindActionCreators } from "redux";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { 
    Grid, TextField, MenuItem, Button, IconButton,
    Paper, Typography, Checkbox, InputAdornment, Tooltip,
    Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../../_theme'
import EditIcon from '@mui/icons-material/Edit';
import Visibility from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';

import { numbRegex } from "../../shared/utility";
import { ONLY_NUMBERS } from "shared/errorMessages";
import CloseImgIcon from '../../Assets/close.svg';
import FileTemplateDialog from './fileTemplateDialog';

const useStyles = tss.create({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        border: "solid 1px #EEEEEE",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(5),
    },
    input: {
        display: 'none',
    },
    menu: {
        fontSize: "15px",
    },
    marginTopBottom: {
        margin: theme.spacing(2, 0, 2, 0),
    },
    titleText: {
        color: "#424242",
        fontFamily: "Quicksand",
        fontSize: "26px",
        letterSpacing: 0,
        lineHeight: "30px"
    },
});

const identifierList = ["NA", "acquirer_id", "ica_id",  "mci_id", "mid","payment_product_type", "psp_id", "tid"];
const secondaryIdentifierList = ["acquirer_id", "psp_id"]

function CreateEditViewMCI(props) {
    const {classes} = useStyles();
    const { 
        currentForm, mciState, handleInputChange, createDisableToken, createEOMDisableToken,
        handleMciCreateUpdate, onChangeView, mciDetails, mciCreateLoader,
        fileTypeList, fileTemplateArr, programTypeArr, 
        paymentModelTypeArr, productTypeArr
    } = props;
    const [valObj, setValObj] = useState({
        mciIdErr: false, issuerIcaErr: false, fileCreationDirectoryPathErr: false, 
        fileCreationServiceInstanceIdErr: false, monthendFileDownloadIntervalInMinutesErr: false,
        installmentProgramTypeErr: false, installmentProductTypeErr: false,
        headerIdErr: false, footerIdErr: false, monthEndFileDownloadTimeInUtcErr: false,
        monthendFileUploadTimeInUtcErr: false, excelFileTemplateIdErr: false,
        monthendFileDownloadPathErr: false, createFileBasedOnIdentifierErr: false,
        createFileBasedOnIdentifierValueErr: false, fileTypeIdErr: false, formattedFileIdErr: false,
        createFileFrequencyErr: false, secondaryIdentifierErr: false, secondaryIdentifierValueErr: false,
    })
    const [openPopover, setopenPopover] = useState(false);
    const [editFileFormatId, setEditFileFormatId] = useState(false)
    const [openTemplateDialog, setOpenTemplateDailog] = useState(false);

    const handleChange = (type, value) => {
        if(type === "mciId"){
            handleInputChange(type, value)
            setValObj({...valObj, mciIdErr: false })

        } else if(type === "issuerIca") {
            handleInputChange(type, value)
            setValObj({...valObj, issuerIcaErr: false })

        } else if(type === "fileCreationDirectoryPath") {
            handleInputChange(type, value)
            setValObj({...valObj, fileCreationDirectoryPathErr: false })

        } else if(type === "fileCreationServiceInstanceId") {
            handleInputChange(type, value)
            setValObj({...valObj, fileCreationServiceInstanceIdErr: false })
        } else if(type === "installmentProgramType") {
            handleInputChange(type, value)
            setValObj({...valObj, installmentProgramTypeErr: false })
        } else if(type === "installmentProductType") {
            handleInputChange(type, value)
            setValObj({...valObj, installmentProductTypeErr: false })
        } else if(type === "paymentModelType") {
            handleInputChange(type, value)
        } else if(type === "headerId") {
            handleInputChange(type, value)
            setValObj({...valObj, headerIdErr: false })
        } else if(type === "footerId") {
            handleInputChange(type, value)
            setValObj({...valObj, footerIdErr: false })
        } else if(type === "excelFileTemplateId") {
            handleInputChange(type, value)
            setValObj({...valObj, excelFileTemplateIdErr: false })
        } else if(type === "createFileBasedOnIdentifier") {
            handleInputChange(type, value)
            setValObj({...valObj, createFileBasedOnIdentifierErr: false})
        } else if(type === "createFileBasedOnIdentifierValue") {
            handleInputChange(type, value)
            setValObj({...valObj, createFileBasedOnIdentifierValueErr: false })
        } else if (type === "secondaryIdentifierValue") {
            handleInputChange(type, value)
            setValObj({...valObj, secondaryIdentifierValueErr: false })
        } else if(type === "fileTypeId") {
            handleInputChange(type, value)
            setValObj({...valObj, fileTypeIdErr: false })
        } else if(type === "createFileFrequency") {
            handleInputChange(type, value)
            setValObj({...valObj, createFileFrequencyErr: false })
        } else if(type === "creationTimeInUtc") {
            
            handleInputChange(type, value)
        } else if(type === "uploadTimeInUtc") {
            
            handleInputChange(type, value)
        } else if(type === "monthendFileDownloadTimeInUtc") {
            
            handleInputChange(type, value)
            setValObj({...valObj, monthendFileDownloadTimeInUtcErr: false })
        } else if(type === "monthendFileUploadTimeInUtc") {
            
            handleInputChange(type, value)
            setValObj({...valObj, monthendFileUploadTimeInUtcErr: false })
        } else if (type === "formattedFileId") {
            handleInputChange(type, value)
            setValObj({...valObj, formattedFileIdErr: false})
        }
    }
    
    const handleOnBlur = (field, value) => {
        if(value === null || value === ""){
            setValObj({...valObj, [field]: true })
        } else {
            setValObj({...valObj, [field]: false })
        } 
    }

    const handleFieldClick = (type) => {
        if(type === "formattedFileId") {
            setopenPopover(true)
        } else if(type === "excelFileTemplateId") {
            setOpenTemplateDailog(true)
        }
    }

    const handleDialogAction = async (type) => {
        setopenPopover(false)
        if(type === "exit" ){
            setEditFileFormatId(false)
        } else {
            setEditFileFormatId(true)
        }
    }
    
    const handleTemplateDialog = (value) => {
        setOpenTemplateDailog(false)

        if(value) {
            handleInputChange("excelFileTemplateId", value)
            setValObj({...valObj, excelFileTemplateIdErr: false })
        }
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <form className="action_form merchant_form" noValidate autoComplete="off">
                    <Grid container rowSpacing={2} columnSpacing={2}>

                        <Grid item xs={12} sm={4}>
                            <Tooltip title="Create File Based On Primary Identifier" placement="top">
                                <TextField 
                                    InputLabelProps={{ shrink: true }}
                                    name="createFileBasedOnIdentifier" id="createFileBasedOnIdentifier" label="Create File Based On Primary Identifier" 
                                    variant="outlined" fullWidth 
                                    disabled={currentForm === 'view'} select={currentForm !== 'view'}
                                    value={currentForm === 'view' ? 
                                        mciDetails !== null && mciDetails.createFileBasedOnIdentifier && 
                                        mciDetails.createFileBasedOnIdentifier : mciState.createFileBasedOnIdentifier ? 
                                        mciState.createFileBasedOnIdentifier : ""
                                    }
                                    onChange={(e) => identifierList.length > 0 && handleChange('createFileBasedOnIdentifier', e.target.value)}
                                >
                                    {identifierList && identifierList.length === 0 && 
                                    <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                                    {identifierList && identifierList.map((val, index) => {
                                    return <MenuItem key={index} value={val} className={classes.menu}>
                                        {val}
                                    </MenuItem>
                                    })}
                                    </TextField>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Tooltip title="Create File Based On Primary Identifier Value" placement="top">
                                <TextField 
                                    InputLabelProps={{ shrink: true }} required
                                    fullWidth variant="outlined" name="createFileBasedOnIdentifierValue" 
                                    id="createFileBasedOnIdentifierValue" label={"Create File Based On Primary Identifier Value"}
                                    value={currentForm === 'view' ? 
                                        mciDetails !== null && mciDetails.createFileBasedOnIdentifierValue && 
                                        mciDetails.createFileBasedOnIdentifierValue : mciState.createFileBasedOnIdentifierValue ? 
                                        mciState.createFileBasedOnIdentifierValue : ""
                                    }
                                    select={currentForm !== 'view' ? mciState.createFileBasedOnIdentifier && mciState.createFileBasedOnIdentifier === "payment_product_type": false}
                                    disabled={currentForm === 'view'}
                                    onBlur={(e) => handleOnBlur("createFileBasedOnIdentifierValueErr", e.target.value)}
                                    onChange={e => handleChange("createFileBasedOnIdentifierValue", e.target.value)}
                                >
                                    {productTypeArr && productTypeArr.length === 0 && 
                                    <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                                    {productTypeArr && productTypeArr.map((val, index) => {
                                    return <MenuItem key={index} value={val} className={classes.menu}>
                                        {val}
                                    </MenuItem>
                                    })}
                                    </TextField>
                            </Tooltip>
                                {valObj.createFileBasedOnIdentifierValueErr ? 
                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                    </p>
                                    : null
                                }
                        </Grid>
                        {((currentForm === "view" && mciDetails !== null && (mciDetails.createFileBasedOnIdentifier === "acquirer_id" || mciDetails.createFileBasedOnIdentifier === "psp_id")) || 
                            (currentForm !== "view" && mciState !== null && (mciState.createFileBasedOnIdentifier === "acquirer_id" || mciState.createFileBasedOnIdentifier === "psp_id"))) &&
                            <>
                            <Grid item xs={12} sm={4}>
                                <TextField 
                                    InputLabelProps={{ shrink: true }}
                                    name="secondaryIdentifier" id="secondaryIdentifier" label="Secondary Identifier" 
                                    variant="outlined" fullWidth 
                                    disabled
                                    value={currentForm === 'view' ? 
                                        mciDetails !== null && mciDetails.secondaryIdentifier && 
                                        mciDetails.secondaryIdentifier : mciState.secondaryIdentifier && mciState.secondaryIdentifier
                                    }
                                >
                                    {secondaryIdentifierList && secondaryIdentifierList.length === 0 && 
                                    <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                                    {secondaryIdentifierList && secondaryIdentifierList.map((val, index) => {
                                    return <MenuItem key={index} value={val} className={classes.menu}>
                                        {val}
                                    </MenuItem>
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField 
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth variant="outlined" name="secondaryIdentifierValue" 
                                    id="secondaryIdentifierValue" label={"Secondary Identifier Value"}
                                    value={currentForm === 'view' ? 
                                        mciDetails !== null && mciDetails.secondaryIdentifierValue && 
                                        mciDetails.secondaryIdentifierValue : mciState.secondaryIdentifierValue ? 
                                        mciState.secondaryIdentifierValue : ""
                                    }
                                    select={currentForm !== 'view' ? mciState.secondaryIdentifierValue && mciState.secondaryIdentifierValue === "payment_product_type": false}
                                    disabled={currentForm === 'view'}
                                    onBlur={(e) => handleOnBlur("secondaryIdentifierValueErr", e.target.value)}
                                    onChange={e => handleChange("secondaryIdentifierValue", e.target.value)}
                                >
                                    {productTypeArr && productTypeArr.length === 0 && 
                                    <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                                    {productTypeArr && productTypeArr.map((val, index) => {
                                    return <MenuItem key={index} value={val} className={classes.menu}>
                                        {val}
                                    </MenuItem>
                                    })}
                                </TextField>
                            </Grid> 
                            </>
                        }
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} required
                                fullWidth variant="outlined" name="mciId" 
                                id="mciId" label={"Issuer Id"}
                                value={currentForm === 'view' ? 
                                    mciDetails !== null && mciDetails.mciId && 
                                    mciDetails.mciId : mciState.mciId !== null ? 
                                    mciState.mciId : ""
                                } 
                                disabled={currentForm === 'view' || mciState.createFileBasedOnIdentifier === "acquirer_id"}
                                onBlur={(e) => handleOnBlur("mciIdErr", e.target.value)}
                                onChange={e => e.target.value.length <= 11 && handleChange("mciId", e.target.value)}
                            />
                                {mciState && mciState.mciId && mciState.mciId !== "" && 
                                !numbRegex.test(mciState.mciId) ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                                </p> : valObj.mciIdErr ? 
                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                    </p>
                                    : null
                                }
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }}
                                fullWidth variant="outlined" name="issuerIca" 
                                required={currentForm !== 'view' && mciState !== null && mciState.createFileBasedOnIdentifier && (mciState.createFileBasedOnIdentifier === "ica_id")}
                                id="issuerIca" label={"Issuer Ica"}
                                value={currentForm === 'view' ? 
                                    mciDetails !== null && mciDetails.issuerIca && 
                                    mciDetails.issuerIca : mciState.issuerIca !== null ? 
                                    mciState.issuerIca : ""
                                } 
                                disabled={currentForm === 'view' || mciState.createFileBasedOnIdentifier === "acquirer_id"}
                                onBlur={(e) => handleOnBlur("issuerIcaErr", e.target.value)}
                                onChange={e => e.target.value.length <= 11 && handleChange("issuerIca", e.target.value)}
                            />
                                {mciState && mciState.issuerIca && mciState.issuerIca !== "" && 
                                !numbRegex.test(mciState.issuerIca) ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                                </p> : (mciState && mciState.createFileBasedOnIdentifier && (mciState.createFileBasedOnIdentifier === "ica_id")) && valObj.issuerIcaErr ? 
                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                    </p>
                                    : null
                                }
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                fullWidth variant="outlined" name="regionId" 
                                id="regionId" label={"Region Id"}
                                value={currentForm === 'view' ? 
                                    mciDetails !== null && mciDetails.regionId && 
                                    mciDetails.regionId : mciState.regionId ? 
                                    mciState.regionId : ""
                                }
                                disabled={currentForm === 'view'}
                                onChange={e => e.target.value.length <= 2 && handleInputChange("regionId", e.target.value)}
                            />
                                {mciState && mciState.regionId && mciState.regionId !== "" && 
                                !numbRegex.test(mciState.regionId) ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                                </p> : null
                                }
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }}
                                name="installmentProgramType" id="installmentProgramType" label="Installment Program Type" 
                                variant="outlined" fullWidth 
                                disabled select={currentForm !== 'view'}
                                value={currentForm === 'view' ? 
                                    mciDetails !== null && mciDetails.installmentProgramTypeName && 
                                    mciDetails.installmentProgramTypeName : mciState.installmentProgramTypeName ? 
                                    mciState.installmentProgramTypeName : ""
                                }
                                onChange={(e) => programTypeArr.length > 0 && handleChange('installmentProgramType', e.target.value)}
                            >
                                {programTypeArr && programTypeArr.length === 0 && 
                                <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                                {programTypeArr && programTypeArr.map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                                })}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} required
                                name="installmentProductType" id="installmentProductType" label="Installment Product Type" 
                                variant="outlined" fullWidth 
                                disabled={currentForm === 'view'} select={currentForm !== 'view'}
                                value={currentForm === 'view' ? 
                                    mciDetails !== null && mciDetails.installmentProductType && 
                                    mciDetails.installmentProductType : mciState.installmentProductType ? 
                                    mciState.installmentProductType : ""
                                }
                                onBlur={(e) => handleOnBlur("installmentProductTypeErr", e.target.value)}
                                onChange={(e) => productTypeArr.length > 0 && handleChange('installmentProductType', e.target.value)}
                            >
                                {productTypeArr && productTypeArr.length === 0 && 
                                <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                                {productTypeArr && productTypeArr.map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                                })}
                            </TextField>
                            {valObj.installmentProductTypeErr ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                            </p>
                            : null
                            }
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }}
                                name="paymentModelType" id="paymentModelType" label="Payment Model Type" 
                                variant="outlined" fullWidth 
                                disabled={currentForm === 'view'} select={currentForm !== 'view'}
                                value={currentForm === 'view' ? 
                                    mciDetails !== null && mciDetails.paymentModelTypeName && 
                                    mciDetails.paymentModelTypeName : mciState.paymentModelTypeName ? 
                                    mciState.paymentModelTypeName : ""
                                }
                                onChange={(e) => paymentModelTypeArr.length > 0 && handleChange('paymentModelType', e.target.value)}
                            >
                                {paymentModelTypeArr && paymentModelTypeArr.length === 0 && 
                                <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                                {paymentModelTypeArr && paymentModelTypeArr.map((val, index) => {
                                return <MenuItem key={index} value={val.description} className={classes.menu}>
                                    {val.description}
                                </MenuItem>
                                })}
                            </TextField>
                        </Grid>
                        
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} required
                                name="fileTypeId" id="fileTypeId" label="File Type Id" 
                                variant="outlined" fullWidth 
                                disabled={currentForm === 'view'} select
                                value={currentForm === 'view' ? 
                                    mciDetails !== null && mciDetails.fileTypeId && 
                                    mciDetails.fileTypeId : mciState.fileTypeId ? 
                                    mciState.fileTypeId : ""
                                }
                                onBlur={(e) => handleOnBlur("fileTypeIdErr", e.target.value)}
                                onChange={(e) => fileTypeList.length > 0 && handleChange('fileTypeId', e.target.value)}
                            >
                                {fileTypeList && fileTypeList.length === 0 && 
                                <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                                {fileTypeList && fileTypeList.map((val, index) => {
                                return <MenuItem key={index} value={val.fileTypeId} className={classes.menu}>
                                    {val.fileTypeId + "-" + val.fileTypeDescription + "-" + val.fileFormat}
                                </MenuItem>
                                })}
                            </TextField>
                            {valObj.fileTypeIdErr ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                            </p>
                            : null
                            }
                        </Grid>
                        
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} required
                                name="formattedFileId" id="formattedFileId" label="Formatted File Id" 
                                variant="outlined" fullWidth 
                                disabled={currentForm === "view" || !editFileFormatId}
                                value={currentForm === 'view' ? 
                                    mciDetails !== null && mciDetails.formattedFileId && 
                                    mciDetails.formattedFileId : mciState.formattedFileId ? 
                                    mciState.formattedFileId : ""
                                }
                                onBlur={(e) => handleOnBlur("formattedFileIdErr", e.target.value)}
                                onChange={(e) => editFileFormatId && handleChange('formattedFileId', e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleFieldClick("formattedFileId")}
                                            disabled={currentForm === 'view'}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                            />
                            {valObj.formattedFileIdErr ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                            </p>
                            : null
                            }
                        </Grid>
                        
                        <Grid item xs={12} sm={4}>
                            <Tooltip title="File Creation Service Instance Id" placement="top">
                                <TextField 
                                    InputLabelProps={{ shrink: true }} required
                                    fullWidth variant="outlined" name="fileCreationServiceInstanceId" 
                                    id="fileCreationServiceInstanceId" label={"File Creation Service Instance Id"}
                                    value={currentForm === 'view' ? 
                                        mciDetails !== null && mciDetails.fileCreationServiceInstanceId && 
                                        mciDetails.fileCreationServiceInstanceId : mciState.fileCreationServiceInstanceId ? 
                                        mciState.fileCreationServiceInstanceId : ""
                                    }
                                    disabled
                                    onBlur={(e) => handleOnBlur("fileCreationServiceInstanceIdErr", e.target.value)}
                                    onChange={e => e.target.value.length <= 2 && handleChange("fileCreationServiceInstanceId", e.target.value)}
                                />
                            </Tooltip>
                                {mciState && mciState.fileCreationServiceInstanceId && mciState.fileCreationServiceInstanceId !== "" && 
                                !numbRegex.test(mciState.fileCreationServiceInstanceId) ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                                </p> : valObj.fileCreationServiceInstanceIdErr ? 
                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                    </p>
                                    : null
                                }
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} required
                                fullWidth variant="outlined" name="headerId" 
                                id="headerId" label={"Header Id"}
                                value={currentForm === 'view' ? 
                                    mciDetails !== null && mciDetails.headerId && 
                                    mciDetails.headerId : mciState.headerId ? 
                                    mciState.headerId : ""
                                }
                                disabled
                                onBlur={(e) => handleOnBlur("headerIdErr", e.target.value)}
                                onChange={e => e.target.value <= 100 && handleChange("headerId", e.target.value)}
                            />
                                {mciState && mciState.headerId && mciState.headerId !== "" && 
                                !numbRegex.test(mciState.headerId) ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                                </p> : valObj.headerIdErr ? 
                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                    </p>
                                    : null
                                }
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} required
                                fullWidth variant="outlined" name="footerId" 
                                id="footerId" label={"Footer Id"}
                                value={currentForm === 'view' ? 
                                    mciDetails !== null && mciDetails.footerId && 
                                    mciDetails.footerId : mciState.footerId ? 
                                    mciState.footerId : ""
                                }
                                disabled
                                onBlur={(e) => handleOnBlur("footerIdErr", e.target.value)}
                                onChange={e => e.target.value <= 100 && handleChange("footerId", e.target.value)}
                            />
                                {mciState && mciState.footerId && mciState.footerId !== "" && 
                                !numbRegex.test(mciState.footerId) ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                                </p> : valObj.footerIdErr ? 
                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                    </p>
                                    : null
                                }
                        </Grid>
                        
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} required
                                fullWidth variant="outlined" name="creationTimeInUtc" 
                                id="creationTimeInUtc" label={"Creation Time In Utc"}
                                type='datetime-local' 
                                value={currentForm === 'view' ? 
                                    mciDetails !== null && mciDetails.creationTimeInUtc &&
                                    `${mciDetails.creationTimeInUtc.slice(0,16)}`
                                    : mciState.creationTimeInUtc ? 
                                    `${mciState.creationTimeInUtc.slice(0,16)}` : ""
                                }
                                disabled={currentForm === 'view'}
                                onChange={e => handleChange("creationTimeInUtc", e.target.value)}
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} required
                                fullWidth variant="outlined" name="uploadTimeInUtc" 
                                id="uploadTimeInUtc" label={"Upload Time In Utc"}
                                type='datetime-local'
                                value={currentForm === 'view' ? 
                                    mciDetails !== null && mciDetails.uploadTimeInUtc &&
                                    `${mciDetails.uploadTimeInUtc.slice(0,16)}`
                                    : mciState.uploadTimeInUtc ? 
                                    `${mciState.uploadTimeInUtc.slice(0,16)}` : ""
                                }
                                disabled={currentForm === 'view'}
                                onChange={e => handleChange("uploadTimeInUtc", e.target.value)}
                            />
                        </Grid>
                        {((currentForm === "view" && mciDetails !== null && mciDetails.fileTypeId !== 2) || (currentForm !== "view" && mciState !== null && mciState.fileTypeId !== 2)) &&
                            <Grid item xs={12} sm={4}>
                                <Tooltip title="Month End File Download Time In Utc" placement="top">
                                    <TextField 
                                        InputLabelProps={{ shrink: true }} 
                                        required fullWidth variant="outlined" id={"monthendFileDownloadTimeInUtc"}
                                        name="monthendFileDownloadTimeInUtc" label={"Month End File Download Time In Utc"}
                                        type='datetime-local'
                                        value={currentForm === 'view' ? 
                                            mciDetails !== null && mciDetails.monthendFileDownloadTimeInUtc &&
                                            `${mciDetails.monthendFileDownloadTimeInUtc.slice(0,16)}`
                                            : mciState.monthendFileDownloadTimeInUtc ? 
                                            `${mciState.monthendFileDownloadTimeInUtc.slice(0,16)}` : ""
                                        }
                                        disabled={currentForm === 'view'}
                                        onBlur={(e) => handleOnBlur("monthEndFileDownloadTimeInUtcErr", e.target.value)}
                                        onChange={e => handleChange("monthendFileDownloadTimeInUtc", e.target.value)}
                                        />
                                </Tooltip>
                                {valObj.monthEndFileDownloadTimeInUtcErr ? 
                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                    </p> : null
                                }
                            </Grid>
                        }
                        
                        {((currentForm === "view" && mciDetails !== null && mciDetails.fileTypeId !== 2) || (currentForm !== "view" && mciState !== null && mciState.fileTypeId !== 2)) &&
                            <Grid item xs={12} sm={4}>
                                <Tooltip title="Month End File Upload Time In Utc" placement="top">
                                    <TextField 
                                        InputLabelProps={{ shrink: true }} 
                                        required fullWidth variant="outlined" id={"monthendFileUploadTimeInUtc"}
                                        name="monthendFileUploadTimeInUtc" label={"Month End File Upload Time In Utc"}
                                        type='datetime-local'
                                        value={currentForm === 'view' ? 
                                            mciDetails !== null && mciDetails.monthendFileUploadTimeInUtc &&
                                            `${mciDetails.monthendFileUploadTimeInUtc.slice(0,16)}`
                                            : mciState.monthendFileUploadTimeInUtc ? 
                                            `${mciState.monthendFileUploadTimeInUtc.slice(0,16)}` : ""
                                        }
                                        disabled={currentForm === 'view'}
                                        onBlur={(e) => handleOnBlur("monthendFileUploadTimeInUtcErr", e.target.value)}
                                        onChange={e => handleChange("monthendFileUploadTimeInUtc", e.target.value)}
                                    />
                                </Tooltip>    
                                {valObj.monthendFileUploadTimeInUtcErr ? 
                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                    </p> : null
                                }
                            </Grid>
                        }
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} required
                                fullWidth variant="outlined" name="createFileFrequency" 
                                id="createFileFrequency" label={"File Creation Frequency"}
                                value={currentForm === 'view' ? 
                                    mciDetails !== null && mciDetails.createFileFrequency && 
                                    mciDetails.createFileFrequency : mciState.createFileFrequency ? 
                                    mciState.createFileFrequency : ""
                                }
                                disabled={currentForm === 'view'}
                                onBlur={(e) => handleOnBlur("createFileFrequencyErr", e.target.value)}
                                onChange={e => e.target.value <= 500 && handleChange("createFileFrequency", e.target.value)}
                            />
                                {mciState && mciState.createFileFrequency && mciState.createFileFrequency !== "" && 
                                !numbRegex.test(mciState.createFileFrequency) ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                                </p> : valObj.createFileFrequencyErr ? 
                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                    </p>
                                    : null
                                }
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} required
                                name="excelFileTemplateId" id="excelFileTemplateId" label="Excel File Template" 
                                placeholder={'Click on Add Icon to select the Excel File Template'}
                                variant="outlined" fullWidth 
                                disabled={currentForm === 'view'} 
                                value={currentForm === 'view' ? 
                                    mciDetails !== null && mciDetails.templateFormat && 
                                    mciDetails.templateFormat : mciState.templateFormat ? 
                                    mciState.templateFormat : ""
                                }
                                onBlur={(e) => handleOnBlur("excelFileTemplateIdErr", e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleFieldClick("excelFileTemplateId")}
                                        >
                                            {currentForm === 'view' ? <Visibility/> : mciState.templateFormat && mciState.templateFormat !== "" ? <EditIcon /> : <AddIcon/>}    
                                        </IconButton>
                                        </InputAdornment>
                                    ),
                                    }}
                            />
                            {valObj.excelFileTemplateIdErr ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                            </p>
                            : null
                            }
                        </Grid>
                    </Grid>

                    <Grid container rowSpacing={2} columnSpacing={2}>
                        <Grid item xs={4}>
                            <Typography>
                                <Checkbox
                                    id="isAcknowledgementRequireq"
                                    color="primary"
                                    disabled={currentForm === "view" ? true : false}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    checked={currentForm === 'view' ? 
                                        mciDetails !== null && mciDetails.isAcknowledgementRequireq && 
                                        mciDetails.isAcknowledgementRequireq : mciState.isAcknowledgementRequireq ? 
                                        mciState.isAcknowledgementRequireq : false
                                    }
                                    onChange={(e) => {handleInputChange("isAcknowledgementRequireq", e.target.checked)}}
                                />
                                {"Is Acknowledgement Required"}
                            </Typography>
                        </Grid >
                        <Grid item xs={4}>
                            <Typography>
                                <Checkbox
                                    id="isBlankFileUploadAllowed"
                                    color="primary"
                                    disabled={currentForm === "view" ? true : false}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    checked={currentForm === 'view' ? 
                                        mciDetails !== null && mciDetails.isBlankFileUploadAllowed && 
                                        mciDetails.isBlankFileUploadAllowed : mciState.isBlankFileUploadAllowed ? 
                                        mciState.isBlankFileUploadAllowed : false
                                    }
                                    onChange={(e) => {handleInputChange("isBlankFileUploadAllowed", e.target.checked)}}
                                />
                                {"Is Blank File Upload Allowed"}
                            </Typography>
                        </Grid >
                        <Grid item xs={4}>
                            <Typography>
                                <Checkbox
                                    id="isSftpUploadEnabled"
                                    color="primary"
                                    disabled={currentForm === "view" ? true : false}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    checked={currentForm === 'view' ? 
                                        mciDetails !== null && mciDetails.isSftpUploadEnabled && 
                                        mciDetails.isSftpUploadEnabled : mciState.isSftpUploadEnabled ? 
                                        mciState.isSftpUploadEnabled : false
                                    }
                                    onChange={(e) => {handleInputChange("isSftpUploadEnabled", e.target.checked)}}
                                />
                                {"Is Sftp Upload Enabled"}
                            </Typography>
                        </Grid >
                        <Grid item xs={4}>
                            <Typography>
                                <Checkbox
                                    id="isActive "
                                    color="primary"
                                    disabled={currentForm === "view" ? true : false}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    checked={currentForm === 'view' ? 
                                        mciDetails !== null && mciDetails.isActive && 
                                        mciDetails.isActive : mciState.isActive ? 
                                        mciState.isActive : false
                                    }
                                    onChange={(e) => {handleInputChange("isActive", e.target.checked)}}
                                />
                                {"Is Active "}
                            </Typography>
                        </Grid >
                        <Grid item xs={4}>
                            <Typography>
                                <Checkbox
                                    id="isSmtpUploadEnabled"
                                    color="primary"
                                    disabled={currentForm === "view" ? true : false}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    checked={currentForm === 'view' ? 
                                        mciDetails !== null && mciDetails.isSmtpUploadEnabled && 
                                        mciDetails.isSmtpUploadEnabled : mciState.isSmtpUploadEnabled ? 
                                        mciState.isSmtpUploadEnabled : false
                                    }
                                    onChange={(e) => {handleInputChange("isSmtpUploadEnabled", e.target.checked)}}
                                />
                                {"Is Smtp Upload Enabled"}
                            </Typography>
                        </Grid >
                        <Grid item xs={4}>
                            <Typography>
                                <Checkbox
                                    id="isPLManaged"
                                    color="primary"
                                    disabled={currentForm === "view" ? true : false}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    checked={currentForm === 'view' ?
                                        mciDetails !== null && mciDetails.isPLManaged &&
                                        mciDetails.isPLManaged : mciState.isPLManaged ?
                                            mciState.isPLManaged : false
                                    }
                                    onChange={(e) => { handleInputChange("isPLManaged", e.target.checked) }}
                                />
                                {"Is PL Managed"}
                            </Typography>
                        </Grid >
                    </Grid>
                
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Button fullWidth variant="contained"
                                color="primary" className={"action_login_button"}
                                disabled={currentForm !== "view" && mciState.fileTypeId !== 2 ? createDisableToken : createEOMDisableToken}
                                onClick={() => currentForm !== "view" ? handleMciCreateUpdate() : onChangeView("list")}
                                >
                                {currentForm === "edit" ? "Modify" : currentForm === "view" ? "Close" : "Create"}
                                {mciCreateLoader ? <CircularProgress color="secondary" style={{
                                    "width": "25px", "height": "25px", "marginLeft": "6px", "color": "#fff"}}
                                /> : null}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
            <Dialog
                open={openPopover}
                onClose={() => handleDialogAction("exit")}
                className={"delete_dialog"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="close_dialog" onClick={() => handleDialogAction("exit")}><img src={CloseImgIcon} alt="close" /></div>
                <DialogTitle id="alert-dialog-title">
                    <h2>{"File Format Id Update Confirmation!"}</h2>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`Are you sure, you want to update File Format Id? Please confirm!`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" className="yes" autoFocus
                        onClick={() => handleDialogAction("update")} 
                    >
                        Update
                    </Button>
                    <Button color="primary" className="no"
                        onClick={() => handleDialogAction("exit")} 
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        
            <FileTemplateDialog
                open={openTemplateDialog}
                currentForm={currentForm}
                fileTemplateArr={fileTemplateArr}
                onClose={handleTemplateDialog}
                value={
                    currentForm === 'view' ? 
                    mciDetails !== null && mciDetails.templateFormat && 
                    mciDetails.templateFormat : mciState.templateFormat ? 
                    mciState.templateFormat : ""
                }
            />
        </div>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            
        }, dispatch)
  }
}

function mapStateToProps(state) {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateEditViewMCI);
