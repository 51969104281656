import React,{useEffect, useState} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import format from "date-fns/format";
import addDays from "date-fns/addDays";
import { bindActionCreators } from "redux";
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { reduxForm } from 'redux-form';
import { withStyles } from "tss-react/mui";
import { tss } from "tss-react";
import theme from '../../_theme'
import {
	Stepper, Paper, Step, StepLabel, Box, StepConnector, LinearProgress,
	Grid, Button, Snackbar, IconButton, Dialog, DialogActions,
  DialogTitle, DialogContentText, DialogContent
} from '@mui/material';

import {allZeroRegex, tNcRegex, installmentDescRegex, urlRegex, spaceRegexp} from "../../shared/utility";
import RoundBlack from "../../Assets/round_blank.svg";
import RoundCheck from "../../Assets/round_Check.svg";
import RoundBlankDark from "../../Assets/round_blank_dark.svg";
import { 
	SchemeTypeStepper, MerchantStepper, AmountStepper, 
	BinRangeStepper, ProductGroupStepper
} from "./steps/index";
import BasePageContainer from '../../_common/basePageContainer';
import {
  handleCreateSchemeDate, handleEmailSelect, handleInstallDescSelect,
  handleProductTypeSelect, handlePaymentModeSelect,
  handleGeoScopeSelect, fetchStepper1DropDownDetails, handleStatusCodeSelect,
  fetchCountryDetails, handleCountrySelect, fetchIssuerDetails, handleIssuerSelect,
  handleStepper2Details, handleStepper3Details, fetchBinRangeDetails,
  handleStepper4Details, fetchBrandListForScheme, submitCreateSchemeForm, 
  clearschemeRes, fetchTccMccList, clearTccMcc, 
  fetchBankSchemesAsTemplate, resetIssuerDetails, handleProgTypeNSubType, createBankScheme,
  resetCountryDetails, fetchTenureList
} from "../actions";
import {fetchProductGroups, resetProductGroupList} from '../../oemManagement/productGroup/actions/index.js'

const QontoConnector = withStyles(StepConnector, {
  horizontal: {
    display: "none",
    paddingLeft: "0px",
    marginRight: "0px", flex: "inherit",
  },
  alternativeLabel: {
    top: 0,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)', padding: "0px",
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    display: "none",
    borderTopWidth: 0,
    borderRadius: 0,
  },
});

const useQontoStepIconStyles = tss.create({
  iconCheck: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  steper: {
    padding: "5px !important"
  },
  active: {
    color: '#784af4',
  },
  circle: {
    borderRadius: '50%',
  },
  completed: {
    zIndex: 1,
  },
});

function QontoStepIcon(props) {
  const {classes, cx} = useQontoStepIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={cx(classes.iconCheck, {
        [classes.active]: active,
      })}
    >
      {completed ? <img src={RoundCheck} alt="" className={classes.completed} />
        : active ? <img src={RoundBlankDark} alt="" className={classes.circle} /> : <img src={RoundBlack} alt="" className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

const useStyles = tss.create({
  root: {
    width: '100%',
	},
	menu: {
		fontSize: "15px"
	},
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    width: '100%', marginTop: theme.spacing(3),
    border: "solid 1px #EEEEEE",
		padding: theme.spacing(2, 5, 5, 5),
		marginBottom: theme.spacing(1)
  },
  tablePaper: {
    width: '100%', marginTop: theme.spacing(3),
    border: "solid 1px #EEEEEE",
  },
  th: {
    backgroundColor: "#f9f9f9"
  },
  subPaper: {
    width: '100%',
    border: "solid 1px #EEEEEE",
    marginBottom: theme.spacing(2),
    padding: "0px 0px 0px 0px",
  },
  subRangePaper:{
    width: '100%',
    padding: "0px 10px 0px 10px",
    backgroundColor:"transparent",
  },
  heading: {
    color: "#424242",
    fontFamily: "Quicksand-SemiBold",
    fontSize: "18px",
    letterSpacing: "0",
    lineHeight: "20px",
  },
  accordion: {
    backgroundColor: "#FAFAFA",
    marginBottom: "20px",
    padding: "0px 0px",
  },
  subAccordion: {
    width: "100%",
    backgroundColor: "#fff"
  },
  iconCircleOutline: {

  },
  addRange: {
    alignItems: "center",
    display: "flex",
	},
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  mccbg: {
    backgroundColor: "#f9f9f9"
  },
  expandMoreIcon: {
    // fontSize: "16px !important"
    fontSize: "18px !important"
  }, 
  color: {
    color: "white",
    backgroundColor: `#1BA785 !important`
  },
  container: {
    paddingTop: theme.spacing(2),
  },
});

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" className="progress_box" alignItems="center">
      <Box width="100%">
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const step = ['Scheme Type details', 'Merchant Details', 'Amount Details', 'Bin Details', 'Product Group Details']
let nextEnable = false;

function CreateEditViewScheme(props) {
  let tenureDetailsObject = {
    id: 1, flag: true, installmentFee: null, distributionType: null,
    numberOfInstallments: null, interestRate: 0, installmentFeeValue: null,
    cashbackDetails: {
      cashbackType: "00",
      issuerCashbackPercent: null,
      issuerCashbackValue: null,
      totalCashbackType: "",
      issuerCashbackType: "None",
      additionalChargeSlipText: null
    },
    subventionDetails:{
      brandSubventionPercent:null,
      merchantSubventionPercent:null,
      brandCashbackPercent:null,
      merchantCashbackPercent:null,
      brandCashbackAmount:null,
      merchantCashbackAmount:null
    },
  }
  let amounDetailsObject = {
    id: 0,
    flag: true,
    lowAmount: null,
    highAmount: null,
    paymentFormatData: [tenureDetailsObject]
  }
  let binRangeObject = {
    icaID: "", id: 0, fundingBin: null,
    fundingBinLength: null
  }
  let tncDataObj = {
    language: "",
    name: "",
    preference: "",
    url: "",
    text: ""
  }
  let multiLingualTermsAndConditionsObj = {
    type: "TEXT",
    data: [{ ...tncDataObj }]
  }
  
	const {classes} = useStyles();
  const [mciId, setMciId] = useState(null);
  const [TCC, setTcc] = useState([]);
  const [progress, setProgress] = useState(23);
  const [configID, setConfigId] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [blockedTcc, setBlockedTcc] = useState([]);
  const [blockedMcc, setBlockedMcc] =  useState([]);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [payModelId, setPayModelId] = useState(null);
  const [TenureRecord, setTenureRecord] = useState([]);
  const [expansionId, setExpansionId] = useState(null);
  const [openPopover, setOpenPopOver] = useState(false);
  const [statusCode, setStatusCode] = useState('CREATE');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedBinRange, setSelBinRange] = useState([]);
  const [selectedBrand ,setBrandSelected] = useState(null);
  const [countryCodeNum, setCountryCodeNum] = useState("");
  const [endDateNotSet, setEndDateNotSet] = useState(false);
  const [nextButtonFlag, setNextButtonFlag] = useState(false);
  const [startDateNotSet, setStartDateNotSet] = useState(false);
  const [amountRangeRecord, setAmountRangeRecord] = useState([]);
  const [schemeType, setSchemeType] = useState(props.type || "Brand");
  const [bankSelectedDate, setBankSelectedDate] = useState(null);
  const [bankSelectedStartDate, setBankSelectStartDate] = useState(null);
  const [bankSchemeSelected, setBankSChemeSelected] = useState(null);
  const [binRangeDetail, setBinRangeDetails] = useState([binRangeObject]);
  const [amountDetails, setAmountDetails] = useState([amounDetailsObject]);
  const [amountValCheck, setAmountValCheck] = useState({lowAmount: [], highAmount: []})
  const [instDuplicateCheck, setInstDuplicateCheck] = useState([{installments: []}]);
  const [bankTemplAmountRangeCheck, setBankTemplAmountRangeCheck] = useState({lowAmount: [], highAmount: []})
  const [bankTemplTenureList, setBankTemplTenureList] = useState([])
  const [bankTemplBinList, setBankTemplBinList] = useState([])
  const [partialCofundedRecord, setPartialCofundedRecord] = useState([]);
  const [bankTemplBlockedTccList, setBankTemplBlockedTccList] = useState([])
  const [bankTemplBlockedMccList, setBankTemplBlockedMccList] = useState([])
  const [bankConfigId, setBankConfigId] = useState(null);
  const [selectedProdGroups, setSelectedProdGroups] = useState([])
  const [selectedProdGroupIds, setSelectedProdGroupIds] = useState([])
  const [isPLManaged, setIsPLManaged] = useState(false)
  const [multiLingualTermsAndConditions, setMultiLingualTermsAndConditions] = useState({
    ...multiLingualTermsAndConditionsObj,
    data: [{
      ...tncDataObj,
      language: "en",
      name: "English",
      preference: "PRIMARY"
    }]
  })

  const {
    schemeStartDate, schemeEndDate, stepper1Details, issuerDetails, countryDetails, country, 
    installDesc, geoScope, productType, paymentMode, issuer, binRangeList,
    brandListForScheme, createSchemeRes, currentForm,
    schemeDetail, disableToken, TccMccList, configId, onChangeView, createLoad, TccLoader, bankSchemeList,
    subventionType, programType, bankTemplate, productGroupList, productGroupLoader, tenureList
  } = props

  const userName = localStorage.getItem('userName')

  let bankSchemeListObj = []
  const bankSchemeArr = bankSchemeList && bankSchemeList.map(obj => {
    if(obj.installmentConfigDesc && obj.installmentConfigDesc !== "" && (((obj.installmentConfigDesc).toUpperCase()).includes("[TEMPLATE ONLY]") || ((obj.installmentConfigDesc).toUpperCase()).includes("[TEMPLATEONLY]"))) {
      bankSchemeListObj.push(obj)
      return obj.installmentConfigDesc
    } else {
      return null
    }
  }).filter(obj => obj !== null)


  const installmentconfigId = currentForm === "edit" ? 
    configId ? 
      configId 
      : "" 
    : configID ? 
      configID 
      : "" 
  
  const [steps, setSteps] = useState(step)
  const amountRangeKey =  "amountRangeDetails" 
  const binRangeKey = "binDetails"

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  });

  useEffect(() => {
    handleSchemeChange(props.type || "Brand")
    props.fetchCountryDetails()
    props.resetProductGroupList()
    if(currentForm === "view") {
      setStatusCode("VIEW")
    } else if(currentForm === "edit") {
      setStatusCode("MODIFY")
    } else  {
      setStatusCode("CREATE")
    }

    props.handleCreateSchemeDate([addDays(new Date(), 1), addDays(new Date(), 2)])
    props.fetchStepper1DropDownDetails({schemeType: schemeType})
    return async () => {
      
      //resetting form on component Mount
      props.resetCountryDetails()
      props.resetIssuerDetails();
      await resetOnCreateSelect()
      await props.handleInstallDescSelect(null);
      await props.clearschemeRes(null)
      await props.clearTccMcc()
      props.handleProgTypeNSubType("program", null)
      props.handleProgTypeNSubType("subvention", null)
    }
  }, [])

  const handleSetDate = async () => {
    if(schemeDetail) {
      const startDate = await handleStepper1Date("start")
      await handleStepper1Date("end", startDate)
    } 
  }

  useEffect(() => {
    if(currentForm === "edit") {
      handleSetDate()
      schemeDetail["productGroupObjectIds"] &&  setSelectedProdGroupIds(schemeDetail["productGroupObjectIds"])
      schemeDetail["isoCountryCodeNum"] && props.fetchBrandListForScheme({isoCountryCodeNum: schemeDetail["isoCountryCodeNum"], status: "ACTIVE"});
      schemeDetail["brandId"] && props.fetchProductGroups({
        pageNo: 1, pageSize: 1000, sortBy: "name", sortDirection: "ASC", 
        brandId: schemeDetail["brandId"], status: "ACTIVE"
      })
    } else if(currentForm === "view" && schemeDetail) {
      handleSetDate();
      const arr = ["BRAND_MERCHANT_COFUNDED", "CASHBACK_ONLY"]
      const progType = schemeDetail['programType'] && arr.includes(schemeDetail['programType']) ?
        schemeDetail['programType'].split("_").join(" ") : schemeDetail['programType'];
      props.handleProgTypeNSubType("program", progType);
      props.handleProgTypeNSubType("subvention", schemeDetail['subventionType'] ? 
        schemeDetail['subventionType'] : "")

      setBrandSelected(schemeDetail.brandId ? schemeDetail.brandId : null)
      schemeDetail["productGroupObjectIds"] &&  setSelectedProdGroupIds(schemeDetail["productGroupObjectIds"])
      schemeDetail["isoCountryCodeNum"] && props.fetchBrandListForScheme({isoCountryCodeNum: schemeDetail["isoCountryCodeNum"], status: "ACTIVE"});
      schemeDetail["brandId"] && props.fetchProductGroups({
        pageNo: 1, pageSize: 1000, sortBy: "name", sortDirection: "ASC", 
        brandId: schemeDetail["brandId"], status: "ACTIVE"
      })
    }
  }, [schemeDetail])
  
  useEffect(() => {
    if(currentForm === "edit" && bankTemplate !== null && bankTemplate) {
      const tempBinList = bankTemplate !== null && bankTemplate && bankTemplate["binDetails"] !== null && bankTemplate["binDetails"] &&
        bankTemplate["binDetails"].map((obj) => {
        return obj
      })
      setBankTemplBinList(tempBinList)

      setBankTemplBlockedTccList(bankTemplate["blackListedTCCs"])
      setBankTemplBlockedMccList(bankTemplate["blackListedMCCs"])

      let tempBankAmtValCheck = {lowAmount: [], highAmount: []}
      let tempBankTenureList = [];
      bankTemplate && bankTemplate["amountRangeDetails"] !== null && 
      bankTemplate["amountRangeDetails"].map((obj, i) => {
        tempBankAmtValCheck["lowAmount"].push(obj.lowAmount)
        tempBankAmtValCheck["highAmount"].push(obj.highAmount)
        obj.paymentFormatData && obj.paymentFormatData.map(tenure => {
          if(!tempBankTenureList.includes(tenure.numberOfInstallments)) {
            tempBankTenureList.push(tenure.numberOfInstallments);
          }
          return null;
        })
        setBankTemplTenureList(tempBankTenureList)
        return null;
      })
      setBankTemplAmountRangeCheck(tempBankAmtValCheck);
    } 
  }, [bankTemplate])

  useEffect(() => {
    if(schemeType && schemeType === "Bank" && tenureList !== null && tenureList.length > 0) {      
      const tempTenureList = tenureList.map(obj => obj.tenure)
      setBankTemplTenureList(tempTenureList)
    }
  }, [tenureList])

  const autoPopulateMultiLingualState = (schemeDetailObj) => {
    let languagesSelected = [];
    countryDetails && countryDetails.countryList && countryDetails.countryList.map(obj => {
      if (schemeDetailObj['isoCountryCodeNum'] && obj.isoCountryCodeNum && (obj.isoCountryCodeNum === schemeDetailObj['isoCountryCodeNum'])) {
        languagesSelected = obj.languages
      }
    })
    if (schemeDetailObj['multiLingualTermsAndConditions']) {
      let tempData = schemeDetailObj['multiLingualTermsAndConditions']
      setMultiLingualTermsAndConditions({
        ...tempData,
        data: tempData.data.map((obj) => {
          return {
            ...obj,
            name: languagesSelected.filter((val) => {
              return val.code === obj.language
            })[0].name
          }
        })
      });
    } else if (schemeDetailObj['termsAndConditions']) {
      setMultiLingualTermsAndConditions({
        ...multiLingualTermsAndConditionsObj,
        data: [{
          ...tncDataObj,
          language: "en",
          name: "English",
          preference: "PRIMARY",
          text: schemeDetailObj['termsAndConditions']
        }]
      });
    } else {
      setMultiLingualTermsAndConditions({
        type: "NONE"
      })
    }
  }

  useEffect(() => {
    if ((currentForm === "view" || currentForm === "edit") && schemeDetail && countryDetails) {
      autoPopulateMultiLingualState(schemeDetail)
    }
    if (schemeDetail && currentForm === "edit") {
      handleAutoPopulateFields(schemeDetail, true);
    }
  }, [schemeDetail, stepper1Details, countryDetails])

  const handleAutoPopulateFields = (schemeDetail, check) => {
    //setting value for all redux state of create scheme
    //  ----------------- >  Stepper 1  < ------------------------
    let countryName = null
    countryDetails && countryDetails !== null &&
      countryDetails.countryList.map(obj => {
        if(schemeDetail['isoCountryCodeNum'] && obj.isoCountryCodeNum && (obj.isoCountryCodeNum === schemeDetail['isoCountryCodeNum']) && countryName === null) {
          countryName = obj.name
          props.handleCountrySelect(countryName);
          setCountryCodeNum(obj.isoCountryCodeNum)
          props.type !== "Bank" ?
            props.fetchIssuerDetails({
              status: "ACTIVE",
              isoCountryCodeNum: obj.isoCountryCodeNum,
            })
            : props.fetchIssuerDetails({
              status: "ACTIVE",
              isoCountryCodeNum: obj.isoCountryCodeNum,
              isPLManaged: true
            })
        }
      })
    //find issuer from IssuerDetails list metaData
    props.handleIssuerSelect(schemeDetail['issuerName']);
    
    setMciId(schemeDetail['mciID']);

    check && props.handleInstallDescSelect(schemeDetail['installmentConfigDesc']);
    setBankSChemeSelected(schemeDetail['bankInstallmentConfigID']);
    setBankConfigId(check ? schemeDetail['bankInstallmentConfigID'] : schemeDetail['installmentConfigID'])
    check && setConfigId(schemeDetail['installmentConfigID']);
    setStatusCode(check ? 'MODIFY' : 'CREATE')

    let payModename = null
    stepper1Details && stepper1Details !== null &&
      stepper1Details['paymentModel'].map(obj => {
        if(parseInt(obj.code) === parseInt(schemeDetail['paymentModel'])) {
          payModename = obj.name
        }
      })
      
    props.handlePaymentModeSelect(payModename);
    setPayModelId(schemeDetail['paymentModel'])
    props.handleProductTypeSelect(schemeDetail['productType']);

    props.handleGeoScopeSelect(schemeDetail['geoScope']);
    
    const arr = ["BRAND_MERCHANT_COFUNDED", "CASHBACK_ONLY"]
    const progType = schemeDetail['programType'] && arr.includes(schemeDetail['programType']) ?
      schemeDetail['programType'].split("_").join(" ") : schemeDetail['programType'];
    if(check) {
      props.handleProgTypeNSubType("program", progType);
      props.handleProgTypeNSubType("subvention", schemeDetail['subventionType'])
    }

    //  ----------------- >  Stepper 2  < ------------------------
    setBlockedTcc(schemeDetail['blackListedTCCs'])
    setBlockedMcc(schemeDetail['blackListedMCCs'])
    
    //  ----------------- >  Stepper 3  < ------------------------
    let tempAmtValCheck = {lowAmount: [], highAmount: []}
    let tempInstDuplicateCheck = []
    setAmountDetails(schemeDetail[amountRangeKey] !== null && schemeDetail[amountRangeKey].map((obj, i) => {
      tempAmtValCheck["lowAmount"].push(obj.lowAmount)
      tempAmtValCheck["highAmount"].push(obj.highAmount)
      tempInstDuplicateCheck.push({installments: []})
      return {
        ...obj,
        id: i,
        flag: true,
        paymentFormatData: obj.paymentFormatData.map((payment, p) => {
          tempInstDuplicateCheck[i]["installments"].push(parseInt(payment.numberOfInstallments))
          if(Object.keys(payment).includes("subventionDetails") && payment.subventionDetails !== null){
            return {
              ...payment,
              id: i+(p+1),
              flag: true
            }
          } else {
            return {
              ...payment,
              id: i+(p+1),
              flag: true,
              subventionDetails:{
                brandSubventionPercent: null,
                merchantSubventionPercent: null,
                brandCashbackPercent: null,
                merchantCashbackPercent: null,
                brandCashbackAmount: null,
                merchantCashbackAmount: null
              }
            }
          }
        })
      }
    }))
    setAmountValCheck(tempAmtValCheck)
    setInstDuplicateCheck(tempInstDuplicateCheck)

    //  ----------------- >  Stepper 4  < ------------------------
    let binRangeArr = []
    setBinRangeDetails(schemeDetail[binRangeKey] !== null && schemeDetail[binRangeKey].map((obj, index) => {
      binRangeArr.push(obj.fundingBin)
      return {
        id: index,
        icaID: obj.icaID,
        fundingBin: obj.fundingBin,
        fundingBinLength: obj.fundingBinLength
      }
    }))
    setSelBinRange(binRangeArr)

    if(check) {

      //  ----------------- >  Stepper 5  < ------------------------
      setBrandSelected(schemeDetail.brandId ? schemeDetail.brandId : null)

    }
  }

  useEffect(() => {
    resetOnCreateSelect()
  }, [currentForm])

  useEffect(() => {
    if(createSchemeRes && createSchemeRes !== null && !createSchemeRes.st) {
        setOpenSnackbar(true)
        if(createSchemeRes && createSchemeRes.status && (createSchemeRes.status).toString() === "504"){
          setSnackbarMsg("Gateway Timeout!")

        }if(createSchemeRes && createSchemeRes.status && (createSchemeRes.status).toString() === "500"){
          setSnackbarMsg("Internal Server Error!")

        } else if(createSchemeRes.msg) {
          setSnackbarMsg(createSchemeRes.msg)

        } else if(createSchemeRes.errors && createSchemeRes.errors.length > 0) {
          const msgStr = createSchemeRes.errors.map(val => val.errorDesc).join(", ")
          setSnackbarMsg(msgStr)

        }
    } else if(createSchemeRes && createSchemeRes !== null && createSchemeRes.st) {
      if(statusCode === "CREATE") {
        setOpenPopOver(true)
      } else {
        setOpenSnackbar(true)
        setSnackbarMsg(`Scheme with Id:${createSchemeRes.installmentConfigID} Modified Successfully!`)
        resetOnCreateSelect()
      }
      setConfigId(createSchemeRes.installmentConfigID)
    }
  }, [createSchemeRes]);

  useEffect(() => {
    if(TCC && TCC.length === 0 ){
      const finalTCc = TccMccList !== null ? TccMccList.map((val, t) => {
        return {
          ...val,
          id: t+1,
          flag: currentForm !== "view" ? blockedTcc.includes(val.tccCode) : false,
          mccList: val.mccList.map((mcc, m) => {
            return {
              ...mcc,
              id: `${t+1}${m+1}`,
              flag: currentForm !== "view" ? blockedMcc.includes(mcc.mccCode) : false,
            }
          })
        }
      }) : [];
      setTcc(finalTCc)
    }
  }, [TccMccList])

  const resetOnCreateSelect = async () => {
    if(currentForm === "create") {
      
      //resetting value for all redux state of create scheme
      //  ----------------- >  Stepper 1  < ------------------------
      await props.handleInstallDescSelect(null);
      await setBankSChemeSelected(null);
      await props.handlePaymentModeSelect(null);
      await setPayModelId(null)
      await props.handleProductTypeSelect(null);
      await setStatusCode('CREATE')
      await props.handleCountrySelect(null);

      //find issuer from IssuerDetails list metaData
      await props.handleIssuerSelect(null);
      await setMciId(null);
      await props.handleGeoScopeSelect(null);
      await props.handleEmailSelect(null);
      
      //  ----------------- >  Stepper 2  < ------------------------
      await setBlockedTcc([])
      await setBlockedMcc([])
      
      //  ----------------- >  Stepper 3  < ------------------------
      await setAmountDetails([amounDetailsObject])

      //  ----------------- >  Stepper 4  < ------------------------
      await setBinRangeDetails([binRangeObject])

      //  ----------------- >  Stepper 5  < ------------------------
      await setSelectedProdGroups([])
      await setSelectedProdGroupIds([])
    }
  }  

  const handleSnackBarAction = (value) => {
    setOpenSnackbar(false)
    props.clearschemeRes(null)
    const token = createSchemeRes && createSchemeRes !== null && createSchemeRes.st
    token && onChangeView("list")
    props.clearschemeRes(null)
  }

  const handleDialogAction = async (value, type) => {
    props.clearschemeRes(null)
    setOpenPopOver(value)
    if (type === "Create") {
      setStatusCode('CREATE')
      resetOnCreateSelect()
      setTcc([])
      await props.clearTccMcc()
      await setActiveStep(0)
      await setProgress(23)
      await setSelBinRange([])
      //manually clear all fields
      props.handleCreateSchemeDate([addDays(new Date(), 1), addDays(new Date(), 2)])

    } else if(type === "Modify") {
        setStatusCode('MODIFY')
        //send installmentConfigId in request and statusCode as MODIFY
        await setActiveStep(0)
        await setProgress(23)
        
    } else {
      onChangeView("list")
    }
  }

  const handleNextEnable = async () => { 
    if(currentForm === "create" || currentForm === "edit"){
      if (activeStep === 0) {
          const schemeTypeCheck = 
            props.type !== "Bank" ?
              (installDesc && installDesc !== null && installDesc.length > 0 && installmentDescRegex.test(installDesc) && !allZeroRegex.test(((installDesc).split(" ")).join(""))) 
                && productType && productType !== null && paymentMode && paymentMode !== null && country && country !== null && bankSchemeSelected && bankSchemeSelected !== null 
                && issuer && issuer !== null && geoScope && geoScope !== null 
                && subventionType && subventionType !== null && programType && programType !== null
                  : (installDesc && installDesc !== null && installDesc.length > 0 && installmentDescRegex.test(installDesc) && !allZeroRegex.test(((installDesc).split(" ")).join(""))) 
                  && productType && productType !== null && paymentMode && paymentMode !== null && country && country !== null
                  && issuer && issuer !== null && geoScope && geoScope !== null 
        
          const tncCheck = multiLingualTermsAndConditions && multiLingualTermsAndConditions.type
            && ((multiLingualTermsAndConditions.type === "QR" && multiLingualTermsAndConditions.data && multiLingualTermsAndConditions.data.length > 0
              && multiLingualTermsAndConditions.data.filter((obj) =>
              !(obj.language && obj.preference && obj.url && urlRegex.test(obj.url) && (obj.text === "" || !tNcRegex.test(obj.text)) && !spaceRegexp.test(obj.text))
            ).length === 0)
            || (multiLingualTermsAndConditions.type === "TEXT" && multiLingualTermsAndConditions.data && multiLingualTermsAndConditions.data.length > 0
              && multiLingualTermsAndConditions.data.filter((obj) => 
              !(obj.language && obj.preference && obj.text && !tNcRegex.test(obj.text) && !spaceRegexp.test(obj.text))
            ).length === 0) || multiLingualTermsAndConditions.type === "NONE")
        
          nextEnable = schemeTypeCheck && tncCheck
            
          if (nextButtonFlag !== nextEnable) {
              setNextButtonFlag(nextEnable)
          }
      } else if (activeStep === 1) {
          nextEnable = true
          if(nextButtonFlag !== nextEnable) {
              setNextButtonFlag(nextEnable)
          }
      } else if (activeStep === 2) {
        let payCheck = null
        let bankPayCheck = null
        let amountCheck = null
        await amountDetails.map((amount) => {
          if((amountCheck !== null && amountCheck) || amountCheck === null ) {
            amountCheck = amount.lowAmount !== null && amount.highAmount !== null &&
              parseFloat(amount.lowAmount) > 0 && parseFloat(amount.highAmount) > 0 &&
              parseFloat(amount.lowAmount) < parseFloat(amount.highAmount);
              amount['paymentFormatData'].map(payment => {
                if ((bankPayCheck !== null && bankPayCheck) || bankPayCheck === null) {
                  bankPayCheck = ((payment['interestRate'] !== null && parseFloat(payment['interestRate']) >= 0 && parseFloat(payment['interestRate']) < 100) &&
                  ((payment['installmentFee'] !== null && ((payment['installmentFee'] === "Fixed" && payment['installmentFeeValue'] !== null &&
                    parseFloat(payment['installmentFeeValue']) > 0) || (payment['installmentFee'] === "Percentage" && payment['installmentFeeValue'] !== null && 
                    parseFloat(payment['installmentFeeValue']) < 100 && parseFloat(payment['installmentFeeValue']) > 0)|| (payment['installmentFee'] === "None"))) || 
                  (payment['installmentFee'] === null)) && payment['numberOfInstallments'] !== null && parseFloat(payment['numberOfInstallments']) >= 0 &&
                  (payment['cashbackDetails']['issuerCashbackType'] === "" || payment['cashbackDetails']['issuerCashbackType'] === "None" ||
                  (payment['cashbackDetails']['issuerCashbackType'] === "Fixed" && payment['cashbackDetails']['issuerCashbackValue'] !== null 
                  ) || (payment['cashbackDetails']['issuerCashbackType'] === "Percentage" &&
                  payment['cashbackDetails']['issuerCashbackValue'] !== null && 
                  parseFloat(payment['cashbackDetails']['issuerCashbackValue']) < 100
                  )) && (payment['cashbackDetails']['additionalChargeSlipText'] === null || payment['cashbackDetails']['additionalChargeSlipText'] === "" 
                  || (payment['cashbackDetails']['additionalChargeSlipText'] !== null && !tNcRegex.test(payment['cashbackDetails']['additionalChargeSlipText']))))
                }
                
                if((payCheck !== null && payCheck) || payCheck === null ) {
                  payCheck = ((payment['interestRate'] !== null && parseFloat(payment['interestRate']) >= 0 && parseFloat(payment['interestRate']) < 100) &&
                    ((payment['installmentFee'] !== null && ((payment['installmentFee'] === "Fixed" && payment['installmentFeeValue'] !== null &&
                      parseFloat(payment['installmentFeeValue']) > 0) || (payment['installmentFee'] === "Percentage" && payment['installmentFeeValue'] !== null && 
                      parseFloat(payment['installmentFeeValue']) < 100 && parseFloat(payment['installmentFeeValue']) > 0)|| (payment['installmentFee'] === "None"))) || 
                    (payment['installmentFee'] === null)) && payment['numberOfInstallments'] !== null && parseFloat(payment['numberOfInstallments']) >= 0 &&
                    (payment['cashbackDetails']['issuerCashbackType'] === "" || payment['cashbackDetails']['issuerCashbackType'] === "None" ||
                    (payment['cashbackDetails']['issuerCashbackType'] === "Fixed" && payment['cashbackDetails']['issuerCashbackValue'] !== null 
                    ) || (payment['cashbackDetails']['issuerCashbackType'] === "Percentage" &&
                    payment['cashbackDetails']['issuerCashbackValue'] !== null && 
                    parseFloat(payment['cashbackDetails']['issuerCashbackValue']) < 100
                    )) && (payment['cashbackDetails']['additionalChargeSlipText'] === null || payment['cashbackDetails']['additionalChargeSlipText'] === "" 
                    || (payment['cashbackDetails']['additionalChargeSlipText'] !== null && !tNcRegex.test(payment['cashbackDetails']['additionalChargeSlipText'])))
                    
                    && ((programType === "BRAND" && subventionType === "FULL" && payment['subventionDetails'] && payment['subventionDetails']['brandSubventionPercent'] !== null
                    && parseFloat(0) <= parseFloat(payment['subventionDetails']['brandSubventionPercent']) && parseFloat(payment['subventionDetails']['brandSubventionPercent']) <= parseFloat(100.00))
                    || (programType === "BRAND" && subventionType === "PARTIAL" && payment['subventionDetails'] && ((payment['subventionDetails']['brandCashbackPercent'] !== null && 
                    parseFloat(0) <= parseFloat(payment['subventionDetails']['brandCashbackPercent']) && parseFloat(payment['subventionDetails']['brandCashbackPercent']) <= parseFloat(100.00)) || 
                    (payment['subventionDetails']['brandCashbackAmount'] !== null && parseFloat(0) <= parseFloat(payment['subventionDetails']['brandCashbackAmount']) && parseFloat(payment['subventionDetails']['brandCashbackAmount']) <= parseFloat(amount.lowAmount)))) || 

                    (programType === "MERCHANT" && subventionType === "FULL" && payment['subventionDetails'] && payment['subventionDetails']['merchantSubventionPercent'] !== null
                    && parseFloat(0) <= parseFloat(payment['subventionDetails']['merchantSubventionPercent']) && parseFloat(payment['subventionDetails']['merchantSubventionPercent']) <= parseFloat(100.00))
                    || (programType === "MERCHANT" && subventionType === "PARTIAL" && payment['subventionDetails'] && ((payment['subventionDetails']['merchantCashbackPercent'] !== null && 
                    parseFloat(0) <= parseFloat(payment['subventionDetails']['merchantCashbackPercent']) && parseFloat(payment['subventionDetails']['merchantCashbackPercent']) <= parseFloat(100.00)) || 
                    (payment['subventionDetails']['merchantCashbackAmount'] !== null && parseFloat(0) <= parseFloat(payment['subventionDetails']['merchantCashbackAmount']) && parseFloat(payment['subventionDetails']['merchantCashbackAmount']) <= parseFloat(amount.lowAmount)))) || 
                    
                    (programType === "BRAND MERCHANT COFUNDED" && subventionType === "PARTIAL" && payment['subventionDetails'] && ((payment['subventionDetails']['brandCashbackPercent'] !== null && 
                    parseFloat(0) <= parseFloat(payment['subventionDetails']['brandCashbackPercent']) && parseFloat(payment['subventionDetails']['brandCashbackPercent']) <= parseFloat(100.00)) || 
                    (payment['subventionDetails']['brandCashbackAmount'] !== null && parseFloat(0) <= parseFloat(payment['subventionDetails']['brandCashbackAmount']))) && 
                    ((payment['subventionDetails']['merchantCashbackPercent'] !== null && 
                    parseFloat(0) <= parseFloat(payment['subventionDetails']['merchantCashbackPercent']) && parseFloat(payment['subventionDetails']['merchantCashbackPercent']) <= parseFloat(100.00)) || 
                    (payment['subventionDetails']['merchantCashbackAmount'] !== null && parseFloat(0) <= parseFloat(payment['subventionDetails']['merchantCashbackAmount'])))) || 
                    
                    (programType === "BRAND MERCHANT COFUNDED" && subventionType === "FULL" && payment['subventionDetails'] && payment['subventionDetails']['brandSubventionPercent'] !== null && 
                    parseFloat( 0) < parseFloat(payment['subventionDetails']['brandSubventionPercent']) && parseFloat(payment['subventionDetails']['brandSubventionPercent']) <= parseFloat(100.00) &&
                    payment['subventionDetails']['merchantSubventionPercent'] !== null && 
                    parseFloat(0) < parseFloat(payment['subventionDetails']['merchantSubventionPercent']) && parseFloat(payment['subventionDetails']['merchantSubventionPercent']) <= parseFloat(100.00)) 

                    || (programType === "CASHBACK ONLY")))
                  }
                return null
              })
            }
            return null
        })

        nextEnable = await (schemeType !== "Bank" ? amountCheck && payCheck && amountRangeRecord.length === 0 && partialCofundedRecord.length === 0 
        : amountCheck && bankPayCheck && amountRangeRecord.length === 0)
        
        if(nextButtonFlag !== nextEnable) {
          setNextButtonFlag(nextEnable)
        }
        
      } else if (activeStep === 3) {
        let binCheck = null
        await binRangeDetail.map(bin => {
          if((binCheck !== null && binCheck) || binCheck === null ) {
            binCheck = bin['fundingBin'] !== null && bin['fundingBinLength'] !== null
          }
          return null
        })
        nextEnable = await (binCheck)
        if(nextButtonFlag !== nextEnable) {
            setNextButtonFlag(nextEnable)
        }
      } else if (activeStep === 4) {
          nextEnable = (
              selectedProdGroups && selectedProdGroups.length > 0
          )
          if(nextButtonFlag !== nextEnable) {
              setNextButtonFlag(nextEnable)
          }
      }
    } else if(currentForm === "view"){
      !nextButtonFlag && setNextButtonFlag(true)
    }
    return null
  }
  handleNextEnable()

  const handleRangeCheckForAmt = (id, type) => {
    if(type === "add") {
      !amountRangeRecord.includes(id) && setAmountRangeRecord([...amountRangeRecord, id])
    } else {
      const tempArr = [...amountRangeRecord].filter(val => val !== id)
      setAmountRangeRecord(tempArr)
    }
  }
  
  const handlePartialCofundedValidCheck = (id, type) => {
    if(type === "add") {
      !partialCofundedRecord.includes(id) && setPartialCofundedRecord([...partialCofundedRecord, id])
    } else {
      const tempArr = [...partialCofundedRecord].filter(val => val !== id)
      setPartialCofundedRecord(tempArr)
    }
  }

  const handleTenureCheckForAmt = (id, type) => {
    if(type === "add") {
      !TenureRecord.includes(id) && setTenureRecord([...TenureRecord, id])
    } else {
      const tempArr = [...TenureRecord].filter(val => val !== id)
      setTenureRecord(tempArr)
    }
  }

  const handleStepback = () => {
    const index = activeStep
    let currentProgress = progress;
    setActiveStep(index - 1);
    let incPercToken =  props.type !== "Brand"

    if (index === 1) {
      currentProgress = incPercToken ? 24 : 22
    }
    if (index === 2) {
      currentProgress = incPercToken ? 50 : 45
      TccMccList === null && props.fetchTccMccList({isoCountryCodeNum: ""})
    }
    if (index === 3) {
      currentProgress = incPercToken ? 76 : 67
      tenureList === null && fetchTenureList()
    }
    if (index === 4) {
      currentProgress = incPercToken ? 100 : 89
    }
    setProgress(currentProgress);
  } 

  const handleNext = () => {
    if(activeStep === 0) {
      props.fetchTccMccList({isoCountryCodeNum: countryCodeNum})
    }
    if(activeStep === 1) {
      props.handleStepper2Details(TCC)
      setNextButtonFlag(false)
      tenureList === null && props.fetchTenureList()
    } else if(activeStep === 2) {
      props.handleStepper3Details(amountDetails)
      props.fetchBinRangeDetails({
          "mciId" : mciId
      })
    } else if(activeStep === 3) {
        props.handleStepper4Details(binRangeDetail)
        props.fetchBrandListForScheme({isoCountryCodeNum: countryCodeNum, status: "ACTIVE"});
        (schemeType === "Merchant" && handleCreateSchemeSubmit()) || (schemeType === "Bank" && handleCreateBankScheme());

    } else if(schemeType === "Brand" && activeStep === 4) {
        handleCreateSchemeSubmit();
    }
    let incPerc = 22;
    if(props.type !== "Brand") {
      incPerc = 26
    }
    if(schemeType === "Brand" ? activeStep < 4 : activeStep < 3) {
      handleCurrentStep("next", activeStep)
    }
    handleNextEnable()
  };

  const handleCreateSchemeSubmit = () => {
    const progTypeCheck = programType === "CASHBACK ONLY";
    const tempAmountDetails = amountDetails.map((obj) => {
      return {
        ...obj,
        lowAmount: obj.lowAmount,
        highAmount: obj.highAmount,
        paymentFormatData: obj.paymentFormatData.map((payObj) => {
          return {
            ...payObj,
            numberOfInstallments: progTypeCheck ? 0 : payObj.numberOfInstallments,
            interestRate: progTypeCheck ? "0.00" : payObj.interestRate,
            installmentFeeValue: payObj.installmentFee !== null && payObj.installmentFee !== "None" ?
              payObj.installmentFeeValue : null,
            cashbackDetails: {
              ...payObj.cashbackDetails,
              issuerCashbackPercent: payObj.cashbackDetails.issuerCashbackPercent !== null ? 
                payObj.cashbackDetails.issuerCashbackPercent : null,
              issuerCashbackValue: payObj.cashbackDetails.issuerCashbackValue !== null ? 
                payObj.cashbackDetails.issuerCashbackValue : null,
            }
          }
        })
      }
    });
    const arr = ["BRAND MERCHANT COFUNDED", "CASHBACK ONLY"]
    const tempProgType = programType && arr.includes(programType) ?
      (programType.split(" ")).join("_"): programType;

    const tempGroupIds = selectedProdGroups.map((obj) => {
      return obj.id
    })

    const tempMultiLingualTermsAndConditions = 
      multiLingualTermsAndConditions && multiLingualTermsAndConditions.type && multiLingualTermsAndConditions.type !== "NONE" ? multiLingualTermsAndConditions : null

    const reqPayload = {
        installmentConfigID: installmentconfigId,
        bankInstallmentConfigID: bankConfigId,
        mciID: mciId,
        statusCode: statusCode,
        installmentConfigDesc: installDesc,
        productType: productType,
        schemeType: (props.type).toUpperCase(),
        programType: tempProgType,
        subventionType: subventionType,
        paymentModel: payModelId,
        geoScope: geoScope,
        installmentStartDate: format(schemeStartDate, 'yyyyMMdd'),
        installmentEndDate: format(schemeEndDate, 'yyyyMMdd'),
        merchantDetails: [],
        issuerEndpoint: productType === "DEBIT" ? issuer :"",
        blackListedMCCs: blockedMcc,
        blackListedTCCs: blockedTcc,
        binDetails: binRangeDetail,
        amountRangeDetails: tempAmountDetails,
        velocityCheckDescription: "",
        productGroupObjectIds: tempGroupIds,
        createdBy: userName,
        modifiedBy: userName,
        multiLingualTermsAndConditions: tempMultiLingualTermsAndConditions
    }
    props.submitCreateSchemeForm(reqPayload);
    //calling final API for Creating Scheme
  }

  const handleCreateBankScheme = () => {
    const progTypeCheck = programType === "CASHBACK ONLY";
    const tempAmountDetails = amountDetails.map((obj) => {
      return {
        ...obj,
        lowAmount: obj.lowAmount,
        highAmount: obj.highAmount,
        paymentFormatData: obj.paymentFormatData.map((payObj) => {
          return {
            ...payObj,
            numberOfInstallments: progTypeCheck ? 0 : payObj.numberOfInstallments,
            interestRate: progTypeCheck ? "0.00" : payObj.interestRate,
            installmentFeeValue: payObj.installmentFee !== null && payObj.installmentFee !== "None" ?
              payObj.installmentFeeValue : null,
            cashbackDetails: {
              ...payObj.cashbackDetails,
              issuerCashbackPercent: payObj.cashbackDetails.issuerCashbackPercent !== null ? 
                payObj.cashbackDetails.issuerCashbackPercent : null,
              issuerCashbackValue: payObj.cashbackDetails.issuerCashbackValue !== null ? 
                payObj.cashbackDetails.issuerCashbackValue : null,
            }
          }
        })
      }
    });

    const tempMultiLingualTermsAndConditions =
      multiLingualTermsAndConditions && multiLingualTermsAndConditions.type && multiLingualTermsAndConditions.type !== "NONE" ? multiLingualTermsAndConditions : null

    const reqPayload = {
      installmentConfigID: installmentconfigId,
      mcID: mciId,
      statusCode: statusCode,
      installmentConfigDesc: installDesc,
      productType: productType,
      schemeType: (props.type).toUpperCase(),
      paymentModel: payModelId,
      geoScope: geoScope,
      installmentStartDate: format(schemeStartDate, 'yyyyMMdd'),
      installmentEndDate: format(schemeEndDate, 'yyyyMMdd'),
      merchantDetails: [],
      issuerEndpoint: productType === "DEBIT" ? issuer :"",
      blackListedMCCs: blockedMcc,
      blackListedTCCs: blockedTcc,
      binDetails: binRangeDetail,
      amountRangeDetails: tempAmountDetails,
      createdBy: userName,
      modifiedBy: userName,
      multiLingualTermsAndConditions: tempMultiLingualTermsAndConditions
    }
    props.createBankScheme(reqPayload);
  }

  const handleSchemeChange = (value) => {
    setSchemeType(value)
    if(value === "Brand") {
      setSteps(step)
    } else {
      setSteps(step.slice(0,4))
    }
  }

	const handleCurrentStep = (type, index) => {
			let currentProgress = 23;
      setActiveStep(type === "next" ? index + 1 : index);
      let incPercToken =  props.type !== "Brand"

			if (index === 1) {
        currentProgress = incPercToken ? 50 : 45
        TccMccList === null && props.fetchTccMccList({isoCountryCodeNum: ""})
			}
			if (index === 2) {
        currentProgress = incPercToken ? 76 : 67
        tenureList === null && fetchTenureList()
			}
			if (index === 3) {
        currentProgress = incPercToken ? 100 : 89
			}
			if (index === 4) {
        currentProgress = incPercToken ? 100 : 100
			}
			setProgress(currentProgress);
  }
  
  const handleDatePickerChange = async (ranges, type) => {
    if(type === "start") {
      if(new Date(schemeEndDate).getTime() <= (new Date(ranges)).getTime()) {
        props.handleCreateSchemeDate([ranges, addDays(new Date(ranges), 1)])
      } else {
        props.handleCreateSchemeDate([ranges, schemeEndDate])
      }
    } else {
      props.handleCreateSchemeDate([schemeStartDate, ranges])
    }
  }
  const handleTnCChange = (type, value, index) => {
    if (type === "text") {
      setMultiLingualTermsAndConditions({
        ...multiLingualTermsAndConditions,
        data: multiLingualTermsAndConditions.data.map((obj, i) => {
          if (index === i) {
            return {
              ...obj,
              text: value
            }
          }
          return obj
        })
      })
    } else if (type === "url") {
      setMultiLingualTermsAndConditions({
        ...multiLingualTermsAndConditions,
        data: multiLingualTermsAndConditions.data.map((obj, i) => {
          if (index === i) {
            return {
              ...obj,
              url: value
            }
          }
          return obj
        })
      })
    } else if (type === "language") {
      setMultiLingualTermsAndConditions({
        ...multiLingualTermsAndConditions,
        data: multiLingualTermsAndConditions.data.map((obj, i) => {
          if (index === i) {
            return {
              ...obj,
              name: value.name,
              language: value.code,
            }
          }
          return obj
        })
      })
    } else if (type === "tncType") {
      if (value !== "NONE") {
        setMultiLingualTermsAndConditions(
          {
            ...multiLingualTermsAndConditionsObj,
            type: value,
            data: [{
              ...tncDataObj,
              language: "en",
              name: "English",
              preference: "PRIMARY",
            }]
          }
        )
      } else {
        setMultiLingualTermsAndConditions({
          type: value,
          data: null
        })
      }
    }
  }

  const handleInputChange = (type, e) => {
    if(type === "email") {
        props.handleEmailSelect(e.target.value)
    } else if(type === "intDesc") {
        props.handleInstallDescSelect(e.target.value)
    } else if (type === "programType") {
      props.handleProgTypeNSubType("program", e.target.value)

      if(e.target.value === "CASHBACK ONLY") {
        //  ----------------- >  Stepper 3  < ------------------------
        let tempAmtValCheck = {lowAmount: [], highAmount: []}
        let tempInstDuplicateCheck = []
        
        setAmountDetails(amountDetails !== null && amountDetails.map((obj, i) => {
          tempAmtValCheck["lowAmount"].push(obj.lowAmount)
          tempAmtValCheck["highAmount"].push(obj.highAmount)
          tempInstDuplicateCheck.push({installments: []})
          
          return {
            ...obj,
            id: i,
            flag: true,
            paymentFormatData: [{
              ...tenureDetailsObject, 
              numberOfInstallments: 0, 
              interestRate: 0,
              installmentFee: "None",
              installmentFeeValue: null,
            }]
          }
        }))
        setAmountValCheck(tempAmtValCheck)
        setInstDuplicateCheck(tempInstDuplicateCheck)
      }
    } else if (type === "subventionType") {
      props.handleProgTypeNSubType("subvention", e)
      if(e === "FULL") {
        setAmountDetails(amountDetails !== null && amountDetails.map((obj, i) => {
          
          return {
            ...obj,
            paymentFormatData: obj.paymentFormatData.map((tenure) => {
              return {
                ...tenure,
                interestRate: 0,
                cashbackDetails: {
                  ...tenure.cashbackDetails,
                  cashbackType: "00",
                  issuerCashbackType: "None",
                  issuerCashbackValue: null
                },
                subventionDetails: {
                  ...tenure.subventionDetails,
                  brandSubventionPercent: programType === "BRAND" ? tenure.interestRate : tenure.subventionDetails.brandSubventionPercent,
                  merchantSubventionPercent: programType === "MERCHANT" ? tenure.interestRate : tenure.subventionDetails.merchantSubventionPercent,
                }
              }
            })
          }
        }))
      }
    }
  }

  const handleSelectChange = (type, e) => {
    
    if(type === "productType") {
      props.handleProductTypeSelect(e.target.value)

    } else if(type === "paymentMode") { 
      props.handlePaymentModeSelect(e.name)
      setPayModelId(e.code)

    } else if (type === "bankSchemeTemplate") {
      // find bankScheme Object and populate all the field
      if (bankSchemeListObj && bankSchemeArr.indexOf(e.target.value) !== -1){
        const startD = bankSchemeListObj[bankSchemeArr.indexOf(e.target.value)]['installmentStartDate'];
        const endD = bankSchemeListObj[bankSchemeArr.indexOf(e.target.value)]['installmentEndDate'];
        setBankSelectedDate(endD);
        setBankSelectStartDate(startD)
        autoPopulateMultiLingualState(bankSchemeListObj[bankSchemeArr.indexOf(e.target.value)]);
        handleAutoPopulateFields(bankSchemeListObj[bankSchemeArr.indexOf(e.target.value)], false);
        new Date().getTime() < new Date((moment(startD)).format("DD-MMM-yyyy")).getTime() &&
          props.handleCreateSchemeDate([new Date((moment(startD)).format("DD-MMM-yyyy")), new Date((moment(endD)).format("DD-MMM-yyyy"))])
        new Date().getTime() >= new Date((moment(startD)).format("DD-MMM-yyyy")).getTime() &&
          props.handleCreateSchemeDate([addDays(new Date(), 1), new Date((moment(endD)).format("DD-MMM-yyyy"))])

        const tempBinList = bankSchemeListObj[bankSchemeArr.indexOf(e.target.value)] !== null && bankSchemeListObj[bankSchemeArr.indexOf(e.target.value)]["binDetails"] !== null &&
        bankSchemeListObj[bankSchemeArr.indexOf(e.target.value)]["binDetails"].map((obj) => {
          return obj
        })
        setBankTemplBinList(tempBinList)

        setBankTemplBlockedTccList(bankSchemeListObj[bankSchemeArr.indexOf(e.target.value)]["blackListedTCCs"])
        setBankTemplBlockedMccList(bankSchemeListObj[bankSchemeArr.indexOf(e.target.value)]["blackListedMCCs"])

        let tempBankAmtValCheck = {lowAmount: [], highAmount: []}
        let tempBankTenureList = [];
        bankSchemeListObj[bankSchemeArr.indexOf(e.target.value)]["amountRangeDetails"] !== null && 
        bankSchemeListObj[bankSchemeArr.indexOf(e.target.value)]["amountRangeDetails"].map((obj, i) => {
          tempBankAmtValCheck["lowAmount"].push(obj.lowAmount)
          tempBankAmtValCheck["highAmount"].push(obj.highAmount)
          obj.paymentFormatData && obj.paymentFormatData.map(tenure => {
            if(!tempBankTenureList.includes(tenure.numberOfInstallments)) {
              tempBankTenureList.push(tenure.numberOfInstallments);
            }
            return null;
          })
          setBankTemplTenureList(tempBankTenureList)
          return null;
        })
        setBankTemplAmountRangeCheck(tempBankAmtValCheck);
      }
      setBankSChemeSelected(e.target.value)

    } else {
      props.handleGeoScopeSelect(e.target.value)
    }
  }

  const handleCountryChange = (e) => {
    props.handleCountrySelect(e.name)
    props.handleIssuerSelect(null)
    setBankSChemeSelected(null)
    setMciId(null);
    setCountryCodeNum(e.isoCountryCodeNum)
    props.type !== "Bank" ?
      props.fetchIssuerDetails({
        status: "ACTIVE",
        isoCountryCodeNum: e.isoCountryCodeNum,
      })
    : props.fetchIssuerDetails({
      status: "ACTIVE",
      isoCountryCodeNum: e.isoCountryCodeNum,
      isPLManaged: true
    })
    setMultiLingualTermsAndConditions({
      ...multiLingualTermsAndConditionsObj,
      data: [{
        ...tncDataObj,
        language: "en",
        name: "English",
        preference: "PRIMARY"
      }]
    })
  }

  const handleIssuerChange = (e) => {
    props.handleIssuerSelect(e.name)
    props.fetchBankSchemesAsTemplate({"mciId": e.mciId})
    setMciId(e.mciId)
    setBankSChemeSelected(null)

    const tempbinRangeDetail = binRangeDetail.map(obj => {
      return {
          ...obj,
          icaID: "", fundingBin: null,
          fundingBinLength: null
      }
    })
    setBinRangeDetails(tempbinRangeDetail)
    setMultiLingualTermsAndConditions({
      ...multiLingualTermsAndConditionsObj,
      data: [{
        ...tncDataObj,
        language: "en",
        name: "English",
        preference: "PRIMARY"
      }]
    })
  }

  const handlestatUpdate = (e) => {
    props.handleStatusCodeSelect(e.target.value)
  }

  const handleCcChange = (type, event) => {
    if(type === "tcc") {
        const newTcc = TCC.map((obj) => {
            if(obj.tccCode === event.tccCode) {
                if(!obj.flag) {
                    setBlockedTcc([...blockedTcc ,obj.tccCode])
                    setBlockedMcc([...blockedMcc, ...(obj.mccList).map(val => val.mccCode)])
                } else if(blockedTcc.includes(obj.tccCode)) {
                    setBlockedTcc(blockedTcc.map(val => {
                        if(val !== obj.tccCode) {
                            return val
                        } else {
                            let tempblockedMcc = blockedMcc;
                            (obj.mccList).map(val => tempblockedMcc = tempblockedMcc.filter(mcc => mcc !== val.mccCode))
                            setBlockedMcc(tempblockedMcc)
                            return null
                        }
                    }).filter(tcc => tcc !== null))
                }
                return {...obj, flag: !obj.flag, mccList: 
                    (obj.mccList).map(val => ({ ...val, flag: !obj.flag}))
                }
            } else {
                return obj
            }
        })
        setTcc(newTcc)
    } else {
        const newTcc = TCC.map((obj) => {
            if(obj.tccCode === event[1].tccCode) {
                return {...obj, mccList: 
                    (obj.mccList).map(val => {
                        if(val.mccCode === event[2].mccCode) {
                            if(!val.flag) {
                                setBlockedMcc([...blockedMcc ,val.mccCode])
                            } else if(blockedMcc.includes(val.mccCode)) {
                                setBlockedMcc(blockedMcc.filter(mcc => mcc !== val.mccCode))
                            }
                            return { ...val, flag: !val.flag}
                        } else {
                            return val
                        }
                    })
                }
            } else {
                return obj
            }
        })
        setTcc(newTcc)
    }
  }

  const handleMCCfilter = (value) => {
    const Value = value === null ? "" : value
    const tempTCC = TccMccList.map((obj, index) => {
        return {
            ...obj,
            flag: TCC[index].flag,
            mccList: TccMccList[index].mccList.map((mccObj, mccInd) => {
                if(((mccObj.mccName).toUpperCase()).includes((Value).toUpperCase())) {
                    return {...mccObj, flag: TCC[index].mccList[mccInd] ? TCC[index].mccList[mccInd].flag : false}
                } else {
                    return null
                }
            })
            .filter(val => val !== null)
        }
    })
    setTcc(tempTCC)
  }

  const handleExpandView = (id) => {
    if(expansionId === id) {
        setExpansionId(null);
    } else {
        setExpansionId(id);
    }
  }

  const AmountCollapseExpand = (ID) => {
    let tempAmountDetails = amountDetails.map((obj) => {
        if(obj.id === ID) {
            return {
                ...obj,
                flag: !obj.flag
            }
        } else {
            return obj
        }
    })
    //find object of amount and add tenure object
    setAmountDetails(tempAmountDetails)
  }

  const handleDeleteAmount = async (amountId) => {
    let tempAmountDetails = await amountDetails.filter((obj, i) => {
      return i !== amountId
    }).map((amount, ind) => {
      return {
        ...amount,
        id: ind
      }
    })
    //find object of amount and add tenure object
    await setAmountDetails(tempAmountDetails)

    //delete invalid amount range record id here ---------------->>>>>>>>>
    await setAmountRangeRecord(amountRangeRecord.filter(val => val !== amountId))

    //delete amount range record
    let tempamountValCheck = {...amountValCheck}
    const tempLowAmount = tempamountValCheck.lowAmount.filter((val, i) => i !== amountId)
    const tempHighAmount = tempamountValCheck.highAmount.filter((val, i) => i !== amountId)
    tempamountValCheck = {
      lowAmount: tempLowAmount,
      highAmount: tempHighAmount
    }
    await setAmountValCheck(tempamountValCheck)

    let tempinstDuplicateCheck = instDuplicateCheck.filter((val, index) => index !== amountId)
    setInstDuplicateCheck(tempinstDuplicateCheck)
  }

  const handleStepper3InputChange = (type, amountId, key, value, tenureId, intNoId) => {

    if(type === "amount") {

      let tempAmtCheckArr = {...amountValCheck}
      tempAmtCheckArr[key][amountId] = value
      setAmountValCheck(tempAmtCheckArr)

      let tempAmountDetails = amountDetails.map((obj, amtIndex) => {
          if(amtIndex === amountId) {
              return {
                  ...obj,
                  [key]: value
              }
          } else {
              return obj
          }
      })
      //find object of amount and add tenure object
      setAmountDetails(tempAmountDetails)
    } else {
      if(key === "numberOfInstallments") {
        let tempArr = [...instDuplicateCheck];
        let instArr = tempArr[amountId]["installments"];
        instArr[parseInt(intNoId)] = value;
        tempArr[amountId] = {installments: [...instArr]}
        setInstDuplicateCheck(tempArr)
      }
      let tempAmountDetails = amountDetails.map((obj, amtIndex) => {
          if(amtIndex === amountId) {
              return {
                  ...obj,
                  paymentFormatData: obj.paymentFormatData.map((tenure) => {
                      if(tenure.id === tenureId) {
                          if(key === 'interestRate') {
                            return {
                              ...tenure,
                              interestRate: value,
                              subventionDetails: {
                                ...tenure.subventionDetails,
                                brandSubventionPercent: programType === "BRAND" ? value : tenure.subventionDetails.brandSubventionPercent,
                                merchantSubventionPercent: programType === "MERCHANT" ? value : tenure.subventionDetails.merchantSubventionPercent,
                              }
                            }
                          } else if(key === 'issuerCashbackType') {
                            return {
                              ...tenure,
                              cashbackDetails: {
                                  ...tenure.cashbackDetails,
                                  [key]: value,
                                  totalCashbackType: value,
                                  issuerCashbackValue: null,
                              }
                            }
                          } else if (key === "issuerCashbackPercent") {
                            return {
                              ...tenure,
                              cashbackDetails: {
                                ...tenure.cashbackDetails,
                                issuerCashbackValue: value,
                              }
                            }
                          } else if (key === "cashbackType") {
                            return {
                              ...tenure,
                              cashbackDetails: {
                                ...tenure.cashbackDetails,
                                [key]: value,
                                issuerCashbackType: value === "00" ? "None" : tenure.cashbackDetails.issuerCashbackType,
                                issuerCashbackValue: value === "00" ? null : tenure.cashbackDetails.issuerCashbackValue,
                              }
                            }
                          } else if([
                              "issuerCashbackValue", 'additionalChargeSlipText', 
                              ].includes(key)
                          ) {
                              return {
                                ...tenure,
                                cashbackDetails: {
                                  ...tenure.cashbackDetails,
                                  [key]: value,
                                }
                              }
                          } else if([
                              "brandSubventionPercent", "merchantSubventionPercent","brandCashbackPercent",
                              "merchantCashbackPercent", "brandCashbackAmount", "merchantCashbackAmount",
                              ].includes(key)
                          ) {
                              return {
                                ...tenure,
                                subventionDetails: {
                                  ...tenure.subventionDetails,
                                  brandCashbackPercent: key === "brandCashbackAmount" ? null : tenure.subventionDetails.brandCashbackPercent,
                                  brandCashbackAmount: key === "brandCashbackPercent" ? null : tenure.subventionDetails.brandCashbackAmount,
                                  merchantCashbackPercent: key === "merchantCashbackAmount" ? null : tenure.subventionDetails.merchantCashbackPercent,
                                  merchantCashbackAmount: key === "merchantCashbackPercent" ? null : tenure.subventionDetails.merchantCashbackAmount,
                                  [key]: value,
                                }
                              }
                          } else {
                            return {
                                ...tenure,
                                [key]: value
                            }
                          }
                      } else {
                          return tenure
                      }
                  })
              }
          } else {
              return obj
          }
        })
        //find object of amount and add tenure object
        setAmountDetails(tempAmountDetails)
    }
  }
  
  const handleTenureAdd = (amountId) => {
    let tempAmountDetails = amountDetails.map((obj) => {
        if(obj.id === amountId) {
            return {
                ...obj,
                paymentFormatData: [
                    ...obj.paymentFormatData,
                    {
                        ...tenureDetailsObject,
                        id: `${parseInt(obj.paymentFormatData[obj.paymentFormatData.length-1]["id"])+1}`
                    }
                ]
            }
        } else {
            return obj
        }
    })
    //find object of amount and add tenure object
    setAmountDetails(tempAmountDetails)
  }

  const handleAmountRangeAdd = () => {
    setAmountDetails([...amountDetails, {
        ...amounDetailsObject,
        id: parseInt(amountDetails[amountDetails.length-1]["id"])+1
    }])
    setInstDuplicateCheck([...instDuplicateCheck, {installments: []}])
  }

  const TenureCollapseExpand = (amountID, tenureID) => {
    let tempAmountDetails = amountDetails.map((obj) => {
        if(obj.id === amountID) {
            return {
                ...obj,
                paymentFormatData: obj.paymentFormatData.map((tenure) => {
                    if(tenure.id === tenureID) {
                        return  {
                            ...tenure,
                            flag: !tenure.flag
                        }
                    } else {
                        return tenure
                    }
                })
            }
        } else {
            return obj
        }
    })
    //find object of amount and add tenure object
    setAmountDetails(tempAmountDetails)
  }

  const handleDeleteTenure = async (amountID, tenureID, intNoId) => {
    let tempAmountDetails = await amountDetails.map((obj) => {
        if(obj.id === amountID) {
          return {
              ...obj,
              paymentFormatData: obj.paymentFormatData.filter((tenure, j) => {
                return tenure.id !== tenureID
              })
          }
        } else {
            return obj
        }
    })
    //find object of amount and add tenure object
    await setAmountDetails(tempAmountDetails)
    
    let tempInstalArr = instDuplicateCheck[amountID]['installments']
    tempInstalArr = await (tempInstalArr).filter((val, i) => i !== parseInt(intNoId))
    await setInstDuplicateCheck(instDuplicateCheck.map((val, index) => {
      if(index === amountID) {
        return {
          installments: tempInstalArr
        }
      } else {
        return val
      }
    }))
    await handleTenureCheckForAmt(`${amountID}${tenureID}`,'remove')
    await handlePartialCofundedValidCheck(`${amountID}${tenureID}`,'remove')

  }

  const handleBinRangeAdd = () => {
    setBinRangeDetails([...binRangeDetail, {
        ...binRangeObject,
        id: binRangeDetail[binRangeDetail.length-1]["id"]+1
    }])
  }

  const handleBinRangeDelete = async (binId) => {
    let rangeStr = null
    let tempBinDetails = await binRangeDetail.filter(obj => {
      if(obj.id === binId) {
        rangeStr = obj.fundingBin
      }
      return obj.id !== binId
    })
    //find object of amount and add tenure object
    await setBinRangeDetails(tempBinDetails)

    let tempselBinRange = [...selectedBinRange];
    tempselBinRange = await tempselBinRange.filter((val, i) => val !== rangeStr)
    await setSelBinRange([...tempselBinRange])
  }

  const handleStepper4InputChange = (id, key, valObject, type) => {
      if (type !== null && type === "dropdown") {
        const tempBinRangeDetails = binRangeDetail.map(obj => {
          if(obj.id === id) {
              return {
                  ...obj,
                  icaID: valObject["icaID"],
                  fundingBin: valObject["fundingBin"],
                  fundingBinLength: valObject['fundingBinLength']
              }
          } else {
              return obj
          }
        })
        setBinRangeDetails(tempBinRangeDetails);
        handleSelectedBinRange("add", valObject["fundingBin"], id)
      } else {
        const tempBinRangeDetails = binRangeDetail.map(obj => {
          if(obj.id === id) {
              return {
                  ...obj,
                  [key]: valObject,
              }
          } else {
              return obj
          }
        })
        setBinRangeDetails(tempBinRangeDetails);
        key === "fundingBin" && handleSelectedBinRange("add", valObject["fundingBin"], id)
      }
  }
  
  const handleStepper5InputChange = (key, value) => {
    
    if(key === "oemName") {
      setBrandSelected(value.id)

    } else if(key === "setGroupForViewEdit") {
      const tempGroups = value.filter(obj => selectedProdGroupIds.indexOf(obj.id) !== -1)
      setSelectedProdGroups([...tempGroups])
      setSelectedProdGroupIds([])
    } else if(key === "apply") {
      props.fetchProductGroups(value)

    } else if(key === "checkbox") {
      const found = selectedProdGroups.some(el => el.id === value.id);
      let tempGroups = [...selectedProdGroups]
      
      if(!found) {
        tempGroups = [...selectedProdGroups, value]
      } else {
        tempGroups = tempGroups.filter((obj, indx) => {
          return obj.id !== value.id
        })
      }
      setSelectedProdGroups(tempGroups)

    } else if(key === "selectAll") {
      if(selectedProdGroups.length !== value.length) {
        setSelectedProdGroups([...value])
      } else {
        setSelectedProdGroups([])
      }

    } else if(key === "remove") {
      const tempGroups = selectedProdGroups.filter((obj) => {
        return value !== obj.id
      })
      setSelectedProdGroups(tempGroups)

    } else if(key === "clearSelectedGroups") {
      setSelectedProdGroups(value)

    }
  }

  const handleStepper1Date = (dateType, date) => {

    const startDateKey = "installmentStartDate" 
    const endDateKey =  "installmentEndDate"
    
    if(dateType === "start" && ((currentForm === "edit" && !startDateNotSet) || currentForm === "view")) {
        let startDate = schemeDetail[startDateKey]
        startDate = `${startDate.slice(0,4)}-${startDate.slice(4,6)}-${startDate.slice(6)}`
        currentForm === "edit" && setStartDateNotSet(true);
        return new Date(startDate)
    } else if(dateType === "end" && ((currentForm === "edit" && !endDateNotSet) || currentForm === "view")) {
        let endDate = schemeDetail[endDateKey]
        endDate = `${endDate.slice(0,4)}-${endDate.slice(4,6)}-${endDate.slice(6)}`
        props.handleCreateSchemeDate([date, new Date(endDate)])
        currentForm === "edit" && setEndDateNotSet(true)
    }
  }

  const handleSelectedBinRange = (type, value, id) => {
    if(type === "add") {
      const tempselectedBinRange = [...selectedBinRange]
      if(!selectedBinRange.includes(value)) {
        tempselectedBinRange[id] = value
        setSelBinRange(tempselectedBinRange)
      }
    } else {
      let tempselBinRange = selectedBinRange;
      tempselBinRange = tempselBinRange.filter(val => val !== value)
      setSelBinRange([...tempselBinRange])
    }
  }

  const handleTncAdd = () => {
    const tncData = [...multiLingualTermsAndConditions.data, {
      ...tncDataObj,
      preference: "SECONDARY",
    }]

    setMultiLingualTermsAndConditions({
      ...multiLingualTermsAndConditions,
      data: tncData
    })
  }
  
  const handleTncDelete = (index) => {
    const tempTncData = multiLingualTermsAndConditions.data.filter((obj, i) => index !== i )
    setMultiLingualTermsAndConditions({
      ...multiLingualTermsAndConditions,
      data: [...tempTncData]
    })
  }

  return (
    <>

      <BasePageContainer>
        <div className={"steper_wraper"}>
          <Stepper className={classes.steper} activeStep={activeStep} variant="progress" connector={<QontoConnector />}>
            {steps.map((label, index) => (
              <Step key={index} onClick={() => currentForm === "view" ?  handleCurrentStep("steps", index) : ""} 
                className={currentForm !== 'create' ? "cursorActive" : "cursorDefault"}>
                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <LinearProgressWithLabel value={progress} />
          
          <React.Fragment>
            <form className={"action_form scheme_form"} noValidate autoComplete="off">
              
              <Paper className={classes.paper}>
                <div className="step_header">
                  {steps[activeStep]} 
                </div>

                <div className={"paper_scroll"}>
                  {/* stepper 1 */}
                  {activeStep === 0 ?
                    <SchemeTypeStepper 
                      {...props}
                      classes={classes}
                      statusCode={statusCode}
                      schemeType={props.type}
                      currentForm={currentForm}
                      schemeDetail={schemeDetail}
                      disableToken={disableToken}
                      issuerDetails={issuerDetails}
                      schemeEndDate={schemeEndDate}
                      bankSchemeArr={bankSchemeArr}
                      programTypeValue={programType}
                      countryDetails={countryDetails}
                      subventionType={subventionType}
                      stepper1Details={stepper1Details}
                      schemeStartDate={schemeStartDate}
                      handlestatUpdate={handlestatUpdate}
                      bankSelectedDate={bankSelectedDate}
                      handleInputChange={handleInputChange}
                      handleSchemeChange={handleSchemeChange}
                      bankSchemeSelected={bankSchemeSelected}
                      handleSelectChange={handleSelectChange}
                      handleIssuerChange={handleIssuerChange}
                      handleCountryChange={handleCountryChange}
                      bankSelectedStartDate={bankSelectedStartDate}
                      handleDatePickerChange={handleDatePickerChange}
                      setIsPLManaged={setIsPLManaged}
                      multiLingualTermsAndConditions={multiLingualTermsAndConditions}
                      handleTncAdd={handleTncAdd}
                      handleTncDelete={handleTncDelete}
                      countryCodeNum={countryCodeNum}
                      handleTnCChange={handleTnCChange}
                    />
                    : null
                  }

                  {/* stepper 2 */}
                  {activeStep === 1 ?
                    <MerchantStepper 
                      TCC={TCC}
                      classes={classes} 
                      TccLoader={TccLoader}
                      expansionId={expansionId}
                      schemeDetail={schemeDetail}
                      disableToken={disableToken}
                      handleCcChange={handleCcChange}
                      handleMCCfilter={handleMCCfilter}
                      handleExpandView={handleExpandView}
                      bankTemplBlockedTccList={bankTemplBlockedTccList}
                      bankTemplBlockedMccList={bankTemplBlockedMccList}
                    />
                    : null
                  }

                  {/* stepper 3 create and view */}
                  {(currentForm === "create" || currentForm === "edit") && activeStep === 2 ?
                    amountDetails.map((obj, index) => { 
                      return <AmountStepper
                        id={index}
                        classes={classes}
                        currentObject={obj}
                        handleNext={handleNext}
                        schemeType={props.type}
                        programType={programType}
                        currentForm={currentForm}
                        TenureRecord={TenureRecord}
                        amountValCheck={amountValCheck}
                        subventionType={subventionType}
                        handleStepback={handleStepback}
                        nextButtonFlag={nextButtonFlag}
                        handleTenureAdd={handleTenureAdd}
                        deleteToken={amountDetails.length}
                        amountRangeRecord={amountRangeRecord}
                        handleDeleteAmount={handleDeleteAmount}
                        handleDeleteTenure={handleDeleteTenure}
                        AmountCollapseExpand={AmountCollapseExpand}
                        handleAmountRangeAdd={handleAmountRangeAdd}
                        TenureCollapseExpand={TenureCollapseExpand}
                        showFooter={index === amountDetails.length-1}
                        handleInputChange={handleStepper3InputChange}
                        instDuplicateCheck={instDuplicateCheck[index]}
                        handleRangeCheckForAmt={handleRangeCheckForAmt}
                        handleTenureCheckForAmt={handleTenureCheckForAmt}
                        bankTemplTenureList={bankTemplTenureList}
                        bankTemplAmountRangeCheck={bankTemplAmountRangeCheck}
                        partialCofundedRecord={partialCofundedRecord}
                        handlePartialCofundedValidCheck={handlePartialCofundedValidCheck}
                        isPLManaged={isPLManaged}
                      />
                    })
                    : null
                  }
                  {currentForm === "view" && activeStep === 2 && schemeDetail &&
                    schemeDetail[amountRangeKey] && schemeDetail[amountRangeKey] !== null ? 
                      schemeDetail[amountRangeKey].map((obj, index) => { 
                        return <AmountStepper
                          id={index}
                          classes={classes}
                          currentObject={obj}
                          handleNext={handleNext}
                          schemeType={props.type}
                          programType={programType}
                          disableToken={disableToken}
                          handleStepback={handleStepback}
                          subventionType={subventionType}
                          nextButtonFlag={nextButtonFlag}
                          handleTenureAdd={handleTenureAdd}
                          handleDeleteAmount={handleDeleteAmount}
                          handleDeleteTenure={handleDeleteTenure}
                          AmountCollapseExpand={AmountCollapseExpand}
                          handleAmountRangeAdd={handleAmountRangeAdd}
                          TenureCollapseExpand={TenureCollapseExpand}
                          handleInputChange={handleStepper3InputChange}
                          showFooter={index === schemeDetail[amountRangeKey].length-1}
                          isPLManaged={isPLManaged}
                        />
                    })
                    : currentForm === "view" && activeStep === 2 && 
                      <AmountStepper
                        id={0}
                        classes={classes}
                        showFooter={true}
                        handleNext={handleNext}
                        schemeType={props.type}
                        programType={programType}
                        disableToken={disableToken}
                        subventionType={subventionType}
                        handleStepback={handleStepback}
                        nextButtonFlag={nextButtonFlag}
                        handleTenureAdd={handleTenureAdd}
                        currentObject={amounDetailsObject}
                        handleDeleteAmount={handleDeleteAmount}
                        handleDeleteTenure={handleDeleteTenure}
                        AmountCollapseExpand={AmountCollapseExpand}
                        handleAmountRangeAdd={handleAmountRangeAdd}
                        TenureCollapseExpand={TenureCollapseExpand}
                        handleInputChange={handleStepper3InputChange}
                        isPLManaged={isPLManaged}
                      />
                  }

                  {/* stepper 4 create and view  */}
                  {(currentForm === "create" || currentForm === "edit")  && activeStep === 3 ?
                    binRangeDetail.map((obj, index) => {
                        return <BinRangeStepper
                          obj={obj}
                          id={obj.id}
                          classes={classes}
                          createLoad={createLoad}
                          schemeType={props.type}
                          handleNext={handleNext}
                          dispatch={props.dispatch}
                          binRangeList={binRangeList}
                          nextButtonFlag={nextButtonFlag}
                          handleStepback={handleStepback}
                          deleteToken={binRangeDetail.length}
                          selectedBinRange={selectedBinRange}
                          handleBinRangeAdd={handleBinRangeAdd}
                          handleBinRangeDelete={handleBinRangeDelete}
                          showFooter={index === binRangeDetail.length-1}
                          handleStepper4InputChange={handleStepper4InputChange}
                          currentForm={statusCode === "MODIFY" ? "edit" : currentForm}
                          bankTemplBinList={bankTemplBinList}
                          isPLManaged={isPLManaged}
                        />
                      })
                    : null
                  }
                  {currentForm === "view" && activeStep === 3 && schemeDetail && 
                    schemeDetail[binRangeKey] && schemeDetail[binRangeKey] !== null ?
                      schemeDetail[binRangeKey].map((obj, index) => {
                        return <BinRangeStepper
                          obj={obj}
                          id={index}
                          classes={classes}
                          schemeType={props.type}
                          handleNext={handleNext}
                          dispatch={props.dispatch}
                          currentForm={currentForm}
                          binRangeList={binRangeList}
                          disableToken={disableToken}
                          onChangeView={onChangeView}
                          nextButtonFlag={nextButtonFlag}
                          handleStepback={handleStepback}
                          handleBinRangeAdd={handleBinRangeAdd}
                          handleBinRangeDelete={handleBinRangeDelete}
                          showFooter={index === schemeDetail[binRangeKey].length-1}
                          handleStepper4InputChange={handleStepper4InputChange}
                          isPLManaged={isPLManaged}
                        />
                      })
                    : currentForm === "view"  && activeStep === 3 && 
                      <BinRangeStepper
                        id={0}
                        classes={classes}
                        showFooter={true}
                        obj={binRangeObject}
                        schemeType={props.type}
                        handleNext={handleNext}
                        dispatch={props.dispatch}
                        binRangeList={binRangeList}
                        disableToken={disableToken}
                        onChangeView={onChangeView}
                        handleStepback={handleStepback}
                        nextButtonFlag={nextButtonFlag}
                        handleBinRangeAdd={handleBinRangeAdd}
                        handleBinRangeDelete={handleBinRangeDelete}
                        handleStepper4InputChange={handleStepper4InputChange}
                        currentForm={statusCode === "MODIFY" ? "edit" : currentForm}
                        isPLManaged={isPLManaged}
                      />
                  }
                  {activeStep === 4 ?
                    <ProductGroupStepper
                      classes={classes}
                      schemeType={props.type}
                      handleNext={handleNext}
                      createLoad={createLoad}
                      disableToken={disableToken}
                      onChangeView={onChangeView}
                      handleStepback={handleStepback}
                      currentForm={statusCode === "MODIFY" ? "edit" : currentForm}
                      nextButtonFlag={nextButtonFlag}
                      brandListForScheme={brandListForScheme}
                      handleStepper5InputChange={handleStepper5InputChange}
                      selectedBrand={selectedBrand}
                      productGroupList={productGroupList}
                      productGroupLoader={productGroupLoader}
                      countryDetails={countryDetails}
                      selectedProdGroups={selectedProdGroups}
                      selectedProdGroupIds={selectedProdGroupIds}
                    />
                    : null
                  }
                </div>
              </Paper>

              {activeStep < 2  ? 
                  <Grid container className="step_action">
                  <Grid item xs={8} justify="flex-start">
                  </Grid>
                  <Grid item xs={4} style={{ display: "flex", justifyContent: "space-evenly"  }} justify="flex-end">
                    <Button 
                      onClick={handleStepback} 
                      disabled={!(activeStep > 0)}
                      className={`action_login_button ${!(activeStep > 0) ? "disabled_login_button" : ""}`}
                    >
                        Back
                    </Button>
                    {activeStep === steps.length - 1 ?
                      <Button className={`action_login_button ${!nextButtonFlag ? "disabled_login_button" : ""}`} disabled={!nextButtonFlag} >
                        {"Create"}
                      </Button>
                      : <Button onClick={handleNext} className={`action_login_button ${!nextButtonFlag ? "disabled_login_button" : ""}`} disabled={!nextButtonFlag} >
                        {"Next"}
                      </Button>
                    }
                  </Grid>
                </Grid>
                : null
              }

            </form>
          </React.Fragment>

        </div>
      </BasePageContainer>
      <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => handleSnackBarAction()}
        action={
        <React.Fragment>
            <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
        }
      >
        <Alert onClose={() => handleSnackBarAction()} severity={
          createSchemeRes && createSchemeRes !== null && !createSchemeRes.st ? "info" : "success"
        }>
            {snackbarMsg}
        </Alert>
      </Snackbar>
      <Dialog
        open={openPopover}
        onClose={() => handleDialogAction(false, "exit")}
        className={"delete_dialog"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h2>{"Scheme Created Successfully!"}</h2>
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <h4>{`Installment Config Id: ${createSchemeRes && createSchemeRes.installmentConfigID ? createSchemeRes.installmentConfigID : " - "}`}</h4>
              {`Do you want to Create a new Scheme or view Scheme list.`}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button className={classes.color}
                onClick={() => handleDialogAction(false, "Create")} 
            >
                Create New
            </Button>
            <Button autoFocus className={classes.color}
                onClick={() => handleDialogAction(false, "exit")} 
            >
                Cancel
            </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

CreateEditViewScheme = reduxForm({
  form: 'CreateEditViewScheme',
  // validate: validate,
  enableReinitialize: true
})(CreateEditViewScheme);


function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
        handleCreateSchemeDate, handleEmailSelect, handleInstallDescSelect,
        handleProductTypeSelect, handlePaymentModeSelect,
        handleGeoScopeSelect, fetchStepper1DropDownDetails, handleStatusCodeSelect,
        fetchCountryDetails, handleCountrySelect, 
        fetchIssuerDetails, handleIssuerSelect, handleStepper2Details, clearTccMcc,
        handleStepper3Details, fetchBinRangeDetails, handleStepper4Details,
        fetchBrandListForScheme, submitCreateSchemeForm, clearschemeRes, 
        fetchTccMccList, fetchBankSchemesAsTemplate, resetIssuerDetails, 
        handleProgTypeNSubType, fetchProductGroups, resetProductGroupList, createBankScheme,
        resetCountryDetails, fetchTenureList
      }, dispatch)
  }
}

function mapStateToProps(state) {

  return {
      email: state.SchemeReducer.email,
      issuer: state.SchemeReducer.issuer,
      country: state.SchemeReducer.country,
      geoScope: state.SchemeReducer.geoScope,
      createLoad: state.SchemeReducer.createLoad,
      schemeEndDate: state.SchemeReducer.endDate,
      paymentMode: state.SchemeReducer.paymentMode,
      productType: state.SchemeReducer.productType,
      installDesc: state.SchemeReducer.installDesc,
      schemeStartDate: state.SchemeReducer.startDate,
      binRangeList: state.SchemeReducer.binRangeList,
      issuerDetails: state.SchemeReducer.issuerDetails,
      countryDetails: state.SchemeReducer.countryDetails,
      stepper1Details: state.SchemeReducer.stepper1Details,
      createSchemeRes: state.SchemeReducer.createSchemeRes,
      brandListForScheme: state.SchemeReducer.brandListForScheme,
      TccMccList: state.SchemeReducer.TccMccList !== null ? 
        state.SchemeReducer.TccMccList.tccList : null,
      TccLoader: state.SchemeReducer.TccLoader,
      bankSchemeList: state.SchemeReducer.bankSchemeList !== null ? 
        state.SchemeReducer.bankSchemeList.bankSchemeList : [],
      programType: state.SchemeReducer.programType,
      subventionType: state.SchemeReducer.subventionType,
      productGroupList: state.ProductGroupReducer.productGroupList !== null && state.ProductGroupReducer.productGroupList.productGroupList ? 
        state.ProductGroupReducer.productGroupList.productGroupList : [],
      productGroupLoader: state.ProductGroupReducer.productGroupLoader,
      tenureList: state.SchemeReducer.tenureList !== null && state.SchemeReducer.tenureList.tenure ? 
        state.SchemeReducer.tenureList.tenure : null,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditViewScheme);
