import React, {useState, useEffect } from 'react'
import {
  Grid,  TextField, MenuItem, Snackbar, IconButton
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../../../_theme'
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import '../../../datepiker.css'
import {floatRegex, tNcRegex} from "../../../shared/utility";

export default function SchemeType(props) {
  const {classes} = useStyles();
  const {
    obj, handleInputChange, id, DisableFlag, instDuplicateCheck, index,
    handleTenureCheckForAmt, TenureRecord, schemeType, programType, subventionType,
    bankTemplTenureList, lowRangeAmount, partialCofundedRecord, handlePartialCofundedValidCheck, isPLManaged
  } = props;
  
  const [opentenAlert, setopentenAlert] = React.useState(false)
  const [valObj, setValObj] = useState({
    instNoError: false, ROIError: false, InstFeeTypeError: false, instFeeValueError: false,
    CbPercError: false, issCBValueError: false, ttlCbPerError: false, ttlCbValueError: false,
    additionalTextErr: false, brandSubperError: false, merchSubperError: false, 
    brandCashbackPercErr: false, merchCashbackPercErr: false, brandCashbackAmountErr: false, 
    merchCashbackAmountErr: false
  })
  let cbForView = null;
  [{name: "None", id: "00"}, {name: "Pre", id: "01"}, {name: "Post", id: "02"}].map(cb => {
    if(parseInt(cb.id) === parseInt(obj['cashbackDetails']['cashbackType'])) {
      cbForView = cb.name
    }
  })
  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  });

  useEffect(() => {
    const noOfInst = document.querySelector(`#noInstallment${id}_${index}`);
    if (noOfInst) { noOfInst.value = null; }
    const intrate = document.querySelector(`#interestRate${id}_${index}`);
    if (intrate) { intrate.value = null; }
    const intfeeVal = document.querySelector(`#installmentFeeValue${id}_${index}`);
    if (intfeeVal) { intfeeVal.value = null; }
    const isscbval = document.querySelector(`#issuerCashbackValue${id}_${index}`);
    if (isscbval) { isscbval.value = null; }
    const isscbPerc = document.querySelector(`#issuerCashBackPercent${id}_${index}`);
    if (isscbPerc) { isscbPerc.value = null; }
    const addTextVal = document.querySelector(`#additionalChargeSlipText${id}_${index}`);
    if (addTextVal) { addTextVal.value = null; }

    const branSubVal = document.querySelector(`#brandSubventionPercent${id}_${index}`);
    if (branSubVal) { branSubVal.value = null; }
    const merSubVal = document.querySelector(`#merchantSubventionPercent${id}_${index}`);
    if (merSubVal) { merSubVal.value = null; }
    const branCBVal = document.querySelector(`#brandCashbackPercent${id}_${index}`);
    if (branCBVal) { branCBVal.value = null; }
    const merCBVal = document.querySelector(`#merchantCashbackPercent${id}_${index}`);
    if (merCBVal) { merCBVal.value = null; }

  }, [index, id])

  useEffect(() => {
    const elemNum0 = document.querySelector('.number_field2')
    const elemNum1 = document.querySelector('.number_field3')
    const elemNum2 = document.querySelector('.number_field4')
    const elemNum3 = document.querySelector('.number_field5')
    const elemNum4 = document.querySelector('.number_field6')
    const elemNum5 = document.querySelector('.number_field7')
    const elemNum6 = document.querySelector('.number_field8')
    const elemNum7 = document.querySelector('.number_field9')
    const elemNum8 = document.querySelector('.number_field10')
    const elemNum9 = document.querySelector('.number_field11')
    const elemNum10 = document.querySelector('.number_field12')

    handleAdEventListner(elemNum0)
    handleAdEventListner(elemNum1)
    handleAdEventListner(elemNum2)
    handleAdEventListner(elemNum3)
    handleAdEventListner(elemNum4)
    handleAdEventListner(elemNum5)
    handleAdEventListner(elemNum6)
    handleAdEventListner(elemNum7)
    handleAdEventListner(elemNum8)
    handleAdEventListner(elemNum9)
    handleAdEventListner(elemNum10)

    document.addEventListener("wheel", function(event){
      if(document.activeElement.type === "number"){
          document.activeElement.blur();
      }
    });
    return() => {
      handleRemoveListener(elemNum0)
      handleRemoveListener(elemNum1)
      handleRemoveListener(elemNum2)
      handleRemoveListener(elemNum3)
      handleRemoveListener(elemNum4)
      handleRemoveListener(elemNum5)
      handleRemoveListener(elemNum6)
      handleRemoveListener(elemNum7)
      handleRemoveListener(elemNum8)
      handleRemoveListener(elemNum9)
      handleRemoveListener(elemNum10)
      document.removeEventListener("wheel", function(event){
        if(document.activeElement.type === "number"){
            document.activeElement.blur();
        }
      });
    }
  })

  useEffect(() => {
    const elem1 = document.getElementById(`brandCashbackAmount${id}_${index}`);
    elem1 && (elem1.value = obj["subventionDetails"]['brandCashbackAmount']);

    const elem2 = document.getElementById(`brandCashbackPercent${id}_${index}`);
    elem2 && (elem2.value = obj["subventionDetails"]['brandCashbackPercent']);

    const elem3 = document.getElementById(`brandSubventionPercent${id}_${index}`);
    elem3 && (elem3.value = obj["subventionDetails"]['brandSubventionPercent']);

    const elem4 = document.getElementById(`merchantCashbackAmount${id}_${index}`);
    elem4 && (elem4.value = obj["subventionDetails"]['merchantCashbackAmount']);

    const elem5 = document.getElementById(`merchantCashbackPercent${id}_${index}`);
    elem5 && (elem5.value = obj["subventionDetails"]['merchantCashbackPercent']);

    const elem6 = document.getElementById(`merchantSubventionPercent${id}_${index}`);
    elem6 && (elem6.value = obj["subventionDetails"]['merchantSubventionPercent']);
  }, [obj, id, index])

  const handleAdEventListner = (element) => {
    element && element.addEventListener('keydown', function(e) {
      if (e.keyCode === 38 || e.keyCode === 40) {
          e.preventDefault();
      }
    });
  }

  const handleRemoveListener = (element) => {
    element && element.removeEventListener('keydown', function(e) {
      if (e.keyCode === 38 || e.keyCode === 40) {
          e.preventDefault();
      }
    })
  }

  const handlevalidationAlert = () => {
    setopentenAlert(false)
  }

  const handleValueChange = async (type, id, field, value, amountId) => {
    if(field === "numberOfInstallments") {
      const installNoArr = await instDuplicateCheck && instDuplicateCheck['installments'].filter((val, i) => {
        return i !== index
      })
      if(installNoArr && installNoArr.includes(parseInt(value))) {
        setValObj({...valObj, instNoError: "duplicate" })
        handleTenureCheckForAmt && handleTenureCheckForAmt(`${id}${index}`,'add')
        setopentenAlert(true)

      } else if (installNoArr && !installNoArr.includes(value)) {
        setValObj({...valObj, instNoError: "false" })
        handleTenureCheckForAmt && handleTenureCheckForAmt(`${id}${index}`,'remove')
        handleInputChange(type, id, field, parseInt(value), amountId, index)
        setopentenAlert(false)
      }

    } else if(field === "interestRate") {
      value && value !== 0 && (!floatRegex.test(value) || parseFloat(value) >= 100.00) ? 
        setValObj({...valObj, ROIError: true })
        : setValObj({...valObj, ROIError: false })

      handleInputChange(type, id, field,  value, amountId)

    } else if(field === "installmentFee") {
      handleInputChange(type, id, field, value, amountId)
      if(value === "Percentage" && obj["installmentFeeValue"] >= 100) {
        setValObj({...valObj, instFeeValueError: true })
      } else {
        setValObj({...valObj, instFeeValueError: false })
      }

    } else if(field === "issuerCashbackType") {
      handleInputChange(type, id, field, value, amountId)
      let issCbInvalid = false
      let ttlCbInvalid = false
      if(value === "Percentage"){
        if(obj['cashbackDetails']["issuerCashbackValue"] >= 100 && 
          obj['cashbackDetails']["issuerCashbackPercent"] === null
        ) {
          issCbInvalid= true
        }
      }
      setValObj({...valObj, CbPercError: issCbInvalid, ttlCbPerError: ttlCbInvalid})

      if(value !== "Percentage"){
        setValObj({...valObj, CbPercError: false, ttlCbPerError: false })
      }

    } else if (field === "installmentFeeValue") {
      
      value && value.length > 0 && !floatRegex.test(value) ? 
        setValObj({...valObj, instFeeValueError: true }) 
        : setValObj({...valObj, instFeeValueError: false })
      
      if(value && obj["installmentFee"] === "Percentage" && parseFloat(value) >= 100) {
        setValObj({...valObj, instFeeValueError: true })
      }
      handleInputChange(type, id, field, value, amountId)

    } else if(field === "cashbackType") {
      handleInputChange(type, id, field, value, amountId)

    } else if(field === "issuerCashbackPercent") {
      value && value !== 0 && (!floatRegex.test(value) || parseFloat(value) >= 100.00) ? 
        setValObj({...valObj, CbPercError: true })
        : setValObj({...valObj, CbPercError: false })

      handleInputChange(type, id, field, value, amountId)

    } else if(field === "issuerCashbackValue") {
      value && value.length > 0 && !floatRegex.test(value) ? 
        setValObj({...valObj, issCBValueError: true }) 
        : setValObj({...valObj, issCBValueError: false })

      handleInputChange(type, id, field, value, amountId)
    } else if(field === "additionalChargeSlipText") {
      handleInputChange(type, id, field, value, amountId)
      value && value !== null && value.length > 0 && tNcRegex.test(value) ? 
        setValObj({...valObj, additionalTextErr: true })
        : setValObj({...valObj, additionalTextErr: false })
    } else if(field === "brandSubventionPercent") {
      
      setValObj({...valObj, brandSubperError: 
        (value === null || value === "" ) ? true : 
        (programType === "BRAND" && (parseFloat(value) < 0 || parseFloat(value) > 100)) ? "true" :
        (programType === "BRAND MERCHANT COFUNDED" && (parseFloat(value) <= 0 || parseFloat(value) > 100)) ? "true" : false })

      handleInputChange(type, id, field, value, amountId)

    } else if(field === "merchantSubventionPercent") {

      setValObj({...valObj, merchSubperError: 
        (value === null || value === "" ) ? true : 
        (programType === "MERCHANT" && (parseFloat(value) < 0 || parseFloat(value) > 100)) ? "true" :
        (programType === "BRAND MERCHANT COFUNDED" && (parseFloat(value) <= 0 || parseFloat(value) > 100)) ? "true" : false })

      handleInputChange(type, id, field, value, amountId)

    } else if(field === "brandCashbackPercent"){

      if(programType === "BRAND MERCHANT COFUNDED") {
        let cofundedPartialValiCheck = value !== null && value !== ""  && ((parseFloat(value) < 0 || parseFloat(value) > 100) || 
          (obj['subventionDetails']['merchantCashbackPercent'] !== null && (parseFloat(obj['subventionDetails']['merchantCashbackPercent']) + parseFloat(value) > 100)) ||
          (obj['subventionDetails']['merchantCashbackAmount'] !== null && (parseFloat(obj['subventionDetails']['merchantCashbackAmount'] + (parseFloat(lowRangeAmount)*parseFloat(value))/100) > parseFloat(lowRangeAmount)))
        )

        setValObj({...valObj, brandCashbackPercErr: false })
        cofundedPartialValiCheck && handlePartialCofundedValidCheck && handlePartialCofundedValidCheck(`${id}${index}`,'add')
        !cofundedPartialValiCheck && handlePartialCofundedValidCheck && handlePartialCofundedValidCheck(`${id}${index}`,'remove')
      } else {
        setValObj({...valObj, brandCashbackPercErr: 
          (value === null || value === "" ) ? true : 
          (parseFloat(value) < 0 || parseFloat(value) > 100) ? "true" : false })
      }

      handleInputChange(type, id, field, value, amountId)

    } else if(field === "brandCashbackAmount"){
      const Value = value !== null && value !== "" && value > 0 ? parseFloat(value) : value;

      if(programType === "BRAND MERCHANT COFUNDED") {
        let cofundedPartialValiCheck = value !== null && value !== ""  && ((Value < 0 || Value > parseFloat(lowRangeAmount)) || 
          (obj['subventionDetails']['merchantCashbackAmount'] !== null && (parseFloat(obj['subventionDetails']['merchantCashbackAmount']) + Value > parseFloat(lowRangeAmount))) ||
          (obj['subventionDetails']['merchantCashbackPercent'] !== null && (parseFloat(Value + (parseFloat(lowRangeAmount)*parseFloat(obj['subventionDetails']['merchantCashbackPercent']))/100) > parseFloat(lowRangeAmount)))
        )

        setValObj({...valObj, brandCashbackAmountErr: false })
        cofundedPartialValiCheck && handlePartialCofundedValidCheck && handlePartialCofundedValidCheck(`${id}${index}`,'add')
        !cofundedPartialValiCheck && handlePartialCofundedValidCheck && handlePartialCofundedValidCheck(`${id}${index}`,'remove')
      } else {
        setValObj({...valObj, brandCashbackAmountErr: 
          (value === null || value === "" ) ? true : 
          (Value < 0 || Value > parseFloat(lowRangeAmount)) ? "true" : false })
      }

      handleInputChange(type, id, field, Value, amountId)

    } else if(field === "merchantCashbackPercent"){
      
      if(programType === "BRAND MERCHANT COFUNDED") {
        let cofundedPartialValiCheck = value !== null && value !== ""  && ((parseFloat(value) < 0 || parseFloat(value) > 100) || 
          (obj['subventionDetails']['brandCashbackPercent'] !== null && (parseFloat(obj['subventionDetails']['brandCashbackPercent']) + parseFloat(value) > 100)) ||
          (obj['subventionDetails']['brandCashbackAmount'] !== null && (parseFloat(obj['subventionDetails']['brandCashbackAmount'] + (parseFloat(lowRangeAmount)*parseFloat(value))/100) > parseFloat(lowRangeAmount)))
        )

        setValObj({...valObj, merchCashbackPercErr: false })
        cofundedPartialValiCheck && handlePartialCofundedValidCheck && handlePartialCofundedValidCheck(`${id}${index}`,'add')
        !cofundedPartialValiCheck && handlePartialCofundedValidCheck && handlePartialCofundedValidCheck(`${id}${index}`,'remove')
      } else {
        setValObj({...valObj, merchCashbackPercErr: 
          (value === null || value === "" ) ? true : 
          (parseFloat(value) < 0 || parseFloat(value) > 100) ? "true" : false })
      }

      handleInputChange(type, id, field, value, amountId)

    } else if(field === "merchantCashbackAmount"){
      const Value = value !== null && value !== "" && value > 0 ? parseFloat(value) : value;

      if(programType === "BRAND MERCHANT COFUNDED") {
        let cofundedPartialValiCheck = value !== null && value !== ""  && ((Value < 0 || Value > parseFloat(lowRangeAmount)) || 
          (obj['subventionDetails']['brandCashbackAmount'] !== null && (parseFloat(obj['subventionDetails']['brandCashbackAmount']) + Value > parseFloat(lowRangeAmount))) ||
          (obj['subventionDetails']['brandCashbackPercent'] !== null && (parseFloat(Value + (parseFloat(lowRangeAmount)*parseFloat(obj['subventionDetails']['brandCashbackPercent']))/100) > parseFloat(lowRangeAmount)))
        )

        setValObj({...valObj, merchCashbackAmountErr: false })
        cofundedPartialValiCheck && handlePartialCofundedValidCheck && handlePartialCofundedValidCheck(`${id}${index}`,'add')
        !cofundedPartialValiCheck && handlePartialCofundedValidCheck && handlePartialCofundedValidCheck(`${id}${index}`,'remove')
      } else {
        setValObj({...valObj, merchCashbackAmountErr: 
          (value === null || value === "" ) ? true : 
          (Value < 0  || Value > parseFloat(lowRangeAmount)) ? "true" : false })
      }

      handleInputChange(type, id, field, Value, amountId)

    }
  }

  const handleOnBlur = (field, value) => {
    const Value = value !== null && value !== "" && value > 0 ? parseFloat(value) : value;
    if(field === "installmentFee") {
      setValObj({...valObj, InstFeeTypeError: value === null })
    } else if (field === "brandSubventionPercent") {
      setValObj({...valObj, brandSubperError: 
        (value === null || value === "" ) ? true : 
        (programType === "BRAND" && (parseFloat(value) < 0 || parseFloat(value) > 100)) ? "true" :
        (programType === "BRAND MERCHANT COFUNDED" && (parseFloat(value) <= 0 || parseFloat(value) > 100)) ? "true" : false })
    } else if (field === "merchantSubventionPercent") {
      setValObj({...valObj, merchSubperError: 
        (value === null || value === "" ) ? true : 
        (programType === "MERCHANT" && (parseFloat(value) < 0 || parseFloat(value) > 100)) ? "true" :
        (programType === "BRAND MERCHANT COFUNDED" && (parseFloat(value) <= 0 || parseFloat(value) > 100)) ? "true" : false })
    } else if(field === "brandCashbackPercent") {
      setValObj({...valObj, brandCashbackPercErr: (value === null || value === "" ) ? true : (Value < 0 || Value > 100) ? "true" : false })
    } else if(field === "brandCashbackAmount") {
      setValObj({...valObj, brandCashbackAmountErr: (value === null || value === "" ) ? true : Value < 0 ? "true" : false })
    } else if(field === "merchantCashbackPercent") {
      setValObj({...valObj, merchCashbackPercErr: (value === null || value === "" ) ? true : (Value < 0 || Value > 100) ? "true" : false })
    } else if(field === "merchantCashbackAmount") {
      setValObj({...valObj, merchCashbackAmountErr: (value === null || value === "" ) ? true : Value < 0 ? "true" : false })
    } else if(field === "numberOfInstallments") {
      setValObj({...valObj, instNoError: (value === null || value === "" ) ? true : false })
    }
  }
  const cbIssuerType = obj['cashbackDetails']['issuerCashbackType'];
  const progTypeCheck = programType === "CASHBACK ONLY";

  return (<>
    <Grid container rowSpacing={2} columnSpacing={2}>
      <Grid item xs={12} sm={4}>
        <TextField 
          InputLabelProps={{ shrink: true }}
          value={progTypeCheck ? 0 : obj['numberOfInstallments']} 
          disabled={DisableFlag || programType === "CASHBACK ONLY"} select={!DisableFlag}
          name={`noInstallment${id}_${index}`} variant="outlined"
          label="Number of Installments" id={`noInstallment${id}_${index}`} fullWidth required
          onChange={e => handleValueChange('tenure', id, 'numberOfInstallments', e.target.value, obj.id)}
          onBlur={(e) => handleOnBlur("numberOfInstallments", e.target.value, 'tenure', id, obj.id)}
        >
        {(bankTemplTenureList && bankTemplTenureList.length !== 0 ? bankTemplTenureList : []).map((val, index) => {
            return <MenuItem key={index} value={val} className={classes.menu}>
              {val + " Months"}
            </MenuItem>
        })} 
        </TextField>
        {obj['numberOfInstallments'] && obj['numberOfInstallments'] !== "" && 
          obj['numberOfInstallments'] !== null && parseFloat(obj['numberOfInstallments']) <= 0 ?
          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
            <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! Installment No. Cannot be zero(0) or less."}</span>
          </p>
          : valObj.instNoError === "true" ? 
            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
              <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry!"}</span>
            </p>
            : valObj.instNoError === "duplicate" ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                <span className="error_message"><InfoOutlinedIcon /> {"Duplicate Entry for No. of Installments!"}</span>
              </p> 
              : valObj.instNoError === "templateTenureErr" ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Installment No. must be from Installment Numbers of Selected Bank Template"}</span>
                </p> : null
        }
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          InputLabelProps={{ shrink: true }} name={`interestRate${id}_${index}`}
          disabled={DisableFlag || programType === "CASHBACK ONLY" || (subventionType === "FULL")}
          className="number_field3"
          value={progTypeCheck ? 0 : obj['interestRate']} fullWidth required type="number"
          label="Interest Rate" id={`interestRate${id}_${index}`} variant="outlined"
          onChange={
            e => programType !== "CASHBACK ONLY" && e.target.value.length <= 7 && 
            (e.target.value.split(".").length < 2 || e.target.value.split(".")[1].length < 4) &&
            handleValueChange('tenure', id, 'interestRate', e.target.value, obj.id)
          } 
        />
        {obj['interestRate'] && obj['interestRate'] !== "" && 
          obj['interestRate'] !== null && parseFloat(obj['interestRate']) < 0 ?
          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
            <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! Interest Rate Cannot be less than zero(0)."}</span>
          </p>
          : valObj.ROIError ? 
            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
              <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry!"}</span>
            </p>
            :null
        }
      </Grid>
      <Grid item xs={12} sm={4}>
        {!isPLManaged &&
        <TextField
          InputLabelProps={{ shrink: true }}
          disabled fullWidth variant="outlined"
          id={`distributionType${id}_${index}`} name={`distributionType${id}_${index}`} label="Distribution Type"
          value={obj['distributionType'] && obj['distributionType']}
        />}
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          InputLabelProps={{ shrink: true }} 
          value={obj['installmentFee']} disabled={DisableFlag || programType === "CASHBACK ONLY"}
          name={`installmentFee${id}_${index}`} variant="outlined"
          id={`installmentFee${id}_${index}`} label="Installments Fee Type" fullWidth select
          onChange={e => handleValueChange('tenure', id, 'installmentFee', e.target.value, obj.id)}
          onBlur={(e) => handleOnBlur("installmentFee", e.target.value, 'tenure', id, obj.id)}
        >
          {["Fixed", "Percentage", "None"].map((val, index) => {
            return <MenuItem key={index} value={val} className={classes.menu}>
              {val}
            </MenuItem>
          })} 
        </TextField>
      </Grid>
      {obj['installmentFee'] !== "None" && <Grid item xs={12} sm={4}>
        <TextField 
          InputLabelProps={{ shrink: true }} name={`installmentFeeValue${id}_${index}`} variant="outlined" className="number_field4"
          value={obj['installmentFeeValue']} disabled={DisableFlag || programType === "CASHBACK ONLY"} required={obj['installmentFee'] === "Fixed" || obj['installmentFee'] === "Percentage"}
          id={`installmentFeeValue${id}_${index}`} label={"Fee of Installments Value"} fullWidth type="number"
          onChange={
            e => ((obj["installmentFee"] === "Fixed" && e.target.value.length <= 12) || 
            (obj["installmentFee"] === "Percentage" && e.target.value.length <= 7)) && 
            (e.target.value.split(".").length < 2 || e.target.value.split(".")[1].length < 4) &&
            handleValueChange('tenure', id, 'installmentFeeValue', e.target.value, obj.id)
          }
        />
        {obj['installmentFeeValue'] && obj['installmentFeeValue'] !== "" && 
          obj['installmentFeeValue'] !== null && parseFloat(obj['installmentFeeValue']) <= 0 ?
          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
            <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! Value Cannot be zero(0) or less."}</span>
          </p>
          : valObj.instFeeValueError ? 
            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
              <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry!"}</span>
            </p>
            :null
        }
      </Grid>}
      {obj['installmentFee'] !== "None" && <Grid item xs={12} sm={4}/>}
      
      <Grid item xs={12} sm={4}>
        <TextField 
          InputLabelProps={{ shrink: true }} name={`cashBackType${id}_${index}`} variant="outlined"
          value={!DisableFlag ? obj['cashbackDetails']['cashbackType'] : cbForView} disabled={DisableFlag || subventionType === "FULL"}
          id={`cashBackType${id}_${index}`} label="Cashback Type" fullWidth select={!DisableFlag}
          onChange={e => handleValueChange('tenure', id, 'cashbackType', e.target.value, obj.id)}
        >
          {[{name: "None", id: "00"}, {name: "Pre", id: "01"}, {name: "Post", id: "02"}].map((val, index) => {
            return <MenuItem key={index} value={val.id} className={classes.menu}>
              {val.name}
            </MenuItem>
          })}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField 
          InputLabelProps={{ shrink: true }}
          value={cbIssuerType} disabled={DisableFlag || subventionType === "FULL" || (obj['cashbackDetails']['cashbackType'] !== null && obj['cashbackDetails']['cashbackType'] === "00")}
          name={`issuerCashbackType${id}_${index}`} variant="outlined"
          id={`issuerCashbackType${id}_${index}`} label="Cashback Value Type" fullWidth select
          onChange={e => handleValueChange('tenure', id, 'issuerCashbackType', e.target.value, obj.id)}
          onBlur={(e) => handleOnBlur("issuerCashbackType", e.target.value, 'tenure', id, obj.id)}
        >
          {["Fixed", "Percentage", "None"].map((val, index) => {
            return <MenuItem key={index} value={val} className={classes.menu}>
              {val}
            </MenuItem>
          })} 
        </TextField>
      </Grid>
      {obj['installmentFee'] !== "None" && <Grid item xs={12} sm={4}/>}

      {cbIssuerType === "Fixed" && <Grid item xs={12} sm={4}>
        <TextField 
          InputLabelProps={{ shrink: true }}
          value={obj['cashbackDetails']['issuerCashbackValue']} required
          disabled={DisableFlag || (cbIssuerType === "None" || cbIssuerType === "")} 
          type="number" variant="outlined" fullWidth
          name={`issuerCashbackValue${id}_${index}`}
          className="number_field5" 
          id={`issuerCashbackValue${id}_${index}`}
          label={"Issuer Cashback Value"}
          onChange={
            e => e.target.value.length <= 15 && 
            (e.target.value.split(".").length < 2 || e.target.value.split(".")[1].length < 4) &&
            handleValueChange('tenure', id, 'issuerCashbackValue', e.target.value, obj.id) }
        />
        {cbIssuerType === "Fixed" && valObj.issCBValueError ? 
          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
            <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry!"}</span>
          </p> 
          : null
        }
      </Grid>}

      {cbIssuerType === "Percentage" && <Grid item xs={12} sm={4}>
        <TextField 
          InputLabelProps={{ shrink: true }} required
          id={`issuerCashBackPercent${id}_${index}`}
          name={`issuerCashBackPercent${id}_${index}`}
          value={obj['cashbackDetails']['issuerCashbackValue']} 
          className="number_field6" 
          disabled={DisableFlag || (cbIssuerType === "None" || cbIssuerType === "")} 
          label={"Issuer Cashback Perc (%)"} type="number" variant="outlined" fullWidth
          onChange={
            e => e.target.value.length <= 7 && 
            (e.target.value.split(".").length < 2 || e.target.value.split(".")[1].length < 4) &&
            handleValueChange('tenure', id, 'issuerCashbackPercent', e.target.value, obj.id) 
          }
        />
        {cbIssuerType === "Percentage" && valObj.CbPercError ? 
          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
            <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry!"}</span>
          </p> 
          : null
        }
      </Grid>}

      <Grid item xs={12}>
        <TextField 
          InputLabelProps={{ shrink: true }}
          value={obj['cashbackDetails']['additionalChargeSlipText']} disabled={DisableFlag} inputProps={{maxLength: 255}}
          name={`additionalChargeSlipText${id}_${index}`} variant="outlined"
          fullWidth id={`additionalChargeSlipText${id}_${index}`} label="Additional Text"
          onChange={e => handleValueChange('tenure', id, 'additionalChargeSlipText', e.target.value, obj.id)}
        />
        {valObj.additionalTextErr ? 
          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
            <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! Only Specific Special Characters Allowed."}</span>
          </p>
          :null
        }
      </Grid>

      {
        schemeType !== "Bank" && (programType === "BRAND" || programType === "BRAND MERCHANT COFUNDED") 
        && subventionType === "FULL" && 
        <Grid item xs={12} sm={4}>
          <TextField 
            InputLabelProps={{ shrink: true }}
            value={obj['subventionDetails']['brandSubventionPercent']} 
            disabled={DisableFlag} type="number" className="number_field9"
            name={`brandSubventionPercent${id}_${index}`} variant="outlined"
            id={`brandSubventionPercent${id}_${index}`} label="Brand Subvention Perc (%)" fullWidth
            onChange={
              e => e.target.value.length <= 6 && (e.target.value.split(".").length < 2 || e.target.value.split(".")[1].length < 4) &&
              handleValueChange('tenure', id, 'brandSubventionPercent', e.target.value, obj.id)
            }
            onBlur={(e) => handleOnBlur("brandSubventionPercent", e.target.value, 'tenure', id, obj.id)}
          />
          {valObj.brandSubperError === "true" ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                <span className="error_message"><InfoOutlinedIcon /> 
                {programType === "BRAND" ? "Invalid Entry! percentage should be between 0-100 only." : "Invalid Entry! percentage should be greater than 0 and less than eaqual to 100 only."}
                </span>
              </p>
            :valObj.brandSubperError? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                <span className="error_message"><InfoOutlinedIcon /> {"Required Field! "}</span>
              </p>
              :null
          }
      </Grid>}

      {
        schemeType !== "Bank" && (programType === "MERCHANT" || programType === "BRAND MERCHANT COFUNDED") 
        && subventionType === "FULL" &&
        <Grid item xs={12} sm={4}>
          <TextField 
            InputLabelProps={{ shrink: true }}
            value={obj['subventionDetails']['merchantSubventionPercent']} 
            disabled={DisableFlag} type="number" className="number_field10"
            name={`merchantSubventionPercent${id}_${index}`} variant="outlined"
            id={`merchantSubventionPercent${id}_${index}`} label="Merchant Subvention Perc (%)" fullWidth
            onChange={
              e => e.target.value.length <= 6 && (e.target.value.split(".").length < 2 || e.target.value.split(".")[1].length < 4) &&
              handleValueChange('tenure', id, 'merchantSubventionPercent', e.target.value, obj.id)
            }
            onBlur={(e) => handleOnBlur("merchantSubventionPercent", e.target.value, 'tenure', id, obj.id)}
          />
          {valObj.merchSubperError === "true" ? 
            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
              <span className="error_message"><InfoOutlinedIcon /> 
              {programType === "MERCHANT" ? "Invalid Entry! percentage should be between 0-100 only." : "Invalid Entry! percentage should be greater than 0 and less than eaqual to 100 only."}
              </span>
            </p>
            :valObj.merchSubperError? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                <span className="error_message"><InfoOutlinedIcon /> {"Required Field! "}</span>
              </p>
              :null
          }
      </Grid>}

      {/* Bank & Merchant Cashback details based on partial subvention type */}
      {
        schemeType !== "Bank" && (programType === "BRAND" || programType === "BRAND MERCHANT COFUNDED") 
        && subventionType === "PARTIAL" &&
        <Grid item xs={12} sm={4}>
          <TextField 
            InputLabelProps={{ shrink: true }}
            value={obj['subventionDetails']['brandCashbackPercent']} 
            disabled={DisableFlag} type="number" className="number_field11"
            name={`brandCashbackPercent${id}_${index}`} variant="outlined"
            id={`brandCashbackPercent${id}_${index}`} label="Brand Cashback Perc (%)" fullWidth
            onChange={
              e => e.target.value.length <= 6 && (e.target.value.split(".").length < 2 || e.target.value.split(".")[1].length < 4) &&
              handleValueChange('tenure', id, 'brandCashbackPercent', e.target.value, obj.id)
            }
            onBlur={(e) => handleOnBlur("brandCashbackPercent", e.target.value, 'tenure', id, obj.id)}
          />
          {valObj.brandCashbackPercErr === "true" ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! percentage should be between 0-100 only. "}</span>
              </p> : null
          }
      </Grid>}

      {
        schemeType !== "Bank" && (programType === "BRAND" || programType === "BRAND MERCHANT COFUNDED") 
        && subventionType === "PARTIAL" &&
        <Grid item xs={12} sm={4}>
          <TextField 
            InputLabelProps={{ shrink: true }}
            value={obj['subventionDetails']['brandCashbackAmount']} 
            disabled={DisableFlag}
            name={`brandCashbackAmount${id}_${index}`} variant="outlined"
            id={`brandCashbackAmount${id}_${index}`} label="Brand Cashback Amount" fullWidth
            onChange={
              e => (e.target.value.split(".").length < 2 || e.target.value.split(".")[1].length < 4) &&
              handleValueChange('tenure', id, 'brandCashbackAmount', e.target.value, obj.id)
            }
            onBlur={(e) => handleOnBlur("brandCashbackAmount", e.target.value, 'tenure', id, obj.id)}
          />
          {valObj.brandCashbackAmountErr === "true" ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! Amount should be between 0 to Low Amount Range only. "}</span>
              </p> : null
          }
      </Grid>}

      {schemeType !== "Bank" && programType === "BRAND MERCHANT COFUNDED" 
        && subventionType === "PARTIAL" && <Grid item xs={12} sm={4}> </Grid>}

      {
        schemeType !== "Bank" && (programType === "MERCHANT" || programType === "BRAND MERCHANT COFUNDED") 
        && subventionType === "PARTIAL" &&
        <Grid item xs={12} sm={4}>
          <TextField 
            InputLabelProps={{ shrink: true }}
            value={obj['subventionDetails']['merchantCashbackPercent']} 
            disabled={DisableFlag} type="number" className="number_field12"
            name={`merchantCashbackPercent${id}_${index}`} variant="outlined"
            id={`merchantCashbackPercent${id}_${index}`} label="Merchant Cashback Perc (%)" fullWidth
            onChange={
              e => e.target.value.length <= 6 && (e.target.value.split(".").length < 2 || e.target.value.split(".")[1].length < 4) &&
              handleValueChange('tenure', id, 'merchantCashbackPercent', e.target.value, obj.id)
            }
            onBlur={(e) => handleOnBlur("merchantCashbackPercent", e.target.value, 'tenure', id, obj.id)}
          />
          {valObj.merchCashbackPercErr === "true" ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! percentage should be between 0-100 only. "}</span>
              </p> : null
          }
      </Grid>}

      {
        schemeType !== "Bank" && (programType === "MERCHANT" || programType === "BRAND MERCHANT COFUNDED") 
        && subventionType === "PARTIAL" &&
        <Grid item xs={12} sm={4}>
          <TextField 
            InputLabelProps={{ shrink: true }}
            value={obj['subventionDetails']['merchantCashbackAmount']} 
            disabled={DisableFlag}
            name={`merchantCashbackAmount${id}_${index}`} variant="outlined"
            id={`merchantCashbackAmount${id}_${index}`} label="Merchant Cashback Amount" fullWidth
            onChange={
              e => (e.target.value.split(".").length < 2 || e.target.value.split(".")[1].length < 4) &&
              handleValueChange('tenure', id, 'merchantCashbackAmount', e.target.value, obj.id)
            }
            onBlur={(e) => handleOnBlur("merchantCashbackAmount", e.target.value, 'tenure', id, obj.id)}
          />
          {valObj.merchCashbackAmountErr === "true" ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! Amount should be between 0 to Low Amount Range only. "}</span>
              </p> : null
          }
      </Grid>}

      {schemeType !== "Bank" && programType === "BRAND MERCHANT COFUNDED" 
      && subventionType === "PARTIAL" && partialCofundedRecord && partialCofundedRecord.includes(`${id}${index}`) && <Grid item xs={12} sm={8}> 
        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
          <span className="error_message"><InfoOutlinedIcon /> {"Your input subvention details should satisfy one of the following validations  :- (1). BCP + MCP <= 100  , (2). BCA + MCA <= Low Range Amount  , (3). BCA + ((LRA*MCP)/100) <= LRA  , (4). ((LRA*BCP)/100) + MCA <= LRA  , Where, Brand Cashback Perc=BCP  , Brand Cashback Amount=BCA  , Merchant Cashback Perc=MCP  , Merchant Cashback Amount=MCA  , Low Range Amount=LRA  ,"}</span>
        </p>
      </Grid>}

    </Grid>

    <Snackbar
      anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
      }}
      open={opentenAlert}
      autoHideDuration={10000}
      onClose={() => handlevalidationAlert()}
      action={
      <React.Fragment>
        <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handlevalidationAlert()}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
      }
    >
      <Alert onClose={() => handlevalidationAlert()} severity={"info"}>
        {"Correct Duplicate No. Of Installments Entry!"}
      </Alert>
    </Snackbar>
  </>)
}

const useStyles = tss.create({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    border: "solid 1px #EEEEEE", backgroundColor: "#FAFAFA",
    marginBottom: theme.spacing(2),
    padding: "20px 30px",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  gridRadio: {
    padding: "20px!important"
  },
  formRedio: {
    color: "#424242",
    fontFamily: "Quicksand-Medium",
    fontSize: "18px",
    letterSpacing: 0,
    lineHeight: "20px"
  },
  profileMenu: {
    marginLeft: '0px',
    width: "340px", border: "1px solid #EEEEEE",
    borderRadius: "3px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 6px 12px 0 rgba(0,0,0,0.08)"
  },
});
