import { PSPAcquirerActions } from '../consts/index';

const initialState = {
    pspAcquirerList: null,
    pspAcquirerLoader: false,
    createPSPAcquirerRes: null,
    pspMasterData: null,
    acqMasterData: null,
    pspIntegrationModeList: null,
}

export default ( state = initialState, {type, payload}) => {
    switch(type) {
        case PSPAcquirerActions.fetchPSPAcquirerDetails.REQUEST:
            return {
                ...state,
                pspAcquirerLoader: true
            }
        case PSPAcquirerActions.fetchPSPAcquirerDetails.SUCCESS:
            return {
                ...state,
                pspAcquirerLoader: false,
                pspAcquirerList: payload
            }
        case PSPAcquirerActions.fetchPSPAcquirerDetails.FAILURE:
            return {
                ...state,
                pspAcquirerLoader: false,
                pspAcquirerList: payload
            }
        case PSPAcquirerActions.createAndUpdatePSPAcquirerMapping.REQUEST:
            return {
                ...state
            }
        case PSPAcquirerActions.createAndUpdatePSPAcquirerMapping.SUCCESS:
            return {
                ...state,
                createPSPAcquirerRes: payload
            }
        case PSPAcquirerActions.createAndUpdatePSPAcquirerMapping.FAILURE:
            return {
                ...state,
                createPSPAcquirerRes: payload
            }
        case PSPAcquirerActions.fetchPSPMasterData.REQUEST:
            return {
                ...state,
            }
        case PSPAcquirerActions.fetchPSPMasterData.SUCCESS:
            return {
                ...state,
                pspMasterData: payload
            }
        case PSPAcquirerActions.fetchPSPMasterData.FAILURE:
            return {
                ...state,
                pspMasterData: payload
            }
        case PSPAcquirerActions.fetchAcqMasterData.REQUEST:
            return {
                ...state,
            }
        case PSPAcquirerActions.fetchAcqMasterData.SUCCESS:
            return {
                ...state,
                acqMasterData: payload
            }
        case PSPAcquirerActions.fetchAcqMasterData.FAILURE:
            return {
                ...state,
                acqMasterData: payload
            }
            case PSPAcquirerActions.fetchPspIntegrationModeMasterData.REQUEST:
            return {
                ...state,
            }
        case PSPAcquirerActions.fetchPspIntegrationModeMasterData.SUCCESS:
            return {
                ...state,
                pspIntegrationModeList: payload
            }
        case PSPAcquirerActions.fetchPspIntegrationModeMasterData.FAILURE:
            return {
                ...state,
                pspIntegrationModeList: payload
            }

        default:
            return state;
    }
}