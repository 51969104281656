import { actionCreator, BASE_URL } from "../../shared/utility";
import { IssuerActions } from "../consts/index";

export function fetchIssuerDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(IssuerActions.fetchIssuerDetailsForIssuer.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/getIssuer`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(IssuerActions.fetchIssuerDetailsForIssuer.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(IssuerActions.fetchIssuerDetailsForIssuer.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchCountryDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(IssuerActions.fetchCountryDetailsForIssuer.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/getIssuerCountrySummaryList`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(IssuerActions.fetchCountryDetailsForIssuer.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(IssuerActions.fetchCountryDetailsForIssuer.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function createOrEditIssuer(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(IssuerActions.createAndEditIssuer.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/addOrEditIssuer`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(IssuerActions.createAndEditIssuer.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(IssuerActions.createAndEditIssuer.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetIssuerCreateResp() {
    return(dispatch) => {
        dispatch(actionCreator(IssuerActions.createAndEditIssuer.FAILURE, null))
    }
}

export function uploadIssuerDocument(payload, type) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(IssuerActions.uploadDocumentForIssuer.REQUEST, {key: type}))
        fetch(`${BASE_URL}/sch/api/data/web/editIssuerImage`, {
            method: 'POST',
            body: payload, //JSON.stringify(payload),
            headers: {
                // 'Content-Type':'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(IssuerActions.uploadDocumentForIssuer.FAILURE, {...response, key: type}))
                }
            } else {
                dispatch(actionCreator(IssuerActions.uploadDocumentForIssuer.SUCCESS, {...response, key: type}))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchInterestTypes() {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(IssuerActions.fetchInterestTypes.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/interestTypes`, {
            method: 'GET',
            //body: payload, //JSON.stringify(payload),
            headers: {
                // 'Content-Type':'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(IssuerActions.fetchInterestTypes.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(IssuerActions.fetchInterestTypes.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetUploadDocumentResp() {
    return(dispatch) => {
        dispatch(actionCreator(IssuerActions.uploadDocumentForIssuer.FAILURE, null))
    }
}