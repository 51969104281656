import React from 'react'
import { 
    Dialog, DialogActions, DialogTitle, DialogContent, Button,
    Radio, RadioGroup, FormControlLabel  
} from '@mui/material'

export default  function FileTemplateDialog(props) {
    const { onClose, value: valueProp, open, fileTemplateArr, currentForm } = props;
    const [value, setValue] = React.useState(valueProp);
    const radioGroupRef = React.useRef(null);
    
    React.useEffect(() => {
        if (!open) {
        setValue(valueProp);
        }
    }, [valueProp, open]);
    
    const handleEntering = () => {
        if (radioGroupRef.current != null) {
        radioGroupRef.current.focus();
        }
    };
    
    const handleCancel = () => {
        onClose();
    };
    
    const handleOk = () => {
        onClose(value);
    };
    
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    
    return (
        <Dialog
            id="ringtone-menu"
            keepMounted
            sx={{ '& .MuiDialog-paper': { width: '100%', height: '100%' } }}
            maxWidth="lg"
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
        >
        <DialogTitle id="alert-dialog-title" align='center'>
            <h3>{"Select Excel File Template!"}</h3>
        </DialogTitle>
        <DialogContent style={{fontSize: '0.8rem'}} dividers>
            <RadioGroup
            ref={radioGroupRef}
            aria-label="ringtone"
            name="ringtone"
            value={value}
            onChange={handleChange}
            disabled={currentForm === 'view'}
            >
            {fileTemplateArr.map((option) => (
                <FormControlLabel
                value={option}
                key={option}
                control={<Radio />}
                label={<span style={{ fontSize: '0.8rem' }}>{option}</span>}
                disabled={currentForm === 'view'}
                style={{
                    backgroundColor: '#e6fcf5', 
                    border: "1px solid",
                    marginLeft: '16px',
                    marginBottom: '16px', 
                }}
                />
            ))}
            </RadioGroup>
        </DialogContent>
        <DialogActions>
            <Button color="primary" className="no"
                onClick={handleCancel}
            >
                Close
            </Button>
            {currentForm !== 'view' &&
            <Button color="primary" className="yes" autoFocus
                onClick={handleOk}
            >
                Submit
            </Button>}
        </DialogActions>
        </Dialog>
    );
}