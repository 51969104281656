import { actionCreator, BASE_URL } from "../../../shared/utility";
import { AcquirerMngActions } from "../consts/index";

export function fetchAcquirerDetails(payload) {
    const id = payload.id && payload.id !== "" ? `&id=${payload.id}` : ""
    const name = payload.name && payload.name !== "" ? `&name=${payload.name}` : ""
    const countryId = payload.countryId && payload.countryId !== "" ? `&countryId=${payload.countryId}` : ""
    const status = payload.status && payload.status !== "" ? `&status=${payload.status}` : ""
    const isoCountryCodeNum = payload.isoCountryCodeNum && payload.isoCountryCodeNum !== "" ? `&isoCountryCodeNum=${payload.isoCountryCodeNum}` : ""
    const endURL = `?pageNo=${payload.pageNo}&pageSize=${payload.pageSize}&sortDirection=${payload.sortDirection}&sortBy=${payload.sortBy}` + status + name + id + countryId + isoCountryCodeNum;
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(AcquirerMngActions.fetchAcquirerDetails.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/acquirers${endURL}`, {
            method: 'GET',
            // body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(AcquirerMngActions.fetchAcquirerDetails.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(AcquirerMngActions.fetchAcquirerDetails.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function createAcquirer(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(AcquirerMngActions.createAcquirer.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/acquirers`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(AcquirerMngActions.createAcquirer.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(AcquirerMngActions.createAcquirer.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function updateAcquirer(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(AcquirerMngActions.updateAcquirer.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/acquirers`, {
            method: 'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(AcquirerMngActions.updateAcquirer.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(AcquirerMngActions.updateAcquirer.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetCreateAcqResp() {
    return(dispatch) => {
        dispatch(actionCreator(AcquirerMngActions.createAcquirer.FAILURE), null)
    }
}