import React, { useState, useEffect } from 'react'
import {Grid, Dialog, DialogActions, DialogTitle, DialogContent,
  DialogContentText, IconButton, Snackbar, Button } from '@mui/material';
import { tss } from "tss-react";
import theme from '../_theme'
import {connect} from "react-redux";
import { bindActionCreators } from "redux";
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';

import BasePageContainer from '../_common/basePageContainer'
import BasePageToolbar from '../_common/basePageToolbar'
import TableComponent from "./table/index"
import CreateEditViewProgram from "./createEditViewProgram/index";
import EditProgram from "./editProgram/index";
import { fetchProgramDetails, fetchIndividualProgramDetails, resetIndividualProgram, 
  handleCountrySelect, handleProgramTypeSelect, handleStartDateSelect, handleEndDateSelect,
  handleDescriptionSelect, handleIsDefaultSelect, handleProgramNameSelect,
  handleBrandSelect, clearProgramRes, handleShowCrossBorderSelect
} from "./actions";

const headCells = [
  { id: 'name', type: 'text', numeric: false, disablePadding: false, label: 'Program Name' },
  { id: 'description', type: 'description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'type', type: 'text', numeric: false, disablePadding: false, label: 'Program Type' },
  { id: 'countryName', type: 'text', numeric: false, disablePadding: false, label: 'Country' },
  { id: 'status', type: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'startDate', type: 'text', numeric: false, disablePadding: false, label: 'Start Date' },
  { id: 'enddate', type: 'text', numeric: false, disablePadding: false, label: 'End Date' },
  { id: 'createdOn', type: 'createdOn', numeric: false, disablePadding: false, label: 'Created On' },
  { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];

let programSchemeObj = {
  issuerIsoCountryCodeNum: "",
  issuerCountryName: "",
  issuerName: "",
  issuerId: "",
  programSchemes: []
}

const filterCells = [
  { id: 'isoCountryCodeNum', type: 'select', label: 'Country Name' },
  { id: 'type', type: 'select', label: 'Program Type' },
  { id: 'name', type: 'text', label: 'Program Name' },
];

const filterobj = {
  type: "",
  name: "",
  pageNo: 1,
  status: "",
  pageSize: 10,
  countryName: "",
  sortBy:"createdOn",
  sortDirection:"DESC",
  isoCountryCodeNum: "",
}

const sortArr = [
  {key:"createdOn", label: "Create Date"},
  {key:"countryName", label: "Country Name"},
  {key:"name", label: "Program Name"},
  {key:"type", label: "Program Type"},
  {key:"status", label: "Status"},
];

const Program = (props) => {
  const { programList, programDetails, programLoader, createProgramRes, totalRecords} = props;
  
  const {classes} = useStyles();
  const [pageNo, setPage] = useState(1);
  const [sortDir, setsortDir] = useState('DESC');
  const [programId, setProgramId] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [issuerList, setIssuerList] = useState([]);
  const [schemeList, setSchemeList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [detailsSet, setDetailsSet] = useState(false)
  const [sortName, setsortName] = useState('createdOn');
  const [openPopover, setOpenPopOver] = useState(false);
  const [currentForm, setCurrentForm] = useState("list");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [filterState, setFilterState] = useState(filterobj);
  const [compInitaited, setCompInitiated] = useState(false);
  const [programSchemesDetails, setProgramSchemesDetails] = useState([programSchemeObj]);
  
  const currentPage = localStorage.getItem('currentPage')
  let permissions = localStorage.getItem("permissions")
    permissions = permissions && permissions !== null ? JSON.parse(permissions) : []

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  });
  
  useEffect(() => {
    const handleInitialCall = async () => {
      await setCompInitiated(true);
      await props.fetchProgramDetails({
        ...filterState,
        pageNo: pageNo, sortBy: sortName, sortDirection: sortDir
      });
      await localStorage.removeItem('currentPage');
    }
    handleInitialCall();
  }, []);

  useEffect(() => {
    if(currentPage && currentPage !== null && currentPage !== undefined && currentPage === "/program" && compInitaited){
        setCurrentForm('list')
        setFilterState({...filterobj});
        setPage(1);
        setsortDir("DESC");
        setsortName("createdOn")
        props.fetchProgramDetails({...filterState,
          pageNo: 1, sortBy: sortName, sortDirection: sortDir});
        localStorage.removeItem('currentPage') 
    }
  }, [currentPage])

  useEffect(() => {
    if(createProgramRes && createProgramRes !== null && !createProgramRes.st) {
        setOpenSnackbar(true)
        if(createProgramRes.msg) {
          setSnackbarMsg(createProgramRes.msg)
        } else if(createProgramRes.errors && createProgramRes.errors.length > 0) {
          const msgStr = createProgramRes.errors.map(val => val.errorDesc).join(", ")
          setSnackbarMsg(msgStr)
        }
    } else if(createProgramRes && createProgramRes !== null && createProgramRes.st) {
      if(currentForm === "create") {
        setOpenPopOver(true)
      } else {
        setOpenSnackbar(true)
        setSnackbarMsg(`Program Modified Successfully!`)
        onChangeView("list");
      }
    }
  }, [createProgramRes]);

  
  const handleSnackBarAction = (value) => {
    setOpenSnackbar(false)
    props.clearProgramRes(null)
    const token = createProgramRes && createProgramRes !== null && createProgramRes.st
    token && onChangeView("list")
    props.clearProgramRes(null)
  }

  const handleDialogAction = async (value, type) => {
    props.clearProgramRes(null)
    setOpenPopOver(value)
    if (type === "Create") {
        resetOnCreateSelect()
        await setProgramSchemesDetails([programSchemeObj])
        // await setProgress(23)
        //manually clear all fields
    } else {
        onChangeView("list")
    }
  }

  const resetOnCreateSelect = async () => {
    if(currentForm === "create") {
      await props.handleCountrySelect(null);
      await props.handleProgramTypeSelect(null);
      await props.handleStartDateSelect("");
      await props.handleEndDateSelect("");
      await props.handleProgramNameSelect("");
      await props.handleDescriptionSelect("");
      await props.handleBrandSelect("");
      await props.handleIsDefaultSelect(false);
      await props.handleShowCrossBorderSelect(false);
      await setProgramSchemesDetails([programSchemeObj]);
      await setIssuerList([{issuerArr: [], issuerObjArr: []}]);
      await setSchemeList([{schemeArr: [], schemeObjArr: []}]);
    }
  }

  const programArray = programList && programList !== null ? 
    programList.map(obj => {

      let programObj = {
        id: obj.id && obj.id,
        programId: obj.programId && obj.programId,
        name: obj.name && obj.name,
        description: obj.description && obj.description,
        status: obj.status && obj.status,
        type: obj.type && obj.type === 'CIB' ? 'BANK'
          : obj.type && obj.type === 'MIB' ? 'MERCHANT' 
            : 'BRAND',   
        isoCountryCodeNum: obj.isoCountryCodeNum && obj.isoCountryCodeNum, 
        countryName: obj.countryName && obj.countryName, 
        isDefault: obj.isDefault && obj.isDefault, 
        startDate: obj.startDate && `${obj.startDate.slice(0,10)}`,
        enddate: obj.enddate && `${obj.enddate.slice(0,10)}`,
        createdOn: obj.createdOn && obj.createdOn,
        brnadId: obj.brnadId && obj.brnadId,
        brandName : obj.brandName && obj.brandName,
        showCrossBorder: obj.showCrossBorder && obj.showCrossBorder,
        programSchemes: obj.programSchemes && obj.programSchemes,
        searchColumn: headCells.map((cell, index) => 
            cell.id === "createdOn" ? obj[cell.id] && `${obj[cell.id].slice(0,10)} ${obj[cell.id].slice(11,19)}` 
            : cell.id === "startDate" || cell.id === "enddate" ? obj[cell.id] && `${obj[cell.id].slice(0,10)}` 
              : cell.id ==="action" ? "" : obj[cell.id] && obj[cell.id]
            ).join(" ")
      }
      return programObj
  }) : []
  
  const programDetailsObj = programDetails && programDetails !== null ? 
    {
        id: programDetails.id && programDetails.id,
        programId: programDetails.programId && programDetails.programId,
        name: programDetails.name && programDetails.name,
        description: programDetails.description && programDetails.description,
        status: programDetails.status && programDetails.status,
        type: programDetails.type && programDetails.type,  
        isoCountryCodeNum: programDetails.isoCountryCodeNum && programDetails.isoCountryCodeNum, 
        countryName: programDetails.countryName && programDetails.countryName, 
        isDefault: programDetails.isDefault && programDetails.isDefault, 
        startDate: programDetails.startDate && `${programDetails.startDate.slice(0,10)}`,
        endDate: programDetails.enddate && `${programDetails.enddate.slice(0,10)}`,
        createdOn: programDetails.createdOn && `${programDetails.createdOn.slice(0,10)} ${programDetails.createdOn.slice(11,19)}`,
        brandId: programDetails.brandId && programDetails.brandId,
        brandName : programDetails.brandName && programDetails.brandName,
        showCrossBorder: programDetails.showCrossBorder && programDetails.showCrossBorder,
        programSchemes: programDetails.programSchemes && programDetails.programSchemes,
    }
    : undefined

  const onChangeView = async (inpval, row) => {
    inpval === "edit" && setProgramId(row.id);
    if(inpval === "view") {
      setIssuerList([]);
      props.fetchIndividualProgramDetails({ 
        id: row.id, ...filterState, pageNo: 1, sortBy: sortName, sortDirection: sortDir 
      });

    } else if(inpval === "edit") {
      setDetailsSet(false);
      setIssuerList([]);
      props.fetchIndividualProgramDetails({ 
        id: row.id, ...filterState, pageNo: 1, sortBy: sortName, sortDirection: sortDir 
      });

    } else if (inpval === "list") {
      await props.resetIndividualProgram()
      props.fetchProgramDetails({...filterState, 
        pageNo: 1, sortBy: sortName, sortDirection: sortDir
      })

    }
    setCurrentForm(inpval);
  }

  const handleDetailsSet = (value) => {
    setDetailsSet(value);
  }

  const handleSetFilterData = (filterArray, searchValue) => {
    setFilterData(filterArray)
    setSearchValue(searchValue)
  }

  const handleFilterStateChange = (key, value) => {
    const tempFilter = {
      ...filterState,
      [key]: value
    }
    setFilterState(tempFilter);
  }

  const resetFilter = () => {
    setFilterState(filterobj);
    setPage(1);
    setsortDir("DESC");
    setsortName("createdOn")
    props.fetchProgramDetails(
      { pageNo: 1, pageSize: 10, sortBy:"createdOn", sortDirection:"DESC" }
    );
  }

  const FilterApply = () => {
    setPage(1)
    props.fetchProgramDetails(
      {...filterState,  pageNo: 1, sortBy: sortName, sortDirection: sortDir }
    );
    setFilterState({...filterState});
  }

  const handlePageNo = (val) => {
      setPage(val)
      props.fetchProgramDetails(
        { ...filterState, pageNo: val, sortBy: sortName, sortDirection: sortDir }
      );
  }

  const handleSortDir = (value) => {
      setsortDir(value)
      setPage(1)
  }

  const handleSortName = (value) => {
      setsortName(value)
      setPage(1)
  }

  return (
    <>
      <BasePageToolbar currentForm={currentForm}  create={permissions.includes("25")} onChangeView={onChangeView} resetFilter={resetFilter}
        title={currentForm === 'list' ? "Program Details" :  currentForm === 'view' ? "View Program" : currentForm === "edit" ? "Modify Program" : "Create Program"}
        handleFilterStateChange={handleFilterStateChange} filterState={filterState} filterCells={filterCells} FilterApply={FilterApply}
        setsortName={handleSortName} setsortDir={handleSortDir} sortDir={sortDir} sortName={sortName} sortArr={sortArr}
        arrayToFilter={programArray} handleSetFilterData={handleSetFilterData} isTableSearchReq={false} searchValue={searchValue}
      ></BasePageToolbar>
        <BasePageContainer>
          {currentForm === 'list' ?
            <Grid container className={classes.container}>
              <TableComponent
                showEdit={permissions.includes("26")}
                sortOrder="desc" sortOrderBy="createdOn"
                loading={programLoader} headCells={headCells} 
                onChangeView={onChangeView} handlePageNo={handlePageNo}
                activeTab={filterState} totalRecord={totalRecords}
                rows={searchValue && searchValue !== "" ? filterData : programArray}
              />
            </Grid>
            : currentForm === 'create' ?
              <Grid container className={classes.formContainer}>
                <CreateEditViewProgram 
                  currentForm={currentForm}
                  onChangeView={onChangeView}
                  progId={programId}
                  issuerList={issuerList}
                  schemeList={schemeList}
                  setIssuerList={setIssuerList}
                  setSchemeList={setSchemeList}
                  detailsSet={detailsSet}
                  handleDetailsSet={handleDetailsSet}
                  programSchemesDetails={programSchemesDetails}
                  setProgramSchemesDetails={setProgramSchemesDetails}
                />
              </Grid>
              : currentForm === "edit" ?
              <Grid container className={classes.formContainer}>
                  <EditProgram 
                    currentForm={currentForm} 
                    programDetails={programDetailsObj}
                    onChangeView={onChangeView}
                    progId={programId}
                    issuerList={issuerList}
                    schemeList={schemeList}
                    setIssuerList={setIssuerList}
                    setSchemeList={setSchemeList}
                    detailsSet={detailsSet}
                    handleDetailsSet={handleDetailsSet}
                    programSchemesDetails={programSchemesDetails}
                    setProgramSchemesDetails={setProgramSchemesDetails}
                  />
              </Grid> :
                <Grid container className={classes.formContainer}>
                    <CreateEditViewProgram 
                      currentForm={currentForm} 
                      programDetails={programDetailsObj}
                      onChangeView={onChangeView}
                    />
                </Grid>
            }
        </BasePageContainer>
        <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => handleSnackBarAction()}
        action={
        <React.Fragment>
            <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
        }
      >
        <Alert onClose={() => handleSnackBarAction()} severity={
          createProgramRes && createProgramRes !== null && !createProgramRes.st ? "info" : "success"
        }>
            {snackbarMsg}
        </Alert>
      </Snackbar>
      <Dialog
        open={openPopover}
        onClose={() => handleDialogAction(false, "exit")}
        className={"delete_dialog"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h2>{"Program Created Successfully!"}</h2>
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {"Click on Create New button if you want to create new Program"}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" className="yes" autoFocus
              onClick={() => handleDialogAction(false, "Create")} 
          >
            Create New
          </Button>
          <Button color="primary" className="yes" autoFocus
                onClick={() => handleDialogAction(false, "exit")} 
            >
              Close
          </Button>
        </DialogActions>
      </Dialog>
    </>)
}
const useStyles = tss.create({
  formContainer: {
    paddingTop: theme.spacing(0),
  },
  container: {
    paddingTop: theme.spacing(2),
  },
});

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
        fetchProgramDetails, fetchIndividualProgramDetails,
        resetIndividualProgram, handleCountrySelect,
        handleProgramTypeSelect, handleStartDateSelect,
        handleEndDateSelect, handleProgramNameSelect,
        handleDescriptionSelect, handleBrandSelect,
        handleIsDefaultSelect, handleShowCrossBorderSelect,
        clearProgramRes
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    programList: state.ProgramReducer.programListDetails && state.ProgramReducer.programListDetails !== null && state.ProgramReducer.programListDetails.programList ? 
      state.ProgramReducer.programListDetails.programList : [],
    totalRecords: state.ProgramReducer.programListDetails && state.ProgramReducer.programListDetails !== null && state.ProgramReducer.programListDetails.totalRecords ? 
      state.ProgramReducer.programListDetails.totalRecords : [],
    programDetails: state.ProgramReducer.programDetails && state.ProgramReducer.programDetails !== null && state.ProgramReducer.programDetails.programList ? 
      state.ProgramReducer.programDetails.programList[0] : undefined,
    programLoader: state.ProgramReducer.programLoader,
    createProgramRes: state.ProgramReducer.createProgramRes && state.ProgramReducer.createProgramRes,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Program);
