import { AcquirerMngActions } from "../consts/index";

const initialState = {
    createAcqRes: null,
    acquirerList: null,
    acquirerLoader: false,
}

export default (state = initialState, {type, payload}) => {
    switch(type) {
        case AcquirerMngActions.fetchAcquirerDetails.REQUEST:
            return {
                ...state,
                acquirerLoader: true
            }
        case AcquirerMngActions.fetchAcquirerDetails.SUCCESS:
            return {
                ...state,
                acquirerLoader: false,
                acquirerList: payload
            }
        case AcquirerMngActions.fetchAcquirerDetails.FAILURE:
            return {
                ...state,
                acquirerList: null,
                acquirerLoader: false,
            }

        case AcquirerMngActions.createAcquirer.REQUEST:
            return {
                ...state
            }
        case AcquirerMngActions.createAcquirer.SUCCESS:
            return {
                ...state,
                createAcqRes: payload
            }
        case AcquirerMngActions.createAcquirer.FAILURE:
            return {
                ...state,
                createAcqRes: payload
            }
        case AcquirerMngActions.updateAcquirer.REQUEST:
            return {
                ...state
            }
        case AcquirerMngActions.updateAcquirer.SUCCESS:
            return {
                ...state,
                createAcqRes: payload
            }
        case AcquirerMngActions.updateAcquirer.FAILURE:
            return {
                ...state,
                createAcqRes: payload
            }

        default:
            return state;
    }
}