import { createRequestActionTypes } from "../../shared/utility";

export const SchemeActions = {
    // To fetch Brand and Merchant Scheme List
    fetchSchemes: createRequestActionTypes('FETCH_SCHEMES'),
    handleApproveCIBPendingScheme: createRequestActionTypes('HANDLE_APPROVE_CIB_PENDING_SCHEME'),
    // For create, edit, view scheme 
    handleDateChange: createRequestActionTypes('HANDLE_DATE_CHANGE'),
    handleProgramType: createRequestActionTypes('HANDLE_PROGRAM_TYPE'),
    fetchTccMccList: createRequestActionTypes('FETCH_TCC_MCC_DETAILS'),
    handleEmailChange: createRequestActionTypes('HANDLE_EMAIL_CHANGE'),
    handleIssuerChange: createRequestActionTypes('HANDLE_ISSUER_CHANGE'),
    handleCountryChange: createRequestActionTypes('HANDLE_COUNTRY_CHANGE'),
    handleSubventionType: createRequestActionTypes('HANDLE_SUBVENTION_TYPE'),
    handleGeoScopeChange: createRequestActionTypes('HANDLE_GEO_SCOPE_CHANGE'),
    fetchBrandListForScheme: createRequestActionTypes('FETCH_BRAND_LIST_SCHEME'),
    handleStatusCodeChange: createRequestActionTypes('HANDLE_STATUS_CODE_CHANGE'),
    handleInstallDescChange: createRequestActionTypes('HANDLE_INSTALL_DESC_CHANGE'),
    handleProductTypeChange: createRequestActionTypes('HANDLE_PRODUCT_TYPE_CHANGE'),
    handlePaymentModeChange: createRequestActionTypes('HANDLE_PAYMENT_MODE_CHANGE'),
    handleDeleteBranchScheme: createRequestActionTypes('HANDLE_DELETE_BRAND_SCHEME'),
    handleStepper2Details: createRequestActionTypes('HANDLE_STEPPER2_DETAILS_CHANGE'),
    handleStepper3Details: createRequestActionTypes('HANDLE_STEPPER3_DETAILS_CHANGE'),
    handleStepper4Details: createRequestActionTypes('HANDLE_STEPPER4_DETAILS_CHANGE'),
    handleIssuerDetailsFetch: createRequestActionTypes('HANDLE_ISSUER_DETAILS_FETCH'),
    handleStepper1Detailsfetch: createRequestActionTypes('HANDLE_STEPPER1_DETAILS_FETCH'),
    handleBinRangeDetailsFetch: createRequestActionTypes('HANDLE_BIN_RANGE_DETAILS_FETCH'),
    handleCreateSchemeSubmit: createRequestActionTypes('HANDLE_CREATE_SCHEME_SUBMIT_CHANGE'),
    handleFetchBankSchemes: createRequestActionTypes('HANDLE_FETCH_BANK_SCHEMES_AS_TEMPLATE'),
    handleCountryDetailsFetch: createRequestActionTypes('HANDLE_HANDLE_COUNTRY_DETAILS_FETCH_CHANGE'),
    // Merchant and Branch pending schemes 
    handleBrandPendingSchemeDetailsFetch: createRequestActionTypes('HANDLE_BRAND_PENDING_SCHEME_DETAILS_FETCH'),
    // Create Bank Scheme 
    submitCreateBankSchemeForm: createRequestActionTypes('HANDLE_CREATE_BANK_SCHEME'),
    approveBrandOrMerchantScheme: createRequestActionTypes('APPROVE_BRAND_OR_MERCHANT_SCHEME'),
    fetchTenureList: createRequestActionTypes('FETCH_TENURE_LIST'),
}