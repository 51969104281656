import React, { useState } from 'react'
import {
    Grid, MenuItem, Typography, Button, TextField, Paper, Checkbox, Autocomplete
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DownloadProductFile = (props) => {
    const { 
        classes, countryArr, countryArrObj, brandArr, merchantArr, merchantList, 
        downloadFileState, handleDownProductFileChange, submitProductFileDownload, disableDownloadProductFile 
    } = props
    const [errObj, setErrObj] = useState({
        countryErr: false, modeErr: false, brandsErr: false, merchantErr: false
    })

    const handleChange = (key, errKey, value) => {

        if(key === "country") {
            countryArr.indexOf(value) !== -1 && handleDownProductFileChange(key, [
                countryArrObj[countryArr.indexOf(value)]['isoCountryCodeNum'],
                value
            ])
            setErrObj({...errObj, [errKey]: false })
        } else if(key === "merchant") {
            merchantArr.indexOf(value) !== -1 && handleDownProductFileChange(key, [
                merchantList[merchantArr.indexOf(value)]['id'],
                value
            ])
            setErrObj({...errObj, [errKey]: false })
        } else {
            handleDownProductFileChange(key, value)
            setErrObj({...errObj, [errKey]: false })
        }
    }

    const handleOnBlur = (field, value) => {
        if(value === null || value === ""){
            setErrObj({...errObj, [field]: true })
        } else {
            setErrObj({...errObj, [field]: false })
        }
    }

    return (
        <Paper className={classes.paper}>
            <form className="action_form merchant_form" noValidate autoComplete="off">
                <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            InputLabelProps={{ shrink: true }} 
                            variant="outlined" select required fullWidth
                            id='downloadProductFile-country' name='country' label='Country'
                            value={downloadFileState && downloadFileState.country && downloadFileState.country}
                            onBlur={(e) => handleOnBlur("countryErr", e.target.value)}
                            onChange={(e) => countryArr.length > 0 && handleChange('country', 'countryErr', e.target.value)}
                        >
                            {countryArr && countryArr.length === 0 && 
                            <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                            {countryArr && countryArr.map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                            })}
                        </TextField>
                        {errObj.countryErr ? 
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                        </p>    :   null}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            InputLabelProps={{ shrink: true }} 
                            variant="outlined" select required fullWidth
                            id='mode' name='mode' label='Mode'
                            value={downloadFileState && downloadFileState.mode && downloadFileState.mode}
                            onBlur={(e) => handleOnBlur("modeErr", e.target.value)}
                            onChange={(e) => handleChange('mode', 'modeErr', e.target.value)}
                        >
                            {["CREATE", "MODIFY"].map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                            })}
                        </TextField>
                        {errObj.modeErr ? 
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                        </p>    : downloadFileState && downloadFileState.mode && downloadFileState.mode === "CREATE" ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message" style={{color: "#33C4FF"}}><InfoOutlinedIcon /> {"Downloaded file will contain only those product for which mapping does not exist as selected mode is CREATE!"}</span>
                            </p>    :  downloadFileState && downloadFileState.mode && downloadFileState.mode === "MODIFY" ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message" style={{color: "#33C4FF"}}><InfoOutlinedIcon /> {"Downloaded file will contain only those product for which mapping already exist as selected mode is MODIFY!"}</span>
                                </p>    :  null}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Autocomplete
                            options={brandArr} multiple 
                            value={
                                downloadFileState && downloadFileState.brands ?  downloadFileState.brands.map(val => val) : []
                            } 
                            getOptionLabel={(option) => {
                                return option
                            }} 
                            disableCloseOnSelect
                            onChange={(e, newValue) =>  
                                {handleChange('brands', "brandsErr", newValue)} 
                            }
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option}
                                </li>
                            )}
                            renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Brands"
                                InputLabelProps={{ shrink: true }} required
                                disabled={false} name="brands" id="brands"
                            />
                            )}
                        />
                        {errObj.brandsErr ? 
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                        </p>    :   null}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>
                            <Checkbox
                                id="select-all-checkbox"
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                checked={downloadFileState && downloadFileState !== null && downloadFileState.allProducts !== null ? downloadFileState.allProducts : false}
                                onChange={(e) => {handleDownProductFileChange("allProducts", !downloadFileState.allProducts)}}
                            />
                            {"All Products"}
                        </Typography>
                    </Grid>
                    {downloadFileState && downloadFileState !== null && !downloadFileState.allProducts &&
                    <Grid item sm={6} xs={12}>
                        <TextField
                            InputLabelProps={{ shrink: true }} 
                            variant="outlined" select required fullWidth
                            id='downloadProductFile-merchant' name='merchant' label='Merchant'
                            value={downloadFileState && downloadFileState.merchant && downloadFileState.merchant}
                            onBlur={(e) => handleOnBlur("merchantErr", e.target.value)}
                            onChange={(e) => merchantArr.length > 0 && handleChange('merchant', 'merchantErr', e.target.value)}
                        >
                            {merchantArr && merchantArr.length === 0 && 
                            <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                            {merchantArr && merchantArr.map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                            })}
                        </TextField>
                        {errObj.merchantErr ? 
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                        </p>    :   null}
                    </Grid>}
                </Grid>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Button fullWidth variant="contained"
                            color="primary" className={"action_login_button"}
                            disabled={disableDownloadProductFile}
                            onClick={() => submitProductFileDownload()}
                        >
                            {"Submit"}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    )
}

export default DownloadProductFile