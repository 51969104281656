import React from 'react'

import NavItem from './navItem'

const AppSidebarNavList = props => {
  const { items = [], isCollapsed = false, isNested = false } = props
  // const classes = useStyles()

  return (
    <>
      {items.map((item, index) => (
        <NavItem {...item} isCollapsed={isCollapsed} className={'nav-item'} isNested={isNested} key={index} />
      ))}
    </>
  )
}

export default AppSidebarNavList
