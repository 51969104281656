import React, {useEffect, useState} from 'react';
import { bindActionCreators } from "redux";
import {connect} from "react-redux";
import {reduxForm} from 'redux-form';
import {
  Grid, TextField, Checkbox,
  Button, Paper, MenuItem, Typography, IconButton, 
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../../../_theme'
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import { 
  handleCountrySelect, handleBrandNameSelect,
  handleDisplayNameSelect, handleDescriptionSelect,
  handleIsSerialNumberRequiredCheck,
  submitCreateOrEditBrandForm, clearBrandRes
} from "../actions";
import {fetchCountryDetails} from "../../../manageScheme/actions/index";
import "../../uploadImage.css"
import {SpecialCharRegex1, addRegex, nameRegExp, descrRegex} from "../../../shared/utility";
import { NO_SPECIAL_CHARACTERS } from "shared/errorMessages";

const useStyles = tss.create({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    border: "solid 1px #EEEEEE",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(5),
  },
  input: {
    display: 'none',
  },
  menu: {
    fontSize: "15px"
  },
  titleText: {
    color: "#424242",
    fontFamily: "Quicksand",
    // fontSize: "24px",
    fontSize: "26px",
    letterSpacing: 0,
    lineHeight: "30px"
  },
});

function CreateEditorViewBrand(props) {
  const {classes} = useStyles();
  const {
    country, brandName, displayName, description, isSerialNumberRequired, docUploadLoader,
    countryDetails, brandDetails, currentForm, 
    brId, onChangeView, handleUploadImage, imageHandler, imageData
  } = props;
  const [status, setStatus] = useState("ACTIVE");
  const [valObj, setValObj] = useState({
    countryErr: false, brandNameErr: false, displayNameErr: false, descriptionErr: false, isSerialNumberRequiredErr: false,
  })

  const reqBrandId = brId ? brId : ""

  const createDisableFlag = currentForm !== "view" && country !== null && country !== "" &&
    brandName !== null && brandName !== "" && nameRegExp.test(brandName) && 
    displayName !== null && displayName !== "" && nameRegExp.test(displayName) &&  
    description !== null && description !== "" && descrRegex.test(description)

  useEffect(() => {
    countryDetails && countryDetails !== null && countryDetails.length === 0 && props.fetchCountryDetails({})
    return async () => {
      //resetting form on component Mount
      resetOnCreateSelect()
      await props.clearBrandRes(null)
    }
  }, [])

  useEffect(() => {
    if(brandDetails && currentForm === "edit") {
      setStatus(brandDetails.status)
      props.handleCountrySelect(brandDetails.countryName && brandDetails.countryName);
      props.handleBrandNameSelect(brandDetails.name && brandDetails.name);
      props.handleDisplayNameSelect(brandDetails.displayName && brandDetails.displayName);
      props.handleDescriptionSelect(brandDetails.description && brandDetails.description);
      props.handleIsSerialNumberRequiredCheck(brandDetails.isSerialNumberRequired && brandDetails.isSerialNumberRequired);
    }
  }, [brandDetails])

  useEffect(() => {
    resetOnCreateSelect()
  }, [currentForm])

  let countryObj = []
  const countryArray = countryDetails && countryDetails !== null && countryDetails.length > 0 ? 
    countryDetails.map(obj => {
        countryObj.push(obj)
        return obj.name
    }) : []
  
  const resetOnCreateSelect = async () => {
    if(currentForm === "create") {
      await props.handleCountrySelect(null);
      await props.handleBrandNameSelect("");
      await props.handleDisplayNameSelect("");
      await props.handleDescriptionSelect("");
      await props.handleIsSerialNumberRequiredCheck(false);
    }
  }  

  const handleCountryChange = (e) => {
    props.handleCountrySelect(e.target.value)
    setValObj({...valObj, countryErr: false })
  }

  const handleInputChange = (type, e) => {
    if(type === "name") {
        props.handleBrandNameSelect(e.target.value)
        setValObj({...valObj, brandNameErr: false })
    } else if(type === "displayName") {
        props.handleDisplayNameSelect(e.target.value)
        setValObj({...valObj, displayNameErr: false})
    } else if (type === "isSerialNumberRequired") { 
        props.handleIsSerialNumberRequiredCheck(e.target.checked)
          setValObj({...valObj, isSerialNumberRequiredErr: false})
    } else {
        props.handleDescriptionSelect(e.target.value)
        setValObj({...valObj, descriptionErr: false})
    }
  }

  const handleCreateBrandSubmit = () => {

    const reqPayload = {
      "id" : currentForm === "edit" ? reqBrandId : null,
      "status": status,
      "description": description,
      "name": brandName,
      "displayName": displayName,
      "countryName": country && country !== "" ? country : "",
      "isoCountryCodeNum" : country && country !== "" && countryArray.indexOf(country) !== -1 
			? countryObj[countryArray.indexOf(country)].isoCountryCodeNum : null,
      "image" : null,
      "userId": "1",
      "isSerialNumberRequired": isSerialNumberRequired,
    }
    props.submitCreateOrEditBrandForm(reqPayload);
    //calling final API for Creating Scheme
  }

  const handleOnBlur = (field, value) => {
    if(value === null || value === ""){
      setValObj({...valObj, [field]: true })
    } else {
      setValObj({...valObj, [field]: false })
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form className="action_form merchant_form" noValidate autoComplete="off">
          <Grid container rowSpacing={2} columnSpacing={2}>

            {/* {currentForm === "edit" &&
              <>
                <Grid item xs={12} sm={8} />
                <Grid item xs={12} sm={4}>
                  <TextField 
                      InputLabelProps={{ shrink: true }} 
                      name="status" variant="outlined" select required
                      fullWidth id="status" label="Brand status"
                      value={ status }
                      onChange={(e) => setStatus(e.target.value)}
                  >
                      {["ACTIVE", "INACTIVE"].map((val, index) => {
                      return <MenuItem key={index} value={val} className={classes.menu}>
                          {val}
                      </MenuItem>
                      })}
                  </TextField>
                </Grid>
              </>
            } */}
            <Grid item xs={12} sm={6}>
                <TextField 
                    InputLabelProps={{ shrink: true }} 
                    required
                    fullWidth 
                    variant="outlined"
                    id="country" 
                    name="country" 
                    label="Country"
                    select={currentForm === 'view' ? false : true} 
                    disabled={currentForm !== 'create' ? true : false}
                    value={ currentForm === "view" ? brandDetails && brandDetails.countryName && brandDetails.countryName : country} 
                    onBlur={(e) => handleOnBlur("countryErr", e.target.value)}
                    onChange={e => handleCountryChange(e)}
                >
                    {countryArray && countryArray.length === 0 && 
                    <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                    {(countryArray).map((val, index) => {
                        return <MenuItem key={index} value={val} className={classes.menu}>
                            {val}
                        </MenuItem>
                    })}
                </TextField>
                {valObj.countryErr ? 
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                  </p>
                :null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                  InputLabelProps={{ shrink: true }} 
                  required
                  name="brandName" 
                  id="brandName" 
                  label="Brand Name"
                  variant="outlined"
                  fullWidth 
                  onChange={e => e.target.value.length <= 50 && handleInputChange('name', e)} 
                  disabled={currentForm !== "create" ? true : false}                          
                  value={currentForm === "view"  ? brandDetails && brandDetails['name'] && brandDetails['name'] : brandName}  
                  onBlur={(e) => handleOnBlur("brandNameErr", e.target.value)}
              />
              {valObj.brandNameErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>
              :brandName && !nameRegExp.test(brandName) ?
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                </p>
                : null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                  InputLabelProps={{ shrink: true }} 
                  required
                  name="displayName" 
                  id="displayName" 
                  label="Display Name"
                  variant="outlined"
                  fullWidth 
                  onChange={e => e.target.value.length <= 50 && handleInputChange('displayName', e)} 
                  disabled={currentForm === "view" ? true : false}                          
                  value={currentForm === "view"  ? brandDetails && brandDetails['displayName'] && brandDetails['displayName'] : displayName}  
                  onBlur={(e) => handleOnBlur("displayNameErr", e.target.value)}
              />
              {valObj.displayNameErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>
              :displayName && !nameRegExp.test(displayName) ?
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                </p>
                : null
            }
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                  InputLabelProps={{ shrink: true }} 
                  required
                  name="description" 
                  id="description" 
                  label="Description"
                  variant="outlined"
                  fullWidth 
                  onChange={e => e.target.value.length <= 255 && handleInputChange('description', e)} 
                  disabled={currentForm === "view" ? true : false}                          
                  value={currentForm === "view"  ? brandDetails && brandDetails['description'] && brandDetails['description'] : description} 
                  onBlur={(e) => handleOnBlur("descriptionErr", e.target.value)} 
              />
              {valObj.descriptionErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>
              :description && !descrRegex.test(description) ?
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                </p>
                : null
              }
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                  <Checkbox
                      id="isSerialNumberRequired"
                      color="primary"
                      disabled={currentForm === "view" ? true : false}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      checked={currentForm !== 'view' ?
                        isSerialNumberRequired : brandDetails && brandDetails['isSerialNumberRequired'] 
                        ? brandDetails['isSerialNumberRequired'] : false
                      }
                      onChange={(e) => handleInputChange('isSerialNumberRequired', e)}
                  />
                  {"Is Serial Number Required"}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" rowSpacing={2} columnSpacing={2}>
            <Grid item>
              <Button fullWidth variant="contained"
                color="primary" className={"action_login_button"}
                disabled={currentForm !== "view" ? !createDisableFlag : false}
                onClick={() => currentForm !== "view" ? handleCreateBrandSubmit() : onChangeView("list")}
              >
                {currentForm === "edit" ? "Modify" : currentForm === "view" ? "Close" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>

      {currentForm === "edit" && 
        <Paper className={classes.paper}>
          <Grid container>
              <Grid item xs={12} >
                  <Typography variant="h4" className={classes.titleText} component="h1">Image Section</Typography>
              </Grid>
              
              <Grid item xs={12} sm={4}>
                  <div className="page">
                      <div className="container">
                          <h1 className="heading">BRAND IMAGE</h1>
                          {!(imageData && imageData.baseURL && imageData.baseURL !== "") &&
                            <h6 className="heading">{`( Image size limit 2mb. 300 W X 150 H Pixel limit)`}</h6>}
                          {imageData && imageData.baseURL && imageData.baseURL !== "" &&
                            <div className={ imageData && imageData.resourceId && imageData.resourceId !== "" ? "img-holder-cross" : "img-holder"}>
                              {docUploadLoader === "BRAND_IMAGE"  ?
                                <CircularProgress className="circleIcon"/>
                              : null}
                              <img src={imageData.baseURL} alt="" id="img" className="img" ></img>
                              {imageData && imageData.resourceId && imageData.resourceId !== "" &&
                                <div className="close-icon">
                                  <IconButton size="small" aria-label="close" color="inherit" onClick={() => handleUploadImage("DELETE_ACTION")}>
                                    <CloseIcon fontSize="small" />
                                  </IconButton>
                                </div>
                              }
                            </div>
                          }
                          <input  type="file" name="image-upload" id="input" accept="image/*" onChange={(e) => imageHandler(e, "SCREEN_TYPE_PRODUCT_LIST")}/>
                          {imageData && imageData.baseURL && imageData.baseURL !== "" ? null :
                          <div className="label" >
                          <label htmlFor="input" className="contained_label">
                            <Button variant="contained" className="upload_button" color="primary" component="span">
                              Upload
                            </Button>
                          </label>
                          </div>
                          }
                          {imageData && imageData.actionKey && imageData.actionKey === "UPLOAD" &&
                          <div className="upload-action">
                          <Button color="primary" className="yes" autoFocus
                            style={{margin: "0px 2px"}}
                            onClick={() => handleUploadImage("UPLOAD")} 
                          >
                              Confirm
                          </Button>
                          <Button color="primary" className="no"
                            style={{margin: "0px 2px"}}
                            onClick={() => handleUploadImage("CANCEL")} 
                          >
                              Cancel
                          </Button>
                          </div>}
                      </div>
                  </div>  
              </Grid> 
          </Grid>
      </Paper>
      }

      {currentForm === "view" && imageData && imageData.baseURL && imageData.baseURL !== "" &&
        <Paper className={classes.paper}>
          <Grid container>
              <Grid item xs={12} >
                <Typography variant="h4" className={classes.titleText} component="h1">Image Section</Typography>
              </Grid>
              
              <Grid item xs={12} sm={4}>
                <div className="page">
                  <div className="container">
                    <h1 className="heading">BRAND IMAGE</h1>
                    <div className="img-holder">
                    <img src={imageData.baseURL} alt="" id="img" className="img" ></img>
                    </div>
                  </div>
                </div>  
              </Grid>
          </Grid> 
        </Paper>
      }
    </div>
  )
}

CreateEditorViewBrand = reduxForm({
  form: 'CreateEditorViewBrand',
  // validate: validate,
  enableReinitialize: true
})(CreateEditorViewBrand);

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
        handleCountrySelect, handleBrandNameSelect,
        handleDisplayNameSelect, handleDescriptionSelect, 
        handleIsSerialNumberRequiredCheck,
        fetchCountryDetails, submitCreateOrEditBrandForm,
        clearBrandRes
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    country: state.BrandReducer.country,
    brandName: state.BrandReducer.brandName,
    displayName: state.BrandReducer.displayName,
    description: state.BrandReducer.description,
    isSerialNumberRequired: state.BrandReducer.isSerialNumberRequired,
    countryDetails: state.SchemeReducer.countryDetails && state.SchemeReducer.countryDetails !== null 
      && state.SchemeReducer.countryDetails.countryList ? state.SchemeReducer.countryDetails.countryList : [],
    docUploadLoader: state.BrandReducer.docUploadLoader
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditorViewBrand);
