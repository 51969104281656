import { actionCreator, BASE_URL } from "../../shared/utility";
import { UserActions } from "../consts/index";

export function fetchUserDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(UserActions.fetchUsersDetails.REQUEST))
        fetch(`${BASE_URL}/auth/api/data/web/getWebUser`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(UserActions.fetchUsersDetails.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(UserActions.fetchUsersDetails.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function createOrEditUser(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(UserActions.createUserDetails.REQUEST))
        fetch(`${BASE_URL}/auth/api/data/web/addOrEditWebUser`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(UserActions.createUserDetails.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(UserActions.createUserDetails.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetUserCreateResp() {
    return(dispatch) => {
        dispatch(actionCreator(UserActions.createUserDetails.FAILURE, null))
    }
}

export function fetchRoleDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(UserActions.fetchRoleDetails.REQUEST))
        fetch(`${BASE_URL}/auth/api/data/web/getRole`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(UserActions.fetchRoleDetails.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(UserActions.fetchRoleDetails.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetRoleDetails() {
    return(dispatch) => {
        dispatch(actionCreator(UserActions.fetchRoleDetails.FAILURE, null))
    }
}

export function fetchPermissionDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(UserActions.fetchPermissionDetailsByRole.REQUEST))
        fetch(`${BASE_URL}/auth/api/data/web/permission`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(UserActions.fetchPermissionDetailsByRole.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(UserActions.fetchPermissionDetailsByRole.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchPermissionDetailsForView(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(UserActions.fetchPermissionDetailsForView.REQUEST))
        fetch(`${BASE_URL}/auth/api/data/web/permission`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(UserActions.fetchPermissionDetailsForView.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(UserActions.fetchPermissionDetailsForView.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function createOrEditRole(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(UserActions.createRoleDetails.REQUEST))
        fetch(`${BASE_URL}/auth/api/data/web/addOrEditRole`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(UserActions.createRoleDetails.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(UserActions.createRoleDetails.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetRoleCreateResp() {
    return(dispatch) => {
        dispatch(actionCreator(UserActions.createRoleDetails.FAILURE, null))
    }
}

export function resetUserPassword(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(UserActions.resetUserPassword.REQUEST))
        fetch(`${BASE_URL}/auth/api/data/web/resetPassword`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(UserActions.resetUserPassword.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(UserActions.resetUserPassword.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetChangePasswordResp() {
    return(dispatch) => {
        dispatch(actionCreator(UserActions.resetUserPassword.FAILURE, null))
    }
}