import { SchemeActions } from "../consts/index";
import addDays from "date-fns/addDays";

const initialState = {
    mibLoader: false,
    cibApproveSchemeRes: null,
    schemeList: null,
    email: null,
    issuer: null,
    country: null,
    geoScope: null,
    statusCode: null,
    TccMccList: null,
    TccLoader: false,
    tccDetails: null,
    programType: null,
    paymentMode: null,
    productType: null,
    installDesc: null,
    createLoad: false,
    binRangeList: null,
    amountDetails: null,
    issuerDetails: null,
    subventionType: null,
    countryDetails: null,
    bankSchemeList: null,
    binRangeDetails: null,
    stepper1Details: null,
    deleteSchemeRes: null,
    createSchemeRes: null,
    brandListForScheme: null,
    endDate: addDays(new Date(), 2),
    startDate: addDays(new Date(), 1),
    approveSchemeResp: null,
    tenureList: null,
}

export default (state = initialState, {type, payload}) => {
    switch(type) {
        case SchemeActions.fetchSchemes.REQUEST:
            return {
                ...state,
                mibLoader: true
            }
        case SchemeActions.fetchSchemes.SUCCESS:
            return {
                ...state,
                mibLoader: false,
                schemeList: payload,
            }
        case SchemeActions.fetchSchemes.FAILURE:
            return {
                ...state,
                mibLoader: false,
                schemeList: null,
            }
        
        case SchemeActions.handleApproveCIBPendingScheme.REQUEST:
            return {
                ...state,
            }
        case SchemeActions.handleApproveCIBPendingScheme.SUCCESS:
            return {
                ...state,
                cibApproveSchemeRes: payload,
            }
        case SchemeActions.handleApproveCIBPendingScheme.FAILURE:
            return {
                ...state,
                cibApproveSchemeRes: payload,
            }
        case SchemeActions.handleDateChange.SUCCESS:
            return {
                ...state,
                startDate: payload[0],
                endDate: payload[1]
            }

        case SchemeActions.handleCountryChange.SUCCESS: 
            return {
                ...state,
                country: payload
            }

        case SchemeActions.handleIssuerChange.SUCCESS: 
            return {
                ...state,
                issuer: payload
            }
        
        case SchemeActions.handleStatusCodeChange.SUCCESS: 
            return {
                ...state,
                statusCode: payload
            }
        
        case SchemeActions.handleEmailChange.SUCCESS:
            return {
                ...state,
                email: payload
            }
        
        case SchemeActions.handleInstallDescChange.SUCCESS:
            return {
                ...state,
                installDesc: payload
            }
        
        case SchemeActions.handleProductTypeChange.SUCCESS: 
            return {
                ...state,
                productType: payload
            }
        
        case SchemeActions.handlePaymentModeChange.SUCCESS: 
            return {
                ...state,
                paymentMode: payload
            }
        
        case SchemeActions.handleGeoScopeChange.SUCCESS: 
            return {
                ...state,
                geoScope: payload
            }
        
        case SchemeActions.handleStepper3Details.SUCCESS:
            return {
                ...state,
                amountDetails: payload
            }
        
        case SchemeActions.handleStepper2Details.SUCCESS:
            return {
                ...state,
                tccDetails: payload
            }
        
        case SchemeActions.handleStepper4Details.SUCCESS:
            return {
                ...state,
                binRangeDetails: payload
            }

        case SchemeActions.fetchTccMccList.REQUEST:
            return {
                ...state,
                TccLoader: true
            }
        case SchemeActions.fetchTccMccList.SUCCESS:
            return {
                ...state,
                TccLoader: false,
                TccMccList: payload,
            }
        case SchemeActions.fetchTccMccList.FAILURE:
            return {
                ...state,
                TccLoader: false,
                TccMccList: null,
            }


        case SchemeActions.handleCreateSchemeSubmit.REQUEST: 
            return {
                ...state,
                createLoad: true,
                createSchemeRes: null
            }
        case SchemeActions.handleCreateSchemeSubmit.SUCCESS: 
            return {
                ...state,
                createLoad: false,
                createSchemeRes: payload
            }
        case SchemeActions.handleCreateSchemeSubmit.FAILURE: 
            return {
                ...state,
                createLoad: false,
                createSchemeRes: payload
            }

        case SchemeActions.handleDeleteBranchScheme.REQUEST:
            return {
                ...state
            }
        case SchemeActions.handleDeleteBranchScheme.SUCCESS:
            return {
                ...state,
                deleteSchemeRes: payload
            }
        case SchemeActions.handleDeleteBranchScheme.FAILURE:
            return {
                ...state,
                deleteSchemeRes: payload
            }


        case SchemeActions.handleStepper1Detailsfetch.REQUEST:
            return {
                ...state
            }
        case SchemeActions.handleStepper1Detailsfetch.SUCCESS:
            return {
                ...state,
                stepper1Details: payload
            }
        case SchemeActions.handleStepper1Detailsfetch.FAILURE:
            return {
                ...state,
                stepper1Details: null
            }

        case SchemeActions.handleCountryDetailsFetch.REQUEST:
            return {
                ...state
            }
        case SchemeActions.handleCountryDetailsFetch.SUCCESS:
            return {
                ...state,
                countryDetails: payload
            }
        case SchemeActions.handleCountryDetailsFetch.FAILURE:
            return {
                ...state,
                countryDetails: null
            }

        case SchemeActions.handleIssuerDetailsFetch.REQUEST:
            return {
                ...state
            }
        case SchemeActions.handleIssuerDetailsFetch.SUCCESS:
            return {
                ...state,
                issuerDetails: payload
            }
        case SchemeActions.handleIssuerDetailsFetch.FAILURE:
            return {
                ...state,
                issuerDetails: null
            }

        case SchemeActions.handleBinRangeDetailsFetch.REQUEST:
            return {
                ...state
            }
        case SchemeActions.handleBinRangeDetailsFetch.SUCCESS:
            return {
                ...state,
                binRangeList: payload
            }
        case SchemeActions.handleBinRangeDetailsFetch.FAILURE:
            return {
                ...state,
                binRangeList: null
            }

        case SchemeActions.fetchBrandListForScheme.REQUEST:
            return {
                ...state
            }
        case SchemeActions.fetchBrandListForScheme.SUCCESS:
            return {
                ...state,
                brandListForScheme: payload
            }
        case SchemeActions.fetchBrandListForScheme.FAILURE:
            return {
                ...state,
                brandListForScheme: null
            }
        
        case SchemeActions.handleFetchBankSchemes.REQUEST:
            return {
                ...state
            }
        case SchemeActions.handleFetchBankSchemes.SUCCESS:
            return {
                ...state,
                bankSchemeList: payload
            }
        case SchemeActions.handleFetchBankSchemes.FAILURE:
            return {
                ...state,
                bankSchemeList: payload
            }

        case SchemeActions.handleProgramType.SUCCESS:
            return {
                ...state,
                programType: payload
            }
        case SchemeActions.handleSubventionType.SUCCESS:
            return {
                ...state,
                subventionType: payload
            }

        case SchemeActions.submitCreateBankSchemeForm.REQUEST:
            return {
                ...state,
                createSchemeRes: null,
                createLoad: true,
            }
        case SchemeActions.submitCreateBankSchemeForm.SUCCESS:
            return {
                ...state,
                createSchemeRes: payload,
                createLoad: false,
            }
        case SchemeActions.submitCreateBankSchemeForm.FAILURE:
            return {
                ...state,
                createSchemeRes: payload,
                createLoad: false,
            }

        case SchemeActions.approveBrandOrMerchantScheme.REQUEST:
            return {
                ...state,
                approveSchemeResp: null,
            }
        case SchemeActions.approveBrandOrMerchantScheme.SUCCESS:
            return {
                ...state,
                approveSchemeResp: payload,
            }
        case SchemeActions.approveBrandOrMerchantScheme.FAILURE:
            return {
                ...state,
                approveSchemeResp: payload,
            }

        case SchemeActions.fetchTenureList.REQUEST:
            return {
                ...state,
                tenureList: null,
            }
        case SchemeActions.fetchTenureList.SUCCESS:
            return {
                ...state,
                tenureList: payload,
            }
        case SchemeActions.fetchTenureList.FAILURE:
            return {
                ...state,
                tenureList: payload,
            }

        default:
            return state;
    }
}