import { configureStore } from '@reduxjs/toolkit'

import MciReducer from "./manageMCI/reducer";
import AuthReducer from "./auth/reducer/index";
import SharedReducer from "./sharedApi/reducer";
import { reducer as formReducer } from 'redux-form';
import UserReducer from "./manageUser/reducer/index";
import CountryReducer from "./manageCountry/reducer";
import PspReducer from "./manageMerchant/psp/reducer";
import IssuerReducer from "./manageIssuer/reducer/index";
import StoreReducer from "./manageMerchant/store/reducer";
import ProgramReducer from "./manageProgram/reducer/index";
import ClientLogReducer from "./paxTerminal/reducer/index";
import BrandReducer from "./oemManagement/brand/reducer/index";
import MerchantReducer from "./manageMerchant/merchant/reducer";
import TerminalReducer from "./manageMerchant/terminal/reducer";
import ProductReducer from "./oemManagement/product/reducer/index";
import ProductCategoryReducer from "./oemManagement/productCategory/reducer/index";
import AcquirerReducer from "./manageMerchant/acquirer/reducer";
import PSPAcquirerReducer from "./manageMerchant/pspAcquirer/reducer";
import ProductGroupReducer from "./oemManagement/productGroup/reducer/index";
import ProductCodeMappingReducer from "./oemManagement/productCodeMapping/reducer/index";
import SchemeReducer from "./manageScheme/reducer/index";
import EncryptionReducer from "./manageRSAEncryption/reducer/index"

export default configureStore({
    reducer: {
        form: formReducer,
        MciReducer: MciReducer,
        PspReducer: PspReducer,
        AuthReducer: AuthReducer,
        UserReducer: UserReducer,
        StoreReducer: StoreReducer,
        BrandReducer: BrandReducer,
        IssuerReducer: IssuerReducer,
        SharedReducer: SharedReducer,
        CountryReducer: CountryReducer,
        ProgramReducer: ProgramReducer,
        ProductReducer: ProductReducer,
        MerchantReducer: MerchantReducer,
        TerminalReducer: TerminalReducer,
        ClientLogReducer: ClientLogReducer,
        ProductCategoryReducer: ProductCategoryReducer,
        AcquirerReducer: AcquirerReducer,
        PSPAcquirerReducer: PSPAcquirerReducer,
        ProductGroupReducer: ProductGroupReducer,
        ProductCodeMappingReducer: ProductCodeMappingReducer,
        SchemeReducer: SchemeReducer,
        EncryptionReducer: EncryptionReducer,
    }
})