import React from 'react'
import { 
    Grid, Paper, Typography
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../../../_theme'

import TableComponent from '../../table'

const useStyles = tss.create({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        border: "solid 1px #EEEEEE",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(5),
    },
    addRange: {
        alignItems: "center",
        display: "flex",
    },
});

const headCells = [
    { id: 'installmentConfigDesc', type: 'text', numeric: false, disablePadding: false, label: 'Installment Config Desc' },
    { id: 'installmentConfigID', type: 'text', numeric: true, disablePadding: false, label: 'Installment Config ID' },
    { id: 'mciID', type: 'text', numeric: true, disablePadding: false, label: 'Issuer ID' },
    { id: 'status', type: 'text', numeric: false, disablePadding: false, label: 'Status' },
];

export default function SchemeDetails(props) {
    const {classes} = useStyles();
    const { loading, brandSchemeList, schemeCount, handlePageNo } = props

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12} sm={12} justifyContent="flex-start" className={classes.addRange}>
                        <Typography className={classes.title} variant="h6" id="filterTitle" component="div">
                            {"Mapped Scheme List"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TableComponent
                            loading={loading}
                            headCells={headCells}
                            rows={brandSchemeList}
                            totalRecord={schemeCount}
                            handlePageNo={handlePageNo}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}