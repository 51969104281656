import React from "react";
import {
  Grid, TextField, Paper, Button, Typography, MenuItem,
  Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

import DeleteIcon from '../../../Assets/delete.svg';

function BinRangeStepper(props) {
    const {
        classes, handleNext, handleStepper4InputChange, id, nextButtonFlag, obj,
        handleBinRangeAdd, handleBinRangeDelete, disableToken, showFooter, schemeType, createLoad,
        handleStepback, currentForm, onChangeView, selectedBinRange, deleteToken, binRangeList, bankTemplBinList
    } = props;

    const [valObj, setValObj] = React.useState({ 
        fundingBinErr: false
    })

    const DisableFlag = disableToken ? disableToken : false
    const allowEdit = currentForm === "edit";

    let bankTemplBinObj = []
    const bankTemplFundingBinArr = (!DisableFlag && bankTemplBinList !== null) ? 
        bankTemplBinList.map(obj => {
            bankTemplBinObj.push(obj)
            return obj.fundingBin
        }) : []

    const handleBinRangeInput = async (id, key, value, type) => {
        
        if(type === "dropdown" && selectedBinRange && selectedBinRange.includes(bankTemplBinObj[bankTemplFundingBinArr.indexOf(value)]['fundingBin'])) {
            setValObj({...valObj, fundingBinErr: "true" })
        } else if(type === "textfield" && selectedBinRange && selectedBinRange.includes(value)) { 
            setValObj({...valObj, fundingBinErr: "true" })
        } else if (type === "textfield") {
            await setValObj({...valObj, fundingBinErr: false})
            handleStepper4InputChange(id, key, value, type);
        } else {
            await setValObj({...valObj, fundingBinErr: false})
            handleStepper4InputChange(id, key, bankTemplBinObj[bankTemplFundingBinArr.indexOf(value)], type);
        }
    }

    const handleOnBlur = (type, value) => {
        value === null ?
            !valObj.fundingBinErr && setValObj({...valObj, fundingBinErr: true })
            : setValObj({...valObj, fundingBinErr: false })
    }
    
    return (
        <React.Fragment>
            <div className={"paper_scroll"}>
                <Accordion className={classes.accordion} key={0} 
                    expanded={true} 
                >
                    <AccordionSummary  aria-controls="panel1a-content" id={"panel" + 1} >
                        <Typography className={classes.heading}>
                            { "Bin Details"}  {id + 1}
                        </Typography>
                        {(!disableToken && deleteToken > 1) ?
                            <div className="deleteAccordIcon" 
                                onClick={() => handleBinRangeDelete(id)}
                            >
                                <img alt="" src={DeleteIcon} />
                            </div>
                            :null
                        }
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container rowSpacing={2} columnSpacing={2}>
                            <Paper className={classes.subRangePaper}>
                                <React.Fragment key={0}>
                                    <Grid container rowSpacing={2} columnSpacing={2}>

                                        <Grid item xs={12} sm={4}>
                                            <TextField 
                                                InputLabelProps={{ shrink: true }} 
                                                value={obj['fundingBin']} disabled={DisableFlag}
                                                name={`fundingBin-${id}`}  variant="outlined"
                                                fullWidth select={props.schemeType !== "Bank" ? !DisableFlag : false} id={`fundingBin-${id}`} label="Funding Bin" required
                                                onChange={props.schemeType !== "Bank" ? (e) => handleBinRangeInput(id, "fundingBin", e.target.value, "dropdown") 
                                                    : e => handleBinRangeInput(id, "fundingBin", e.target.value, "textfield")
                                                }
                                                onBlur={(e) => handleOnBlur("fundingBinErr", e.target.value)}
                                            >
                                                {(bankTemplFundingBinArr).map((val, index) => {
                                                    return <MenuItem key={index} value={val} className={classes.menu}>
                                                        {val}
                                                    </MenuItem>
                                                })}
                                            </TextField>
                                            {valObj.fundingBinErr === true ? 
                                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                                </p>
                                                :valObj.fundingBinErr === "true" ? 
                                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                                        <span className="error_message"><InfoOutlinedIcon /> {"This Range is already selected!"}</span>
                                                    </p>
                                                    :null
                                            }
                                        </Grid>
                                        {props.schemeType !== "Bank" && !props.isPLManaged &&
                                            <Grid item xs={12} sm={4}>
                                                <TextField 
                                                    InputLabelProps={{ shrink: true }} 
                                                    value={obj['icaID']} name={`icaId-${id}`} variant="outlined"
                                                    fullWidth id={`icaId-${id}`} label="ICA ID" required disabled
                                                    onChange={e => handleStepper4InputChange(id, "icaID", e.target.value)} 
                                                    onBlur={(e) => handleStepper4InputChange(id, "icaID", e.target.value)}
                                                />
                                            </Grid> 
                                    
                                        }
                                        <Grid item xs={12} sm={4}>
                                            <TextField 
                                                InputLabelProps={{ shrink: true }} 
                                                value={obj['fundingBinLength']} name={`fundingBinLength-${id}`} variant="outlined"
                                                fullWidth id={`fundingBinLength-${id}`} label="Bin Length" required disabled={!(props.currentForm !== "view" && props.schemeType === "Bank")}
                                                onChange={e => handleStepper4InputChange(id, "fundingBinLength", e.target.value)} 
                                                onBlur={(e) => handleStepper4InputChange(id, "fundingBinLength", e.target.value)}
                                            />
                                        </Grid>

                                    </Grid>
                                </React.Fragment>
                            </Paper>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </div>
            {showFooter && <Grid container className="step_action">
                <Grid item xs={8} justify="flex-start" className={classes.addRange}>
                    {!DisableFlag && <Button className={"btn_addForm"} 
                        onClick={() => handleBinRangeAdd()}
                    >
                        <AddCircleOutlineRoundedIcon className={classes.iconCircleOutline} />  Add New Range
                    </Button>}
                </Grid>
                <Grid item xs={4} style={{ display: "flex", justifyContent: "space-evenly"  }} justify="flex-end">
                    <Button 
                        onClick={handleStepback} 
                        disabled={createLoad}
                        className={`action_login_button`}
                    >
                        Back
                    </Button>
                    <Button disabled={!nextButtonFlag || createLoad}
                        onClick={() => ((schemeType === "Merchant" || schemeType === "Bank") && DisableFlag) ? allowEdit ? handleNext() : onChangeView("list") : handleNext()}
                        className={`action_login_button ${!nextButtonFlag ? "disabled_login_button" : ""}`}
                    >
                        {schemeType === "Brand" ? "Next" : ((schemeType === "Merchant" || schemeType === "Bank") && DisableFlag) ? "Close" : allowEdit ? "Modify" : "Create"}
                        {createLoad ? <CircularProgress color="secondary" style={{
                            "width": "25px", "height": "25px", "marginLeft": "6px", "color": "#fff"}}
                        /> : null}
                    </Button>
                </Grid>
            </Grid>}
        </React.Fragment>
    )
}

export default BinRangeStepper;