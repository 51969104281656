import { createRequestActionTypes } from "../../../shared/utility";

export const ProductCategoryActions = {
    handleProductCategoryNameChangeForCategory: createRequestActionTypes('HANDLE_PRODUCT_CATEGORY_NAME_CHANGE_FOR_CATEGORY'),
    handleDisplayNameChangeForCategory: createRequestActionTypes('HANDLE_DISPLAY_NAME_CHANGE_FOR_CATEGORY'),
    handleDescriptionChangeForCategory: createRequestActionTypes('HANDLE_DESCRIPTION_CHANGE_FOR_CATEGORY'),
    handleParentCategoryChangeForCategory: createRequestActionTypes('HANDLE_PARENT_CATEGORY_CHANGE_FOR_CATEGORY'),
    handleProductCategoryDetailsFetch: createRequestActionTypes('HANDLE_PRODUCT_CATEGORY_DETAILS_FETCH'),
    handleIndividualProductCategoryDetailsFetch: createRequestActionTypes('HANDLE_INDIVIDUAL_PRODUCT_CATEGORY_DETAILS_FETCH'),
    handleParentCategoryDetailsFetchForCategory: createRequestActionTypes('HANDLE_PARENT_CATEGORY_DETAILS_FETCH_FOR_CATEGORY'),
    handleCreateOrEditProductCategorySubmit: createRequestActionTypes('HANDLE_CREATE_OR_EDIT_PRODUCT_CATEGORY_SUBMIT'),
    uploadDocumentForCategory: createRequestActionTypes('UPLOAD_DOCUMENT_FOR_CATEGORY'),
}