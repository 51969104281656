import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Snackbar, Dialog, DialogActions,
    DialogTitle, DialogContentText, DialogContent, Button, Grid 
} from '@mui/material'
import { tss } from "tss-react";
import theme from '../../_theme'

import BasePageToolbar from '../../_common/basePageToolbar';
import BasePageContainer from '../../_common/basePageContainer';
import TableComponent from '../table/index';
import CreateEditViewMapping from './createEditViewMapping/index';
import {fetchCountryDetails} from '../../manageScheme/actions/index';
import {
    fetchPSPAcquirerDetails, createPSPAcquirerMapping, 
    updatePSPAcquirerMapping, resetCreatePSPAcquirerMapping, 
    fetchPSPMasterData, fetchAcqMasterData, 
    fetchPspIntegrationModeMasterData,
} from './actions/index';

const headCells = [
    { id: 'pspName', type: 'text', numeric: false, disablePadding: false, label: 'PSP Name' },
    { id: 'acquirerName', type: 'text', numeric: false, disablePadding: false, label: 'Acquirer Name' },
    { id: 'countryName', type: 'text', numeric: false, disablePadding: false, label: 'Country Name' },
    { id: 'pspIntegrationMode', type: 'text', numeric: true, disablePadding: false, label: 'Mode' },
    { id: 'status', type: 'text', numeric: true, disablePadding: false, label: 'Status' },
    { id: 'createdOn', type: 'createdOn', numeric: false, disablePadding: false, label: 'Created On' },
    { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
  ];
  
  const filterCells = [
    { id: 'isoCountryCodeNum', type: 'select', label: 'Country' },
    { id: 'pspId', type: 'select', label: 'Select PSP' },
    // { id: 'acquirerObjId', type: 'text', label: 'Acquirer' },
    { id: 'pspIntegrationMode', type: 'text', label: 'PSP Integration Mode' },
    { id: 'status', type: 'select', label: 'Status' },
  ];
  
  const filterobj = {
    pageNo: 1,
    pageSize: 10,
    sortBy:"createdOn",
    sortDirection:"DESC",
    status: "",
    pspId: "",
    isoCountryCodeNum: "",
    acquirerObjId: "",
    pspIntegrationMode: "",
  }
  
  const sortArr = [
    {key:"createdOn", label: "Create Date"},
    {key:"countryId", label: "Country Id"},
    {key:"pspObjId", label: "PSP Id"},
    {key:"acquirerObjId", label: "Acquirer Id"},
    {key:"pspIntegrationMode", label: "PSP Integration Mode"},
    {key:"status", label: "Status"},
  ]
  
  const pspAcquirerObj = {
    "id": null,
    "countryId": "",
    "countryName": "",
    "pspObjId": "",
    "pspName": "",
    "acquirerObjId": "",
    "acquirerName": "",
    "pspIntegrationMode": "BOTH",
    "status": "ACTIVE",
  }

const PSPAcquirer = (props) => {
    const {
        pspAcquirerList, totalRecords, pspAcquirerLoader, createPSPAcquirerRes, countryDetails, acquirerList, pspList,
        pspIntegrationModeList,
    } = props;

    const {classes} = useStyles();
    const [currentForm, setCurrentForm] = useState("list");
    const [pageNo, setPage] = useState(1);
    const [sortDir, setsortDir] = useState('DESC');
    const [filterData, setFilterData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [sortName, setsortName] = useState('createdOn');
    const [filterState, setFilterState] = useState(filterobj);
    const [snackbarMsg, setSnackBarMsg] = useState(null);
    const [openPopover, setopenPopover] = useState(false);
    const [openSnackbar, SetOpenSnackbar] = useState(false);
    const [compInitaited, setCompInitiated] = useState(false);
    const [clientRow, setClientRow] = useState(null);
    const [pspAcquirerState, setPSPAcquirerState] = useState(pspAcquirerObj);
    
    const currentPage = localStorage.getItem('currentPage')
    let permissions = localStorage.getItem("permissions")
    permissions = permissions && permissions !== null ? JSON.parse(permissions) : []

    const nextDisableToken = currentForm !== "view" && pspAcquirerState && pspAcquirerState.acquirerName
        && pspAcquirerState.countryName && pspAcquirerState.pspName

    let countryIdArr = []
    const countryArr = countryDetails && countryDetails.map(country => {
        if(country.id && country.id !== "") {
            countryIdArr.push(country.id);
        }
        return country.name ? country.name : null
    }).filter(val => val !== null)

    let pspIdArr = []
    const pspArr = pspList && pspList.map(psp => {
        if(psp.id && psp.id !== "") {
            pspIdArr.push(psp.id);
        }
        return psp.name ? psp.name : null
    }).filter(val => val !== null)

    let acqIdArr = []
    const acqArr = acquirerList && acquirerList.map(acquirer => {
        if(acquirer.id && acquirer.id !== "") {
            acqIdArr.push(acquirer.id);
        }
        return acquirer.name ? acquirer.name : null
    }).filter(val => val !== null)

    const pspAcquirerArr = pspAcquirerList && pspAcquirerList !== null ?
        pspAcquirerList.map(obj => {

            let pspAcqObj = {
                ...obj,
                createdOn:  obj.createdOn && `${obj.createdOn.slice(0,10)}`, 
                countryName: countryIdArr.indexOf(obj.countryId) !== -1 ? countryDetails[countryIdArr.indexOf(obj.countryId)]['name'] : "",
                pspName: pspIdArr.indexOf(obj.pspObjId) !== -1 ? pspList[pspIdArr.indexOf(obj.pspObjId)]['name'] : "",
                acquirerName: acqIdArr.indexOf(obj.acquirerObjId) !== -1 ? acquirerList[acqIdArr.indexOf(obj.acquirerObjId)]['name'] : "",
            }
            return pspAcqObj
        }) : []

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    });
        
    useEffect(() => {
        const handleInitialCall = async () => {
        await setCompInitiated(true);
        await countryDetails && countryDetails.length === 0 && props.fetchCountryDetails({})
        await props.fetchPSPMasterData({})
        await props.fetchAcqMasterData({})
        await props.fetchPSPAcquirerDetails({...filterState,
            pageNo: pageNo, sortBy: sortName, sortDirection: sortDir
        })
        await localStorage.removeItem('currentPage')
        }
        handleInitialCall();
        
        return () => {
        //add all list reset API here
        
        //reset acquirer response
        props.resetCreatePSPAcquirerMapping()
        }
    }, [])
    
    useEffect(() => {
        if(createPSPAcquirerRes && createPSPAcquirerRes !== null && createPSPAcquirerRes.st) {
            if(currentForm === "create" ) {
            setopenPopover(true)
            } else {
            SetOpenSnackbar(true)
            setSnackBarMsg(`PSP Acquirer Mapping Modified Successfully!`)
            onChangeView("list")
            }
            // reset terminal create response
            props.resetCreatePSPAcquirerMapping()

        } else if(createPSPAcquirerRes && createPSPAcquirerRes !== null && !createPSPAcquirerRes.st) {
            SetOpenSnackbar(true)
            setSnackBarMsg(createPSPAcquirerRes.msg && createPSPAcquirerRes.msg)
        }
    }, [createPSPAcquirerRes])
    
    const handleSnackBarAction = () => {
        SetOpenSnackbar(false)
        setSnackBarMsg(null)

        // reset terminal create response
        props.resetCreatePSPAcquirerMapping()
    }
    
    const handleInputChange = (key, value) => {

        let updatedPSPAcqState = {...pspAcquirerState};
        if(key === "country") {
            updatedPSPAcqState = {
                ...updatedPSPAcqState,
                countryName: value,
                countryId: countryDetails[countryArr.indexOf(value)]['id'],
            }

            props.fetchPSPMasterData({countryId: countryDetails[countryArr.indexOf(value)]['id']})
            props.fetchAcqMasterData({countryId: countryDetails[countryArr.indexOf(value)]['id']})
        } else if(key === "pspName") {
            updatedPSPAcqState = {
                ...updatedPSPAcqState,
                pspName: value,
                pspObjId: pspList[pspArr.indexOf(value)]['id'],
            }

        } else if(key === "acquirerName") {
            updatedPSPAcqState = {
                ...updatedPSPAcqState,
                acquirerName: value,
                acquirerObjId: acquirerList[acqArr.indexOf(value)]['id'],
            }

        } else {
            updatedPSPAcqState = {
                ...updatedPSPAcqState,
                [key]: value,
            }
        }

        setPSPAcquirerState(updatedPSPAcqState)
    }
    
    const onChangeView = (inpval, row) => {
        setCurrentForm(inpval);
        if(inpval === "view") {
            setClientRow(row)
        } else if(inpval === "edit") {
            setPSPAcquirerState(row)
            props.fetchPspIntegrationModeMasterData();

        } else if(inpval === "create") {
            setPSPAcquirerState(pspAcquirerObj)
            props.fetchPspIntegrationModeMasterData();

        } else {
            countryDetails && countryDetails.length === 0 && props.fetchCountryDetails({})
            props.fetchPSPMasterData({})
            props.fetchAcqMasterData({})
            props.fetchPSPAcquirerDetails({...filterState, pageNo: 1, sortBy: sortName, sortDirection: sortDir})
        }
    }
    
    useEffect(() => {
        if(currentPage && currentPage !== null && currentPage !== undefined && currentPage === "/productype" && compInitaited){
            setCurrentForm('list')
            setFilterState({...filterobj});
            setPage(1);
            setsortDir("DESC");
            setsortName("createdOn")
            props.fetchProdTypeDetails({...filterState, pageNo: 1, sortBy: sortName, sortDirection: sortDir})
            localStorage.removeItem('currentPage')
        }
    }, [currentPage])
    
    const handleCreateUpdatePSPAcqMapping = () => {
        let req = {...pspAcquirerState }

        if(req.id !== null && req.id !== "") {
            props.updatePSPAcquirerMapping(req)
        } else {
            props.createPSPAcquirerMapping(req)
        }
    }

    const handleDialogAction = async (type) => {
        setopenPopover(false)
        setPSPAcquirerState(pspAcquirerObj)
        if(type === "exit" ){
            onChangeView('list')
        } else {
            await onChangeView('list')
            await onChangeView('create')
        }
    }

    const handleSetFilterData = (filterArray, searchValue) => {
        setFilterData(filterArray)
        setSearchValue(searchValue)
    }
    
    const handleFilterStateChange = (key, value) => {
        const tempFilter = {
            ...filterState,
            [key]: value
        }
        setFilterState(tempFilter);
    }

    const resetFilter = () => {
        setFilterState(filterobj);
        setPage(1);
        setsortDir("DESC");
        setsortName("createdOn")
        props.fetchPSPAcquirerDetails({pageNo: 1, pageSize: 10, sortBy:"createdOn", sortDirection:"DESC"})
    }

    const FilterApply = () => {
        setPage(1)
        props.fetchPSPAcquirerDetails(
            {...filterState, pageNo: 1, sortBy: sortName, sortDirection: sortDir}
        );
        setFilterState({...filterState});
    }

    const handlePageNo = (val) => {
        setPage(val)
        props.fetchPSPAcquirerDetails(
            {...filterState, pageNo: val, sortBy: sortName, sortDirection: sortDir}
        );
    }

    const handleSortDir = (value) => {
        setsortDir(value)
        setPage(1)
    }

    const handleSortName = (value) => {
        setsortName(value)
        setPage(1)
    }

    return (
        <>
            <BasePageToolbar
                currentForm={currentForm}  create={permissions.includes("62")} onChangeView={onChangeView} resetFilter={resetFilter}
                title={currentForm === 'list' ? "PSP Acquirer Mapping" : currentForm === 'view' ? "View PSP Acquirer Mapping" : currentForm === 'edit' ? "Modify PSP Acquirer Mapping" : "Create PSP Acquirer Mapping"}
                handleFilterStateChange={handleFilterStateChange} filterState={filterState} filterCells={filterCells} FilterApply={FilterApply}
                setsortName={handleSortName} setsortDir={handleSortDir} sortDir={sortDir} sortName={sortName} sortArr={sortArr}
                arrayToFilter={pspAcquirerArr} isTableSearchReq={false} handleSetFilterData={handleSetFilterData} searchValue={searchValue}
            ></BasePageToolbar>
            <BasePageContainer>
            </BasePageContainer>
            {currentForm === 'list' ?
            <Grid container className={classes.container}>
                <TableComponent 
                showEdit={permissions.includes("63")}
                totalRecord={totalRecords} 
                loading={pspAcquirerLoader} 
                onChangeView={onChangeView} 
                handlePageNo={handlePageNo}
                sortOrder="desc" sortOrderBy="createdOn" headCells={headCells}
                rows={searchValue !== "" ? filterData : pspAcquirerArr} 
                activeTab={filterState}
                />
            </Grid>
            : currentForm === 'create' ?
                <Grid container className={classes.formContainer}>
                <CreateEditViewMapping 
                    currentForm={currentForm}
                    pspAcquirerState={pspAcquirerState}
                    nextDisableToken={!nextDisableToken}
                    handleInputChange={handleInputChange}
                    handleCreateUpdatePSPAcqMapping={handleCreateUpdatePSPAcqMapping}
                    countryArr={countryArr}
                    pspArr={pspArr}
                    acqArr={acqArr}
                    pspIntegrationModeList={pspIntegrationModeList}
                />
                </Grid>
                : currentForm === 'edit' ? <Grid container className={classes.formContainer}>
                    <CreateEditViewMapping
                    currentForm={currentForm}
                    pspAcquirerState={pspAcquirerState}
                    nextDisableToken={false}
                    handleInputChange={handleInputChange}
                    handleCreateUpdatePSPAcqMapping={handleCreateUpdatePSPAcqMapping}
                    countryArr={countryArr}
                    pspArr={pspArr}
                    acqArr={acqArr}
                    pspIntegrationModeList={pspIntegrationModeList}
                    />
                </Grid>
                : <Grid container className={classes.formContainer}>
                    <CreateEditViewMapping
                        currentForm={currentForm} 
                        acquirerDetails={clientRow}
                        onChangeView={onChangeView}
                    />
                </Grid>
            }

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={() => handleSnackBarAction()}
                action={
                <React.Fragment>
                <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
                    <CloseIcon fontSize="small" />
                </IconButton>
                </React.Fragment>
                }
            >
                <Alert onClose={() => handleSnackBarAction()} severity={
                createPSPAcquirerRes && createPSPAcquirerRes !== null && !createPSPAcquirerRes.st ? "info" : "success"
                }>
                {snackbarMsg}
                </Alert>
            </Snackbar>
            <Dialog
                open={openPopover}
                onClose={() => handleDialogAction("exit")}
                className={"delete_dialog"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                <h2>{`PSP Acquirer Mapping Created Successfully!`}</h2>
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`Do you want to Create new Mapping?`}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button className={classes.color}
                    onClick={() => handleDialogAction("create")} 
                >
                    Create New
                </Button>
                <Button className={classes.color}
                    onClick={() => handleDialogAction("exit")} 
                >
                    Cancel
                </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const useStyles = tss.create({
    formContainer: {
        paddingTop: theme.spacing(0),
    },
    container: {
        paddingTop: theme.spacing(2),
    },
    color: {
        color: "white",
        backgroundColor: `#1BA785 !important`
    },
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            fetchCountryDetails, fetchPSPAcquirerDetails, 
            createPSPAcquirerMapping, updatePSPAcquirerMapping,
            resetCreatePSPAcquirerMapping, fetchPSPMasterData, 
            fetchAcqMasterData, fetchPspIntegrationModeMasterData,
        }, dispatch)
    }
}

function mapStateToProps(state) {
    return {
        pspAcquirerList:  state.PSPAcquirerReducer.pspAcquirerList !== null &&
            state.PSPAcquirerReducer.pspAcquirerList.pspAcquirerList
                ? state.PSPAcquirerReducer.pspAcquirerList.pspAcquirerList : [],
        totalRecords: state.PSPAcquirerReducer.pspAcquirerList !== null &&
            state.PSPAcquirerReducer.pspAcquirerList.totalRecords
                ? state.PSPAcquirerReducer.pspAcquirerList.totalRecords : 0,
        createPSPAcquirerRes: state.PSPAcquirerReducer.createPSPAcquirerRes,
        pspAcquirerLoader: state.PSPAcquirerReducer.pspAcquirerLoader,
        countryDetails: state.SchemeReducer.countryDetails !== null &&
            state.SchemeReducer.countryDetails.countryList ?
                state.SchemeReducer.countryDetails.countryList : [],
        acquirerList: state.PSPAcquirerReducer.acqMasterData !== null &&
            state.PSPAcquirerReducer.acqMasterData.acquirerList ?
                state.PSPAcquirerReducer.acqMasterData.acquirerList : [],
        pspList: state.PSPAcquirerReducer.pspMasterData !== null && 
            state.PSPAcquirerReducer.pspMasterData.pspList ?
                state.PSPAcquirerReducer.pspMasterData.pspList : [],
        pspIntegrationModeList:  state.PSPAcquirerReducer.pspIntegrationModeList !== null &&
            state.PSPAcquirerReducer.pspIntegrationModeList.pspIntegrationModes
                ? state.PSPAcquirerReducer.pspIntegrationModeList.pspIntegrationModes : [],
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PSPAcquirer);

