import { createRequestActionTypes } from "../../shared/utility";

export const MciActions = {
    fetchMciList: createRequestActionTypes('FETCH_MCI_LIST'),
    createUpdateMci: createRequestActionTypes('CREATE_UPDATE_MCI'),
    deleteMci: createRequestActionTypes('DELETE_MCI'),
    fetchFileTypeId: createRequestActionTypes('FETCH_FILE_TYPE_ID'),
    fetchExcelFileTemplateId: createRequestActionTypes('FETCH_EXCEL_FILE_TEMPLATE_ID'),
    fetchMCIProgramTypes: createRequestActionTypes('FETCH_MCI_PROGRAM_TYPES'),
    fetchMCIPaymentModelTypes: createRequestActionTypes('FETCH_MCI_PAYMENT_MODEL_TYPES'),
    fetchMCIProductTypes: createRequestActionTypes('FETCH_MCI_PRODUCT_TYPES'),
}