import React, {useEffect, useState} from "react";
import moment from 'moment';
import {addDays, subDays } from "date-fns";
import { Calendar } from 'react-date-range';
import {
    Grid, TextField, Paper, MenuItem, Menu, InputAdornment, Typography,
    Accordion, AccordionDetails, AccordionSummary, Button,
    Radio, RadioGroup, FormControlLabel, FormControl,
} from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

import DeleteIcon from '../../../Assets/delete.svg';
import { HONG_KONG, EmailRegex, allZeroRegex, installmentDescRegex } from "../../../shared/utility";
import TermsAndConditions from "./termsAndConditions";

function SchemeTypeStepper(props) {
    const {
        classes, schemeEndDate, schemeStartDate,  handlestatUpdate, bankSchemeArr,
        handleInputChange, handleSelectChange, handleIssuerChange, handleCountryChange,
        countryDetails, issuerDetails, disableToken, installDesc, productType, paymentMode,
        country, issuer, geoScope, email, schemeType, schemeDetail, statusCode,
        handleDatePickerChange, handleSchemeChange, stepper1Details, currentForm, bankSchemeSelected,
        programTypeValue, subventionType, bankSelectedDate, bankSelectedStartDate, multiLingualTermsAndConditions,
        handleTncAdd, handleTncDelete, countryCodeNum, handleTnCChange
    } = props;
    const tempBankSelectedDate = bankSelectedDate && bankSelectedDate !== null ? 
        new Date((moment(bankSelectedDate)).format("DD-MMM-yyyy")) : null;
    const tempBankSelectedStartDate = bankSelectedStartDate && bankSelectedStartDate !== null ? 
        new Date((moment(bankSelectedStartDate)).format("DD-MMM-yyyy")) : null;

    const [emailVal, setEmailVal] = React.useState({flag: false, msg: null})
    const [issuerReq, setIssuerReq] = React.useState(false)
    const [payModeReq, setpayModeReq] = React.useState(false)
    const [geoScopeReq, setGeoScopeReq] = React.useState(false)
    const [prodTypeReq, setProdTypeReq] = React.useState(false)
    const [programTypeReq, setProgramTypeReq] = React.useState(false)
    const [subventionTypeReq, setSubventionTypeReq] = React.useState(false)

    let countryObj = []
    let issuerObj = []
    let payModelObj = []
    let countryName = null
    let issuerName = null
    let languagesArr = []
    const countryArray = countryDetails && countryDetails !== null ? 
        countryDetails.countryList.map(obj => {
            if(schemeDetail && schemeDetail['isoCountryCodeNum'] && obj.isoCountryCodeNum && currentForm === "view" && 
                obj.isoCountryCodeNum === schemeDetail['isoCountryCodeNum'] && countryName === null) {
                countryName = obj.name
            }
            if (countryCodeNum && obj.isoCountryCodeNum === countryCodeNum) {
                languagesArr = obj.languages
            }
            countryObj.push(obj)
            return obj.name
        }) : []
    const issuerArray = issuerDetails && issuerDetails !== null ? 
        issuerDetails.issuer.map(obj => {
            if(schemeDetail && schemeDetail['mciID'] && obj.mciId && currentForm === "view" && obj.mciId === schemeDetail['mciID'] && issuerName === null) {
                issuerName = obj.name
                obj.isPLManaged !== null && props.setIsPLManaged(obj.isPLManaged)
            }
            issuerObj.push(obj)
            return obj.name
        }) : []
    const payModelArray = stepper1Details && stepper1Details !== null ? 
        stepper1Details.paymentModel.map(obj => {
            payModelObj.push(obj)
            return obj.name
        }) : []
        
    const [startAnchorEl, setStartAnchorEl] = useState(null)
    const [endAnchorEl, setEndAnchorEl] = useState(null)
    const allowEdit = currentForm === "edit";
    const DisableFlag = disableToken ? disableToken : false

    // useEffect(() => {
    //     if(!DisableFlag && currentForm !== "view"){
    //         const tempdesc = document.getElementById("installmentDesc")
    //         tempdesc.value = null
    //     }
    // }, [installDesc])

    const handleDateMenuToggle = (event, type) => {
        //handler for date menu open and close
        if(type === "start") {
            startAnchorEl === null ? setStartAnchorEl(event.currentTarget) : setStartAnchorEl(null)
        } else {
            endAnchorEl === null ? setEndAnchorEl(event.currentTarget) : setEndAnchorEl(null)
        }
    }

    const handleDateSelect = (event, type) => {
        handleDateMenuToggle(event, type)
        if(type === "start") {
            handleDatePickerChange(event, "start")
        } else {
            handleDatePickerChange(event, "end")
        }
    }

    const handleCountry = (e) => {
        const countryObject = countryArray.indexOf(e.target.value) !== -1 ? countryObj[countryArray.indexOf(e.target.value)] : null
        if (countryObject) {
            handleCountryChange(countryObject)
        }
    }
    const handleIssuer = (e) => {
        handleIssuerChange(issuerObj[issuerArray.indexOf(e.target.value)])
    }

    const handlePayModel = (type, e) => {
        handleSelectChange(type, payModelObj[payModelArray.indexOf(e.target.value)])
    }

    const handleOnBlur = (type) => {
        if(type === "productType") {
            setProdTypeReq(productType === null)

        } else if(type === "paymentMode") {
            setpayModeReq(paymentMode === null)

        } else if(type === "issuer") {
            setIssuerReq(issuer === null)

        } else if ((type === "geoScope")){
            setGeoScopeReq(geoScope === null)

        } else if(type === "email") {
            const emailVReq = !DisableFlag && email && ((email === null) ||(email !== null && email.length === 0))
            const emailVal = !DisableFlag && email && email !== null && (email.length > 0 && !EmailRegex.test(email))
            setEmailVal({flag: emailVReq ? emailVReq : emailVal, msg: emailVReq ? "Email Required!" : "Invalid Email!"})
        
        } else if (type === "programType") {
            setProgramTypeReq(programTypeValue === null)

        } else if (type === "subventionType") {
            setSubventionTypeReq(subventionType === null)
        }
    }

    const handleChange = async (type, e) => {
        if(type === "productType") {
            setProdTypeReq(false)
            handleSelectChange('productType', e)
        } else if(type === "paymentMode") {
            setpayModeReq(false)
            handlePayModel('paymentMode', e)
        } else if (type === "issuer") {
            setIssuerReq(false)
            handleIssuer(e)
        } else if(type === "geoScope") {
            setGeoScopeReq(false)
            handleSelectChange("geoScope", e)
        } else if(type === "email") {
            const emailVReq = !DisableFlag && ((e.target.value === null) ||(e.target.value !== null && e.target.value.length === 0))
            const emailVal = !DisableFlag && e.target.value !== null && (e.target.value.length > 0 && !EmailRegex.test(e.target.value))
            setEmailVal({flag: emailVReq ? emailVReq : emailVal, msg: emailVReq ? "Email Required!" : "Invalid Email!"})
            handleInputChange(type, e)
        } else if(type === "programType") {
            setProgramTypeReq(false)
            await handleInputChange(type, e)
            if(subventionType !== null && e.target.value !== "CASHBACK ONLY") {
                await handleInputChange("subventionType", null)
            } else if(e.target.value === "CASHBACK ONLY" && subventionType !== "NONE") {
                await handleInputChange("subventionType", "NONE")
            }
        }  else if(type === "subventionType") {
            setSubventionTypeReq(false)
            handleInputChange(type, e.target.value)
        } else {
            handleInputChange(type, e.target.value)
        }
    }

    const instDescReq = !DisableFlag &&  installDesc !== null && (installDesc.length === 0)
    const instDescVal = !DisableFlag &&  installDesc !== null && !installmentDescRegex.test(installDesc)
    let payModeForView = null
    schemeDetail && schemeDetail['paymentModel'] && stepper1Details && stepper1Details !== null &&
      stepper1Details['paymentModel'].map(obj => {
        if(parseInt(obj.code) === parseInt(schemeDetail['paymentModel'])) {
            payModeForView = obj.name
        }
        return null;
      })

    return (
        <Paper className={classes.subRangePaper}>
            <Grid container rowSpacing={2} columnSpacing={2}>

                <Grid item xs={12} sm={4}>
                    <TextField 
                        InputLabelProps={{ shrink: true }} required
                        name="country" variant="outlined" select={currentForm === "create"}
                        fullWidth id="country" label="Country Code"
                        onChange={e => handleCountry(e)} 
                        disabled={allowEdit || DisableFlag}
                        value={!DisableFlag ? country : countryName && countryName} 
                    >
                        {(countryArray).map((val, index) => {
                            return <MenuItem key={index} id={val} value={val} className={classes.menu}>
                                {val}
                            </MenuItem>
                        })}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField 
                        InputLabelProps={{ shrink: true }} required
                        name="issuer" variant="outlined" select={currentForm === "create"} 
                        fullWidth id="issuer" label="Issuer Name"
                        onBlur={() => handleOnBlur("issuer")}
                        onChange={e => e.target.value !== "NA" && handleChange('issuer', e)} 
                        disabled={allowEdit || DisableFlag || country === null}
                        value={!DisableFlag ? issuer : schemeDetail && schemeDetail['issuerName']} 
                    > 
                        {issuerArray && issuerArray.length === 0 ?
                            <MenuItem key={10000} value={"NA"} className={classes.menu}>
                                {"No Option Available!"}
                            </MenuItem> : null}
                        {(issuerArray).map((val, index) => {
                            return <MenuItem key={index} value={val} className={classes.menu}>
                                {val}
                            </MenuItem>
                        })}
                    </TextField>
                    {issuerReq? 
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message"><InfoOutlinedIcon /> {"Required Field"}</span>
                        </p>
                        : null
                    }
                </Grid>
                {(schemeType !== "Bank" && currentForm === "create") ? <Grid item xs={12} sm={4}>
                    <TextField 
                        InputLabelProps={{ shrink: true }} required
                        fullWidth id="bankSchemeTemp" label="Bank Scheme Template"
                        name="bankSchemeTemp" variant="outlined" select={!DisableFlag || !allowEdit}
                        disabled={allowEdit || DisableFlag || issuer === null} value={bankSchemeSelected} 
                        onChange={e => e.target.value !== "NA" && handleSelectChange('bankSchemeTemplate', e)} 
                    >
                        {bankSchemeArr && bankSchemeArr.length === 0 ?
                            <MenuItem key={10001} value={"NA"} className={classes.menu}>
                                {"No Option Available!"}
                            </MenuItem> : null}
                        {bankSchemeArr && (bankSchemeArr).map((val, index) => {
                            return <MenuItem key={index} id={val} value={val} className={classes.menu}>
                                {val}
                            </MenuItem>
                        })}
                    </TextField>
                </Grid> : <Grid item xs={12} sm={4} /> }

                <Grid item xs={12} sm={4}>
                    <TextField 
                        name="schemeType" variant="outlined" select
                        fullWidth id="schemeType" label="Scheme Type" 
                        defaultValue={schemeType || "Brand"} disabled
                        onChange={e => handleSchemeChange(e.target.value)}
                    >
                        {["Brand", "Merchant", "Bank"].map((val, index) => {
                            return <MenuItem key={index} value={val} className={classes.menu}>
                                {val}
                            </MenuItem>
                        })}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4} className={"calender_input"}>
                    <TextField 
                        label="Start Date" name="schemeDate1" 
                        id={"schemeDate1"} variant="outlined" fullWidth required={true}
                        value={(moment(schemeStartDate)).format("DD-MMM-yyyy")} 
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end" >
                                <DateRangeIcon className={"calender_input"} />
                            </InputAdornment>
                            ),
                        }} 
                        onClick={(e) => (!DisableFlag || allowEdit) && 
                            (new Date().getTime() < new Date(schemeStartDate).getTime())
                            && handleDateMenuToggle(e, "start")}
                        disabled={!allowEdit ? (DisableFlag || issuer === null) : (issuer === null ||false)}
                    />
                        <Menu
                            id={"simple"} anchorEl={startAnchorEl}
                            keepMounted open={Boolean(startAnchorEl)}
                            onClose={() => setStartAnchorEl(null)}
                            elevation={1}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
                            classes={{ paper: classes.profileMenu, }}
                        >
                            {tempBankSelectedDate !== null && tempBankSelectedStartDate !== null ?
                                <Calendar color="#1BA785" rangeColors={['#1BA785']}
                                    onChange={(e) => handleDateSelect(e, "start")}
                                    minDate={(new Date().getTime() >= new Date(tempBankSelectedStartDate).getTime()) ?
                                        addDays(new Date(), 1) : tempBankSelectedStartDate}
                                    maxDate={subDays(tempBankSelectedDate, 1)}
                                />
                                :
                                <Calendar color="#1BA785" rangeColors={['#1BA785']}
                                    onChange={(e) => handleDateSelect(e, "start")}
                                    minDate={addDays(new Date(), 1)}
                                />
                            }
                        </Menu>
                </Grid>
                <Grid item xs={12} sm={4} className={"calender_input"}>
                    <TextField 
                        label="End Date" name="schemeDate2" 
                        id={"schemeDate2"} variant="outlined" fullWidth required={true}
                        value={(moment(schemeEndDate)).format("DD-MMM-yyyy")} 
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end" >
                                <DateRangeIcon className={"calender_input"} />
                            </InputAdornment>
                            ),
                        }} 
                        onClick={(e) => (!DisableFlag || allowEdit) && handleDateMenuToggle(e, "end")}
                        disabled={!allowEdit ? (DisableFlag || issuer === null) : (issuer === null ||false)}
                    />
                        <Menu
                            id={"simple"} anchorEl={endAnchorEl}
                            keepMounted open={Boolean(endAnchorEl)}
                            onClose={() => setEndAnchorEl(null)}
                            elevation={1}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
                            classes={{ paper: classes.profileMenu, }}
                        >
                            {tempBankSelectedDate !== null && tempBankSelectedStartDate !== null?
                                <Calendar color="#1BA785" rangeColors={['#1BA785']}
                                    onChange={(e) => handleDateSelect(e, "end")}
                                    minDate={(new Date().getTime() >= new Date(tempBankSelectedStartDate).getTime()) ?
                                        addDays(schemeStartDate, 1) : tempBankSelectedStartDate}
                                    maxDate={tempBankSelectedDate}
                                />
                                :
                                <Calendar color="#1BA785" rangeColors={['#1BA785']}
                                    onChange={(e) => handleDateSelect(e, "end")}
                                    minDate={addDays(schemeStartDate, 1)}
                                />
                            }
                        </Menu>
                </Grid>
                
                <Grid item xs={12}>
                    <TextField 
                        InputLabelProps={{ shrink: true }} inputProps={{maxLength: 255}}
                        name="installmentDesc" variant="outlined" required
                        fullWidth id="installmentDesc" label={"Installment Description"}
                        value={!DisableFlag ? installDesc !== null ? installDesc : null : schemeDetail && schemeDetail['installmentConfigDesc']}
                        disabled={!allowEdit ? (DisableFlag || issuer === null) : (issuer === null ||false)}
                        onChange={e => handleInputChange('intDesc', e)}
                    />
                    {instDescReq? 
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message"><InfoOutlinedIcon /> {"Required Field"}</span>
                        </p>
                        : instDescVal ?
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! Installment Config Description can only contain Alphabets, Numbers, Whitespace, and () [] - _"}</span>
                            </p>
                            : installDesc !== null && allZeroRegex.test(((installDesc).split(" ")).join("")) ?
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! All Zero Entry Not Allowed."}</span>
                                </p>
                                : null
                    }
                </Grid>
                
                <Grid item xs={12} sm={4}>
                    <TextField 
                        InputLabelProps={{ shrink: true }} 
                        name="productType" variant="outlined" select={!DisableFlag || allowEdit} required
                        fullWidth id="productType" label="Product Type" 
                        disabled={!allowEdit ? (DisableFlag || issuer === null) : (issuer === null ||false)}
                        value={!DisableFlag ? productType : schemeDetail && schemeDetail['productType']} 
                        onBlur={() => handleOnBlur("productType")}
                        onChange={e => handleChange('productType', e)}
                    >
                        {( 
                            ["CREDIT"]).map((val, index) => {
                            return <MenuItem key={index} value={val} className={classes.menu}>
                                {val}
                            </MenuItem>
                        })}
                    </TextField>
                    {prodTypeReq? 
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message"><InfoOutlinedIcon /> {"Required Field"}</span>
                        </p>
                        : null
                    }
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField 
                        InputLabelProps={{ shrink: true }} 
                        name="paymentMode" variant="outlined" select={!DisableFlag || allowEdit} required
                        fullWidth id="paymentMode" label="Payment Mode"
                        onBlur={() => handleOnBlur("paymentMode")}
                        onChange={e => handleChange('paymentMode', e)} 
                        disabled={!allowEdit ? (DisableFlag || issuer === null) : (issuer === null ||false)}
                        value={!DisableFlag ? paymentMode : payModeForView} 
                    >
                        {(payModelArray).map((val, index) => {
                            return <MenuItem key={index} value={val} className={classes.menu}>
                                {val}
                            </MenuItem>
                        })}
                    </TextField>
                    {payModeReq? 
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message"><InfoOutlinedIcon /> {"Required Field"}</span>
                        </p>
                        : null
                    }
                </Grid>
                {currentForm !== "view" &&<Grid item xs={12} sm={4}>
                    <TextField 
                        InputLabelProps={{ shrink: true }} 
                        name="statuscode" variant="outlined" select required
                        fullWidth id="statuscode" label="Status Code"
                        onChange={e => handlestatUpdate(e)} disabled                     
                        value={statusCode} 
                    >
                        {["CREATE", "MODIFY", "VIEW"].map((val, index) => {
                            return <MenuItem key={index} value={val} className={classes.menu}>
                                {val}
                            </MenuItem>
                        })}
                    </TextField>
                </Grid>}

                <Grid item xs={12} sm={4}>
                    <TextField 
                        InputLabelProps={{ shrink: true }} 
                        name="geoScope" variant="outlined" select={!DisableFlag || allowEdit} required
                        fullWidth id="geoScope" label="Geo Scope" 
                        disabled={!allowEdit ? (DisableFlag || issuer === null) : (issuer === null ||false)}
                        onBlur={() => handleOnBlur("geoScope")}
                        onChange={e => handleChange('geoScope', e)}
                        value={!DisableFlag ? geoScope : schemeDetail && schemeDetail['geoScope']} 
                    >
                        {(stepper1Details && stepper1Details !== null ? stepper1Details.geoScope : 
                            []).map((val, index) => {
                            return <MenuItem key={index} value={val} className={classes.menu}>
                                {val}
                            </MenuItem>
                        })}
                    </TextField>
                    {geoScopeReq? 
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message"><InfoOutlinedIcon /> {"Required Field"}</span>
                        </p>
                        : null
                    }
                </Grid>

                {schemeType !== "Bank" && <Grid item xs={12} sm={4}>
                    <TextField 
                        InputLabelProps={{ shrink: true }} 
                        name="programType" variant="outlined" 
                        select={!DisableFlag || allowEdit} required
                        fullWidth id="programType" label="Program Type"
                        onBlur={() => handleOnBlur("programType")}
                        onChange={e => handleChange('programType', e)} 
                        disabled={!allowEdit ? (DisableFlag || issuer === null) : (issuer === null ||false)}
                        value={!DisableFlag ? programTypeValue : programTypeValue} 
                    >
                        {(schemeType === "Brand" ? ["BRAND", "BRAND MERCHANT COFUNDED", "CASHBACK ONLY"] : ['MERCHANT'])
                            .map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                            })
                        }
                    </TextField>
                    {programTypeReq? 
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message"><InfoOutlinedIcon /> {"Required Field"}</span>
                        </p>
                        : null
                    }
                </Grid>}
                {schemeType !== "Bank" && <Grid item xs={12} sm={4}>
                    <TextField 
                        InputLabelProps={{ shrink: true }} 
                        name="subventionType" variant="outlined" 
                        select={!DisableFlag || allowEdit} required
                        onBlur={() => handleOnBlur("subventionType")}
                        fullWidth id="subventionType" label="Subvention Type"
                        onChange={e => handleChange('subventionType', e)} 
                        disabled={programTypeValue === null || programTypeValue === "CASHBACK ONLY" || 
                            (!allowEdit ? (DisableFlag || issuer === null) : (issuer === null || false))
                        }
                        value={!DisableFlag ? subventionType : subventionType} 
                    >
                        {(programTypeValue === "CASHBACK ONLY" ? ["PARTIAL", "FULL", "NONE"] : ["PARTIAL", "FULL"])
                            .map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                            })
                        }
                    </TextField>
                    {subventionTypeReq? 
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message"><InfoOutlinedIcon /> {"Required Field"}</span>
                        </p>
                        : null
                    }
                </Grid>}
                
                <div className={"paper_scroll"} style={{ padding: 20 }}>
                    <Grid container rowSpacing={4} columnSpacing={4}>
                        <Grid item xs={12} sm={12}>
                            <FormControl>
                                <Typography className={classes.heading}>Please choose any terms and conditions display type</Typography>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={multiLingualTermsAndConditions.type}
                                    onChange={e => handleTnCChange('tncType', e.target.value)}
                                >
                                    <FormControlLabel value="TEXT" control={<Radio />} disabled={currentForm === 'view' || issuer === null} label="TEXT" />
                                    {((currentForm !== "view" && countryCodeNum === HONG_KONG) || (currentForm === "view" && schemeDetail['isoCountryCodeNum'] === HONG_KONG)) &&
                                        <FormControlLabel value="QR" control={<Radio />} disabled={currentForm === 'view' || issuer === null} label="QR" />
                                    }
                                    <FormControlLabel value="NONE" control={<Radio />} disabled={currentForm === 'view' || issuer === null} label="NONE" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <React.Fragment>
                            {multiLingualTermsAndConditions && multiLingualTermsAndConditions.data && multiLingualTermsAndConditions.data.length > 0 &&
                                multiLingualTermsAndConditions.data.map((obj, index) => {
                                    return (
                                        <Grid item xs={12} sm={12} key={0}>
                                            <Paper className={classes.subPaper} elevation={1}>
                                                <Grid container >
                                                    <Accordion
                                                        expanded={true}
                                                        className={classes.subAccordion} id={"panel" + 1}
                                                    >
                                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                                            <Typography className={classes.heading}>Terms and Conditions</Typography>
                                                            {!DisableFlag && index !== 0 &&
                                                                <div className="deleteAccordIcon"
                                                                    onClick={() => handleTncDelete(index)}
                                                                >
                                                                    <img alt="" src={DeleteIcon} />
                                                                </div>
                                                            }
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <TermsAndConditions
                                                                tncType={multiLingualTermsAndConditions.type}
                                                                tncObjData={obj}
                                                                index={index}
                                                                currentForm={currentForm}
                                                                languagesArr={languagesArr}
                                                                issuer={issuer}
                                                                handleTnCChange={handleTnCChange}
                                                            />
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Grid>
                                                {!DisableFlag && multiLingualTermsAndConditions.data.length < 2 &&
                                                    ((currentForm !== "view" && countryCodeNum === HONG_KONG) || (currentForm === "view" && schemeDetail['isoCountryCodeNum'] === HONG_KONG)) &&
                                                    <Grid container justifyContent="flex-end" className={"last_grid_row"}>
                                                        <Grid item xs={12}>
                                                            <Button className="btn_addForm"
                                                                onClick={() => handleTncAdd()}
                                                            >
                                                                <AddCircleOutlineRoundedIcon className={classes.iconCircleOutline} />   Add terms and conditions in another language
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Paper>
                                        </Grid>
                                    )
                                })
                            }
                        </React.Fragment>
                    </Grid>
                </div>
            </Grid>
        </Paper>
    )
}

export default SchemeTypeStepper;