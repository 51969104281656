import { createRequestActionTypes } from "../../shared/utility";

export const AuthenticationActions = {
    handleUserLogin: createRequestActionTypes('HANDLE_USER_AUTHENTICATION'),
    handleRefreshToken: createRequestActionTypes('HANDLE_REFRESH_TOKEN_FETCH'),
    handleValidateUser: createRequestActionTypes('HANDLE_VALIDATE_USER'),
    fetchCaptchaForLogin: createRequestActionTypes("FETCH_CAPTCHA_FOR_LOGIN"),
    handleFetchUserNameById: createRequestActionTypes("FETCH_USER_NAME_BY_ID"),
    handleValForgotPassToken: createRequestActionTypes("VALIDATE_FOR_PASS_TOKEN"),
    handleResetPasswordForForgotPassword: createRequestActionTypes('HANDLE_RESET_PASSOWRD_FOR_FORGOT_PASSWORD'),
    unblockUserRequest: createRequestActionTypes("UNBLOCK_USER_REQUEST")
}