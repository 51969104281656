import { createRequestActionTypes } from "../../shared/utility";

export const ProgramActions = {
    handleProgramDetailsFetchForProgram: createRequestActionTypes('HANDLE_PROGRAM_DETAILS_FETCH_FOR_PROGRAM'),
    handleIndividualProgramDetailsFetchForProgram: createRequestActionTypes('HANDLE_INDIVIDUAL_PROGRAM_DETAILS_FETCH_FOR_PROGRAM'),
    handleCountryChangeForProgram: createRequestActionTypes('HANDLE_COUNTRY_CHANGE_FOR_PROGRAM'),
    handleProgramTypeChangeForProgram: createRequestActionTypes('HANDLE_PROGRAM_TYPE_CHANGE_FOR_PROGRAM'),
    handleStartDateChangeForProgram: createRequestActionTypes('HANDLE_START_DATE_CHANGE_FOR_PROGRAM'),
    handleEndDateChangeForProgram: createRequestActionTypes('HANDLE_END_DATE_CHANGE_FOR_PROGRAM'),
    handleProgramNameChangeForProgram: createRequestActionTypes('HANDLE_PROGRAM_NAME_CHANGE_FOR_PROGRAM'),
    handleDescriptionChangeForProgram: createRequestActionTypes('HANDLE_DESCRIPTION_CHANGE_FOR_PROGRAM'),
    handleBrandChangeForProgram: createRequestActionTypes('HANDLE_BRAND_CHANGE_FOR_PROGRAM'),
    handleIsDefaultChangeForProgram: createRequestActionTypes('HANDLE_IS_DEFAULT_CHANGE_FOR_PROGRAM'),
    handleShowCrossBorderChangeForProgram: createRequestActionTypes('HANDLE_SHOW_CROSS_BORDER_CHANGE_FOR_PROGRAM'),
    handleSchemesChangeForProgram: createRequestActionTypes('HANDLE_SCHEME_CHANGE_FOR_PROGRAM'),
    handleCountryDetailsFetchForProgram: createRequestActionTypes('HANDLE_COUNTRY_DETAILS_FETCH_FOR_PROGRAM'),
    handlePtogramTypeDetailsFetchForProgram: createRequestActionTypes('HANDLE_PROGRAM_TYPE_DETAILS_FETCH_FOR_PROGRAM'),
    handleSchemeDetailsFetchForProgram: createRequestActionTypes('HANDLE_SCHEME_DETAILS_FETCH_FOR_PROGRAM'),
    handleBankSchemeDetailsFetchForProgram: createRequestActionTypes('HANDLE_BANK_SCHEME_DETAILS_FETCH_FOR_PROGRAM'),
    handleBrandDetailsFetchForProgram: createRequestActionTypes('HANDLE_BRAND_DETAILS_FETCH_FOR_PROGRAM'),
    handleIssuerCountryDetailsFetchForProgram: createRequestActionTypes('HANDLE_ISSUER_COUNTRY_DETAILS_FETCH_FOR_PROGRAM'),
    handleCreateOrEditProgramSubmit: createRequestActionTypes('HANDLE_CREATE_OR_EDIT_PROGRAM_SUBMIT'),
}