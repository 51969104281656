import React, {useEffect} from 'react';
import { bindActionCreators } from "redux";
import {connect} from "react-redux";
import {reduxForm} from 'redux-form';
import { Grid, TextField, Button, Paper, MenuItem
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../../../_theme'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {addRegex, nameRegExp} from "../../../shared/utility";

const useStyles = tss.create({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    border: "solid 1px #EEEEEE",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(5),
  },
  input: {
    display: 'none',
  },
  menu: {
    fontSize: "15px"
  },
});

function CreateEditViewMapping(props) {
  const {
    acquirerDetails, handleInputChange, pspAcquirerState, currentForm, handleCreateUpdatePSPAcqMapping, nextDisableToken, onChangeView,
    countryArr, pspArr, acqArr, pspIntegrationModeList, countryDetails
  } = props;
  
  const [valObj, setValObj] = React.useState({
    countryErr: false, nameErr: false,
  })
  
  const {classes} = useStyles();
  
  // const countryArr = countryDetails && countryDetails.map(country => country.name ? (country.name).toUpperCase() : null).filter(val => val !== null)

  const handleOnBlur = (field, value) => {
    if(value === null || value === ""){
      setValObj({...valObj, [field]: true })
    } else {
      setValObj({...valObj, [field]: false })
    }
  }

  const handleChange = async (type, value) => {
    
    if(type === "country"){
      countryArr.indexOf(value) !== -1 && handleInputChange(type, value)
      setValObj({...valObj, countryErr: false })
    } else if(type === "pspName"){
      pspArr.indexOf(value) !== -1 && handleInputChange(type, value)
      setValObj({...valObj, pspNameErr: false })
    } else if(type === "acquirerName"){
      acqArr.indexOf(value) !== -1 && handleInputChange(type, value)
      setValObj({...valObj, acquirerNameErr: false })
    }
  }

  return (
    <div className={classes.root}>
        <Paper className={classes.paper}>
            <form className="action_form merchant_form" noValidate autoComplete="off">
                <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            InputLabelProps={{ shrink: true }} 
                            name="country" variant="outlined" select={currentForm === 'create'} required
                            fullWidth id="country" label="Country" 
                            disabled={currentForm !== 'create'}
                            value={currentForm === 'view' ? 
                                acquirerDetails !== null && acquirerDetails.countryName && 
                                acquirerDetails.countryName : pspAcquirerState.countryName ? 
                                pspAcquirerState.countryName : null
                            } 
                            onBlur={(e) => handleOnBlur("countryErr", e.target.value)}
                            onChange={(e) => countryArr.length > 0 && handleChange('country', e.target.value)}
                        >
                            {countryArr && countryArr.length === 0 && 
                            <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                            {countryArr && countryArr.map((val, index) => {
                            return <MenuItem key={index} value={val} className={classes.menu}>
                                {val}
                            </MenuItem>
                            })}
                        </TextField>
                        {valObj.countryErr ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                            </p>
                        :null}
                    </Grid>
                
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            InputLabelProps={{ shrink: true }} 
                            name="pspName" variant="outlined" select={currentForm === 'create'} required
                            fullWidth id="pspName" label="PSP" 
                            disabled={currentForm !== 'create'}
                            value={currentForm === 'view' ? 
                                acquirerDetails !== null && acquirerDetails.pspName && 
                                acquirerDetails.pspName : pspAcquirerState.pspName ? 
                                pspAcquirerState.pspName : null
                            } 
                            onBlur={(e) => handleOnBlur("pspNameErr", e.target.value)}
                            onChange={(e) => countryArr.length > 0 && handleChange('pspName', e.target.value)}
                        >
                            {pspArr && pspArr.length === 0 && 
                            <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                            {pspArr && pspArr.map((val, index) => {
                            return <MenuItem key={index} value={val} className={classes.menu}>
                                {val}
                            </MenuItem>
                            })}
                        </TextField>
                        {valObj.pspNameErr ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                            </p>
                        :null}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField 
                            InputLabelProps={{ shrink: true }} 
                            name="acquirerName" variant="outlined" select={currentForm === 'create'} required
                            fullWidth id="acquirerName" label="Acquirer" 
                            disabled={currentForm !== 'create'}
                            value={currentForm === 'view' ? 
                                acquirerDetails !== null && acquirerDetails.acquirerName && 
                                acquirerDetails.acquirerName : pspAcquirerState.acquirerName ? 
                                pspAcquirerState.acquirerName : null
                            } 
                            onBlur={(e) => handleOnBlur("acquirerNameErr", e.target.value)}
                            onChange={(e) => countryArr.length > 0 && handleChange('acquirerName', e.target.value)}
                        >
                            {acqArr && acqArr.length === 0 && 
                            <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                            {acqArr && acqArr.map((val, index) => {
                            return <MenuItem key={index} value={val} className={classes.menu}>
                                {val}
                            </MenuItem>
                            })}
                        </TextField>
                        {valObj.acquirerNameErr ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                            </p>
                        :null}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField 
                        InputLabelProps={{ shrink: true }} 
                        name="status" variant="outlined" select={currentForm !== 'view'}
                        fullWidth id="status" label="Acquirer status"
                        disabled={currentForm === 'view'}
                        value={currentForm === 'view' ?
                            acquirerDetails !== null && acquirerDetails.status && 
                            acquirerDetails.status : pspAcquirerState.status ? 
                            pspAcquirerState.status : null
                        }
                        onChange={(e) => handleInputChange('status', e.target.value)}
                        >
                        {["ACTIVE", "INACTIVE"].map((val, index) => {
                            return <MenuItem key={index} value={val} className={classes.menu}>
                            {val}
                            </MenuItem>
                        })}
                        </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField 
                        InputLabelProps={{ shrink: true }} 
                        name="pspIntegrationMode" variant="outlined" select={currentForm !== 'view'}
                        fullWidth id="pspIntegrationMode" label="PSP Integration Mode"
                        disabled={currentForm === 'view'}
                        value={currentForm === 'view' ?
                            acquirerDetails !== null && acquirerDetails.pspIntegrationMode && 
                            acquirerDetails.pspIntegrationMode : pspAcquirerState.pspIntegrationMode ? 
                            pspAcquirerState.pspIntegrationMode : null
                        }
                        onChange={(e) => handleInputChange('pspIntegrationMode', e.target.value)}
                        >
                        {pspIntegrationModeList && pspIntegrationModeList.length === 0 && 
                            <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                        {pspIntegrationModeList && pspIntegrationModeList.map((val, index) => {
                        return <MenuItem key={index} value={val} className={classes.menu}>
                            {val}
                        </MenuItem>
                        })}
                        </TextField>
                    </Grid>

                </Grid>

                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Button fullWidth variant="contained"
                            color="primary" className={"action_login_button"}
                            disabled={nextDisableToken}
                            onClick={() => currentForm !== "view" ? handleCreateUpdatePSPAcqMapping() : onChangeView("list")}
                        >
                            {currentForm === "edit" ? "Modify" : currentForm === "view" ? "Close" : "Create"}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    </div>
  )
}

CreateEditViewMapping = reduxForm({
  form: 'CreateEditViewMapping',
  // validate: validate,
  enableReinitialize: true
})(CreateEditViewMapping);

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditViewMapping);
