import React, {useState} from 'react';
import { bindActionCreators } from "redux";
import {connect} from "react-redux";
// import {reduxForm} from 'redux-form';
import {
  Grid, TextField, MenuItem, Button, Paper, IconButton,
  Typography, Checkbox
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../../../_theme'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { useHistory } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

import { floatRegex, nameRegExp, descrRegex } from "../../../shared/utility";
import { NO_SPECIAL_CHARACTERS, ONLY_NUMBERS } from "shared/errorMessages";
import "../../uploadImage.css"

const useStyles = tss.create({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    border: "solid 1px #EEEEEE",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(5),
  },
  input: {
    display: 'none',
  },
  menu: {
    fontSize: "15px"
  },
  imageSize: {
    fontSize: "3rem"
  },
  titleText: {
    color: "#424242",
    fontFamily: "Quicksand",
    fontSize: "26px",
    letterSpacing: 0,
    lineHeight: "30px"
  },
});

const defaultUrl = "https://pbs.twimg.com/profile_images/688545544243949568/SgfHYNTh_400x400.jpg";

function CreateEditViewProduct(props) {
  const {classes} = useStyles();
  // const history = useHistory()
  const {
    currentForm, productDetails, onChangeView,
    productState, countryList, brandList, categoryList,
    handleInputChange, createDisableToken, handleProductCreateUpdate,
    handleUploadImage, imageData, imageHandler, docUploadLoader
  } = props;
  
  const [valObj, setValObj] = useState({
    countryErr: false, brandErr: false, productNameErr: false, descriptionErr: false, categoryErr: false,
    level1Err: false, level2Err: false, level3Err: false, level4Err: false, level5Err: false,
    priceErr: false, lowAmountErr: false, highAmountErr: false, productSubCategoryErr: false
  })

  let countryArr = []
  const countryArrObj = countryList && 
    countryList.filter((obj) => {
        if (obj.name && obj.name !== "") {
          countryArr.push(obj.name)
        }
        return obj.name && obj.name !== ""
    })

  let brandArr = []
  const brandArrObj = brandList && 
    brandList.filter((obj) => {
      if (obj.name && obj.name !== "") {
        brandArr.push(obj.name)
      }
      return obj.name && obj.name !== ""
    })

  let subCategoryArrays = []
  const categoryArr = categoryList && categoryList.length > 0 ?
    categoryList.map((obj) => {
      
      const subCategoryArr = obj.subCategories && obj.subCategories !== null && obj.subCategories.length > 0 ?
        obj.subCategories.map((subCategory) => {
          
          return subCategory.name
        }) : []
      
        subCategoryArrays.push({arr: subCategoryArr, obj: obj.subCategories})
      return obj.name
    }) : []
  
  if(currentForm === "edit" && categoryArr && categoryArr.length > 0 && productState.productCategoryName && 
    productState.productCategoryName !== "" && !categoryArr.includes(productState.productCategoryName)) {
      handleInputChange("productCategory", [ "", "" ])
    }

  if(currentForm === "edit" && brandArr && brandArr.length > 0 && productState.brandName && 
    productState.brandName !== "" && !brandArr.includes(productState.brandName)) {
      handleInputChange("brandName", [ "", "" ])
    }

  const handleChange = (e, type, errorField) => {
    if(type === "country") {
      countryArr.indexOf(e.target.value) !== -1 &&
        handleInputChange(type, [
          e.target.value,
          countryArrObj[countryArr.indexOf(e.target.value)].isoCountryCodeNum,
        ])
      setValObj({...valObj, countryErr: false })

    } else if(type === "brandName"){
        brandArr.indexOf(e.target.value) !== -1 && handleInputChange(type, [
          e.target.value,
          brandArrObj[brandArr.indexOf(e.target.value)].id,
        ])
        setValObj({...valObj, brandErr: false })

    } else if(type === "productCategory"){
        const index = categoryArr.indexOf(e.target.value)
        index !== -1 && subCategoryArrays[index].arr.length === 0 && 
          window.alert("No Sub-Category is found for the selected Category. Please select a different Category OR create a Sub-Category under the selected Category!")

        index !== -1 && subCategoryArrays[index].arr.length !== 0 && handleInputChange(type, [
          e.target.value,
          categoryList[index].id,
        ])
        setValObj({...valObj, categoryErr: false })

    } else if(type === "productSubCategory"){
      const catIndex = categoryArr.indexOf(productState.productCategoryName)
      const index = subCategoryArrays[catIndex].arr.indexOf(e.target.value)
      
        index !== -1 && handleInputChange(type, [
          e.target.value,
          subCategoryArrays[catIndex].obj[index].id,
        ])
        setValObj({...valObj, productSubCategoryErr: false })

    } else if(type === "price"){ 
        setValObj({...valObj, priceErr: false })
        handleInputChange(type, e.target.value)

    } else if(type === "lowAmount"){
      setValObj({...valObj, lowAmountErr: false })
      handleInputChange(type, e.target.value)

    } else if(type === "highAmount"){
      setValObj({...valObj, highAmountErr: false })
      handleInputChange(type, e.target.value)

    } else {
      handleInputChange(type, e.target.value)
      setValObj({...valObj, [errorField]: false })
    }
  }

  const handleOnBlur = (field, value) => {
    if(value === null || value === ""){
      setValObj({...valObj, [field]: true })
    } else {
      setValObj({...valObj, [field]: false })
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form className="action_form merchant_form" noValidate autoComplete="off">
         <Grid container rowSpacing={2} columnSpacing={2}>

          {currentForm === "edit" &&
            <>
              <Grid item xs={12} sm={8} />
              <Grid item xs={12} sm={4}>
                <TextField 
                    InputLabelProps={{ shrink: true }} 
                    name="status" variant="outlined" select required
                    fullWidth id="status" label="Product status"
                    value={ productState.status && productState.status }
                    onChange={(e) => handleInputChange('status', e.target.value)}
                >
                    {["ACTIVE", "INACTIVE"].map((val, index) => {
                    return <MenuItem key={index} value={val} className={classes.menu}>
                        {val}
                    </MenuItem>
                    })}
                </TextField>
              </Grid>
            </>
          }

            <Grid item xs={12} sm={6}>
              <TextField 
                  InputLabelProps={{ shrink: true }} 
                  required fullWidth variant="outlined"
                  id="country" name="country" label="Country"
                  select={currentForm === 'view' ? false : true} 
                  disabled={currentForm !== 'create' ? true : false}
                  value={currentForm === 'view' ? 
                    productDetails !== null && productDetails.countryName && 
                    productDetails.countryName : productState.countryName ? 
                    productState.countryName : ""
                  }  
                  onBlur={(e) => handleOnBlur("countryErr", e.target.value)}
                  onChange={e => handleChange(e, "country")}
              >
                  {countryArr && countryArr.length === 0 && 
                  <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                  {(countryArr && countryArr.length > 0 &&
                    !countryArr.includes(productState.countryName) ?
                    [...countryArr, productState.countryName]
                    : countryArr).map((val, index) => {
                      return <MenuItem key={index} value={val} className={classes.menu}>
                          {val}
                      </MenuItem>
                  })}
              </TextField>
              {valObj.countryErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>
              :null}
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField 
                    InputLabelProps={{ shrink: true }} 
                    required fullWidth variant="outlined"
                    id="brandName" name="brandName" label="Brand Name"
                    select={currentForm !== 'view'} 
                    disabled={currentForm !== 'create'}
                    value={currentForm === 'view' ? 
                      productDetails !== null && productDetails.brandName && 
                      productDetails.brandName : productState.brandName ? 
                      productState.brandName : ""
                    }  
                    onBlur={(e) => handleOnBlur("brandErr", e.target.value)}
                    onChange={e => handleChange(e, "brandName")}
                >
                    {brandArr && brandArr.length === 0 && 
                    <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                    {(brandArr).map((val, index) => {
                        return <MenuItem key={index} value={val} className={classes.menu}>
                            {val}
                        </MenuItem>
                    })}
                </TextField>
                {valObj.brandErr ? 
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                  </p>
                :null}
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField 
                InputLabelProps={{ shrink: true }}
                name="name" variant="outlined" label="Product Name"
                required fullWidth id="name" 
                disabled={currentForm !== 'create' ? true : false}
                value={currentForm === 'view' ? 
                  productDetails !== null && productDetails.name && 
                  productDetails.name : productState.name ? 
                  productState.name : ""
                }  
                onChange={e => e.target.value.length <= 50 && handleChange(e, "name", 'productNameErr')}
                onBlur={(e) => handleOnBlur("productNameErr", e.target.value)}
              />
              {valObj.productNameErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>
              :productState && productState.name && !nameRegExp.test(productState.name) ?
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                </p>
                : null}
            </Grid>
            {currentForm !== "create" &&
            <Grid item xs={12} sm={12}>
              <TextField 
                InputLabelProps={{ shrink: true }}
                id="displayName" name="displayName" label="Display Name"
                variant="outlined" required fullWidth disabled
                value={currentForm === 'view' ? 
                  productDetails !== null && productDetails.displayName && 
                  productDetails.displayName : productState.displayName ? 
                  productState.displayName : ""
                }
              />
            </Grid>}
            
            <Grid item xs={12} >
              <TextField 
                InputLabelProps={{ shrink: true }}
                name="description" variant="outlined" fullWidth
                label="Description" required id="description" 
                disabled={currentForm === 'view' ? true : false}
                value={currentForm === 'view' ? 
                  productDetails !== null && productDetails.description && 
                  productDetails.description : productState.description ? 
                  productState.description : ""
                }  
                onChange={e => e.target.value.length <= 255 && handleChange(e, "description", "descriptionErr")}
                onBlur={(e) => handleOnBlur("descriptionErr", e.target.value)}
              />
              {valObj.descriptionErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>
              :productState && productState.description && !descrRegex.test(productState.description) ?
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                </p>
                : null}
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField 
                InputLabelProps={{ shrink: true }} 
                fullWidth variant="outlined" id="productCategory" 
                required name="productCategory" label="Product Category"
                select={currentForm === 'view' ? false : true} 
                disabled={currentForm === 'view' ? true : false}
                value={currentForm === 'view' ? 
                  productDetails !== null && productDetails.productCategoryName && 
                  productDetails.productCategoryName : productState.productCategoryName ? 
                  productState.productCategoryName : ""
                }  
                onBlur={(e) => handleOnBlur("categoryErr", e.target.value)}
                onChange={e => handleChange(e, "productCategory")}
              >
                {categoryArr && categoryArr.length === 0 && 
                  <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                {(categoryArr).map((val, index) => {
                    return <MenuItem key={index} value={val} className={classes.menu}>
                        {val}
                    </MenuItem>
                })}
              </TextField>
              {valObj.categoryErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>
              :null}
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField 
                    InputLabelProps={{ shrink: true }} 
                    required variant="outlined" id="productSubCategory" 
                    fullWidth name="productSubCategory" label="Product Sub Category"
                    select={currentForm === 'view' ? false : true} 
                    disabled={currentForm === 'view' ? true : false}
                    value={currentForm === 'view' ? 
                      productDetails !== null && productDetails.productSubCategoryName && 
                      productDetails.productSubCategoryName : productState.productSubCategoryName ? 
                      productState.productSubCategoryName : ""
                    }  
                    onBlur={(e) => handleOnBlur("productSubCategoryErr", e.target.value)}
                    onChange={e => handleChange(e, "productSubCategory")}
                >
                    {productState && categoryArr.indexOf(productState.productCategoryName) !== -1 && subCategoryArrays[categoryArr.indexOf(productState.productCategoryName)].arr.length === 0 && 
                      <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                    {(productState && categoryArr.indexOf(productState.productCategoryName) !== -1 && 
                      subCategoryArrays[categoryArr.indexOf(productState.productCategoryName)].arr.length > 0 
                      ? subCategoryArrays[categoryArr.indexOf(productState.productCategoryName)].arr : []
                      ).map((val, index) => {
                        return <MenuItem key={index} value={val} className={classes.menu}>
                            {val}
                        </MenuItem>
                    })}
                </TextField>
                {valObj.productSubCategoryErr ? 
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                  </p>
                :null}
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField 
                InputLabelProps={{ shrink: true }}
                name="level1" variant="outlined" label="Level1"
                fullWidth id="level1" disabled={currentForm === 'view' ? true : false}
                value={currentForm === 'view' ? 
                  productDetails !== null && productDetails.level1 && 
                  productDetails.level1 : productState.level1 ? 
                  productState.level1 : ""
                }  
                onChange={e => e.target.value.length <= 50 && handleChange(e, "level1", "level1Err")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                InputLabelProps={{ shrink: true }}
                name="level2" variant="outlined" label="Level2" id="level2" 
                fullWidth disabled={currentForm === 'view' ? true : false}
                value={currentForm === 'view' ? 
                  productDetails !== null && productDetails.level2 && 
                  productDetails.level2 : productState.level2 ? 
                  productState.level2 : ""
                }
                onChange={e => e.target.value.length <= 50 && handleChange(e, "level2", "level2Err")}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField 
                InputLabelProps={{ shrink: true }}
                name="level3" variant="outlined" label="Level3" id="level3" 
                fullWidth disabled={currentForm === 'view' ? true : false}
                value={currentForm === 'view' ? 
                  productDetails !== null && productDetails.level3 && 
                  productDetails.level3 : productState.level3 ? 
                  productState.level3 : ""
                }
                onChange={e => e.target.value.length <= 50 && handleChange(e, "level3", "level3Err")}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField 
                InputLabelProps={{ shrink: true }} required
                name="price" variant="outlined" label="Price" id="price" 
                fullWidth disabled={currentForm === 'view' ? true : false}
                value={currentForm === 'view' ? 
                  productDetails !== null && productDetails.price && 
                  productDetails.price : productState.price ? 
                  productState.price : ""
                }
                onBlur={(e) => handleOnBlur("priceErr", e.target.value)}
                onChange={e => e.target.value.length < 16 && (e.target.value.split(".").length < 2 || e.target.value.split(".")[1].length < 4) && handleChange(e, "price", "priceErr")}
              />
              {productState && productState.price && productState.price !== "" && 
                productState.price !== null && !floatRegex.test(productState.price) ?
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                  </p>
                  :productState && productState.price && productState.price !== "" && 
                  productState.price !== null && parseFloat(productState.price) <= 0 ?
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                      <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! Value Cannot be zero(0) or less."}</span>
                    </p>
                    : productState && productState.price && productState.price !== "" && productState.lowAmount !== "" &&
                    productState.lowAmount !== null && productState.price !== null && parseFloat(productState.price) < parseFloat(productState.lowAmount)?
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                        <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! Value Should be equal or more than Low Amount."}</span>
                      </p> 
                      : productState && productState.price && productState.price !== "" && productState.lowAmount !== "" &&
                        productState.lowAmount !== null && productState.price !== null && parseFloat(productState.price) > parseFloat(productState.highAmount)?
                          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! Value Should not Exceed High Amount."}</span>
                          </p> 
                          : valObj.priceErr ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                              <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                            </p>
                          :null
              }
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField 
                InputLabelProps={{ shrink: true }} required
                name="lowAmount" variant="outlined" label="Low Amount" id="lowAmount" 
                fullWidth disabled={currentForm === 'view' ? true : false}
                value={currentForm === 'view' ? 
                  productDetails !== null && productDetails.lowAmount && 
                  productDetails.lowAmount : productState.lowAmount ? 
                  productState.lowAmount : ""
                }
                onBlur={(e) => handleOnBlur("lowAmountErr", e.target.value)}
                onChange={e => e.target.value.length < 16 && (e.target.value.split(".").length < 2 || e.target.value.split(".")[1].length < 4) && handleChange(e, "lowAmount", "lowAmountErr")}
              />
              {productState && productState.lowAmount && productState.lowAmount !== "" && 
                productState.lowAmount !== null && !floatRegex.test(productState.lowAmount) ?
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                  </p>
                  :productState && productState.lowAmount && productState.lowAmount !== "" && 
                    productState.lowAmount !== null && parseFloat(productState.lowAmount) <= 0 ?
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                      <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! Value Cannot be zero(0) or less."}</span>
                    </p>
                    : productState && productState.lowAmount && productState.lowAmount !== "" && productState.highAmount !== "" &&
                      productState.highAmount !== null && productState.lowAmount !== null && parseFloat(productState.lowAmount) > parseFloat(productState.highAmount)?
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                          <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! Value Cannot Exceed High Amount."}</span>
                        </p> 
                        : valObj.lowAmountErr ? 
                          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                          </p>
                          :null
              }
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                InputLabelProps={{ shrink: true }} required
                name="highAmount" variant="outlined" label="High Amount" id="highAmount" 
                fullWidth disabled={currentForm === 'view' ? true : false}
                value={currentForm === 'view' ? 
                  productDetails !== null && productDetails.highAmount && 
                  productDetails.highAmount : productState.highAmount ? 
                  productState.highAmount : ""
                }
                onBlur={(e) => handleOnBlur("highAmountErr", e.target.value)}
                onChange={e => e.target.value.length < 16 && (e.target.value.split(".").length < 2 || e.target.value.split(".")[1].length < 4) && handleChange(e, "highAmount", "highAmountErr")}
              />
              {productState && productState.highAmount && productState.highAmount !== "" && 
                productState.highAmount !== null && !floatRegex.test(productState.highAmount) ?
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                  </p>
                  :productState && productState.highAmount && productState.highAmount !== "" && 
                    productState.highAmount !== null && parseFloat(productState.highAmount) <= 0 ?
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                      <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! Value Cannot be zero(0) or less."}</span>
                    </p>
                    : productState && productState.lowAmount && productState.lowAmount !== "" && productState.highAmount !== "" &&
                      productState.highAmount !== null && productState.lowAmount !== null && parseFloat(productState.lowAmount) > parseFloat(productState.highAmount)?
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                          <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! Value should be greater Than Low Amount."}</span>
                        </p> 
                        : valObj.highAmountErr ? 
                          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                          </p>
                          :null
              }
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <Checkbox
                  id="select-all-checkbox"
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled={currentForm === "view" ? true : false}
                  checked={
                    currentForm === 'view' ? 
                    productDetails !== null && productDetails.amountRangeToBeCompared !== null && 
                    productDetails.amountRangeToBeCompared : productState !== null && productState.amountRangeToBeCompared !== null ? 
                    productState.amountRangeToBeCompared : false
                  }
                  onChange={(e) => {
                    handleInputChange("amountRangeToBeCompared", 
                      productState !== null && productState.amountRangeToBeCompared !== null ? 
                      !productState.amountRangeToBeCompared : true)
                  }}
                />
                {"Is Amount Range To Be Compared"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <Checkbox
                  id="isSerialNumberRequired"
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled={currentForm === "view" ? true : false}
                  checked={
                    currentForm === 'view' ? 
                    productDetails !== null && productDetails.isSerialNumberRequired !== null && 
                    productDetails.isSerialNumberRequired : productState !== null && productState.isSerialNumberRequired !== null ? 
                    productState.isSerialNumberRequired : false
                  }
                  onChange={(e) => {
                    handleInputChange("isSerialNumberRequired", 
                      productState !== null && productState.isSerialNumberRequired !== null ? 
                      !productState.isSerialNumberRequired : true)
                  }}
                />
                {"Is Serial Number Required"}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" rowSpacing={2} columnSpacing={2}>
            <Grid item>
              <Button fullWidth variant="contained"
                color="primary" className={"action_login_button"}
                disabled={createDisableToken}
                onClick={() => currentForm !== "view" ? handleProductCreateUpdate() : onChangeView("list")}
              >
                  {currentForm === "edit" ? "Modify" : currentForm === "view" ? "Close" : "Create"}
               </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>

      {currentForm === "edit" && 
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12} >
            <Typography variant="h4" className={classes.titleText} component="h1">Image Section</Typography>
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <div className="page">
              <div className="container">
                <h1 className="heading">LIST</h1>
                {!(imageData && imageData[0] && imageData[0].baseURL && imageData[0].baseURL !== "") && 
                  <h6 className="heading">{`( Image size limit 2mb. 500 H X 441 W Pixel limit)`}</h6>}
                {imageData && imageData[0] && imageData[0].baseURL && imageData[0].baseURL !== "" &&
                <div className={ imageData && imageData[0] && imageData[0].resourceId && imageData[0].resourceId !== "" ? "img-holder-cross" : "img-holder"}>
                  {docUploadLoader === "SCREEN_TYPE_PRODUCT_LIST"  ?
                    <CircularProgress className="circleIcon"/>
                   : null}
                  <img src={imageData[0].baseURL && imageData[0].baseURL !== "" ? imageData[0].baseURL : defaultUrl} alt="" id="img" className="img" ></img>
                  {imageData && imageData[0] && imageData[0].resourceId && imageData[0].resourceId !== "" &&
                  <div className="close-icon">
                    <IconButton size="small" aria-label="close" color="inherit" onClick={() => handleUploadImage("SCREEN_TYPE_PRODUCT_LIST", "DELETE_ACTION", 0)}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </div>}
                </div>}
                <input  type="file" name="image-upload" id="input" accept="image/*" onChange={(e) => imageHandler(e, "SCREEN_TYPE_PRODUCT_LIST")}/>
                {imageData && imageData[0] && imageData[0].baseURL && imageData[0].baseURL !== "" ? null :
                <div className="label">
                  <label htmlFor="input" className="contained_label">
                    <Button variant="contained" className="upload_button" color="primary" component="span">
                      Upload
                    </Button>
                  </label>
                </div>
                }
                {imageData && imageData[0] && imageData[0].actionKey && imageData[0].actionKey === "UPLOAD" &&
                <div className="upload-action">
                  <Button color="primary" className="yes" autoFocus 
                    style={{margin: "0px 2px"}}
                    onClick={() => handleUploadImage("SCREEN_TYPE_PRODUCT_LIST", "UPLOAD", 0)} 
                  >
                    Confirm
                  </Button>
                  <Button color="primary" className="no"
                    style={{margin: "0px 2px"}}
                    onClick={() => handleUploadImage("SCREEN_TYPE_PRODUCT_LIST", "CANCEL", 0)} 
                  >
                    Cancel
                  </Button>
                </div>}
              </div>
            </div>  
          </Grid>

          <Grid item xs={12} sm={4}>
            <div className="page">
              <div className="container">
                <h1 className="heading">DETAIL</h1>
                {!(imageData && imageData[1] && imageData[1].baseURL && imageData[1].baseURL !== "") && 
                  <h6 className="heading">{`( Image size limit 2mb. 762 H X 528 W Pixel limit)`}</h6>}
                {imageData && imageData[1] && imageData[1].baseURL && imageData[1].baseURL !== "" &&
                <div className={ imageData && imageData[1] && imageData[1].resourceId && imageData[1].resourceId !== "" ? "img-holder-cross" : "img-holder"}>
                  {docUploadLoader === "SCREEN_TYPE_PRODUCT_DETAIL" ?
                    <CircularProgress className="circleIcon"/>
                   : null}
                  <img src={imageData[1].baseURL && imageData[1].baseURL !== "" ? imageData[1].baseURL : defaultUrl} alt="" id="img" className="img" ></img>
                  {imageData && imageData[1] && imageData[1].resourceId && imageData[1].resourceId !== "" &&
                  <div className="close-icon">
                    <IconButton size="small" aria-label="close" color="inherit" onClick={() => handleUploadImage("SCREEN_TYPE_PRODUCT_DETAIL", "DELETE_ACTION", 1)}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </div>}
                </div>}
                <input  type="file" name="image-upload" id="input1" accept="image/*" onChange={(e) => imageHandler(e, "SCREEN_TYPE_PRODUCT_DETAIL")}/>
                {imageData && imageData[1] && imageData[1].baseURL && imageData[1].baseURL !== "" ? null :
                <div className="label" >
                  <label htmlFor="input1" className="contained_label">
                    <Button variant="contained" className="upload_button" color="primary" component="span">
                      Upload
                    </Button>
                  </label>
                </div>
                }
                {imageData && imageData[1] && imageData[1].actionKey && imageData[1].actionKey === "UPLOAD" &&
                <div className="upload-action">
                  <Button color="primary" className="yes" autoFocus
                    onClick={() => handleUploadImage("SCREEN_TYPE_PRODUCT_DETAIL", "UPLOAD", 1)} 
                    style={{margin: "0px 2px"}}
                  >
                    Confirm
                  </Button>
                  <Button color="primary" className="no"
                    onClick={() => handleUploadImage("SCREEN_TYPE_PRODUCT_DETAIL", "CANCEL", 1)}
                    style={{margin: "0px 2px"}} 
                  >
                    Cancel
                  </Button>
                </div>}
              </div>
            </div>  
          </Grid>

          <Grid item xs={12} sm={4}>
            <div className="page">
              <div className="container">
                <h1 className="heading">REVIEW</h1>
                {!(imageData && imageData[2] && imageData[2].baseURL && imageData[2].baseURL !== "") && 
                  <h6 className="heading">{`( Image size limit 2mb. 330 H X 400 W Pixel limit)`}</h6>}
                {imageData && imageData[2] && imageData[2].baseURL && imageData[2].baseURL !== "" &&
                <div className={ imageData && imageData[2] && imageData[2].resourceId && imageData[2].resourceId !== "" ? "img-holder-cross" : "img-holder"}>
                  {docUploadLoader === "SCREEN_TYPE_PRODUCT_REVIEW" ? 
                    <CircularProgress className="circleIcon" />
                   : null}
                  <img src={imageData[2].baseURL} alt="" id="img" className="img" ></img>
                  {imageData && imageData[2] && imageData[2].resourceId && imageData[2].resourceId !== "" &&
                    <div className="close-icon">
                      <IconButton size="small" aria-label="close" color="inherit" onClick={() => handleUploadImage("SCREEN_TYPE_PRODUCT_REVIEW", "DELETE_ACTION", 2)}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </div>
                  }
                </div>}
                <input  type="file" name="image-upload" id="input2" accept="image/*" onChange={(e) => imageHandler(e, "SCREEN_TYPE_PRODUCT_REVIEW")}/>
                {imageData && imageData[2] && imageData[2].baseURL && imageData[2].baseURL !== "" ? null :
                <div className="label" >
                  <label htmlFor="input2" className="contained_label">
                    <Button variant="contained" className="upload_button" color="primary" component="span">
                      Upload
                    </Button>
                  </label>
                </div>
                }
                {imageData && imageData[2] && imageData[2].actionKey && imageData[2].actionKey === "UPLOAD" &&
                <div className="upload-action">
                  <Button color="primary" className="yes" autoFocus
                    onClick={() => handleUploadImage("SCREEN_TYPE_PRODUCT_REVIEW", "UPLOAD", 2)}
                    style={{margin: "0px 2px"}} 
                  >
                    Confirm
                  </Button>
                  <Button color="primary" className="no"
                    onClick={() => handleUploadImage("SCREEN_TYPE_PRODUCT_REVIEW", "CANCEL", 2)} 
                    style={{margin: "0px 2px"}}
                  >
                    Cancel
                  </Button>
                </div>}
              </div>
            </div>  
          </Grid>
        
        </Grid>
        
      </Paper>
      }

      {currentForm === "view" && imageData && ((imageData[0] && imageData[0].baseURL && imageData[0].baseURL !== "") ||
      (imageData[1] && imageData[1].baseURL && imageData[1].baseURL !== "") ||
      (imageData[2] && imageData[2].baseURL && imageData[2].baseURL !== "")) &&
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12} >
            <Typography variant="h4" className={classes.titleText} component="h1">Image Section</Typography>
          </Grid>
          
          
          {imageData && imageData[0] && imageData[0].baseURL && imageData[0].baseURL !== "" && <Grid item xs={12} sm={4}>
            <div className="page">
              <div className="container">
                <h1 className="heading">LIST</h1>
                <div className="img-holder">
                  <img src={imageData[0].baseURL && imageData[0].baseURL !== "" ? imageData[0].baseURL : defaultUrl} alt="" id="img" className="img" ></img>
                </div>
              </div>
            </div>  
          </Grid>}

          {imageData && imageData[1] && imageData[1].baseURL && imageData[1].baseURL !== "" && <Grid item xs={12} sm={4}>
            <div className="page">
              <div className="container">
                <h1 className="heading">DETAIL</h1>
                <div className="img-holder">
                  <img src={imageData[1].baseURL && imageData[1].baseURL !== "" ? imageData[1].baseURL : defaultUrl} alt="" id="img" className="img" ></img>
                </div>
              </div>
            </div>  
          </Grid>}

          {imageData && imageData[2] && imageData[2].baseURL && imageData[2].baseURL !== "" && <Grid item xs={12} sm={4}>
            <div className="page">
              <div className="container">
                <h1 className="heading">REVIEW</h1>
                <div className="img-holder">
                  <img src={imageData[2].baseURL && imageData[2].baseURL !== "" ? imageData[2].baseURL : defaultUrl} alt="" id="img" className="img" ></img>
                </div>
                <input  type="file" name="image-upload" id="input2" accept="image/*" onChange={(e) => imageHandler(e, "SCREEN_TYPE_PRODUCT_REVIEW")}/>
              </div>
            </div>  
          </Grid>}
        
        </Grid>
        
      </Paper>
      }

    </div>
  )
}

// CreateEditViewProduct = reduxForm({
//   form: 'CreateEditViewProduct',
//   // validate: validate,
//   enableReinitialize: true
// })(CreateEditViewProduct);

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    docUploadLoader: state.SharedReducer.docUploadLoader
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditViewProduct);
