import React, { useState } from "react";
import {
    Tabs, Tab
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../../../_theme'

import DownloadProductFile from "./downloadProductFile";
import UploadProductFile from "./uploadProductFile";
import DeleteMappings from "./deleteMappings/index";

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CreateMapping = (props) => {

    const { 
        countryList, brandArr, brandArrObj, merchantMetadata, downloadFileState, handleDownProductFileChange, submitProductFileDownload, disableDownloadProductFile,
        uploadFileState, handleUploadProductFileChange, submitProductFileUpload, disableUploadProductFile,
        deleteFilterState, handleDeletePageChange, applyDeleteFilter, productMappingList, selectedMappings, permissions 
    } = props
    const {classes} = useStyles()
    const [activeTab, setActiveTab] = useState(0)

    let countryArr = []
    const countryArrObj = countryList && countryList !== null &&
        countryList.filter((obj) => {
            if (obj.name && obj.name !== "") {
            countryArr.push(obj.name)
            }
            return obj.name && obj.name !== ""
        })

    const merchantArr = merchantMetadata && 
        merchantMetadata.map(merchant => 
            merchant.name ? merchant.name : null
        ).filter(val => val !== null)

    const handleChange = async (event, newValue) => {
        await setActiveTab(newValue);
    };

    return (
        <div className={classes.root}>
            <div className={""}>
                <Tabs value={activeTab} onChange={handleChange} className="tabs_wraper" aria-label="simple tabs example">
                    {permissions.includes("71") && <Tab label="Download Product File" {...a11yProps(0)} className={classes.tabWidth} />}
                    {permissions.includes("72") && <Tab label="Upload Product File" {...a11yProps(1)} className={classes.tabWidth} />}
                    {permissions.includes("73") && <Tab label="Delete Mapping" {...a11yProps(2)} className={classes.tabWidth} />}
                </Tabs>
            </div>
            {activeTab === 0 ? 
            <DownloadProductFile
                classes={classes}
                countryArr={countryArr}
                countryArrObj={countryArrObj}
                brandArr={brandArr}
                merchantArr={merchantArr}
                merchantList={merchantMetadata}
                downloadFileState={downloadFileState}
                handleDownProductFileChange={handleDownProductFileChange}
                submitProductFileDownload={submitProductFileDownload}
                disableDownloadProductFile={disableDownloadProductFile}
            /> 
            : activeTab === 1 ?
                <UploadProductFile
                    classes={classes}
                    countryArr={countryArr}
                    countryArrObj={countryArrObj}
                    merchantArr={merchantArr}
                    merchantList={merchantMetadata}
                    uploadFileState={uploadFileState}
                    handleUploadProductFileChange={handleUploadProductFileChange}
                    submitProductFileUpload={submitProductFileUpload}
                    disableUploadProductFile={disableUploadProductFile}
                /> 
                :   <DeleteMappings
                        classes={classes}
                        countryArr={countryArr}
                        countryArrObj={countryArrObj}
                        merchantArr={merchantArr}
                        merchantList={merchantMetadata}
                        brandArr={brandArr}
                        brandArrObj={brandArrObj}
                        handleDeletePageChange={handleDeletePageChange}
                        applyFilter={applyDeleteFilter}
                        deleteFilterState={deleteFilterState}
                        productMappingList={productMappingList}
                        selectedMappings={selectedMappings}
                    />
            }
        </div>
    )
}

const useStyles = tss.create({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        border: "solid 1px #EEEEEE",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(5),
    },
    input: {
        display: 'none',
    },
    menu: {
        fontSize: "15px"
    },
    addRange: {
        alignItems: "center",
        display: "flex",
    },
});

export default CreateMapping