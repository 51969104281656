import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { tss } from "tss-react";
import theme from '../../_theme'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer'
import Paper from '@mui/material/Paper'

import AppHeader from '../../_common/appHeader'
import AppFooter from '../../_common/appFooter'
import AppSidebar from '../../_common/appSidebar'

const DashboardLayout = (
  { header, footer, sidebar, children } = {
    header: AppHeader,
    footer: AppFooter,
    sidebar: AppSidebar,
  },
) => {
  const refHeaderContainer = useRef(null)

  const {classes, cx} = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const isMobile = !isDesktop

  const isSidebarOpenDesktop= true;
  const [headerHeight, setHeaderHeight] = useState(0)
  const [isSidebarOpenMobile, setIsSidebarOpenMobile] = useState(false)
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false)

  useEffect(() => {
    // code to run on component mount
    setHeaderHeight(refHeaderContainer.current.offsetHeight)
  }, [])

  const contentOffset = (() => {
    if ((isDesktop && !isSidebarOpenDesktop) || isMobile) {
      return "0px"
    } else if (isDesktop && isSidebarCollapsed) {
      return theme.sidebar.widthCollapsed
    } else {
      return theme.sidebar.width
    }
  })()

  const HeaderComponent = header
  const SidebarComponent = sidebar
  const FooterComponent = footer

  function handleSidebarToggleOpenMobile() {
    setIsSidebarOpenMobile(!isSidebarOpenMobile)
  }

  function handleSidebarToggle() {
    // Open/close on mobile
    if (isMobile) {
      setIsSidebarOpenMobile(!isSidebarOpenMobile)
    }
    // Collapse/uncollapse on desktop
    else {
      setIsSidebarCollapsed(!isSidebarCollapsed)
    }
  }

  // function handleSidebarToggleCollapse() {
  //   setIsSidebarCollapsed(!isSidebarCollapsed)
  // }

  return (
    <div className={classes.dashboardContainer}>
      <div
        ref={refHeaderContainer}
        className={cx(classes.headerContainer)}
        style={{
          width: `calc(100% - ${contentOffset})`,
        }}
      >
        {HeaderComponent && <HeaderComponent onToggleClick={handleSidebarToggle} />}
      </div>
      <div
        // ref={refSidebarContainer}
        className={cx(
          classes.sidebarContainer,
          isMobile && classes.sidebarContainerMobile,
          isDesktop && isSidebarCollapsed && classes.sidebarContainerCollapsed,
        )}
      >
        {/* Mobile sidebar */}
        <Paper sx={{ display: { md: "block", sm: "none" } }}>
          <Drawer
            variant="temporary"
            open={isSidebarOpenMobile}
            onClose={handleSidebarToggleOpenMobile}
            // classes={{
            //   paper: cx(classes.drawer), //  classes.drawerMobile
            // }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: theme.sidebar.width },
            }}
          >
            {SidebarComponent && <SidebarComponent />}
          </Drawer>
        </Paper>
        {/* Desktop sidebar */}
        <Paper sx={{ display: { xs: "none", sm: "block" } }}>
          <Drawer
            classes={{
              paper: cx(classes.drawer),
            }}
            variant="permanent"
          >
            {SidebarComponent && <SidebarComponent isCollapsed={isSidebarCollapsed} />}
          </Drawer>
        </Paper>
      </div>
      <main
        className={classes.mainContainer}
        style={{
          paddingTop: headerHeight, // || headerSize.height,
        }}>
        <div className={classes.contentContainer}>{children}</div>
        <div className={classes.footerContainer}>
          {FooterComponent && <FooterComponent />}
        </div>
      </main>
    </div>
  )
}

DashboardLayout.defaultProps = {
  header: AppHeader,
  sidebar: AppSidebar,
  footer: AppFooter,
}

DashboardLayout.propTypes = {
  header: PropTypes.elementType,
  sidebar: PropTypes.elementType,
  footer: PropTypes.elementType,
}

const useStyles = tss.create({
  dashboardContainer: {
    display: 'flex',
    background: '#fafafa',
  },
  headerContainer: {
    top: 0,
    left: 'auto',
    right: 0,
    display: 'flex',
    alignItems: 'stretch',
    position: 'absolute',
    zIndex: theme.zIndex.drawer,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  sidebarContainer: {
    display: 'flex',
    alignItems: 'stretch',
    position: 'relative',
    top: 0,
    bottom: 0,
    flexDirection: 'row',
    width: theme.sidebar.width,
    flexShrink: 0,
    // [theme.breakpoints.up('md')]: {
    //   width: theme.sidebar.width,
    //   flexShrink: 0,
    // },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  sidebarContainerMobile: {
    width: 0,
  },
  sidebarContainerCollapsed: {
    width: theme.sidebar.widthCollapsed,
  },
  drawer: {
    width: '100%',
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      width: theme.sidebar.width,
      flexShrink: 0,
    },
  },
  mainContainer: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    flexDirection: 'column',
    display: 'flex',
  },
  contentContainer: {
    // display: 'flex',
    position: 'relative',
    flex: 1,
  },
  footerContainer: {
    position: 'relative',
  },
});

export default DashboardLayout
