import { IssuerActions } from "../consts/index";

const initialState = {
    issuerLoader: false,
    issuerList: null,
    countryList: null,
    createIssuerRes: null,
    uploadDocumentRes: null,
    docUploadLoader: null,
    interestTypes: null,
}

export default (state = initialState, {type, payload}) => {
    switch(type) {
        case IssuerActions.fetchIssuerDetailsForIssuer.REQUEST:
            return {
                ...state,
                issuerLoader: true
            }
        case IssuerActions.fetchIssuerDetailsForIssuer.SUCCESS:
            return {
                ...state,
                issuerLoader: false,
                issuerList: payload
            }
        case IssuerActions.fetchIssuerDetailsForIssuer.FAILURE:
            return {
                ...state,
                issuerLoader: false,
                issuerList: null,
            }
        case IssuerActions.fetchCountryDetailsForIssuer.REQUEST:
            return {
                ...state,
                issuerLoader: true
            }
        case IssuerActions.fetchCountryDetailsForIssuer.SUCCESS:
            return {
                ...state,
                issuerLoader: false,
                countryList: payload
            }
        case IssuerActions.fetchCountryDetailsForIssuer.FAILURE:
            return {
                ...state,
                issuerLoader: false,
                countryList: null,
            }
        case IssuerActions.createAndEditIssuer.REQUEST:
            return {
                ...state,
                issuerLoader: true
            }
        case IssuerActions.createAndEditIssuer.SUCCESS:
            return {
                ...state,
                issuerLoader: false,
                createIssuerRes: payload
            }
        case IssuerActions.createAndEditIssuer.FAILURE:
            return {
                ...state,
                issuerLoader: false,
                createIssuerRes: payload,
            }
        case IssuerActions.uploadDocumentForIssuer.REQUEST:
            return {
                ...state,
                issuerLoader: true,
                docUploadLoader: payload.key ? payload.key : null
            }
        case IssuerActions.uploadDocumentForIssuer.SUCCESS:
            return {
                ...state,
                issuerLoader: false,
                uploadDocumentRes: payload,
                docUploadLoader: null
            }
        case IssuerActions.uploadDocumentForIssuer.FAILURE:
            return {
                ...state,
                issuerLoader: false,
                uploadDocumentRes: payload,
                docUploadLoader: null
            }
        case IssuerActions.fetchInterestTypes.REQUEST:
            return {
                ...state,
            }
        case IssuerActions.fetchInterestTypes.SUCCESS:
            return {
                ...state,
                interestTypes: payload,
            }
        case IssuerActions.fetchInterestTypes.FAILURE:
            return {
                ...state,
                interestTypes: payload,
            }

        default:
            return state;
    }
}