import { actionCreator, BASE_URL } from "../../../shared/utility"
import { ProductCodeMappingActions } from "../consts/index"

export function downloadProductFileToCreateMapping(payload) {
    let endURL = payload.brandIds && payload.brandIds.length > 0  ? `brandIds=${payload.brandIds}` : ""
    endURL = endURL + (endURL !== "" && payload.merchantId && payload.merchantId !== "" ? `&merchantId=${payload.merchantId}` : (endURL === "" && payload.merchantId !== "" ? `merchantId=${payload.merchantId}` : ""))
    endURL = endURL !== "" ? `?${endURL}` : endURL

    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductCodeMappingActions.downloadProductFileToCreateMapping.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/productmappings/file/download${endURL}`, {
            method: 'POST',
            // body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductCodeMappingActions.downloadProductFileToCreateMapping.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(ProductCodeMappingActions.downloadProductFileToCreateMapping.SUCCESS,  response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function downloadProductFileToUpdateMapping(payload) {
    let endURL = payload.brandIds && payload.brandIds.length > 0  ? `brandIds=${payload.brandIds}` : ""
    endURL = endURL + (endURL !== "" && payload.merchantId && payload.merchantId !== "" ? `&merchantId=${payload.merchantId}` : (endURL === "" && payload.merchantId !== "" ? `merchantId=${payload.merchantId}` : ""))
    endURL = endURL !== "" ? `?${endURL}` : endURL
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductCodeMappingActions.downloadProductFileToUpdateMapping.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/productmappings/file/download${endURL}`, {
            method: 'PUT',
            // body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductCodeMappingActions.downloadProductFileToUpdateMapping.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(ProductCodeMappingActions.downloadProductFileToUpdateMapping.SUCCESS,  response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetDownloadProductFileResp() {
    return(dispatch) => {
        dispatch(actionCreator(ProductCodeMappingActions.downloadProductFileToCreateMapping.FAILURE, null))
    }
}

export function uploadProductFileCreateMode(payload) {
    let formData = new FormData()
    formData.append('file', payload.file)
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductCodeMappingActions.uploadProductFileCreateMode.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/productmappings/file/upload?merchantId=${payload.merchantId}`, {
            method: 'POST',
            body: formData, 
            // JSON.stringify(payload),
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductCodeMappingActions.uploadProductFileCreateMode.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(ProductCodeMappingActions.uploadProductFileCreateMode.SUCCESS,  response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function uploadProductFileUpdateMode(payload) {
    let formData = new FormData()
    formData.append('file', payload.file)
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductCodeMappingActions.uploadProductFileUpdateMode.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/productmappings/file/upload?merchantId=${payload.merchantId}`, {
            method: 'PUT',
            body: formData, 
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductCodeMappingActions.uploadProductFileUpdateMode.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(ProductCodeMappingActions.uploadProductFileUpdateMode.SUCCESS,  response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetUploadProductFileResp() {
    return(dispatch) => {
        dispatch(actionCreator(ProductCodeMappingActions.uploadProductFileCreateMode.FAILURE, null))
    }
}

export function fetchProductMappingFilesDetail(payload) {
    
    const endURL = `?pageNo=${payload.pageNo}&pageSize=${payload.pageSize}`
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductCodeMappingActions.fetchProductMappingFilesDetail.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/merchantpcode/file/details${endURL}`, {
            method: 'GET',
            // body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductCodeMappingActions.fetchProductMappingFilesDetail.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(ProductCodeMappingActions.fetchProductMappingFilesDetail.SUCCESS,  response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function downloadProductMappingFileByStatus(payload) {
    const status = payload.status && payload.status !== "" ? `&status=${payload.status}` : ""
    const endURL = `?merchantPCodeFileUploadId=${payload.merchantPCodeFileUploadId}` + status
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductCodeMappingActions.downloadProductMappingFileByStatus.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/merchantpcode/file/download${endURL}`, {
            method: 'GET',
            // body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            console.log("response: ", response)
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductCodeMappingActions.downloadProductMappingFileByStatus.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(ProductCodeMappingActions.downloadProductMappingFileByStatus.SUCCESS,  response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetDownloadMappingFileByStatusResp() {
    return(dispatch) => {
        dispatch(actionCreator(ProductCodeMappingActions.downloadProductMappingFileByStatus.FAILURE, null))
    }
}

export function fetchProductMappings(payload) {
    const merchantId = payload.merchantId && payload.merchantId !== "" ? `&merchantId=${payload.merchantId}` : ""
    const oemIds = payload.brandId && payload.brandId !== "" ? `&oemIds=${payload.brandId}` : ""
    const merchantProductCode = payload.merchantProductCode && payload.merchantProductCode !== "" ? `&merchantProductCode=${payload.merchantProductCode}` : ""
    const endURL = `?pageNo=${payload.pageNo}&pageSize=${payload.pageSize}&isDeleted=${false}` + merchantId + oemIds + merchantProductCode
    
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductCodeMappingActions.fetchProductMappings.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/productmappings${endURL}`, {
            method: 'GET',
            // body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductCodeMappingActions.fetchProductMappings.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(ProductCodeMappingActions.fetchProductMappings.SUCCESS,  response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetProductMappingListResp() {
    return(dispatch) => {
        dispatch(actionCreator(ProductCodeMappingActions.fetchProductMappings.FAILURE, null))
    }
}

export function deleteProductMappings(payload) {
        return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductCodeMappingActions.deleteProductMappings.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/productmapping/${payload.productMappingIds}`, {
            method: 'DELETE',
            // body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductCodeMappingActions.deleteProductMappings.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(ProductCodeMappingActions.deleteProductMappings.SUCCESS,  response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetDeleteMappingResp() {
    return(dispatch) => {
        dispatch(actionCreator(ProductCodeMappingActions.deleteProductMappings.FAILURE, null))
    }
}