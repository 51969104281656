import React from 'react'
// import { Route, Redirect } from 'react-router-dom' //
import { tss } from "tss-react";
import theme from '../_theme';
import { Grid, Box } from '@mui/material'
// import { Link as RouterLink } from 'react-router-dom'
import Login from './login' 
// import Recover from './recover' 
import Logo from '../Assets/logo_white.svg';
import LoginBg from '../Assets/login_bg.svg';
import Footer from './_common/AuthFooter/AuthFooter'

export default function Auth({ match }) {
  const {classes} = useStyles();
  return (
    <Grid container className={classes.container}>
      <div className={classes.introSection}>
        <div className={classes.topHeadLogo}><img src={Logo} size={30} className={classes.logo} alt="Paylater" /></div>
        <div className={classes.bottomContent}>
          <p className={"login_content"}>Sign in and manage your PayLater <br /> Account</p>
        </div>
      </div>
      <div className={classes.formSection}>
        <Grid item xs={12} sm={12} md={6}>
          <Box p={3}>
            <Login/>
          </Box>
        </Grid>
        <Grid container className={classes.footerContainer}>
          <Grid item xs={12} sm={12} md={12}>
            <Footer />
          </Grid>
        </Grid>
      </div>
    </Grid>
  )
}

const useStyles = tss.create({
  insideContainer: {
    paddingTop: theme.spacing(2),
  },
  container: {
    minHeight: '100vh',
  },
  formSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    flexGrow: "0",
    maxWidth: "calc(100% - 400px)",
    flexBasis: "calc(100% - 400px)"
  },
  introSection: {
    flexGrow: "0",
    maxWidth: "400px",
    flexBasis: "400px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: '#1BA785',
    backgroundImage: 'url(' + LoginBg + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  topHeadLogo: {
    position: 'absolute',
    top: "20px", left: "25px"
  },
  bottomContent: {
    position: 'absolute',
    bottom: "20px", left: "25px"
  },
  Pinelogo: {
    color: theme.palette.secondary.main,
    zIndex: 10,
  },
  copyright: {
    display: "flex",
    alignItems: "center", justifyContent: "center", width: "90%",
  },
  footer: {
    display: 'flex',
    background: '#fff',
    padding: '0.5rem 1rem',
    justifyContent: 'space-between',
    borderTop: "solid 1px #eee"
  },
  footerContainer: {
    position: "absolute",
    bottom: "0",
    display: "flex",
    maxWidth: "calc(100% - 400px)",
  }
});
