import { actionCreator, BASE_URL } from "../../shared/utility";
import { SchemeActions } from "../consts/index";

export function fetchSchemeList(payload) {

    const id = payload.id && payload.id !== "" ? `&id=${payload.id}` : ""
    const mciId = payload.mciId && payload.mciId !== "" ? `&mciId=${payload.mciId}` : ""
    const productType = payload.productType && payload.productType !== "" ? `&productType=${payload.productType}` : ""
    const installmentConfigId = payload.installmentConfigId && payload.installmentConfigId !== "" ? `&installmentConfigId=${payload.installmentConfigId}` : ""
    const installmentConfigDesc = payload.installmentConfigDesc && payload.installmentConfigDesc !== "" ? `&installmentConfigDesc=${encodeURIComponent(payload.installmentConfigDesc)}` : ""
    const installmentEndDate = payload.installmentEndDate && payload.installmentEndDate !== "" ? `&installmentEndDate=${payload.installmentEndDate}` : ""
    const installmentStartDate = payload.installmentStartDate && payload.installmentStartDate !== "" ? `&installmentStartDate=${payload.installmentStartDate}` : ""
    const schemeType = payload.schemeType && payload.schemeType !== "" ? `&schemeType=${payload.schemeType}` : ""
    const schemeStatus = payload.schemeStatus && payload.schemeStatus !== "" ? `&schemeStatus=${payload.schemeStatus}` : ""

    const endURL = `?pageNo=${payload.pageNo}&pageSize=${payload.pageSize}&sortDirection=${payload.sortDirection}&sortBy=${payload.sortBy}` 
        + id + mciId + productType + installmentConfigId + installmentConfigDesc + installmentEndDate + installmentStartDate + schemeType + schemeStatus

    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(SchemeActions.fetchSchemes.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/schemes${endURL}`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
          .then(response => response.json())
          .then(function (res) {
            if(!res.st) {
                if(res.responseCode && res.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(SchemeActions.fetchSchemes.FAILURE));
                }
            } else {
                dispatch(actionCreator(SchemeActions.fetchSchemes.SUCCESS, res));
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    }
}

export function fetchBankSchemes(payload) {
    const id = payload.id && payload.id !== "" ? `&id=${payload.id}` : ""
    const mciId = payload.mciId && payload.mciId !== "" ? `&mciId=${payload.mciId}` : ""
    const productType = payload.productType && payload.productType !== "" ? `&productType=${payload.productType}` : ""
    const installmentConfigId = payload.installmentConfigId && payload.installmentConfigId !== "" ? `&installmentConfigId=${payload.installmentConfigId}` : ""
    const installmentConfigDesc = payload.installmentConfigDesc && payload.installmentConfigDesc !== "" ? `&installmentConfigDesc=${encodeURIComponent(payload.installmentConfigDesc)}` : ""
    const installmentEndDate = payload.installmentEndDate && payload.installmentEndDate !== "" ? `&installmentEndDate=${payload.installmentEndDate}` : ""
    const installmentStartDate = payload.installmentStartDate && payload.installmentStartDate !== "" ? `&installmentStartDate=${payload.installmentStartDate}` : ""
    // const schemeType = payload.schemeType && payload.schemeType !== "" ? `&schemeType=${payload.schemeType}` : ""
    const schemeStatus = payload.schemeStatus && payload.schemeStatus !== "" ? `&schemeStatus=${payload.schemeStatus}` : ""
    const requestId = payload.requestId && payload.requestId !== "" ? `&requestId=${payload.requestId}` : ""

    const endURL = `?pageNo=${payload.pageNo}&pageSize=${payload.pageSize}&sortDirection=${payload.sortDirection}&sortBy=${payload.sortBy}` 
        + id + mciId + productType + installmentConfigId + installmentConfigDesc + installmentEndDate + installmentStartDate + schemeStatus + requestId

    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(SchemeActions.fetchSchemes.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/bank/schemes${endURL}`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
          .then(response => response.json())
          .then(function (res) {
            if(!res.st) {
                if(res.responseCode && res.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(SchemeActions.fetchSchemes.FAILURE));
                }
            } else {
                dispatch(actionCreator(SchemeActions.fetchSchemes.SUCCESS, res));
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    }
}

export function resetSchemeListResponse() {
    return (dispatch) => {
        dispatch(actionCreator(SchemeActions.fetchSchemes.FAILURE))
    }
}

export function approveCIBPendingBankScheme(payload, pendingReqPayload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(SchemeActions.handleApproveCIBPendingScheme.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/approveBankScheme`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
          .then(response => response.json())
          .then(function (res) {
            if(!res.st) {
                if(res.responseCode && res.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(SchemeActions.handleApproveCIBPendingScheme.FAILURE, res));
                }
            } else {
                dispatch(actionCreator(SchemeActions.handleApproveCIBPendingScheme.SUCCESS, res));
                dispatch(fetchBankSchemes(pendingReqPayload));
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    }
}

export function removeApproveResponse() {
    return(dispatch) => {
        dispatch(actionCreator(SchemeActions.handleApproveCIBPendingScheme.SUCCESS, null));
    }
}

export function handleCreateSchemeDate(payload) {
    return(dispatch) => {
        dispatch(actionCreator(SchemeActions.handleDateChange.SUCCESS, payload))
    }
}

export function handleCountrySelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(SchemeActions.handleCountryChange.SUCCESS, payload))
    }
}

export function handleIssuerSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(SchemeActions.handleIssuerChange.SUCCESS, payload))
    }
}

export function handleStatusCodeSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(SchemeActions.handleStatusCodeChange.SUCCESS, payload))
    }
}

export function handleEmailSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(SchemeActions.handleEmailChange.SUCCESS, payload))
    }
}

export function handleInstallDescSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(SchemeActions.handleInstallDescChange.SUCCESS, payload))
    }
}

export function handleProductTypeSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(SchemeActions.handleProductTypeChange.SUCCESS, payload))
    }
}

export function handlePaymentModeSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(SchemeActions.handlePaymentModeChange.SUCCESS, payload))
    }
}

export function handleGeoScopeSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(SchemeActions.handleGeoScopeChange.SUCCESS, payload))
    }
}

export function handleStepper2Details(payload) {
    return(dispatch) => {
        dispatch(actionCreator(SchemeActions.handleStepper2Details.SUCCESS, payload))
    }
}

export function handleStepper3Details(payload) {
    return(dispatch) => {
        dispatch(actionCreator(SchemeActions.handleStepper3Details.SUCCESS, payload))
    }
}

export function handleStepper4Details(payload) {
    return(dispatch) => {
        dispatch(actionCreator(SchemeActions.handleStepper4Details.SUCCESS, payload))
    }
}

export function fetchStepper1DropDownDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(SchemeActions.handleStepper1Detailsfetch.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/getMetadata`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(SchemeActions.handleStepper1Detailsfetch.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(SchemeActions.handleStepper1Detailsfetch.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchCountryDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(SchemeActions.handleCountryDetailsFetch.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/getCountrySummaryList`
            , {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(SchemeActions.handleCountryDetailsFetch.FAILURE))
                }
            } else {
                dispatch(actionCreator(SchemeActions.handleCountryDetailsFetch.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetCountryDetails() {
    return(dispatch) => {
        dispatch(actionCreator(SchemeActions.handleCountryDetailsFetch.FAILURE))
    }
}

export function fetchIssuerDetails(payload) {
    return (dispatch, getState) => {
        const endURL = payload && payload.isPLManaged !== null && payload.isPLManaged === true ? `?isPLManaged=${payload.isPLManaged}` : "";
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(SchemeActions.handleIssuerDetailsFetch.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/getIssuerSummaryList${endURL}`
            , {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(SchemeActions.handleIssuerDetailsFetch.FAILURE))
                }
            } else {
                dispatch(actionCreator(SchemeActions.handleIssuerDetailsFetch.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetIssuerDetails() {
    return(dispatch) => {
        dispatch(actionCreator(SchemeActions.handleIssuerDetailsFetch.FAILURE))
    }
}

export function fetchBinRangeDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(SchemeActions.handleBinRangeDetailsFetch.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/getBinRange`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(SchemeActions.handleBinRangeDetailsFetch.FAILURE))
                }
            } else {
                dispatch(actionCreator(SchemeActions.handleBinRangeDetailsFetch.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchBrandListForScheme(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(SchemeActions.fetchBrandListForScheme.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/getBrandSummaryList`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(SchemeActions.fetchBrandListForScheme.FAILURE))
                }
            } else {
                dispatch(actionCreator(SchemeActions.fetchBrandListForScheme.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetBrandList() {
    return(dispatch) => {
        dispatch(actionCreator(SchemeActions.fetchBrandListForScheme.FAILURE))
    }
}

export function submitCreateSchemeForm(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(SchemeActions.handleCreateSchemeSubmit.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/createBrandScheme`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(SchemeActions.handleCreateSchemeSubmit.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(SchemeActions.handleCreateSchemeSubmit.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchTccMccList(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(SchemeActions.fetchTccMccList.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/getTccMccList`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(SchemeActions.fetchTccMccList.FAILURE))
                }
            } else {
                dispatch(actionCreator(SchemeActions.fetchTccMccList.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function clearTccMcc() {
    return(dispatch) => {
        dispatch(actionCreator(SchemeActions.fetchTccMccList.FAILURE))
    }
}

export function clearschemeRes(payload) {
    return(dispatch) => {
        dispatch(actionCreator(SchemeActions.handleCreateSchemeSubmit.SUCCESS, payload))
    }
}

export function deleteBrandScheme(payload, listReq) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(SchemeActions.handleDeleteBranchScheme.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/createBrandScheme`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(SchemeActions.handleDeleteBranchScheme.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(SchemeActions.handleDeleteBranchScheme.SUCCESS, response))
                dispatch(fetchSchemeList(listReq))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function clearDeleteBrandScheme() {
    return(dispatch, getState) => {
        dispatch(actionCreator(SchemeActions.handleDeleteBranchScheme.SUCCESS, null))
    }
}


export function fetchBankSchemesAsTemplate(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(SchemeActions.handleFetchBankSchemes.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/getBankTamplet`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(SchemeActions.handleFetchBankSchemes.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(SchemeActions.handleFetchBankSchemes.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function handleProgTypeNSubType(type, payload) {
    return(dispatch) => {
        if(type === "program"){
            dispatch(actionCreator(SchemeActions.handleProgramType.SUCCESS, payload))
        } else {
            dispatch(actionCreator(SchemeActions.handleSubventionType.SUCCESS, payload))
        } 
    }
    
}

export function createBankScheme(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(SchemeActions.submitCreateBankSchemeForm.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/mci/bankinstlconfigsetups`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`,
                'Scheme-Type': 'PL-Managed-Scheme',
            }
        }).then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(SchemeActions.submitCreateBankSchemeForm.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(SchemeActions.submitCreateBankSchemeForm.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })

    }
}

export function resetBankSchemeResp() {
    return(dispatch) => {
        dispatch(actionCreator(SchemeActions.submitCreateBankSchemeForm.SUCCESS, null));
    }
}

export function approveBrandOrMerchantScheme(payload, pendingReqPayload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(SchemeActions.approveBrandOrMerchantScheme.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/mci/brandinstlconfigapprovals`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`,
                'Scheme-Type': 'PL-Managed-Scheme',
            }
        }
        )
          .then(response => response.json())
          .then(function (res) {
            if(!res.st) {
                if(res.responseCode && res.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(SchemeActions.approveBrandOrMerchantScheme.FAILURE, res));
                }
            } else {
                dispatch(actionCreator(SchemeActions.approveBrandOrMerchantScheme.SUCCESS, res));
                dispatch(fetchSchemeList(pendingReqPayload));
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    }
}

export function resetBrandOrMerchantSchemeResp() {
    return(dispatch) => {
        dispatch(actionCreator(SchemeActions.approveBrandOrMerchantScheme.SUCCESS, null));
    }
}

export function fetchTenureList(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(SchemeActions.fetchTenureList.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/tenures`, {
            method: 'GET',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(SchemeActions.fetchTenureList.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(SchemeActions.fetchTenureList.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}