import React, {useState} from 'react';
import {connect} from "react-redux";
import { bindActionCreators } from "redux";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { 
    Grid, TextField, Button, Paper, MenuItem, Checkbox, Typography,
    Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText 
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../../_theme'

import CloseImgIcon from '../../Assets/close.svg';
import { descrRegex } from '../../shared/utility'
import { LIMITED_SPECIAL_CHARACTERS } from "shared/errorMessages";

const useStyles = tss.create({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        border: "solid 1px #EEEEEE",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(5),
    },
    input: {
        display: 'none',
    },
    menu: {
        fontSize: "15px",
    },
    marginTopBottom: {
        margin: theme.spacing(2, 0, 2, 0),
    },
    titleText: {
        color: "#424242",
        fontFamily: "Quicksand",
        fontSize: "26px",
        letterSpacing: 0,
        lineHeight: "30px"
    },
});

function CreateEditViewCountry(props) {
    const {classes} = useStyles();
    const { 
        currentForm, countryState, handleInputChange, createDisableToken,
        handleCountryCreateUpdate, onChangeView, countryDetails, countryCreateLoader,
        masterCountryList
    } = props;
    const [valObj, setValObj] = useState({
        countryNameErr: false, countryCodeErr: false, countryCodeNumErr: false, 
        mobileRegExpErr: false, postalCodeLenErr: false, countryTypeErr: false, plManagedFlagUpdateReasonErr: false
    })
    const [openPopover, setopenPopover] = useState(false);

    let permissions = localStorage.getItem("permissions")
    permissions = permissions && permissions !== null ? JSON.parse(permissions) : []

    const countryArr = masterCountryList && masterCountryList.map(country => country.name ? country.name : null).filter(val => val !== null)

    const handleChange = (type, value) => {
        if(type === "name"){
            countryArr.indexOf(value) !== -1 && handleInputChange(type, [value, masterCountryList[countryArr.indexOf(value)]])
            setValObj({...valObj, countryNameErr: false })

        } else if(type === "status") {
            countryState && countryState.name !== "" && countryArr.indexOf(countryState.name) !== -1 &&
            handleInputChange(type, [value, masterCountryList[countryArr.indexOf(countryState.name)]])

        } else if(type === "isoCountryCode") {
            handleInputChange(type, value)
            setValObj({...valObj, countryCodeErr: false })

        } else if(type === "isoCountryCodeNum") {
            handleInputChange(type, value)
            setValObj({...valObj, countryCodeNumErr: false })

        } else if(type === "mobileRegexp") {
            handleInputChange(type, value)
            setValObj({...valObj, mobileRegExpErr: false })

        } else if(type === "postalCodeLength") {
            handleInputChange(type, value)
            setValObj({...valObj, postalCodeLenErr: false })

        } else {
            handleInputChange(type, value)
            setValObj({...valObj, countryTypeErr: false })
        }
    }
    
    const handleOnBlur = (field, value) => {
        if(value === null || value === "" || (field === "plManagedFlagUpdateReasonErr" && value.replace(/\s/g, '').length === 0)){
            setValObj({...valObj, [field]: true })
        } else {
            setValObj({...valObj, [field]: false })
        } 
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <form className="action_form merchant_form" noValidate autoComplete="off">
                    <Grid container rowSpacing={2} columnSpacing={2}>
                        
                        {currentForm === "edit" &&
                            <>
                                <Grid item xs={12} sm={8} />
                                <Grid item xs={12} sm={4}>
                                    <TextField 
                                        InputLabelProps={{ shrink: true }} 
                                        name="status" variant="outlined" select required
                                        fullWidth id="status" label="Country Status"
                                        value={ countryState.status && countryState.status }
                                        onChange={(e) => handleChange('status', e.target.value)}
                                    >
                                        {["ACTIVE", "INACTIVE"].map((val, index) => {
                                        return <MenuItem key={index} value={val} className={classes.menu}>
                                            {val}
                                        </MenuItem>
                                        })}
                                    </TextField>
                                </Grid>
                            </>
                        }

                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} required fullWidth
                                variant="outlined" 
                                id="country" label="Master Country" name="country"
                                disabled={currentForm !== 'create'}
                                select={currentForm === 'create'} 
                                value={currentForm === 'view' ? 
                                    countryDetails !== null && countryDetails.name && 
                                    countryDetails.name : countryState.name ? 
                                    countryState.name : ""
                                } 
                                onBlur={(e) => handleOnBlur("countryNameErr", e.target.value)}
                                onChange={(e) => countryArr.length > 0 && handleChange('name', e.target.value)}
                            >
                                {countryArr && countryArr.length === 0 && 
                                <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                                {countryArr && countryArr.map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                                })}
                            </TextField>
                            {valObj.countryNameErr ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                </p>
                            :null}
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }}
                                fullWidth variant="outlined" disabled
                                name="countryCode" id="countryCode" label={"Country Code"}
                                value={currentForm === 'view' ? 
                                    countryDetails !== null && countryDetails.isoCountryCode && 
                                    countryDetails.isoCountryCode : countryState.isoCountryCode ? 
                                    countryState.isoCountryCode : ""
                                } 
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                fullWidth variant="outlined" disabled
                                name="countryCodeNo" id="countryCodeNo" label={"Country Code No."}
                                value={currentForm === 'view' ? 
                                    countryDetails !== null && countryDetails.isoCountryCodeNum && 
                                    countryDetails.isoCountryCodeNum : countryState.isoCountryCodeNum ? 
                                    countryState.isoCountryCodeNum : ""
                                } 
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} disabled
                                fullWidth variant="outlined" name="isoCurrencyCode" 
                                id="isoCurrencyCode" label={"Currency Code"}
                                value={currentForm === 'view' ? 
                                    countryDetails !== null && countryDetails.isoCurrencyCode && 
                                    countryDetails.isoCurrencyCode : countryState.isoCurrencyCode ? 
                                    countryState.isoCurrencyCode : ""
                                } 
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                fullWidth variant="outlined" disabled 
                                name="mobileRegexp" id="mobileRegexp" label={"Mobile Country Ext."}
                                value={currentForm === 'view' ? 
                                    countryDetails !== null && countryDetails.mobileRegexp && 
                                    countryDetails.mobileRegexp : countryState.mobileRegexp ? 
                                    countryState.mobileRegexp : ""
                                } 
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} disabled
                                fullWidth variant="outlined" name="phoneRegex" 
                                id="phoneRegex" label={"phoneRegex"}
                                value={currentForm === 'view' ? 
                                    countryDetails !== null && countryDetails.phoneRegex && 
                                    countryDetails.phoneRegex : countryState.phoneRegex ? 
                                    countryState.phoneRegex : ""
                                } 
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }}
                                fullWidth variant="outlined" disabled
                                name="postalCodeLength" id="postalCodeLength" label={"Postal Code Length"}
                                value={currentForm === 'view' ? 
                                    countryDetails !== null && countryDetails.postalCodeLength && 
                                    countryDetails.postalCodeLength : countryState.postalCodeLength ? 
                                    countryState.postalCodeLength : ""
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} disabled
                                fullWidth variant="outlined" name="minorUnit" 
                                id="minorUnit" label={"Minor Unit"}
                                value={currentForm === 'view' ? 
                                    countryDetails !== null && countryDetails.minorUnit && 
                                    countryDetails.minorUnit : countryState.minorUnit ? 
                                    countryState.minorUnit : ""
                                } 
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} disabled
                                fullWidth variant="outlined" name="timeZone" 
                                id="timeZone" label={"Time Zone"}
                                value={currentForm === 'view' ? 
                                    countryDetails !== null && countryDetails.timezoneConfig && 
                                    countryDetails.timezoneConfig.offset && countryDetails.timezoneConfig.offset
                                    : countryState.timezoneConfig && countryState.timezoneConfig.offset ? countryState.timezoneConfig.offset : ""
                                } 
                            />
                        </Grid>
                    </Grid>
                
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Button fullWidth variant="contained"
                                color="primary" className={"action_login_button"}
                                disabled={createDisableToken}
                                onClick={() => currentForm !== "view" ? handleCountryCreateUpdate() : onChangeView("list")}
                                >
                                {currentForm === "edit" ? "Modify" : currentForm === "view" ? "Close" : "Create"}
                                {countryCreateLoader ? <CircularProgress color="secondary" style={{
                                    "width": "25px", "height": "25px", "marginLeft": "6px", "color": "#fff"}}
                                /> : null}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </div>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({}, dispatch)
  }
}

function mapStateToProps(state) {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateEditViewCountry);
