import React from "react";
import {
  Checkbox, Paper, Table, TableCell,  Button,
  TableBody, TableContainer, TableHead, TableRow, InputAdornment, TextField
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import ExpandLessIcon from "@mui/icons-material/ExpandMore";
import ExpandMoreIcon from "@mui/icons-material/ArrowForwardIos";
import CircularProgress from '@mui/material/CircularProgress';

function MerchantStepper(props) {
    const [MCCfilter, setMCCFilter] = React.useState(null);
    const {
        classes, handleCcChange, TCC, handleExpandView, expansionId, 
        handleMCCfilter, disableToken, schemeDetail, TccLoader, bankTemplBlockedTccList, bankTemplBlockedMccList
    } = props;
    const DisableFlag = disableToken ? disableToken : false
    const TccLength = TCC && TCC.filter(obj => obj.mccList && obj.mccList.length > 0)
    
    const handleInputChange = (value) => {
        setMCCFilter(value)
        handleMCCfilter(value)
    }

    const handleKeyPress = (e) => {
        if(e.which === 13) {
            e.preventDefault();
            const elem = document.getElementById("searchForMCC");
            handleMCCfilter(elem.value);
        }
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.tablePaper}>
                <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                    aria-label="enhanced table"
                >
                    <TableHead className={classes.th}>
                        <TableRow>
                            <TableCell padding="checkbox">
                                
                            </TableCell>
                            <TableCell
                                key={"0"}
                                component="th" 
                                sortDirection={false}
                                className="cell_name" 
                                scope="row" padding="none"
                                id={`enhanced-table-checkbox-0`} 
                                style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "space-between", paddingRight: "15px" }} 
                            >
                                {"Select TCC & MCC To Block "}
                                <TextField 
                                    name="searchForMCC" variant="outlined"                           
                                    id="searchForMCC" label="Search for MCC"
                                    InputProps={{
                                        endAdornment: <Button 
                                            style={{height: "30px"}}
                                            onClick={() => {
                                                handleMCCfilter(MCCfilter)
                                            }}
                                        > <InputAdornment position="end"> <SearchIcon /> </InputAdornment> </Button>
                                    }}
                                    onKeyPress={(e) => handleKeyPress(e)}
                                    onChange={e => e.target.value.length < 50 && handleInputChange(e.target.value)}
                                />
                            </TableCell>
                            <TableCell ></TableCell>
                            <TableCell ></TableCell>
                            <TableCell ></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody >
                        {TccLoader && (
                            <TableRow style={{ height: 30 * 10 }}>
                                <TableCell colSpan={6} style={{paddingLeft: "47%"}}> 
                                    <CircularProgress color="secondary" style={{
                                    "width": "70px", "height": "70px"}}
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                        {!TccLoader && TccLength && TccLength.length === 0 && (
                            <TableRow style={{ height: 30 * 10 }}>
                                <TableCell colSpan={6} style={{paddingLeft: "47%"}}> 
                                    {"No Data Found!"}
                                </TableCell>
                            </TableRow>
                        )}
                        {!TccLoader && TCC.map((val, index) => {
                            return (val.mccList).length > 0 && <>
                                <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={false}
                                    tabIndex={-1}
                                    key={index}
                                    selected={false}
                                >
                                    <TableCell 
                                        padding="checkbox"
                                    >
                                        <Checkbox
                                            checked={!DisableFlag ? val.flag : schemeDetail && schemeDetail['blackListedTCCs'] && 
                                                schemeDetail['blackListedTCCs'] !== null && schemeDetail['blackListedTCCs'].includes(val.tccCode)
                                            }
                                            disabled={DisableFlag || bankTemplBlockedTccList.includes(val.tccCode)}
                                            onChange={() => handleCcChange("tcc", val)}
                                            inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-0` }}
                                        />
                                    </TableCell>
                                    <TableCell className="cell_name" style={{ cursor: "pointer" }} 
                                        component="th" 
                                        id={`enhanced-table-checkbox-0`} 
                                        scope="row" padding="none"
                                    >
                                        <span>{`${val.tccCode} - ${val.tccName}    `}</span>
                                    </TableCell>
                                    <TableCell >
                                        {`         Selected Mcc - ${!DisableFlag ? val.mccList.filter(mcc => mcc.flag).length
                                            : schemeDetail && schemeDetail['blackListedMCCs'] && schemeDetail['blackListedMCCs'] !== null && 
                                                val.mccList.filter(mcc => schemeDetail['blackListedMCCs'].includes(mcc.mccCode)).length
                                                    
                                        }`}
                                    </TableCell>
                                    <TableCell ></TableCell>
                                    <TableCell 
                                        onClick={() => handleExpandView(val.tccCode)}
                                    > 
                                        {expansionId === val.tccCode ? <ExpandLessIcon /> : 
                                            <ExpandMoreIcon className={classes.expandMoreIcon}/>
                                        }
                                    </TableCell>
                                </TableRow>
                                {expansionId !== null && expansionId === val.tccCode && val.mccList.map((mcc, i) => {
                                    return <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={false}
                                        tabIndex={-1}
                                        key={i}
                                        className={classes.mccbg}
                                        selected={false}
                                    >
                                        <TableCell > </TableCell>
                                        <TableCell className="cell_name" style={{ cursor: "pointer" }} 
                                            component="th" 
                                            id={`enhanced-table-checkbox-0`} 
                                            scope="row" padding="none"
                                        >
                                            <Checkbox
                                                checked={!DisableFlag ? mcc.flag : (schemeDetail && schemeDetail['blackListedMCCs'] && 
                                                    schemeDetail['blackListedMCCs'] !== null &&  schemeDetail['blackListedMCCs'].includes(mcc.mccCode))
                                                    || (schemeDetail && schemeDetail['blackListedTCCs'] && 
                                                    schemeDetail['blackListedTCCs'] !== null && schemeDetail['blackListedTCCs'].includes(val.tccCode))
                                                }
                                                className="mccCheckbox"
                                                disabled={DisableFlag || bankTemplBlockedTccList.includes(val.tccCode) || bankTemplBlockedMccList.includes(mcc.mccCode)}
                                                onChange={() => handleCcChange("mcc", {1: val, 2:mcc} )}
                                                inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-0` }}
                                            />
                                            {mcc.mccCode} - {mcc.mccName}
                                        </TableCell>
                                        <TableCell ></TableCell>
                                        <TableCell ></TableCell>
                                        <TableCell ></TableCell>
                                    </TableRow>
                                })}
                            </>
                        })}
                    </TableBody>

                </Table>
            </TableContainer>
        </Paper>
    </div>
    )
}

export default MerchantStepper;