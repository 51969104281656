import { ProductGroupActions } from "../consts/index";

const initialState = {
    productGroupLoader: false,
    productGroupList: null,
    prodctGroupResp: null,
    productList: null,
    schemeDetails: null,
}

export default (state = initialState, {type, payload}) => {
    switch(type) {
        case ProductGroupActions.fetchProductGroups.REQUEST:
            return {
                ...state,
                productGroupLoader: true,
            }
        case ProductGroupActions.fetchProductGroups.SUCCESS:
            return {
                ...state,
                productGroupList: payload,
                productGroupLoader: false,
            }
        case ProductGroupActions.fetchProductGroups.FAILURE:
            return {
                ...state,
                productGroupList: null,
                productGroupLoader: false,
            }

        case ProductGroupActions.createProductGroup.REQUEST: 
            return {
                ...state,
                productGroupLoader: true,
            }
        case ProductGroupActions.createProductGroup.SUCCESS: 
            return {
                ...state,
                prodctGroupResp: payload,
                productGroupLoader: false,
            }
        case ProductGroupActions.createProductGroup.FAILURE: 
            return {
                ...state,
                prodctGroupResp: payload,
                productGroupLoader: false,
            }

        case ProductGroupActions.updateProductGroup.REQUEST: 
            return {
                ...state,
                productGroupLoader: true,
            }
        case ProductGroupActions.updateProductGroup.SUCCESS: 
            return {
                ...state,
                prodctGroupResp: payload,
                productGroupLoader: false,
            }
        case ProductGroupActions.updateProductGroup.FAILURE: 
            return {
                ...state,
                prodctGroupResp: payload,
                productGroupLoader: false,
            }

        case ProductGroupActions.fetchProductsForBrand.REQUEST:
            return {
                ...state,
                productGroupLoader: true,
            }
        case ProductGroupActions.fetchProductsForBrand.SUCCESS:
            return {
                ...state,
                productList: payload,
                productGroupLoader: false,
            }
        case ProductGroupActions.fetchProductsForBrand.FAILURE:
            return {
                ...state,
                productList: null,
                productGroupLoader: false,
            }

        case ProductGroupActions.fetchSchemesByProductGroup.REQUEST:
            return {
                ...state,
                productGroupLoader: true,
            }
        case ProductGroupActions.fetchSchemesByProductGroup.SUCCESS:
            return {
                ...state,
                schemeDetails: payload,
                productGroupLoader: false,
            }
        case ProductGroupActions.fetchSchemesByProductGroup.FAILURE:
            return {
                ...state,
                schemeDetails: null,
                productGroupLoader: false,
            }

        default:
            return state;
    }
}