import React from 'react'
import { 
    Dialog, DialogActions, DialogTitle, DialogContentText, DialogContent, Button,
    Grid, TextField  
} from '@mui/material'
import { tss } from "tss-react";
import theme from '../../_theme'

import CloseImgIcon from '../../Assets/close.svg';

const useStyles = tss.create({
    color: {
        color: "white",
        backgroundColor: `#1BA785 !important`
    }
});

export default function DialogBox(props) {

    const {classes} = useStyles();
    const {
        openDialog, handleDialogAction, title, body, type, handleUserNameChange, userName
    } = props

    return (
        <Dialog
            open={openDialog}
            onClose={() => handleDialogAction("close")}
            className={"delete_dialog"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div className="close_dialog" onClick={() => handleDialogAction("close")}><img src={CloseImgIcon} alt="close" /></div>
            <DialogTitle id="alert-dialog-title">
                <h2>{title}</h2>
            </DialogTitle>
            <DialogContent dividers>
                {type === "unblockUser" &&
                <DialogContentText id="alert-dialog-description">
                    {body}
                </DialogContentText>}
                {type === "forgotPassword" &&
                <div>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                        <Grid item xs={12}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                required
                                fullWidth 
                                variant="outlined" 
                                name="userName" 
                                id="userName" 
                                label={"User Name"}
                                value={userName} 
                                onChange={e => e.target.value.length <= 40 && handleUserNameChange(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </div>}
            </DialogContent>
            <DialogActions style={{paddingBottom: "25px"}}>
                <Button color="primary" className="no"
                    onClick={() => handleDialogAction("close")} 
                >
                    Cancel
                </Button>
                <Button color="primary" className="yes" autoFocus
                    onClick={() => handleDialogAction(type)} 
                >
                    {type === "unblockUser" ? "Unblock" : "Submit"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}