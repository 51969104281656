import React, { useState, useEffect } from 'react'
import {connect} from "react-redux";
import Grid from '@mui/material/Grid';
import { bindActionCreators } from "redux";
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Snackbar, Dialog, DialogActions,
  DialogTitle, DialogContentText, DialogContent, Button } from '@mui/material'
import { tss } from "tss-react";
import theme from '../../_theme'

import TableComponent from "../table/index";
import {SpecialCharRegex} from "../../shared/utility";
import BasePageToolbar from '../../_common/basePageToolbar';
import BasePageContainer from '../../_common/basePageContainer';
import CreateEditViewTerminal from "./createEditViewTerminal/index";
import {fetchCountryDetails} from "../../manageScheme/actions/index";
import {fetchPSPMetadata, resetPSPMetadata} from "../psp/actions/index";
import {fetchStoreMetadata, resetStoreMetadata} from "../store/actions/index";
import {fetchMerchantMetadata, resetMerchantMetadata} from "../merchant/actions/index";
import {fetchTerminalDetails, createTerminal, resetTerminalCreateResp, 
  deleteTerminal, resetTerminalDeleteResp, fetchHardwareTypes} from "./actions/index";

const headCells = [
  { id: 'externalId', type: 'text', numeric: false, disablePadding: false, label: 'External Id' },
  { id: 'clientId', type: 'text', numeric: false, disablePadding: false, label: 'Client Id' },
  { id: 'hardwareSerialNumber', type: 'text', numeric: false, disablePadding: false, label: 'Hardware Serial Number' },
  { id: 'hardwareType', type: 'text', numeric: false, disablePadding: false, label: 'Hardware Type' },
  { id: 'countryName', type: 'text', numeric: true, disablePadding: false, label: 'Country Name' },
  { id: 'merchantName', type: 'text', numeric: false, disablePadding: false, label: 'Merchant Name' },
  { id: 'merchantUniqueId', type: 'text', numeric: false, disablePadding: false, label: 'Merchant ID' },
  { id: 'storeName', type: 'text', numeric: false, disablePadding: false, label: 'Store Name' },
  { id: 'status', type: 'text', numeric: true, disablePadding: false, label: 'Status' },
  { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];

const termObj = {
  "isoCountryCodeNum": "",
  "countryName" : "",
  "pspName": "",
  "pspRefId": "",
  "merchantId": "",
  "merchantName" : "",
  "merchantUniqueId": "",
  "storeId": "",
  "storeName" : "",
  "externalId": "",
  "hardwareSerialNumber": "",
  "hardwareType": "",
  "hardwareSubType": "",
  "status": "ACTIVE",
  "hardwareSubTypeId" : "",
  "isDCC": false,
}

const filterCells = [
  { id: 'isoCountryCodeNum', type: 'select', label: 'Country' },
  { id: 'pspId', type: 'select', label: 'Psp Name' },
  { id: 'merchantName', type: 'select', label: 'Merchant Name' },
  { id: 'storeName', type: 'select', label: 'Store Name' },
  { id: 'externalId', type: 'text', label: 'External Id' },
  { id: 'hardwareSerialnumber', type: 'text', label: 'Hardware Serial Number' },
];

const filterobj = {
  pspId: "",
  storeName: "",
  externalId: "",
  merchantName: "",
  isoCountryCodeNum: "",
  hardwareSerialnumber: "",
  pageNo: 1,
  pageSize: 10,
  sortBy:"createdOn",
  sortDirection:"DESC"
}

const sortArr = [
  {key:"createdOn", label: "Create Date"},
  {key:"countryName", label: "Country Name"},
  {key:"pspName", label: "Psp Name"},
  {key:"merchantName", label: "Merchant Name"},
  {key:"storeName", label: "Store Name"},
  // {key:"hardwareId", label: "hardware Serial No"},
  // {key:"externalId", label: "External Id"},
  {key:"status", label: "Status"},
]

const Terminal = (props) => {
  const {terminalList, countryDetails, pspMetaData, merchantMetadata, storeMetadata, 
    createTerResponse, terminalLoader, terminalListCount, deleteTerResponse, hardwareTypeList} = props;

  const {classes} = useStyles();
  const [openDelSnackbar, SetOpenDelSnackbar] = useState(false);
  const [openSnackbar, SetOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackBarMsg] = useState(null);
  const [currentForm, setCurrentForm] = useState("list");
  const [clientRow, setClientRow] = useState(null)
  const [responseId, setResponseId] = useState("");
  const [openPopover, setopenPopover] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState({});
  const [terminalState, setTerminalState] = useState(termObj)
  const currentPage = localStorage.getItem('currentPage')
  const [filterData, setFilterData] = useState([]);
  const [pageNo, setPage] = useState(1)
  const [sortName, setsortName] = useState('createdOn')
  const [sortDir, setsortDir] = useState('DESC');
  const [searchValue, setSearchValue] = useState("");
  const [compInitaited, setCompInitiated] = useState(false);

  let permissions = localStorage.getItem("permissions")
  const [filterState, setFilterState] = useState(filterobj)
  permissions = permissions && permissions !== null ? JSON.parse(permissions) : []
  const userid = localStorage.getItem("userid")

  const nextDisableToken = currentForm !== "view" && terminalState && terminalState.countryName !== "" &&
    terminalState.pspName !== "" && terminalState.merchantName !== "" && terminalState.storeName !== "" &&
    (terminalState.externalId !== "" && terminalState.externalId !== null && SpecialCharRegex.test(terminalState.externalId)) &&
    (terminalState.hardwareSerialNumber !== "" && terminalState.hardwareSerialNumber !== null && SpecialCharRegex.test(terminalState.hardwareSerialNumber)) &&
    terminalState.hardwareSubTypeId !== ""
    // (terminalState.hardwareType === "" || terminalState.hardwareType === null || 
    //   (terminalState.hardwareType !== "" && terminalState.hardwareType !== null && SpecialCharRegex.test(terminalState.hardwareType))) &&
    // (terminalState.hardwareSubType === "" || terminalState.hardwareSubType === null || 
    //   (terminalState.hardwareSubType !== "" && terminalState.hardwareSubType !== null && SpecialCharRegex.test(terminalState.hardwareSubType)))
  
  const terminalArray = terminalList && terminalList !== null ? 
    terminalList.map(obj => {

        let schemeObj = {
          ...obj,
          searchColumn: headCells.map((cell, index) => 
            cell.id ==="action" ? "" : obj[cell.id] && obj[cell.id]
          ).join(" ")
        }
        return schemeObj
    }) : []

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  });
  
  useEffect(() => {
    const handleInitialCall = async () => {
      await setCompInitiated(true);
      await props.fetchTerminalDetails({
        ...filterState,
        pageNo: pageNo, sortBy: sortName, sortDirection: sortDir
      })
      await localStorage.removeItem('currentPage')

      return () => {
        //add all list reset API here
        props.resetPSPMetadata()
        props.resetStoreMetadata()
        props.resetMerchantMetadata()
        // reset terminal create response
        props.resetTerminalCreateResp()
        props.resetTerminalDeleteResp()
      }
    }
    handleInitialCall()
  }, [])

  const handleFilterStateChange = (key, value) => {
    let tempFilter = {
        ...filterState,
        [key]: value
    }
    if(key === "isoCountryCodeNum") {
      tempFilter = {
        ...tempFilter,
        pspId: "",
        merchantName: "",
        storeName: ""
      }
    } else if(key === "pspId") {
      tempFilter = {
        ...tempFilter,
        merchantName: "",
        storeName: ""
      }
    } else if(key === "merchantName") {
      tempFilter = {
        ...tempFilter,
        storeName: ""
      }
    }
    setFilterState(tempFilter);
  }

  const resetFilter = () => {
    setFilterState(filterobj);
    setPage(1);
    setsortDir("DESC");
    setsortName("createdOn")
    props.fetchTerminalDetails({
      pageNo: 1,
      pageSize: 10,
      sortBy:"createdOn",
      sortDirection:"DESC"
    })
  }

  const FilterApply = () => {
    setPage(1)
    props.fetchTerminalDetails({ ...filterState, pageNo: 1, sortBy: sortName, sortDirection: sortDir})
    setFilterState({...filterState});
  }

  useEffect(() => {
    if(createTerResponse && createTerResponse !== null && createTerResponse.st) {
      setResponseId(createTerResponse.id)
      if(currentForm === "create" ) {
        setopenPopover(true)
      } else {
        SetOpenSnackbar(true)
        setSnackBarMsg(`Terminal Modified Successfully!`)
        onChangeView("list")
      }
      // reset terminal create response
      props.resetTerminalCreateResp()
    } else if(createTerResponse && createTerResponse !== null && !createTerResponse.st) {
      SetOpenSnackbar(true)
      setSnackBarMsg(createTerResponse.msg && createTerResponse.msg)
      // onChangeView("list")
    }
  }, [createTerResponse])

  useEffect(() => {
    if(deleteTerResponse && deleteTerResponse !== null && deleteTerResponse.st) {
      SetOpenDelSnackbar(true)
      setSnackBarMsg(`Terminal Deleted Successfully!`)
      onChangeView("list")
      // reset terminal create response

    } else if(deleteTerResponse && deleteTerResponse !== null && !deleteTerResponse.st) {
      SetOpenDelSnackbar(true)
      setSnackBarMsg(deleteTerResponse.msg && deleteTerResponse.msg)
      onChangeView("list")
    }
  }, [deleteTerResponse])

  const handleSnackBarAction = (type) => {
    if( type !== "delete") {
      SetOpenSnackbar(false)
      setSnackBarMsg(null)
      // reset terminal create response
      props.resetTerminalCreateResp()

    } else {
      SetOpenDelSnackbar(false)
      setSnackBarMsg(null)
      // reset terminal delete response
      props.resetTerminalDeleteResp()
    }
  }

  const handleInputChange = (key, value) => {

    let updatedTerminalState = {...terminalState};
    if(key === "country") {
      
      // adding country details & reseting other fields
      updatedTerminalState = {
        ...updatedTerminalState,
        storeId: "",
        pspName: "",
        pspRefId: "",
        storeName: "",
        merchantId: "",
        merchantName: "",
        merchantUniqueId: "",
        countryName: value[1],
        isoCountryCodeNum: value[0],
      }
      props.resetMerchantMetadata()
      props.resetStoreMetadata()

      //fetch PSP details
      props.fetchPSPMetadata({
        "isoCountryCodeNum": value[0],
        "status": "ACTIVE"
      })

    } else if(key === "psp") {

      //update psp and reset merchant and store
      updatedTerminalState = {
        ...updatedTerminalState,
        storeId: "",
        storeName: "",
        merchantId: "",
        merchantName: "",
        merchantUniqueId: "",
        pspName: value[1],
        pspRefId: value[0],
      }
      props.resetStoreMetadata()

      //fetch merchant details
      props.fetchMerchantMetadata({
        "pspId" : value[0],
        "status": "ACTIVE"
      })

    } else if(key === "merchant") {
      
      //update merchant and reset store
      updatedTerminalState = {
        ...updatedTerminalState,
        storeId: "",
        storeName: "",
        merchantId: value[0],
        merchantName: value[1],
        merchantUniqueId: value[2]
      }

      //fetch store details
      props.fetchStoreMetadata({
        "merchantId" : value[0],
        "status": "ACTIVE"
      })

    } else if(key === "store") {
      updatedTerminalState['storeId'] = value[0];
      updatedTerminalState['storeName'] = value[1];

    } else if(key === "hardwareSubTypeId") {
      updatedTerminalState['hardwareSubTypeId'] = value[0];
      updatedTerminalState['hardwareType'] = value[1];
      updatedTerminalState['hardwareSubType'] = value[2];
    } else {
      updatedTerminalState[key] = value[0];
    }

    setTerminalState(updatedTerminalState)
  }

  const onChangeView = (inpval, row) => {
    setCurrentForm(inpval === "delete" ? 'list' : inpval);
    if(inpval === "view") {
      setClientRow(row)
    } else if(inpval === "edit") {
      setTerminalState(row)
      handleMetaDataCall(row)

    } else if(inpval === "create") {
      setTerminalState(termObj)
      countryDetails && countryDetails.length === 0 && props.fetchCountryDetails({})
      hardwareTypeList && hardwareTypeList.length == 0 && props.fetchHardwareTypes({})

    } else if (inpval === "delete") {
      setOpenDelete(true)
      setDeleteId(row)

    } else {
      props.fetchTerminalDetails({...filterState,
        pageNo: 1, sortBy: sortName, sortDirection: sortDir
      })
    }
  }

  useEffect(() => {
    if(currentPage && currentPage !== null && currentPage !== undefined && currentPage === "/terminal" && compInitaited){
      setCurrentForm('list')
      setFilterState({...filterobj});
      setPage(1);
      setsortDir("DESC");
      setsortName("createdOn")
      props.fetchTerminalDetails({
        pageNo: 1,
        pageSize: 10,
        sortBy:"createdOn",
        sortDirection:"DESC"
      })
      localStorage.removeItem('currentPage')
    }
  }, [currentPage])

  const handleMetaDataCall = (row) => {
    countryDetails && countryDetails.length === 0 && props.fetchCountryDetails({})
    hardwareTypeList && hardwareTypeList.length == 0 && props.fetchHardwareTypes({})
  }

  const handleTerminalCreateUpdate = () => {
    let req = {
      // "id" : null,
      "mobileNoList": "",
      "emailIdList": "",
      "terminalHash": "",
      "terminalUserList":null,
      "terminalParameters": {
        "key": "",
        "key2": ""
        },
        ...terminalState,
        "userId": JSON.parse(userid),
    }
    props.createTerminal(req)
    setFilterState(filterobj);
    setSearchValue("")
  }

  const handleDialogAction = async (type) => {
    setopenPopover(false)
    setTerminalState(termObj)
    if(type === "exit" ){
      onChangeView('list')
    } else {
      await onChangeView('list')
      await onChangeView('create')
    }
  }

  const handleDeleteDialogAction = (type) => {
    setOpenDelete(false)
    if(type === "exit" ){
      onChangeView('list')
    } else {
      //call API Here
      props.deleteTerminal({ id: deleteId.id })
    }
  }
  
  const handleSetFilterData = (filterArray, searchValue) => {
    setFilterData(filterArray)
    setSearchValue(searchValue)
  }

  const handlePageNo = (val) => {
    setPage(val)
    props.fetchTerminalDetails({...filterState,
      pageNo: val, sortBy: sortName, sortDirection: sortDir
    });
  }

  const handleSortDir = (value) => {
    setsortDir(value)
    setPage(1)
  }

  const handleSortName = (value) => {
    setsortName(value)
    setPage(1)
  }

  return (
    <>
      <BasePageToolbar 
        currentForm={currentForm}  create={permissions.includes("22")} onChangeView={onChangeView}  resetFilter={resetFilter}
        title={currentForm === 'list' ? "Terminal Detail" : currentForm === 'view' ? "View Terminal" : currentForm === 'edit' ? "Modify Terminal" : "Create Terminal"}
        handleFilterStateChange={handleFilterStateChange} filterState={filterState} filterCells={filterCells} FilterApply={FilterApply}
        setsortName={handleSortName} setsortDir={handleSortDir} sortDir={sortDir} sortName={sortName} sortArr={sortArr}
        arrayToFilter={terminalArray} handleSetFilterData={handleSetFilterData} isTableSearchReq={false} searchValue={searchValue}
      ></BasePageToolbar>
        <BasePageContainer>
          {currentForm === 'list' ?
          <Grid container className={classes.container}>
            <TableComponent
              sortOrder="asc"
              sortOrderBy="id"
              showEdit={permissions.includes("23")}
              showDelete={permissions.includes("66")}
              // rows={terminalList}
              rows={searchValue && searchValue !== "" ? filterData : terminalArray}
              headCells={headCells}
              activeTab={filterState}
              loading={terminalLoader}
              totalRecord={terminalListCount}
              handlePageNo={handlePageNo}
              requestType="TerminalList"
              onChangeView={onChangeView}
            />
          </Grid>
          : currentForm === 'create' ?
            <Grid container className={classes.formContainer}>
              <CreateEditViewTerminal 
                PSPList={pspMetaData}
                StoreList={storeMetadata}
                currentForm={currentForm}
                terminalState={terminalState}
                countryDetails={countryDetails}
                merchantList={merchantMetadata}
                nextDisableToken={!nextDisableToken}
                handleInputChange={handleInputChange}
                handleTerminalCreateUpdate={handleTerminalCreateUpdate}
                hardwareTypeList = {hardwareTypeList}
              />
            </Grid>
            : currentForm === 'edit' ? <Grid container className={classes.formContainer}>
                <CreateEditViewTerminal 
                  PSPList={pspMetaData}
                  StoreList={storeMetadata}
                  currentForm={currentForm}
                  terminalState={terminalState}
                  countryDetails={countryDetails}
                  merchantList={merchantMetadata}
                  nextDisableToken={!nextDisableToken}
                  handleInputChange={handleInputChange}
                  handleTerminalCreateUpdate={handleTerminalCreateUpdate}
                  hardwareTypeList = {hardwareTypeList}
                />
              </Grid>
              : <Grid container className={classes.formContainer}>
                  <CreateEditViewTerminal
                    currentForm={currentForm} 
                    productDetails={clientRow}
                    onChangeView={onChangeView}
                  />
              </Grid>
          }
          <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={openSnackbar}
            autoHideDuration={4000}
            onClose={() => handleSnackBarAction()}
            action={
            <React.Fragment>
              <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
            }
          >
            <Alert onClose={() => handleSnackBarAction()} severity={
              createTerResponse && createTerResponse !== null && !createTerResponse.st ? "info" : "success"
            }>
              {snackbarMsg}
            </Alert>
          </Snackbar>
          <Dialog
            open={openPopover}
            onClose={() => handleDialogAction("exit")}
            className={"delete_dialog"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <h2>{`Terminal ${responseId} Created Successfully!`}</h2>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`Do you want to Create new Terminal?`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className={classes.color}
                  onClick={() => handleDialogAction("create")} 
              >
                Create New
              </Button>
              <Button className={classes.color}
                  onClick={() => handleDialogAction("exit")} 
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openDelete}
            onClose={() => handleDeleteDialogAction("exit")}
            className={"delete_dialog"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <h2>{`Terminal ${deleteId && deleteId.hardwareSerialNumber ? deleteId.hardwareSerialNumber : ""} Delete Request!`}</h2>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`Do you want to Delete this Terminal?`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className={classes.color}
                  onClick={() => handleDeleteDialogAction("delete")} 
              >
                Delete
              </Button>
              <Button className={classes.color}
                  onClick={() => handleDeleteDialogAction("exit")} 
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={openDelSnackbar}
            autoHideDuration={4000}
            onClose={() => handleSnackBarAction("delete")}
            action={
            <React.Fragment>
              <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction("delete")}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
            }
          >
            <Alert onClose={() => handleSnackBarAction("delete")} severity={
              deleteTerResponse && deleteTerResponse !== null && !deleteTerResponse.st ? "info" : "success"
            }>
              {snackbarMsg}
            </Alert>
          </Snackbar>
        </BasePageContainer>
    </>)
}
const useStyles = tss.create({
  formContainer: {
    paddingTop: theme.spacing(0),
  },
  container: {
    paddingTop: theme.spacing(2),
  },
  color: {
    color: "white",
    backgroundColor: `#1BA785 !important`
  },
});
// export default BrandScheme

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
        fetchTerminalDetails, fetchPSPMetadata,
        fetchMerchantMetadata, fetchStoreMetadata,
        fetchCountryDetails, resetPSPMetadata,
        resetStoreMetadata, resetMerchantMetadata,
        createTerminal, resetTerminalCreateResp,
        deleteTerminal, resetTerminalDeleteResp,
        fetchHardwareTypes
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    terminalList: state.TerminalReducer.terminalList !== null &&
      state.TerminalReducer.terminalList.terminals
        ? state.TerminalReducer.terminalList.terminals : [],
    countryDetails: state.SchemeReducer.countryDetails !== null &&
      state.SchemeReducer.countryDetails.countryList ?
        state.SchemeReducer.countryDetails.countryList : [],
    pspMetaData: state.PspReducer.pspMetaData !== null && state.PspReducer.pspMetaData.pspList ?
      state.PspReducer.pspMetaData.pspList : [],
    terminalListCount: state.TerminalReducer.terminalList !== null && state.TerminalReducer.terminalList.totalRecords ?
      state.TerminalReducer.terminalList.totalRecords : 0,
    merchantMetadata: state.MerchantReducer.merchantMetadata !== null &&
      state.MerchantReducer.merchantMetadata.merchantList ?
        state.MerchantReducer.merchantMetadata.merchantList : [],
    storeMetadata: state.StoreReducer.storeMetadata !== null &&
      state.StoreReducer.storeMetadata.storeList ? 
        state.StoreReducer.storeMetadata.storeList : [],
    createTerResponse: state.TerminalReducer.createTerResponse,
    deleteTerResponse: state.TerminalReducer.deleteTerResponse,
    terminalLoader: state.TerminalReducer.terminalLoader,
    hardwareTypeList: state.TerminalReducer.hardwareTypeList !== null &&
    state.TerminalReducer.hardwareTypeList.hardwareTypeList ?
      state.TerminalReducer.hardwareTypeList.hardwareTypeList : []
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Terminal);
