import { actionCreator, BASE_URL } from "../../../shared/utility";
import { BrandActions } from "../consts/index";

export function handleCountrySelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(BrandActions.handleCountryChangeForCreateBrand.SUCCESS, payload))
    }
}

export function handleBrandNameSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(BrandActions.handleBrandNameChangeForCreateBrand.SUCCESS, payload))
    }
}

export function handleDisplayNameSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(BrandActions.handleDisplayBrandNameChangeForCreateBrand.SUCCESS, payload))
    }
}

export function handleDescriptionSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(BrandActions.handleDescriptionChangeForCreateBrand.SUCCESS, payload))
    }
}

export function handleIsSerialNumberRequiredCheck(payload) {
    return(dispatch) => {
        dispatch(actionCreator(BrandActions.handleIsSerialNumberRequiredCheckForCreateBrand.SUCCESS, payload))
    }
}

export function handleScreenTypeSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(BrandActions.handleScreenTypeChangeForCreateBrand.SUCCESS, payload))
    }
}

export function fetchBrandDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(BrandActions.handleBrandDetailsFetch.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/getBrandlist`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
        .then(response => response.json())
        .then(function (res) {
            if(!res.st) {
                if(res.responseCode && res.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(BrandActions.handleBrandDetailsFetch.FAILURE));
                }
            } else {
                dispatch(actionCreator(BrandActions.handleBrandDetailsFetch.SUCCESS, res))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchIndividualBrandDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(BrandActions.handleIndividualBrandDetailsFetchForBrand.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/getBrandlist`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
          .then(response => response.json())
          .then(function (res) {
            if(!res.st) {
                if(res.responseCode && res.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(BrandActions.handleIndividualBrandDetailsFetchForBrand.FAILURE));
                }
            } else {
                dispatch(actionCreator(BrandActions.handleIndividualBrandDetailsFetchForBrand.SUCCESS, res));
            }
          })
          .catch(function (error) {
            console.log("BrandActions.handleIndividualBrandDetailsFetchForBrand.FAILURE >>", error);
          });
    }
}

export function resetIndividualBrand() {
    return (dispatch) => {
        dispatch(actionCreator(BrandActions.handleIndividualBrandDetailsFetchForBrand.FAILURE))
    }
}

export function fetchCountryDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(BrandActions.handleCountryDetailsFetchForBrand.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/getCountryListForScheme`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(BrandActions.handleCountryDetailsFetchForBrand.FAILURE))
                }
            } else {
                dispatch(actionCreator(BrandActions.handleCountryDetailsFetchForBrand.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchScreenTypeDetails(payload) {
    return(dispatch,getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(BrandActions.handleScreenTypeDetailsFetchForBrand.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/getCountryListForScheme`, {
            method: 'GET',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(BrandActions.handleScreenTypeDetailsFetchForBrand.FAILURE))
                }
            } else {
                dispatch(actionCreator(BrandActions.handleScreenTypeDetailsFetchForBrand.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function submitCreateOrEditBrandForm(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(BrandActions.handleCreateOrEditBrandSubmit.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/addOrEditBrand`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(BrandActions.handleCreateOrEditBrandSubmit.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(BrandActions.handleCreateOrEditBrandSubmit.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function clearBrandRes(payload) {
    return(dispatch) => {
        dispatch(actionCreator(BrandActions.handleCreateOrEditBrandSubmit.SUCCESS, payload))
    }
}

export function uploadBrandDocument(payload, type) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(BrandActions.uploadDocumentForBrand.REQUEST, {key: type}))
        fetch(`${BASE_URL}/oem/api/data/web/editBrandImage`, {
            method: 'POST',
            body: payload, //JSON.stringify(payload),
            headers: {
                // 'Content-Type':'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(BrandActions.uploadDocumentForBrand.FAILURE, {...response, key: type}))
                }
            } else {
                dispatch(actionCreator(BrandActions.uploadDocumentForBrand.SUCCESS,  {...response, key: type}))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetUploadDocumentResp() {
    return(dispatch) => {
        dispatch(actionCreator(BrandActions.uploadDocumentForBrand.FAILURE, null))
    }
}


