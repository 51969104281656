import { AuthenticationActions } from "../consts/index";

const initialState = {
    userName: null,
    captchaImg: null,
    forPassVal: null,
    authLoader: false,
    authResponse: null,
    refreshToken: null,
    validateUserResponse: null,
    resetPasswordRes: null,
    unblockUserResp: null
}

export default (state = initialState, {type, payload}) => {
    switch(type) {
        case AuthenticationActions.handleUserLogin.REQUEST:
            return {
                ...state,
                authLoader: true
            }
        case AuthenticationActions.handleUserLogin.SUCCESS:
            return {
                ...state,
                authLoader: false,
                authResponse: payload,
            }
        case AuthenticationActions.handleUserLogin.FAILURE:
            return {
                ...state,
                authLoader: false,
                authResponse: payload,
            }

        case AuthenticationActions.handleRefreshToken.SUCCESS:
            return {
                ...state,
                refreshToken: payload
            }

        case AuthenticationActions.handleValidateUser.REQUEST:
            return {
                ...state
            }
        case AuthenticationActions.handleValidateUser.SUCCESS:
            return {
                ...state,
                validateUserResponse: payload,
            }
        case AuthenticationActions.handleValidateUser.FAILURE:
            return {
                ...state,
                validateUserResponse: payload,
            }

        case AuthenticationActions.handleResetPasswordForForgotPassword.REQUEST:
            return {
                ...state,
            }
        case AuthenticationActions.handleResetPasswordForForgotPassword.SUCCESS:
            return {
                ...state,
                resetPasswordRes: payload,
            }
        case AuthenticationActions.handleResetPasswordForForgotPassword.FAILURE:
            return {
                ...state,
                resetPasswordRes: payload,
            }

        case AuthenticationActions.handleFetchUserNameById.REQUEST:
            return {
                ...state,
            }
        case AuthenticationActions.handleFetchUserNameById.SUCCESS:
            return {
                ...state,
                userName: payload,
            }
        case AuthenticationActions.handleFetchUserNameById.FAILURE:
            return {
                ...state,
                userName: payload,
            }
        
        case AuthenticationActions.handleValForgotPassToken.REQUEST:
            return {
                ...state,
            }
        case AuthenticationActions.handleValForgotPassToken.SUCCESS:
            return {
                ...state,
                forPassVal: payload,
            }
        case AuthenticationActions.handleValForgotPassToken.FAILURE:
            return {
                ...state,
                forPassVal: payload,
            }
        
        case AuthenticationActions.fetchCaptchaForLogin.REQUEST:
            return {
                ...state,
            }
        case AuthenticationActions.fetchCaptchaForLogin.SUCCESS:
            return {
                ...state,
                captchaImg: payload,
            }
        case AuthenticationActions.fetchCaptchaForLogin.FAILURE:
            return {
                ...state,
                captchaImg: payload,
            }

        case AuthenticationActions.unblockUserRequest.REQUEST:
            return {
                ...state,
            }
        case AuthenticationActions.unblockUserRequest.SUCCESS:
            return {
                ...state,
                unblockUserResp: payload,
            }
        case AuthenticationActions.unblockUserRequest.FAILURE:
            return {
                ...state,
                unblockUserResp: payload,
            }

        default:
            return state;
    }
}