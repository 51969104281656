import React from 'react'
import PropTypes from 'prop-types'

import { tss } from "tss-react";
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import IconMenu from '@mui/icons-material/Menu' 
import HeaderProfile from './appHeaderProfile' 
import { withRouter } from "react-router-dom";
const AppHeader = ({ onToggleClick }) => {
  const {classes, cx} = useStyles()
  return (
    <AppBar position="static" className={classes.header}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="Toggle sidebar"
          onClick={onToggleClick}
          className={cx(classes.menuButton)}
        >
          <IconMenu />
        </IconButton> 
        <div className={classes.actions}> 
          <HeaderProfile />
        </div>
      </Toolbar>
    </AppBar>
  )
}

AppHeader.propTypes = {
  onToggleClick: PropTypes.func,
}

const useStyles = tss.create({
  header: {
    background: '#fff',
    color: '#7b7b7b',
    boxShadow: 'none',
  },
  toolbar: {},
  menuButton: {},
  actions: {
    marginLeft: 'auto',
    alignItems: 'center',
    display: 'flex',
  },
  notificationsButton: {
    marginRight: 23,
  },
  title: {
    flexGrow: 1,
  },
});

export default withRouter(AppHeader); 
