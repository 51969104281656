import React, {useEffect, useState} from "react";
import {Grid
//     Dialog, DialogActions, DialogTitle, DialogContent,
//   DialogContentText, IconButton, Snackbar 
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { Snackbar, IconButton } from '@mui/material'
import { tss } from "tss-react";
import theme from '../_theme'
import {connect} from "react-redux";
import { bindActionCreators } from "redux";

import TableComponent from "./table/index";
import {fetchClientLogDetails, addOrEditClientLogMapping,
    resetCreateLogRes, resetDirectoryPathRes} from "./actions";
import BasePageToolbar from '../_common/basePageToolbar';
import BasePageContainer from '../_common/basePageContainer';
import CreateEditViewClientLog from "./createEditViewClientLog/index";
import {numbRegex, timeRegex} from "../shared/utility";

const headCells = [
    { id: 'clientId', type: 'text', numeric: false, disablePadding: false, label: 'Client ID' },
    { id: 'levelName', type: 'text', numeric: false, disablePadding: false, label: 'Level Name' },
    { id: 'levelId', type: 'text', numeric: false, disablePadding: false, label: 'Level ID' },
    { id: 'enableLog', type: 'boolean', numeric: false, disablePadding: false, label: 'Logs Enable' },
    { id: 'uploadFrequency', type: 'text', numeric: false, disablePadding: false, label: 'Upload Frequency'},
    { id: 'directoryPath', type: 'text', numeric: false, disablePadding: false, label: 'Directory Path'},
    { id: 'isBlacklist', type: 'boolean', numeric: false, disablePadding: false, label: 'Black Listed'},
    { id: 'createdOn', type: 'createdOn', numeric: false, disablePadding: false, label: 'Created On' },
    { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];

const filterobj = {
    "id": "",
    "clientId": "",
    "levelId": "",
    "pageSize": 10,
    "pageNo": 1,
    "sortBy": "createdOn",
    "sortDirection": "DESC",
};

const filterCells = [
    { id: 'clientId', type: 'text', label: 'Client ID' },
    { id: 'levelId', type: "text", label: "Level ID"},
];

const sortArr = [
    {key:"createdOn", label: "Create Date"},
    {key:"clientId", label: "Client ID"},
    {key:"levelName", label: "Level Name"},
];

const clientLogObj = {
    "id": "",
    "clientId": "",
    "enableLog": true,
    "levelId": "",
    "levelName": "",
    "retentionDays": "",
    "retentionSize": "",
    "uploadFrequency": "",
    "uploadFailureRetryCount": "",
    "uploadFailureRetryInterval": "",
    "uploadStartTime": "",
    "directoryPath": "",
    "enableShipping": false,
    "maxFileSize": "",
    "maxFileCount": "",
    "isBlacklist": false,
    "enableCascadingToStore": false,
    "enableCascadingToMerchant": false,
    "blackListedStartTime": "",
    "blackListedEndTime": "",
    "enableFileEncryption": false,
    "fileMoveFrequency": ""
}

const PaxConfiguration = (props) => {
    const {classes} = useStyles();
    const {clientLogList, clientLogLoader, totalRecords, createClientLogRes } = props;

    const [sortDir, setsortDir] = useState('DESC');
    const [snackbarMsg, setSnackBarMsg] = useState(null);
    const [sortName, setsortName] = useState('createdOn');
    const [currentForm, setCurrentForm] = useState("list");
    const [openSnackbar, SetOpenSnackbar] = useState(false);
    const [filterState, setFilterState] = useState(filterobj);
    const [compInitaited, setCompInitiated] = useState(false);
    const [clientLogState, setClientLogState] = useState(clientLogObj);

    const createButtonFlag = currentForm !== "view" && clientLogState && clientLogState.clientId !== "" &&
        clientLogState.levelName !== "" && clientLogState.retentionDays !== "" && clientLogState.retentionSize !== ""
        && numbRegex.test(clientLogState.retentionSize) && clientLogState.uploadFrequency !== "" && 
        numbRegex.test(clientLogState.uploadFrequency) && clientLogState.uploadFailureRetryCount !== "" && 
        numbRegex.test(clientLogState.uploadFailureRetryCount) && clientLogState.uploadFailureRetryInterval !== "" &&
        numbRegex.test(clientLogState.uploadFailureRetryInterval) && clientLogState.uploadStartTime !== "" && timeRegex.test(clientLogState.uploadStartTime) && clientLogState.directoryPath !== "" 
        && clientLogState.maxFileSize !== "" && numbRegex.test(clientLogState.maxFileSize) && clientLogState.maxFileCount !== "" 
        && numbRegex.test(clientLogState.maxFileCount) && 
        (!clientLogState.isBlacklist || (clientLogState.isBlacklist && clientLogState.blackListedStartTime !== "" && clientLogState.blackListedEndTime !== "")) &&
        clientLogState.fileMoveFrequency !== "" && numbRegex.test(clientLogState.fileMoveFrequency)
    
    let permissions = localStorage.getItem("permissions")
    permissions = permissions && permissions !== null ? JSON.parse(permissions) : []

    const currentPage = localStorage.getItem('currentPage');

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    });
    
    useEffect(() => {
        const handleInitialCall = async () => {
            await setCompInitiated(true);
            await props.fetchClientLogDetails({...filterState})
            await localStorage.removeItem('currentPage')
        }
        handleInitialCall();
    }, [])

    useEffect(() => {
        if(currentPage && currentPage !== null && currentPage !== undefined && currentPage === "/paxconfig" && compInitaited){
            setCurrentForm('list')
            setFilterState({...filterobj});
            setsortDir("DESC");
            setsortName("createdOn")
            props.fetchClientLogDetails({...filterobj})
            localStorage.removeItem('currentPage')
        }
    }, [currentPage])

    useEffect(() => {
        if(createClientLogRes && createClientLogRes !== null && createClientLogRes.st) {
            SetOpenSnackbar(true)
            setSnackBarMsg("Client Log Mapping Record added Successfully!")
            props.resetCreateLogRes()
        }
    }, [createClientLogRes])

    const onChangeView = (inpval, row) => {
        if(inpval === "view") {
            setClientLogState({...row})
    
        } else if(inpval === "edit") {
            setClientLogState({...row})
    
        } else if(inpval === "create") {
            setClientLogState(clientLogObj)
            props.resetDirectoryPathRes()
        
        }  else if (inpval === "list") {
          props.fetchClientLogDetails({...filterState, 
            pageNo: 1, sortBy: sortName, sortDirection: sortDir
          })
    
        }
        setCurrentForm(inpval);
    }

    const handlePageNo = (val) => {
        const payloadObj = { ...filterState, pageNo: val, sortBy: sortName, sortDirection: sortDir}
        props.fetchClientLogDetails(payloadObj)
    }

    const resetFilter = () => {
        setFilterState(filterobj);
        setsortDir("DESC");
        setsortName("createdOn");
        const payloadObj = {
            pageNo: 1,
            pageSize: 10,
            sortBy:"createdOn",
            sortDirection:"DESC"
        }
        props.fetchClientLogDetails(payloadObj)
    }

    const handleFilterStateChange = (key, value) => {
        let tempFilter = {
            ...filterState,
            [key]: value
        }
        setFilterState(tempFilter);
    }

    const FilterApply = () => {
        const payloadObj = { ...filterState, pageNo: 1, sortBy: sortName, sortDirection: sortDir }
        props.fetchClientLogDetails(payloadObj)
        setFilterState({...filterState});
    }

    const handleSortDir = (value) => {
        setsortDir(value)
    }

    const handleSortName = (value) => {
        setsortName(value)
    }

    const handleInputChange = (key, value) => {
        let tempclientLogState = {...clientLogState};
        if(key === "levelName"){
            tempclientLogState = {
                ...tempclientLogState,
                [key]: value.name,
                levelId: value.id
            }
        } else if(key === "isBlacklist"){
            tempclientLogState = {
                ...tempclientLogState,
                [key]: value,
                blackListedStartTime: "",
                blackListedEndTime: "",
            }
        } else {
            tempclientLogState = {
                ...tempclientLogState,
                [key]: value,
            }
        }

        setClientLogState(tempclientLogState)
    }

    const createClientLog = () => {
        props.addOrEditClientLogMapping(clientLogState)
    }

    const handleSnackBarAction = () => {
        props.resetCreateLogRes()
        SetOpenSnackbar(false)
        setSnackBarMsg(null)
    }

    return( <>
        <BasePageToolbar currentForm={currentForm} create={permissions.includes("56")} onChangeView={onChangeView} resetFilter={resetFilter}
            title={currentForm === 'list' ? "PAX Configuration" :  currentForm === 'view' ? "View PAX Config" : currentForm === "edit" ? "Modify PAX Config" : "Create PAX Config"}
            handleFilterStateChange={handleFilterStateChange} filterState={filterState} filterCells={filterCells} FilterApply={FilterApply}
            setsortName={handleSortName} setsortDir={handleSortDir} sortDir={sortDir} sortName={sortName} sortArr={sortArr} isTableSearchReq={false}
        ></BasePageToolbar>
        <BasePageContainer>
            {currentForm === 'list' ?
                <Grid container className={classes.container}>
                <TableComponent
                    showEdit={permissions.includes("57")}
                    sortOrder="desc" sortOrderBy="createdOn"
                    loading={clientLogLoader} headCells={headCells} 
                    onChangeView={onChangeView} handlePageNo={handlePageNo}
                    activeTab={filterState} totalRecord={totalRecords}
                    rows={clientLogList}
                />
                </Grid>
                : currentForm === 'create' ?
                <Grid container className={classes.formContainer}>
                    <CreateEditViewClientLog 
                        currentForm={currentForm}
                        onChangeView={onChangeView}
                        clientLogState={clientLogState}
                        createClientLog={createClientLog}
                        createButtonFlag={!createButtonFlag}
                        handleInputChange={handleInputChange}
                    />
                </Grid>
                : currentForm === "edit" ?
                <Grid container className={classes.formContainer}>
                    <CreateEditViewClientLog 
                        currentForm={currentForm}
                        onChangeView={onChangeView}
                        clientLogState={clientLogState}
                        createClientLog={createClientLog}
                        createButtonFlag={!createButtonFlag}
                        handleInputChange={handleInputChange}
                    />
                </Grid> :
                <Grid container className={classes.formContainer}>
                    <CreateEditViewClientLog 
                        currentForm={currentForm}
                        onChangeView={onChangeView}
                        clientLogState={clientLogState}
                        createClientLog={createClientLog}
                        handleInputChange={handleInputChange}
                    />
                </Grid>
            }
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => handleSnackBarAction()}
                action={
                    <React.Fragment>
                        <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            >
                <Alert onClose={() => handleSnackBarAction()} severity={
                    createClientLogRes && createClientLogRes !== null && !createClientLogRes.st ? "error" : "success"
                }>
                    {snackbarMsg}
                </Alert>
            </Snackbar>
        </BasePageContainer>
    </>)
}

const useStyles = tss.create({
    formContainer: {
        paddingTop: theme.spacing(0),
    },
    container: {
        paddingTop: theme.spacing(2),
    },
});
  
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            fetchClientLogDetails, addOrEditClientLogMapping,
            resetCreateLogRes, resetDirectoryPathRes
        }, dispatch)
    }
}
  
function mapStateToProps(state) {
    return {
        clientLogLoader: state.ClientLogReducer.clientLogLoader,
        clientLogList: state.ClientLogReducer.clientLogList !== null 
            && state.ClientLogReducer.clientLogList.clientLogMapping 
                ? state.ClientLogReducer.clientLogList.clientLogMapping : [],
        totalRecords: state.ClientLogReducer.clientLogList !== null 
            && state.ClientLogReducer.clientLogList.totalRecords 
                ? state.ClientLogReducer.clientLogList.totalRecords : 0,
        createClientLogRes: state.ClientLogReducer.createClientLogRes,
    }   
}
  
export default connect(
mapStateToProps,
mapDispatchToProps
)(PaxConfiguration);