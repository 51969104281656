import React, { useState, useEffect } from 'react'
import format from "date-fns/format";
import {connect} from "react-redux";
import { bindActionCreators } from "redux";
import {
    Grid, Tabs, Tab, Dialog, DialogTitle, DialogContent, 
    DialogContentText, DialogActions, Button, IconButton, Snackbar, TextField
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../_theme'
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import CloseImgIcon from '../Assets/close.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import BasePageContainer from '../_common/basePageContainer'
import BasePageToolbar from '../_common/basePageToolbar'
import { 
    approveCIBPendingBankScheme, removeApproveResponse, createBankScheme, 
    resetBankSchemeResp, fetchBankSchemes, resetSchemeListResponse
} from "./actions";
import TableComponent from "./table"
import CreateEditViewScheme from "./createEditViewScheme/index"
import { installmentDescRegex } from "shared/utility";

const pendingHeadCells = [
    { id: 'requestID', type: 'number', numeric: true, disablePadding: false, label: 'Request ID' },
    { id: 'installmentConfigID', type: 'number', numeric: true, disablePadding: false, label: 'Installment Config ID' },
    { id: 'mciID', type: 'number', numeric: true, disablePadding: false, label: 'Issuer ID' },
    { id: 'issuerName', type: 'text', numeric: false, disablePadding: false, label: 'Issuer Name' },
    { id: 'installmentConfigDesc', type: 'description', numeric: false, disablePadding: false, label: 'Installment Desc' },
    { id: 'productType', type: 'text', numeric: false, disablePadding: false, label: 'Product Type' },
    { id: 'installmentStartDate', type: 'date', numeric: false, disablePadding: false, label: 'Start Date' },
    { id: 'installmentEndDate', type: 'date', numeric: false, disablePadding: false, label: 'End Date' },
    { id: 'statusCode', type: 'statusCode', numeric: false, disablePadding: false, label: 'Status Code' },
    { id: 'createdOn', type: 'createdOn', numeric: false, disablePadding: false, label: 'Created On' },
    { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];

const approvedHeadCells = [
    { id: 'requestID', type: 'number', numeric: true, disablePadding: false, label: 'Request ID' },
    { id: 'installmentConfigID', type: 'number', numeric: true, disablePadding: false, label: 'Installment Config ID' },
    { id: 'mciID', type: 'number', numeric: true, disablePadding: false, label: 'Issuer ID' },
    { id: 'issuerName', type: 'text', numeric: false, disablePadding: false, label: 'Issuer Name' },
    { id: 'installmentConfigDesc', type: 'description', numeric: false, disablePadding: false, label: 'Installment Desc' },
    { id: 'productType', type: 'text', numeric: false, disablePadding: false, label: 'Product Type' },
    { id: 'installmentStartDate', type: 'date', numeric: false, disablePadding: false, label: 'Start Date' },
    { id: 'installmentEndDate', type: 'date', numeric: false, disablePadding: false, label: 'End Date' },
    { id: 'statusCode', type: 'statusCode', numeric: false, disablePadding: false, label: 'Status Code' },
    { id: 'createdOn', type: 'createdOn', numeric: false, disablePadding: false, label: 'Created On' },
    { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];

const filterCells = [
    { id: 'requestId', type: 'text', label: 'Request Id' },
    { id: 'installmentConfigId', type: 'text', label: 'Installment Config Id' },
    { id: 'installmentConfigDesc', type: 'text', label: 'Installment Config Desc' },
    { id: 'mciId', type: 'select', label: 'issuer' },
    { id: 'productType', type: 'select', label: 'Product Type' },
    { id: 'installmentStartDate', type: 'date', label: 'Installment Start Date' },
    { id: 'installmentEndDate', type: 'date', label: 'Installment End Date' },
  ];
  
const filterobj = {
    mciId: "",
    pageNo: 1,
    pageSize: 10,
    requestId: "",
    productType: "",
    sortBy:"createdOn",
    sortDirection:"DESC",
    isoCountryCodeNum: "",
    installmentConfigId: "",
    installmentEndDate: null,
    installmentStartDate: null,
    installmentConfigDesc: "",
}

const sortArr = [
    {key:"createdOn", label: "Create Date"},
    {key:"requestID", label: "Request Id"},
    {key:"installmentConfigID", label: "Installment Config Id"},
    {key:"mciID", label: "Issuer Id"},
    {key:"productType", label: "Product Type"},
]

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CIBScheme = (props) => {
    const {classes, cx} = useStyles();
    const [currentForm, setCurrentForm] = useState("list");
    const [filterState, setFilterState] = useState(filterobj)
    const { 
        cibApproveSchemeRes, mibLoader,
        createSchemeRes, bankSchemesList, totalRecords
    } = props;
    let permissions = localStorage.getItem("permissions")
    permissions = permissions && permissions !== null ? JSON.parse(permissions) : []
    const userName = localStorage.getItem('userName')

    const [activeTab, setActiveTab] = useState(0);
    const [openPopover, setOpenPopOver] = useState(false);
    const [approveRequest, setApproveRequest] = useState({
        installmentConfigID: "",
        mciID: "",
        requestID : "",
        configStatus: "",
        declineReason: ""
    });
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [schemeDeclineMsg, setSchemeDeclineMsg] = useState("");
    const [declineMsgReq, setDeclineMsgReq] = useState(false);
    const [declineRequest, setDeclineRequest] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [pageNo, setPage] = useState(1)
    const [sortName, setsortName] = useState('createdOn')
    const [sortDir, setsortDir] = useState('DESC');
    const [searchValue, setSearchValue] = useState("");
    const [compInitaited, setCompInitiated] = useState(false);
    const [rowState, setRowState] = useState(null);
    const [deleteReqRow, setDelReqRow] = useState(null);
    const [delConfrimOpen, setDelConfrimOpen] = useState(false)
    const [deleteAlert, openDeleteAlert] = useState(false);
    const currentPage = localStorage.getItem('currentPage')
    const enableDeclineButton = schemeDeclineMsg && schemeDeclineMsg !== "" && installmentDescRegex.test(schemeDeclineMsg);
    const [alertMsg, setAlertMsg] = useState(null)
    const [configId, setConfigId] = useState("");
    const [isExpired, setIsExpired] = useState(false);

    useEffect(() => {
        setSchemeDeclineMsg("");
        setDeclineMsgReq(false);
    }, [openPopover]);

    useEffect(() => {
        const handleInitialCall = async () => {
            await setCompInitiated(true);
            await props.fetchBankSchemes({
                ...filterState,
                pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeStatus: "APPROVED"
            });
            await localStorage.removeItem('currentPage')
        }
        handleInitialCall();
    }, []);

    useEffect(() => {
        if(cibApproveSchemeRes && cibApproveSchemeRes !== null) {
            if(!cibApproveSchemeRes.st) {
                if( cibApproveSchemeRes.errors && cibApproveSchemeRes.errors.length >0 ) {
                    let resError ="";
                    cibApproveSchemeRes.errors.map((error, index) => {
                        resError =[resError, error.errorDesc].join( index === 0 ? "Error " + index + ": " : ", Error " + index + ": ");
                    })
                    setSnackbarMsg(resError)
                } else if (cibApproveSchemeRes.msg) {
                    setSnackbarMsg(cibApproveSchemeRes.msg)
                } else {
                    setSnackbarMsg("Unknown error!")
                }
            } else {
                if(declineRequest) {
                    setSnackbarMsg("Scheme is Declined successfully!")
                    setDeclineRequest(false)
                } else { 
                    setSnackbarMsg(
                        approveRequest && approveRequest.requestType === "DELETE" 
                        ?   "Scheme is Deleted successfully!" : "Scheme is Approved successfully!"
                    ) 
                }
            }
            
            activeTab === 0 && props.fetchBankSchemes({...filterState,
                pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeStatus: "APPROVED"});
            activeTab === 1 && props.fetchBankSchemes({...filterState,
                pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeStatus: "PENDING"});
            activeTab === 2 && props.fetchBankSchemes({...filterState,
                pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeStatus: "REJECTED"});
            setOpenSnackbar(true);
        }
    }, [cibApproveSchemeRes])

    const bankSchemeArray = bankSchemesList && bankSchemesList !== null ?
        bankSchemesList.map(obj => {
            let schemeObj = {
            ...obj,
            searchColumn: approvedHeadCells.map((cell, index) => 
                cell.type === "date" && obj[cell.id] ? `${obj[cell.id].slice(0,4)}-${obj[cell.id].slice(4,6)}-${obj[cell.id].slice(6)}`
                : cell.id ==="action" ? "" : obj[cell.id] && obj[cell.id]
                ).join(" ")
            }
            return schemeObj
    }) : []
    
    const handleChange = async (event, newValue) => {
        setPage(1);
        setFilterData([]);
        setsortDir("DESC");
        setsortName("createdOn")
        await setActiveTab(newValue);
        await setFilterState(filterobj);
        newValue === 0 && await props.fetchBankSchemes({...filterobj,
            pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeStatus: "APPROVED"});
        newValue === 1 && await props.fetchBankSchemes({...filterobj,
            pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeStatus: "PENDING"});
        newValue === 2 && await props.fetchBankSchemes({...filterobj,
            pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeStatus: "REJECTED"});
    };

    const handleApproveScheme = (str) => {
        if(str === "APPROVED") {
            setOpenPopOver(false);
            setFilterState(filterobj)
            const pendingReqPaylod = {"pageNo": 1, "pageSize": 10, "sortDirection": "DESC", "sortBy": "createdOn", "schemeType": "BANK", "schemeStatus": "PENDING"};
            props.approveCIBPendingBankScheme({...approveRequest, configStatus: str, approvedBy: userName }, pendingReqPaylod);
        } else if (str === "DECLINE") {
            setDeclineRequest(true)
            if(schemeDeclineMsg && schemeDeclineMsg !== "") {
                setOpenPopOver(false);
                setFilterState(filterobj)
                const pendingReqPaylod = {"pageNo": 1, "pageSize": 10, "sortDirection": "DESC", "sortBy": "createdOn", "schemeType": "BANK", "schemeStatus": "PENDING"};
                props.approveCIBPendingBankScheme({...approveRequest, configStatus: str, declineReason: schemeDeclineMsg, approvedBy: userName }, pendingReqPaylod);
            } else {
                setDeclineMsgReq(true);
            }
        } else {
            setOpenPopOver(false);
        }
    };

    const handleDeclineMsgChange = (e) => {
        setSchemeDeclineMsg(e.target.value);
    };

    const handleAlertClose = () => {
        openDeleteAlert(false)
        setAlertMsg(null)
        //remove deleteSchemeRes 
        props.resetBankSchemeResp()
    }    

    const handleIconActions = (rowData, actionType) => {
        if (actionType === 'approve') {
            setOpenPopOver(true);
            setApproveRequest({
                installmentConfigID: rowData.installmentConfigID,
                mciID: rowData.mciID,
                requestID : rowData.requestID,
                configStatus: "APPROVED",
                declineReason: "",
                requestType: rowData.statusCode === "DELETE" ? rowData.statusCode : "APPROVE"
            });

            const currentDate = new Date().toISOString().split("T")[0].replaceAll("-","")
            if ( rowData.installmentEndDate && rowData.installmentEndDate !== "" && (currentDate < rowData.installmentEndDate)) {
                setIsExpired(false);
            } else {
                setIsExpired(true);
            }
        }
    }

    const handleDelConfirmAction = async (type) => {
        if(type === "delete") {
            props.createBankScheme({...deleteReqRow, mcID: deleteReqRow.mciID, createdBy: userName})
          setDelConfrimOpen(false)
        } else {
          setDelConfrimOpen(false)
          setDelReqRow(null)
        }
        await props.fetchBankSchemes({...filterState,
            pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeStatus: "APPROVED"});
    }

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
      });
    
    const handleSnackBarAction = () => {
        setOpenSnackbar(false)
        setSnackbarMsg(null)
        //remove approve response once snackbar is closed
        props.removeApproveResponse()
    }

    const onChangeView = async (inpval, row) => {
        const tempFilterState = {
            ...filterState,
            installmentStartDate: filterState['installmentStartDate'] !== null ?
                format(new Date(filterState['installmentStartDate']), 'yyyyMMdd') 
                : null,
            installmentEndDate: filterState['installmentEndDate'] !== null ?
                format(new Date(filterState['installmentEndDate']), 'yyyyMMdd') 
                : null
        }
        const inp = inpval === "delete" ? "list" : inpval

        if (inp === "list" && currentForm === "view") {
            await props.resetSchemeListResponse()
            props.fetchBankSchemes({...tempFilterState,
                pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeStatus: activeTab === 0 ? "APPROVED" : activeTab === 1 ? "PENDING" : "REJECTED"});
            } else if(inpval === "list" && currentForm === "create") {
            props.fetchBankSchemes({...tempFilterState,
                pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeStatus: activeTab === 0 ? "APPROVED" : activeTab === 1 ? "PENDING" : "REJECTED"});
        }

        if(inp === "view" || inp === "edit") {
            setRowState(row);
            setConfigId(row.installmentConfigID)
        }

        if(inpval === "delete") {
            await setDelReqRow({...row, statusCode: "DELETE", 
            binRangeDetails: row.binRangeDetails && row.binRangeDetails.map(bin => {
              return {
                ...bin,
                isTokenAccRange: bin.isTokenAcc
              }
            }) 
          })
          await setDelConfrimOpen(true)    
        }

        setCurrentForm(inp);
    }

    useEffect(() => {
        if(currentPage && currentPage !== null && currentPage !== undefined && currentPage === "/bankschemes" && compInitaited){
            setCurrentForm('list')
            setFilterState(filterobj);
            setPage(1);
            setsortDir("DESC");
            setsortName("createdOn")
            activeTab === 0 && props.fetchBankSchemes({...filterState,
                pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeStatus: "APPROVED"}); 
            activeTab === 1 && props.fetchBankSchemes({...filterState,
                pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeStatus: "PENDING"}); 
            activeTab === 2 && props.fetchBankSchemes({...filterState,
                pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeStatus: "REJECTED"}); 
            localStorage.removeItem('currentPage')
        }
    }, [currentPage])

    useEffect(() => {
        if(deleteReqRow !== null) {
            if(createSchemeRes && createSchemeRes !== null && createSchemeRes.st) {
                openDeleteAlert(true)
                setAlertMsg(`Bank Scheme delete request for Config Id: ${deleteReqRow.installmentConfigID} is pending for approval!`)
                //clear deletROw after setting message with key ID
                setDelReqRow(null)
          
            } else  if(createSchemeRes && createSchemeRes !== null && !createSchemeRes.st) {
                openDeleteAlert(true)
                setAlertMsg(createSchemeRes.errors && createSchemeRes.errors.length > 0 ?
                  createSchemeRes.errors.map(err => err.errorDesc).join(", ")
                  :`Problem Occured While deleting Scheme Id: ${deleteReqRow.installmentConfigID}.`)
                //clear deletROw after setting message with key ID
                setDelReqRow(null)
            }
            props.resetBankSchemeResp()
        }
    }, [createSchemeRes])    

    const handleFilterStateChange = (key, value) => {
        const tempFilter = {
          ...filterState,
          [key]: value
        }
        if(key === "isoCountryCodeNum") {
          tempFilter['mciId'] = ""
        }
        setFilterState(tempFilter);
    }
    
    const resetFilter = () => {
        setFilterState(filterobj);
        setPage(1);
        setsortDir("DESC");
        setsortName("createdOn");
        activeTab === 0 && props.fetchBankSchemes({ 
            pageNo: 1, pageSize: 10, sortBy:"createdOn", sortDirection:"DESC", schemeStatus: "APPROVED" });
        activeTab === 1 && props.fetchBankSchemes({ 
            pageNo: 1, pageSize: 10, sortBy:"createdOn", sortDirection:"DESC", schemeStatus: "PENDING" });
        activeTab === 2 && props.fetchBankSchemes({ 
            pageNo: 1, pageSize: 10, sortBy:"createdOn", sortDirection:"DESC", schemeStatus: "REJECTED" });
        setSearchValue("");
    }
    
    const FilterApply = () => {
        const tempFilterState = {
            ...filterState,
            installmentStartDate: filterState['installmentStartDate'] !== null ?
                format(new Date(filterState['installmentStartDate']), 'yyyyMMdd') 
                : null,
            installmentEndDate: filterState['installmentEndDate'] !== null ?
                format(new Date(filterState['installmentEndDate']), 'yyyyMMdd') 
                : null
        }
        activeTab === 0 && props.fetchBankSchemes(
            {...tempFilterState, pageNo: 1, sortBy: sortName, sortDirection: sortDir, schemeStatus: "APPROVED"});
        activeTab === 1 && props.fetchBankSchemes(
            {...tempFilterState, pageNo: 1, sortBy: sortName, sortDirection: sortDir, schemeStatus: "PENDING"});
        activeTab === 2 && props.fetchBankSchemes(
            {...tempFilterState, pageNo: 1, sortBy: sortName, sortDirection: sortDir, schemeStatus: "REJECTED"});
        setSearchValue("");
        setFilterState({...filterState, pageNo: 1, sortBy: sortName});
    }
    
    const handleOnBlur = (type) => {
        if(type === "declineMsg") {
            setDeclineMsgReq(schemeDeclineMsg && schemeDeclineMsg !== "" ? false : true)
        } 
    }

    const handleSetFilterData = (filterArray, searchValue) => {
        setFilterData(filterArray)
        setSearchValue(searchValue)
    }
    
    const handlePageNo = (val) => {
        setPage(val)
        const tempFilterState = {
            ...filterState,
            installmentStartDate: filterState['installmentStartDate'] !== null ?
                format(new Date(filterState['installmentStartDate']), 'yyyyMMdd') 
                : null,
            installmentEndDate: filterState['installmentEndDate'] !== null ?
                format(new Date(filterState['installmentEndDate']), 'yyyyMMdd') 
                : null
        }
        activeTab === 0 && props.fetchBankSchemes(
            { ...tempFilterState, pageNo: val, sortBy: sortName, sortDirection: sortDir, schemeStatus: "APPROVED"}); 
        activeTab === 1 && props.fetchBankSchemes(
            { ...tempFilterState, pageNo: val, sortBy: sortName, sortDirection: sortDir, schemeStatus: "PENDING"}); 
        activeTab === 2 && props.fetchBankSchemes(
            { ...tempFilterState, pageNo: val, sortBy: sortName, sortDirection: sortDir, schemeStatus: "REJECTED"});  
    }

    const handleSortDir = (value) => {
        setsortDir(value)
        setPage(1)
    }
    
    const handleSortName = (value) => {
        setsortName(value)
        setPage(1)
    }

    return (
    <>
        <BasePageToolbar currentForm={currentForm} create={permissions.includes("74")}
            title={currentForm === 'list' ? "Bank Scheme Detail" : currentForm === 'view' ? "View Bank Scheme" : 
            currentForm === 'edit' ? "Modify Bank Scheme": "Create Bank Scheme"}
            handleFilterStateChange={handleFilterStateChange} filterState={filterState} filterCells={filterCells} FilterApply={FilterApply}
            onChangeView={onChangeView} arrayToFilter={bankSchemeArray} resetFilter={resetFilter}
            setsortName={handleSortName} setsortDir={handleSortDir} sortDir={sortDir} sortName={sortName} sortArr={sortArr}
            handleSetFilterData={handleSetFilterData} isTableSearchReq={false} searchValue={searchValue}
        ></BasePageToolbar>
        <BasePageContainer>
            {currentForm === 'list' ?
            <div>
                {activeTab === 1 && <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={handleSnackBarAction}
                    action={
                    <React.Fragment>
                        <IconButton size="medium" aria-label="close" color="inherit" onClick={handleSnackBarAction}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                    }
                >
                    <Alert onClose={handleSnackBarAction} severity={
                        cibApproveSchemeRes && cibApproveSchemeRes !== null && !cibApproveSchemeRes.st ? "info" : "success"
                    }>
                        {snackbarMsg}
                    </Alert>
                </Snackbar>}

                <div className={""}>
                    <Tabs value={activeTab} onChange={handleChange} className="tabs_wraper" aria-label="simple tabs example">
                        <Tab label="Approved" {...a11yProps(0)} className={classes.tabWidth} />
                        <Tab label="Pending" {...a11yProps(1)} className={classes.tabWidth} />
                        <Tab label="Declined" {...a11yProps(2)} className={classes.tabWidth} />
                    </Tabs>
                </div>

                <Grid container className={classes.container}>
                    <TableComponent
                        loading={mibLoader}
                        headCells={activeTab !== 0 ? pendingHeadCells : approvedHeadCells}
                        onChangeView={onChangeView}
                        rows={searchValue && searchValue !== "" ? filterData : bankSchemeArray} 
                        onIconActions={handleIconActions}
                        activeTab={activeTab}
                        filterState={filterState}
                        totalRecord={totalRecords}
                        searchValue={searchValue}
                        handlePageNo={handlePageNo}
                        showEdit={activeTab === 0 && permissions.includes("75")}
                        showDelete={activeTab === 0 && permissions.includes("76")}
                        showApprove={activeTab === 1 && permissions.includes("2")}
                        type={"BANK"}
                    />
                </Grid>

                <Dialog className={"delete_dialog"}
                    open={openPopover}
                    onClose={() => handleApproveScheme("CLOSE")}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="close_dialog" onClick={() => handleApproveScheme("CLOSE")}>
                        <img src={CloseImgIcon} alt="close" />
                    </div>
                    <DialogTitle id="alert-dialog-title" className="delete_dialog_title">
                        {/* <img src={DeleteLargeIcon} className="delete_large" alt="Delete" /> */}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description" className={cx(classes.approveDialog)}>
                        <h2>{isExpired === false ? `${approveRequest.requestType} Confirmation` : "DECLINE Confirmation"}</h2>
                        <p>
                            {isExpired === false ? `Are you sure you want to ${approveRequest.requestType} scheme for Request Id ${approveRequest.requestID}?` :
                                `This scheme has either expired or is expiring today. Kindly decline.`}
                        </p>
                    </DialogContentText>
                    <div className={classes.reasonField}>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField className={"name_field"}
                                name="declineMsg"
                                id="declineMsg"
                                label="Reason (Mandatory in case of Decline)*"
                                variant="outlined"
                                fullWidth 
                                onBlur={() => handleOnBlur("declineMsg")}
                                onChange={(e) => handleDeclineMsgChange(e)}
                                />
                            </Grid>
                            {schemeDeclineMsg && schemeDeclineMsg !== "" && !installmentDescRegex.test(schemeDeclineMsg) ?
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message">
                                <InfoOutlinedIcon />{" "}
                                {"Invalid Entry! Reason can only contain Alphabets, Numbers, Whitespace, and () [] - _"}
                                </span>
                            </p> : declineMsgReq? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field"}</span>
                                </p>
                                : null
                            }
                        </Grid>
                    </div>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => handleApproveScheme("DECLINE")} color="primary" className="no" disabled={!enableDeclineButton}>
                        DECLINE
                    </Button>
                    {isExpired === false &&
                        <Button onClick={() => handleApproveScheme("APPROVED")} color="primary" className="yes" autoFocus disabled={!(schemeDeclineMsg === "" || installmentDescRegex.test(schemeDeclineMsg))}>
                            {approveRequest.requestType}
                        </Button>
                    }
                    </DialogActions>
                </Dialog>
            </div>
            : currentForm === 'view' ?
                <Grid container className={classes.formContainer}>
                    {rowState && <CreateEditViewScheme 
                        disableToken={true} onChangeView={onChangeView}
                        schemeDetail={rowState}
                        type="Bank" currentForm={currentForm}
                    />}
                </Grid> 
                : currentForm === 'edit' ?
                    <Grid container className={classes.formContainer}>
                        {rowState && <CreateEditViewScheme 
                            disableToken={false} onChangeView={onChangeView}
                            schemeDetail={rowState}
                            type="Bank" currentForm={currentForm}
                            configId={configId}
                        />}
                    </Grid>           
                    : <Grid container className={classes.formContainer}>
                        <CreateEditViewScheme onChangeView={onChangeView}
                        type="Bank" disableToken={false} currentForm={currentForm} 
                        />
                    </Grid>
            }
            <Dialog
              open={delConfrimOpen}
              onClose={() => handleDelConfirmAction("close")}
              className={"delete_dialog"}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <h2>{"Scheme Delete Confirmation?"}</h2>
              </DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      {`Please confirm if you want to delete Installment Config Id: ${deleteReqRow && deleteReqRow.installmentConfigID && deleteReqRow.installmentConfigID}.`}
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button className={classes.color}
                    onClick={() => handleDelConfirmAction("delete")} 
                  >
                    Delete
                  </Button>
                  <Button autoFocus className={classes.color}
                    onClick={() => handleDelConfirmAction("close")} 
                  >
                    Cancel
                  </Button>
              </DialogActions>
            </Dialog>
            <Snackbar
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
              }}
              open={deleteAlert}
              autoHideDuration={4000}
              onClose={() => handleAlertClose()}
              action={
              <React.Fragment>
                <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleAlertClose()}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
              }
            >
              <Alert onClose={() => handleAlertClose()} severity={
                  createSchemeRes && createSchemeRes !== null && !createSchemeRes.st ? "info" : "success"
              }>
                {alertMsg}
              </Alert>
            </Snackbar>
        </BasePageContainer>
    </>)
}
const useStyles = tss.create({
  formContainer:{
    paddingTop: theme.spacing(0),
  },
  container: {
    // paddingTop: theme.spacing(2),
  },
  approveDialog: {
    width: "100%",
    textAlign: 'center',
  },
  color: {
    color: "white",
    backgroundColor: `#1BA785 !important`
  },
  tabWidth: {
    minWidth: "103px"
  },
  reasonField: {
    paddingTop: "15px"
  }
});

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
        approveCIBPendingBankScheme, removeApproveResponse, createBankScheme, 
        resetBankSchemeResp, fetchBankSchemes, resetSchemeListResponse
      }, dispatch)
  }
}

function mapStateToProps(state) {
    return {
        mibLoader: state.SchemeReducer.mibLoader,
        cibApproveSchemeRes: state.SchemeReducer.cibApproveSchemeRes,
        createSchemeRes: state.SchemeReducer.createSchemeRes,
        bankSchemesList: state.SchemeReducer.schemeList !== null &&
            state.SchemeReducer.schemeList.bankSchemeList ? 
                state.SchemeReducer.schemeList.bankSchemeList : [],
        totalRecords: state.SchemeReducer.schemeList !== null &&
            state.SchemeReducer.schemeList.totalRecord ? 
                state.SchemeReducer.schemeList.totalRecord : 0,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CIBScheme);
