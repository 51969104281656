import React, { useState, useEffect } from 'react'
import {connect} from "react-redux";
import Grid from '@mui/material/Grid';
import { bindActionCreators } from "redux";
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Snackbar, Dialog, DialogActions,
  DialogTitle, DialogContentText, DialogContent, Button } from '@mui/material';
import { tss } from "tss-react";
import theme from '../../_theme'

import TableComponent from "../table";
import {fetchPSPDetails, createPSP, resetcreatePspRes} from "./actions/index";
import CreateEditViewPSP from "./createEditViewPSP/index";
import BasePageToolbar from '../../_common/basePageToolbar';
import BasePageContainer from '../../_common/basePageContainer';
import {EmailRegex, SpecialCharRegex1, addRegex, nameRegExp, numbRegex} from "../../shared/utility";
import {fetchCountryDetails} from "../../manageScheme/actions/index";

const headCells = [
  { id: 'id', type: 'text', numeric: true, disablePadding: false, label: 'PSP Obj Id' },
  { id: 'pspId', type: 'text', numeric: true, disablePadding: false, label: 'PSP Id' },
  { id: 'name', type: 'text', numeric: false, disablePadding: false, label: 'PSP Name' },
  { id: 'displayName', type: 'text', numeric: false, disablePadding: false, label: 'Display Name' },
  { id: 'countryName', type: 'text', numeric: false, disablePadding: false, label: 'Country' },
  { id: 'status', type: 'status', numeric: true, disablePadding: false, label: 'Status' },
  { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];
const PspObj = {
  "name": "",
  "displayName": "",
  "countryName" : "",
  "address": { 
    "address1": "", "address2": "",
    "address3": "", "state": "",
    "city": "", "postalCode": ""
  },
  "isoCountryCodeNum": "",
  "contactPersonDetail": {
      "name": "",
      "phoneNo": "",
      "mobileNo": "",
      "emailId": ""
  },
  "status": "ACTIVE",
}

const filterCells = [
  { id: 'isoCountryCodeNum', type: 'select', label: 'Country' },
  { id: 'pspId', type: 'select', label: 'Psp Name' },
  { id: 'city', type: 'text', label: 'City' },
  { id: 'state', type: 'text', label: 'State' },
  { id: 'postalCode', type: 'text', label: 'Postal Code' },
];

const filterobj = {
  city: "",
  pspId: "",
  state: "",
  postalCode: "",
  isoCountryCodeNum: "",
  pageNo: 1,
  pageSize: 10,
  sortBy:"createdOn",
  sortDirection:"DESC"
}

const sortArr = [
  {key:"createdOn", label: "Create Date"},
  {key:"pspId", label: "PSP Id"},
  {key:"countryName", label: "Country Name"},
  {key:"name", label: "Psp Name"},
  {key:"displayName", label: "Display Name"},
  {key:"status", label: "Status"},
]

const PSP = (props) => {
  const {classes} = useStyles();
  const {PSPList, countryDetails, createPspRes, pspLoader, PSPListCount} = props;
  const [clientRow, setClientRow] = useState(null);
  const [snackbarMsg, setSnackBarMsg] = useState(null);
  const [currentForm, setCurrentForm] = useState("list");
  const [openSnackbar, SetOpenSnackbar] = useState(false);
  const [filterState, setFilterState] = useState(filterobj)
  const [responseId, setResponseId] = useState("");
  const [openPopover, setopenPopover] = useState(false);
  const [PspState, setPspState] = useState(PspObj)
  const currentPage = localStorage.getItem('currentPage')
  const [filterData, setFilterData] = useState([]);
  const [pageNo, setPage] = useState(1)
  const [sortName, setsortName] = useState('createdOn')
  const [sortDir, setsortDir] = useState('DESC');
  const [searchValue, setSearchValue] = useState("");
  const [compInitaited, setCompInitiated] = useState(false);
  
  let permissions = localStorage.getItem("permissions")
  permissions = permissions && permissions !== null ? JSON.parse(permissions) : []
  const userid = localStorage.getItem("userid");

  const nextDisableToken = currentForm !== "view" && PspState && PspState.countryName !== "" &&
    PspState.name !== "" && PspState.displayName !== "" && 
    nameRegExp.test(PspState.displayName) && nameRegExp.test(PspState.name) && 
    (PspState.address['address1'] !== "" && addRegex.test(PspState.address['address1'])) && 
    (PspState.address['address2'] === "" || PspState.address['address2'] === null || 
    (PspState.address['address2'] !== "" && addRegex.test(PspState.address['address2']))) && 
    (PspState.address['address3'] === "" || PspState.address['address3'] === null || 
    (PspState.address['address3'] !== "" && addRegex.test(PspState.address['address3']))) &&
    (PspState.address['postalCode'] !== "" && numbRegex.test(PspState.address['postalCode'])) &&
    (PspState.address['state'] !== "" && SpecialCharRegex1.test(PspState.address['state'])) &&
    (PspState.address['city'] !== "" && SpecialCharRegex1.test(PspState.address['city'])) &&
    (PspState.contactPersonDetail['name'] !== "" && SpecialCharRegex1.test(PspState.contactPersonDetail['name'])) && 
    (PspState.contactPersonDetail['emailId'] !== "" && EmailRegex.test(PspState.contactPersonDetail['emailId'])) &&
    (!PspState.contactPersonDetail['phoneNo'] || numbRegex.test(PspState.contactPersonDetail['phoneNo'])) &&
    (PspState.contactPersonDetail['mobileNo'] !== "" && numbRegex.test(PspState.contactPersonDetail['mobileNo']))

  const pspArray = PSPList && PSPList !== null ? 
    PSPList.map(obj => {

        let schemeObj = {
          ...obj,
          searchColumn: headCells.map((cell, index) => 
            cell.id ==="action" ? "" : obj[cell.id] && obj[cell.id]
          ).join(" ")
        }
        return schemeObj
    }) : []

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  });
  
  const onChangeView = (inpval, row) => {
    setCurrentForm(inpval);
    if(inpval === "view") {
      setClientRow(row)
    } else if(inpval === "edit") {
      setPspState(row)

      //fetch details for edit drop down
      countryDetails && countryDetails.length === 0 && props.fetchCountryDetails({})

    } else if(inpval === "create") {
      setPspState(PspObj)
      countryDetails && countryDetails.length === 0 && props.fetchCountryDetails({})

    } else {
      props.fetchPSPDetails({...filterState, id: filterState.pspId,
        pageNo: 1, sortBy: sortName, sortDirection: sortDir
      })
    }
  }

  useEffect(() => {
    if(currentPage && currentPage !== null && currentPage !== undefined && currentPage === "/psp" && compInitaited){
      setCurrentForm('list')
      setFilterState({...filterobj});
      props.fetchPSPDetails({
        pageNo: 1,
        pageSize: 10,
        sortBy:"createdOn",
        sortDirection:"DESC"
      })
      setPage(1);
      setsortDir("DESC");
      setsortName("createdOn")
      localStorage.removeItem('currentPage')
    }
  }, [currentPage])

  useEffect(() => {
    const handleInitialCall = async () => {
      await setCompInitiated(true);
      await props.fetchPSPDetails({...filterState, id: filterState.pspId,
        pageNo: pageNo, sortBy: sortName, sortDirection: sortDir
      })
      await localStorage.removeItem('currentPage')
    }
    handleInitialCall();
    
    return () => {
      //reset psp response
      props.resetcreatePspRes()
    }
  }, [])
 
  const handleFilterStateChange = (key, value) => {
    let tempFilter = {
        ...filterState,
        [key]: value
    }
    if(key === "isoCountryCodeNum") {
      tempFilter = {
        ...tempFilter,
        pspId: "",
      }
    }
    setFilterState(tempFilter);
  }

  const resetFilter = () => {
    setFilterState(filterobj);
    setPage(1);
    setsortDir("DESC");
    setsortName("createdOn")
    props.fetchPSPDetails({
      pageNo: 1,
      pageSize: 10,
      sortBy:"createdOn",
      sortDirection:"DESC"
    })
  }

  const FilterApply = () => {
    setPage(1)
    props.fetchPSPDetails({ ...filterState, id: filterState.pspId, pageNo: 1, sortBy: sortName, sortDirection: sortDir, pspId: ""})
    setFilterState({...filterState});
  }

  const handleSnackBarAction = () => {
    SetOpenSnackbar(false)
    setSnackBarMsg(null)
    //reset psp response
    props.resetcreatePspRes()
  }

  useEffect(() => {
    if(createPspRes && createPspRes !== null && createPspRes.st) {
      setResponseId(createPspRes.id)
      if(currentForm === "create" ) {
        setopenPopover(true)
      } else {
        SetOpenSnackbar(true)
        setSnackBarMsg(`PSP Modified Successfully!`)
        onChangeView("list")
      }
      //reset psp response
      props.resetcreatePspRes()
    } else if(createPspRes && createPspRes !== null && !createPspRes.st) {
      SetOpenSnackbar(true)
      setSnackBarMsg(createPspRes.msg && createPspRes.msg)
      // onChangeView("list")
    }
  }, [createPspRes])

  const handleInputChange = (key, value) => {

    let updatedPspState = {...PspState};
    if(key === "country") {
      
      // adding country details & reseting other fields
      updatedPspState = {
        ...updatedPspState,
        countryName: value[1],
        isoCountryCodeNum: value[0],
      }

    } else if(key === "user") {
      //setting user details
      updatedPspState = {
        ...updatedPspState,
        contactPersonDetail: {
          ...updatedPspState['contactPersonDetail'],
          ...value
        }
      }

    } else if(key === "address") {
      //setting address details
      updatedPspState = {
        ...updatedPspState,
        address: {
          ...updatedPspState['address'],
          ...value
        }
      }

    } else {
      //update other details
      updatedPspState = {
        ...updatedPspState,
        [key]: value[0]
      }

    }
    setPspState(updatedPspState)
  }

  const handlePspCreateUpdate = () => {
    let req = {
      // "id" : "",
      "PSPParameters": {
        "key": "value",
        "key2": "value2"
      },  
      "isoCountryCode": "",
      ...PspState,
      "userId": JSON.parse(userid),

    }
    props.createPSP(req)
    setFilterState(filterobj);
    setSearchValue("")
  }

  const handleSetFilterData = (filterArray, searchValue) => {
    setFilterData(filterArray)
    setSearchValue(searchValue)
  }

  const handleDialogAction = async (type) => {
    setopenPopover(false)
    setPspState(PspObj)
    if(type === "exit" ){
      onChangeView('list')
    } else {
      await onChangeView('list')
      await onChangeView('create')
    }
  }

  const handlePageNo = (val) => {
    setPage(val)
    props.fetchPSPDetails({...filterState, id: filterState.pspId,
      pageNo: val, sortBy: sortName, sortDirection: sortDir, pspId: ""
    });
  }

  const handleSortDir = (value) => {
    setsortDir(value)
    setPage(1)
  }

  const handleSortName = (value) => {
    setsortName(value)
    setPage(1)
  }

  return (
    <>
      <BasePageToolbar 
        currentForm={currentForm}  create={permissions.includes("13")} onChangeView={onChangeView} resetFilter={resetFilter}
        title={currentForm === 'list' ? "Payment Provider Detail" :  currentForm === 'view' ? "View Payment Provider" : 
          currentForm === 'edit' ? "Modify Payment Provider" : "Create Payment Provider"}
        setsortName={handleSortName} setsortDir={handleSortDir} sortDir={sortDir} sortName={sortName} sortArr={sortArr}
        handleFilterStateChange={handleFilterStateChange} filterState={filterState} filterCells={filterCells} FilterApply={FilterApply}
        arrayToFilter={pspArray} handleSetFilterData={handleSetFilterData} isTableSearchReq={false} searchValue={searchValue}
      ></BasePageToolbar>
        <BasePageContainer>
            {currentForm === 'list' ?
            <Grid container className={classes.container}>
              <TableComponent
                // sortOrder="asc"
                // sortOrderBy="id"
                loading={pspLoader}
                headCells={headCells}
                activeTab={filterState}
                rows={searchValue && searchValue !== "" ? filterData : pspArray}
                onChangeView={onChangeView}
                requestType="PSPList"
                showEdit={permissions.includes("14")}
                totalRecord={PSPListCount}
                handlePageNo={handlePageNo}
              />
            </Grid>
            : currentForm === 'create' ?
              <Grid container className={classes.formContainer}>
                <CreateEditViewPSP 
                  PspState={PspState}
                  currentForm={currentForm}
                  countryDetails={countryDetails}
                  nextDisableToken={!nextDisableToken}
                  handleInputChange={handleInputChange}
                  handlePspCreateUpdate={handlePspCreateUpdate}
                />
              </Grid>
              : currentForm === 'edit' ?
                <Grid container className={classes.formContainer}>
                  <CreateEditViewPSP 
                    PspState={PspState}
                    currentForm={currentForm}
                    countryDetails={countryDetails}
                    nextDisableToken={!nextDisableToken}
                    handleInputChange={handleInputChange}
                    handlePspCreateUpdate={handlePspCreateUpdate}
                  />
                </Grid>
                :<Grid container className={classes.formContainer}>
                    <CreateEditViewPSP 
                      currentForm={currentForm} 
                      productDetails={clientRow}
                      onChangeView={onChangeView}
                    />
                </Grid>
            }
            <Snackbar
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
              }}
              open={openSnackbar}
              autoHideDuration={4000}
              onClose={() => handleSnackBarAction()}
              action={
              <React.Fragment>
                <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
              }
            >
              <Alert onClose={() => handleSnackBarAction()} severity={
                createPspRes && createPspRes !== null && !createPspRes.st ? "info" : "success"
              }>
                {snackbarMsg}
              </Alert>
            </Snackbar>
            <Dialog
              open={openPopover}
              onClose={() => handleDialogAction("exit")}
              className={"delete_dialog"}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <h2>{`Psp ${responseId} Created Successfully!`}</h2>
              </DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      {`Do you want to Create new Psp?`}
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button className={classes.color}
                    onClick={() => handleDialogAction("create")} 
                >
                  Create New
                </Button>
                <Button className={classes.color}
                    onClick={() => handleDialogAction("exit")} 
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
        </BasePageContainer>
    </>)
}
const useStyles = tss.create({
  formContainer: {
    paddingTop: theme.spacing(0),
  },
  container: {
    paddingTop: theme.spacing(2),
  },
  color: {
    color: "white",
    backgroundColor: `#1BA785 !important`
  },
});

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
        fetchPSPDetails, fetchCountryDetails,
        resetcreatePspRes, createPSP
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    PSPList: state.PspReducer.PSPList !== null && state.PspReducer.PSPList.pspResponse ?
      state.PspReducer.PSPList.pspResponse : [],
    PSPListCount: state.PspReducer.PSPList !== null && state.PspReducer.PSPList.totalRecords ?
      state.PspReducer.PSPList.totalRecords : 0,
    countryDetails: state.SchemeReducer.countryDetails !== null &&
      state.SchemeReducer.countryDetails.countryList ?
        state.SchemeReducer.countryDetails.countryList : [],
    createPspRes: state.PspReducer.createPspRes,
    pspLoader: state.PspReducer.pspLoader
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PSP);
