import React from 'react'

import { tss } from "tss-react";
import theme from '../../_theme';

const PageContainer = ({ children }) => {
  const {classes} = useStyles()

  return <article className={classes.container}>{children}</article>
}

const useStyles = tss.create({
  container: {
    flex: 1,
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
});

export default PageContainer
