import { BrandActions } from "../consts/index";

const initialState = {
    country: null,
    brandName: null,
    displayName: null,
    description: null,
    isSerialNumberRequired: null,
    screenType: null,
    countryDetails: null,
    screenTypeDetails: null,
    allBrandDetails: null,
    brandDetails: null,
    createBrandRes: null,
    uploadDocumentRes: null,
    brandLoader: false,
    docUploadLoader: null
}

export default (state = initialState, {type, payload}) => {
    switch(type) {
        case BrandActions.handleCountryChangeForCreateBrand.SUCCESS:
            return {
                ...state,
                country: payload,
            }
        case BrandActions.handleBrandNameChangeForCreateBrand.SUCCESS:
            return {
                ...state,
                brandName: payload,
            }
        case BrandActions.handleDisplayBrandNameChangeForCreateBrand.SUCCESS:
            return {
                ...state,
                displayName: payload,
            }
        case BrandActions.handleDescriptionChangeForCreateBrand.SUCCESS:
            return {
                ...state,
                description: payload,
            }
        case BrandActions.handleIsSerialNumberRequiredCheckForCreateBrand.SUCCESS:
            return {
                ...state,
                isSerialNumberRequired: payload,
            }
        case BrandActions.handleScreenTypeChangeForCreateBrand.SUCCESS:
            return {
                ...state,
                screenType: payload,
            }
        case BrandActions.handleCountryDetailsFetchForBrand.REQUEST:
            return {
                ...state,
                brandLoader: true
                }
        case BrandActions.handleCountryDetailsFetchForBrand.SUCCESS:
            return {
                ...state,
                brandLoader: false,
                countryDetails: payload,
            }
        case BrandActions.handleCountryDetailsFetchForBrand.FAILURE:
            return {
                ...state,
                brandLoader: false,
                countryDetails: null,
            }
        case BrandActions.handleScreenTypeDetailsFetchForBrand.REQUEST:
            return {
                ...state,
                screenTypeDetails: null,
                }
        case BrandActions.handleScreenTypeDetailsFetchForBrand.SUCCESS:
            return {
                ...state,
                screenTypeDetails: payload,
            }
        case BrandActions.handleScreenTypeDetailsFetchForBrand.FAILURE:
            return {
                ...state,
                screenTypeDetails: payload,
            }
        case BrandActions.handleBrandDetailsFetch.REQUEST:
            return {
                ...state,
                brandLoader: true,
            }
        case BrandActions.handleBrandDetailsFetch.SUCCESS:
            return {
                ...state,
                brandLoader: false,
                allBrandDetails: payload,
            }
        case BrandActions.handleBrandDetailsFetch.FAILURE:
            return {
                ...state,
                brandLoader: false,
                allBrandDetails: null,
            }
        case BrandActions.handleIndividualBrandDetailsFetchForBrand.REQUEST:
            return {
                ...state,
                brandLoader: true,
            }
        case BrandActions.handleIndividualBrandDetailsFetchForBrand.SUCCESS:
            return {
                ...state,
                brandLoader: false,
                brandDetails: payload,
            }
        case BrandActions.handleIndividualBrandDetailsFetchForBrand.FAILURE:
            return {
                ...state,
                brandLoader: false,
                brandDetails: null,
            }
        case BrandActions.handleCreateOrEditBrandSubmit.REQUEST: 
            return {
                ...state,
                brandLoader: true,
            }
        case BrandActions.handleCreateOrEditBrandSubmit.SUCCESS: 
            return {
                ...state,
                brandLoader: false,
                createBrandRes: payload,
            }
        case BrandActions.handleCreateOrEditBrandSubmit.FAILURE: 
            return {
                ...state,
                brandLoader: false,
                createBrandRes: payload,
            }
        case BrandActions.uploadDocumentForBrand.REQUEST: 
            return {
                ...state,
                brandLoader: true,
                docUploadLoader: payload.key ? payload.key : null
            }
        case BrandActions.uploadDocumentForBrand.SUCCESS: 
            return {
                ...state,
                brandLoader: false,
                uploadDocumentRes: payload,
                docUploadLoader: null
            }
        case BrandActions.uploadDocumentForBrand.FAILURE: 
            return {
                ...state,
                brandLoader: false,
                uploadDocumentRes: payload,
                docUploadLoader: null
            }

        default:
            return state;
    }
}