import {RSAActions} from '../consts'

const initialState = {
    loader: false,
    rsaKeyListResp: null,
    createRSAKeyResp: null,
    downloadKeyResp: null,
    expireKeyResp: null,
}

export default (state = initialState, {type, payload}) => {
    switch(type) {
        case RSAActions.fetchRSAKeyList.REQUEST:
            return {
                ...state,
                loader: true,
                rsaKeyListResp: null
            }
        case RSAActions.fetchRSAKeyList.FAILURE:
            return {
                ...state,
                loader: false,
                rsaKeyListResp: payload
            }
        case RSAActions.fetchRSAKeyList.SUCCESS:
            return {
                ...state,
                loader: false,
                rsaKeyListResp: payload
            }
        
        case RSAActions.createRSAKey.REQUEST:
            return {
                ...state,
                loader: true,
                createRSAKeyResp: null
            }
        case RSAActions.createRSAKey.FAILURE:
            return {
                ...state,
                loader: false,
                createRSAKeyResp: payload
            }
        case RSAActions.createRSAKey.SUCCESS:
            return {
                ...state,
                loader: false,
                createRSAKeyResp: payload
            }

        case RSAActions.downloadRSAPublicKey.REQUEST:
            return {
                ...state,
                loader: true,
                downloadKeyResp: null
            }
        case RSAActions.downloadRSAPublicKey.FAILURE:
            return {
                ...state,
                loader: false,
                downloadKeyResp: payload
            }
        case RSAActions.downloadRSAPublicKey.SUCCESS:
            return {
                ...state,
                loader: false,
                downloadKeyResp: payload
            }

        case RSAActions.expireKeyPair.REQUEST:
            return {
                ...state,
                loader: true,
                expireKeyResp: null
            }
        case RSAActions.expireKeyPair.FAILURE:
            return {
                ...state,
                loader: false,
                expireKeyResp: payload
            }
        case RSAActions.expireKeyPair.SUCCESS:
            return {
                ...state,
                loader: false,
                expireKeyResp: payload
            }

        default: 
            return state
    }
}