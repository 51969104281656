import { actionCreator, BASE_URL } from "../../../shared/utility";
import { ProductCategoryActions } from "../consts/index";

export function handleProductCategorySelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(ProductCategoryActions.handleProductCategoryNameChangeForCategory.SUCCESS, payload))
    }
}

export function handleDisplayNameSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(ProductCategoryActions.handleDisplayNameChangeForCategory.SUCCESS, payload))
    }
}

export function handleDescriptionSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(ProductCategoryActions.handleDescriptionChangeForCategory.SUCCESS, payload))
    }
}

export function handleParentCategorySelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(ProductCategoryActions.handleParentCategoryChangeForCategory.SUCCESS, payload))
    }
}

export function fetchProductCategryDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductCategoryActions.handleProductCategoryDetailsFetch.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/getProductCategoryList`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
        .then(response => response.json())
          .then(function (res) {
            if(!res.st) {
                if(res.responseCode && res.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductCategoryActions.handleProductCategoryDetailsFetch.FAILURE))
                }
            } else {
                dispatch(actionCreator(ProductCategoryActions.handleProductCategoryDetailsFetch.SUCCESS, res))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchIndividualProductCategryDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductCategoryActions.handleIndividualProductCategoryDetailsFetch.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/getProductCategoryList`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
        .then(response => response.json())
          .then(function (res) {
            if(!res.st) {
                if(res.responseCode && res.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductCategoryActions.handleIndividualProductCategoryDetailsFetch.FAILURE))
                }
            } else {
                dispatch(actionCreator(ProductCategoryActions.handleIndividualProductCategoryDetailsFetch.SUCCESS, res))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetIndividualProductCategory() {
    return (dispatch) => {
        dispatch(actionCreator(ProductCategoryActions.handleIndividualProductCategoryDetailsFetch.FAILURE))
    }
}

export function fetchParentCategoryDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductCategoryActions.handleParentCategoryDetailsFetchForCategory.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/getProductCategorySummaryList`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => response.json())
        .then(function (res) {
          if(!res.st) {
            if(res.responseCode && res.responseCode === "401") {
                localStorage.setItem("tokenExpired", "true")
                localStorage.removeItem('accessToken')
                window.location.reload()
            }  else {
                dispatch(actionCreator(ProductCategoryActions.handleParentCategoryDetailsFetchForCategory.FAILURE))
            }
         } else {
                dispatch(actionCreator(ProductCategoryActions.handleParentCategoryDetailsFetchForCategory.SUCCESS, res))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function submitCreateOrEditProductCategoryForm(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductCategoryActions.handleCreateOrEditProductCategorySubmit.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/addOrEditProductCategory`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductCategoryActions.handleCreateOrEditProductCategorySubmit.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(ProductCategoryActions.handleCreateOrEditProductCategorySubmit.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function clearProductCateogryRes(payload) {
    return(dispatch) => {
        dispatch(actionCreator(ProductCategoryActions.handleCreateOrEditProductCategorySubmit.SUCCESS, payload))
    }
}

export function uploadCategoryDocument(payload, type) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductCategoryActions.uploadDocumentForCategory.REQUEST, {key: type}))
        fetch(`${BASE_URL}/oem/api/data/web/editProductCategoryImage`, {
            method: 'POST',
            body: payload, //JSON.stringify(payload),
            headers: {
                // 'Content-Type':'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductCategoryActions.uploadDocumentForCategory.FAILURE, {...response, key: type}))
                }
            } else {
                dispatch(actionCreator(ProductCategoryActions.uploadDocumentForCategory.SUCCESS, {...response, key: type}))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetUploadDocumentResp() {
    return(dispatch) => {
        dispatch(actionCreator(ProductCategoryActions.uploadDocumentForCategory.FAILURE, null))
    }
}