import React, { forwardRef } from 'react'

import ListItem from '@mui/material/ListItem'
import { tss } from "tss-react";
import theme from '../../../_theme';

import { NavLink } from 'react-router-dom'

export const NavItemLink = forwardRef((props, ref) => (
  <NavLink exact {...props} innerRef={ref} />
))

// Can be a link, or button
export const NavItemComponent = forwardRef((props, ref) => {
  const { isCollapsed, ...newProps } = props
  const {classes, cx} = useStyles()

  const handleListClick = (e) => {
    localStorage.setItem('currentPage', newProps.link)
    newProps.onClick(e)
  }

  const component =
    typeof props.link === 'string' ? (
      <ListItem {...newProps} onClick={(e) => handleListClick(e)} button component={NavItemLink} to={props.link} />
    ) : (
      <ListItem {...newProps} button />
    )

  return (
    <div {...props} ref={ref} className={cx(isCollapsed && classes.navItemCollapsedWrapper)}>
      {component}
    </div>
  )
})

const useStyles = tss.create({
  navItemCollapsedWrapper: {
    width: theme.sidebar.widthCollapsed,
  },
});

export default NavItemComponent
