import React, {useState, useEffect} from 'react';
import { bindActionCreators } from "redux";
import {connect} from "react-redux";
import {reduxForm} from 'redux-form';
import {
  Grid, TextField, Menu, MenuItem,
  Button, Paper, InputAdornment,
  FormGroup, FormControlLabel, Checkbox,
  Accordion, AccordionSummary, AccordionDetails, Typography,
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../../_theme'
import Autocomplete from '@mui/material/Autocomplete';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import addDays from "date-fns/addDays";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
  handleCountrySelect, handleProgramTypeSelect,
  handleStartDateSelect, handleEndDateSelect,
  handleDescriptionSelect, handleIsDefaultSelect,
  fetchProgramTypeDetails, fetchSchemeDetails,
  submitCreateOrEditProgramForm, handleProgramNameSelect,
  fetchBankSchemeDetails, handleBrandSelect,
  fetchBrandDetails, fetchIssuerCountryDetails,
  clearProgramRes, handleShowCrossBorderSelect,
  handleSchemeSelect,
} from "../actions";
import {fetchCountryDetails} from "../../manageScheme/actions/index";
import DeleteIcon from '../../Assets/delete.svg';
import {nameRegExp, descrRegex} from "../../shared/utility";
import { NO_SPECIAL_CHARACTERS } from "shared/errorMessages";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = tss.create({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    border: "solid 1px #EEEEEE",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(5),
  },
  input: {
    display: 'none',
  },
  menu: {
    fontSize: "15px"
  },
  accordion: {
    backgroundColor: "#FAFAFA",
    marginBottom: "20px",
    padding: "0px 0px",
  },
  subRangePaper: {
    width: '100%',
    padding: "0px 10px 0px 10px",
    backgroundColor: "transparent",
  },
  heading: {
    color: "#424242",
    fontFamily: "Quicksand-SemiBold",
    // fontSize: "16px",
    fontSize: "18px",
    letterSpacing: "0",
    lineHeight: "20px",
  },
  addRange: {
    alignItems: "center",
    display: "flex",
  },
});

const programTypeList = [ {name: "CIB", displayName: "BANK"}, {name: "MIB", displayName: "MERCHANT"}, {name: "BRAND", displayName: "BRAND"} ];

function CreateEditViewProgram(props) {
  let programSchemeObj = {
    issuerIsoCountryCodeNum: "",
    issuerCountryName: "",
    issuerName: "",
    issuerId: "",
    programSchemes: []
  }
  const {classes} = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEndEl, setAnchorEndEl] = useState(null);
  const [countryCodeNum, setCountryCodeNum] = useState("");
  const [brandID, setBrandId] = useState(null);
  const [issuerSelectedArr, setIssuerSelectedArr] = useState([]);
  const [currentIssuerIndex, setCurrentIssuerIndex] = useState(-1);

  const {
    country, programType, startDate, endDate, programName, programDescription, 
    brand, isDefault, showCrossBorder, countryDetails, brandSchemeDetails, brandDetails, 
    issuerCountryDetails, programDetails, currentForm, onChangeView, progId, setIssuerList, 
    setSchemeList, issuerList, schemeList, programSchemesDetails, setProgramSchemesDetails
  } = props;
  const [valObj, setValObj] = React.useState({
    nameErr: false, descriptionErr: false, 
  })

  const reqProgramId = progId ? progId : ""

  const programSchemeDetailsCheck = () => {
    let retFlag = programSchemesDetails && programSchemesDetails.length > 0;
    programSchemesDetails && programSchemesDetails.length > 0 &&
      programSchemesDetails.map((obj) => {
        if(retFlag) {
          retFlag = obj.issuerCountryName && obj.issuerCountryName !== "" &&
          obj.issuerName && obj.issuerName !== "" &&
          obj.programSchemes && obj.programSchemes.length > 0
        }
      })

    return retFlag;
  }
  
  const createButtonFlag = currentForm !== "view" && country && country !== "" && programType && programType !== "" && 
    (programDescription === "" || programDescription === null ||
      (programDescription !== "" && programDescription !== null && descrRegex.test(programDescription))) &&
    ((programType === "BRAND" && brand && brand !== null) || programType !== "BRAND") && startDate && startDate !== "" && 
    endDate && endDate !== "" && programName && programName !== "" && nameRegExp.test(programName) && programSchemesDetails && programSchemesDetails.length > 0 && 
    programSchemeDetailsCheck()

  let countryObj = []
  let countryCodeArr = []
  const countryArray = countryDetails && countryDetails !== null && countryDetails.length >0 ? 
    countryDetails.map(obj => {
        countryObj.push(obj)
        countryCodeArr.push(obj.isoCountryCodeNum)
        return obj.name
    }) : []
  
  let issuerCountryArray = []
  let issuerCountryCodeArr = []
  issuerCountryDetails && issuerCountryDetails !== null && issuerCountryDetails.length >0 &&
      issuerCountryDetails.map(obj => {
        if(issuerCountryArray.indexOf((obj.countryName).toUpperCase()) === -1) {
          issuerCountryCodeArr.push(obj.isoCountryCodeNum)
          issuerCountryArray.push((obj.countryName).toUpperCase())
        }
        return obj
    })

  let brandObj = []
  const brandArray = brandDetails && brandDetails !== null && brandDetails.length >0 ? 
      brandDetails.map(obj => {
        brandObj.push(obj)
        return obj.name
    }) : []

  useEffect(() => {
    props.fetchCountryDetails({});
    props.fetchIssuerCountryDetails({});
    props.handleIsDefaultSelect(true);
    return async () => {
      //resetting form on component Mount
      resetOnCreateSelect()
      await props.clearProgramRes(null)
    }
  }, [])

  // useEffect(() => {
  //   resetStatesOnEditSelect()
  // }, [programDetails, issuerCountryDetails])

  useEffect(() => {
    resetOnCreateSelect()
  }, [currentForm])

  useEffect(() => {
    if(currentIssuerIndex !== -1) {
      let schemesArrayObj = []
      // const schemesArray = programType && programType !== "" &&
      //   brandSchemeDetails && brandSchemeDetails !== null && brandSchemeDetails.length > 0 ? 
      //     brandSchemeDetails.map(obj => {
      //       schemesArrayObj.push({id: obj.id, installmentConfigID: obj.code, installmentConfigDesc: obj.name})
      //       return obj.name
      // }) :[]

      const schemesArray = programType && programType !== "" &&
        brandSchemeDetails && brandSchemeDetails !== null && brandSchemeDetails.length > 0 ? 
          brandSchemeDetails.map(obj => {
            if(programType === "CIB") {
              if(obj.name && obj.name !== "" && !(((obj.name).toUpperCase()).includes("[TEMPLATE ONLY]") || ((obj.name).toUpperCase()).includes("[TEMPLATEONLY]"))) {
                schemesArrayObj.push({id: obj.id, installmentConfigID: obj.code, installmentConfigDesc: obj.name})
                return obj.name
              } else {
                return null
              }
            } else {
              schemesArrayObj.push({id: obj.id, installmentConfigID: obj.code, installmentConfigDesc: obj.name})
              return obj.name
            }
      }).filter(name => name !== null) :[]

      const tempSchemes = schemeList.map((obj, index) => {
        if(index === currentIssuerIndex) {
            return {
                ...obj,
                schemeArr: schemesArray,
                schemeObjArr: schemesArrayObj,
            }
        } else {
            return obj
        }
      })
      setSchemeList(tempSchemes);
    }
  }, [brandSchemeDetails])

  const resetOnCreateSelect = async () => {
    if(currentForm === "create") {
      await props.handleCountrySelect(null);
      await props.handleProgramTypeSelect(null);
      await props.handleStartDateSelect("");
      await props.handleEndDateSelect("");
      await props.handleProgramNameSelect("");
      await props.handleDescriptionSelect("");
      await props.handleBrandSelect("");
      // await props.handleIsDefaultSelect(false);
      await props.handleShowCrossBorderSelect(false);
      await setProgramSchemesDetails([programSchemeObj]);
      await setCountryCodeNum("");
      await setIssuerList([{issuerArr: [], issuerObjArr: []}]);
      await setSchemeList([{schemeArr: [], schemeObjArr: []}]);
    }
  }  

  const handleInputChange = (e, type) => {
    if(type === "name") {
        props.handleProgramNameSelect(e.target.value)
        setValObj({...valObj, nameErr: false })
    } else if(type === "description"){
        props.handleDescriptionSelect(e.target.value)
    } 
  }

  const handleCheckBoxChange = (event, type) => {
    if(type === "isDefault") {
      props.handleIsDefaultSelect(event.target.checked);
      props.handleShowCrossBorderSelect(!(event.target.checked))
      setIssuerSelectedArr([])
      handleIssuerCountryChange([{...programSchemeObj}], 0, "issuerCountry", country, true)
    } else {
      props.handleShowCrossBorderSelect(event.target.checked)
      props.handleIsDefaultSelect(!(event.target.checked));
    }
  };

  const handleChange = (e, type) => {
    if(type === "country") {
      handleCountryChange(e.target.value)
      handleIssuerCountryChange([{...programSchemeObj}], 0, "issuerCountry", e.target.value, true)
      setIssuerSelectedArr([]);
      
      if(programType === "BRAND"){
        props.handleBrandSelect("")
        setBrandId(null)
      }

    } else if(type === "programType"){
        handleProgramTypeChange(e.target.value)
        if(isDefault) {
          handleIssuerCountryChange([{...programSchemeObj}], 0, "issuerCountry", country, true)
        } else {
          setProgramSchemesDetails([{...programSchemeObj}])
        }
        setIssuerSelectedArr([])
        props.handleBrandSelect("")
        setBrandId(null)

    } else if (type === "brand") {
      if(isDefault) {
        handleIssuerCountryChange([{...programSchemeObj}], 0, "issuerCountry", country, true)
      } else {
        setProgramSchemesDetails([{...programSchemeObj}])
      }
      setIssuerSelectedArr([]);

      if(brandArray.indexOf(e) !== -1) {
        setBrandId(brandDetails[brandArray.indexOf(e)]["id"])
      } else {
        setBrandId(null)
      }
      props.handleBrandSelect(e)
      
    }
  }

  const handleCountryChange = (value) => {
    props.handleCountrySelect(value)
    setCountryCodeNum(countryObj[countryArray.indexOf(value)].isoCountryCodeNum);
    if( currentForm !== "view" && programType && programType === "BRAND" && value && value !== "" ) {
      countryArray.indexOf(value) !== -1 && props.fetchBrandDetails({ 
        "isoCountryCodeNum" : countryObj[countryArray.indexOf(value)].isoCountryCodeNum, status: "ACTIVE", 
        "pageNo": 1, "pageSize":1000, "sortBy": "name", "sortDirection": "ASC"})
    }
  }

  const handleProgramTypeChange = (value) => {
    props.handleProgramTypeSelect(value)
    if( currentForm !== "view" && value === "BRAND" && country && country !== "" ) {
      props.fetchBrandDetails({"isoCountryCodeNum" : countryCodeNum, "id" : "", status: "ACTIVE", 
        "pageNo": 1, "pageSize":1000, "sortBy": "name", "sortDirection": "ASC"})
    }
    !isDefault && setProgramSchemesDetails([...programSchemeObj]);
    !isDefault && setIssuerList([{issuerArr: [], issuerObjArr: []}]);
    !isDefault && setSchemeList([{schemeArr: [], schemeObjArr: []}]);
    if(isDefault) {
      setProgramSchemesDetails((programSchemesDetails).map(obj => {
        const obj1 = {...obj, 
          issuerId: "",
          issuerName: "",
          programSchemes: []
        }
        return obj1;
      }));
      setIssuerSelectedArr([]);
    }
    props.handleBrandSelect("");
  }

  function handleStartDateOpen(event) {
    if (currentForm !== 'view') {
      setAnchorEl(event.currentTarget)
    }
  }
  function handleEndDateOpen(event) {
    if (currentForm !== 'view') {
      setAnchorEndEl(event.currentTarget)
    }
  }
  function handleClose() {
    setAnchorEl(null)
  }
  function handleEndClose() {
    setAnchorEndEl(null)
  }

  const startDateSelect = (date) => {
    props.handleStartDateSelect(date);
    props.handleEndDateSelect(null);
    setAnchorEl(null)
  }

  const endDateSelect = (date) => {
    if(isDefault) {
      handleIssuerCountryChange([{...programSchemeObj}], 0, "issuerCountry", country, true)
    } else {
      setProgramSchemesDetails([...programSchemeObj])
    }
    setIssuerSelectedArr([]);
    
    props.handleEndDateSelect(date);
    setAnchorEndEl(null)
  }

  const handleCreateProgramSubmit = () => {
    const reqPayload = {
      programId: currentForm === "edit" ? reqProgramId : "",
      name: programName,
      description: programDescription,
      type: programType,
      isoCountryCodeNum: country && country !== "" ? countryObj[countryArray.indexOf(country)].isoCountryCodeNum : null,
      countryName: country && country !== "" ? country : "",
      status: "ACTIVE",
      isDefault: isDefault,
      showCrossBorder: showCrossBorder,
      startDate: startDate && startDate !== "" ? moment(startDate).format('YYYY-MM-DD') : "",
      enddate: endDate && endDate !== "" ? moment(endDate).format('YYYY-MM-DD') : "",
      brandId: programType === "BRAND" && brand && brand !== "" ? brandObj[brandArray.indexOf(brand)].id : null,
      brandName: programType === "BRAND" && brand && brand !== "" ? brand : "",
      programSchemes: programSchemesDetails,
    }
    props.submitCreateOrEditProgramForm(reqPayload);
    //calling final API for Creating Scheme
  }

  const handleNewSchemeAdd = () => {
    if(!isDefault){
      setProgramSchemesDetails([...programSchemesDetails, {
        ...programSchemeObj
      }])

      setIssuerList([...issuerList, {
        issuerArr: [],
        issuerObjArr: []
      }])

      setSchemeList([...schemeList, {
        schemeArr: [],
        schemeObjArr: []
      }])
    }
     
    if(isDefault) {
      const progArr = [...programSchemesDetails, {
          ...programSchemeObj
        }]
      handleIssuerCountryChange(progArr, 0, "issuerCountry", country, true, true)
    }
  }

  const handleSchemeDelete = async (id) => {
    let tempissuerSelectedArr = [...issuerSelectedArr]
    tempissuerSelectedArr = [...tempissuerSelectedArr.slice(0, id), ...tempissuerSelectedArr.slice(id+1)]
    setIssuerSelectedArr(tempissuerSelectedArr);

    let tempSchemesDetails = programSchemesDetails.filter((obj, index) => {
      return index !== id
    })
    setProgramSchemesDetails(tempSchemesDetails)

    let tempIssuer = issuerList.filter((obj, index) => {
      
      return index !== id
    })
    setIssuerList(tempIssuer)

    let tempSchemes = await schemeList.filter((obj, index) => {
      
      return index !== id
    })
    await setSchemeList(tempSchemes)
  }

  const handleIssuerCountryChange = async (progArr, schemeIndex, key, value, isDefault, addNew) => {
    const Value = isDefault ? (value).toUpperCase() : value;
    const flag = addNew ? progArr.length - 1 : null; 
    const tempSchemesDetails = await progArr.map((obj, index) => {
      if(isDefault || (index === schemeIndex)) {
          return {
              ...obj,
              issuerIsoCountryCodeNum: issuerCountryArray.indexOf(Value) !== -1 && issuerCountryCodeArr[issuerCountryArray.indexOf(Value)],
              issuerCountryName: Value,
              issuerId: flag !== null && index !== flag ? obj.issuerId : "",
              issuerName: flag !== null && index !== flag ? obj.issuerName : "",
              programSchemes: flag !== null && index !== flag ? obj.programSchemes : []
          }
      } else {
          return obj
      }
    })
    await setProgramSchemesDetails(tempSchemesDetails);

    Value && await setIssuerListState(isDefault ? 0 : schemeIndex, Value, addNew);
  }

  const setIssuerListState = async (schemeIndex, value, addNew) => {
    let tempIssuerArray = []
    const tempIssuerObj = issuerCountryDetails && issuerCountryDetails !== null && issuerCountryDetails.length > 0 ? 
      await issuerCountryDetails.filter(obj => {
        if((value).toUpperCase() === (obj.countryName).toUpperCase()) {
          tempIssuerArray.push(obj.name)
        }
        return (obj.countryName).toUpperCase() === (value).toUpperCase()
    }) : []

    const issuerTempArr = addNew ? [...issuerList, {
        issuerArr: [],
        issuerObjArr: []
      }] : [...issuerList];

    const tempIssuer = await issuerTempArr.map((obj, index) => {
      if(isDefault || (index === schemeIndex)) {
          return {
              ...obj,
              issuerArr: tempIssuerArray,
              issuerObjArr: tempIssuerObj,
          }
      } else {
          return obj
      }
    });
    
    await setIssuerList(tempIssuer);
    await setSchemeList([...schemeList, {
      schemeArr: [],
      schemeObjArr: []
    }])
  }

  const handleIssuerChange = async (schemeIndex, key, value) => {
    const mciId = issuerList[schemeIndex].issuerObjArr[issuerList[schemeIndex].issuerArr.indexOf(value)].mciId
    
    if(issuerSelectedArr.includes(mciId)) {
      window.alert("Issuer already selected");

    } else {
      const tempIssuerSelectedArr = [...issuerSelectedArr];
      tempIssuerSelectedArr[schemeIndex] = mciId
      setIssuerSelectedArr(tempIssuerSelectedArr)

      const tempSchemesDetails = await programSchemesDetails.map((obj, index) => {
        if(index === schemeIndex) {
            return {
                ...obj,
                issuerName: value,
                issuerId: issuerList[schemeIndex].issuerObjArr[issuerList[schemeIndex].issuerArr.indexOf(value)].id,
                programSchemes: []
            }
        } else {
            return obj
        }
      })
      await setProgramSchemesDetails(tempSchemesDetails);
      
      const reqMcId = issuerList[schemeIndex].issuerObjArr[issuerList[schemeIndex].issuerArr.indexOf(value)].mciId ;
      const reqCountryCodeNum = issuerList[schemeIndex].issuerObjArr[issuerList[schemeIndex].issuerArr.indexOf(value)].isoCountryCodeNum ;
      await setSchemeListState(schemeIndex, value, reqMcId, reqCountryCodeNum);
    }    
  }

  const setSchemeListState = async (schemeIndex, value, reqMcId, reqCountryCodeNum) => {
    let brandIdObj = {}
    if(brandID !== null) {
      brandIdObj = {
        brandId: brandID
      }
    }
    if( value && value !== "" && programType && programType !== "" ) {
      if ( programType === "BRAND" ) {
        await props.fetchSchemeDetails(
          {"picklistType": "BRAND_SCHEME", "programType": "BRAND", "mciId": reqMcId,
          "startDate": moment(startDate).format('YYYY-MM-DD'),
          "endDate": moment(endDate).format('YYYY-MM-DD'), ...brandIdObj});

      } else if ( programType === "MIB" ) {
        await props.fetchSchemeDetails({"picklistType": "BRAND_SCHEME", "programType": "MERCHANT", "mciId": reqMcId,
        "startDate": moment(startDate).format('YYYY-MM-DD'),
        "endDate": moment(endDate).format('YYYY-MM-DD'), ...brandIdObj});

      } else if ( programType === "CIB" ) {
        await props.fetchSchemeDetails({"picklistType": "BANK_SCHEME", "mciId": reqMcId,
        "startDate": moment(startDate).format('YYYY-MM-DD'),
        "endDate": moment(endDate).format('YYYY-MM-DD'), ...brandIdObj});
        
      }
    }

    setCurrentIssuerIndex(schemeIndex);
  }

  const handleSchemeChange = async (e, value, id) => {
    const tempValue = await value.map((val, index) => {
      return {
        id: schemeList[id].schemeArr.indexOf(val) !== -1 && schemeList[id].schemeObjArr[schemeList[id].schemeArr.indexOf(val)].id, //  schemesArrayObj[schemesArray.indexOf(val)].id,
        instalmentConfigId: schemeList[id].schemeArr.indexOf(val) !== -1 && schemeList[id].schemeObjArr[schemeList[id].schemeArr.indexOf(val)].installmentConfigID,
        schemeDescription: val
      }
    })

    const tempSchemesDetails = await programSchemesDetails.map((obj, index) => {
      if(index === id) {
          return {
              ...obj,
              programSchemes: tempValue,
          }
      } else {
          return obj
      }
    })
    await setProgramSchemesDetails(tempSchemesDetails);
  }
  
  const handleOnBlur = (field, value) => {
    if(value === null || value === ""){
      setValObj({...valObj, [field]: true })
    } else {
      setValObj({...valObj, [field]: false })
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form className="action_form merchant_form" noValidate autoComplete="off">
          <Grid container rowSpacing={2} columnSpacing={2}>

            <Grid item xs={12} sm={3}>
                <TextField 
                    InputLabelProps={{ shrink: true }} 
                    required
                    fullWidth 
                    variant="outlined"
                    id="country" 
                    name="country" 
                    label="Country"
                    select={currentForm === 'view' ? false : true} 
                    disabled={currentForm === 'view' ? true : false}
                    value={ currentForm === "view" ? programDetails && programDetails.countryName &&  programDetails.countryName : country } 
                    // onBlur={() => handleOnBlur("country")}
                    onChange={e => handleChange(e, "country")}
                >
                    {(countryArray).map((val, index) => {
                        return <MenuItem key={index} value={val} className={classes.menu}>
                            {val}
                        </MenuItem>
                    })}
                </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField 
                InputLabelProps={{ shrink: true }} 
                disabled={currentForm === 'view'}
                required fullWidth select={currentForm !== 'view'} 
                variant="outlined" id="programType" name="programType" label="Program Type"
                value={ 
                  currentForm === "view" ? 
                    programDetails && programDetails.type === 'CIB' ? 'BANK' 
                    : programDetails && programDetails.type === 'MIB' ? 'MERCHANT' : programDetails && programDetails.type
                  : programType 
                } 
                onChange={e => handleChange(e, "programType")}
              >
              {(programTypeList).map((val, index) => {
                return <MenuItem key={index} value={val.name} className={classes.menu}>
                    {val.displayName}
                </MenuItem>
              })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3} className={"calender_input " + (startDate ? 'MuiInputLabel_shrink' : '')}>
              <TextField label="Start Date" name="startDate" id={"startDate"} 
                // className="startDate_field"
                InputLabelProps={{ shrink: true }} 
                variant="outlined" fullWidth required
                onClick={handleStartDateOpen}
                value={currentForm === "view" ? programDetails && programDetails.startDate && programDetails.startDate : startDate && startDate !== "" ? moment(startDate).format('YYYY-MM-DD') : ""} 
                InputProps={{
                    endAdornment: (
                    <InputAdornment position="end" >
                        <DateRangeIcon className={"calender_input"} />
                    </InputAdornment>
                    ),
                }} disabled={props.currentForm === 'view' ? true : false} 
              />
              <Menu
                id={"simpleStart"} anchorEl={anchorEl}
                keepMounted open={Boolean(anchorEl)}
                onClose={handleClose} elevation={1}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                classes={{ paper: classes.profileMenu, }}>
                  <Calendar color="#1BA785" rangeColors={['#1BA785']}
                      // onInit={startDateSelect}
                      onChange={startDateSelect}
                      minDate={addDays(new Date(), 1)}
                  />
              </Menu>
            </Grid>
            <Grid item xs={12} sm={3} className={endDate ? 'MuiInputLabel_shrink' : '  '}>
              <TextField name="startDate" id={"endDate"}
                // className="endDate_field"
                InputLabelProps={{ shrink: true }} required
                label="End Date" variant="outlined" fullWidth 
                onClick={handleEndDateOpen}
                value={currentForm === "view" ? programDetails && programDetails.endDate && programDetails.endDate : endDate && endDate !== "" ? moment(endDate).format('YYYY-MM-DD') : ""}
                InputProps={{
                    endAdornment: (
                    <InputAdornment position="end"  >
                        <DateRangeIcon className={"calender_input"} />
                    </InputAdornment>
                    ),
                }} disabled={props.currentForm === 'view' ? true : false}
                ></TextField>
                <Menu
                id={"simpleEnd"} anchorEl={anchorEndEl} keepMounted
                open={Boolean(anchorEndEl)} onClose={handleEndClose}
                elevation={1}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                classes={{ paper: classes.profileMenu, }}>
                <Calendar color="#1BA785" rangeColors={['#1BA785']}
                    // onInit={endDateSelect}
                    onChange={endDateSelect}
                    minDate={startDate && startDate !== "" && currentForm === "create"  ? addDays(startDate, 1) : addDays(new Date(), 2)}
                    //minDate={addDays(startDate, 1)}
                />
                </Menu>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                name="name" id="name" label="Program Name" variant="outlined"
                fullWidth required disabled={props.currentForm === 'view' ? true : false}
                value={ props.currentForm === "view" ? programDetails && programDetails.name && programDetails.name : programName}
                onChange={e => handleInputChange(e, "name")}
                onBlur={(e) => handleOnBlur("nameErr", e.target.value)}
              />
              {programName && programName !== "" && !nameRegExp.test(programName) ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
              </p> : valObj.nameErr ? 
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                      <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                    </p> : null
              }
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                name="description" id="description" label="Program Description" variant="outlined"
                fullWidth disabled={props.currentForm === 'view' ? true : false}
                value={ props.currentForm === "view" ? programDetails && programDetails.description && programDetails.description : programDescription}
                onChange={e => handleInputChange(e, "description")}
              />
              {programDescription && programDescription !== "" && !descrRegex.test(programDescription) ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
              </p> : null
              }
            </Grid>
            
            {currentForm !== "view" && programType && programType === "BRAND" && country && country !== "" &&
              <Grid item xs={12} >
                <Autocomplete
                  options={brandArray} value={brand}
                  getOptionLabel={(option) => option}
                  onChange={(e, newValue) => handleChange(newValue, 'brand')}
                  // renderOption={(option, { selected }) => option }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Brand" 
                      InputLabelProps={{ shrink: true }} id="brand" name="brand" required
                    />
                  )}
                />
              </Grid>
            }

            {currentForm === "view" && programDetails && programDetails.type && programDetails.type === "BRAND" &&
              <Grid item xs={12}>
                <Autocomplete
                  options={brandArray} 
                  getOptionLabel={(option) => option} disabled
                  onChange={(e, newValue) => handleChange(newValue, 'brand')}
                  renderOption={(option, { selected }) => option }
                  value={ programDetails && programDetails.brandName && programDetails.brandName }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Brand" required
                      InputLabelProps={{ shrink: true }} id="brand" name="brand" disabled
                    />
                  )}
                />
              </Grid>
            }

            <Grid item xs={12} sm={6}>
              <FormGroup row>
                <FormControlLabel className={"formRedio"}
                  control={
                    <Checkbox
                      checked={ props.currentForm === "view" ? programDetails && programDetails.isDefault ? programDetails.isDefault : false : isDefault}
                      onChange={e => handleCheckBoxChange(e, "isDefault")}
                      name="isDefault" color="primary"
                      disabled={props.currentForm === 'view'}
                    />
                  }
                  label="Is Default"
                />
                <FormControlLabel className={"formRedio"}
                  control={
                    <Checkbox
                      checked={ props.currentForm === "view" ? programDetails && programDetails.showCrossBorder ? programDetails.showCrossBorder : false : showCrossBorder}
                      onChange={e => handleCheckBoxChange(e, "crossBorder")}
                      name="showCrossBorder"
                      color="primary"
                      disabled={props.currentForm === 'view' ? true : false}
                    />
                  }
                  label="Show Cross Border"
                />
              </FormGroup>
            </Grid>

            {(currentForm === "create" || currentForm === "edit") ? 
              programSchemesDetails.map((obj, index) => {
                return <div className={"paper_scroll"}>
                <Accordion className={classes.accordion} key={0} 
                    expanded={true} 
                >
                    <AccordionSummary  aria-controls="panel1a-content" id={"panel" + 1} >
                        <Typography className={classes.heading}>
                            { "Scheme Details"}  {index + 1}
                        </Typography>
                        {programSchemesDetails.length > 1 ?
                          <div className="deleteAccordIcon" 
                              onClick={() => handleSchemeDelete(index)}
                          >
                            <img alt="" src={DeleteIcon} />
                          </div> 
                          : null
                        }
                        
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container rowSpacing={2} columnSpacing={2}>
                            <Paper className={classes.subRangePaper}>
                                <React.Fragment key={0}>
                                    <Grid container rowSpacing={2} columnSpacing={2}>
                                      <Grid item xs={12} >
                                          <TextField 
                                              InputLabelProps={{ shrink: true }} 
                                              required
                                              fullWidth 
                                              variant="outlined"
                                              id={`issuerCountry-${index}`}
                                              name={`issuerCountry-${index}`}
                                              label="Issuer Country"
                                              select={currentForm === 'view' || isDefault ? false : true} 
                                              disabled={(programType  && programType === "" || isDefault ||
                                                (programType === "BRAND" && (brand === null || brand === "")))
                                              }
                                              value={ obj.issuerCountryName && obj.issuerCountryName } 
                                              // onBlur={() => handleOnBlur("country")}
                                              onChange={e => handleIssuerCountryChange(programSchemesDetails, index, "issuerCountry", e.target.value)}
                                          >
                                              {(issuerCountryArray).map((val, index) => {
                                                  return <MenuItem key={index} value={val} className={classes.menu}>
                                                      {val}
                                                  </MenuItem>
                                              })}
                                          </TextField>
                                      </Grid>
                                      <Grid item xs={12} >
                                          <TextField 
                                              InputLabelProps={{ shrink: true }} 
                                              required
                                              fullWidth 
                                              variant="outlined"
                                              id={`issuer-${index}`}
                                              name={`issuer-${index}`}
                                              label="Issuer"
                                              select={currentForm === 'view' ? false : true} 
                                              // disabled={programType  && programType !== "" ? false : true}
                                              disabled={programType  && programType !== "" && obj.issuerCountryName && obj.issuerCountryName !== "" ? false : true}
                                              value={ obj.issuerName && obj.issuerName } 
                                              // onBlur={() => handleOnBlur("country")}
                                              onChange={e => handleIssuerChange(index, "issuer", e.target.value)}
                                          >
                                              {(issuerList[index] && issuerList[index].issuerArr ? issuerList[index].issuerArr : []).map((val, index) => {
                                                return <MenuItem key={index} value={val} className={classes.menu}>
                                                  {val}
                                                </MenuItem>
                                              })}
                                          </TextField>
                                      </Grid>
                                      
                                      {programType && programType === "BRAND" ?
                                      <Grid item xs={12} >
                                        <Autocomplete
                                          multiple
                                          id={`checkboxes-tags-demo-${index}`}
                                          options={schemeList[index] && schemeList[index].schemeArr ? schemeList[index].schemeArr : []}
                                          // options={schemesArray && schemesArray.length > 0 ? schemesArray : []} 
                                          // disabled={programType  && programType !== "" ? false : true}
                                          disabled={programType  && programType !== "" && obj.issuerName && obj.issuerName !== "" ? false : true}
                                          disableCloseOnSelect
                                          getOptionLabel={(option) => option}
                                          value={obj.programSchemes.map((obj1) => {return obj1.schemeDescription})}
                                          onChange={(e, newValue) => {handleSchemeChange(e, newValue, index)}}
                                          renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                              <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                              />
                                              {option}
                                            </li>
                                          )}
                                          renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Schemes" placeholder="" required/>
                                          )}
                                        />
                                      </Grid> 
                                      : <Grid item xs={12} >
                                          <Autocomplete
                                            id={`checkboxes-tags-demo-${index}`}
                                            options={schemeList[index] && schemeList[index].schemeArr ? schemeList[index].schemeArr : []}
                                            disabled={programType  && programType !== "" && obj.issuerName && obj.issuerName !== "" ? false : true}
                                            getOptionLabel={(option) => option}
                                            value={obj.programSchemes && obj.programSchemes.length > 0 ? obj.programSchemes[0].schemeDescription  : ""}
                                            onChange={(e, newValue) => {handleSchemeChange(e, [newValue], index)}}
                                            renderOption={(props, option, { selected }) => (
                                              <li {...props}>
                                                <Checkbox
                                                  icon={icon}
                                                  checkedIcon={checkedIcon}
                                                  style={{ marginRight: 8 }}
                                                  checked={selected}
                                                />
                                                {option}
                                              </li>
                                            )}
                                            renderInput={(params) => (
                                              <TextField {...params} variant="outlined" label="Scheme" placeholder="" required/>
                                            )}
                                          />
                                       </Grid>
                                      }

                                    </Grid>
                                </React.Fragment>
                            </Paper>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                {index === programSchemesDetails.length - 1 ?
                  <Grid container className="step_action">
                    <Grid item justifyContent="flex-end" className={classes.addRange}>
                        {<Button className={"btn_addForm"} 
                          onClick={() => handleNewSchemeAdd()}
                          disabled={!programSchemeDetailsCheck()}
                        >
                            <AddCircleOutlineRoundedIcon className={classes.iconCircleOutline} />  Add New
                        </Button>}
                    </Grid>
                  </Grid> : null 
                }
              </div>
            
              }) : null
            }


            {currentForm === "view"  &&
              (programDetails && programDetails.programSchemes && programDetails.programSchemes.length > 0 ? programDetails.programSchemes : [])
              .map((obj, index) => {
                return <div className={"paper_scroll"}>
                <Accordion className={classes.accordion} key={0} 
                    expanded={true} 
                >
                    <AccordionSummary  aria-controls="panel1a-content" id={"panel" + 1} >
                        <Typography className={classes.heading}>
                            { "Scheme Details"}  {index + 1}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container rowSpacing={2} columnSpacing={2}>
                            <Paper className={classes.subRangePaper}>
                                <React.Fragment key={0}>
                                    <Grid container rowSpacing={2} columnSpacing={2}>
                                      <Grid item xs={12} >
                                          <TextField 
                                              InputLabelProps={{ shrink: true }} 
                                              required
                                              fullWidth 
                                              variant="outlined"
                                              id={`issuerCountry-${index}`}
                                              name={`issuerCountry-${index}`}
                                              label="Issuer Country"
                                              //select={currentForm === 'view' ? false : true} 
                                              disabled
                                              value={ obj.issuerCountryName && obj.issuerCountryName } 
                                              // onBlur={() => handleOnBlur("country")}
                                              // onChange={e => handleIssuerCountryChange(obj.id, "issuerCountry", e.target.value)}
                                          >
                                              {(issuerCountryArray).map((val, index) => {
                                                  return <MenuItem key={index} value={val} className={classes.menu}>
                                                      {val}
                                                  </MenuItem>
                                              })}
                                          </TextField>
                                      </Grid>
                                      <Grid item xs={12} >
                                          <TextField 
                                              InputLabelProps={{ shrink: true }} 
                                              required
                                              fullWidth 
                                              variant="outlined"
                                              id={`issuer-${index}`}
                                              name={`issuer-${index}`}
                                              label="Issuer"
                                              select={currentForm === 'view' ? false : true} 
                                              disabled
                                              value={ obj.issuerName && obj.issuerName } 
                                              // onBlur={() => handleOnBlur("country")}
                                              //onChange={e => handleIssuerChange(index, "issuer", e.target.value)}
                                          >
                                              {([]).map((val, index) => {
                                                  return <MenuItem key={index} value={val} className={classes.menu}>
                                                      {val}
                                                  </MenuItem>
                                              })}
                                          </TextField>
                                      </Grid>
                                      <Grid item xs={12} 
                                        // style={radio === 'Multi Select Option' ? { display: "block" } : { display: "none" }}
                                      >
                                        <Autocomplete 
                                          multiple
                                          id={`checkboxes-tags-demo-${index}`}
                                          options={[]} 
                                          disabled
                                          disableCloseOnSelect
                                          // onChange={(e, newValue) => {handleSchemeChange(e, newValue, obj.id)}}
                                          value={ obj.programSchemes}
                                          getOptionLabel={(option) => option.schemeDescription}
                                          renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                              <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                              />
                                              {option.schemeDescription}
                                            </li>
                                          )}
                                          renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Schemes" placeholder="" />
                                          )}
                                        />
                                      </Grid>

                                    </Grid>
                                </React.Fragment>
                            </Paper>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </div>
            
              }) 
            }

          </Grid>

          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button fullWidth variant="contained"
                color="primary" className={"action_login_button"}
                disabled={currentForm !== "view" ? !createButtonFlag : false}
                onClick={() => currentForm !== "view" ? handleCreateProgramSubmit() : onChangeView("list")}
              >
                {currentForm === "edit" ? "Modify" : currentForm === "view" ? "Close" : "Create"}
              </Button>
            </Grid>
          </Grid>

        </form>
      </Paper>
    </div>
  )
}

CreateEditViewProgram = reduxForm({
  form: 'CreateEditViewProgram',
  // validate: validate,
  enableReinitialize: true
})(CreateEditViewProgram);

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
        handleCountrySelect, handleProgramTypeSelect,
        handleStartDateSelect, handleEndDateSelect,
        handleDescriptionSelect, handleIsDefaultSelect,
        handleSchemeSelect, fetchCountryDetails,
        fetchProgramTypeDetails, fetchSchemeDetails,
        submitCreateOrEditProgramForm, handleProgramNameSelect,
        fetchBankSchemeDetails, handleBrandSelect,
        fetchBrandDetails, fetchIssuerCountryDetails,
        clearProgramRes, handleShowCrossBorderSelect
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
      country: state.ProgramReducer.country,
      programType: state.ProgramReducer.programType,
      startDate: state.ProgramReducer.startDate,
      endDate: state.ProgramReducer.endDate,
      programName: state.ProgramReducer.programName,
      programDescription: state.ProgramReducer.programDescription,
      brand: state.ProgramReducer.brand,
      isDefault: state.ProgramReducer.isDefault,
      showCrossBorder: state.ProgramReducer.showCrossBorder,
      // schemes: state.ProgramReducer.schemes,
      countryDetails: state.SchemeReducer.countryDetails && state.SchemeReducer.countryDetails !== null && state.SchemeReducer.countryDetails.countryList ? 
        state.SchemeReducer.countryDetails.countryList : [],
      // programTypeDetails: state.ProgramReducer.countryListDetails && state.ProgramReducer.countryListDetails !== null && state.ProgramReducer.countryListDetails.countryList ? 
      //   state.ProgramReducer.countryListDetails.countryList : [],
      brandSchemeDetails: state.ProgramReducer.brandSchemeListDetails && state.ProgramReducer.brandSchemeListDetails !== null && state.ProgramReducer.brandSchemeListDetails.picklistItems ? 
        state.ProgramReducer.brandSchemeListDetails.picklistItems : [],
      // bankSchemeDetails: state.ProgramReducer.bankSchemeListDetails && state.ProgramReducer.bankSchemeListDetails !== null && state.ProgramReducer.bankSchemeListDetails.bankSchemeList ? 
      //   state.ProgramReducer.bankSchemeListDetails.bankSchemeList : [],
      brandDetails: state.ProgramReducer.brandListDetails && state.ProgramReducer.brandListDetails !== null && state.ProgramReducer.brandListDetails.brands ? 
        state.ProgramReducer.brandListDetails.brands : [],
      issuerCountryDetails: state.ProgramReducer.issuerCountryListDetails && state.ProgramReducer.issuerCountryListDetails !== null && state.ProgramReducer.issuerCountryListDetails.issuer ? 
        state.ProgramReducer.issuerCountryListDetails.issuer : [],
      // createProgramRes: state.ProgramReducer.createProgramRes && state.ProgramReducer.createProgramRes,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditViewProgram);
