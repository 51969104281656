import { ProductCategoryActions } from "../consts/index";

const initialState = {
    productCategoryDetails: null,
    individualProductCategoryDetails: null,
    productCategoryName: null,
    displayName: null,
    description: null,
    parentCategory: null,
    parentCategoryDetails: null,
    createProductCategoryRes: null,
    uploadDocumentRes: null,
    categoryLoader: false,
    docUploadLoader: null
}

export default (state = initialState, {type, payload}) => {
    switch(type) {
        case ProductCategoryActions.handleProductCategoryNameChangeForCategory.SUCCESS:
            return {
                ...state,
                productCategoryName: payload,
            }
        case ProductCategoryActions.handleDisplayNameChangeForCategory.SUCCESS:
            return {
                ...state,
                displayName: payload,
            }
        case ProductCategoryActions.handleDescriptionChangeForCategory.SUCCESS:
            return {
                ...state,
                description: payload,
            }
        case ProductCategoryActions.handleParentCategoryChangeForCategory.SUCCESS:
            return {
                ...state,
                parentCategory: payload,
            }
        case ProductCategoryActions.handleProductCategoryDetailsFetch.REQUEST:
            return {
                ...state,
                categoryLoader: true,
            }
        case ProductCategoryActions.handleProductCategoryDetailsFetch.SUCCESS:
            return {
                ...state,
                categoryLoader: false,
                productCategoryDetails: payload,
            }
        case ProductCategoryActions.handleProductCategoryDetailsFetch.FAILURE:
            return {
                ...state,
                categoryLoader: false,
                productCategoryDetails: null,
            }   
        case ProductCategoryActions.handleIndividualProductCategoryDetailsFetch.REQUEST:
            return {
                ...state
            }
        case ProductCategoryActions.handleIndividualProductCategoryDetailsFetch.SUCCESS:
            return {
                ...state,
                individualProductCategoryDetails: payload,
            }
        case ProductCategoryActions.handleIndividualProductCategoryDetailsFetch.FAILURE:
            return {
                ...state,
                individualProductCategoryDetails: null,
            }   
        case ProductCategoryActions.handleParentCategoryDetailsFetchForCategory.REQUEST:
            return {
                ...state,
            }
        case ProductCategoryActions.handleParentCategoryDetailsFetchForCategory.SUCCESS:
            return {
                ...state,
                parentCategoryDetails: payload,
            }
        case ProductCategoryActions.handleParentCategoryDetailsFetchForCategory.FAILURE:
            return {
                ...state,
                parentCategoryDetails: null,
            }
        case ProductCategoryActions.handleCreateOrEditProductCategorySubmit.REQUEST:
            return {
                ...state
            }
        case ProductCategoryActions.handleCreateOrEditProductCategorySubmit.SUCCESS:
            return {
                ...state,
                createProductCategoryRes: payload
            }
        case ProductCategoryActions.handleCreateOrEditProductCategorySubmit.FAILURE:
            return {
                ...state,
                createProductCategoryRes: payload
            }
        case ProductCategoryActions.uploadDocumentForCategory.REQUEST: 
            return {
                ...state,
                categoryLoader: true,
                docUploadLoader: payload.key ? payload.key : null
            }
        case ProductCategoryActions.uploadDocumentForCategory.SUCCESS:
            return {
                ...state,
                categoryLoader: true,
                uploadDocumentRes: payload,
                docUploadLoader: null
            }
        case ProductCategoryActions.uploadDocumentForCategory.FAILURE: 
            return {
                ...state,
                categoryLoader: true,
                uploadDocumentRes: payload,
                docUploadLoader: null
            }

        default:
            return state;
    }
}