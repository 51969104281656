import React from 'react'
import {
    Grid, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../../../../_theme'
import {Delete} from '@mui/icons-material'

const useStyles = tss.create({
    root: {
      flexGrow: 1,
      maxWidth: 752,
      border: "solid 1px #EEEEEE",
    },
    demo: {
      backgroundColor: theme.palette.background.paper,
      border: "solid 1px #EEEEEE",
      paddingTop: "0",
      paddingLeft: "0",
      marginLeft: "16px"
    },
    title: {
      margin: theme.spacing(4, 0, 2),
    },
    headCell: {
        margin: "5px 5px",
        fontSize: "14px",
        fontWeight: "500",
    },
    tableCell: {
        margin: "5px 5px",
        fontSize: "14px",
        fontWeight: "40",
        
    },
    tableRow: {
        borderTop: "solid 1px #EEEEEE"
    },
    headRow: {
        backgroundColor: "#f0f5f4",
    }
});

const SelectedMappings = (props) => {
    const {classes} = useStyles();
    const {
        selectedMappings, handleRemoveMapping
    } = props
    
    return (
        <div className="paper_scroll">
            <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={12} sm={12} justify="flex-start" >
                    <Typography className={classes.title} variant="h6" id="selectedGroupTitle" component="div">
                        Selected Product Code Mappings
                    </Typography>
                </Grid>
                <Grid container className={classes.demo}>
                    <Grid container className={classes.headRow}>
                        <Grid item xs={3} sm={3}>
                            <Typography className={classes.headCell}>Brand Name</Typography>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <Typography className={classes.headCell}>PL Product Code</Typography>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <Typography className={classes.headCell}>Merchant Product Code</Typography>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <Typography className={classes.headCell}>Action</Typography>
                        </Grid>
                    </Grid>
                    {selectedMappings.map((obj, index) => {
                        return (
                            <Grid container className={classes.tableRow}>
                                <Grid item xs={3} sm={3}>
                                    <Typography className={classes.tableCell}>{obj.oemName}</Typography>
                                </Grid>
                                <Grid item xs={3} sm={3}>
                                    <Typography className={classes.tableCell}>{obj.plProductCode}</Typography>
                                </Grid>
                                <Grid item xs={3} sm={3}>
                                    <Typography className={classes.tableCell}>{obj.merchantProductCode}</Typography>
                                </Grid>
                                <Grid item xs={3} sm={3}>
                                    <IconButton style={{margin: "5px 5px"}} edge="center" aria-label="delete" onClick={() => {handleRemoveMapping(obj.id)}}>
                                        <Delete/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        </div>
    )
}

export default SelectedMappings