import React from 'react'
import { IconButton, Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';

export default function SnackbarToast(props) {
    const {
        openSnackbar, handleSnackBarAction, snackbarMsg, type
    } = props

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    });

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={openSnackbar}
            autoHideDuration={4000}
            onClose={() => handleSnackBarAction()}
            action={
            <React.Fragment>
            <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
                <CloseIcon fontSize="small" />
            </IconButton>
            </React.Fragment>
            }
        >
            <Alert 
                onClose={() => handleSnackBarAction()} 
                severity={type}
            >
            {snackbarMsg}
            </Alert>
        </Snackbar>
    )
}