import React, {useEffect} from 'react';
import {connect} from "react-redux";
// import {reduxForm} from 'redux-form';
import { bindActionCreators } from "redux";
import {SpecialCharRegex} from "../../../shared/utility";
import { NO_SPECIAL_CHARACTERS } from "shared/errorMessages";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid, TextField, Button, Paper, MenuItem, Checkbox, Typography } from '@mui/material';
import { tss } from "tss-react";
import theme from '../../../_theme'

const useStyles = tss.create({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    border: "solid 1px #EEEEEE",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(5),
  },
  input: {
    display: 'none',
  },
  menu: {
    fontSize: "15px"
  },
});
// const Store = [
//   {storeDisplayName: "MARUTI ENTERPRISE RAJULA", id: "6005bf36158c3b00e4a7f583"},
//   {storeDisplayName: "CHEF BAKERS", id: "6005bf36158c3b00e4a7f584"}
// ]
// const merchant = [
//   {name: "MARUTI ENTERPRISE RAJULA", id: "6005bebc158c3b00e4a7f57f"},
//   {name: "CHEF BAKERS", id: "6005bebc158c3b00e4a7f580"}
// ]
// const psp = [
//   {name: "Axel Bank", id: "6005b73c158c3b00e4a7f56d"},
//   {name: "ICCE Bank", id: "6005b73c158c3b00e4a7f56e"}
// ]

function CreateEditViewTerminal(props) {
  const {productDetails, handleInputChange, terminalState, countryDetails, currentForm,
    PSPList, merchantList, StoreList, handleTerminalCreateUpdate, nextDisableToken, onChangeView, hardwareTypeList
  } = props;
  const [valObj, setValObj] = React.useState({
    countryErr: false, pspErr: false, merchantErr: false, storeErr: false, extIdErr: false,
    hardSerNoErr: false, hardTypeErr: false, hardSubTypeErr: false, hardwareSubTypeIdErr: false
  })
  const {classes} = useStyles();
  const countryArr = countryDetails && countryDetails.map(country => country.name ? (country.name).toUpperCase() : null).filter(val => val !== null)
  //change merchant with merchantList when API added
  const merchantArr = merchantList && merchantList.map(merchant => merchant.name ? merchant.name : null).filter(val => val !== null)
  //change store to StoreList when API added.
  const storeArr = StoreList && StoreList.map(store => store.name ? store.name : null).filter(val => val !== null)
  //change psp to PSPList when API added
  const pspArr = PSPList && PSPList.map(psp => psp.name ? psp.name: null).filter(val => val !== null)
 
  const hardwareTypeArr = [];
  const hardwareTypeArrObj = hardwareTypeList && hardwareTypeList.map((hardwareType) => {
    hardwareTypeArr.push(hardwareType.hardwareFamily + "-" + hardwareType.name)
    return hardwareType;
  })

  const handleAddEvent = (e) => {
    if(e.keyCode === 13) {
      e.preventDefault()
    }  
  }

  const handleRemoveEvent = (e) => {
    if(e.keyCode === 13) {
      e.preventDefault()
    }  
  }

  useEffect(() => {
    const country = document.querySelector("#country")
    const psp = document.querySelector("#psp")
    const merchant = document.querySelector("#merchant")
    const store = document.querySelector("#store")

    country.addEventListener('keydown', (e) => handleAddEvent(e))
    psp.addEventListener('keydown', (e) => handleAddEvent(e))
    merchant.addEventListener('keydown', (e) => handleAddEvent(e))
    store.addEventListener('keydown', (e) => handleAddEvent(e))

    return () => {
      country.removeEventListener('keydown', (e) => handleRemoveEvent(e))
      psp.removeEventListener('keydown', (e) => handleRemoveEvent(e))
      merchant.removeEventListener('keydown', (e) => handleRemoveEvent(e))
      store.removeEventListener('keydown', (e) => handleRemoveEvent(e))
    }
  })

  const handleChange = (type, value) => {
    if(type === "country"){
      countryArr.indexOf((value).toUpperCase()) !== -1 && handleInputChange(type, [
        countryDetails[countryArr.indexOf((value).toUpperCase())]['isoCountryCodeNum'],
        value
      ])
      setValObj({...valObj, countryErr: false })
    } else if(type === "psp") {
      pspArr.indexOf(value) !== -1 && handleInputChange(type, [
        // PSPList
        PSPList[pspArr.indexOf(value)]['id'],
        value
      ])
      setValObj({...valObj, pspErr: false })
    } else if(type === "merchant") {
      let merchantUniqueId = null
      merchantArr.indexOf(value) !== -1 && merchantList[merchantArr.indexOf(value)]['pspObjList'] !== null &&
        merchantList[merchantArr.indexOf(value)]['pspObjList'].map(obj => {
          if(obj.pspObjId === terminalState.pspRefId && merchantUniqueId === null) {
            merchantUniqueId = obj.merchantUniqueId
          }
        })
        merchantUniqueId !== null && handleInputChange(type, [
        // merchantList
        merchantList[merchantArr.indexOf(value)]['id'],
        value,
        merchantUniqueId
      ])
      setValObj({...valObj, merchantErr: false })
    } else if(type === "store") {
      storeArr.indexOf(value) !== -1 && handleInputChange(type, [
        // StoreList
        StoreList[storeArr.indexOf(value)]['id'],
        value
      ])
      setValObj({...valObj, storeErr: false })
    }
    else  if(type === "hardwareSubTypeId"){
      hardwareTypeArr.indexOf((value)) !== -1 && handleInputChange(type, [
        hardwareTypeArrObj[hardwareTypeArr.indexOf((value))]['id'],
        hardwareTypeArrObj[hardwareTypeArr.indexOf((value))]['hardwareFamily'],
        hardwareTypeArrObj[hardwareTypeArr.indexOf((value))]['name']
        
      ])
      setValObj({...valObj, hardwareSubTypeIdErr: false })
    }
  }

  const handleOnBlur = (field, value) => {
    if(value === null || value === ""){
      setValObj({...valObj, [field]: true })
    } else {
      setValObj({...valObj, [field]: false })
    }
  }
  
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form className="action_form merchant_form" noValidate autoComplete="off">
          <Grid container rowSpacing={2} columnSpacing={2}>
            
            {currentForm === "edit" &&
              <>
                <Grid item xs={12} sm={8} />
                <Grid item xs={12} sm={4}>
                  <TextField 
                    InputLabelProps={{ shrink: true }} 
                    name="status" variant="outlined" select required
                    fullWidth id="status" label="Terminal status"
                    value={terminalState.status && terminalState.status}
                    onChange={(e) => handleInputChange('status', [e.target.value])}
                  >
                    {["ACTIVE", "INACTIVE"].map((val, index) => {
                      return <MenuItem key={index} value={val} className={classes.menu}>
                        {val}
                      </MenuItem>
                    })}
                  </TextField>
                </Grid>
              </>
            }
            
            <Grid item xs={12} sm={4}>
              <TextField 
                InputLabelProps={{ shrink: true }} 
                name="country" variant="outlined" select={currentForm === 'create'} required
                fullWidth id="country" label="Country" 
                disabled={currentForm !== 'create'}
                value={currentForm === 'view' ? 
                  productDetails !== null && productDetails.countryName && 
                  productDetails.countryName : terminalState.countryName ? 
                    terminalState.countryName : null
                } 
                onBlur={(e) => handleOnBlur("countryErr", e.target.value)}
                onChange={(e) => countryArr.length > 0 && handleChange('country', e.target.value)}
              >
                {countryArr && countryArr.length === 0 && 
                  <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                {countryArr && countryArr.map((val, index) => {
                  return <MenuItem key={index} value={val} className={classes.menu}>
                    {val}
                  </MenuItem>
                })}
              </TextField>
              {valObj.countryErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>
              :null}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                InputLabelProps={{ shrink: true }} 
                name="psp" variant="outlined" select={currentForm === 'create'} required
                fullWidth id="psp" label="PSP" 
                disabled={currentForm !== 'create'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails.pspName && 
                  productDetails.pspName : terminalState.pspName ? 
                    terminalState.pspName : null
                }
                onBlur={(e) => handleOnBlur("pspErr", e.target.value)}
                onChange={(e) => pspArr.length > 0 && handleChange('psp', e.target.value)}
              >
                {pspArr && pspArr.length === 0 && 
                  <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                {pspArr && pspArr.map((val, index) => {
                  return <MenuItem key={index} value={val} className={classes.menu}>
                    {val}
                  </MenuItem>
                })}
              </TextField>
              {valObj.pspErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>
              :null}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                InputLabelProps={{ shrink: true }} 
                name="merchant" variant="outlined" select={currentForm === 'create'} required
                fullWidth id="merchant" label="Merchant" 
                disabled={currentForm !== 'create'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails.merchantName && 
                  productDetails.merchantName : terminalState.merchantName ? 
                    terminalState.merchantName : null
                }
                onBlur={(e) => handleOnBlur("merchantErr", e.target.value)}
                onChange={(e) => merchantArr.length > 0 &&  handleChange('merchant', e.target.value)}
              >
                {merchantArr && merchantArr.length === 0 && 
                  <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                {merchantArr && merchantArr.map((val, index) => {
                  return <MenuItem key={index} value={val} className={classes.menu}>
                    {val}
                  </MenuItem>
                })}
              </TextField>
              {valObj.merchantErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>
              :null}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                InputLabelProps={{ shrink: true }} 
                name="merchantUniqueId" variant="outlined" disabled
                fullWidth id="merchantUniqueId" label="Merchant External ID" 
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails.merchantUniqueId && 
                  productDetails.merchantUniqueId : terminalState.merchantUniqueId ? 
                    terminalState.merchantUniqueId : " - "
                }
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField 
                InputLabelProps={{ shrink: true }} 
                name="store" variant="outlined" select={currentForm === 'create'} required
                fullWidth id="store" label="Store" 
                disabled={currentForm !== 'create'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails.storeName && 
                  productDetails.storeName : terminalState.storeName ? 
                    terminalState.storeName : null
                }
                onBlur={(e) => handleOnBlur("storeErr", e.target.value)}
                onChange={(e) => storeArr.length > 0 && handleChange('store', e.target.value)}
              >
                {storeArr && storeArr.length === 0 && 
                  <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                {storeArr && storeArr.map((val, index) => {
                  return <MenuItem key={index} value={val} className={classes.menu}>
                    {val}
                  </MenuItem>
                })}
              </TextField>
              {valObj.storeErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>
              :null}
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                InputLabelProps={{ shrink: true }} required
                name="externalId" id="externalId" fullWidth
                label="External Id" variant="outlined"
                disabled={currentForm !== 'create'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails.externalId && 
                  productDetails.externalId : terminalState.externalId ? 
                  terminalState.externalId : null
                }
                onChange={(e) => e.target.value.length <= 20 && handleInputChange('externalId', [e.target.value])}
              />
              {terminalState && terminalState.externalId !== "" && !SpecialCharRegex.test(terminalState.externalId) ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                </p>
              :null}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                InputLabelProps={{ shrink: true }} required
                name="hardwareSerialNumber" id="hardwareSerialNumber"
                label="Hardware Serial Number" variant="outlined" fullWidth
                disabled={currentForm === 'view'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails.hardwareSerialNumber && 
                  productDetails.hardwareSerialNumber : terminalState.hardwareSerialNumber ? 
                  terminalState.hardwareSerialNumber : null
                }
                onChange={(e) => e.target.value.length <= 30 && handleInputChange('hardwareSerialNumber', [e.target.value])}
              />
              {terminalState && terminalState.hardwareSerialNumber !== "" && !SpecialCharRegex.test(terminalState.hardwareSerialNumber) ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                </p>
              :null}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                InputLabelProps={{ shrink: true }} 
                name="hardwareType" id="hardwareType"
                label="Hardware Type" variant="outlined" 
                select={currentForm === 'create'} required fullWidth 
                disabled={currentForm !== 'create'}
                value={currentForm === 'view' ? 
                  productDetails !== null && productDetails.hardwareSubTypeId && productDetails.hardwareType && productDetails.hardwareSubType
                   && productDetails.hardwareType + '-'+ productDetails.hardwareSubType
                  : terminalState.hardwareSubTypeId && terminalState.hardwareType && terminalState.hardwareSubType
                   ? terminalState.hardwareType + '-'+ terminalState.hardwareSubType : null
                }
                onBlur={(e) => handleOnBlur("hardwareSubTypeIdErr", e.target.value)}
                onChange={(e) => hardwareTypeList.length > 0 && handleChange('hardwareSubTypeId', e.target.value)}
              >
                {hardwareTypeArr && hardwareTypeArr.length === 0 && 
                  <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                {hardwareTypeArr && hardwareTypeArr.map((val, index) => {
                  return <MenuItem key={index} value={val} className={classes.menu}>
                    {val}
                  </MenuItem>
                })}
              </TextField>
              {valObj.hardwareSubTypeIdErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>
              :null}
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <Checkbox
                  id="select-all-checkbox"
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled={currentForm === "view" ? true : false}
                  checked={
                    currentForm === 'view' ? 
                    productDetails !== null && productDetails.isDCC !== null && 
                    productDetails.isDCC : terminalState !== null && terminalState.isDCC !== null ? 
                    terminalState.isDCC : false
                  }
                  onChange={(e) => {
                    handleInputChange("isDCC", [e.target.checked])
                  }}
                />
                {"DCC Enabled Terminal"}
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm={4}>
              <TextField
                InputLabelProps={{ shrink: true }} 
                name="hardwareSubType" id="hardwareSubType"
                label="Hardware Sub Type" variant="outlined" fullWidth 
                disabled={currentForm === 'view'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails.hardwareSubType && 
                  productDetails.hardwareSubType : terminalState.hardwareSubType ? 
                  terminalState.hardwareSubType : null
                }
                onChange={(e) => e.target.value.length <= 30 && handleInputChange('hardwareSubType', [e.target.value])}
              />
              {terminalState && terminalState.hardwareSubType !== "" && !SpecialCharRegex.test(terminalState.hardwareSubType) ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! No Special Chars Allowed."}</span>
                </p>
              :null}
            </Grid> */}
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button fullWidth variant="contained"
                color="primary" className={"action_login_button"}
                disabled={nextDisableToken}
                onClick={() => currentForm !== "view" ? handleTerminalCreateUpdate() : onChangeView("list")}
              >
                {currentForm === "edit" ? "Modify" : currentForm === "view" ? "Close" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  )
}

// CreateEditViewTerminal = reduxForm({
//   form: 'CreateEditViewTerminal',
//   // validate: validate,
//   enableReinitialize: true
// })(CreateEditViewTerminal);

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
        
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditViewTerminal);
