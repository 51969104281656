import { MciActions } from "../consts/index";

const initialState = {
    mciLoader: false,
    mciList: null,
    mciCreateRes: null,
    mciCreateLoader: false,
    mciDeleteRes: null,
    mciDeleteLoader: false,
    fileTypeIdList: null,
    excelFileTemplateIdList: null,
    programTypesList: null,
    paymentModelTypesList: null,
    productTypesList: null,
}

export default (state = initialState, {type, payload}) => {
    switch(type) {
        case MciActions.fetchMciList.REQUEST:
            return {
                ...state,
                mciLoader: true
            }
        case MciActions.fetchMciList.SUCCESS:
            return {
                ...state,
                mciLoader: false,
                mciList: payload
            }
        case MciActions.fetchMciList.FAILURE:
            return {
                ...state,
                mciLoader: false,
                mciList: null,
            }
    
        case MciActions.createUpdateMci.REQUEST:
            return {
                ...state,
                mciCreateLoader: true
            }
        case MciActions.createUpdateMci.SUCCESS:
            return {
                ...state,
                mciCreateLoader: false,
                mciCreateRes: payload
            }
        case MciActions.createUpdateMci.FAILURE:
            return {
                ...state,
                mciCreateLoader: false,
                mciCreateRes: payload,
            }

        case MciActions.deleteMci.REQUEST:
            return {
                ...state,
                mciDeleteLoader: true
            }
        case MciActions.deleteMci.SUCCESS:
            return {
                ...state,
                mciDeleteLoader: false,
                mciDeleteRes: payload
            }
        case MciActions.deleteMci.FAILURE:
            return {
                ...state,
                mciDeleteLoader: false,
                mciDeleteRes: null,
            }

        case MciActions.fetchFileTypeId.REQUEST:
            return {
                ...state,
                mciLoader: true
            }
        case MciActions.fetchFileTypeId.SUCCESS:
            return {
                ...state,
                mciLoader: false,
                fileTypeIdList: payload
            }
        case MciActions.fetchFileTypeId.FAILURE:
            return {
                ...state,
                mciLoader: false,
                fileTypeIdList: null,
            }

        case MciActions.fetchExcelFileTemplateId.REQUEST:
            return {
                ...state,
                mciLoader: true
            }
        case MciActions.fetchExcelFileTemplateId.SUCCESS:
            return {
                ...state,
                mciLoader: false,
                excelFileTemplateIdList: payload
            }
        case MciActions.fetchExcelFileTemplateId.FAILURE:
            return {
                ...state,
                mciLoader: false,
                excelFileTemplateIdList: null,
            }

        case MciActions.fetchMCIProgramTypes.REQUEST:
            return {
                ...state,
                mciLoader: true
            }
        case MciActions.fetchMCIProgramTypes.SUCCESS:
            return {
                ...state,
                mciLoader: false,
                programTypesList: payload
            }
        case MciActions.fetchMCIProgramTypes.FAILURE:
            return {
                ...state,
                mciLoader: false,
                programTypesList: null,
            }

        case MciActions.fetchMCIPaymentModelTypes.REQUEST:
            return {
                ...state,
                mciLoader: true
            }
        case MciActions.fetchMCIPaymentModelTypes.SUCCESS:
            return {
                ...state,
                mciLoader: false,
                paymentModelTypesList: payload
            }
        case MciActions.fetchMCIPaymentModelTypes.FAILURE:
            return {
                ...state,
                mciLoader: false,
                paymentModelTypesList: null,
            }

        case MciActions.fetchMCIProductTypes.REQUEST:
            return {
                ...state,
                mciLoader: true
            }
        case MciActions.fetchMCIProductTypes.SUCCESS:
            return {
                ...state,
                mciLoader: false,
                productTypesList: payload
            }
        case MciActions.fetchMCIProductTypes.FAILURE:
            return {
                ...state,
                mciLoader: false,
                productTypesList: null,
            }

        default:
            return state;
    }
}