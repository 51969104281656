import React, { useState, useEffect } from 'react'
import {connect} from "react-redux";
import { bindActionCreators } from "redux";
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { 
    Grid, IconButton, Snackbar, Dialog, DialogActions,
    DialogTitle, DialogContentText, DialogContent, Button
} from '@mui/material'
import { tss } from "tss-react";
import theme from '../_theme'
  
import CloseImgIcon from '../Assets/close.svg';
import BasePageContainer from '../_common/basePageContainer'
import BasePageToolbar from '../_common/basePageToolbar'
import TableComponent from "./table/index"
import CreateEditViewCountry from "./createEditViewCountry/index";
import { 
    fetchCountryList, createUpdateCountry, resetCountryCreateResp, 
    fetchMasterCountryList,
} from "./actions/index"

const headCells = [
    { id: 'name', type: 'text', numeric: false, disablePadding: false, label: 'Country Name' },
    { id: 'isoCountryCode', type: 'text', numeric: false, disablePadding: false, label: 'Country Code' },
    { id: 'isoCountryCodeNum', type: 'text', numeric: false, disablePadding: false, label: 'Country Code No.' },
    { id: 'isoCurrencyCode', type: 'text', numeric: false, disablePadding: false, label: 'Currency Code' },
    { id: 'mobileRegexp', type: 'text', numeric: false, disablePadding: false, label: 'Mobile Country Code' },
    { id: 'status', type: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'postalCodeLength', type: 'text', numeric: false, disablePadding: false, label: 'Postal Code Length' },
    { id: 'createdOn', type: 'createdOn', numeric: true, disablePadding: false, label: 'Created On' },
    { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];

const CountryObj = {
    "id": "",
	"name": "",
	"isoCountryCode": "",
	"isoCountryCodeNum": "",
	"timezoneConfig": {
        "offset": "", 
        "timezone": ""
    },
	"languages": [],
	"status": "ACTIVE",
	"mobileRegexp": "",
	"phoneRegex": "",
	"postalCodeLength": "",
	"currencyHtmlCode": "",
    "isoCurrencyCode": "",
    "minorUnit": "",
}

const filterCells = [
    { id: 'isoCountryCodeNum', type: 'select', label: 'Country Name' },
    { id: 'isoCountryCode', type: 'text', label: 'Country Code' },
    { id: 'mobileRegexp', type: "text", label: "Mobile Country Code"}
  ];
  
const filterobj = {
    name: "",
    pageNo: 1,
    pageSize: 10,
    mobileRegexp: "",
    sortBy:"createdOn",
    isoCountryCode: "",
    sortDirection:"DESC",
    isoCountryCodeNum: "",
}

const sortArr = [
    {key:"createdOn", label: "Create Date"},
    {key:"name", label: "Country Name"},
    {key:"isoCountryCodeNum", label: "Country Code No."},
    {key:'isoCountryCode', label: "Country Code"},
    {key:"status", label: "Status"},
];

const Country = (props) => {
    const {classes} = useStyles();
    const { 
        countryList, countryLoader, countryCreateLoader, countryCreatRes, totalRecords, 
        masterCountryList
    } = props;

    const [pageNo, setPage] = useState(1);
    const [sortDir, setsortDir] = useState('DESC');
    const [filterData, setFilterData] = useState([]);
    const [clientRow, setClientRow] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [snackbarMsg, setSnackBarMsg] = useState(null);
    const [sortName, setsortName] = useState('createdOn');
    const [openPopover, setopenPopover] = useState(false);
    const [currentForm, setCurrentForm] = useState("list");
    const [openSnackbar, SetOpenSnackbar] = useState(false);
    const [filterState, setFilterState] = useState(filterobj);
    const [compInitaited, setCompInitiated] = useState(false);
    const [countryState, setCountryState] = useState(CountryObj)
    
    const currentPage = localStorage.getItem('currentPage')
    let permissions = localStorage.getItem("permissions")
    permissions = permissions && permissions !== null ? JSON.parse(permissions) : []

    const createDisableToken = currentForm !== "view" && countryState && countryState.name !== "" && countryState.name !== null

    const countryArr = countryList && countryList !== null ? 
        countryList.map(obj => {

            let countryObj = {
                ...obj,
                searchColumn: headCells.map((cell, index) => 
                    cell.id !== "action" &&  obj[cell.id] &&  obj[cell.id] !== "" ?  obj[cell.id] : "" 
                ).join(" ")
            }
            return countryObj
        }) : []
    
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    });
        
    const onChangeView = (inpval, row) => {
        setCurrentForm(inpval);
        if(inpval === "view") {
            setClientRow(row)
            
        } else if(inpval === "edit") {
            setCountryState(row)
            masterCountryList && masterCountryList.length === 0 && props.fetchMasterCountryList({"sortBy": "name", "sortDirection": "ASC",})

        } else if(inpval === "create") {
            setCountryState({...CountryObj})
            masterCountryList && masterCountryList.length === 0 && props.fetchMasterCountryList({"sortBy": "name", "sortDirection": "ASC",})
            
        } else {
            props.fetchCountryList({...filterState,
                pageNo: 1, sortBy: sortName, sortDirection: sortDir
                })
        }
    }

    useEffect(() => {
        if(currentPage && currentPage !== null && currentPage !== undefined && currentPage === "/country" && compInitaited){
            setCurrentForm('list')
            setFilterState({...filterobj});
            setPage(1);
            setsortDir("DESC");
            setsortName("createdOn")
            props.fetchCountryList({
                pageNo: 1, pageSize: 10,
                sortBy:"createdOn", sortDirection:"DESC"
                })
            localStorage.removeItem('currentPage')
        }
    }, [currentPage])

    useEffect(() => {
        const handleIntialCall = async () => {
            await setCompInitiated(true);
            await props.fetchCountryList({...filterState,
                pageNo: pageNo, sortBy: sortName, sortDirection: sortDir
            })
            await localStorage.removeItem('currentPage')
        }
        handleIntialCall();
    }, [])

    useEffect(() => {
        if(countryCreatRes && countryCreatRes !== null && countryCreatRes.st) {
            if(currentForm === "create" ) {
                setopenPopover(true)
            } else {
                SetOpenSnackbar(true)
                setSnackBarMsg(`Country Modified Successfully!`)
                onChangeView("list")
            }
            //reset user response
            props.resetCountryCreateResp()
        } else if(countryCreatRes && countryCreatRes !== null && !countryCreatRes.st) {
            SetOpenSnackbar(true)
            setSnackBarMsg(countryCreatRes.msg && countryCreatRes.msg)
            //reset user response
        }
    }, [countryCreatRes])

    const handleInputChange = (key, value) => {
        let updatedCountryState = {...countryState};
        
        if(key === "name") {
            updatedCountryState = {
                ...value[1],
                id: updatedCountryState.id,
                status: updatedCountryState.status,
                masterCountryId: value[1].id,
            }
        } else if(key === "status") {
            updatedCountryState = {
                ...value[1],
                id: updatedCountryState.id,
                [key]: value[0],
                masterCountryId: value[1].id,
            } 
        } else {
            updatedCountryState = {
                ...updatedCountryState,
                [key]: value[0]
            } 
        }

        setCountryState(updatedCountryState)
    }

    const handleCountryCreateUpdate = () => {
        let req = {
            ...countryState
        }

        props.createUpdateCountry(req)
    }

    const handleSnackBarAction = () => {
        SetOpenSnackbar(false)
        setSnackBarMsg(null)
        props.resetCountryCreateResp()
    }

    const handleDialogAction = async (type) => {
        setopenPopover(false)
        setCountryState(CountryObj)
        if(type === "exit" ){
          onChangeView('list')
        } else {
          await onChangeView('list')
          await onChangeView('create')
        }
    }
    
    const handleSetFilterData = (filterArray, searchValue) => {
        setFilterData(filterArray)
        setSearchValue(searchValue)
    }

    const handleFilterStateChange = (key, value) => {
        let tempFilter = {
            ...filterState,
            [key]: value
        }
        setFilterState(tempFilter);
    }
    
    const resetFilter = () => {
        setFilterState(filterobj);
        setPage(1);
        setsortDir("DESC");
        setsortName("createdOn");
        const payloadObj = {
            pageNo: 1,
            pageSize: 10,
            sortBy:"createdOn",
            sortDirection:"DESC"
        } 
        props.fetchCountryList(payloadObj)
    }

    const FilterApply = () => {
        setPage(1)
        const payloadObj = { ...filterState, pageNo: 1, sortBy: sortName, sortDirection: sortDir}
        props.fetchCountryList(payloadObj)
        setFilterState({...filterState});
    }

    const handlePageNo = (val) => {
        setPage(val)
        const payloadObj = { ...filterState, pageNo: val, sortBy: sortName, sortDirection: sortDir}
        props.fetchCountryList(payloadObj)
    }

    const handleSortDir = (value) => {
        setsortDir(value)
        setPage(1)
    }

    const handleSortName = (value) => {
        setsortName(value)
        setPage(1)
    }

    return (
        <>
            <BasePageToolbar currentForm={currentForm}  create={permissions.includes("46")} onChangeView={onChangeView} resetFilter={resetFilter} 
                title={currentForm === 'list' ? "Country" :  currentForm === 'view' ? "View Country" : currentForm === "edit" ? "Modify Country" : "Create Country"}
                handleFilterStateChange={handleFilterStateChange} filterState={filterState} filterCells={filterCells} FilterApply={FilterApply}
                setsortName={handleSortName} setsortDir={handleSortDir} sortDir={sortDir} sortName={sortName} sortArr={sortArr}
                arrayToFilter={countryArr} handleSetFilterData={handleSetFilterData} isTableSearchReq={false} searchValue={searchValue}
            ></BasePageToolbar>
            <BasePageContainer>

                {currentForm === 'list' ? <>
                    <Grid container className={classes.container}>
                        <TableComponent
                            sortOrder="desc" sortOrderBy="createdOn" //tab={activeTab}
                            onChangeView={onChangeView} handlePageNo={handlePageNo}
                            loading={countryLoader}
                            // loading={activeTab === 0 ? countryLoader : issCountryLoader}
                            headCells={headCells} activeTab={filterState}
                            rows={searchValue && searchValue !== "" ? filterData : countryArr}
                            // totalRecord={activeTab === 0 ? totalRecords : totalRecordsIss} 
                            totalRecord={totalRecords} 
                            // showEdit={false}
                            showEdit={permissions.includes("47")}
                        />
                    </Grid>
                </>
                : currentForm === 'create' ?
                <Grid container className={classes.formContainer}>
                    <CreateEditViewCountry 
                        currentForm={currentForm}
                        countryState={countryState}
                        handleInputChange={handleInputChange}
                        createDisableToken={!createDisableToken}
                        countryCreateLoader={countryCreateLoader}
                        handleCountryCreateUpdate={handleCountryCreateUpdate}
                        masterCountryList={masterCountryList}
                    />
                </Grid>
                : currentForm === "edit" ?
                <Grid container className={classes.formContainer}>
                    <CreateEditViewCountry 
                        currentForm={currentForm}
                        countryState={countryState}
                        handleInputChange={handleInputChange}
                        createDisableToken={!createDisableToken}
                        countryCreateLoader={countryCreateLoader}
                        handleCountryCreateUpdate={handleCountryCreateUpdate}
                        masterCountryList={masterCountryList}
                    />
                </Grid> :
                    <Grid container className={classes.formContainer}>
                        <CreateEditViewCountry 
                            currentForm={currentForm} 
                            countryDetails={clientRow}
                            onChangeView={onChangeView}
                        />
                    </Grid>
                }

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => handleSnackBarAction()}
                    action={
                        <React.Fragment>
                            <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
                            <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                >
                    <Alert onClose={() => handleSnackBarAction()} severity={
                        countryCreatRes && countryCreatRes !== null && !countryCreatRes.st ? "info" : "success"
                    }>
                        {snackbarMsg}
                    </Alert>
                </Snackbar>
                <Dialog
                    open={openPopover}
                    onClose={() => handleDialogAction("exit")}
                    className={"delete_dialog"}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="close_dialog" onClick={() => handleDialogAction("exit")}><img src={CloseImgIcon} alt="close" /></div>
                    <DialogTitle id="alert-dialog-title">
                        <h2>{"Country Created Successfully!"}</h2>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`Do you want to Create new Country?`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" className="yes" autoFocus
                            onClick={() => handleDialogAction("create")} 
                        >
                            Create New
                        </Button>
                        <Button color="primary" className="no"
                            onClick={() => handleDialogAction("exit")} 
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </BasePageContainer>
        </>
    )
}

const useStyles = tss.create({
    formContainer: {
        paddingTop: theme.spacing(0),
    },
    container: {
        paddingTop: theme.spacing(2),
    },
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            fetchCountryList, createUpdateCountry,
            resetCountryCreateResp, fetchMasterCountryList,
        }, dispatch)
    }
}

function mapStateToProps(state) {
  return {
    countryList: state.CountryReducer.countryList !== null && state.CountryReducer.countryList.countryList ? 
        state.CountryReducer.countryList.countryList : [],
    totalRecords: state.CountryReducer.countryList !== null && state.CountryReducer.countryList.totalRecords ?
        state.CountryReducer.countryList.totalRecords : 0,
    countryLoader: state.CountryReducer.countryLoader,
    countryCreateLoader: state.CountryReducer.countryCreateLoader,
    countryCreatRes: state.CountryReducer.countryCreatRes,
    masterCountryList: state.CountryReducer.masterCountryList !== null && state.CountryReducer.masterCountryList.countryList ? 
        state.CountryReducer.masterCountryList.countryList : [],
    masterCountryLoader: state.CountryReducer.masterCountryLoader,
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Country);
