import React from 'react'
// import PropTypes from 'prop-types'
import { tss } from "tss-react";

const AuthContent = props => {
  const {classes} = useStyles()

  return <div className={classes.paper}>{props.children}</div>
}

AuthContent.propTypes = {}

const useStyles = tss.create({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export default AuthContent
