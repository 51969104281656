import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Paper, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow
} from '@mui/material'
import { tss } from "tss-react";
import theme from '../../_theme'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';

function EnhancedTableHead(props) {
  const { classes, order, orderBy, headCells} = props;

  return (

    <TableHead>
       <TableRow  className={classes.leftCell}>
            <React.Fragment>
            {headCells.map((headCell, index) => {
                return <TableCell
                    width={`${Math.ceil(100/(headCells.length))}%`}
                    key={headCell.id} align= 'left'
                    sortDirection={orderBy === headCell.id ? order : false}
                    className={index === 0 ? `${classes.cellPadding} ${classes.headStyle} ${classes.paddingStyle}` : `${classes.cellPadding} ${classes.headStyle} ${classes.paddingStyle}`}
                >
                    {headCell.label}
                </TableCell>
              })}
            </React.Fragment>
        </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.array.isRequired,
};

const useStyles = tss.create({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    border: "solid 1px #EEEEEE",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  deleteDialog: {
    width: "100%",
    textAlign: 'center',
  },
  tabWidth: {
    minWidth: "103px"
  },
  leftCell: {
    color: "#ffffff !important",
    backgroundColor: "#1BA785 !important"
  },
  pagination: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
  cellPadding: {
    padding: "17px 10px 17px 10px !important",
  },
  headStyle: {
    color: "#ffffff !important",
  },
  paddingStyle: {
    paddingLeft: "10px !important",
  }
});

function EnhancedTable(props) {
const {classes} = useStyles();
  const {
    headCells, rows, loading, sortOrder, sortOrderBy, onChangeView, showEdit, 
    activeTab, showDelete, searchValue, totalRecord, handlePageNo, filterState
  } = props;

  const [order, setOrder] = useState(sortOrder);
  const [orderBy, setOrderBy] = useState(sortOrderBy);
  const [page, setPage] = useState(1);
  const [dense] = useState(false);
  const [rowsPerPage] = useState(10);
  const toggleMenu = useRef([]);
  const [activeTop, setactiveTop] = useState(0);
  const [activeLeft, setactiveLeft] = useState(627);

  const totalPages = Math.ceil(totalRecord/rowsPerPage) 
  const ref = useRef(null);

  const onOpenMoreMenu = (e, i) => {
    var element = document.getElementById(i + 'user');
    element.classList.add("active");
    setactiveTop(toggleMenu.current[i].offsetTop + 100);
    setactiveLeft(toggleMenu.current[i].offsetLeft - 130);
  }

  useEffect(() => {
    setPage(1);
  }, [activeTab, searchValue, filterState]);


  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onMouseOut();
      setactiveTop(0);
      setactiveLeft(0);
    }
  };
  const onMouseOut = (e) => {
    var element = document.getElementsByClassName('users_menu');
    for (let i = 0; i < element.length; i++) {
      if (element[i].classList.contains("active")) {
        element[i].classList.remove('active');
      }
    }
    setactiveTop(0)
    setactiveLeft(0)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChange = (event, value) => {
    setPage(value);
    handlePageNo && handlePageNo(value)
  };

  return (
    <div className={classes.root}>
        <Paper className={classes.paper}>
            <TableContainer>
            <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table"
            >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {!loading && 
                    rows.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          {headCells.map((headCell, headIndex) => {
                              return (headCell.type !== 'action') ? (
                                row[headCell.id] && row[headCell.id] !== "" ? 
                                  <TableCell 
                                    align="left"
                                    className={headIndex === 0 ? classes.cellPadding : classes.paddingStyle}
                                  >
                                    {headCell.type === 'date' && 
                                      `${row[headCell.id].slice(0,4)}-${row[headCell.id].slice(4,6)}-${row[headCell.id].slice(6)}`
                                    }
                                    {headCell.type === 'status' &&
                                      <div className={row[headCell.id]  === "Active" ? "badge badge-success" : row[headCell.id]  === "Inactive" ? "badge badge-danger" : null}>
                                        {row[headCell.id]}
                                      </div>
                                    }
                                    {headCell.type === 'createdOn' &&
                                      (row[headCell.id] !== null && row[headCell.id] !== "" ? 
                                        row[headCell.id].split("T")[0] && row[headCell.id].split("T")[1] && row[headCell.id].split("T")[1].split(".")[0] && 
                                        row[headCell.id].split("T")[1].split(".")[1] && row[headCell.id].split("T")[1].split(".")[1].split("+")[1] ?
                                          `${row[headCell.id].split("T")[0]}  ${row[headCell.id].split("T")[1].split(".")[0]} +${row[headCell.id].split("T")[1].split(".")[1].split("+")[1]}` : "_" : "_")
                                    }
                                    {headCell.type === 'description' &&
                                    <Tooltip title={row[headCell.id]}>
                                      <p>{row[headCell.id].length > 20 ? `${row[headCell.id].slice(0,20)}...` : row[headCell.id]}</p>
                                    </Tooltip>
                                    }
                                    {headCell.type !== 'date' && headCell.type !== 'status' && headCell.type !== 'createdOn' && headCell.type !== 'description' && 
                                      (row[headCell.id] !== null && row[headCell.id] !== "" ? row[headCell.id] : "_")
                                    }
                                </TableCell>
                                : <TableCell align="left">
                                  _
                                </TableCell> 
                            ) : (
                                <TableCell align="right" className="options" id={index + 'icon'} ref={el => (toggleMenu.current[index] = el)} width="3%">
                                  <div ref={ref}>
                                    <div onClick={(e) => onOpenMoreMenu(e, index)} className="icon-options-vertical"  ><MoreVertOutlinedIcon /></div>
                                    <div style={{ top: activeTop, left: activeLeft }} className="dropdown-menu user_menu users_menu" id={index + 'user'}>
                                      <div className="dropdown-item" onClick={()=> onChangeView("view", row)}><i className="view_icon" />&nbsp; View</div>

                                      {showEdit &&  row.installmentEndDate && (new Date().getTime() <= 
                                        (new Date(`${(row.installmentEndDate).slice(0,4)}-${(row.installmentEndDate).slice(4,6)}-${(row.installmentEndDate).slice(6)}`)).getTime()) &&
                                        (props.type !== "BANK" || row.isIssuerPLManaged === true) && row.statusCode !== "DELETE" &&
                                        <div className="dropdown-item" onClick={()=> onChangeView("edit", row)}><i className="edit_icon" />&nbsp; Modify</div>
                                      }
                                      {row.statusCode !== "DELETE" && showDelete && row.installmentEndDate && (new Date().getTime() <= 
                                        (new Date(`${(row.installmentEndDate).slice(0,4)}-${(row.installmentEndDate).slice(4,6)}-${(row.installmentEndDate).slice(6)}`)).getTime()) &&
                                        (props.type !== "BANK" || row.isIssuerPLManaged === true) &&
                                        <div className="dropdown-item" onClick={()=> onChangeView("delete", row)}><i className="delete_icon" />&nbsp; Delete</div>
                                      }
                                      
                                      {props.showApprove && (props.type === "BANK" || row.isIssuerPLManaged === true) &&
                                      <div className="dropdown-item" onClick={()=> props.onIconActions(row, "approve")}><i className="active_icon" />&nbsp; Approve</div>
                                      }
                                    </div>
                                  </div>
                                </TableCell>
                              )
                          })}
                        </TableRow>
                        );
                  })}
                  {!loading && rows && rows.length === 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * 10 }}>
                      <TableCell colSpan={6} style={{paddingLeft: "47%"}}> 
                        {"No Data Found!"}
                      </TableCell>
                    </TableRow>
                  )}
                  {loading && (
                    <TableRow style={{ height: (dense ? 33 : 53) * 10 }}>
                      <TableCell colSpan={6} style={{paddingLeft: "47%"}}> 
                        <CircularProgress color="secondary" style={{
                          "width": "70px", "height": "70px"}}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Grid container justifyContent="center">
          <Grid item>
            <div className={classes.pagination}>
              <Pagination count={totalPages} page={page} onChange={handleChange} color="primary" />
            </div>
          </Grid>
        </Grid>
    </div>
  );
}

export default EnhancedTable;