import React, {useState, useEffect} from 'react';
import { bindActionCreators } from "redux";
import {connect} from "react-redux";
import {
  Grid, TextField, Menu, MenuItem,
  Button, Paper, InputAdornment,
  FormGroup, FormControlLabel, Checkbox,
  Accordion, AccordionSummary, AccordionDetails, Typography
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../../_theme'
import Autocomplete from '@mui/material/Autocomplete';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import addDays from "date-fns/addDays";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
  handleCountrySelect, handleProgramTypeSelect,
  handleStartDateSelect, handleEndDateSelect,
  handleDescriptionSelect, handleIsDefaultSelect,
  handleSchemeSelect, fetchCountryDetails,
  fetchProgramTypeDetails, fetchSchemeDetails,
  submitCreateOrEditProgramForm, handleProgramNameSelect,
  fetchBankSchemeDetails, handleBrandSelect,
  fetchBrandDetails, fetchIssuerCountryDetails,
  clearProgramRes, handleShowCrossBorderSelect,
  resetBrandSchemeDetails, resetBankSchemeDetails,
  resetIndividualProgram,
} from "../actions";
import DeleteIcon from '../../Assets/delete.svg';
import {descrRegex} from "../../shared/utility";
import { NO_SPECIAL_CHARACTERS } from "shared/errorMessages";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = tss.create({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    border: "solid 1px #EEEEEE",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(5),
  },
  input: {
    display: 'none',
  },
  menu: {
    fontSize: "15px"
  },
  accordion: {
    backgroundColor: "#FAFAFA",
    marginBottom: "20px",
    padding: "0px 0px",
  },
  subRangePaper: {
    width: '100%',
    padding: "0px 10px 0px 10px",
    backgroundColor: "transparent",
  },
  heading: {
    color: "#424242",
    fontFamily: "Quicksand-SemiBold",
    // fontSize: "16px",
    fontSize: "18px",
    letterSpacing: "0",
    lineHeight: "20px",
  },
  addRange: {
    alignItems: "center",
    display: "flex",
  },
});

const programTypeList = [ {name: "CIB", displayName: "BANK"}, {name: "MIB", displayName: "MERCHANT"}, {name: "BRAND", displayName: "BRAND"} ];

function EditProgram(props) {
  let programSchemeObj = {
    issuerIsoCountryCodeNum: "",
    issuerCountryName: "",
    issuerName: "",
    issuerId: "",
    programSchemes: []
  }
  const {classes} = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEndEl, setAnchorEndEl] = useState(null);
  // const [radio, setRadio] = useState(radioList[1])
  const [issuerSelectedArr, setIssuerSelectedArr] = useState([]);
  const [brandState, setBrandState] = useState(false)
  const [status, setStatus] = useState("ACTIVE");
  const [brandID, setBrandId] = useState(null);
  const [issuerMissingIindex, setIssuerMissingIndex] = useState(null)
  
  const {
    country, programType, startDate, endDate, programName, programDescription, 
    brand, isDefault, showCrossBorder, brandSchemeDetails, brandDetails, issuerCountryDetails,
    programDetails, currentForm, progId, setIssuerList, setSchemeList, issuerList, schemeList,
    handleDetailsSet, detailsSet, programSchemesDetails, setProgramSchemesDetails
  } = props;
  
  const reqProgramId = currentForm === "edit" ? 
      progId ? progId : "" 
      : "" 

  const programSchemeDetailsCheck = () => {
    let retFlag = programSchemesDetails && programSchemesDetails.length > 0;
    programSchemesDetails && programSchemesDetails.length > 0 &&
      programSchemesDetails.map((obj) => {
        if(retFlag) {
          retFlag = obj.issuerCountryName && obj.issuerCountryName !== "" &&
          obj.issuerName && obj.issuerName !== "" &&
          obj.programSchemes && obj.programSchemes.length > 0
        }
        return null;
      })

    return retFlag;
  }

  const createButtonFlag = currentForm !== "view" && country && country !== "" && programType && programType !== "" && 
    (programDescription === "" || programDescription === null ||
      (programDescription !== "" && programDescription !== null && descrRegex.test(programDescription))) &&
    ((programType === "BRAND" && brand && brand !== null) || programType !== "BRAND") && startDate && startDate !== "" && 
    endDate && endDate !== "" && programName && programName !== "" && programSchemesDetails && programSchemesDetails.length > 0 && 
    programSchemeDetailsCheck()

  let issuerCountryArray = []
  let issuerCountryCodeArr = []
  issuerCountryDetails && issuerCountryDetails !== null && issuerCountryDetails.length > 0 && 
      issuerCountryDetails.map(obj => {
        if(issuerCountryArray.indexOf((obj.countryName).toUpperCase()) === -1) {
          issuerCountryCodeArr.push(obj.isoCountryCodeNum)
          issuerCountryArray.push((obj.countryName).toUpperCase())
        }
        return obj
    });

  let brandObj = []
  const brandArray = brandDetails && brandDetails !== null && brandDetails.length >0 ? 
      brandDetails.map(obj => {
        brandObj.push(obj)
        return obj.name
    }) : [];

  useEffect(() => {
    const handleBrandArrChange = async () => {
      if(brandArray && brandArray.length > 0 && brand && brand !== null && !brandArray.includes(brand) && !brandState) {
        await setBrandState(true)
        await props.handleBrandSelect("")
        await window.alert("Selected Brand is deactivate! Please select Other Brand.")
      }
    }
    handleBrandArrChange();
  }, [brandArray])

  useEffect(() => {
    props.fetchIssuerCountryDetails({});
  }, [])

  useEffect(() => {
    if(issuerCountryDetails && issuerCountryDetails.length > 0 && programDetails && currentForm === "edit" && !detailsSet) {
      handleDetailsSet(true)

      setStatus(programDetails.status)
      props.handleCountrySelect(programDetails.countryName && programDetails.countryName);
      props.handleProgramTypeSelect(programDetails.type && programDetails.type);
      if ( programDetails.type === "BRAND") {
        props.fetchBrandDetails({ status: "ACTIVE", "isoCountryCodeNum" : programDetails.isoCountryCodeNum,
          "pageNo": 1, "pageSize":1000, "sortBy": "name", "sortDirection": "ASC" });
      }
      props.handleStartDateSelect(programDetails.startDate && programDetails.startDate !== "" ? new Date(programDetails.startDate) : "");
      props.handleEndDateSelect(programDetails.endDate && programDetails.endDate !== "" ? new Date(programDetails.endDate) : "");
      props.handleProgramNameSelect(programDetails.name && programDetails.name);
      props.handleDescriptionSelect(programDetails.description && programDetails.description);
      props.handleIsDefaultSelect(programDetails.isDefault && programDetails.isDefault);
      props.handleShowCrossBorderSelect(programDetails.showCrossBorder && programDetails.showCrossBorder);
      props.handleBrandSelect(programDetails.brandName && programDetails.brandName);
      setBrandId(programDetails.brandId && programDetails.brandId)

      const tempIssuerSelectedArr = [];
      // setting issuer list for dropdown
      programDetails.programSchemes && programDetails.programSchemes !== null && 
        programDetails.programSchemes.map(async (obj, index) => {
          let tempIssuerArray = []
          const tempIssuerObj = issuerCountryDetails && issuerCountryDetails !== null && issuerCountryDetails.length >0 ? 
            issuerCountryDetails.filter(issuerObj => {
              if((obj.issuerCountryName).toUpperCase() === (issuerObj.countryName).toUpperCase()) {
                tempIssuerArray.push(issuerObj.name)
              }
              return (obj.issuerCountryName).toUpperCase() === (issuerObj.countryName).toUpperCase()
          }) : []
          
          const tempIssuer = issuerList;
          tempIssuer.push({issuerArr: tempIssuerArray, issuerObjArr: tempIssuerObj})
          const reqMcId = obj.issuerName && obj.issuerName !== "" && tempIssuer[index].issuerArr.indexOf(obj.issuerName) !== -1 &&
              tempIssuer[index].issuerObjArr[tempIssuer[index].issuerArr.indexOf(obj.issuerName)].mciId ?
              tempIssuer[index].issuerObjArr[tempIssuer[index].issuerArr.indexOf(obj.issuerName)].mciId : "";

          reqMcId !== "" && reqMcId !== null && tempIssuerSelectedArr.push(reqMcId);
          setIssuerList(tempIssuer);

        return {
          obj
        }
      })
      setIssuerSelectedArr(tempIssuerSelectedArr);

      setSchemeList({schemeArr: [], schemeObjArr: []})

      setProgramSchemesDetails(programDetails.programSchemes)
    }
  }, [programDetails, issuerCountryDetails])

  useEffect(() => {
    resetSchemeListFromApiResponse()
  }, [brandSchemeDetails])

  const resetSchemeListFromApiResponse = () => {

    let schemesArrayObj = []
    // const schemesArray = programType && programType !== "" &&
    //   brandSchemeDetails && brandSchemeDetails !== null && brandSchemeDetails.length > 0 ? 
    //   brandSchemeDetails.map(obj => {
    //     schemesArrayObj.push({id: obj.id, installmentConfigID: obj.code, installmentConfigDesc: obj.name})
    //     return obj.name
    //   }) :[]

    const schemesArray = programType && programType !== "" &&
        brandSchemeDetails && brandSchemeDetails !== null && brandSchemeDetails.length > 0 ? 
          brandSchemeDetails.map(obj => {
            if(programType === "CIB") {
              if(obj.name && obj.name !== "" && !(((obj.name).toUpperCase()).includes("[TEMPLATE ONLY]") || ((obj.name).toUpperCase()).includes("[TEMPLATEONLY]"))) {
                schemesArrayObj.push({id: obj.id, installmentConfigID: obj.code, installmentConfigDesc: obj.name})
                return obj.name
              } else {
                return null
              }
            } else {
              schemesArrayObj.push({id: obj.id, installmentConfigID: obj.code, installmentConfigDesc: obj.name})
              return obj.name
            }
      }).filter(name => name !== null) :[]
      
    setSchemeList({
      schemeArr: schemesArray,
      schemeObjArr: schemesArrayObj,
    });
  }

  const handleInputChange = (e, type) => {
    if(type === "description"){
        props.handleDescriptionSelect(e.target.value)
    } 
  }
  
  const handleBrandChange = (value) => {
    if(isDefault) {
      handleIssuerCountryChange([{...programSchemeObj}], 0, "issuerCountry", country, true)
    } else {
      setProgramSchemesDetails([{...programSchemeObj}])
    }
    setIssuerSelectedArr([]);

    if(brandArray.indexOf(value) !== -1) {
      setBrandId(brandDetails[brandArray.indexOf(value)]["id"])
    } else {
      setBrandId(null)
    }
    props.handleBrandSelect(value)
  }

  function handleStartDateOpen(event) {
    if (currentForm !== 'view') {
      setAnchorEl(event.currentTarget)
    }
  }
  function handleEndDateOpen(event) {
    if (currentForm !== 'view') {
      setAnchorEndEl(event.currentTarget)
    }
  }
  function handleClose() {
    setAnchorEl(null)
  }
  function handleEndClose() {
    setAnchorEndEl(null)
  }

  const startDateSelect = (date) => {
    props.handleStartDateSelect(date);
    props.handleEndDateSelect(null);
    setAnchorEl(null)
  }
  const endDateSelect = (date) => {
    if(isDefault) {
      handleIssuerCountryChange([{...programSchemeObj}], 0, "issuerCountry", country, true)
    } else {
      setProgramSchemesDetails([{...programSchemeObj}])
    }
    setIssuerSelectedArr([]);

    props.handleEndDateSelect(date);
    setAnchorEndEl(null)
  }

  const handleCreateProgramSubmit = () => {
    const reqPayload = {
      programId: reqProgramId,
      name: programName,
      description: programDescription,
      type: programType,
      isoCountryCodeNum: programDetails.isoCountryCodeNum,
      countryName: country && country !== "" ? country : "",
      status: status,
      isDefault: isDefault,
      showCrossBorder: showCrossBorder,
      startDate: startDate && startDate !== "" ? moment(startDate).format('YYYY-MM-DD') : "",
      enddate: endDate && endDate !== "" ? moment(endDate).format('YYYY-MM-DD') : "",
      brandId: programType === "BRAND" && brand && brand !== "" && brandArray.indexOf(brand) !== -1 ? brandObj[brandArray.indexOf(brand)].id : null,
      brandName: programType === "BRAND" && brand && brand !== "" ? brand : "",
      programSchemes: programSchemesDetails,
    }
    props.submitCreateOrEditProgramForm(reqPayload);
    //calling final API for Creating Program
  }

  const handleNewSchemeAdd = () => {
    if(!isDefault){
      setProgramSchemesDetails([...programSchemesDetails, {
        ...programSchemeObj
      }])

      setIssuerList([...issuerList, {
        issuerArr: [],
        issuerObjArr: []
      }])

      setSchemeList({
        schemeArr: [],
        schemeObjArr: []
      })
    }
     
    if(isDefault) {
      const progArr = [...programSchemesDetails, {
          ...programSchemeObj
        }]
      handleIssuerCountryChange(progArr, 0, "issuerCountry", country, true, true)
    }
  }

  const handleSchemeDelete = async (id) => {
    let tempissuerSelectedArr = [...issuerSelectedArr]
    tempissuerSelectedArr = [...tempissuerSelectedArr.slice(0, id), ...tempissuerSelectedArr.slice(id+1)]
    setIssuerSelectedArr(tempissuerSelectedArr);

    let tempSchemesDetails = programSchemesDetails.filter((obj, index) => {
      
      return index !== id
    })
    setProgramSchemesDetails(tempSchemesDetails)

    let tempIssuer = issuerList.filter((obj, index) => {
      
      return index !== id
    })
    setIssuerList(tempIssuer)

    setSchemeList({
      schemeArr: [],
      schemeObjArr: []
    })

    await props.resetBrandSchemeDetails(null)
    await props.resetBankSchemeDetails(null)
  }

  const handleIssuerCountryChange = async (progArr, schemeIndex, key, value, isDefault, addNew) => {

    const Value = isDefault ? (value).toUpperCase() : value;
    const flag = addNew ? progArr.length - 1 : null; 
    const tempSchemesDetails = await progArr.map((obj, index) => {
      if(isDefault || (index === schemeIndex)) {
          return {
              ...obj,
              issuerIsoCountryCodeNum: issuerCountryArray.indexOf(Value) !== -1 && issuerCountryCodeArr[issuerCountryArray.indexOf(Value)],
              issuerCountryName: Value,
              issuerId: flag !== null && index !== flag ? obj.issuerId : "",
              issuerName: flag !== null && index !== flag ? obj.issuerName : "",
              programSchemes: flag !== null && index !== flag ? obj.programSchemes : []
          }
      } else {
          return obj
      }
    })
    await setProgramSchemesDetails(tempSchemesDetails);

    Value && await handleSetIssuerListState(isDefault ? 0 : schemeIndex, Value, addNew);

    await props.resetBrandSchemeDetails(null)
    await props.resetBankSchemeDetails(null)
  }

  const handleSetIssuerListState = async (schemeIndex, value, addNew) => {

    let tempIssuerArray = []
    const tempIssuerObj = issuerCountryDetails && issuerCountryDetails !== null && issuerCountryDetails.length > 0 ? 
      await issuerCountryDetails.filter(obj => {
        if((value).toUpperCase() === (obj.countryName).toUpperCase()) {
          tempIssuerArray.push(obj.name)
        }
        return (obj.countryName).toUpperCase() === (value).toUpperCase()
    }) : []
    
    const issuerTempArr = addNew ? [...issuerList, {
      issuerArr: [],
      issuerObjArr: []
    }] : [...issuerList];

    const tempIssuer = await issuerTempArr.map((obj, index) => {
      if(isDefault || (index === schemeIndex)) {
          return {
            ...obj,
            issuerArr: tempIssuerArray,
            issuerObjArr: tempIssuerObj,
          }
      } else {
          return obj
      }
    })
    
    await setIssuerList(tempIssuer);
    await setSchemeList({
      schemeArr: [],
      schemeObjArr: []
    })
  }

  const handleIssuerChange = async (schemeIndex, key, value) => {
    const mciId = issuerList[schemeIndex].issuerArr.indexOf(value) !== -1 ?
      issuerList[schemeIndex].issuerObjArr[issuerList[schemeIndex].issuerArr.indexOf(value)].mciId : ""
    
    if(issuerSelectedArr.includes(mciId)) {
      window.alert("Issuer already selected");

    } else {
      const tempIssuerSelectedArr = [...issuerSelectedArr];
      tempIssuerSelectedArr[schemeIndex] = mciId
      mciId !== "" && mciId !== null && setIssuerSelectedArr(tempIssuerSelectedArr)

      const tempSchemesDetails = await programSchemesDetails.map((obj, index) => {
        if(index === schemeIndex) {
            return {
                ...obj,
                issuerName: value,
                issuerId: issuerList[schemeIndex].issuerArr.indexOf(value) !== -1 ? 
                  issuerList[schemeIndex].issuerObjArr[issuerList[schemeIndex].issuerArr.indexOf(value)].id : "",
                programSchemes: []
            }
        } else {
            return obj
        }
      })
      await setProgramSchemesDetails(tempSchemesDetails);

      await setSchemeList({
        schemeArr: [],
        schemeObjArr: []
      })
      await props.resetBrandSchemeDetails(null)
      await props.resetBankSchemeDetails(null)

      const reqMcId = issuerList[schemeIndex].issuerArr.indexOf(value) !== -1 ?
        issuerList[schemeIndex].issuerObjArr[issuerList[schemeIndex].issuerArr.indexOf(value)].mciId : "" ;
      // const reqCountryCodeNum = issuerList[schemeIndex].issuerObjArr[issuerList[schemeIndex].issuerArr.indexOf(value)].isoCountryCodeNum ;
      await fetchSchemeMetaData(schemeIndex, value, reqMcId);
    }
  }

  const fetchSchemeMetaData = (schemeIndex, value, reqMcId) => {
    let brandIdObj = {}
    if(brandID !== null) {
      brandIdObj = {
        brandId: brandID
      }
    }
    if( value && value !== "" && programType && programType !== "" ) {
      if ( programType === "BRAND" ) {
        schemeList && schemeList.schemeArr && schemeList.schemeArr.length === 0 &&
        props.fetchSchemeDetails(
          {"picklistType": "BRAND_SCHEME", "programType": "BRAND", "mciId": reqMcId,
          "startDate": moment(startDate).format('YYYY-MM-DD'),
          "endDate": moment(endDate).format('YYYY-MM-DD'), ...brandIdObj});
      } else if ( programType === "MIB" ) {
        schemeList && schemeList.schemeArr && schemeList.schemeArr.length === 0 &&
        props.fetchSchemeDetails(
          {"picklistType": "BRAND_SCHEME", "programType": "MERCHANT", "mciId": reqMcId,
          "startDate": moment(startDate).format('YYYY-MM-DD'),
          "endDate": moment(endDate).format('YYYY-MM-DD'), ...brandIdObj});
      } else if ( programType === "CIB" ) {
        schemeList && schemeList.schemeArr && schemeList.schemeArr.length === 0 &&
        props.fetchSchemeDetails({"picklistType": "BANK_SCHEME", "mciId": reqMcId,
        "startDate": moment(startDate).format('YYYY-MM-DD'),
        "endDate": moment(endDate).format('YYYY-MM-DD'), ...brandIdObj});
      }
    }
  }

  const handleSchemeListState = (e, schemeIndex, value) => {
    let brandIdObj = {}
    if(brandID !== null) {
      brandIdObj = {
        brandId: brandID
      }
    }
    const reqMcId = issuerList[schemeIndex].issuerObjArr[issuerList[schemeIndex].issuerArr.indexOf(value)].mciId ;
    if( value && value !== "" && programType && programType !== "" ) {
      if ( programType === "BRAND" ) {
        schemeList && schemeList.schemeArr && schemeList.schemeArr.length === 0 &&
        props.fetchSchemeDetails({"picklistType": "BRAND_SCHEME", "programType": "BRAND", "mciId": reqMcId,
        "startDate": moment(startDate).format('YYYY-MM-DD'),
        "endDate": moment(endDate).format('YYYY-MM-DD'), ...brandIdObj
      });
        //props.fetchSchemeDetails({"id" :"", "programType" : "BRAND", "status" : "", "offset": 1, "pageSize": 10, "mciId": reqMcId, "isoCountryCodeNum": ""});
      } else if ( programType === "MIB" ) {
        schemeList && schemeList.schemeArr && schemeList.schemeArr.length === 0 &&
        props.fetchSchemeDetails({"picklistType": "BRAND_SCHEME", "programType": "MERCHANT", "mciId": reqMcId,
        "startDate": moment(startDate).format('YYYY-MM-DD'),
        "endDate": moment(endDate).format('YYYY-MM-DD'), ...brandIdObj
      });
        // props.fetchSchemeDetails({"id" :"", "programType" : "MERCHANT", "status" : "", "offset": 1, "pageSize": 10, "mciId": reqMcId, "isoCountryCodeNum": ""});
      } else if ( programType === "CIB" ) {
        schemeList && schemeList.schemeArr && schemeList.schemeArr.length === 0 &&
        props.fetchSchemeDetails({"picklistType": "BANK_SCHEME", "mciId": reqMcId,
        "startDate": moment(startDate).format('YYYY-MM-DD'),
        "endDate": moment(endDate).format('YYYY-MM-DD'), ...brandIdObj
      });
        //props.fetchBankSchemeDetails({"id": "","status" : "","offset": 1, "pageSize": 10, "mciId": reqMcId, "isoCountryCodeNum": ""})
      }
    }
  }

  const handleSchemeOnBlur = async (e, schemeIndex) => {
    await props.resetBrandSchemeDetails(null)
    await props.resetBankSchemeDetails(null)

    await setSchemeList({
      schemeArr: [],
      schemeObjArr: [],
    });
  }

  const handleSchemeChange = async (e, value, id, issName) => {
    
    let tempValue = []
    if(schemeList && schemeList.schemeArr && schemeList.schemeArr.length === 0) {

      tempValue = await programSchemesDetails && programSchemesDetails[id] && programSchemesDetails[id].programSchemes &&
      programSchemesDetails[id].programSchemes.map(val => {
        return {
          id: value.indexOf(val.schemeDescription) !== -1 && val.id ? val.id : "",
          instalmentConfigId: value.indexOf(val.schemeDescription) !== -1 && val.instalmentConfigId ? val.instalmentConfigId : "",
          schemeDescription: value[value.indexOf(val.schemeDescription)]
        }
      }).filter(obj => obj.id !== "");

      handleSchemeListState(e, id, issName);
    } else {
      tempValue = await value.map(val => {
        return {
          id: schemeList.schemeArr.indexOf(val) !== -1 && schemeList.schemeObjArr[schemeList.schemeArr.indexOf(val)].id ? 
            schemeList.schemeObjArr[schemeList.schemeArr.indexOf(val)].id : "",
          instalmentConfigId: schemeList.schemeArr.indexOf(val) !== -1 && schemeList.schemeObjArr[schemeList.schemeArr.indexOf(val)] && 
            schemeList.schemeObjArr[schemeList.schemeArr.indexOf(val)].installmentConfigID ?
            schemeList.schemeObjArr[schemeList.schemeArr.indexOf(val)].installmentConfigID : "",
          schemeDescription: val
        }
      }).filter(obj => obj.id !== "");
    }

    const tempSchemesDetails = await programSchemesDetails.map((obj, index) => {
      if(index === id) {
          return {
              ...obj,
              programSchemes: tempValue,
          }
      } else {
          return obj
      }
    })
    await setProgramSchemesDetails(tempSchemesDetails);
  }

  const handleIssuerMissing = (index, obj) => {
    
    if(issuerMissingIindex !== index && issuerList[index] && issuerList[index].issuerArr && 
      !(issuerList[index].issuerArr).includes(obj.issuerName)){
        setIssuerMissingIndex(index)
        handleIssuerChange(index, "issuer", "")
    }
  }
  
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form className="action_form merchant_form" noValidate autoComplete="off">
          <Grid container rowSpacing={2} columnSpacing={2}>

            {currentForm === "edit" &&
              <>
                <Grid item xs={12} sm={8} />
                <Grid item xs={12} sm={4}>
                  <TextField 
                      InputLabelProps={{ shrink: true }} 
                      name="status" variant="outlined" select required
                      fullWidth id="status" label="Product Category status"
                      value={ status }
                      onChange={(e) => setStatus(e.target.value)}
                  >
                      {["ACTIVE", "INACTIVE"].map((val, index) => {
                      return <MenuItem key={index} value={val} className={classes.menu}>
                          {val}
                      </MenuItem>
                      })}
                  </TextField>
                </Grid>
              </>
            }

            <Grid item xs={12} sm={3}>
                <TextField 
                    InputLabelProps={{ shrink: true }} 
                    required
                    fullWidth 
                    variant="outlined"
                    id="country" 
                    name="country" 
                    label="Country"
                    // select 
                    disabled
                    value={ country } 
                    // onBlur={() => handleOnBlur("country")}
                    // onChange={e => handleChange(e, "country")}
                >
                    {/* {(countryArray).map((val, index) => {
                        return <MenuItem key={index} value={val} className={classes.menu}>
                            {val}
                        </MenuItem>
                    })} */}
                </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextField 
                    InputLabelProps={{ shrink: true }} 
                    required
                    fullWidth 
                    variant="outlined"
                    id="programType" 
                    name="programType" 
                    label="Program Type"
                    select
                    disabled
                    value={ currentForm === "view" ? programDetails && programDetails.type && programDetails.type : programType } 
                    // onBlur={() => handleOnBlur("country")}
                    // onChange={e => handleChange(e, "programType")}
                >
                    {(programTypeList).map((val, index) => {
                        return <MenuItem key={index} value={val.name} className={classes.menu}>
                            {val.displayName}
                        </MenuItem>
                    })}
                </TextField>
            </Grid>
            <Grid item xs={12} sm={3} className={"calender_input " + (startDate ? 'MuiInputLabel_shrink' : '')}>
                <TextField label="Start Date" name="startDate" id={"startDate"} 
                InputLabelProps={{ shrink: true }} 
                // className="startDate_field"
                variant="outlined" fullWidth required
                onClick={(e) => (new Date().getTime() < new Date(startDate).getTime()) && handleStartDateOpen(e)}
                value={startDate && startDate !== "" ? moment(startDate).format('YYYY-MM-DD') : ""} 
                InputProps={{
                    endAdornment: (
                    <InputAdornment position="end" >
                        <DateRangeIcon className={"calender_input"} />
                    </InputAdornment>
                    ),
                }} disabled={moment(new Date()).format('YYYY-MM-DD') >= moment(startDate).format('YYYY-MM-DD')} />
                <Menu
                id={"simpleStart"} anchorEl={anchorEl}
                keepMounted open={Boolean(anchorEl)}
                onClose={handleClose} elevation={1}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                classes={{ paper: classes.profileMenu, }}>
                <Calendar color="#1BA785" rangeColors={['#1BA785']}
                    // onInit={startDateSelect}
                    onChange={startDateSelect}
                    minDate={addDays(new Date(), 1)}
                />
                </Menu>
            </Grid>
            <Grid item xs={12} sm={3} className={endDate ? 'MuiInputLabel_shrink' : '  '}>
                <TextField name="endDate"  id={"endDate"}
                // className="endDate_field"
                InputLabelProps={{ shrink: true }} required
                label="End Date" variant="outlined" fullWidth 
                onClick={(e) => ((endDate && new Date().getTime() < new Date(endDate).getTime()) || endDate === null) && handleEndDateOpen(e)}
                value={endDate && endDate !== "" ? moment(endDate).format('YYYY-MM-DD') : ""}
                InputProps={{
                    endAdornment: (
                    <InputAdornment position="end"  >
                        <DateRangeIcon className={"calender_input"} />
                    </InputAdornment>
                    ),
                }} disabled={moment(new Date()).format('YYYY-MM-DD') >= moment(endDate).format('YYYY-MM-DD')}
                ></TextField>
                <Menu
                id={"simpleEnd"} anchorEl={anchorEndEl} keepMounted
                open={Boolean(anchorEndEl)} onClose={handleEndClose}
                elevation={1}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                classes={{ paper: classes.profileMenu, }}>
                <Calendar color="#1BA785" rangeColors={['#1BA785']}
                    // onInit={endDateSelect}
                    onChange={endDateSelect}
                    minDate={startDate && startDate !== ""  ? addDays(startDate, 1) : addDays(new Date(), 2)}
                />
                </Menu>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                name="name" id="name" label="Program Name"
                variant="outlined" fullWidth required
                disabled value={ programName }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                name="description" id="description" label="Program Description"
                variant="outlined" fullWidth value={programDescription}
                onChange={e => handleInputChange(e, "description")}
              />
              {programDescription && programDescription !== "" && !descrRegex.test(programDescription) ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
              </p> : null
              }
            </Grid>
            
            {programType && programType === "BRAND" &&
              <Grid item xs={12} >
                <Autocomplete
                  options={brandArray} value={ brandArray.includes(brand) ? brand : "" }
                  getOptionLabel={(option) => option}
                  onChange={(e, newValue) => handleBrandChange(newValue)}
                  // renderOption={(option, { selected }) => option }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Brand" 
                      InputLabelProps={{ shrink: true }} id="brand" name="brand" required
                    />
                  )}
                />
              </Grid>
            }
            
            <Grid item xs={12} sm={6}>
              <FormGroup row>
                <FormControlLabel className={"formRedio"}
                  control={
                    <Checkbox
                      checked={ props.currentForm === "view" ? programDetails && programDetails.isDefault ? programDetails.isDefault : false : isDefault}
                      // onChange={e => handleCheckBoxChange(e, "isDefault")}
                      name="isDefault"
                      color="primary"
                      disabled
                    />
                  }
                  label="Is Default"
                />
                <FormControlLabel className={"formRedio"}
                  control={
                    <Checkbox
                      checked={ props.currentForm === "view" ? programDetails && programDetails.showCrossBorder ? programDetails.showCrossBorder : false : showCrossBorder}
                      // onChange={e => handleCheckBoxChange(e, "crossBorder")}
                      name="showCrossBorder"
                      color="primary"
                      disabled
                    />
                  }
                  label="Show Cross Border"
                />
              </FormGroup>
            </Grid>

            {
              programSchemesDetails.map((obj, index) => {
                handleIssuerMissing(index, obj)
                return <div className={"paper_scroll"}>
                <Accordion className={classes.accordion} key={0} 
                    expanded={true} 
                >
                    <AccordionSummary  aria-controls="panel1a-content" id={"panel" + 1} >
                        <Typography className={classes.heading}>
                            { "Scheme Details"}  {index + 1}
                        </Typography>
                        {programSchemesDetails.length > 1 ?
                          <div className="deleteAccordIcon" 
                              onClick={() => handleSchemeDelete(index)}
                          >
                              <img alt="" src={DeleteIcon} />
                          </div> 
                          : null
                        }
                        
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container rowSpacing={2} columnSpacing={2}>
                            <Paper className={classes.subRangePaper}>
                                <React.Fragment key={0}>
                                    <Grid container rowSpacing={2} columnSpacing={2}>
                                      <Grid item xs={12} >
                                          <TextField 
                                              InputLabelProps={{ shrink: true }} 
                                              required
                                              fullWidth 
                                              variant="outlined"
                                              id={`issuerCountry-${index}`}
                                              name={`issuerCountry-${index}`}
                                              label="Issuer Country"
                                              select={currentForm === 'view' || isDefault ? false : true} 
                                              disabled={(programType  && (programType === "" || isDefault ||
                                                (programType === "BRAND" && (brand === null || brand === ""))))
                                              }
                                              value={ obj.issuerCountryName && obj.issuerCountryName } 
                                              // onBlur={() => handleOnBlur("country")}
                                              onChange={e => handleIssuerCountryChange(programSchemesDetails, index, "issuerCountry", e.target.value)}
                                          >
                                              {(issuerCountryArray).map((val, index) => {
                                                  return <MenuItem key={index} value={val} className={classes.menu}>
                                                      {val}
                                                  </MenuItem>
                                              })}
                                          </TextField>
                                      </Grid>
                                      <Grid item xs={12} >
                                          <TextField 
                                              InputLabelProps={{ shrink: true }} 
                                              required
                                              fullWidth 
                                              variant="outlined"
                                              id={`issuer-${index}`}
                                              name={`issuer-${index}`}
                                              label="Issuer"
                                              select={currentForm === 'view' ? false : true} 
                                              // disabled={programType  && programType !== "" ? false : true}
                                              disabled={programType  && programType !== "" && obj.issuerCountryName && obj.issuerCountryName !== "" ? false : true}
                                              value={ obj.issuerName && obj.issuerName } 
                                              // onBlur={() => handleOnBlur("country")}
                                              onChange={e => handleIssuerChange(index, "issuer", e.target.value)}
                                          >
                                              {(issuerList[index] && issuerList[index].issuerArr ? issuerList[index].issuerArr : []).map((val, index) => {
                                                  return <MenuItem key={index} value={val} className={classes.menu}>
                                                      {val}
                                                  </MenuItem>
                                              })}
                                          </TextField>
                                      </Grid>
                                      {programType && programType === "BRAND" ?
                                      <Grid item xs={12} style={{ display: "block" }}>
                                        <Autocomplete className={"multi_field"}
                                          multiple 
                                          id={`checkboxes-tags-demo-${index}`}
                                          options={schemeList && schemeList.schemeArr && schemeList.schemeArr.length > 0 ? schemeList.schemeArr : []}
                                          // options={schemesArray && schemesArray.length > 0 ? schemesArray : []} 
                                          // disabled={programType  && programType !== "" ? false : true}
                                          disabled={programType  && programType !== "" && obj.issuerName && obj.issuerName !== "" ? false : true}
                                          disableCloseOnSelect
                                          getOptionLabel={(option) => option}
                                          value={obj.programSchemes.map((obj1) => {return obj1.schemeDescription})}
                                          onChange={(e, newValue) => {handleSchemeChange(e, newValue, index, obj.issuerName)}}
                                          renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                              <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                              />
                                              {option}
                                            </li>
                                          )}
                                          renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Schemes" placeholder="" required
                                              onFocus={(e) => {handleSchemeListState(e, index, obj.issuerName)}}
                                              onBlur={(e) => handleSchemeOnBlur(e, index)}
                                            />
                                          )}
                                        />
                                      </Grid>
                                      : <Grid item xs={12} >
                                          <Autocomplete className={"multi_field"}
                                            id={`checkboxes-tags-demo-${index}`}
                                            options={schemeList && schemeList.schemeArr && schemeList.schemeArr.length > 0 ? schemeList.schemeArr : []}
                                            disabled={programType  && programType !== "" && obj.issuerName && obj.issuerName !== "" ? false : true}
                                            getOptionLabel={(option) => option}
                                            value={obj.programSchemes && obj.programSchemes.length > 0 ? obj.programSchemes[0].schemeDescription  : ""}
                                            onChange={(e, newValue) => {handleSchemeChange(e, [newValue], index, obj.issuerName)}}
                                            renderOption={(props, option, { selected }) => (
                                              <li {...props}>
                                                <Checkbox
                                                  icon={icon}
                                                  checkedIcon={checkedIcon}
                                                  style={{ marginRight: 8 }}
                                                  checked={selected}
                                                />
                                                {option}
                                              </li>
                                            )}
                                            renderInput={(params) => (
                                              <TextField {...params} variant="outlined" label="Scheme" placeholder="" required
                                                onFocus={(e) => {handleSchemeListState(e, index, obj.issuerName)}}
                                                onBlur={(e) => handleSchemeOnBlur(e, index)}
                                              />
                                            )}
                                          />
                                        </Grid>
                                      }
                                    </Grid>
                                </React.Fragment>
                            </Paper>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                {index === programSchemesDetails.length - 1 ?
                <Grid container className="step_action">
                  <Grid item justifyContent="flex-end" className={classes.addRange}>
                      {<Button className={"btn_addForm"} 
                        onClick={() => handleNewSchemeAdd()} 
                        disabled={!programSchemeDetailsCheck()}
                      >
                          <AddCircleOutlineRoundedIcon className={classes.iconCircleOutline} />  Add New
                      </Button>}
                  </Grid>
                </Grid> : null }
              </div>
            
              }) 
            }

          </Grid>

          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button fullWidth variant="contained"
                color="primary" className={"action_login_button"}
                onClick={handleCreateProgramSubmit}
                disabled={!createButtonFlag}
                >
                Modify
               </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  )
}

// EditProgram = reduxForm({
//   form: 'EditProgram',
//   // validate: validate,
//   enableReinitialize: true
// })(EditProgram);

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
        handleCountrySelect, handleProgramTypeSelect,
        handleStartDateSelect, handleEndDateSelect,
        handleDescriptionSelect, handleIsDefaultSelect,
        handleSchemeSelect, fetchCountryDetails,
        fetchProgramTypeDetails, fetchSchemeDetails,
        submitCreateOrEditProgramForm, handleProgramNameSelect,
        fetchBankSchemeDetails, handleBrandSelect,
        fetchBrandDetails, fetchIssuerCountryDetails,
        clearProgramRes, handleShowCrossBorderSelect,
        resetBrandSchemeDetails, resetBankSchemeDetails,
        resetIndividualProgram,
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
      country: state.ProgramReducer.country,
      programType: state.ProgramReducer.programType,
      startDate: state.ProgramReducer.startDate,
      endDate: state.ProgramReducer.endDate,
      programName: state.ProgramReducer.programName,
      programDescription: state.ProgramReducer.programDescription,
      brand: state.ProgramReducer.brand,
      isDefault: state.ProgramReducer.isDefault,
      showCrossBorder: state.ProgramReducer.showCrossBorder,
      // schemes: state.ProgramReducer.schemes,
      // countryDetails: state.ProgramReducer.countryListDetails && state.ProgramReducer.countryListDetails !== null && state.ProgramReducer.countryListDetails.countryList ? 
      //   state.ProgramReducer.countryListDetails.countryList : [],
      // programTypeDetails: state.ProgramReducer.countryListDetails && state.ProgramReducer.countryListDetails !== null && state.ProgramReducer.countryListDetails.countryList ? 
      //   state.ProgramReducer.countryListDetails.countryList : [],
      brandSchemeDetails: state.ProgramReducer.brandSchemeListDetails && state.ProgramReducer.brandSchemeListDetails !== null && state.ProgramReducer.brandSchemeListDetails.picklistItems ? 
        state.ProgramReducer.brandSchemeListDetails.picklistItems : [],
      // bankSchemeDetails: state.ProgramReducer.bankSchemeListDetails && state.ProgramReducer.bankSchemeListDetails !== null && state.ProgramReducer.bankSchemeListDetails.bankSchemeList ? 
      //   state.ProgramReducer.bankSchemeListDetails.bankSchemeList : [],
      brandDetails: state.ProgramReducer.brandListDetails && state.ProgramReducer.brandListDetails !== null && state.ProgramReducer.brandListDetails.brands ? 
        state.ProgramReducer.brandListDetails.brands : [],
      issuerCountryDetails: state.ProgramReducer.issuerCountryListDetails && state.ProgramReducer.issuerCountryListDetails !== null && state.ProgramReducer.issuerCountryListDetails.issuer ? 
        state.ProgramReducer.issuerCountryListDetails.issuer : [],
      // createProgramRes: state.ProgramReducer.createProgramRes && state.ProgramReducer.createProgramRes,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProgram);
