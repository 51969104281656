import { actionCreator, BASE_URL } from "../../../shared/utility";
import { TerminalActions } from "../consts/index";

export function fetchTerminalDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(TerminalActions.fetchTerminalDetails.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/getTerminalList`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(TerminalActions.fetchTerminalDetails.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(TerminalActions.fetchTerminalDetails.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetTerminalDetails() {
    return(dispatch) => {
        dispatch(actionCreator(TerminalActions.fetchTerminalDetails.FAILURE))
    }
}

export function resetTerminalCreateResp() {
    return(dispatch) => {
        dispatch(actionCreator(TerminalActions.createTerminalDetails.FAILURE, null))
    }
}

export function createTerminal(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(TerminalActions.createTerminalDetails.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/addOrEditTerminal`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(TerminalActions.createTerminalDetails.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(TerminalActions.createTerminalDetails.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function deleteTerminal(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(TerminalActions.deleteTerminal.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/deleteTerminal`, {
            method: 'DELETE',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(TerminalActions.deleteTerminal.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(TerminalActions.deleteTerminal.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetTerminalDeleteResp() {
    return(dispatch) => {
        dispatch(actionCreator(TerminalActions.deleteTerminal.FAILURE, null))
    }
}

export function fetchHardwareTypes(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(TerminalActions.fetchHardwareTypes.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/getHardwareTypes`, 
           { method: 'GET',
           // body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(TerminalActions.fetchHardwareTypes.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(TerminalActions.fetchHardwareTypes.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

