import React, {useEffect} from 'react';
import { bindActionCreators } from "redux";
import {connect} from "react-redux";
import {reduxForm} from 'redux-form';
import { Grid, TextField, Button, Paper, MenuItem, Typography 
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../../../_theme'
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {fetchProgramPickList} from "../actions/index";
import {fetchTccMccList} from "../../../manageScheme/actions/index"
import { EmailRegex, SpecialCharRegex1, SpecialCharRegex, addRegex, nameRegExp, descrRegex, urlRegex, numbRegex } from "../../../shared/utility";
import { NO_NUMBERS_AND_SPECIAL_CHARACTERS, NO_SPECIAL_CHARACTERS, ONLY_NUMBERS } from "shared/errorMessages";
import PSPMapping from '../pspMapping/index.js';

const useStyles = tss.create({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    border: "solid 1px #EEEEEE",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(5),
  },
  input: {
    display: 'none',
  },
  menu: {
    fontSize: "15px"
  },
  container: {
    paddingTop: theme.spacing(5),
  },
});

function CreateEditViewMerchant(props) {
  let postCodeToken = false;
  const {
    productDetails, handleInputChange, merchantState, countryDetails, currentForm,
    PSPList, handleMerchantCreateUpdate, nextDisableToken, onChangeView, cibProgPicklist,
    mibProgPicklist, brandProgPicklist, TccMccList, handleProgIdReset, selectedPSPs, 
    pspIntegrationModeList, handleAddNewMappedPSP, handleDeletePSP, mappedPSPList, merchantMetadata
  } = props;
  
  const [valObj, setValObj] = React.useState({
    countryErr: false, pspErr: false, merchantNameErr: false, displayNameErr: false, legalNameErr: false,
    merchantDescErr: false, bussNoErr:false, busEmailErr: false, websiteErr: false, mccErr: false,
    userNameErr: false, userPhoneErr: false, userMobileErr: false, userEmailErr: false,
    add1Err: false, add2Err: false, add3Err: false, stateErr: false, cityErr: false, pinErr: false
  })
  const [CIBset, setCib] = React.useState(false)
  const [MIBset, setMib] = React.useState(false)
  const [Brandset, setBrand] = React.useState(false)
  const [reset, setReset] = React.useState(false)
  const [activePage, setActivePage] = React.useState(0)
  const [currPSPObj, setCurrPSPObj] = React.useState({
    pspName: "",
    pspIntegrationMode: "",
    merchantUniqueId: "",
  })
  
  const {classes} = useStyles();
  const countryArr = countryDetails && countryDetails.map(country => country.name ? (country.name).toUpperCase() : null).filter(val => val !== null)
  // const pspArr = PSPList && PSPList.map(psp => psp.name ? psp.name: null).filter(val => val !== null)
  let pspIdArr = []
  const pspArr = PSPList && PSPList.map(psp => {
    if(psp.id && psp.id !== "" && psp.name && psp.name !== "") {
      pspIdArr.push(psp.id);
      return psp.name
    } else return null
  }).filter(val => val !== null)

  const legalNameArr = merchantMetadata && merchantMetadata.map(obj => {
    if (merchantState && merchantState.legalName && obj.legalName && obj.legalName !== "" && (obj.legalName).toUpperCase().includes((merchantState.legalName).toUpperCase())) {
      return obj.legalName
    } else return null
  }).filter(val => val !== null)  

  let mccArr = []
  let mccObj = {}
  let selectedMcc = ""
  TccMccList !== null && TccMccList.map(obj => mccArr.push(
    ...(obj.mccList.map(mcc => {
      if(currentForm === 'view' && productDetails && productDetails['mcc'] && productDetails['mcc'] === mcc.mccCode) {
        selectedMcc = mcc.mccCode + "-" + mcc.mccName
      } else if(currentForm !== 'view' && merchantState && merchantState['mcc'] && merchantState['mcc'] === mcc.mccCode) {
        selectedMcc = mcc.mccCode + "-" + mcc.mccName
      }
      mccObj[mcc.mccCode + "-" + mcc.mccName] = mcc.mccCode;
      return mcc.mccCode + "-" + mcc.mccName
    }))
  ));
  mccArr = mccArr.sort();

  const handleAddEvent = (e) => {
    if(e.keyCode === 13) {
      e.preventDefault()
    }  
  }

  const handleRemoveEvent = (e) => {
    if(e.keyCode === 13) {
      e.preventDefault()
    }  
  }

  useEffect(() => {

    if(activePage !== 0) {
      return () => {

      }
    }
    const country = document.querySelector("#country")
    // const psp = document.querySelector("#psp")
    const elemNum0 = document.querySelector('.number_field0')
    const elemNum1 = document.querySelector('.number_field1')
    const elemNum2 = document.querySelector('.number_field2')

    handleAdEventListner(elemNum0)
    handleAdEventListner(elemNum1)
    handleAdEventListner(elemNum2)
    country.addEventListener('keydown', (e) => handleAddEvent(e))
    // psp.addEventListener('keydown', (e) => handleAddEvent(e))

    document.addEventListener("wheel", function(event){
      if(document.activeElement.type === "number"){
        document.activeElement.blur();
      }
    });

    return () => {
      handleRemoveListener(elemNum0)
      handleRemoveListener(elemNum1)
      handleRemoveListener(elemNum2)
      country.removeEventListener('keydown', (e) => handleRemoveEvent(e))
      // psp.removeEventListener('keydown', (e) => handleRemoveEvent(e))
      
      document.removeEventListener("wheel", function(event){
        if(document.activeElement.type === "number"){
            document.activeElement.blur();
        }
      });
    }
  })

  useEffect(() => {
    props.fetchTccMccList({})
    handleDataFetchOnEdit()
  }, [])

  const handleDataFetchOnEdit = async () => {
    if(currentForm !== "credit") {
      const Req = {
        "picklistType": "PROGRAM",
        "isoCountryCodeNum": currentForm === 'view' &&
          productDetails && productDetails['isoCountryCodeNum'] 
          ? productDetails['isoCountryCodeNum']
            : merchantState && merchantState['isoCountryCodeNum'] 
            ? merchantState['isoCountryCodeNum'] : "",
        "mccId":  currentForm === 'view' &&
          productDetails && productDetails['mcc'] 
          ? productDetails['mcc']
            : merchantState && merchantState['mcc'] 
            ? merchantState['mcc'] : "",
      }
      await props.fetchProgramPickList({...Req, schemeProgramType: "CIB"}, "CIB")
      await props.fetchProgramPickList({...Req, schemeProgramType: "MIB"}, "MIB")
      await props.fetchProgramPickList({...Req, schemeProgramType: "BRAND"}, "BRAND")
    }
  }

  const handleAdEventListner = (element) => {
    element && element.addEventListener('keydown', function(e) {
      if (e.keyCode === 38 || e.keyCode === 40) {
          e.preventDefault();
      }
    });
  }

  const handleRemoveListener = (element) => {
    element && element.removeEventListener('keydown', function(e) {
      if (e.keyCode === 38 || e.keyCode === 40) {
          e.preventDefault();
      }
    })
  }

  const handleOnBlur = (field, value) => {
    if(value === null || value === ""){
      setValObj({...valObj, [field]: true })
    } else {
      setValObj({...valObj, [field]: false })
    }
  }

  const handlePostCodeCheck = (value) => {
    if(value !== postCodeToken) {
      postCodeToken = value
    }
  }


  let CibSelectedValue = currentForm === 'view' ?
    productDetails !== null && productDetails['programDetail'] && productDetails['programDetail']['cibDetail'] && 
    (productDetails['programDetail']['cibDetail']['isEnabled'] === "1"|| productDetails['programDetail']['cibDetail']['isEnabled'] === 1) 
      : merchantState['programDetail'] && merchantState['programDetail']['cibDetail'] &&
        (merchantState['programDetail']['cibDetail']['isEnabled'] === "1" || merchantState['programDetail']['cibDetail']['isEnabled'] === 1);
  let cibDefaultSelectVal = currentForm === 'view' ?
    productDetails !== null && productDetails['programDetail'] && productDetails['programDetail']['cibDetail'] && 
    (productDetails['programDetail']['cibDetail']['isDefault'] === "1" || productDetails['programDetail']['cibDetail']['isDefault'] === 1) 
      : merchantState['programDetail'] && merchantState['programDetail']['cibDetail'] &&
        (merchantState['programDetail']['cibDetail']['isDefault'] === "1" || merchantState['programDetail']['cibDetail']['isDefault'] === 1);
  let mibSelectedValue = currentForm === 'view' ?
    productDetails !== null && productDetails['programDetail'] && productDetails['programDetail']['mibDetail'] && 
    (productDetails['programDetail']['mibDetail']['isEnabled'] === "1"|| productDetails['programDetail']['mibDetail']['isEnabled'] === 1) 
      : merchantState['programDetail'] && merchantState['programDetail']['mibDetail'] &&
        (merchantState['programDetail']['mibDetail']['isEnabled'] === "1" || merchantState['programDetail']['mibDetail']['isEnabled'] === 1);
  let mibDefaultSelectVal = currentForm === 'view' ?
    productDetails !== null && productDetails['programDetail'] && productDetails['programDetail']['mibDetail'] && 
    (productDetails['programDetail']['mibDetail']['isDefault'] === "1" || productDetails['programDetail']['mibDetail']['isDefault'] === 1) 
      : merchantState['programDetail'] && merchantState['programDetail']['mibDetail'] &&
        (merchantState['programDetail']['mibDetail']['isDefault'] === "1" || merchantState['programDetail']['mibDetail']['isDefault'] === 1);
  let brandSelectedValue = currentForm === 'view' ?
    productDetails !== null && productDetails['programDetail'] && productDetails['programDetail']['brandDetail'] && 
    (productDetails['programDetail']['brandDetail']['isEnabled'] === "1"|| productDetails['programDetail']['brandDetail']['isEnabled'] === 1) 
      : merchantState['programDetail'] && merchantState['programDetail']['brandDetail'] && 
        (merchantState['programDetail']['brandDetail']['isEnabled'] === "1" || merchantState['programDetail']['brandDetail']['isEnabled'] === 1);

  let brandObj = []
  let selectedBrands = []
  
  let brandArr = brandProgPicklist !== null && brandProgPicklist.picklistItems ? 
    brandProgPicklist.picklistItems.map((obj) => {
      brandObj.push(obj.id);
      if(currentForm !== 'view' && merchantState['programDetail'] && 
        merchantState['programDetail']['brandDetail']['programIds'].includes(obj.id)) {
        selectedBrands.push(obj.name);

      } else if(currentForm === 'view' && productDetails['programDetail'] && 
        productDetails['programDetail']['brandDetail']['programIds'] && 
        productDetails['programDetail']['brandDetail']['programIds'].includes(obj.id)) {
        selectedBrands.push(obj.name);
        
      }
      return obj.name
    }) 
    : []
  brandObj = brandObj.length > 0 ? [10000, ...brandObj] : brandObj;
  brandArr = brandArr.length > 0 ? ["Select All", ...brandArr] : brandArr;

  const cibObj = {}
  let selectedCIB = null

  const cibArr = cibProgPicklist !== null && cibProgPicklist.picklistItems ? 
    cibProgPicklist.picklistItems.map((obj) => {
      cibObj[obj.name] = (obj.id);
      if(currentForm !== 'view' && merchantState['programDetail'] && 
        merchantState['programDetail']['cibDetail']['programIds'].includes(obj.id)) {
        selectedCIB = (obj.name);

      } else if(currentForm === 'view' && productDetails['programDetail'] && 
        productDetails['programDetail']['cibDetail']['programIds'] && 
        productDetails['programDetail']['cibDetail']['programIds'].includes(obj.id)) {
        selectedCIB = (obj.name);
      }
      return obj.name
    }) 
    : []

  const mibObj = {}
  let selectedMIB = null
  const mibArr = mibProgPicklist !== null && mibProgPicklist.picklistItems ? 
    mibProgPicklist.picklistItems.map((obj) => {
      mibObj[obj.name] = (obj.id);
      if(currentForm !== 'view' && merchantState['programDetail'] && 
        merchantState['programDetail']['mibDetail']['programIds'].includes(obj.id)) {
        selectedMIB = (obj.name);

      } else if(currentForm === 'view' && productDetails['programDetail'] && 
        productDetails['programDetail']['mibDetail']['programIds'] && 
        productDetails['programDetail']['mibDetail']['programIds'].includes(obj.id)) {
        selectedMIB = (obj.name);
      }
      return obj.name
    }) 
    : []

  
  const handleChange = async (type, value, typeErr) => {
    
    if(type === "country"){
      countryArr.indexOf((value).toUpperCase()) !== -1 && handleInputChange(type, [
        countryDetails[countryArr.indexOf((value).toUpperCase())]['isoCountryCodeNum'],
        value
      ])
      setValObj({...valObj, countryErr: false })
  
      //fetching picklist Data
      const countryCode = countryArr.indexOf((value).toUpperCase()) !== -1 ?
        countryDetails[countryArr.indexOf((value).toUpperCase())]['isoCountryCodeNum'] : "";
      const Req = {
        "picklistType": "PROGRAM",
        "isoCountryCodeNum": countryCode,
        "mccId": merchantState && merchantState['mcc'] ? merchantState['mcc'] : ""
      }
      await props.fetchProgramPickList({...Req, schemeProgramType: "CIB"}, "CIB")
      await props.fetchProgramPickList({...Req, schemeProgramType: "MIB"}, "MIB")
      await props.fetchProgramPickList({...Req, schemeProgramType: "BRAND"}, "BRAND")
    } 
    // else if(type === "psp") {
    //   pspArr.indexOf(value) !== -1 && handleInputChange(type, [
    //     // PSPList
    //     PSPList[pspArr.indexOf(value)]['id'],
    //     value
    //   ])
    //   setValObj({...valObj, pspErr: false })
    // } 
    else if(type === "brand") {
      
      let arr;
      if(value && value.includes("Select All")){
        arr = brandArr.map(obj => {
          return brandObj[brandArr.indexOf(obj)]
        });
        
      } else {
        arr = value.map(obj => {
          return brandObj[brandArr.indexOf(obj)]
        });

      }
      handleInputChange("brandProgramId", [arr])
    } else if(type === "cib") {
      handleInputChange("cibProgramId", value !== null ? [cibObj[value]] : [])
    } else if(type === "mib") {
      handleInputChange("mibProgramId", value !== null ? [mibObj[value]] : [])
    } else if(type === "mcc") {
      setValObj({...valObj, mccErr: false })
      handleInputChange("mcc", [mccObj[value]])
      const Req = {
        "picklistType": "PROGRAM",
        "isoCountryCodeNum": merchantState && merchantState['isoCountryCodeNum'] ? merchantState['isoCountryCodeNum'] : "",
        "mccId": mccObj[value]
      }
      await props.fetchProgramPickList({...Req, schemeProgramType: "CIB"}, "CIB")
      await props.fetchProgramPickList({...Req, schemeProgramType: "MIB"}, "MIB")
      await props.fetchProgramPickList({...Req, schemeProgramType: "BRAND"}, "BRAND")
    } else {

      handleInputChange(type, value)
      setValObj({...valObj, [typeErr]: false })
    }
  }

  const handleResetPrograms = async () => {
    const keyArr = [];
    const valArr = []
    if(selectedCIB === null && cibProgPicklist !== null && cibProgPicklist.picklistItems && 
      (cibProgPicklist.picklistItems).length > 0 && !CIBset && CibSelectedValue && !cibDefaultSelectVal){
      await setCib(true)
      await keyArr.push("cibProgramId")
      await valArr.push([])
    }
  
    if(selectedMIB === null && mibProgPicklist !== null && mibProgPicklist.picklistItems &&
      (mibProgPicklist.picklistItems).length > 0 && !MIBset && mibSelectedValue && !mibDefaultSelectVal){
      await setMib(true)
      await keyArr.push("mibProgramId")
      await valArr.push([])
    }
  
    if(selectedBrands.length === 0 && brandProgPicklist !== null && brandProgPicklist.picklistItems &&
      (brandProgPicklist.picklistItems).length > 0 && !Brandset && brandSelectedValue){
      await setBrand(true)
      await keyArr.push("brandProgramId")
      await valArr.push([])
    }
    
    if(keyArr && keyArr.length > 0 && !reset) {
      await setReset(true)
      await handleProgIdReset(keyArr, valArr)
    }
  }
  handleResetPrograms();

  const handleStepback = () => {
    const tempPage = activePage;

    setActivePage(tempPage - 1)
  }

  const handleNext = () => {
    const tempPage = activePage;

    setActivePage(tempPage + 1)
  }

  const handlePSPChange = (e) => {
    if(e.target.name === "pspName") {
      let pspId = pspArr.indexOf(e.target.value) !== -1 && PSPList[pspArr.indexOf(e.target.value)]["id"]
      let index = selectedPSPs.indexOf(pspId)
      setCurrPSPObj({...currPSPObj, [e.target.name]: e.target.value})
      
      setValObj({...valObj, pspErr: index !== -1 })
    } else {
      setCurrPSPObj({...currPSPObj, [e.target.name]: e.target.value})
    }
  }

  const handleAddNewPSP = () => {
    pspArr.indexOf(currPSPObj.pspName) !== -1 && handleAddNewMappedPSP(
      PSPList[pspArr.indexOf(currPSPObj.pspName)]["id"], 
      currPSPObj.pspIntegrationMode, 
      currPSPObj.merchantUniqueId
    )

    setCurrPSPObj({
      pspName: "",
      pspIntegrationMode: "",
      merchantUniqueId: "",
    })
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form className="action_form merchant_form" noValidate autoComplete="off">
          {activePage === 0 ?
          <Grid container rowSpacing={2} columnSpacing={2}>
            
            {currentForm === "edit" &&
              <>
                <Grid item xs={12} sm={8} />
                <Grid item xs={12} sm={4}>
                  <TextField 
                    InputLabelProps={{ shrink: true }} 
                    name="status" variant="outlined" select required
                    fullWidth id="status" label="Merchant status"
                    value={ merchantState.status && merchantState.status }
                    onChange={(e) => handleInputChange('status', [e.target.value])}
                  >
                    {["ACTIVE", "INACTIVE"].map((val, index) => {
                      return <MenuItem key={index} value={val} className={classes.menu}>
                        {val}
                      </MenuItem>
                    })}
                  </TextField>
                </Grid>
              </>
            }

            <Grid item xs={12} sm={6}>
              <TextField 
                InputLabelProps={{ shrink: true }} 
                name="country" variant="outlined" select={currentForm === 'create'} required
                fullWidth id="country" label="Country" 
                disabled={currentForm !== 'create'}
                value={currentForm === 'view' ? 
                  productDetails !== null && productDetails.countryName && 
                  productDetails.countryName : merchantState.countryName ? 
                    merchantState.countryName : null
                } 
                onBlur={(e) => handleOnBlur("countryErr", e.target.value)}
                onChange={(e) => countryArr.length > 0 && handleChange('country', e.target.value)}
              >
                {countryArr && countryArr.length === 0 && 
                  <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                {countryArr && countryArr.map((val, index) => {
                  return <MenuItem key={index} value={val} className={classes.menu}>
                    {val}
                  </MenuItem>
                })}
              </TextField>
              {valObj.countryErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>
              :null}
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <TextField 
                InputLabelProps={{ shrink: true }} 
                name="psp" variant="outlined" select={currentForm === 'create'} required
                fullWidth id="psp" label="Select PSP" 
                disabled={currentForm !== 'create'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails.pspName && 
                  productDetails.pspName : merchantState.pspName ? 
                    merchantState.pspName : null
                }
                onBlur={(e) => handleOnBlur("pspErr", e.target.value)}
                onChange={(e) => pspArr.length > 0 && handleChange('psp', e.target.value)}
              >
                {pspArr && pspArr.length === 0 && 
                  <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                {pspArr && pspArr.map((val, index) => {
                  return <MenuItem key={index} value={val} className={classes.menu}>
                    {val}
                  </MenuItem>
                })}
              </TextField>
              {valObj.pspErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>
              :null}
            </Grid> */}
          
            <Grid item xs={12} sm={6}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }} required
                name="merchantName" id="merchantName" fullWidth
                label="Merchant Name" variant="outlined"
                disabled={currentForm !== 'create'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails.name && 
                  productDetails.name : merchantState.name ? 
                  merchantState.name : null
                }
                onChange={(e) => e.target.value.length <= 50 && handleChange('name', [e.target.value], 'merchantNameErr')}
                onBlur={(e) => handleOnBlur("merchantNameErr", e.target.value)}
              />
              {valObj.merchantNameErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p> 
                : merchantState && merchantState.name && merchantState.name !== "" && 
                  !nameRegExp.test(merchantState.name) ? 
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                      <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                  </p>
                  : null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }} required
                name="displayName" id="displayName" fullWidth
                label="Display Name" variant="outlined"
                disabled={currentForm === 'view'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails.displayName && 
                  productDetails.displayName : merchantState.displayName ? 
                  merchantState.displayName : null
                }
                onChange={(e) => e.target.value.length <= 50 && handleChange('displayName', [e.target.value], 'displayNameErr')}
                onBlur={(e) => handleOnBlur("displayNameErr", e.target.value)}
              />
              {valObj.displayNameErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>  
                : merchantState && merchantState.displayName && merchantState.displayName !== "" && 
                  !nameRegExp.test(merchantState.displayName) ? 
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                      <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                  </p>
                  : null}
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }}
                name="legalName" id="legalName" fullWidth
                label="Legal Name" variant="outlined"
                disabled={currentForm === 'view'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails.legalName && 
                  productDetails.legalName : merchantState.legalName ? 
                  merchantState.legalName : null
                }
                onChange={(e) => e.target.value.length <= 50 && handleInputChange('legalName', [e.target.value])}
              />
              {merchantState && merchantState.legalName && merchantState.legalName !== "" && 
                !nameRegExp.test(merchantState.legalName) ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! Special Chars not allowed."}</span>
                </p>
              :null}
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                getOptionDisabled={(option) => true}
                options={legalNameArr}
                // filterOptions={filterOptions}
                disabled={currentForm === 'view'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails.legalName && 
                  productDetails.legalName : merchantState.legalName ? 
                  merchantState.legalName : null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="legalName" name="legalName" label="Legal Name" 
                    InputLabelProps={{ shrink: true }} variant="outlined" required
                    InputProps={{ ...params.InputProps, type: 'search' }}
                    disabled={currentForm === 'view'}
                    onBlur={(e) => handleOnBlur("legalNameErr", e.target.value)}
                    onChange={(e) => e.target.value.length <= 50 && handleChange('legalName', [e.target.value], 'legalNameErr')}
                  />
                )}
              />
              {valObj.legalNameErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p> : merchantState && merchantState.legalName && merchantState.legalName !== "" && 
                    !nameRegExp.test(merchantState.legalName) ? 
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                      <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                    </p> : null
              }
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }}
                name="businessRegistrationNumber" id="businessRegistrationNumber" fullWidth
                label="Business Registration Number" variant="outlined"
                disabled={props.currentForm === 'view'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails.businessRegistrationNumber && 
                  productDetails.businessRegistrationNumber : merchantState.businessRegistrationNumber ? 
                  merchantState.businessRegistrationNumber : null
                }
                onChange={(e) => e.target.value.length <= 50 && handleInputChange('businessRegistrationNumber', [e.target.value])}
              />
              {merchantState && merchantState.businessRegistrationNumber && merchantState.businessRegistrationNumber !== "" && 
                !SpecialCharRegex.test(merchantState.businessRegistrationNumber) ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                </p>
              :null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }}
                name="description" id="description" fullWidth
                label="Description" variant="outlined"
                disabled={props.currentForm === 'view'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails.description && 
                  productDetails.description : merchantState.description ? 
                  merchantState.description : null
                }
                onChange={(e) => e.target.value.length <= 100 && handleInputChange('description', [e.target.value])}
              />
              {merchantState && merchantState.description && merchantState.description !== "" && 
                !descrRegex.test(merchantState.description) ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                </p>
              :null}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={mccArr} value={selectedMcc}
                getOptionLabel={(option) => option}
                disabled={currentForm === 'view'}
                onBlur={(e) => handleOnBlur("mccErr", e.target.value)}
                onChange={(e, newValue) => currentForm !== 'view' && handleChange('mcc', newValue)}
                // renderOption={(option, { selected }) => option }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Merchant Category" 
                    InputLabelProps={{ shrink: true }} required
                    disabled={currentForm === 'view'} id="MccList" name="MccList"
                  />
                )}
              />
              {valObj.mccErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>
              :null}
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                InputLabelProps={{ shrink: true }} required
                name="merchantUniqueId" id="merchantUniqueId" fullWidth
                label="Merchant External Id" variant="outlined"
                disabled={props.currentForm === 'view'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails.merchantUniqueId && 
                  productDetails.merchantUniqueId : merchantState.merchantUniqueId ? 
                  merchantState.merchantUniqueId : null
                }
                onChange={(e) => e.target.value.length <= 20 && handleInputChange('merchantUniqueId', [e.target.value])}
              />
              {merchantState && merchantState.merchantUniqueId && merchantState.merchantUniqueId !== "" && 
                !SpecialCharRegex.test(merchantState.merchantUniqueId) ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! Special Chars not allowed."}</span>
                </p>
              :null}
            </Grid> */}

            <Grid item xs={12} sm={6}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }}
                name="email" id="email" fullWidth
                label="Email" variant="outlined"
                disabled={props.currentForm === 'view'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails.email && 
                  productDetails.email : merchantState.email ? 
                  merchantState.email : null
                }
                onChange={(e) => e.target.value.length <= 50 && handleInputChange('email', [e.target.value])}
              />
              {merchantState && merchantState.email && merchantState.email !== "" && 
                !EmailRegex.test(merchantState.email) ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Invalid Email Address!"}</span>
                </p>
              :null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }} 
                name="website" id="website" fullWidth
                label="Website" variant="outlined"
                disabled={props.currentForm === 'view'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails.website && 
                  productDetails.website : merchantState.website ? 
                  merchantState.website : null
                }
                onChange={(e) => e.target.value.length <= 100 && handleInputChange('website', [e.target.value])}
                />
                {merchantState && merchantState.website && !urlRegex.test(merchantState.website) ?
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon /> {"Invalid URL! Please enter a valid URL."}</span>
                  </p>
                  : null}
            </Grid>

            <Grid item xs={12} >
              <Typography variant="h5" >Contact Person Details</Typography>
            </Grid>
            
            <Grid item xs={12} sm={4}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }}
                name="personName" id="personName" fullWidth
                label="Name" variant="outlined"
                disabled={currentForm === 'view'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails['contactPersonDetail'].name && 
                  productDetails['contactPersonDetail'].name : merchantState['contactPersonDetail'].name ? 
                  merchantState['contactPersonDetail'].name : null
                }
                onChange={(e) => e.target.value.length <= 50 && handleInputChange('user', {name: e.target.value})}
              />
              {merchantState && merchantState['contactPersonDetail'] && merchantState['contactPersonDetail'].name && 
                merchantState['contactPersonDetail'].name !== "" && !SpecialCharRegex1.test(merchantState['contactPersonDetail'].name) ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon />{NO_NUMBERS_AND_SPECIAL_CHARACTERS}</span>
                </p>
              :null}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                className={"number_field0"} type="text"
                InputLabelProps={{ shrink: true }} 
                name="phoneNo" id="phoneNo" fullWidth
                label="Phone No." variant="outlined"
                disabled={currentForm === 'view'}
                // onKeyDown={(e) => handlePostCodeCheck((e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || e.keyCode === 8)}
                // onBlur={() => handlePostCodeCheck(false)}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails['contactPersonDetail'].phoneNo && 
                  productDetails['contactPersonDetail'].phoneNo : merchantState['contactPersonDetail'].phoneNo &&
                  merchantState['contactPersonDetail'].phoneNo 
                }
                onChange={(e) => e.target.value.length <= 20 && 
                  // postCodeToken &&
                  handleInputChange('user', {phoneNo: e.target.value})}
              />
                {merchantState && merchantState['contactPersonDetail'].phoneNo && !numbRegex.test(merchantState['contactPersonDetail'].phoneNo) ?
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                  </p> : null}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                className={"number_field1"} type="text"
                InputLabelProps={{ shrink: true }}
                name="mobileNo" id="mobileNo" fullWidth
                label="Mobile No." variant="outlined"
                disabled={currentForm === 'view'}
                // onKeyDown={(e) => handlePostCodeCheck((e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || e.keyCode === 8)}
                // onBlur={() => handlePostCodeCheck(false)}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails['contactPersonDetail'].mobileNo && 
                  productDetails['contactPersonDetail'].mobileNo : merchantState['contactPersonDetail'].mobileNo &&
                  merchantState['contactPersonDetail'].mobileNo
                }
                onChange={(e) => e.target.value.length <= 20 && 
                  // postCodeToken && 
                  handleInputChange('user',  {mobileNo: e.target.value })}
              />
                {merchantState && merchantState['contactPersonDetail'].mobileNo && !numbRegex.test(merchantState['contactPersonDetail'].mobileNo) ?
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                  </p> : null}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }}
                name="emailId" id="emailId" fullWidth
                label="Email Id" variant="outlined"
                disabled={currentForm === 'view'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails['contactPersonDetail'].emailId && 
                  productDetails['contactPersonDetail'].emailId : merchantState['contactPersonDetail'].emailId ? 
                  merchantState['contactPersonDetail'].emailId : null
                }
                onChange={(e) => e.target.value.length <= 50 && handleInputChange('user', {emailId: e.target.value})}
              />
              {merchantState && merchantState['contactPersonDetail'] && merchantState['contactPersonDetail'].emailId && 
                merchantState['contactPersonDetail'].emailId !== "" && !EmailRegex.test(merchantState['contactPersonDetail'].emailId) ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Invalid Email Address!"}</span>
                </p>
              :null}
            </Grid>
            
            <Grid item xs={12} >
              <Typography variant="h5" >Address Details</Typography>
            </Grid>
            
            <Grid item xs={12} sm={4}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }} required
                name="address1" id="address1" fullWidth
                label="Address Line 1" variant="outlined"
                disabled={currentForm === 'view'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails['address'].address1 && 
                  productDetails['address'].address1 : merchantState['address'].address1 ? 
                  merchantState['address'].address1 : null
                }
                onChange={(e) => handleChange('address', {address1: e.target.value}, 'add1Err')}
                onBlur={(e) => handleOnBlur("add1Err", e.target.value)}
              />
              {valObj.add1Err ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p> 
                : merchantState && merchantState['address'] && merchantState['address'].address1 && 
                  merchantState['address'].address1 !== "" && !addRegex.test(merchantState['address'].address1) ? 
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                      <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                  </p>
                  : merchantState && merchantState['address'] && merchantState['address'].address1 && 
                    merchantState['address'].address1.length > 50 ? 
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                      <span className="error_message"><InfoOutlinedIcon /> {"Invalid Length! More than 50 characters are not allowed."}</span>
                    </p>
                    : null}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }} 
                name="address2" id="address2" fullWidth
                label="Address Line 2" variant="outlined"
                disabled={currentForm === 'view'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails['address'].address2 && 
                  productDetails['address'].address2 : merchantState['address'].address2 ? 
                  merchantState['address'].address2 : null
                }
                onChange={(e) => e.target.value.length <= 100 && handleInputChange('address', {address2: e.target.value})}
              />
              {merchantState && merchantState['address'] && merchantState['address'].address2 && 
                merchantState['address'].address2 !== "" && !addRegex.test(merchantState['address'].address2) ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                </p>
              :null}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }} 
                name="address3" id="address3" fullWidth
                label="Address Line 3" variant="outlined"
                disabled={currentForm === 'view'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails['address'].address3 && 
                  productDetails['address'].address3 : merchantState['address'].address3 ? 
                  merchantState['address'].address3 : null
                }
                onChange={(e) => e.target.value.length <= 100 && handleInputChange('address', {address3: e.target.value})}
              />
              {merchantState && merchantState['address'] && merchantState['address'].address3 && 
                merchantState['address'].address3 !== "" && !addRegex.test(merchantState['address'].address3) ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                </p>
              :null}
            </Grid>
            
            <Grid item xs={12} sm={4}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }} 
                name="state" id="state" fullWidth required
                label="State" variant="outlined"
                disabled={currentForm === 'view' }
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails['address'].state && 
                  productDetails['address'].state : merchantState['address'].state ? 
                  merchantState['address'].state : null
                }
                onChange={(e) => e.target.value.length <= 50 && handleChange('address', {state: e.target.value}, 'stateErr')}
                onBlur={(e) => handleOnBlur("stateErr", e.target.value)}
              />
              {merchantState && merchantState['address'] && merchantState['address'].state && 
                merchantState['address'].state !== "" && !SpecialCharRegex1.test(merchantState['address'].state) ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon />{NO_NUMBERS_AND_SPECIAL_CHARACTERS}</span>
                </p> 
                : valObj.stateErr ? 
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                      <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                  </p>
                  : null
              }
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }} 
                name="city" id="city" fullWidth required
                label="City" variant="outlined"
                disabled={currentForm === 'view' }
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails['address'].city && 
                  productDetails['address'].city : merchantState['address'].city ? 
                  merchantState['address'].city : null
                }
                onChange={(e) => e.target.value.length <= 50 && handleChange('address', {city: e.target.value}, 'cityErr')}
                onBlur={(e) => handleOnBlur("cityErr", e.target.value)}
              />
              {merchantState && merchantState['address'] && merchantState['address'].city && 
                merchantState['address'].city !== "" && !SpecialCharRegex1.test(merchantState['address'].city) ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon />{NO_NUMBERS_AND_SPECIAL_CHARACTERS}</span>
                </p>
                : valObj.cityErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>
                : null
              }
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                className={"number_field2"} type="text"
                InputLabelProps={{ shrink: true }} required
                name="postalCode" id="postalCode" fullWidth
                label="Postal Code" variant="outlined"
                disabled={currentForm === 'view'}
                // onKeyDown={(e) => handlePostCodeCheck((e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || e.keyCode === 8) }
                // onBlur={() => handlePostCodeCheck(false)}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails['address'].postalCode && 
                  productDetails['address'].postalCode : merchantState['address'].postalCode &&
                  merchantState['address'].postalCode
                }
                onChange={(e) => e.target.value.length <= 15 && 
                  // postCodeToken && 
                  handleChange('address', {postalCode: e.target.value }, 'pinErr') }
                onBlur={(e) => handleOnBlur("pinErr", e.target.value)}
              />
                {merchantState && merchantState['address'].postalCode && !numbRegex.test(merchantState['address'].postalCode) ?
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                  </p> : valObj.pinErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p> 
                : null
              /* {<p className="MuiFormHelperText-root MuiFormHelperText-contained" id="password-helper-text">
                <span className="error_message" style={{display: "flex"}}>
                  <InfoOutlinedIcon style={{fontSize: "20px", marginRight: "5px"}} /> {"Please enter 6 digit PIN number."}
                </span>
              </p>} */}
            </Grid>
          
            <Grid item xs={12}>
              <Typography variant="h5" >{"Programs:"}</Typography>
            </Grid>

            {/* CIB */}
            <Grid container item>
              <Grid item xs={12} sm={4}>
                <b>{"Bank Installment:"}</b>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Radio
                  disabled={currentForm === 'view'}
                  checked={currentForm === 'view' ?
                    productDetails !== null && productDetails['programDetail'] && productDetails['programDetail']['cibDetail'] && 
                    (productDetails['programDetail']['cibDetail']['isEnabled'] === "1"|| productDetails['programDetail']['cibDetail']['isEnabled'] === 1) 
                      : merchantState['programDetail'] && merchantState['programDetail']['cibDetail'] &&
                        (merchantState['programDetail']['cibDetail']['isEnabled'] === "1" || merchantState['programDetail']['cibDetail']['isEnabled'] === 1)}
                  onChange={(e) => handleInputChange('isCibEnabled', ["1"])}
                  name="radio-button-demo"
                /> {"YES"}
                <Radio
                  disabled={currentForm === 'view'}
                  checked={currentForm === 'view' ?
                    productDetails !== null && productDetails['programDetail'] && productDetails['programDetail']['cibDetail'] && 
                    (productDetails['programDetail']['cibDetail']['isEnabled'] === "0"|| productDetails['programDetail']['cibDetail']['isEnabled'] === 0) 
                      : merchantState['programDetail'] && merchantState['programDetail']['cibDetail'] &&
                        (merchantState['programDetail']['cibDetail']['isEnabled'] === "0" || merchantState['programDetail']['cibDetail']['isEnabled'] === 0)
                  }
                  onChange={(e) => handleInputChange('isCibEnabled', ["0"])}
                  name="radio-button-demo"
                /> {"NO"}
              </Grid> 
              <Grid item xs={12} sm={4}>
                {CibSelectedValue ?
                  <><Checkbox
                    disabled={currentForm === 'view'}
                    checked={cibDefaultSelectVal }
                    onChange={(e) => handleInputChange('isDefaultCib', [e.target.checked ? "1" : "0" ])}
                    name="radio-button-demo"
                  /> {"Bank Default:"}
                  </> : null}
              </Grid>
            </Grid>
            
            {CibSelectedValue && !cibDefaultSelectVal ?
            <Grid container item>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={cibArr} value={selectedCIB}
                  getOptionLabel={(option) => option}
                  disabled={currentForm === 'view'}
                  onChange={(e, newValue) => currentForm !== 'view' && handleChange('cib', newValue)}
                  // renderOption={(option, { selected }) => option }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Bank Program" required
                    disabled={currentForm === 'view'} id="cibProgList" name="cibProgList"
                    />
                  )}
                />
              </Grid>
            </Grid> : null}


            {/* MIB */}
            <Grid container item>
              <Grid item xs={12} sm={4}>
                <b>{"Merchant installment:"}</b>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Radio
                  disabled={currentForm === 'view'}
                  checked={currentForm === 'view' ?
                    productDetails !== null && productDetails['programDetail'] && productDetails['programDetail']['mibDetail'] && 
                    (productDetails['programDetail']['mibDetail']['isEnabled'] === "1"|| productDetails['programDetail']['mibDetail']['isEnabled'] === 1) 
                      : merchantState['programDetail'] && merchantState['programDetail']['mibDetail'] &&
                        (merchantState['programDetail']['mibDetail']['isEnabled'] === "1" || merchantState['programDetail']['mibDetail']['isEnabled'] === 1)}
                  onChange={(e) => handleInputChange('isMibEnabled', ["1"])}
                  name="radio-button-demo"
                /> {"YES"}
                <Radio
                  disabled={currentForm === 'view'}
                  checked={currentForm === 'view' ?
                    productDetails !== null && productDetails['programDetail'] && productDetails['programDetail']['mibDetail'] && 
                    (productDetails['programDetail']['mibDetail']['isEnabled'] === "0"|| productDetails['programDetail']['mibDetail']['isEnabled'] === 0) 
                      : merchantState['programDetail'] && merchantState['programDetail']['mibDetail'] &&
                        (merchantState['programDetail']['mibDetail']['isEnabled'] === "0" || merchantState['programDetail']['mibDetail']['isEnabled'] === 0)
                  }
                  onChange={(e) => handleInputChange('isMibEnabled', ["0"])}
                  name="radio-button-demo"
                /> {"NO"}
              </Grid>
              <Grid item xs={12} sm={4}>
                {mibSelectedValue ? 
                  <><Checkbox
                    checked={mibDefaultSelectVal }
                    disabled={currentForm === 'view'}
                    onChange={(e) => handleInputChange('isDefaultMib', [e.target.checked ? "1" : "0" ])}
                    name="radio-button-demo"
                  /> {"Merchant Default:"}
                  </>: null}
              </Grid> 
            </Grid>

            {mibSelectedValue && !mibDefaultSelectVal &&
              <Grid container item>   
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    options={mibArr} value={selectedMIB}
                    getOptionLabel={(option) => option}
                    disabled={currentForm === 'view'}
                    onChange={(e, newValue) => currentForm !== 'view' && handleChange('mib', newValue)}
                    // renderOption={(option, { selected }) => option }
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Merchant Program" required
                      disabled={currentForm === 'view'} id="mibProgList" name="mibProgList"
                      />
                    )}
                  />
              </Grid>
            </Grid>}
              

            {/* Brand */}
            <Grid container item>
              <Grid item xs={12} sm={4}>
                <b>{"Brand Installment:"}</b>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Radio
                  disabled={currentForm === 'view'}
                  checked={currentForm === 'view' ?
                    productDetails !== null && productDetails['programDetail'] && productDetails['programDetail']['brandDetail'] && 
                    (productDetails['programDetail']['brandDetail']['isEnabled'] === "1"|| productDetails['programDetail']['brandDetail']['isEnabled'] === 1) 
                      : merchantState['programDetail'] && merchantState['programDetail']['brandDetail'] &&
                        (merchantState['programDetail']['brandDetail']['isEnabled'] === "1" || merchantState['programDetail']['brandDetail']['isEnabled'] === 1)}
                  onChange={(e) => handleInputChange('isBrandEnabled', ["1"])}
                  name="radio-button-demo"
                /> {"YES"}
                <Radio
                  disabled={currentForm === 'view'}
                  checked={currentForm === 'view' ?
                    productDetails !== null && productDetails['programDetail'] && productDetails['programDetail']['brandDetail'] && 
                    (productDetails['programDetail']['brandDetail']['isEnabled'] === "0"|| productDetails['programDetail']['brandDetail']['isEnabled'] === 0) 
                      : merchantState['programDetail'] && merchantState['programDetail']['brandDetail'] &&
                        (merchantState['programDetail']['brandDetail']['isEnabled'] === "0" || merchantState['programDetail']['brandDetail']['isEnabled'] === 0)
                  }
                  onChange={(e) => handleInputChange('isBrandEnabled', ["0"])}
                  name="radio-button-demo"
                /> {"NO"}
              </Grid>
            </Grid>

            {brandSelectedValue &&
            <Grid container item>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    multiple id={`checkboxes-tags-demo`} options={brandArr}
                    disableCloseOnSelect getOptionLabel={(option) => option}
                    value={selectedBrands.map((obj) => {return obj})}
                    disabled={currentForm === 'view'}
                    onChange={(e, newValue) => currentForm !== 'view' && handleChange('brand', newValue)}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        {option === "Select All" ? option
                        : <><Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        /> {option}</>
                      }
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Brand Program" placeholder="" required
                      disabled={currentForm === 'view'}/>
                    )}
                  />
                </Grid>
              </Grid>}
          </Grid> :
          <Grid container>
            <PSPMapping 
              classes={classes}
              currentForm={currentForm}
              pspArr={pspArr}
              pspIdArr={pspIdArr}
              pspIntegrationModeList={pspIntegrationModeList}
              selectedPSPs={selectedPSPs}
              currPSPObj={currPSPObj}
              handlePSPChange={handlePSPChange}
              handleAddNewPSP={handleAddNewPSP}
              handleDeletePSP={handleDeletePSP}
              valObj={valObj}
              handleOnBlur={handleOnBlur}
              mappedPSPList={mappedPSPList}
            />
          </Grid>}

          {/* <Grid container justify="flex-end">
            <Grid item>
              <Button fullWidth variant="contained"
                color="primary" className={"action_login_button"}
                disabled={nextDisableToken}
                onClick={() => currentForm !== "view" ? handleMerchantCreateUpdate() : onChangeView("list")}
              >
                {currentForm === "edit" ? "Modify" : currentForm === "view" ? "Close" : "Create"}
              </Button>
            </Grid>
          </Grid> */}
          

        </form> 
      </Paper>

      <Grid container className="step_action">
        <Grid item xs={9} justify="flex-start">
        </Grid>
        <Grid item xs={3} style={{ display: "flex", justifyContent: "space-evenly"  }} justify="flex-end">
          <Button fullWidth variant="contained"
            color="primary" className={"action_login_button"}
            onClick={handleStepback} 
            disabled={!(activePage > 0)}
          >
            Back
          </Button>
          {activePage === 1 ?
            <Button fullWidth variant="contained"
              color="primary" className={"action_login_button"}
              disabled={nextDisableToken || (currentForm !== "view" && selectedPSPs.length === 0)}
              onClick={() => currentForm !== "view" ? handleMerchantCreateUpdate() : onChangeView("list")}
            >
              {currentForm === "edit" ? "Modify" : currentForm === "view" ? "Close" : "Create"}
            </Button>
            : 
            <Button fullWidth variant="contained"
              color="primary" className={"action_login_button"}
              disabled={nextDisableToken}
              onClick={handleNext}
            >
              {"Next"}
            </Button>
          }
        </Grid>
      </Grid>
    </div>
  )
}

CreateEditViewMerchant = reduxForm({
  form: 'CreateEditViewMerchant',
  // validate: validate,
  enableReinitialize: true
})(CreateEditViewMerchant);

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
        fetchProgramPickList, fetchTccMccList
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    cibProgPicklist: state.MerchantReducer.cibProgPicklist,
    mibProgPicklist: state.MerchantReducer.mibProgPicklist,
    brandProgPicklist: state.MerchantReducer.brandProgPicklist,
    TccMccList: state.SchemeReducer.TccMccList !== null ? 
      state.SchemeReducer.TccMccList.tccList : null,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditViewMerchant);
