import React from 'react'
import { Link } from 'react-router-dom'
import { tss } from "tss-react";
import theme from '../_theme'
import SchemeIcon from "../Assets/scheme.svg";
import MerchantIcon from "../Assets/merchant.svg";
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Paper, Box, Grid, Typography } from '@mui/material'
import Loyalty from '@mui/icons-material/Loyalty';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import LanguageIcon from '@mui/icons-material/Language';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReportIcon from '@mui/icons-material/Report';
import ListAltIcon from '@mui/icons-material/ListAlt';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';

const Scheme = props => {
    const {classes} = useStyles()
    let permissions = localStorage.getItem("permissions")
    permissions = permissions && permissions !== null ? JSON.parse(permissions) : []
    const schemeModule = (permissions.includes("1") ? '/bankschemes' : permissions.includes("4") ? '/merchantschemes' 
        : permissions.includes("8") ? '/brandschemes' : null)
    // const schemeReqModule = (permissions.includes("8") ? '/brandschemerequest' 
    //     : permissions.includes("4") ? '/mibschemerequest' : null)
    const merchantModule = (permissions.includes("15") ? '/merchant' : permissions.includes("12") ? '/psp'
        : permissions.includes("18") ? '/store' : permissions.includes("21") ? '/terminal' : permissions.includes("58") ? 
            '/acquirer' : permissions.includes("61") ? '/pspAcquirer' : null)
    const ProductModule = (permissions.includes("36") ? '/product' : permissions.includes("39") ? '/category'
        : permissions.includes("42") ? '/brand' : permissions.includes("67") ? '/productGroup' : permissions.includes("70") ? '/productCodeMapping' : null)
    const userModule = (permissions.includes("27") ? '/users' : permissions.includes("30") ? '/roles' : null)
    
    const handleListClick = (link) => {
        localStorage.setItem('currentPage', link)
    }

    return (
        <>
            {schemeModule !== null && <Grid item xs={12} sm={6} md={6} rowSpacing={6} columnSpacing={6} className="paper_box">
                <Paper className={classes.paper}>
                    <Grid container >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box p={0}>
                                <Typography className={classes.img}>
                                    <img src={SchemeIcon} alt="" />
                                </Typography>
                                <Typography className={classes.name}>
                                    Manage Scheme
                                 </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={12}>
                            <Box position="relative"  >
                                <div className={classes.seAlldiv}>
                                    <Link to={schemeModule} className={classes.seeAll} onClick={() => handleListClick(schemeModule)}>
                                        See all Schemes <ArrowForwardIcon className={"arrowIcon"} />
                                    </Link>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>}
            
            {merchantModule !== null && <Grid item xs={12} sm={6} md={6} rowSpacing={6} columnSpacing={6} className="paper_box">
                <Paper className={classes.paper}>
                    <Grid container >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box p={0}>
                                <Typography className={classes.img}>
                                    <img src={MerchantIcon} alt="" />
                                </Typography>
                                <Typography className={classes.name}>
                                    Manage Merchant
                                 </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={12}>
                            <Box position="relative"  >
                                <div className={classes.seAlldiv}>
                                    <Link to={merchantModule} className={classes.seeAll} onClick={() => handleListClick(merchantModule)}>
                                        See all merchants  <ArrowForwardIcon className={"arrowIcon"} />
                                    </Link>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>}
            
            {
            //     schemeReqModule !== null && <Grid item xs={12} sm={6} md={6} rowSpacing={6} columnSpacing={6} className="paper_box">
            //     <Paper className={classes.paper}>
            //         <Grid container >
            //             <Grid item xs={12} sm={12} md={12} lg={12}>
            //                 <Box p={0}>
            //                     <Typography className={classes.img}>
            //                         <HourglassFullIcon style={{ fontSize: "2.5rem", color: "rgb(81 168 134)"}} />
            //                     </Typography>
            //                     <Typography className={classes.name}>
            //                         Manage Scheme Request
            //                      </Typography>
            //                 </Box>
            //             </Grid>
            //             <Grid item xs={12} sm={6} md={12} lg={12}>
            //                 <Box position="relative"  >
            //                     <div className={classes.seAlldiv}>
            //                         <Link to={schemeReqModule} className={classes.seeAll} onClick={() => handleListClick(schemeReqModule)}>
            //                             See all Scheme Request  <ArrowForwardIcon className={"arrowIcon"} />
            //                         </Link>
            //                     </div>
            //                 </Box>
            //             </Grid>
            //         </Grid>
            //     </Paper>
            // </Grid>
        }

            {permissions.includes("24") && <Grid item xs={12} sm={6} md={6} rowSpacing={6} columnSpacing={6} className="paper_box">
                <Paper className={classes.paper}>
                    <Grid container >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box p={0}>
                                <Typography className={classes.img}>
                                    <Loyalty fontSize="large" color="primary" className= {classes.loyalty}/>
                                </Typography>
                                <Typography className={classes.name}>
                                    Manage Program
                                 </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={12}>
                            <Box position="relative"  >
                                <div className={classes.seAlldiv}>
                                    <Link to="/program" className={classes.seeAll} onClick={() => handleListClick("/program")}>
                                        See all programs  <ArrowForwardIcon className={"arrowIcon"} />
                                    </Link>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>}

            {userModule !== null && <Grid item xs={12} sm={6} md={6} rowSpacing={6} columnSpacing={6} className="paper_box">
                <Paper className={classes.paper}>
                    <Grid container >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box p={0}>
                                <Typography className={classes.img}>
                                    <PersonRoundedIcon color="primary" className= {classes.loyalty}/>
                                </Typography>
                                <Typography className={classes.name}>
                                    Manage User
                                 </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={12}>
                            <Box position="relative"  >
                                <div className={classes.seAlldiv}>
                                    <Link to={userModule} className={classes.seeAll} onClick={() => handleListClick(userModule)}>
                                        See all users  <ArrowForwardIcon className={"arrowIcon"} />
                                    </Link>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>}

            {permissions.includes("33") && <Grid item xs={12} sm={6} md={6} rowSpacing={6} columnSpacing={6} className="paper_box">
                <Paper className={classes.paper}>
                    <Grid container >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box p={0}>
                                <Typography className={classes.img}>
                                    <AccountBalanceIcon color="primary" className= {classes.loyalty}/>
                                </Typography>
                                <Typography className={classes.name}>
                                    Manage Issuer
                                 </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={12}>
                            <Box position="relative"  >
                                <div className={classes.seAlldiv}>
                                    <Link to="/issuers" className={classes.seeAll} onClick={() => handleListClick("/issuers")}>
                                        See all issuers  <ArrowForwardIcon className={"arrowIcon"} />
                                    </Link>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>}

            {ProductModule !== null && <Grid item xs={12} sm={6} md={6} rowSpacing={6} columnSpacing={6} className="paper_box">
                <Paper className={classes.paper}>
                    <Grid container >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box p={0}>
                                <Typography className={classes.img}>
                                    <GroupWorkIcon color="primary" className= {classes.loyalty}/>
                                </Typography>
                                <Typography className={classes.name}>
                                    Manage Product
                                 </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={12}>
                            <Box position="relative"  >
                                <div className={classes.seAlldiv}>
                                    <Link to={ProductModule} className={classes.seeAll}>
                                        See all products  <ArrowForwardIcon className={"arrowIcon"} />
                                    </Link>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>}

            {permissions.includes("45") && <Grid item xs={12} sm={6} md={6} rowSpacing={6} columnSpacing={6} className="paper_box">
                <Paper className={classes.paper}>
                    <Grid container >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box p={0}>
                                <Typography className={classes.img}>
                                    <LanguageIcon color="primary" className= {classes.loyalty}/>
                                </Typography>
                                <Typography className={classes.name}>
                                    Manage Country
                                 </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={12}>
                            <Box position="relative"  >
                                <div className={classes.seAlldiv}>
                                    <Link to="/country" className={classes.seeAll}>
                                        See all country  <ArrowForwardIcon className={"arrowIcon"} />
                                    </Link>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>}

            {permissions.includes("48") && <Grid item xs={12} sm={6} md={6} rowSpacing={6} columnSpacing={6} className="paper_box">
                <Paper className={classes.paper}>
                    <Grid container >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box p={0}>
                                <Typography className={classes.img}>
                                    <ReportIcon color="primary" className= {classes.loyalty}/>
                                </Typography>
                                <Typography className={classes.name}>
                                    Manage MCI Report
                                 </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={12}>
                            <Box position="relative"  >
                                <div className={classes.seAlldiv}>
                                    <Link to="/mci" className={classes.seeAll}>
                                        See all MCI Reports  <ArrowForwardIcon className={"arrowIcon"} />
                                    </Link>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>}

            {permissions.includes("55") && <Grid item xs={12} sm={6} md={6} rowSpacing={6} columnSpacing={6} className="paper_box">
                <Paper className={classes.paper}>
                    <Grid container >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box p={0}>
                                <Typography className={classes.img}>
                                    <ListAltIcon color="primary" className= {classes.loyalty}/>
                                </Typography>
                                <Typography className={classes.name}>
                                    Client Log Mapping
                                 </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={12}>
                            <Box position="relative"  >
                                <div className={classes.seAlldiv}>
                                    <Link to="/paxconfig" className={classes.seeAll}>
                                        See all Client Logs  <ArrowForwardIcon className={"arrowIcon"} />
                                    </Link>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>}

            {permissions.includes("80") && <Grid item xs={12} sm={6} md={6} rowSpacing={6} columnSpacing={6} className="paper_box">
                <Paper className={classes.paper}>
                    <Grid container >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box p={0}>
                                <Typography className={classes.img}>
                                    <EnhancedEncryptionIcon color="primary" className= {classes.loyalty}/>
                                </Typography>
                                <Typography className={classes.name}>
                                    Key Management
                                 </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={12}>
                            <Box position="relative"  >
                                <div className={classes.seAlldiv}>
                                    <Link to="/keyManagement" className={classes.seeAll}>
                                        See Key Details  <ArrowForwardIcon className={"arrowIcon"} />
                                    </Link>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>}

        </>
    ) 
}

const useStyles = tss.create({
    root: {
        flexGrow: 1,
    },
    img: { paddingBottom: "8px" },
    paper: {
        padding: theme.spacing(2.5),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        height: '100%',
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF", cursor: "pointer",
        '&:hover': {
            border: "1px solid #EEEEEE",
            borderRadius: "8px",
            backgroundColor: "#FFFFFF",
            boxShadow: "0 10px 20px 0 rgba(0,0,0,0.06)",
        },
    },
    containedSecondary: {
        color: '#fff',
        '&:hover': {
            backgroundColor: 'rgb(118, 195, 21)',
        },
    },
    name: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: "#424242",
        fontFamily: "Quicksand",
        // fontSize: "24px",
        fontSize: "26px",
        letterSpacing: 0,
        lineHeight: "30px",
    },
    value: {
        // fontWeight: 'bold',
        whiteSpace: 'nowrap',
        color: "#757575",
        fontFamily: "Quicksand",
        // fontSize: "16px",
        fontSize: "18px",
        letterSpacing: 0,
        lineHeight: "20px",
        fontWeight: "normal",
        padding: "15px 0px 0px 0px"
    },
    valueChange: {},
    negative: {
        color: theme.palette.text.negative,
    },
    positive: {
        color: theme.palette.text.positive,
    },
    seAlldiv: {
        padding: "70px 0px 10px 0px",
    },
    seeAll: {
        display: "flex",
        alignItems: "center",
        color: "#1BA785",
        fontFamily: "Quicksand",
        fontSize: "18px",
        // fontSize: "16px",
        letterSpacing: "0",
        lineHeight: "20px",
        textDecoration: "none"
    },
    loyalty: {
        fontSize: "3rem"
    }
});

export default Scheme
