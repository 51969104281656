import React, { useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { tss } from "tss-react";
import theme from '../../../_theme';
import List from '@mui/material/List'

import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import Popover from '@mui/material/Popover'
import Collapse from '@mui/material/Collapse'

import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'
import IconSpacer from '@mui/icons-material/FiberManualRecord'
import NavItemComponent from './navItemComponent'

// ----------------------------------------------------------------------

const NavItemCollapsed = props => {
  const {
    name,
    link,
    Icon,
    IconStyles = {},
    IconClassName = '',
    isCollapsed,
    className,
    items = [],
  } = props

  const location = useLocation();
  const locPathName = location.pathname
  const {classes, cx} = useStyles()
  const hasChildren = items && items.length > 0

  const itemsAll = getItemsAll(items)
  const hasChildrenAndIsActive =
    hasChildren &&
    itemsAll.filter(item => `${item.link}` === `${locPathName}`).length > 0
    
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = event => {
    if (!hasChildren) {
      return false
    }

    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'sidebar-nav-item-popper' : undefined

  const ListItemIconInner =
    (!!Icon && <Icon />) ||
    (isCollapsed && <IconSpacer className={classes.iconSpacer} />) ||
    ''

  const ListItemRoot = (
    <Tooltip
      disableFocusListener={true}
      disableHoverListener={false}
      disableTouchListener={true}
      title={name}
      placement="right"
    >
      <NavItemComponent
        link={link}
        className={cx(
          classes.navItem,
          classes.navItemCollapsed,
          hasChildrenAndIsActive && 'active',
          open && 'open',
          className,
        )}
        style={{
          paddingLeft: "0px",
          // marginTop: "10px",
          // marginBottom: "10px",
        }}
        isCollapsed={true}
        aria-describedby={id}
        onClick={handlePopoverOpen}
      >
        <div className="item_list"
          style={{
            paddingTop: "0px",
            paddingBottom: "0px",
          }} > 
          {link === "/schememgmt" ?
            <ListItemIcon style={IconStyles}
              className={cx(classes.navItemCustomIconSlide, IconClassName)}>
              <i className="scheme_icon"></i>
            </ListItemIcon>
            : <React.Fragment>
              {!!ListItemIconInner && (
                <ListItemIcon
                  style={{
                    paddingLeft: "10px",
                  }}
                  className={cx(classes.navItemIcon, IconClassName)}
                >
                  {ListItemIconInner}
                </ListItemIcon>
              )}
            </React.Fragment>
          }
          <ListItemText
            primary={name}
            disableTypography={true}
            style={{ visibility: 'hidden' }}
          />
          {hasChildren && (
            <IconExpandLess
              className={cx(classes.iconToggle, !open && classes.iconToggleInactive)}
            />
          )}
        </div>
      </NavItemComponent>
    </Tooltip>
  )

  const ListItemChildren = hasChildren ? (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handlePopoverClose}
      classes={{
        paper: classes.navItemPoper,
      }}
    >
      <div className={cx(classes.navItemChildren)}>
        <List component="div" disablePadding>
          {items.map(item => (
            <NavItem
              {...item}
              isNested={true}
              nestingLevel={0}
              isCollapsed={false}
              key={item.name || item.link}
              isOpen={open}
              onClick={!item.items || !item.items.length ? handlePopoverClose : undefined}
            />
          ))}
        </List>
      </div>
    </Popover>
  ) : null

  return (
    <div
      className={cx(
        hasChildrenAndIsActive && classes.navItemWrapperActive,
        hasChildrenAndIsActive && isCollapsed && classes.navItemWrapperActiveCollapsed,
      )}
    >
      {ListItemRoot}
      {ListItemChildren}
    </div>
  )
}

const parentArr = ["Dashboard", "Manage Scheme", "Scheme Request", "Manage Merchant", "Manage Program",
"Manage Users", "Manage Issuer", "Manage Country", "Manage Product"];
const NavItemDefault = props => {
  const {
    name,
    link,
    Icon,
    IconStyles = {},
    IconClassName = '',
    isCollapsed,
    nestingLevel = 0,
    nestingOffset = 16,
    className,
    items = [],
    onClick = () => { },
  } = props
  
  const location = useLocation();
  const locPathName = location.pathname
  const {classes, cx} = useStyles()
  const hasChildren = items && items.length > 0

  const itemsAll = getItemsAll(items)
  const hasChildrenAndIsActive =
    hasChildren &&
    itemsAll.filter(item => `${item.link}` === `${locPathName}`).length > 0
    
  const isOpen = hasChildrenAndIsActive || false
  const [open, setOpen] = React.useState(isOpen)

  // This is a work around for fixing the collapsed item poper overflow bug
  useLayoutEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'))
  })

  function handleClick() {
    setOpen(!open)
  }

  const ListItemIconInner =
    (!!Icon && <Icon />) ||
    (isCollapsed && <IconSpacer className={classes.iconSpacer} />) ||
    ''

  const nestingOffsetChildren = !isCollapsed ? nestingOffset + 16 : 16

  const ListItemRoot = (
    <NavItemComponent
      link={link}
      className={cx(
        classes.navItem,
        isCollapsed && classes.navItemCollapsed,
        hasChildrenAndIsActive && 'active',
        className, "item_a"
      )}
      style={{
        fontSize: `${1.5 - 0.07 * nestingLevel}em`,
        // fontSize: `${1 - 0.07 * nestingLevel}em`,
        paddingLeft: "0px",
        marginTop: name && parentArr.includes(name) ? "5px" : "1px",
        marginBottom: name && parentArr.includes(name) ? "5px" : "1px",
        paddingBottom: "0px"
      }}
      isCollapsed={isCollapsed}
      onClick={handleClick}>
      <div className="item_list"
        style={{
          fontSize: `${1.5 - 0.07 * nestingLevel}em`,
          // fontSize: `${1 - 0.07 * nestingLevel}em`,
          paddingTop: "0px", width: "100%", paddingBottom: "0px",
          paddingLeft: `${!ListItemIconInner ? nestingOffset + 40 : nestingOffset}px`,
        }} >
        {/* {link === "/schememgmt" ?
          <ListItemIcon
            style={IconStyles}
            className={cx(classes.navItemCustomIcon, IconClassName)}
          >
            <i className="scheme_icon"></i>
          </ListItemIcon>
          :  */}
          <React.Fragment>
            {!!ListItemIconInner && (
              <ListItemIcon
                style={IconStyles}
                className={cx(classes.navItemIcon, IconClassName)}
              >
                {ListItemIconInner}
              </ListItemIcon>
            )}
          </React.Fragment>
          {/* } */}
        <ListItemText primary={name} disableTypography={true} />
        {hasChildren && !open && <IconExpandMore className={classes.iconToggle} />}
        {hasChildren && open && <IconExpandLess className={classes.iconToggle} />}
      </div>
    </NavItemComponent>
  )
  const ListItemChildren = hasChildren ? (
    <div className={hasChildren && open ? cx(classes.navItemChildrenActive) : cx(classes.navItemChildren)}>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {/* <Divider /> */}
        <List component="div" disablePadding>
          {items.map(item => (
            <NavItem
              {...item}
              isNested={true}
              nestingLevel={nestingLevel + 1}
              isCollapsed={isCollapsed}
              key={item.name || item.link}
              isOpen={open}
              nestingOffset={nestingOffsetChildren}
            />
          ))}
        </List>
      </Collapse>
    </div>
  ) : null

  return (
    <div
      className={cx(
        hasChildrenAndIsActive && classes.navItemWrapperActive,
        hasChildrenAndIsActive && isCollapsed && classes.navItemWrapperActiveCollapsed,
      )}
      onClick={onClick}
    >
      {ListItemRoot}
      {ListItemChildren}
    </div>
  )
}

const NavItem = props => {
  if (props.isCollapsed) {
    return <NavItemCollapsed {...props} />
  } else {
    return <NavItemDefault {...props} />
  }
}

const useStyles = tss.create({
  navItemWrapper: {
    position: 'relative',
  },
  navItemWrapperActive: {
    // background: 'rgba(0, 0, 0, 0.08)',
  },
  navItemWrapperActiveCollapsed: {
    background: 'rgba(0, 0, 0, 0.08)',
  },
  navItem: {
    position: 'relative', color: "#757575",
    transition: 'background .23s ease',
    paddingBottom: "5px",
    '&.active:not(.open)': {
      // borderLeft:"solid 4px #1BA785",
      color: theme.palette.secondary.main,
      // background: 'rgba(0, 0, 0, 0.08)',
      '& .MuiListItemIcon-root': {
        // color: '#fff',
        color: theme.palette.secondary.main,
      },
    },
    '&.open': {
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff',
      },
    },
  },
  navItemPoper: {
    width: theme.sidebar.width,
    color: theme.sidebar.color,
    background: theme.sidebar.background,
  },
  navItemChildren: {
    transition: 'background .23s ease',
    // position: 'absolute',
    // maxHeight:"100px"
  },
  navItemChildrenActive: {
    // background: 'rgba(0, 0, 0, 0.1)',
    height: "auto", overflowY: "auto",
    maxWidth: "95%"
  },
  navItemCollapsed: {
    whiteSpace: 'nowrap',
    flexWrap: 'nowrap',
    width: theme.sidebar.widthCollapsed,
    '& $iconToggle': {
      position: 'absolute',
      // bottom: -1,
      // fontSize: 14,
      fontSize: 20,
      top: '50%',
      marginTop: '-0.5em',
      transform: 'rotate(90deg)',
      right: '3px',
    },
    '&.active': {
      background: 'rgba(0, 0, 0, 0.08)',
    },
  },
  navItemCustomIcon: {
    minWidth: 40,
    '&.hover': {},
    '&.active': {
    },
  },
  navItemCustomIconSlide: {
    minWidth: 40, paddingLeft: "10px",
  },
  navItemIcon: {
    minWidth: 40,
  },
  iconToggle: {},
  iconToggleInactive: {
    opacity: 0.35,
  },
  iconSpacer: {
    // fontSize: 13,
    fontSize: 15,
    marginLeft: 6,
  },
});

// ----------------------

// Flattened array of all children
function getItemsAll(items) {
  return items.reduce((allItems, item) => {
    // let res = allItems.concat([item])

    if (item.items && item.items.length) {
      return allItems.concat([item], getItemsAll(item.items))
    } else {
      return allItems.concat([item])
    }
  }, [])
}

export default NavItem
