import { actionCreator, BASE_URL } from "../../../shared/utility";
import { MerchantMngActions } from "../consts/index";

export function fetchMerchantDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(MerchantMngActions.fetchMerchantDetails.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/getMerchantList`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(MerchantMngActions.fetchMerchantDetails.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(MerchantMngActions.fetchMerchantDetails.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchMerchantMetadata(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(MerchantMngActions.fetchMerchantMetadata.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/getMerchantSummaryList`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(MerchantMngActions.fetchMerchantMetadata.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(MerchantMngActions.fetchMerchantMetadata.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function createMerchant(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(MerchantMngActions.createMerchant.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/addOrEditMerchant`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(MerchantMngActions.createMerchant.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(MerchantMngActions.createMerchant.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetCreateMerResp() {
    return(dispatch) => {
        dispatch(actionCreator(MerchantMngActions.createMerchant.FAILURE))
    }
}

export function resetMerchantMetadata(payload) {
    return(dispatch) => {
        dispatch(actionCreator(MerchantMngActions.fetchMerchantMetadata.FAILURE))
    }
}

export function fetchProgramPickList(payload, type) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        if(type === "CIB") {
            dispatch(actionCreator(MerchantMngActions.fetchCIBProrgamPicklist.REQUEST))
        } else if(type === "MIB") {
            dispatch(actionCreator(MerchantMngActions.fetchMIBProrgamPicklist.REQUEST))
        } else if(type === "BRAND") { 
            dispatch(actionCreator(MerchantMngActions.fetchBrandProrgamPicklist.REQUEST))
        }
        fetch(`${BASE_URL}/sch/api/data/web/getPicklist`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    if(type === "CIB") {
                        dispatch(actionCreator(MerchantMngActions.fetchCIBProrgamPicklist.FAILURE))
                    } else if(type === "MIB") {
                        dispatch(actionCreator(MerchantMngActions.fetchMIBProrgamPicklist.FAILURE))
                    } else if(type === "BRAND") {
                        dispatch(actionCreator(MerchantMngActions.fetchBrandProrgamPicklist.FAILURE))
                    }
                }
            } else {
                if(type === "CIB") {
                    dispatch(actionCreator(MerchantMngActions.fetchCIBProrgamPicklist.SUCCESS, response))
                } else if(type === "MIB") {
                    dispatch(actionCreator(MerchantMngActions.fetchMIBProrgamPicklist.SUCCESS, response))
                } else if(type === "BRAND") { 
                    dispatch(actionCreator(MerchantMngActions.fetchBrandProrgamPicklist.SUCCESS, response))
                }
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetProgramPickList() {
    return(dispatch) => {
        dispatch(actionCreator(MerchantMngActions.fetchCIBProrgamPicklist.FAILURE))
        dispatch(actionCreator(MerchantMngActions.fetchMIBProrgamPicklist.FAILURE))
        dispatch(actionCreator(MerchantMngActions.fetchBrandProrgamPicklist.FAILURE))
                    
    }
}