import { actionCreator, BASE_URL } from "../../shared/utility";
import { MciActions } from "../consts/index";

export function fetchMciList(payload) {
    const id = payload.id && payload.id !== "" ? `&id=${payload.id}` : ""
    const mciId = payload.mciId && payload.mciId !== "" ? `&mciId=${payload.mciId}` : ""
    const issuerIca = payload.issuerIca && payload.issuerIca !== "" ? `&issuerIca=${payload.issuerIca}` : ""
    const installmentStartDate = payload.installmentStartDate && payload.installmentStartDate !== "" ? `&installmentStartDate=${payload.installmentStartDate}` : ""
    const installmentEndDate = payload.installmentEndDate && payload.installmentEndDate !== "" ? `&installmentEndDate=${payload.installmentEndDate}` : ""
    const createFileBasedOnIdentifierValue = payload.createFileBasedOnIdentifierValue && payload.createFileBasedOnIdentifierValue !== "" ? `&createFileBasedOnIdentifierValue=${payload.createFileBasedOnIdentifierValue}` : ""
    const installmentProductType = payload.installmentProductType && payload.installmentProductType !== "" ? `&installmentProductType=${payload.installmentProductType}` : ""
    const paymentModelType = payload.paymentModelType !== null && payload.paymentModelType !== "" && payload.paymentModelType !== undefined ? `&paymentModelType=${payload.paymentModelType}` : ""
    const status = payload.status && payload.status !== "" ? `&status=${payload.status}` : ""
    const endURL = `?pageNo=${payload.pageNo}&pageSize=${payload.pageSize}&sortDirection=${payload.sortDirection}&sortBy=${payload.sortBy}` + issuerIca + mciId + id + installmentStartDate + installmentEndDate +
        createFileBasedOnIdentifierValue + installmentProductType + paymentModelType + status;
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(MciActions.fetchMciList.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/reportConfigurations${endURL}`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(MciActions.fetchMciList.FAILURE))
                }
            } else {
                dispatch(actionCreator(MciActions.fetchMciList.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function createOrEditMci(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(MciActions.createUpdateMci.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/reportConfiguration`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(MciActions.createUpdateMci.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(MciActions.createUpdateMci.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export const resetMciCreateOrEditResp = () => {
    return(dispatch) => {
        dispatch(actionCreator(MciActions.createUpdateMci.FAILURE))
    }
}

export function deleteMci(payload, listReq) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(MciActions.deleteMci.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/deleteRecord`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(MciActions.deleteMci.FAILURE))
                }
            } else {
                dispatch(actionCreator(MciActions.deleteMci.SUCCESS, response))
                dispatch(fetchMciList(listReq))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export const resetMciDeleteResp = () => {
    return(dispatch) => {
        dispatch(actionCreator(MciActions.deleteMci.FAILURE))
    }
}

export function fetchFileTypeIdList(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(MciActions.fetchFileTypeId.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/syncFileTypeId`, {
            method: 'GET',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(MciActions.fetchFileTypeId.FAILURE))
                }
            } else {
                dispatch(actionCreator(MciActions.fetchFileTypeId.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchTemplateIdList(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(MciActions.fetchExcelFileTemplateId.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/syncMCIFileTemplateDetails`, {
            method: 'GET',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(MciActions.fetchExcelFileTemplateId.FAILURE))
                }
            } else {
                dispatch(actionCreator(MciActions.fetchExcelFileTemplateId.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchMCIProgramTypesList(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(MciActions.fetchMCIProgramTypes.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/syncMCIInstallmentProgramTypes`, {
            method: 'GET',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(MciActions.fetchMCIProgramTypes.FAILURE))
                }
            } else {
                dispatch(actionCreator(MciActions.fetchMCIProgramTypes.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchMCIPaymentModelTypesList(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(MciActions.fetchMCIPaymentModelTypes.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/syncMCIPaymentModelTypes`, {
            method: 'GET',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(MciActions.fetchMCIPaymentModelTypes.FAILURE))
                }
            } else {
                dispatch(actionCreator(MciActions.fetchMCIPaymentModelTypes.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchMCIProductTypesList(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(MciActions.fetchMCIProductTypes.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/syncMCIInstallmentProductTypes`, {
            method: 'GET',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(MciActions.fetchMCIProductTypes.FAILURE))
                }
            } else {
                dispatch(actionCreator(MciActions.fetchMCIProductTypes.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}