import { MerchantMngActions } from "../consts/index";

const initialState = {
    createMerRes: null,
    merchantList: null,
    merchantLoader: false,
    merchantMetadata: null,
    brandProgPicklist: null,
    cibProgPicklist: null,
    mibProgPicklist: null
}

export default (state = initialState, {type, payload}) => {
    switch(type) {
        case MerchantMngActions.fetchMerchantDetails.REQUEST:
            return {
                ...state,
                merchantLoader: true
            }
        case MerchantMngActions.fetchMerchantDetails.SUCCESS:
            return {
                ...state,
                merchantLoader: false,
                merchantList: payload
            }
        case MerchantMngActions.fetchMerchantDetails.FAILURE:
            return {
                ...state,
                merchantList: null,
                merchantLoader: false,
            }

        case MerchantMngActions.fetchMerchantMetadata.REQUEST:
            return {
                ...state
            }
        case MerchantMngActions.fetchMerchantMetadata.SUCCESS:
            return {
                ...state,
                merchantMetadata: payload
            }
        case MerchantMngActions.fetchMerchantMetadata.FAILURE:
            return {
                ...state,
                merchantMetadata: null
            }

        case MerchantMngActions.createMerchant.REQUEST:
            return {
                ...state
            }
        case MerchantMngActions.createMerchant.SUCCESS:
            return {
                ...state,
                createMerRes: payload
            }
        case MerchantMngActions.createMerchant.FAILURE:
            return {
                ...state,
                createMerRes: payload
            }

        case MerchantMngActions.fetchCIBProrgamPicklist.REQUEST:
            return {
                ...state
            }
        case MerchantMngActions.fetchCIBProrgamPicklist.SUCCESS:
            return {
                ...state,
                cibProgPicklist: payload
            }
        case MerchantMngActions.fetchCIBProrgamPicklist.FAILURE:
            return {
                ...state,
                cibProgPicklist: null
            }

        case MerchantMngActions.fetchMIBProrgamPicklist.REQUEST:
            return {
                ...state
            }
        case MerchantMngActions.fetchMIBProrgamPicklist.SUCCESS:
            return {
                ...state,
                mibProgPicklist: payload
            }
        case MerchantMngActions.fetchMIBProrgamPicklist.FAILURE:
            return {
                ...state,
                mibProgPicklist: null
            }

        case MerchantMngActions.fetchBrandProrgamPicklist.REQUEST:
            return {
                ...state
            }
        case MerchantMngActions.fetchBrandProrgamPicklist.SUCCESS:
            return {
                ...state,
                brandProgPicklist: payload
            }
        case MerchantMngActions.fetchBrandProrgamPicklist.FAILURE:
            return {
                ...state,
                brandProgPicklist: null
            }

        default:
            return state;
    }
}