import React, { useEffect, useState } from 'react'
import {connect} from "react-redux";
import { useForm } from "react-hook-form";
import { bindActionCreators } from "redux";
import CircularProgress from '@mui/material/CircularProgress';
import { 
  Button, TextField, Grid, Snackbar, 
} from '@mui/material'
import { tss } from "tss-react";
import theme from '../../_theme';
import AuthHeader from '../_common/AuthHeader';
import AuthContent from '../_common/AuthContent';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
  handleUserAuthentication, clearLoginRes, 
  validateUserAndSendEmail, clearValidateUserRes,
  fetchCaptchaForLogin, unblockUserRequest
} from "../actions/index";
import {spaceRegexp} from "../../shared/utility";
import DialogBox from './dialogBox';
import SnackbarToast from '_common/SnackbarToast';
// let submitCalled = false

function Login(props) {
  const {classes, cx} = useStyles();
  const {authResponse, authLoader, validateUserResponse, captchaImg, unblockUserResp} = props;
  const [form, setForm] = React.useState({
    email: '',
    password: '',
    showPassword: false,
  });
  const [capState, setCaptcha] = useState("");
  const [userName, setUserName] = useState("");
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [genCaptcha, setGenCaptcha] = useState(null);
  const [openPopover, setopenPopover] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogBody, setDialogBody] = useState("");
  const [dialogType, setDialogType] = useState("");
  const [snackbarType, setSnackbarType] = useState("success")
  let tempTokenExpired = localStorage.getItem("tokenExpired");

  const submitEnable = form['email'] !== "" && form['email'].length > 2 && 
    form['password'] !== "" && form['password'].length >= 7 && form['password'].length <= 15 
    && (capState !== "" && capState.length <= 6 && !spaceRegexp.test(capState));

  const {
    register,
    clearErrors,
    formState: {
      errors,
    },
  } = useForm({
    mode: "all"
  });

  React.useEffect(() => {
    if(authResponse && authResponse !== null && !authResponse.status) {

      if(authResponse.responseCode === 'ERROR_10') {
        setOpenDialog(true)
        setDialogType("unblockUser")
        setDialogTitle("User Blocked")
        setDialogBody(() => {
          return (
            <div>
              <p>Your account is blocked for security reasons.</p>
              <p>Please click on Unblock button to raise a request to unblock your account!</p>
            </div>
          )
        })
        
      } else {
        // submitCalled = false
        setSnackbarType("error")
        setOpenSnackbar(true)
        setSnackbarMsg(authResponse.responseMessage)
      }
      let elem = document.getElementById("captcha")
      elem.value = "";
      setGenCaptcha(null);
      setCaptcha("")
      props.fetchCaptchaForLogin();
    }
    
    if(tempTokenExpired === "true") {
      setSnackbarType("info")
      setOpenSnackbar(true)
      setTokenExpired(true)
      setSnackbarMsg("Your session has expired. Please login again to continue!")
    } else if(tempTokenExpired === "timeout") {
      setSnackbarType("info")
      setOpenSnackbar(true)
      setTokenExpired(true)
      setSnackbarMsg("Inactivity Found! Ended your session for security reasons.")
    }
  }, [authResponse]);

  React.useEffect(() => {
    if(validateUserResponse && validateUserResponse !== null && validateUserResponse.st && validateUserResponse.st === true) {
      setSnackbarType("info")
      setOpenSnackbar(true)
      setSnackbarMsg(validateUserResponse.msg && validateUserResponse.msg)
    } else if (validateUserResponse && validateUserResponse !== null && validateUserResponse.st === false) {
      setSnackbarType("error")
      setOpenSnackbar(true)
      setSnackbarMsg(validateUserResponse.msg && validateUserResponse.msg)
    } else if (validateUserResponse && validateUserResponse !== null && validateUserResponse.status) {
      setSnackbarType("error")
      setOpenSnackbar(true)
      setSnackbarMsg(validateUserResponse.error && validateUserResponse.error)
    }
    
  }, [validateUserResponse]);

  useEffect(() => {
    if(unblockUserResp && unblockUserResp.st) {
      setSnackbarType("success")
      setOpenSnackbar(true)
      setSnackbarMsg("Request raised successfully to unblock your account.")
    } else if(unblockUserResp && !unblockUserResp.st) {
      setSnackbarType("error")
      setOpenSnackbar(true)
      setSnackbarMsg(unblockUserResp.msg)
    }
  }, [unblockUserResp])

  const handleSnackBarAction = () => {
    setOpenSnackbar(false)
    setSnackbarMsg("")
    setSnackbarType("")
    //clear login response
    localStorage.setItem("tokenExpired", "false")
    props.clearLoginRes(null)
  }

  const onSubmit = () => {
    if(submitEnable) {
      const req = {
        username: form['email'],
        password: form['password'],
        captcha: capState,
        captchaId: captchaImg.captchaId
      }
      props.handleUserAuthentication(req);
      localStorage.setItem('userName', form['email']);
    }
  };

  const handleChange = (prop, event) => {
    if (prop === "captcha") {
      clearErrors(prop)
      setCaptcha(event.target.value)
    } else {
      clearErrors(prop)
      setForm({ ...form, [prop]: event.target.value });
    }
  };

  const handleClickShowPassword = () => {
    setForm({ ...form, showPassword: !form.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    // getCaptcha();
    props.fetchCaptchaForLogin();
  }, [])

  const handleForgotPasswordPopover = () => {
    setUserName("");
    // setopenPopover(true);
    setOpenDialog(true)
    setDialogType("forgotPassword")
    setDialogTitle("Forgot Password")
    setDialogBody("")
    localStorage.setItem("forgotPasswordToken", "false")
  }

  const handleDialogAction = async (type, value) => {
    if(type === "forgotPassword") {
      props.validateUserAndSendEmail({"userName": userName})
    } else if(type === "unblockUser") {
      props.unblockUserRequest({username: form["email"]})
    } 
    setOpenDialog(false)
    setDialogType("")
    setDialogTitle("")
    setDialogBody("")
  }

  const handleUserNameChange = (value) => {
    setUserName(value);
  }

  return (
    <AuthContent>
      <form className="action_form login_form" noValidate autoComplete="off">
        
        <AuthHeader title={'Sign in'} text="to continue PayLater" />
        <TextField 
          name="email" type="text" margin="normal" autoFocus fullWidth
          id="email" labelwidth={20} variant="outlined"
          placeholder={"UserName"} label="UserName"
          error={!!errors.email} defaultValue={form['email']}
          {...register('email', { required: true })}
          onChange={(e) => handleChange('email', e)}
          helperText={errors.email ? 
            form.email ? <span className="error_message">
              <InfoOutlinedIcon /> {"Enter a valid username"}</span> 
              : <span className="error_message">
                <InfoOutlinedIcon />{"Username is required!"}</span> 
                : ""
            }
        />
        <FormControl 
          fullWidth 
          variant="outlined"
          error={!!errors.password}
          className={cx(classes.marginTopBottom)} 
        >
          <InputLabel htmlFor="outlined-adornment-password1"> Password </InputLabel>
          <OutlinedInput 
            name="password" id="outlined-adornment-password"
            type={form.showPassword ? 'text' : 'password'}
            labelwidth={65} defaultValue={form['password']}
            label="Password"
            placeholder={"Enter Password"}
            {...register('password', { required: true })}
            onChange={(e) => handleChange('password', e)} 
            endAdornment={
              <InputAdornment position="end">
                <IconButton 
                  aria-label="toggle password visibility" className="password_icon"
                  onClick={() => handleClickShowPassword()} edge="end"
                  onMouseDown={(e) => handleMouseDownPassword(e)} 
                >
                  {form.showPassword ? <Visibility /> : <VisibilityOff />} 
                </IconButton>
              </InputAdornment>
            }
          />
          {errors.password ? 
            form.password ?
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                <span className="error_message"><InfoOutlinedIcon /> {"Enter a valid password!"}</span>
              </p>
              : <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                <span className="error_message"><InfoOutlinedIcon /> {"Password is required!"}</span>
              </p>
            :  null
          }
        </FormControl>
        
        {captchaImg !== null && captchaImg.realCaptcha && 
        <div>
          <img src={`data:image/png;base64,${captchaImg.realCaptcha}`} width="180" height="57"/>
        </div>}

        <TextField 
          name="captcha" type="text" margin="normal" fullWidth
          id="captcha" labelwidth={20} variant="outlined"
          placeholder={"Enter captcha"} label="Enter captcha"
          error={!!errors.captcha} defaultValue={capState}
          {...register('captcha', { required: true })}
          inputProps={{maxLength: 6}}
          onChange={(e) => e.target.value.length <= 6 && handleChange('captcha', e)}
          helperText={errors.captcha ? 
            capState ? <span className="error_message">
              <InfoOutlinedIcon /> {"Enter a valid captcha"}</span> 
              : <span className="error_message">
                <InfoOutlinedIcon /> {"Captcha is required!"}</span> 
                : ""
            }
        />

        <Grid container className="MuiGrid-align-items-xs-center grid_button" >
          <Grid item xs className="button_text_sec">
            <Button color="primary" 
              onClick={() => handleForgotPasswordPopover()}
            >
              Forgot password?
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="button" variant="contained" color="primary"
              fullWidth className={"action_login_button"}
              disabled={!submitEnable || authLoader} onClick={() => onSubmit()}
            >
              Sign in
              {authLoader ? <CircularProgress color="secondary" style={{
                    "width": "25px", "height": "25px", "marginLeft": "6px", "color": "#fff"}}
                /> : null}
            </Button>
          </Grid>
        </Grid>

        <DialogBox
          openDialog={openDialog}
          title={dialogTitle}
          body={dialogBody}
          handleDialogAction={handleDialogAction}
          type={dialogType}
          handleUserNameChange={handleUserNameChange}
          userName={userName}
        />
        <SnackbarToast
            openSnackbar={openSnackbar} 
            handleSnackBarAction={handleSnackBarAction} 
            snackbarMsg={snackbarMsg} 
            type={snackbarType}
        />
      </form>
    </AuthContent>
  )
}

const useStyles = tss.create({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  marginTopBottom: {
    margin: theme.spacing(2, 0, 2, 0),
  },
});

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
        handleUserAuthentication, clearLoginRes,
        validateUserAndSendEmail, clearValidateUserRes,
        fetchCaptchaForLogin, unblockUserRequest
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
      authResponse: state.AuthReducer.authResponse,
      authLoader: state.AuthReducer.authLoader,
      captchaImg: state.AuthReducer.captchaImg,
      validateUserResponse: state.AuthReducer.validateUserResponse,
      unblockUserResp: state.AuthReducer.unblockUserResp,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
