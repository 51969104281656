import React, {useState, useEffect} from 'react'
import {connect} from "react-redux";
import format from "date-fns/format";
import { bindActionCreators } from "redux";
import { Calendar } from 'react-date-range';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { 
  Grid, TextField, Button, Menu, InputAdornment, MenuItem
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../../_theme';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import CloseIcon from '../../Assets/close.svg';
import { fetchCountryDetails, fetchIssuerDetails, fetchBrandListForScheme, resetBrandList } from "../../manageScheme/actions/index";
import {fetchPSPMetadata, resetPSPMetadata} from "../../manageMerchant/psp/actions/index";
import {fetchMerchantMetadata, resetMerchantMetadata} from "../../manageMerchant/merchant/actions/index";
import {fetchStoreMetadata, resetStoreMetadata} from "../../manageMerchant/store/actions/index";
import {fetchMCIProductTypesList, fetchMCIPaymentModelTypesList} from "../../manageMCI/actions/index";
import { descrRegex } from 'shared/utility';
import { LIMITED_SPECIAL_CHARACTERS } from "shared/errorMessages";

function Filter(props) {
  const {filterState, filterCells, handleFilterStateChange, FilterApply, resetFilter,
    issuerDetails, countryDetails, title, pspMetaData, merchantMetadata, storeMetadata,
    setsortName, setsortDir, sortDir, sortName, sortArr, brandListForScheme, categoryList,
    productTypeList, paymentModelTypeList,
  } = props;

  const {classes, cx} = useStyles();
  const [startAnchorEl, setStartAnchorEl] = useState(null)
  const [selectedField, setselectedField] = useState(null)
  const disableApplyButton = () => {
    const arr = filterState && filterCells && filterCells.length > 0 && filterCells.filter((obj) => {
      if (obj.type === "text" && filterState[obj.id] !== "" && !descrRegex.test(filterState[obj.id])) {
        return true;
      }
      return false;
    });
    return arr.length > 0;
  };
  let countryObj = []
  let issuerObj = []
  let countryName = null
  let issuerName = null
  let categoryName = null
  let brandName = null
  let pspName = null
  let installmentProductType = null
  let sortBy = 'Create Date'
  const countryArray = countryDetails && countryDetails !== null ? 
    countryDetails.countryList.map(obj => {
      if(filterState && filterState['isoCountryCodeNum'] && obj.isoCountryCodeNum &&
          obj.isoCountryCodeNum === filterState['isoCountryCodeNum'] && countryName === null) {
          countryName = (obj.name).slice(0, 30)
      }
      countryObj.push(obj)
      return (obj.name).slice(0, 30)
    }) : []
  const issuerArray = issuerDetails && issuerDetails !== null ? 
    issuerDetails.issuer.map(obj => {
      if(filterState && filterState['mciId'] && obj.mciId && obj.mciId === filterState['mciId'] && issuerName === null) {
        issuerName = obj.name
      }
      issuerObj.push(obj)
      return obj.name
    }) : []
  // const pspArr = pspMetaData && pspMetaData.map(psp => psp.name ? psp.name: null).filter(val => val !== null)
  const pspArr = pspMetaData && pspMetaData !== null ?
    pspMetaData.map(psp => {
      if(filterState && filterState['pspId'] && psp.id && psp.id === filterState['pspId'] && pspName === null) {
        pspName = psp.name
      }
      return psp.name
    }) : []
  const merchantArr = merchantMetadata && merchantMetadata.map(merchant => merchant.name ? merchant.name : null).filter(val => val !== null)
  const storeArr = storeMetadata && storeMetadata.map(store => store.name ? store.name : null).filter(val => val !== null)
  const SortArr = sortArr && sortArr.map(obj => {
    if(obj.key === sortName){
      sortBy = obj.label
    }
    return obj.label
  }).filter(val => val !== null)
  
  const categoryArr = categoryList && categoryList !== null ? 
    categoryList.map(obj => {
      if(filterState && filterState['productCategoryName'] && 
        obj.name && obj.name !== "" && obj.parentId && (obj.parentId !== null || obj.parentId !== "")
        && obj.name === filterState['productCategoryName'] && categoryName === null
      ) {
        categoryName = obj.name
      }
      return (obj.name && obj.name !== "" && obj.parentId && (obj.parentId !== null || obj.parentId !== "")) ? obj.name : null;
    }).filter(val => val !== null) : [] 

  const brandArr = brandListForScheme && brandListForScheme !== null ? 
    brandListForScheme.map(obj => {
      if(filterState && filterState['brandId'] && obj.id && obj.id === filterState['brandId'] && brandName === null) {
        brandName = obj.name
      }
      return obj.name
    }) : []

  const productTypeArr = productTypeList && productTypeList.length > 0 ?
    productTypeList.map(obj => {
        if(filterState && filterState['installmentProductType'] && obj.name && obj.name === filterState['installmentProductType'] && installmentProductType === null) {
          installmentProductType = obj.name
        }
        return obj.name
    }) : []
  
  let paymentModelType = filterState && filterState.paymentModelType === 0 ? "Blank" : null

  const paymentModelArr = paymentModelTypeList && paymentModelTypeList.length > 0 ?
    paymentModelTypeList.map(obj => {
      if(filterState && filterState['paymentModelType'] && obj.id && obj.id === filterState['paymentModelType'] && paymentModelType === null) {
        paymentModelType = obj.description
      }
      return obj.description
    }) : []
  paymentModelArr.push("Blank")

  useEffect(() => {
    //Removing API call as it's being called multiple times unnecessarily
    // countryArray && countryArray.length === 0 && props.fetchCountryDetails({})
    // issuerArray && issuerArray.length === 0 && props.fetchIssuerDetails({})

    if(title && title.includes("Payment") ) {
      countryArray && countryArray.length === 0 && props.fetchCountryDetails({})

    } else if(title && title.includes("Payment") && filterState && filterState['isoCountryCodeNum']) {
      props.fetchPSPMetadata({
        isoCountryCodeNum: filterState['isoCountryCodeNum']
      })

    } else if(title && title.includes("Scheme")) {
      issuerArray && issuerArray.length === 0 && props.fetchIssuerDetails({})

    } else if(title && title.includes("Scheme") && filterState && filterState['isoCountryCodeNum']) {
      props.fetchIssuerDetails({
        isoCountryCodeNum : filterState['isoCountryCodeNum']
      })

    } else if(title && title.includes("PSP Acquirer")) {
      countryArray && countryArray.length === 0 && props.fetchCountryDetails({})

      if(filterState && filterState['isoCountryCodeNum']){
        props.fetchPSPMetadata({
          isoCountryCodeNum : filterState['isoCountryCodeNum']
        }) 
        
      }
    } else if(title && title.includes("Merchant")) {
      countryArray && countryArray.length === 0 && props.fetchCountryDetails({})

      if(filterState && filterState['isoCountryCodeNum']){
        props.fetchPSPMetadata({
          isoCountryCodeNum : filterState['isoCountryCodeNum']
        }) 
        
      }
      // if(filterState && filterState['pspName'] && pspArr.indexOf(filterState['pspName']) !== -1 && 
      //   pspMetaData[pspArr.indexOf(filterState['pspName'])]) {
      //     props.fetchMerchantMetadata({
      //       pspId:  pspMetaData[pspArr.indexOf(filterState['pspName'])]["id"]
      //     })
      //   }
      // if(filterState && filterState['pspId']) {
        props.fetchMerchantMetadata({
          pspId:  filterState['pspId']
        })
      // }

    } else if(title && (title.includes("Store") || title.includes("Terminal"))) {
      countryArray && countryArray.length === 0 && props.fetchCountryDetails({})

      if(filterState && filterState['isoCountryCodeNum']){
        props.fetchPSPMetadata({
          isoCountryCodeNum : filterState['isoCountryCodeNum']
        })

      }

      // if(filterState && filterState['pspName'] && pspArr.indexOf(filterState['pspName']) !== -1 && 
      // pspMetaData[pspArr.indexOf(filterState['pspName'])]) {
      //   props.fetchMerchantMetadata({
      //     pspId: pspMetaData[pspArr.indexOf(filterState['pspName'])]["id"]
      //   })
      // }
      if(filterState && filterState['pspId']) {
        props.fetchMerchantMetadata({
          pspId:  filterState['pspId']
        })
      }

      if(filterState && filterState['merchantName'] && merchantArr.indexOf(filterState['merchantName']) !== -1 && 
        merchantMetadata[merchantArr.indexOf(filterState['merchantName'])]){
          props.fetchStoreMetadata({
          merchantId :  merchantMetadata[merchantArr.indexOf(filterState['merchantName'])]["id"]
        })
      }
    } else if(title && title.includes("Product Details")) {

      countryArray && countryArray.length === 0 && props.fetchCountryDetails({})
    
    } else if(title && title.includes("Brand Details")) {

      countryArray && countryArray.length === 0 && props.fetchCountryDetails({})
    
    } else if(title && title.includes("Country") || title.includes("Issuers") ) {

      countryArray && countryArray.length === 0 && props.fetchCountryDetails({})

    } else if(title && title.includes("Program") ) {

      countryArray && countryArray.length === 0 && props.fetchCountryDetails({})
    } else if(title && title.includes("Product Groups")) {

      countryArray && countryArray.length === 0 && props.fetchCountryDetails({})
      props.fetchBrandListForScheme({isoCountryCodeNum : filterState['isoCountryCodeNum'], status: "ACTIVE"});
    
    }

  }, [])

  const handleDateMenuToggle = (event, obj) => {
    obj && obj.id && setselectedField(obj.id)
    startAnchorEl === null ? setStartAnchorEl(event.currentTarget) : setStartAnchorEl(null)
  }

  const handleDateSelect = (event, key) => {
    handleDateMenuToggle(event)
    handleFilterStateChange(selectedField, event)
    setselectedField(null)
  }

  const inputDateField = (obj, i) => {

    return <Grid item xs={12} key={i}>
      <TextField 
        label={obj.label} name={obj.id} 
        id={`${obj.id}`} variant="outlined" fullWidth
        value={filterState[obj.id] && filterState[obj.id] !== null ? format( 
          new Date(filterState[obj.id]), 'dd-MMM-yyyy'): ""} 
        InputProps={{
            endAdornment: (
            <InputAdornment position="end" >
                <DateRangeIcon className={"calender_input"} />
            </InputAdornment>
            ),
        }} 
        onClick={(e) => handleDateMenuToggle(e, obj)}
      />
        {<Menu
          // keepMounted 
          id={`simple-${i}`} 
          elevation={1}
          anchorEl={startAnchorEl}
          open={Boolean(startAnchorEl)}
          onClose={() => setStartAnchorEl(null)}
          classes={{ paper: classes.profileMenu}}
          anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
        >
          {selectedField === "installmentStartDate" && filterState['installmentEndDate'] !== null ?
            <Calendar color="#1BA785" rangeColors={['#1BA785']}
              onChange={(e) => handleDateSelect(e, obj.id)}
              maxDate={new Date(filterState['installmentEndDate'])}
            /> : 
              selectedField === "installmentStartDate" && filterState['installmentEndDate'] === null ?
                <Calendar color="#1BA785" rangeColors={['#1BA785']}
                  onChange={(e) => handleDateSelect(e, obj.id)}
                /> :
                <Calendar color="#1BA785" rangeColors={['#1BA785']}
                  onChange={(e) => handleDateSelect(e, obj.id)}
                  minDate={new Date(filterState['installmentStartDate'])}
                />
          }
        </Menu>}
    </Grid>
  }

  const inputSelectField = (obj, i) => {
    const tempArr = obj.id === "mciId" ? issuerArray : obj.id === "isoCountryCodeNum" ? countryArray : 
      obj.id === "pspId" ? pspArr : obj.label === "Merchant Name" ? merchantArr : 
      obj.label === "Store Name" ? storeArr : obj.id === "statusCode" ? ["CREATE", "MODIFY", "DELETE"] : 
      obj.id === "brandName" ? brandArr : obj.id === "productCategoryName" ? categoryArr : obj.id === "type" ?
        ["CIB", "MIB", "BRAND"] : obj.id === "status" ? ["ACTIVE", "INACTIVE"] : obj.id === "brandId" ? brandArr :
        obj.id === "paymentModelType" ? paymentModelArr : obj.id === "installmentProductType" ? productTypeArr : ['CREDIT']

    const SelectedValue = obj.id === "isoCountryCodeNum" ? 
      countryName : obj.id === "mciId" ?  
        issuerName : obj.id === "pspId" ?
          pspName : obj.id === "brandId" ?
            brandName : obj.id === "paymentModelType" ? 
              paymentModelType : filterState[obj.id]

    return <Grid item xs={12} key={i}>
      <TextField
        InputLabelProps={{ shrink: true }} 
        variant="outlined" fullWidth select={true}
        name={obj.id} id={`${obj.id}${i}`}
        label={obj.label}
        value={SelectedValue}
        onChange={(e) => e.target.value !== "NA" && handleSelectChange(obj.id, e.target.value)}
      >
        {tempArr && tempArr.length === 0 ? 
          <MenuItem key={100} id={'NA'} value={"NA"} className={classes.menu}> {"No Data Found!"} </MenuItem>: null}
        {(tempArr).map((val, index) => {
          return <MenuItem key={index} id={val} value={val} className={classes.menu}>
              {val}
          </MenuItem>
        })}
      </TextField>
    </Grid>
  }

  const handleSelectChange = (key, value) => {
    if(key === "isoCountryCodeNum") {
      const Value = countryObj[countryArray.indexOf(value)]['isoCountryCodeNum']
      if(title && title.includes("Scheme")) {
        props.fetchIssuerDetails({
          isoCountryCodeNum : Value
        })
      } else if(title && title.includes("Payment")) {
        props.fetchPSPMetadata({
          isoCountryCodeNum : Value
        })
      } else if(title && title.includes("PSP Acquirer")) {
        props.fetchPSPMetadata({
          isoCountryCodeNum : Value
        })
      } else if(title && title.includes("Merchant")) {
        props.fetchPSPMetadata({
          isoCountryCodeNum : Value
        })
        // reset Merchant MetaData
        props.resetMerchantMetadata({})

      } else if(title && (title.includes("Store") || title.includes("Terminal"))) {
        props.fetchPSPMetadata({
          isoCountryCodeNum : Value
        })
        
        // reset Merchant & Store MetaData
        props.resetMerchantMetadata({})
        props.resetStoreMetadata({})

      } else if(title && title.includes("Product Details")) {
        props.fetchBrandListForScheme({isoCountryCodeNum : Value, status: "ACTIVE"});
      } else if(title && title.includes("Product Groups")) {
        props.fetchBrandListForScheme({isoCountryCodeNum : Value, status: "ACTIVE"});
      }

      handleFilterStateChange(key, Value)

    } else if(key === "mciId") {
      const Value = issuerObj[issuerArray.indexOf(value)]['mciId']
      handleFilterStateChange(key, Value)

    } else if(key === "sortBy") {
      const Value = sortArr[SortArr.indexOf(value)]['key']
      setsortName(Value)

    } else if (key === "paymentModelType") {
      const Value = value !== "Blank" ? paymentModelTypeList[paymentModelArr.indexOf(value)].id : 0;
      handleFilterStateChange(key, Value)

    } else if (key === "pspId") {

      const Value = pspMetaData[pspArr.indexOf(value)]['id']
      if (key === "pspId" && title && (title.includes("Merchant") || title.includes("Store") || title.includes("Terminal"))) {
        
        props.fetchMerchantMetadata({
          pspId : Value,
        })
        props.resetStoreMetadata({})
      }
      handleFilterStateChange(key, Value)
    } else if(key === "brandId") {

      const Value = brandListForScheme[brandArr.indexOf(value)]['id']
      handleFilterStateChange(key, Value)

    } else {
      handleFilterStateChange(key, value)
      if(key === "merchantName" && title && (title.includes("Store") || title.includes("Terminal"))) {
        props.fetchStoreMetadata({
          merchantId : merchantMetadata[merchantArr.indexOf(value)]['id'],
        })

      }
    }
  }

  const inputTextField = (obj, i ) => {
    return <Grid item xs={12} key={i}>
      <TextField
        variant="outlined" fullWidth
        name={obj.id} id={`${obj.id}${i}`}
        label={obj.label}
        value={filterState[obj.id]}
        onChange={(e) => handleFilterStateChange(obj.id, e.target.value)}
      />
      {filterState && filterState[obj.id] && filterState[obj.id] !== "" && !descrRegex.test(filterState[obj.id]) ?
      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
            <span className="error_message">
              <InfoOutlinedIcon />{" "}
              {LIMITED_SPECIAL_CHARACTERS}
            </span>
          </p> : null}
    </Grid>
  }

  const handleOnApply = () => {
    FilterApply()
    props.onClose()
  }

  const handleReset = () => {
    resetFilter()
    if(title && title.includes("Scheme")) {
      props.fetchIssuerDetails({})

    } else if(title && title.includes("Payment")) {
      props.resetPSPMetadata({})

    } else if(title && title.includes("Merchant")) {
      props.resetPSPMetadata({})
      props.resetMerchantMetadata({})

    } else if(title && (title.includes("Store") || title.includes("Terminal"))) {
      props.resetPSPMetadata({})
      props.resetMerchantMetadata({})
      props.resetStoreMetadata({})
    } else if(title && title.includes("Product")) {
      props.resetBrandList({})
    }
  }

  return (
    <div>
      <div className="close_dialog" onClick={props.onClose}><img src={CloseIcon} alt="close" /></div>
      <div className="filter_heading">Filter</div>
      
      <form className={"action_form filter_form " + (cx(classes.form))} noValidate autoComplete="off">
        <Grid container rowSpacing={2} columnSpacing={2}>

          {/* Sort by selection */}
          {SortArr && SortArr.length > 0 ? 
            <>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }} 
                  variant="outlined" fullWidth select={true}
                  name={"sortBy"} id={`sortBy"`}
                  label={"Sort By"}
                  value={sortBy}
                  onChange={(e) => e.target.value !== "NA" && handleSelectChange('sortBy', e.target.value)}
                >
                  {SortArr && SortArr.length === 0 ? 
                    <MenuItem key={100} id={'NA'} value={"NA"} className={classes.menu}> {"No Data Found!"} </MenuItem>: null}
                  {SortArr && (SortArr).map((val, index) => {
                    return <MenuItem key={index} id={val} value={val} className={classes.menu}>
                        {val}
                    </MenuItem>
                  })}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }} 
                  variant="outlined" fullWidth select={true}
                  name={"sortDir"} id={`sortDir"`}
                  label={"Sort order"}
                  value={sortDir}
                  onChange={(e) => e.target.value !== "NA" && setsortDir(e.target.value)}
                >
                  {(["ASC", "DESC"]).map((val, index) => {
                    return <MenuItem key={index} id={val} value={val} className={classes.menu}>
                        {val}
                    </MenuItem>
                  })}
                </TextField>
              </Grid>
            </> 
          : null}

          {filterCells && filterCells.map((obj, i) => {
            if(obj.type === "date") {
              return inputDateField(obj, i)
            } else if (obj.type === "select") {
              return inputSelectField(obj, i)
            } else {
              return inputTextField(obj, i)
            }
          })}
          
          <Grid item xs={6} className="button_text_sec MuiGrid-d-flex MuiGrid-align-items-xs-center grid_button">
            <Button className="clear_form"
              onClick={() => handleReset()}
            >
              Clear All
            </Button>
          </Grid>
          <Grid item xs={6} className={classes.buttonAlign}>
            <Button
              fullWidth variant="contained"
              color="primary" className={"action_login_button"}
              onClick={() => handleOnApply()}
              disabled={disableApplyButton()}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

const useStyles = tss.create({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonAlign: {
    justifyContent: "flex-end",
    display: "flex"
  }
});


function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
        fetchCountryDetails, fetchIssuerDetails,
        fetchPSPMetadata, fetchMerchantMetadata,
        fetchStoreMetadata, resetMerchantMetadata,
        resetStoreMetadata, resetPSPMetadata,
        fetchBrandListForScheme, resetBrandList,
        fetchMCIProductTypesList, fetchMCIPaymentModelTypesList
      }, dispatch)
  }
}

function mapStateToProps(state) {

  return {
    issuerDetails: state.SchemeReducer.issuerDetails,
    countryDetails: state.SchemeReducer.countryDetails,
    pspMetaData: state.PspReducer.pspMetaData !== null && state.PspReducer.pspMetaData.pspList ?
      state.PspReducer.pspMetaData.pspList : [],
    merchantMetadata: state.MerchantReducer.merchantMetadata !== null &&
      state.MerchantReducer.merchantMetadata.merchantList ?
        state.MerchantReducer.merchantMetadata.merchantList : [],
    storeMetadata: state.StoreReducer.storeMetadata !== null &&
      state.StoreReducer.storeMetadata.storeList ? 
        state.StoreReducer.storeMetadata.storeList : [],
    categoryList: state.ProductReducer.productCategoryDetails && state.ProductReducer.productCategoryDetails !== null && 
      state.ProductReducer.productCategoryDetails.category ? state.ProductReducer.productCategoryDetails.category : [],
    brandListForScheme: state.SchemeReducer.brandListForScheme && state.SchemeReducer.brandListForScheme !== null &&
      state.SchemeReducer.brandListForScheme.brand ? state.SchemeReducer.brandListForScheme.brand : [],
    productTypeList: state.MciReducer.productTypesList !== null && state.MciReducer.productTypesList.productTypeList ? 
      state.MciReducer.productTypesList.productTypeList : [],
    paymentModelTypeList: state.MciReducer.paymentModelTypesList !== null && state.MciReducer.paymentModelTypesList.paymentModelTypeList ? 
      state.MciReducer.paymentModelTypesList.paymentModelTypeList : [],
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filter);
