import React, { useState, useEffect } from 'react'
import {connect} from "react-redux";
import { bindActionCreators } from "redux";
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { 
  Grid, IconButton, Snackbar, Dialog, DialogActions,
  DialogTitle, DialogContentText, DialogContent, Button 
} from '@mui/material'
import { tss } from "tss-react";
import theme from '../../_theme'

import CloseImgIcon from '../../Assets/close.svg';
import BasePageContainer from '../../_common/basePageContainer'
import BasePageToolbar from '../../_common/basePageToolbar'
import TableComponent from "../table/index"
import { 
  fetchBrandDetails, fetchIndividualBrandDetails, resetIndividualBrand,
  uploadBrandDocument, resetUploadDocumentResp,
  handleCountrySelect, handleBrandNameSelect, clearBrandRes,
  handleDisplayNameSelect, handleDescriptionSelect, handleIsSerialNumberRequiredCheck,
} from "./actions";
import CreateEditOrViewBrand from "./CreateEditOrViewBrand/index";

const headCells = [
  { id: 'name', type: 'text', numeric: false, disablePadding: false, label: 'Brand Name' },
  { id: 'displayName', type: 'text', numeric: false, disablePadding: false, label: 'Display Name' },
  { id: 'description', type: 'description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'countryName', type: 'text', numeric: false, disablePadding: false, label: 'Country' },
  { id: 'status', type: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'createdOn', type: 'createdOn', numeric: false, disablePadding: false, label: 'Created On' },
  { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];

const ImageDataObj = {
  "screenType": "BRAND_IMAGE",
  "screenTypeCode": "400",
  "resourceId": "",
  "originalUrl": "",
  "baseURL": "",
  "file": null,
  "actionKey": "",
}

const filterCells = [
  { id: 'isoCountryCodeNum', type: 'select', label: 'Country' },
  { id: 'description', type: 'text', label: 'Description' },
  { id: 'name', type: 'text', label: 'Brand Name' }
];

const filterobj = {
  name: "",
  pageNo: 1,
  pageSize: 10,
	description: "",
  sortBy:"createdOn",
  sortDirection:"DESC",
  isoCountryCodeNum: "",
}

const sortArr = [
  {key:"createdOn", label: "Create Date"},
  {key:"countryName", label: "Country Name"},
  {key:"name", label: "Brand Name"}
]

const Brand = (props) => {
  const {classes} = useStyles();
  const { brandList, brandDetails, uploadDocumentRes, brandLoader, createBrandRes, totalRecord } = props;

  const [currentForm, setCurrentForm] = useState("list");
  const [brandId, setBrandId] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [imageData, setImageData] = useState(ImageDataObj);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openPopover, setOpenPopOver] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [openDeletePopover, setOpenDeletePopover] = useState(false);
  const [docsSnackbarMsg, setDocsSnackBarMsg] = useState(null);
  const [openDocsSnackbar, SetOpenDocsSnackbar] = useState(false);
  const currentPage = localStorage.getItem('currentPage')
  const [compInitaited, setCompInitiated] = useState(false);
  const [imageAction, setImageActionType] = useState("Upload")
  const [pageNo, setPage] = useState(1)
  const [sortName, setsortName] = useState('createdOn')
  const [sortDir, setsortDir] = useState('DESC');
  const [filterState, setFilterState] = useState(filterobj)

  let permissions = localStorage.getItem("permissions")
  permissions = permissions && permissions !== null ? JSON.parse(permissions) : []

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  });

  const brandArray = brandList && brandList !== null ? 
    brandList.map(obj => {

      let brandObj = {
        id: obj.id && obj.id,
        name: obj.name && obj.name,
        displayName: obj.displayName && obj.displayName,
        description: obj.description && obj.description,
        isoCountryCodeNum: obj.isoCountryCodeNum && obj.isoCountryCodeNum,
        countryName: obj.countryName && obj.countryName,
        createdBy: obj.createdBy && obj.createdBy,
        status: obj.status && obj.status,
        createdOn: obj.createdOn && obj.createdOn,
        sortOrder: obj.sortOrder && obj.sortOrder,
        isSerialNumberRequired: obj.isSerialNumberRequired && obj.isSerialNumberRequired,
        images: null,
        searchColumn: headCells.map((cell, index) => 
            cell.id === "action" ? "" : obj[cell.id] && obj[cell.id]
            ).join(" ")
      }
      return brandObj
    }) : []

  const brandDetailsObj = brandDetails && brandDetails !== null ? 
    {
      id: brandDetails.id && brandDetails.id,
      name: brandDetails.name && brandDetails.name,
      displayName: brandDetails.displayName && brandDetails.displayName,
      description: brandDetails.description && brandDetails.description,
      isoCountryCodeNum: brandDetails.isoCountryCodeNum && brandDetails.isoCountryCodeNum,
      countryName: brandDetails.countryName && brandDetails.countryName,
      createdBy: brandDetails.createdBy && brandDetails.createdBy,
      status: brandDetails.status && brandDetails.status,
      createdOn: brandDetails.createdOn && brandDetails.createdOn,
      sortOrder: brandDetails.sortOrder && brandDetails.sortOrder,
      isSerialNumberRequired: brandDetails.isSerialNumberRequired && brandDetails.isSerialNumberRequired,
      images: null,
    }
    : undefined

  const onChangeView = (inpval, row) => {
    setCurrentForm(inpval);
    if(inpval === "view") {
      setImageData({})
      props.fetchIndividualBrandDetails({"id" : row.id, pageNo: 1, pageSize: 10, sortBy: "createdOn", sortDirection: "DESC" });
    } else if(inpval === "edit") {
      setBrandId(row.id);
      setImageData({})
      props.fetchIndividualBrandDetails({ "id" : row.id, pageNo: 1, pageSize: 10, sortBy: "createdOn", sortDirection: "DESC"  });

    } else if(inpval === "list") {
      props.resetIndividualBrand()
      props.fetchBrandDetails({"pageNo": 1, "pageSize":10, "sortBy": sortName, "sortDirection": sortDir})
    
    }
  }

  useEffect(() => {
    if(currentPage && currentPage !== null && currentPage !== undefined && currentPage === "/brand" && compInitaited){
        setCurrentForm('list')
        setPage(1);
        setsortDir("DESC");
        setsortName("createdOn")
        setFilterState({...filterobj})
        props.fetchBrandDetails({ "pageNo": 1, "pageSize":10, "sortBy": sortName, "sortDirection": sortDir})
        localStorage.removeItem('currentPage')
    }
  }, [currentPage])

  useEffect(() => {
    const handleInitialCall = async () => {
      await setCompInitiated(true);
      await props.fetchBrandDetails({...filterState, "pageNo": pageNo, "sortBy": sortName, "sortDirection": sortDir});
      await localStorage.removeItem('currentPage')
      await props.resetUploadDocumentResp(null)
    }
    handleInitialCall();
  }, []);

  useEffect(() => {
    if(currentForm !== "create" && brandDetails && brandDetails !== null ) {
      if(brandDetails.images && brandDetails.images.length > 0) {
        setImageData({
          "screenType": brandDetails.images[0].screenType && brandDetails.images[0].screenType !== "" ? brandDetails.images[0].screenType : "",
          "screenTypeCode": brandDetails.images[0].screenTypeCode && brandDetails.images[0].screenTypeCode !== "" ? brandDetails.images[0].screenTypeCode : "",
          "resourceId": brandDetails.images[0].resourceId && brandDetails.images[0].resourceId !== "" ? brandDetails.images[0].resourceId : "",
          "originalUrl": brandDetails.images[0].baseURL && brandDetails.images[0].baseURL !== "" ? brandDetails.images[0].baseURL : "",
          "baseURL": brandDetails.images[0].baseURL && brandDetails.images[0].baseURL !== "" ? brandDetails.images[0].baseURL : "",
          "file": null,
          "actionKey": "",
        })
      } else {
        setImageData({
          "screenType": "BRAND_IMAGE",
          "screenTypeCode": "400",
          "resourceId": "",
          "originalUrl": "",
          "baseURL": "",
          "file": null,
          "actionKey": "",
        })
      }
    }
  }, [brandDetails]);

  useEffect(() => {
    if(uploadDocumentRes && uploadDocumentRes !== null && uploadDocumentRes.st) {
      SetOpenDocsSnackbar(true)
      setDocsSnackBarMsg(imageAction === "Upload" ? "Image Uploaded Successfully!" :
        "Image Removed Successfully!");
      
      // props.resetUploadDocumentResp(null)
    } else if(uploadDocumentRes && uploadDocumentRes !== null && !uploadDocumentRes.st) {
      SetOpenDocsSnackbar(true)
      setDocsSnackBarMsg(uploadDocumentRes.msg && uploadDocumentRes.msg)
        
      // props.resetUploadDocumentResp(null)
    }  else if(uploadDocumentRes && uploadDocumentRes !== null && uploadDocumentRes.status) {
        SetOpenDocsSnackbar(true)
        setDocsSnackBarMsg(uploadDocumentRes.error && uploadDocumentRes.error)
          
        // props.resetUploadDocumentResp(null)
    } 
    brandId && props.fetchIndividualBrandDetails({id : brandId, pageNo: 1, pageSize: 10, sortBy: "createdOn", sortDirection: "DESC" });
  }, [uploadDocumentRes])

  useEffect(() => {
    if(createBrandRes && createBrandRes !== null && !createBrandRes.st) {
        setOpenSnackbar(true)
        if(createBrandRes.msg) {
          setSnackbarMsg(createBrandRes.msg)
        } else if(createBrandRes.errors && createBrandRes.errors.length > 0) {
          const msgStr = createBrandRes.errors.map(val => val.errorDesc).join(", ")
          setSnackbarMsg(msgStr)
        }
    } else if(createBrandRes && createBrandRes !== null && createBrandRes.st) {
      if(currentForm === "create") {
        setOpenPopOver(true)
      } else {
        setOpenSnackbar(true)
        setSnackbarMsg(`Brand ${createBrandRes && createBrandRes.id ? 
          `with Id:${createBrandRes.id}`: "" } Modified Successfully!`)
        resetOnCreateSelect()
        onChangeView("list")
      }
      setBrandId(createBrandRes.id)
    }
  }, [createBrandRes]);

  const resetOnCreateSelect = async () => {
    if(currentForm === "create") {
      await props.handleCountrySelect(null);
      await props.handleBrandNameSelect("");
      await props.handleDisplayNameSelect("");
      await props.handleDescriptionSelect("");
      await props.handleIsSerialNumberRequiredCheck(false);
    }
  } 

  const handleSnackBarAction = () => {
    setOpenSnackbar(false)
    props.clearBrandRes(null)
  }

  const handleDialogAction = async (value, type) => {
    props.clearBrandRes(null)
    setOpenPopOver(value)
    if (type === "Create") {
      resetOnCreateSelect()
    } else {
      onChangeView("list")
    }
  }

  const handleSetFilterData = (filterArray, searchValue) => {
    setFilterData(filterArray)
    setSearchValue(searchValue)
  }

  const imageHandler = (e, key) => {
    
    if(e.target.files && e.target.files[0]) {
      let file = e.target.files[0]
      setImageActionType("Upload");
        
      if(file.size > 2097152) {
        window.alert("Image File Size limit is 2MB!")
        let tempElement = document.getElementById("input");
        if( tempElement && tempElement !== null ) {
            tempElement.value = null;
        }
      } else {
        let img = new Image()
        img.src = URL.createObjectURL(file)
        img.onload = function () {

          if(img.width !== 300 || img.height !== 150) {
            window.alert("Image Resolution should be 300 W X 150 H Pixel.")
            let tempElement = document.getElementById("input");
            tempElement.value = null;

          } else {
            const imageUrl = URL.createObjectURL(file)
            setImageData({
                ...imageData,
                "baseURL": imageUrl,
                "file": file,
                "actionKey": "UPLOAD",
            })
          }
        }
      }
    }
  }

  const handleUploadImage = async (type) => {

    let formData = new FormData()
    
    if ( type === "UPLOAD" && imageData.resourceId === "") {
      await formData.append('brandId', brandDetails.id && brandDetails.id)
      await formData.append('operation', "ADD")
      await formData.append('uuid', "")
      await formData.append('documentSubCategory', imageData.screenType)
      await formData.append('file', imageData.file)
      
      await props.uploadBrandDocument(formData, imageData.screenType)

      setImageData({
          ...imageData,
          "actionKey": "",
      })

    } else if ( type === "UPLOAD" && imageData.resourceId !== "" ) {
      await formData.append('brandId', brandDetails.id && brandDetails.id)
      await formData.append('operation', "EDIT")
      await formData.append('uuid', imageData.resourceId)
      await formData.append('documentSubCategory', imageData.screenType)
      await formData.append('file', imageData.file)

      await props.uploadBrandDocument(formData, imageData.screenType)
      
      setImageData({
          ...imageData,
          "actionKey": "",
      })

    } else if( type === "CANCEL" ) {
      let tempElement = document.getElementById("input");
      if( tempElement && tempElement !== null ) {
          tempElement.value = null;
      }
      setImageData({
          ...imageData,
          "resourceId": "",
          "originalUrl": "",
          "baseURL": "",
          "file": null,
          "actionKey": "",
      })
      
    } else if( type === "DELETE_ACTION" ) {
      let tempElement = document.getElementById("input");
      if( tempElement && tempElement !== null ) {
          tempElement.value = null;
      }
      setOpenDeletePopover(true);
    } 
  }

  const handleDeleteDialogAction = async (type) => {
    setOpenDeletePopover(false)
    if(type === "Delete" ){
      setImageActionType("Delete");
        let formData = new FormData()
        await formData.append('brandId', brandDetails.id && brandDetails.id)
        await formData.append('operation', "DELETE")
        await formData.append('uuid', imageData.resourceId)
        await formData.append('documentSubCategory', imageData.screenType)
        await formData.append('file', imageData.file)

        await props.uploadBrandDocument(formData, imageData.screenType)

        setImageData({
            "resourceId": "",
            "originalUrl": "",
            "baseURL": "",
            "file": null,
            "actionKey": "",
        })
    }
  }
  
  const handleFilterStateChange = (key, value) => {
    let tempFilter = {
        ...filterState,
        [key]: value
    }
    setFilterState(tempFilter);
  }

  const resetFilter = () => {
    setFilterState(filterobj);
    setPage(1);
    setsortDir("DESC");
    setsortName("createdOn")
    props.fetchBrandDetails({
      pageNo: 1,
      pageSize: 10,
      sortBy:"createdOn",
      sortDirection:"DESC"
    })
  }

  const FilterApply = () => {
    setPage(1)
    props.fetchBrandDetails({ ...filterState, pageNo: 1, sortBy: sortName, sortDirection: sortDir})
    setFilterState({...filterState});
  }

  const handleDocsSnackBarAction = () => {
    SetOpenDocsSnackbar(false)
    setDocsSnackBarMsg(null)
    props.resetUploadDocumentResp(null)
  }

  const handlePageNo = (val) => {
    setPage(val)
    props.fetchBrandDetails({ ...filterState,
      pageNo: val, pageSize: 10, sortBy: sortName, sortDirection: sortDir
    });
  }

  const handleSortDir = (value) => {
    setsortDir(value)
    setPage(1)
  }

  const handleSortName = (value) => {
    setsortName(value)
    setPage(1)
  }
  
  return (
    <>
      <BasePageToolbar currentForm={currentForm}  create={permissions.includes("43")} resetFilter={resetFilter} onChangeView={onChangeView}
        title={currentForm === 'list' ? "Brand Details" :  currentForm === 'view' ? "View Brand" : currentForm === "edit" ? "Modify Brand" : "Create Brand"}
        handleFilterStateChange={handleFilterStateChange} filterState={filterState} filterCells={filterCells} FilterApply={FilterApply}
        setsortName={handleSortName} setsortDir={handleSortDir} sortDir={sortDir} sortName={sortName} sortArr={sortArr}
        arrayToFilter={brandArray} handleSetFilterData={handleSetFilterData} isTableSearchReq={false} searchValue={searchValue}
      ></BasePageToolbar>
        <BasePageContainer>
          {currentForm === 'list' ?
          <Grid container className={classes.container}>
            <TableComponent
              sortOrder="desc"
              sortOrderBy="createdOn"
              loading={brandLoader}
              headCells={headCells}
              rows={searchValue && searchValue !== "" ? filterData : brandArray}
              onChangeView={onChangeView}
              showEdit={permissions.includes("44")}
              activeTab={filterState}
              totalRecord={totalRecord}
              handlePageNo={handlePageNo}
            />
          </Grid>
          : currentForm === 'create' ?
            <Grid container className={classes.formContainer}>
              <CreateEditOrViewBrand 
                currentForm={currentForm}
                onChangeView={onChangeView}
              />
            </Grid>
            :
            currentForm === "edit" ?
            <Grid container className={classes.formContainer}>
              <CreateEditOrViewBrand 
                currentForm={currentForm} 
                brandDetails={brandDetailsObj}
                onChangeView={onChangeView}
                brId={brandId}
                handleUploadImage={handleUploadImage}
                imageHandler={imageHandler}
                imageData={imageData}
              />
            </Grid> :
              <Grid container className={classes.formContainer}>
                <CreateEditOrViewBrand 
                  currentForm={currentForm} 
                  brandDetails={brandDetailsObj}
                  onChangeView={onChangeView}
                  imageData={imageData}
                />
              </Grid>
          }

          <Dialog
            open={openDeletePopover}
            onClose={() => handleDeleteDialogAction("exit")}
            className={"delete_dialog"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div className="close_dialog" onClick={() => handleDeleteDialogAction("exit")}><img src={CloseImgIcon} alt="close" /></div>
            <DialogTitle id="alert-dialog-title">
                <h2>{"Delete Confirmation!"}</h2>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  {`Are you sure do you want to delete ?`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" className="yes" autoFocus
                  onClick={() => handleDeleteDialogAction("Delete")} 
              >
                  Delete
              </Button>
              <Button color="primary" className="no"
                  onClick={() => handleDeleteDialogAction("exit")} 
              >
                  Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Snackbar key="dsocument-upload"
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={openDocsSnackbar}
            autoHideDuration={6000}
            onClose={() => handleDocsSnackBarAction()}
            action={
              <React.Fragment>
                  <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleDocsSnackBarAction()}>
                  <CloseIcon fontSize="small" />
                  </IconButton>
              </React.Fragment>
            }
          >
            <Alert onClose={() => handleDocsSnackBarAction()} severity={
              uploadDocumentRes && uploadDocumentRes !== null && !uploadDocumentRes.st ? "error" : "success"
              }>
              {docsSnackbarMsg}
            </Alert>
          </Snackbar>

          <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={() => handleSnackBarAction()}
            action={
            <React.Fragment>
              <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
                  <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
            }
          >
            <Alert onClose={() => handleSnackBarAction()} severity={
              createBrandRes && createBrandRes !== null && !createBrandRes.st ? "info" : "success"
            }>
                {snackbarMsg}
            </Alert>
          </Snackbar>

          <Dialog
            open={openPopover}
            onClose={() => handleDialogAction(false, "exit")}
            className={"delete_dialog"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <h2>{"Brand Created Successfully!"}</h2>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  {"Do you want to create new Brand ?"}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" className="yes" autoFocus
                  onClick={() => handleDialogAction(false, "Create")} 
              >
                  Create New
              </Button>
              <Button color="primary" className="yes"
                  onClick={() => handleDialogAction(false, "exit")} 
              >
                  Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </BasePageContainer>
    </>)
}
const useStyles = tss.create({
  formContainer: {
    paddingTop: theme.spacing(0),
  },
  container: {
    paddingTop: theme.spacing(2),
  },
});

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
          fetchBrandDetails, fetchIndividualBrandDetails,
          resetIndividualBrand, uploadBrandDocument,
          handleCountrySelect, handleBrandNameSelect,
          handleDisplayNameSelect, handleDescriptionSelect,
          handleIsSerialNumberRequiredCheck,
          resetUploadDocumentResp, clearBrandRes
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    brandList: state.BrandReducer.allBrandDetails && state.BrandReducer.allBrandDetails !== null && state.BrandReducer.allBrandDetails.brands ? 
      state.BrandReducer.allBrandDetails.brands : [],
    totalRecord:  state.BrandReducer.allBrandDetails && state.BrandReducer.allBrandDetails !== null && state.BrandReducer.allBrandDetails.totalRecord? 
      state.BrandReducer.allBrandDetails.totalRecord : 0,
    brandDetails: state.BrandReducer.brandDetails && state.BrandReducer.brandDetails !== null && state.BrandReducer.brandDetails.brands ? 
      state.BrandReducer.brandDetails.brands[0] : undefined,
    uploadDocumentRes: state.BrandReducer.uploadDocumentRes,
    brandLoader: state.BrandReducer.brandLoader,
    createBrandRes: state.BrandReducer.createBrandRes,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Brand);
