import React, { useState, useEffect } from 'react'
import {connect} from "react-redux";
import { bindActionCreators } from "redux";
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { 
  Grid, IconButton, Snackbar, Dialog, DialogActions,
  DialogTitle, DialogContentText, DialogContent, Button 
} from '@mui/material'
import { tss } from "tss-react";
import theme from '../../_theme'

import CloseImgIcon from '../../Assets/close.svg';
import BasePageContainer from '../../_common/basePageContainer'
import BasePageToolbar from '../../_common/basePageToolbar'
import TableComponent from "../table/index"
import { 
  fetchProductCategryDetails, fetchIndividualProductCategryDetails, 
  resetIndividualProductCategory, uploadCategoryDocument,
  resetUploadDocumentResp, clearProductCateogryRes,
  handleProductCategorySelect, handleDisplayNameSelect,
  handleDescriptionSelect, handleParentCategorySelect,
} from "./actions";
import CreateEditViewCategory from "./createEditCategory/index"
import {fetchMasterProductCategories} from '../product/actions'

const headCells = [
  { id: 'name', type: 'text', numeric: false, disablePadding: false, label: 'Category Name' },
  { id: 'displayName', type: 'text', numeric: false, disablePadding: false, label: 'Display Name' },
  { id: 'description', type: 'description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'parentName', type: 'text', numeric: true, disablePadding: false, label: 'Parent Category' },
  { id: 'status', type: 'status', numeric: true, disablePadding: false, label: 'Status' },
  { id: 'createdOn', type: 'createdOn', numeric: true, disablePadding: false, label: 'Created On' },
  { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];

const ImageDataObj = {
  "screenType": "PRODUCT_CATEGORY_IMAGE",
  "screenTypeCode": "400",
  "resourceId": "",
  "originalUrl": "",
  "baseURL": "",
  "file": null,
  "actionKey": "",
}

const filterCells = [
  { id: 'parentName', type: 'text', label: 'Parent Category' },
  { id: 'name', type: 'text', label: 'Category Name' },
  { id: 'displayName', type: 'text', label: 'Display Name' },
];

const filterobj = {
  name: "",
  pageNo: 1,
  pageSize: 10,
	parentName: "",
  displayName: "",
  sortBy:"createdOn",
  sortDirection:"DESC",
}

const sortArr = [
  {key:"createdOn", label: "Create Date"},
  {key:"parentName", label: "Parents Category"},
  {key:"name", label: "Category Name"},
  {key:"displayName", label: "Display Name"},
]


const ProductCategory = (props) => {
  const {classes} = useStyles();
  const {productCategoryList, productCategoryDetails, uploadDocumentRes, categoryLoader, 
    createProductCategoryRes, totalRecord
  } = props;
  
  const [currentForm, setCurrentForm] = useState("list");
  const [categoryID, setCategoryId] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [openPopover, setOpenPopOver] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [imageData, setImageData] = useState(ImageDataObj);
  const [openDeletePopover, setOpenDeletePopover] = useState(false);
  const [docsSnackbarMsg, setDocsSnackBarMsg] = useState(null);
  const [imageAction, setImageActionType] = useState("Upload")
  const [openDocsSnackbar, SetOpenDocsSnackbar] = useState(false);
  const currentPage = localStorage.getItem('currentPage')
  const [compInitaited, setCompInitiated] = useState(false);
  const [pageNo, setPage] = useState(1)
  const [sortName, setsortName] = useState('createdOn')
  const [sortDir, setsortDir] = useState('DESC');
  const [filterState, setFilterState] = useState(filterobj)
  
  let permissions = localStorage.getItem("permissions")
  permissions = permissions && permissions !== null ? JSON.parse(permissions) : []

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  });

  const productCategoryArray = productCategoryList && productCategoryList !== null ? 
    productCategoryList.map(obj => {
      let productCategoryObj = {
        id: obj.id && obj.id,
        name: obj.name && obj.name,
        displayName: obj.displayName && obj.displayName,
        description: obj.description && obj.description,
        parentId: obj.parentId && obj.parentId,
        parentName: obj.parentName && obj.parentName,
        status: obj.status && obj.status,
        createdOn: obj.createdOn && obj.createdOn,
        createdBy: obj.createdBy && obj.createdBy,
        searchColumn: headCells.map((cell, index) => 
            cell.id === "action" ? "" : obj[cell.id] && obj[cell.id]
            ).join(" ")
      }
      return productCategoryObj
    }) : []

  const categoryDetailsObj = productCategoryDetails && productCategoryDetails !== null ? 
  {
    id: productCategoryDetails.id && productCategoryDetails.id,
    name: productCategoryDetails.name && productCategoryDetails.name,
    displayName: productCategoryDetails.displayName && productCategoryDetails.displayName,
    description: productCategoryDetails.description && productCategoryDetails.description,
    parentId: productCategoryDetails.parentId && productCategoryDetails.parentId,
    parentName: productCategoryDetails.parentName && productCategoryDetails.parentName,
    status: productCategoryDetails.status && productCategoryDetails.status,
    createdOn: productCategoryDetails.createdOn && productCategoryDetails.createdOn,
    createdBy: productCategoryDetails.createdBy && productCategoryDetails.createdBy
  }
  : undefined

  const onChangeView = (inpval, row) => {
    setCurrentForm(inpval);
    if(inpval === "view") {
      setImageData({})
      props.fetchIndividualProductCategryDetails({ "id" : row.id, "pageNo": 1, "pageSize":10, "sortBy": "createdOn", "sortDirection": "DESC" });
    } else if(inpval === "edit") {
      setCategoryId(row.id);
      setImageData({})
      props.fetchIndividualProductCategryDetails({ "id" : row.id,"pageNo": 1, "pageSize":10, "sortBy": "createdOn", "sortDirection": "DESC" });

    } else if(inpval === "list") {
      props.resetIndividualProductCategory()
      props.fetchProductCategryDetails({"pageNo": 1, "pageSize":10, "sortBy": sortName, "sortDirection": sortDir })
    
    }
  }

  useEffect(() => {
    if(currentPage && currentPage !== null && currentPage !== undefined && currentPage === "/category" && compInitaited){
        setCurrentForm('list')
        setPage(1);
        setsortDir("DESC");
        setsortName("createdOn")
        setFilterState({...filterobj})
        props.fetchProductCategryDetails({"pageNo": 1, "pageSize":10, "sortBy": sortName, "sortDirection": sortDir})
        localStorage.removeItem('currentPage')
    }
  }, [currentPage])

  useEffect(() => {
    const handleInitialCall = async () => {
      await setCompInitiated(true);
      await props.fetchProductCategryDetails({ ...filterState, "pageNo": pageNo, "pageSize": 10, "sortBy": sortName, "sortDirection": sortDir });
      await localStorage.removeItem('currentPage')
      await props.resetUploadDocumentResp(null)
    }
    handleInitialCall();
  }, [])

  useEffect(() => {
    if(currentForm !== "create" && productCategoryDetails && productCategoryDetails !== null ) {
      if(productCategoryDetails.images && productCategoryDetails.images.length > 0) {
        setImageData({
          "screenType": productCategoryDetails.images[0].screenType && productCategoryDetails.images[0].screenType !== "" ? productCategoryDetails.images[0].screenType : "",
          "screenTypeCode": productCategoryDetails.images[0].screenTypeCode && productCategoryDetails.images[0].screenTypeCode !== "" ? productCategoryDetails.images[0].screenTypeCode : "",
          "resourceId": productCategoryDetails.images[0].resourceId && productCategoryDetails.images[0].resourceId !== "" ? productCategoryDetails.images[0].resourceId : "",
          "originalUrl": productCategoryDetails.images[0].baseURL && productCategoryDetails.images[0].baseURL !== "" ? productCategoryDetails.images[0].baseURL : "",
          "baseURL": productCategoryDetails.images[0].baseURL && productCategoryDetails.images[0].baseURL !== "" ? productCategoryDetails.images[0].baseURL : "",
          "file": null,
          "actionKey": "",
        })
      } else {
        setImageData({
          "screenType": "PRODUCT_CATEGORY_IMAGE",
          "screenTypeCode": "400",
          "resourceId": "",
          "originalUrl": "",
          "baseURL": "",
          "file": null,
          "actionKey": "",
        })
      }
    }
  }, [productCategoryDetails]);

  useEffect(() => {
    if(uploadDocumentRes && uploadDocumentRes !== null && uploadDocumentRes.st) {
      SetOpenDocsSnackbar(true)
      setDocsSnackBarMsg(imageAction === "Upload" ? "Image Uploaded Successfully!" :
        "Image Removed Successfully!");
      
    } else if(uploadDocumentRes && uploadDocumentRes !== null && !uploadDocumentRes.st) {
      SetOpenDocsSnackbar(true)
      setDocsSnackBarMsg(uploadDocumentRes.msg && uploadDocumentRes.msg)
        
    }  else if(uploadDocumentRes && uploadDocumentRes !== null && uploadDocumentRes.status) {
        SetOpenDocsSnackbar(true)
        setDocsSnackBarMsg(uploadDocumentRes.error && uploadDocumentRes.error)
          
    } 
    categoryID && props.fetchIndividualProductCategryDetails(
      { "id" : categoryID, pageNo: 1, pageSize: 10, sortBy: "createdOn", sortDirection: "DESC" }
    );
  }, [uploadDocumentRes])

  useEffect(() => {
    if(createProductCategoryRes && createProductCategoryRes !== null && !createProductCategoryRes.st) {
        setOpenSnackbar(true)
        if(createProductCategoryRes.msg) {
          setSnackbarMsg(createProductCategoryRes.msg)
        } else if(createProductCategoryRes.errors && createProductCategoryRes.errors.length > 0) {
          const msgStr = createProductCategoryRes.errors.map(val => val.errorDesc).join(", ")
          setSnackbarMsg(msgStr)
        }
    } else if(createProductCategoryRes && createProductCategoryRes !== null && createProductCategoryRes.st) {
      if(currentForm === "create") {
        setOpenPopOver(true)
      } else {
        setOpenSnackbar(true)
        setSnackbarMsg(`Product Category ${createProductCategoryRes && createProductCategoryRes.id ? 
          `with Id:${createProductCategoryRes.id}`: "" } Modified Successfully!`)
        resetOnCreateSelect()
        onChangeView("list")
      }
    }
  }, [createProductCategoryRes]);

  const handleDialogAction = async (value, type) => {
    props.clearProductCateogryRes(null)
    setOpenPopOver(value)
    if (type === "Create") {
        resetOnCreateSelect()
        // await setProgress(23)
        //manually clear all fields
    } else {
        onChangeView("list")
    }
  }

  const handleSnackBarAction = () => {
    setOpenSnackbar(false)
    props.clearProductCateogryRes(null)
    // onChangeView("list")
  }

  const resetOnCreateSelect = async () => {
    if(currentForm === "create") {
      await props.fetchMasterProductCategories();
      await props.handleProductCategorySelect("");
      await props.handleDisplayNameSelect("");
      await props.handleDescriptionSelect("");
      await props.handleParentCategorySelect(null);
    }
  }  

  const handleSetFilterData = (filterArray, searchValue) => {
    setFilterData(filterArray)
    setSearchValue(searchValue)
  }

  const imageHandler = (e) => {
    
    if(e.target.files && e.target.files[0]) {
      let file = e.target.files[0]
      const imageUrl = URL.createObjectURL(file)
        
      if(file.size > 2097152) {
        window.alert("Image File Size limit is 2MB!")
        let tempElement = document.getElementById("input");
        if( tempElement && tempElement !== null ) {
            tempElement.value = null;
        }
      } else {
        let img = new Image()
        img.src = URL.createObjectURL(file)
        img.onload = function () {

          if(img.width !== 120 || img.height !== 120) {
            window.alert("Image Resolution should be 120 X 120 Pixel.")
            let tempElement = document.getElementById("input");
            tempElement.value = null;

          } else {
            const imageUrl = URL.createObjectURL(file)
            setImageData({
                ...imageData,
                "baseURL": imageUrl,
                "file": file,
                "actionKey": "UPLOAD",
            })
          }
        }
      }
    }
  }

  const handleUploadImage = async (type) => {

    let formData = new FormData()
    
    if ( type === "UPLOAD" && imageData.resourceId === "") {
      setImageActionType('Upload')
      await formData.append('productCategoryId', productCategoryDetails.id && productCategoryDetails.id)
      await formData.append('operation', "ADD")
      await formData.append('uuid', "")
      await formData.append('documentSubCategory', imageData.screenType)
      await formData.append('file', imageData.file)
      
      await props.uploadCategoryDocument(formData, imageData.screenType)

      setImageData({
          ...imageData,
          "actionKey": "",
      })

    } else if ( type === "UPLOAD" && imageData.resourceId !== "" ) {
      setImageActionType('Upload')
      await formData.append('productCategoryId', productCategoryDetails.id && productCategoryDetails.id)
      await formData.append('operation', "EDIT")
      await formData.append('uuid', imageData.resourceId)
      await formData.append('documentSubCategory', imageData.screenType)
      await formData.append('file', imageData.file)

      await props.uploadCategoryDocument(formData, imageData.screenType)
      
      setImageData({
          ...imageData,
          "actionKey": "",
      })

    } else if( type === "CANCEL" ) {

      let tempElement = document.getElementById("input");
      if( tempElement && tempElement !== null ) {
          tempElement.value = null;
      }
      setImageData({
          ...imageData,
          "resourceId": "",
          "originalUrl": "",
          "baseURL": "",
          "file": null,
          "actionKey": "",
      })
    } else if( type === "DELETE_ACTION" ) {
      
      let tempElement = document.getElementById("input");
      if( tempElement && tempElement !== null ) {
          tempElement.value = null;
      }
      setOpenDeletePopover(true);
    } 
  }

  const handleDeleteDialogAction = async (type) => {
    setOpenDeletePopover(false)
    if(type === "Delete" ){
      setImageActionType("Delete");
      let formData = new FormData()
      await formData.append('productCategoryId', productCategoryDetails.id && productCategoryDetails.id)
      await formData.append('operation', "DELETE")
      await formData.append('uuid', imageData.resourceId)
      await formData.append('documentSubCategory', imageData.screenType)
      await formData.append('file', imageData.file)

      await props.uploadCategoryDocument(formData, imageData.screenType)

      setImageData({
          "resourceId": "",
          "originalUrl": "",
          "baseURL": "",
          "file": null,
          "actionKey": "",
      })
    }
  }
  
  const handleFilterStateChange = (key, value) => {
    let tempFilter = {
        ...filterState,
        [key]: value
    }
    setFilterState(tempFilter);
  }

  const resetFilter = () => {
    setFilterState(filterobj);
    setPage(1);
    setsortDir("DESC");
    setsortName("createdOn")
    props.fetchProductCategryDetails({
      pageNo: 1,
      pageSize: 10,
      sortBy:"createdOn",
      sortDirection:"DESC"
    })
  }

  const FilterApply = () => {
    setPage(1)
    props.fetchProductCategryDetails({ ...filterState, pageNo: 1, sortBy: sortName, sortDirection: sortDir})
    setFilterState({...filterState});
  }

  const handleDocsSnackBarAction = () => {
    SetOpenDocsSnackbar(false)
    setDocsSnackBarMsg(null)
    props.resetUploadDocumentResp(null)
  }

  const handlePageNo = (val) => {
    setPage(val)
    props.fetchProductCategryDetails({ ...filterState,
      pageNo: val, pageSize: 10, sortBy: sortName, sortDirection: sortDir
    });
  }

  const handleSortDir = (value) => {
    setsortDir(value)
    setPage(1)
  }

  const handleSortName = (value) => {
    setsortName(value)
    setPage(1)
  }

  return (
    <>
      <BasePageToolbar currentForm={currentForm}  create={permissions.includes("40")} resetFilter={resetFilter} onChangeView={onChangeView}
        title={currentForm === 'list' ? "Product Category Details" :  currentForm === 'view' ? "View Product Category" : currentForm === "edit" ? "Modify Product Category" : "Create Product Category"}
        arrayToFilter={productCategoryArray} handleSetFilterData={handleSetFilterData} isTableSearchReq={false} searchValue={searchValue}
        handleFilterStateChange={handleFilterStateChange} filterState={filterState} filterCells={filterCells} FilterApply={FilterApply}
        setsortName={handleSortName} setsortDir={handleSortDir} sortDir={sortDir} sortName={sortName} sortArr={sortArr}
      ></BasePageToolbar>
        <BasePageContainer>
            {currentForm === 'list' ?
            <Grid container className={classes.container}>
              <TableComponent
                sortOrder="desc"
                sortOrderBy="createdOn"
                loading={categoryLoader}
                headCells={headCells}
                rows={searchValue && searchValue !== "" ? filterData : productCategoryArray}
                onChangeView={onChangeView}
                showEdit={permissions.includes("41")}
                activeTab={filterState}
                totalRecord={totalRecord}
                handlePageNo={handlePageNo}
              />
            </Grid>
            : currentForm === 'create' ?
              <Grid container className={classes.formContainer}>
                <CreateEditViewCategory 
                  currentForm={currentForm}
                  onChangeView={onChangeView}
                />
              </Grid>
              :
              currentForm === "edit" ?
              <Grid container className={classes.formContainer}>
                  <CreateEditViewCategory 
                    currentForm={currentForm} 
                    categoryDetails={categoryDetailsObj}
                    onChangeView={onChangeView}
                    categoryID={categoryID}
                    handleUploadImage={handleUploadImage}
                    imageHandler={imageHandler}
                    imageData={imageData}
                  />
              </Grid> :
                <Grid container className={classes.formContainer}>
                    <CreateEditViewCategory 
                      currentForm={currentForm} 
                      categoryDetails={categoryDetailsObj}
                      onChangeView={onChangeView}
                      imageData={imageData}
                    />
                </Grid>
            }

            <Dialog
              open={openDeletePopover}
              onClose={() => handleDeleteDialogAction("exit")}
              className={"delete_dialog"}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div className="close_dialog" onClick={() => handleDeleteDialogAction("exit")}><img src={CloseImgIcon} alt="close" /></div>
              <DialogTitle id="alert-dialog-title">
                  <h2>{"Delete Confirmation!"}</h2>
              </DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      {`Are you sure do you want to delete ?`}
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button color="primary" className="yes" autoFocus
                      onClick={() => handleDeleteDialogAction("Delete")} 
                  >
                      Delete
                  </Button>
                  <Button color="primary" className="no"
                      onClick={() => handleDeleteDialogAction("exit")} 
                  >
                      Cancel
                  </Button>
              </DialogActions>
            </Dialog>

            <Snackbar key="dsocument-upload"
              anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
              }}
              open={openDocsSnackbar}
              autoHideDuration={6000}
              onClose={() => handleDocsSnackBarAction()}
              action={
                <React.Fragment>
                  <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleDocsSnackBarAction()}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            >
                <Alert onClose={() => handleDocsSnackBarAction()} severity={
                  uploadDocumentRes && uploadDocumentRes !== null && !uploadDocumentRes.st ? "error" : "success"
                }>
                  {docsSnackbarMsg}
                </Alert>
            </Snackbar>

            <Snackbar
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
              }}
              open={openSnackbar}
              autoHideDuration={3000}
              onClose={() => handleSnackBarAction()}
              action={
              <React.Fragment>
                  <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
                      <CloseIcon fontSize="small" />
                  </IconButton>
              </React.Fragment>
              }
            >
              <Alert onClose={() => handleSnackBarAction()} severity={
                createProductCategoryRes && createProductCategoryRes !== null && !createProductCategoryRes.st ? "info" : "success"
              }>
                  {snackbarMsg}
              </Alert>
            </Snackbar>
            <Dialog
              open={openPopover}
              onClose={() => handleDialogAction(false, "exit")}
              className={"delete_dialog"}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <h2>{"Product Category Created Successfully!"}</h2>
              </DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {createProductCategoryRes && createProductCategoryRes !== null && createProductCategoryRes.parentId && createProductCategoryRes.parentId !== ""
                    ?  "Click on Create New button if you want to create new Product Category"
                      : `Please create the Sub-Cateogry for the created Parent Category ${createProductCategoryRes && createProductCategoryRes.name && createProductCategoryRes.name}!`
                    }
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button color="primary" className="yes" autoFocus
                      onClick={() => handleDialogAction(false, "Create")} 
                  >
                    Create New
                  </Button>
                  <Button color="primary" className="yes"
                        onClick={() => handleDialogAction(false, "exit")} 
                    >
                      Cancel
                    </Button>
              </DialogActions>
            </Dialog>

        </BasePageContainer>
    </>)
}
const useStyles = tss.create({
  formContainer: {
    paddingTop: theme.spacing(0),
  },
  container: {
    paddingTop: theme.spacing(2),
  },
});

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
        fetchProductCategryDetails, fetchIndividualProductCategryDetails,
        resetIndividualProductCategory, uploadCategoryDocument,
        resetUploadDocumentResp, clearProductCateogryRes,
        handleProductCategorySelect, handleDisplayNameSelect,
        handleDescriptionSelect, handleParentCategorySelect,
        fetchMasterProductCategories
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    productCategoryList: state.ProductCategoryReducer.productCategoryDetails && state.ProductCategoryReducer.productCategoryDetails !== null && state.ProductCategoryReducer.productCategoryDetails.productCategories ? 
      state.ProductCategoryReducer.productCategoryDetails.productCategories : [],
    totalRecord: state.ProductCategoryReducer.productCategoryDetails && state.ProductCategoryReducer.productCategoryDetails !== null && state.ProductCategoryReducer.productCategoryDetails.totalRecord ? 
      state.ProductCategoryReducer.productCategoryDetails.totalRecord : 0,
    productCategoryDetails: state.ProductCategoryReducer.individualProductCategoryDetails && state.ProductCategoryReducer.individualProductCategoryDetails !== null && state.ProductCategoryReducer.individualProductCategoryDetails.productCategories ? 
      state.ProductCategoryReducer.individualProductCategoryDetails.productCategories[0] : undefined,
    uploadDocumentRes: state.ProductCategoryReducer.uploadDocumentRes,
    categoryLoader: state.ProductCategoryReducer.categoryLoader,
    createProductCategoryRes: state.ProductCategoryReducer.createProductCategoryRes,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductCategory);
