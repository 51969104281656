import { actionCreator, BASE_URL } from "../shared/utility";
import { SharedActions } from "./consts";

export function uploadDocument(payload, type) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(SharedActions.uploadDocument.REQUEST, {key: type}))
        fetch(`${BASE_URL}/oem/api/data/web/editProductImage`, {
            method: 'POST',
            body: payload, //JSON.stringify(payload),
            headers: {
                // 'Content-Type':'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(SharedActions.uploadDocument.FAILURE, {...response, key: type}))
                }
            } else {
                dispatch(actionCreator(SharedActions.uploadDocument.SUCCESS, {...response, key: type}))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetUploadDocumentResp() {
    return(dispatch) => {
        dispatch(actionCreator(SharedActions.uploadDocument.FAILURE, null))
    }
}