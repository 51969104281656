import React from 'react'
import { 
    Dialog, DialogActions, DialogTitle, DialogContentText, DialogContent, Button,
    Grid, TextField  
} from '@mui/material'
import { tss } from "tss-react";
import theme from '../../_theme'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import CloseImgIcon from '../../Assets/close.svg';

const useStyles = tss.create({
    color: {
        color: "white",
        backgroundColor: `#1BA785 !important`
    },
    approveDialog: {
        width: "100%",
        textAlign: 'center',
    },
});

export default function CustomDialogBox(props) {

    const {classes, cx} = useStyles();
    const {
        openDialog, handleDialogAction, title, body, handleExpiryDateTime, dialogType, filterState
    } = props

    const handleChange = (key, value) => {
        handleExpiryDateTime(key, value)
    }

    return (
        <Dialog
            open={openDialog}
            onClose={() => handleDialogAction("close")}
            className={"delete_dialog"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div className="close_dialog" onClick={() => handleDialogAction("close")}><img src={CloseImgIcon} alt="close" /></div>
            <DialogTitle id="alert-dialog-title">
                <h2>{title}</h2>
            </DialogTitle>
            <DialogContent>
                <div>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                        <Grid item xs={12}>
                            <DialogContentText id="alert-dialog-description" className={cx(classes.approveDialog)}>
                                {body}
                            </DialogContentText>
                        </Grid>
                        {dialogType === "Re-Create" &&
                        <Grid item xs={12}>
                            <TextField 
                                InputLabelProps={{ shrink: true }}
                                fullWidth required variant="outlined" type="datetime-local" 
                                id="expiryDateTime" name="expiryDateTime" label="Expiry Date Time(UTC)" 
                                value={
                                    filterState && filterState.expiryDateTime !== null && filterState.expiryDateTime !== ""
                                    ? `${filterState.expiryDateTime.slice(0,16)}` : ""
                                }
                                onChange={(e) => handleChange("expiryDateTime", e.target.value)}
                            />
                            {filterState && (filterState.expiryDateTime === null || filterState.expiryDateTime === "") ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Required Field"}</span>
                            </p> : null
                            }
                        </Grid>}
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button className={classes.color}
                    onClick={() => handleDialogAction("confirm")} 
                    disabled={dialogType === "Re-Create" && filterState && (filterState.expiryDateTime === null || filterState.expiryDateTime === "")}
                >
                    Confirm
                </Button>
                <Button className={classes.color}
                    onClick={() => handleDialogAction("close")} 
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}