import React, {useState} from 'react';
import { bindActionCreators } from "redux";
import {connect} from "react-redux";
 import {reduxForm} from 'redux-form';
import {
    Typography, IconButton, Checkbox,
    Grid, TextField, MenuItem, Button, Paper, 
}   from '@mui/material';
import { tss } from "tss-react";
import theme from '../../_theme'
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import { fetchInterestTypes } from "../actions/index";
import { numbRegex, allZeroRegex, nameRegExp, descrRegex, tNcRegex } from "../../shared/utility";
import { NO_SPECIAL_CHARACTERS, ONLY_NUMBERS } from "shared/errorMessages";

const useStyles = tss.create({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        border: "solid 1px #EEEEEE",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(5),
    },
    input: {
        display: 'none',
    },
    menu: {
        fontSize: "15px",
    },
    marginTopBottom: {
        margin: theme.spacing(2, 0, 2, 0),
    },
    titleText: {
        color: "#424242",
        fontFamily: "Quicksand",
        fontSize: "26px",
        // fontSize: "24px",
        letterSpacing: 0,
        lineHeight: "30px"
    },
});

function CreateEditViewIssuer(props) {
    const {classes} = useStyles();
    const { 
        currentForm, issuerState, countryList, handleInputChange, createDisableToken, 
        handleIssuerCreateUpdate, issuerDetails, onChangeView, handleUploadImage, 
        imageHandler, imageData, docUploadLoader, interestTypes
    } = props;
    const [valObj, setValObj] = useState({
        nameErr: false, displayNameErr: false, descriptionErr: false, countryErr: false, mciIdErr: false, interestTypeErr: false
    });
    const interTypeArr = [];
    const interTypeObj = []
    interestTypes && interestTypes !== null && Object.keys(interestTypes).length > 0 &&
        Object.keys(interestTypes).map(obj => {
            interTypeArr.push(interestTypes[obj]);
            interTypeObj.push(obj);
            return null;
        });
    
    let countryArr = []
    const countryArrObj = countryList && 
        countryList.filter((obj) => {
            if (obj.name && obj.name !== "") {
                countryArr.push(obj.name)
            }
            return obj.name && obj.name !== ""
        })

    const handleChange = (type, value) => {
        if(type === "country"){
            countryArr.indexOf(value) !== -1 && handleInputChange(type, [
                value,
                countryArrObj[countryArr.indexOf(value)].isoCountryCodeNum,
                countryArrObj[countryArr.indexOf(value)].isoCountryCode,
            ])
            setValObj({...valObj, countryErr: false })
        } else if(type === "mciId") {
            handleInputChange(type, value)
            setValObj({...valObj, mciIdErr: value.length === 0 })
        } else if(type === "name") {
            handleInputChange(type, value)
            setValObj({...valObj, nameErr: value.length === 0 })
        } else if(type === "displayName") {
            handleInputChange(type, value)
            setValObj({...valObj, displayNameErr: value.length === 0 })
        } else if(type === "interestType") {
            interTypeArr.indexOf(value) !== -1 && 
            handleInputChange(type, interTypeObj[interTypeArr.indexOf(value)])
            setValObj({...valObj, interestTypeErr: false })
        }
    }
    
    const handleOnBlur = (field, value) => {
        if(value === null || value === ""){
            setValObj({...valObj, [field]: true })
        } else {
            setValObj({...valObj, [field]: false })
        } 
    }
  
    React.useEffect(() => {
        interestTypes === null && props.fetchInterestTypes();
    }, [])

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <form className="action_form merchant_form" noValidate autoComplete="off">
                    <Grid container rowSpacing={2} columnSpacing={2}>

                        {currentForm === "edit" &&
                            <>
                                <Grid item xs={12} sm={8} />
                                <Grid item xs={12} sm={4}>
                                    <TextField 
                                        InputLabelProps={{ shrink: true }} 
                                        name="status" variant="outlined" select required
                                        fullWidth id="status" label="Issuer status"
                                        value={ issuerState.status && issuerState.status }
                                        onChange={(e) => handleInputChange('status', e.target.value)}
                                    >
                                        {["ACTIVE", "INACTIVE"].map((val, index) => {
                                        return <MenuItem key={index} value={val} className={classes.menu}>
                                            {val}
                                        </MenuItem>
                                        })}
                                    </TextField>
                                </Grid>
                            </>
                        }

                        <Grid item xs={12} sm={6}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                required
                                fullWidth 
                                variant="outlined" 
                                name="country" 
                                id="country" 
                                label="Country" 
                                select={currentForm !== 'create' ? false : true}
                                disabled={currentForm !== 'create' ? true : false}
                                value={currentForm === 'view' ? 
                                    issuerDetails !== null && issuerDetails.countryName && 
                                    issuerDetails.countryName : issuerState.countryName ? 
                                    issuerState.countryName : ""
                                } 
                                onBlur={(e) => handleOnBlur("countryErr", e.target.value)}
                                onChange={e => countryArr.length > 0 && handleChange("country", e.target.value)}
                            >
                                {countryArr && countryArr.length === 0 && 
                                <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                                {countryArr.map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                            })}
                            </TextField>
                            {valObj.countryErr ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                            </p>
                            :null}
                        </Grid>
                        {(currentForm !== "create" || (currentForm === "create" && issuerState.isPLManaged === false))  && 
                            <Grid item xs={12} sm={6}>
                                <TextField 
                                    InputLabelProps={{ shrink: true }} 
                                    required
                                    fullWidth 
                                    variant="outlined" 
                                    name="mciId" 
                                    id="mciId" 
                                    label={"Issuer Id"}
                                    value={currentForm === 'view' ? 
                                        issuerDetails !== null && issuerDetails.mciId && 
                                        issuerDetails.mciId : issuerState.mciId ? 
                                        issuerState.mciId : ""
                                    } 
                                    disabled={currentForm === 'create' ? false : true}
                                    onBlur={(e) => handleOnBlur("mciIdErr", e.target.value)}
                                    onChange={e => e.target.value.length <= 11 && handleChange("mciId", e.target.value)}
                                />
                                {issuerState && issuerState.mciId && issuerState.mciId !== "" && 
                                allZeroRegex.test(issuerState.mciId) ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! Only Zeros not Allowed."}</span>
                                </p> : 
                                currentForm === "create" && issuerState && issuerState.mciId && issuerState.mciId !== "" && 
                                !numbRegex.test(issuerState.mciId) ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                                </p> : valObj.mciIdErr ? 
                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                    </p>
                                    : null
                                }
                            </Grid>
                        }
                        <Grid item xs={12} sm={6}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                required
                                fullWidth 
                                variant="outlined" 
                                name="name" 
                                id="name" 
                                label={"Issuer Name"}
                                value={currentForm === 'view' ? 
                                    issuerDetails !== null && issuerDetails.name && 
                                    issuerDetails.name : issuerState.name ? 
                                    issuerState.name : ""
                                } 
                                disabled={currentForm === 'view' ?  true : false}
                                onBlur={(e) => handleOnBlur("nameErr", e.target.value)}
                                onChange={e => e.target.value.length <= 100 && handleChange("name", e.target.value)}
                            />
                            {issuerState && issuerState.name && issuerState.name !== "" && 
                            !nameRegExp.test(issuerState.name) ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                            </p> : valObj.nameErr ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                </p>
                                :null
                            }
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField 
                                required fullWidth label={"Display Name"}
                                variant="outlined" name="displayName" id="displayName" 
                                InputLabelProps={{ shrink: true }} disabled={currentForm === 'view'}
                                value={currentForm === 'view' ? 
                                    issuerDetails !== null && issuerDetails.displayName && 
                                    issuerDetails.displayName : issuerState.displayName ? 
                                    issuerState.displayName : ""
                                } 
                                onBlur={(e) => handleOnBlur("displayNameErr", e.target.value)}
                                onChange={e => e.target.value.length <= 100 && handleChange("displayName", e.target.value)}
                            />
                            {issuerState && issuerState.displayName && issuerState.displayName !== "" && 
                            !nameRegExp.test(issuerState.displayName) ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                            </p> : valObj.displayNameErr ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                </p>
                                :null
                            }
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                fullWidth variant="outlined" select={currentForm !== 'create' ?  false : true}
                                name="interestType" id="interestType" required
                                label={"Interest Type "}
                                value={currentForm === 'view' ? 
                                    issuerDetails !== null && issuerDetails.interestType && interTypeObj.includes(issuerDetails.interestType.toString()) &&
                                    interestTypes[issuerDetails.interestType] 
                                    : issuerState.interestType && interTypeObj.includes(issuerState.interestType.toString()) ? 
                                      interestTypes[issuerState.interestType] 
                                      : ""
                                } 
                                disabled={currentForm !== 'create' ?  true : false}
                                onChange={e => handleChange("interestType", e.target.value)}
                                onBlur={(e) => handleOnBlur("interestTypeErr", e.target.value)}
                            >
                                {interTypeArr.map((val, index) => {
                                    return <MenuItem key={index} value={val} className={classes.menu}>
                                        {val}
                                    </MenuItem>
                                })}
                            </TextField>
                            {valObj.interestTypeErr ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                            </p>
                            :null}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography>
                                <Checkbox
                                    id="isPLManaged"
                                    color="primary"
                                    disabled={currentForm !== "create" ? true : false}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        checked={ currentForm === 'view' ? 
                                            issuerDetails !== null && issuerDetails.isPLManaged
                                            : issuerState.isPLManaged !== null ? issuerState.isPLManaged : false}
                                    onChange={(e) => handleInputChange('isPLManaged', e.target.checked)}
                                />
                                {"isPLManaged"}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} >
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                // required
                                fullWidth 
                                variant="outlined" 
                                name="description" 
                                id="description" 
                                label={"Description"}
                                value={currentForm === 'view' ? 
                                    issuerDetails !== null && issuerDetails.description && 
                                    issuerDetails.description : issuerState.description ? 
                                    issuerState.description : ""
                                } 
                                disabled={currentForm === 'view' ?  true : false}
                                // onBlur={(e) => handleOnBlur("descriptionErr", e.target.value)}
                                onChange={e => e.target.value.length <= 50 && handleInputChange("description", e.target.value)}
                            />
                            {issuerState && issuerState.description && issuerState.description !== "" && 
                            !descrRegex.test(issuerState.description) ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                            </p> : null}
                        </Grid>
                        
                        <Grid item xs={12}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} inputProps={{maxLength: 255}}
                                name="termAndConditions" variant="outlined" multiline rows={4}
                                disabled={currentForm === 'view'}
                                fullWidth id="termAndConditions" label="Terms & Conditions"
                                onChange={e => e.target.value.length <= 255 && handleInputChange('termAndConditions', e.target.value)}
                                value={currentForm === 'view' ? 
                                    issuerDetails !== null && issuerDetails.termAndConditions && 
                                    issuerDetails.termAndConditions : issuerState.termAndConditions ? 
                                    issuerState.termAndConditions : ""
                                }
                            />
                            {issuerState && issuerState.termAndConditions && issuerState.termAndConditions !== "" && 
                            tNcRegex.test(issuerState.termAndConditions) ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                            </p> : null}
                        </Grid>
                    </Grid>
                
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Button fullWidth variant="contained"
                                color="primary" className={"action_login_button"}
                                disabled={createDisableToken}
                                onClick={() => currentForm !== "view" ? handleIssuerCreateUpdate() : onChangeView("list")}
                                >
                                {currentForm === "edit" ? "Modify" : currentForm === "view" ? "Close" : "Create"}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>

            {currentForm === "edit" && 
            <Paper className={classes.paper}>
                <Grid container>
                    <Grid item xs={12} >
                        <Typography variant="h4" className={classes.titleText} component="h1">Image Section</Typography>
                    </Grid>
                    
                    <Grid item xs={12} sm={4}>
                        <div className="page">
                            <div className="container">

                                <h1 className="heading">ISSUER IMAGE</h1>
                                {!(imageData && imageData.baseURL && imageData.baseURL !== "") &&
                                    <h6 className="heading">{`( Image size limit 2mb. 169 H X 155 W Pixel limit)`}</h6>}
                                {imageData && imageData.baseURL && imageData.baseURL !== "" &&
                                    <div className={imageData && imageData.resourceId && imageData.resourceId !== "" ? "img-holder-cross" : "img-holder"}>
                                        {docUploadLoader === "ISSUER_IMAGE"  ?
                                            <CircularProgress className="circleIcon"/>
                                        : null}
                                        <img src={imageData.baseURL} alt="" id="img" className="img" ></img>
                                        {imageData && imageData.resourceId && imageData.resourceId !== "" && <div className="close-icon">
                                            <IconButton size="small" aria-label="close" color="inherit" onClick={() => handleUploadImage("DELETE_ACTION")}>
                                                <CloseIcon fontSize="small" />
                                            </IconButton>
                                        </div>}
                                </div>}
                                <input  type="file" name="image-upload" id="input" accept="image/*" onChange={(e) => imageHandler(e, "SCREEN_TYPE_PRODUCT_LIST")}/>
                                {imageData && imageData.baseURL && imageData.baseURL !== "" ? null :
                                <div className="label" >
                                <label htmlFor="input" className="contained_label">
                                    <Button variant="contained" className="upload_button" color="primary" component="span">
                                    Upload
                                    </Button>
                                </label>
                                </div>
                                }
                                {imageData && imageData.actionKey && imageData.actionKey === "UPLOAD" &&
                                <div className="upload-action">
                                <Button color="primary" className="yes" autoFocus
                                    style={{margin: "0px 2px"}}
                                    onClick={() => handleUploadImage("UPLOAD")} 
                                >
                                    Confirm
                                </Button>
                                <Button color="primary" className="no"
                                    style={{margin: "0px 2px"}}
                                    onClick={() => handleUploadImage("CANCEL")} 
                                >
                                    Cancel
                                </Button>
                                </div>}
                            </div>
                        </div>  
                    </Grid> 
                </Grid>
            </Paper>
            }

            {currentForm === "view" && imageData && imageData.baseURL && imageData.baseURL !== "" &&
            <Paper className={classes.paper}>
                <Grid container>
                    <Grid item xs={12} >
                        <Typography variant="h4" className={classes.titleText} component="h1">Image Section</Typography>
                    </Grid>
                    
                    <Grid item xs={12} sm={4}>
                        <div className="page">
                            <div className="container">
                                <h1 className="heading">ISSUER IMAGE</h1>
                                <div className="img-holder">
                                <img src={imageData.baseURL} alt="" id="img" className="img" ></img>
                                </div>
                            </div>
                        </div>  
                    </Grid>
                </Grid> 
            </Paper>
            }
        
        </div>
    )
}

CreateEditViewIssuer = reduxForm({
    form: 'CreateEditViewIssuer',
    // validate: validate,
    enableReinitialize: true
})(CreateEditViewIssuer);

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({ 
            fetchInterestTypes
        }, dispatch)
  }
}

function mapStateToProps(state) {
    return {
        docUploadLoader: state.IssuerReducer.docUploadLoader,
        interestTypes: state.IssuerReducer.interestTypes,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateEditViewIssuer);
