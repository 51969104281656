import { actionCreator, BASE_URL } from "../../shared/utility";
import { AuthenticationActions } from "../consts/index";
import jwt_decode from "jwt-decode";

export function handleUserAuthentication(payload) {
    return(dispatch) => {
        dispatch(actionCreator(AuthenticationActions.handleUserLogin.REQUEST))
        fetch(`${BASE_URL}/auth/authentication/web/login`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
              'Content-Type':'application/json'
            }
        })
          .then(response => response.json())
          .then(async function (res) {
            if(res.status !== true) {
                dispatch(actionCreator(AuthenticationActions.handleUserLogin.FAILURE, {...res, authtoken: null}));
            } else {
              const decoded_token = jwt_decode(res.authtoken);
              await localStorage.setItem('accessToken', JSON.stringify(res.authtoken));
              decoded_token.permissions && await localStorage.setItem('permissions', JSON.stringify(decoded_token.permissions));
              decoded_token.sub && localStorage.setItem('userid', JSON.stringify(decoded_token.sub));
              res.firstTimeLogin !== undefined && await localStorage.setItem('firstTimeLogin', res.firstTimeLogin);
              await dispatch(actionCreator(AuthenticationActions.handleUserLogin.SUCCESS, res));
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    }
}

export function handleUserLogout(type) {
  let token = localStorage.getItem('accessToken')
  return(dispatch) => {
      fetch(`${BASE_URL}/auth/api/data/web/logout`, {
          method: 'POST',
          body: JSON.stringify({}),
          headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer ${JSON.parse(token)}`
          }
      })
        .then(response => response.json())
        .then(async function (res) {
          if(res.st) {
            await localStorage.clear()
            if(type === 'timeout') {
              await localStorage.setItem("tokenExpired", "timeout")
            }
            await window.location.reload()
          } else {
            if(res.responseCode && res.responseCode === "401") {
              localStorage.setItem("tokenExpired", "true")
              localStorage.removeItem('accessToken')
              window.location.reload()
            } else {
              localStorage.clear()
              window.location.reload()
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
  }
}

export function RefreshTokenFetch(token) {
  return(dispatch) => {
    const payload = { "authToken":   JSON.parse(token) }
      dispatch(actionCreator(AuthenticationActions.handleUserLogin.REQUEST))
      fetch(`${BASE_URL}/auth/api/data/web/refreshToken`, {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer ${JSON.parse(token)}`
          }
      })
        .then(response => response.json())
        .then(async function (res) {
          if(res.status !== true) {
            if(res.responseCode && res.responseCode === "401") {
              await dispatch(handleUserLogout())
              await localStorage.setItem("tokenExpired", "true")
              await localStorage.removeItem('accessToken')
              await window.location.reload()
            }  else {
              await dispatch(handleUserLogout())
              await dispatch(actionCreator(AuthenticationActions.handleUserLogin.FAILURE, {...res, authtoken: null}));
            }
          } else {
            const decoded_token = jwt_decode(res.authtoken);
            localStorage.setItem('accessToken', JSON.stringify(res.authtoken));
            decoded_token.permissions && localStorage.setItem('permissions', JSON.stringify(decoded_token.permissions));
            await dispatch(actionCreator(AuthenticationActions.handleUserLogin.SUCCESS, res));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
  }
}

export function clearLoginRes(payload) {
  return(dispatch) => {
    dispatch(actionCreator(AuthenticationActions.handleUserLogin.SUCCESS, payload));
  }
}

export function validateUserAndSendEmail(payload) {
  return(dispatch) => {
      dispatch(actionCreator(AuthenticationActions.handleValidateUser.REQUEST))
      fetch(`${BASE_URL}/auth/authentication/web/validateUserNameAndSendEmail`, {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type':'application/json'
          }
      })
      .then(res => res.json())
      .then(response => {
          if(!response.st) {
              if(response.responseCode && response.responseCode === "401") {
                localStorage.setItem("tokenExpired", "true")
                localStorage.removeItem('accessToken')
                  window.location.reload()
              }  else {
                  dispatch(actionCreator(AuthenticationActions.handleValidateUser.FAILURE, response))
              }
          } else {
              dispatch(actionCreator(AuthenticationActions.handleValidateUser.SUCCESS, response))
          }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}

export function clearValidateUserRes(payload) {
  return(dispatch) => {
    dispatch(actionCreator(AuthenticationActions.handleValidateUser.FAILURE, payload));
  }
}

export function fetchUserNameById(payload) {
  return(dispatch) => {
    dispatch(actionCreator(AuthenticationActions.handleFetchUserNameById.REQUEST))
    fetch(`${BASE_URL}/auth/authentication/web/getUserByUserId`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type':'application/json'
        }
    })
    .then(res => res.json())
    .then(response => {
        if(!response.st) {
            if(response.responseCode && response.responseCode === "401") {
              localStorage.setItem("tokenExpired", "true")
              localStorage.removeItem('accessToken')
                window.location.reload()
            }  else {
                dispatch(actionCreator(AuthenticationActions.handleFetchUserNameById.FAILURE, response))
            }
        } else {
            dispatch(actionCreator(AuthenticationActions.handleFetchUserNameById.SUCCESS, response))
        }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
}

export function validateForgotPasswordToken(payload) {
  return(dispatch) => {
    dispatch(actionCreator(AuthenticationActions.handleValForgotPassToken.REQUEST))
    fetch(`${BASE_URL}/auth/authentication/web/validateForgotPasswordToken`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type':'application/json'
        }
    })
    .then(res => res.json())
    .then(response => {
        if(!response.st) {
            if(response.responseCode && response.responseCode === "401") {
              localStorage.setItem("tokenExpired", "true")
              localStorage.removeItem('accessToken')
              window.location.reload()
            }  else {
                dispatch(actionCreator(AuthenticationActions.handleValForgotPassToken.FAILURE, response))
            }
        } else {
            dispatch(actionCreator(AuthenticationActions.handleValForgotPassToken.SUCCESS, response))
        }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
}

export function resetPasswordForForgotPassword(payload) {
  return(dispatch) => {
      dispatch(actionCreator(AuthenticationActions.handleResetPasswordForForgotPassword.REQUEST))
      fetch(`${BASE_URL}/auth/authentication/web/resetPassword`, {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type':'application/json'
          }
      })
      .then(res => res.json())
      .then(response => {
          if(!response.st) {
              if(response.responseCode && response.responseCode === "401") {
                localStorage.setItem("tokenExpired", "true")
                localStorage.removeItem('accessToken')
                  window.location.reload()
              }  else {
                  dispatch(actionCreator(AuthenticationActions.handleResetPasswordForForgotPassword.FAILURE, response))
              }
          } else {
              dispatch(actionCreator(AuthenticationActions.handleResetPasswordForForgotPassword.SUCCESS, response))
          }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}

export function clearResetPasswordRes(payload) {
  return(dispatch) => {
    dispatch(actionCreator(AuthenticationActions.handleResetPasswordForForgotPassword.FAILURE, payload));
    dispatch(actionCreator(AuthenticationActions.handleValForgotPassToken.FAILURE, null))
  }
}

export function fetchCaptchaForLogin(payload) {
  return(dispatch) => {
      dispatch(actionCreator(AuthenticationActions.fetchCaptchaForLogin.REQUEST))
      fetch(`${BASE_URL}/auth/authentication/web/captcha`, {
          method: 'GET',
          headers: {
            'Content-Type':'application/json'
          }
      })
      .then(res => res.json())
      .then(response => {
          if(!response.st) {
              if(response.responseCode && response.responseCode === "401") {
                localStorage.setItem("tokenExpired", "true")
                localStorage.removeItem('accessToken')
                window.location.reload()
              }  else {
                  dispatch(actionCreator(AuthenticationActions.fetchCaptchaForLogin.FAILURE, response))
              }
          } else {
              dispatch(actionCreator(AuthenticationActions.fetchCaptchaForLogin.SUCCESS, response))
          }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}

export function unblockUserRequest(payload) {
  return(dispatch) => {
      dispatch(actionCreator(AuthenticationActions.unblockUserRequest.REQUEST))
      fetch(`${BASE_URL}/auth/authentication/web/user/unblock?username=${payload.username}`, {
          method: 'GET',
          headers: {
            'Content-Type':'application/json'
          }
      })
      .then(res => res.json())
      .then(response => {
          if(!response.st) {
              if(response.responseCode && response.responseCode === "401") {
                localStorage.setItem("tokenExpired", "true")
                localStorage.removeItem('accessToken')
                window.location.reload()
              }  else {
                  dispatch(actionCreator(AuthenticationActions.unblockUserRequest.FAILURE, response))
              }
          } else {
              dispatch(actionCreator(AuthenticationActions.unblockUserRequest.SUCCESS, response))
          }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}