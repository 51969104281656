import React from 'react';
import {
    Grid, TextField, MenuItem, Button
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import MappingTable from '../MappingTable/index';
import {SpecialCharRegex} from "../../../shared/utility";
import { NO_SPECIAL_CHARACTERS } from "shared/errorMessages";

const headCells = [
    { id: 'pspName', type: 'text', numeric: false, disablePadding: false, label: 'PSP Name' },
    { id: 'merchantUniqueId', type: 'text', numeric: false, disablePadding: false, label: 'Merchant External Id' },
    { id: 'pspIntegrationMode', type: 'text', numeric: false, disablePadding: false, label: 'PSP Integration Mode' },
    { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];

export default function PSPMapping(props) {
    
    const {
        classes, currentForm, pspArr, pspIdArr, currPSPObj, handlePSPChange, pspIntegrationModeList, 
        handleAddNewPSP, valObj, mappedPSPList, handleDeletePSP
    } = props;

    const disableAddButton = currentForm !== "view" && currPSPObj && currPSPObj.pspName !== "" && currPSPObj.pspIntegrationMode !== "" &&
        (currPSPObj.merchantUniqueId !== "" && currPSPObj.merchantUniqueId !== null && SpecialCharRegex.test(currPSPObj.merchantUniqueId))

    const mappedPSPArr = mappedPSPList && mappedPSPList !== null ? 
        mappedPSPList.map(obj => {
        let pspObj = {
            ...obj,
            pspName: pspIdArr.indexOf(obj.pspObjId) !== -1 ? pspArr[pspIdArr.indexOf(obj.pspObjId)] : ""            
        }
        return pspObj
        }) : []

    return (
        <>
        <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} sm={4}>
                <TextField 
                    InputLabelProps={{ shrink: true }} 
                    name="pspName" variant="outlined" select={currentForm !== 'view'} required
                    fullWidth id="pspName" label="PSP" 
                    disabled={currentForm === 'view'}
                    value={currPSPObj.pspName}
                    onChange={(e) => pspArr.length > 0 && handlePSPChange(e)}
                >
                    {pspArr && pspArr.length === 0 && 
                    <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                    {pspArr && pspArr.map((val, index) => {
                    return <MenuItem key={index} value={val} className={classes.menu}>
                        {val}
                    </MenuItem>
                    })}
                </TextField>
                {valObj.pspErr ? 
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon /> {"Selected PSP has been mapped already!"}</span>
                    </p>
                :null}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                InputLabelProps={{ shrink: true }} required
                name="merchantUniqueId" id="merchantUniqueId" fullWidth
                label="Merchant External Id" variant="outlined"
                disabled={currentForm === 'view'}
                value={currPSPObj.merchantUniqueId}
                onChange={(e) => e.target.value.length <= 20 && handlePSPChange(e)}
              />
              {currPSPObj && currPSPObj.merchantUniqueId && currPSPObj.merchantUniqueId !== "" && 
                !SpecialCharRegex.test(currPSPObj.merchantUniqueId) ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                </p>
              :null}
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField 
                    InputLabelProps={{ shrink: true }} 
                    name="pspIntegrationMode" variant="outlined" select={currentForm !== 'view'} required
                    fullWidth id="pspIntegrationMode" label="PSP Integration Mode" disabled={currentForm === 'view'}
                    value={currPSPObj.pspIntegrationMode}
                    onChange={(e) => handlePSPChange(e)}
                >
                    {pspIntegrationModeList && pspIntegrationModeList.length === 0 && 
                        <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                        {pspIntegrationModeList && pspIntegrationModeList.map((val, index) => {
                        return <MenuItem key={index} value={val} className={classes.menu}>
                            {val}
                        </MenuItem>
                    })}
                </TextField>
            </Grid>
            <Grid item xs={12} sm={10}></Grid>
            <Grid item xs={12} sm={2}>
              <Button fullWidth variant="contained" justifyContent="flex-end"
                color="primary" className={"action_login_button"}
                disabled={!disableAddButton}
                onClick={() => handleAddNewPSP()}
              >
                ADD PSP
              </Button>
            </Grid>
        </Grid>
        <Grid container className={classes.container}>
            <MappingTable
            sortOrder="asc" 
            sortOrderBy="pspName" 
            handleDeletePSP={handleDeletePSP} 
            disableDelete={currentForm === "view"} 
            rows={mappedPSPArr}
            loading={false} 
            activeTab={1} 
            headCells={headCells}
            />
        </Grid>
        </>
    )
}