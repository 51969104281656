import { UserActions } from "../consts/index";

const initialState = {
    userLoader: false,
    userList: null,
    createUserRes: null,
    roleList: null,
    permissionList: null,
    permissionListForView: null,
    createRoleRes: null,
    changePasswordRes: null,
}

export default (state = initialState, {type, payload}) => {
    switch(type) {
        case UserActions.fetchUsersDetails.REQUEST:
            return {
                ...state,
                userLoader: true
            }
        case UserActions.fetchUsersDetails.SUCCESS:
            return {
                ...state,
                userLoader: false,
                userList: payload
            }
        case UserActions.fetchUsersDetails.FAILURE:
            return {
                ...state,
                userLoader: false,
                userList: null,
            }
        case UserActions.createUserDetails.REQUEST:
            return {
                ...state,
                userLoader: true
            }
        case UserActions.createUserDetails.SUCCESS:
            return {
                ...state,
                userLoader: false,
                createUserRes: payload
            }
        case UserActions.createUserDetails.FAILURE:
            return {
                ...state,
                userLoader: false,
                createUserRes: payload,
            }
        case UserActions.fetchRoleDetails.REQUEST:
            return {
                ...state,
                userLoader: true
            }
        case UserActions.fetchRoleDetails.SUCCESS:
            return {
                ...state,
                userLoader: false,
                roleList: payload
            }
        case UserActions.fetchRoleDetails.FAILURE:
            return {
                ...state,
                userLoader: false,
                roleList: null,
            }
        case UserActions.fetchPermissionDetailsByRole.REQUEST:
            return {
                ...state,
                userLoader: true
            }
        case UserActions.fetchPermissionDetailsByRole.SUCCESS:
            return {
                ...state,
                userLoader: false,
                permissionList: payload
            }
        case UserActions.fetchPermissionDetailsByRole.FAILURE:
            return {
                ...state,
                userLoader: false,
                permissionList: null,
            }
        case UserActions.fetchPermissionDetailsForView.REQUEST:
            return {
                ...state,
                userLoader: true
            }
        case UserActions.fetchPermissionDetailsForView.SUCCESS:
            return {
                ...state,
                userLoader: false,
                permissionListForView: payload
            }
        case UserActions.fetchPermissionDetailsForView.FAILURE:
            return {
                ...state,
                userLoader: false,
                permissionListForView: null,
            }
        case UserActions.createRoleDetails.REQUEST:
            return {
                ...state,
                userLoader: true
            }
        case UserActions.createRoleDetails.SUCCESS:
            return {
                ...state,
                userLoader: false,
                createRoleRes: payload
            }
        case UserActions.createRoleDetails.FAILURE:
            return {
                ...state,
                userLoader: false,
                createRoleRes: payload,
            }
        case UserActions.resetUserPassword.REQUEST:
            return {
                ...state,
                userLoader: true
            }
        case UserActions.resetUserPassword.SUCCESS:
            return {
                ...state,
                userLoader: false,
                changePasswordRes: payload
            }
        case UserActions.resetUserPassword.FAILURE:
            return {
                ...state,
                userLoader: false,
                changePasswordRes: payload,
            }

        default:
            return state;
    }
}