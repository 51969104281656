import React, { useEffect, useState } from 'react'
import {
    Grid, Button, CircularProgress, Snackbar, IconButton
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';

import {FilterComponent, MappingTable, SelectedGroups} from '../ProductGroupComp/index'

const headCells = [
    { id: 'name', type: 'text', numeric: false, disablePadding: false, label: 'Product Group Name' },
    { id: 'countryName', type: 'text', numeric: false, disablePadding: false, label: 'Country' },
    { id: 'brandName', type: 'text', numeric: false, disablePadding: false, label: 'Brand Name' },
    { id: 'createdBy', type: 'text', numeric: false, disablePadding: false, label: 'Created By' },
    { id: 'createdOn', type: 'text', numeric: false, disablePadding: false, label: 'Created On' },
    { id: 'status', type: 'text', numeric: false, disablePadding: false, label: 'Status' },
];

function ProductGroupStepper(props) {
    const {
        classes, currentForm, handleNext, brandListForScheme, handleStepper5InputChange, disableToken, 
        nextButtonFlag, handleStepback, onChangeView, createLoad, selectedBrand, productGroupList, 
        productGroupLoader, countryDetails, selectedProdGroups, selectedProdGroupIds
    } = props;
    
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    });
    
    const DisableFlag = disableToken ? disableToken : false

    const [groupName, setGroupName] = useState("")
    const [oldOem, setOldOem] = useState("")
    const [openSnackbar, SetOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackBarMsg] = useState(null);
    const [showTable, setShowTable] = useState(false)
    const allowEdit = currentForm === "edit";

    let countryObj = []
    const countryIdArray = countryDetails && countryDetails !== null ? 
        countryDetails.countryList.map(obj => {
            countryObj.push(obj)
            return obj.isoCountryCodeNum
        }) : []

    let oemName = null
    let brandObj = []
    let brandIdArr = []
    const brandArray = (brandListForScheme && brandListForScheme !== null) ? 
        brandListForScheme.brand.map(obj => {
            if(oemName === null && selectedBrand !== null && obj.id !== null && selectedBrand === obj.id) {
                oemName = obj.name
            }
            brandObj.push(obj)
            brandIdArr.push(obj.id)
            return obj.name
        }) : []

    const groupArr = productGroupList && productGroupList !== null ? 
        productGroupList.map(obj => {
    
            let groupObj = {
              ...obj,
              countryName: obj.isoCountryCodeNum && countryIdArray.indexOf(obj.isoCountryCodeNum) !== -1 ? countryObj[countryIdArray.indexOf(obj.isoCountryCodeNum)].name : "",
              brandName: obj.brandId && brandIdArr.indexOf(obj.brandId) !== -1 ? brandObj[brandIdArr.indexOf(obj.brandId)].name : ""
            }
            return groupObj
        }) : []

    useEffect(() => {
        if((currentForm === "view" || currentForm === "edit") && selectedProdGroupIds.length > 0 && groupArr) {
            handleStepper5InputChange("setGroupForViewEdit", groupArr)
        }
    }, [selectedProdGroupIds])

    const onChangeFilter = (type, value) => {
        
        if(type === "oemName") {
            if(oemName !== null && oemName !== "" && (oemName !== value)) {
                setOldOem(oemName)
                SetOpenSnackbar(true)
                setSnackBarMsg("Your selected Groups will be lost after applying filter as your are changing the Brand!")
            }
            handleStepper5InputChange(type, brandObj[brandArray.indexOf(value)]);
        } else if(type === "groupName") {
            setGroupName(value)
        }

    }

    const applyFilter = (key) => {

        if(key === "apply") {

            if(oldOem !== "" && oemName !== null && ((oemName !== oldOem))) {
                handleStepper5InputChange("clearSelectedGroups", [])
                setOldOem("")
                setGroupName("")
            }
            handleStepper5InputChange(
                key, 
                {
                    pageNo: 1, pageSize: 1000, sortBy: "name", sortDirection: "ASC", 
                    brandId: selectedBrand, name: groupName, status: "ACTIVE"
                }
            )
            setShowTable(true)
        }
    }

    const handleCheckBox = (e, row) => {
        handleStepper5InputChange("checkbox", row)
    }

    const handleSelectAll = (e) => {
        handleStepper5InputChange("selectAll", groupArr)
    };

    const handleRemoveGroup = (id) => {
        handleStepper5InputChange("remove", id)
    }
    
    const handleSnackBarAction = () => {
        SetOpenSnackbar(false)
        setSnackBarMsg(null)
    }

    return (
        <React.Fragment>
            <Grid constainer className="step_action">
                <FilterComponent
                    classes={classes}
                    currentForm={currentForm}
                    oemName={oemName}
                    groupName={groupName}
                    brandArray={brandArray}
                    onChangeFilter={onChangeFilter}
                    applyFilter={applyFilter}
                />
            </Grid>
            {(showTable || (groupArr && groupArr.length > 0)) &&
            <Grid constainer className="step_action">
                <MappingTable
                    rows={groupArr}
                    headCells={headCells}
                    loading={productGroupLoader}
                    selectedProdGroups={selectedProdGroups}
                    handleCheckBox={handleCheckBox}
                    handleSelectAll={handleSelectAll}
                    currentForm={currentForm}
                />
            </Grid>
            }
            {selectedProdGroups && selectedProdGroups.length > 0 &&
            <Grid constainer className="step_action">
                <SelectedGroups
                    selectedProdGroups={selectedProdGroups}
                    handleRemoveGroup={handleRemoveGroup}
                    currentForm={currentForm}
                />
            </Grid>}
            <Grid container className="step_action">
                <Grid item xs={8} justify="flex-start" className={classes.addRange}>
                </Grid>
                <Grid item xs={4} style={{ display: "flex", justifyContent: "space-evenly" }} justify="flex-end">
                    <Button 
                        onClick={handleStepback} 
                        disabled={createLoad}
                        className={`action_login_button`}
                    >
                        Back
                    </Button>
                    <Button 
                        onClick={() => !DisableFlag ? handleNext() : onChangeView("list")} 
                        disabled={!nextButtonFlag || createLoad}
                        className={`action_login_button ${!nextButtonFlag ? "disabled_login_button" : ""}`}
                    >
                        {!DisableFlag ? allowEdit ? "Modify" : "Create" : "Close"}
                        {createLoad ? <CircularProgress color="secondary" style={{
                            "width": "25px", "height": "25px", "marginLeft": "6px", "color": "#fff"}}
                        /> : null}
                    </Button>
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={() => handleSnackBarAction()}
                action={
                <React.Fragment>
                <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
                    <CloseIcon fontSize="small" />
                </IconButton>
                </React.Fragment>
                }
            >
                <Alert onClose={() => handleSnackBarAction()} severity={"info"}>
                {snackbarMsg}
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}

export default ProductGroupStepper;