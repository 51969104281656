import React from 'react'
import Typography from '@mui/material/Typography'
// import Link from '@mui/material/Link'
import { tss } from "tss-react";
import theme from '../../../_theme'
import PL_Logo from '../../../Assets/Pine-logo-transaperent.png'
import moment from 'moment';

const Footer = () => {
  const { classes } = useStyles()
  
  const getCurrentYear = moment().year()

  return (
    <footer className={classes.footer}>
      <Typography variant="body2" color="textSecondary" className={classes.copyright} align="center">
        <span className="copyright text-muted text-center text-sm-left d-block d-sm-inline-block">
          Copyright © {getCurrentYear}, PayLater. All rights reserved.</span>
      </Typography>
      <Typography variant="body2" color="textSecondary" align="right">
        {/* <Link color="primary" href=""> */}
        <img size={30} src={PL_Logo} alt="PayLater" className={classes.logo} />
        {/* </Link> */}
      </Typography>
    </footer>
  )
}

const useStyles = tss.create({
  logo: {
    color: theme.palette.secondary.main,
    zIndex: 10,
  },
  copyright: {
    display: "flex",
    alignItems: "center", justifyContent: "center", width: "90%",
  },
  footer: {
    display: 'flex',
    background: '#fff',
    padding: '0.5rem 1rem',
    justifyContent: 'space-between',
    borderTop: "solid 1px #eee"
  },
});

export default Footer
