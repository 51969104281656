import { ProductCodeMappingActions } from "../consts/index"

const initialState = {
    loader: false,
    downloadProductFileResp: null,
    uploadProductFileResp: null,

    mappingFileList: null,
    downloadProductMappingFileByStatusResp: null,
    productMappingList: null,
    deleteProductMappingResp: null,
}

export default (state = initialState, {type, payload}) => {
    switch(type) {
        case ProductCodeMappingActions.downloadProductFileToCreateMapping.REQUEST:
            return {
                ...state,
                loader: true,
                downloadProductFileResp: null
            }
        case ProductCodeMappingActions.downloadProductFileToCreateMapping.SUCCESS:
            return {
                ...state,
                loader: false,
                downloadProductFileResp: payload
            }
        case ProductCodeMappingActions.downloadProductFileToCreateMapping.FAILURE:
            return {
                ...state,
                loader: false,
                downloadProductFileResp: payload
            }

        case ProductCodeMappingActions.downloadProductFileToUpdateMapping.REQUEST:
            return {
                ...state,
                loader: true,
                downloadProductFileResp: null
            }
        case ProductCodeMappingActions.downloadProductFileToUpdateMapping.SUCCESS:
            return {
                ...state,
                loader: false,
                downloadProductFileResp: payload
            }
        case ProductCodeMappingActions.downloadProductFileToUpdateMapping.FAILURE:
            return {
                ...state,
                loader: false,
                downloadProductFileResp: payload
            }

        case ProductCodeMappingActions.uploadProductFileCreateMode.REQUEST:
            return {
                ...state,
                loader: true,
                uploadProductFileResp: null
            }
        case ProductCodeMappingActions.uploadProductFileCreateMode.SUCCESS:
            return {
                ...state,
                loader: false,
                uploadProductFileResp: payload
            }
        case ProductCodeMappingActions.uploadProductFileCreateMode.FAILURE:
            return {
                ...state,
                loader: false,
                uploadProductFileResp: payload
            }

        case ProductCodeMappingActions.uploadProductFileUpdateMode.REQUEST:
            return {
                ...state,
                loader: true,
                uploadProductFileResp: null
            }
        case ProductCodeMappingActions.uploadProductFileUpdateMode.SUCCESS:
            return {
                ...state,
                loader: false,
                uploadProductFileResp: payload
            }
        case ProductCodeMappingActions.uploadProductFileUpdateMode.FAILURE:
            return {
                ...state,
                loader: false,
                uploadProductFileResp: payload
            }

        case ProductCodeMappingActions.fetchProductMappingFilesDetail.REQUEST:
            return {
                ...state,
                loader: true,
                mappingFileList: null
            }
        case ProductCodeMappingActions.fetchProductMappingFilesDetail.SUCCESS:
            return {
                ...state,
                loader: false,
                mappingFileList: payload
            }
        case ProductCodeMappingActions.fetchProductMappingFilesDetail.FAILURE:
            return {
                ...state,
                loader: false,
                mappingFileList: payload
            }

        case ProductCodeMappingActions.downloadProductMappingFileByStatus.REQUEST:
            return {
                ...state,
                loader: true,
                downloadProductMappingFileByStatusResp: null
            }
        case ProductCodeMappingActions.downloadProductMappingFileByStatus.SUCCESS:
            return {
                ...state,
                loader: false,
                downloadProductMappingFileByStatusResp: payload
            }
        case ProductCodeMappingActions.downloadProductMappingFileByStatus.FAILURE:
            return {
                ...state,
                loader: false,
                downloadProductMappingFileByStatusResp: payload
            }

        case ProductCodeMappingActions.fetchProductMappings.REQUEST:
            return {
                ...state,
                loader: true,
                productMappingList: null
            }
        case ProductCodeMappingActions.fetchProductMappings.SUCCESS:
            return {
                ...state,
                loader: false,
                productMappingList: payload
            }
        case ProductCodeMappingActions.fetchProductMappings.FAILURE:
            return {
                ...state,
                loader: false,
                productMappingList: payload
            }

        case ProductCodeMappingActions.deleteProductMappings.REQUEST:
            return {
                ...state,
                loader: true,
                deleteProductMappingResp: null
            }
        case ProductCodeMappingActions.deleteProductMappings.SUCCESS:
            return {
                ...state,
                loader: false,
                deleteProductMappingResp: payload
            }
        case ProductCodeMappingActions.deleteProductMappings.FAILURE:
            return {
                ...state,
                loader: false,
                deleteProductMappingResp: payload
            }
            
        default:
            return state
    }
}