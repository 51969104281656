import React from 'react'
import './style.css';
import {connect} from "react-redux";
import { bindActionCreators } from "redux";
import { ThemeProvider } from '@mui/material/styles'
import { IntlProvider } from 'react-intl'
import CssBaseline from '@mui/material/CssBaseline'
import theme from './_theme'
import AppRouter from './AppRouter'
import {RefreshTokenFetch, handleUserLogout} from "./auth/actions/index"
import CircularProgress from '@mui/material/CircularProgress';

let time;
let loginTime;
let LoggedInTime = 0;
let userconfirm;

const App = (props) => { 
  const {authResponse} = props;
  const [loader, setLoader] = React.useState(true)
  const [openDialog, setopenDialog] = React.useState(false)
  const [loginTimeState, setloginTimeState] = React.useState(0)
  let token = localStorage.getItem('accessToken')
  LoggedInTime = localStorage.getItem('loggedInTime')
  if(token && token !== null) {
    document.onmousemove = resetTimer;
    document.onkeydown = resetTimer;
  }

  React.useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 1000)
    setloginTimeState(0)
    localStorage.setItem('loggedInTime', 0)
  }, [])

  React.useEffect(() => {
    if(token && token !== null) {
      resetTimer();
      handleLoginTime();
    }
  }, [authResponse])

  function resetTimer() {
    clearTimeout(time);
    time = setTimeout(handleUserConfirmation, 570000)

    if(parseInt(LoggedInTime) >= 540000) {
      props.RefreshTokenFetch(token)
      localStorage.setItem('loggedInTime', 0)
      setloginTimeState(0)
    }
  }

  function handleUserConfirmation() {
    setopenDialog(true);
    userconfirm = setTimeout(() => {
      setopenDialog(false);
      handleLogOut()
    }, 30000)
  }
  
  React.useEffect(() => {
    if(token && token !== null) {
      handleLoginTime();
    }
  }, [loginTimeState])

  function handleLoginTime() {
    clearTimeout(loginTime)
    loginTime = setTimeout(() => {
      localStorage.setItem('loggedInTime', loginTimeState+30000)
      setloginTimeState(loginTimeState+30000)
    }, 30000)
  }

  async function handleLogOut() {
    await props.handleUserLogout('timeout')
    //moved all this statements to API success scenario
    // await localStorage.clear();
    // await localStorage.setItem("tokenExpired", "timeout")
    // await window.location.reload();
  }

  function confirmSessionReset() {
    clearTimeout(userconfirm)
    setopenDialog(false)
    props.RefreshTokenFetch(token)
    localStorage.setItem('loggedInTime', 0)
    setloginTimeState(0)
  }

  return (
    <div className="App">
      {loader ?
        <div className={"loading"}>
          <CircularProgress color="secondary" />
        </div>
        : null}
      <CssBaseline />
      <AppRouter confirmSessionReset={confirmSessionReset} openDialog={openDialog}/>
    </div>
  )
}


function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({ 
        RefreshTokenFetch, handleUserLogout
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
      authResponse: state.AuthReducer.authResponse
  }
}

const AppConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default () => (
  <IntlProvider locale={navigator.language}>
    <ThemeProvider theme={theme}>
      <AppConnect />
    </ThemeProvider>
  </IntlProvider>
)
