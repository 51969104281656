import React, { useState, useEffect } from 'react'
import {connect} from "react-redux";
import Grid from '@mui/material/Grid';
import { bindActionCreators } from "redux";
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Snackbar, Dialog, DialogActions,
    DialogTitle, DialogContentText, DialogContent, Button  } from '@mui/material'
import { tss } from "tss-react";
import theme from '../../_theme'

import TableComponent from "../table/index";
import BasePageToolbar from '../../_common/basePageToolbar';
import BasePageContainer from '../../_common/basePageContainer';
import CreateEditViewAcquirer from "./CEVAcquirer/index";
import {fetchCountryDetails} from "../../manageScheme/actions/index";
import {addRegex, nameRegExp, descrRegex} from "../../shared/utility";
import {
    fetchAcquirerDetails, createAcquirer,
    updateAcquirer, resetCreateAcqResp, 
} from "./actions/index";

const headCells = [
    { id: 'id', type: 'text', numeric: false, disablePadding: false, label: 'Acquirer Obj Id' },
    { id: 'acquirerId', type: 'text', numeric: false, disablePadding: false, label: 'Acquirer Id' },
    { id: 'name', type: 'text', numeric: false, disablePadding: false, label: 'Acquirer Name' },
    { id: 'description', type: 'description', numeric: true, disablePadding: false, label: 'Description' },
    { id: 'countryName', type: 'text', numeric: false, disablePadding: false, label: 'Country Name' },
    { id: 'status', type: 'text', numeric: true, disablePadding: false, label: 'Status' },
    { id: 'createdOn', type: 'text', numeric: true, disablePadding: false, label: 'Created On' },
    { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];
const AcquirerObj = {
    "acquirerId": null,
    "name": "",
    "displayName": "",
    "description": "",
    "countryId": "",
    "countryName" : "",
    "status": "ACTIVE",
}

const filterCells = [
    // { id: 'countryId', type: 'select', label: 'Country' },
    { id: 'isoCountryCodeNum', type: 'select', label: 'Country' },
    { id: 'name', type: 'text', label: 'Acquirer Name' },
    { id: 'status', type: 'select', label: 'Status' },
];

const filterobj = {
    id: "",
    name: "",
    status: "",
    countryId: "",
    isoCountryCodeNum: "",
    pageNo: 1,
    pageSize: 10,
    sortBy:"createdOn",
    sortDirection:"DESC"
}

const sortArr = [
    {key:"createdOn", label: "Create Date"},
    {key:"countryId", label: "Country Name"},
    {key:"acquirerId", label: "Acquirer Id"},
    {key:"name", label: "Acquirer Name"},
    {key:"status", label: "Status"},
]

const Acquirer = (props) => {
    const {classes} = useStyles();
    const {
        acquirerList, acquirerListCount, acquirerLoader, countryDetails, createAcqRes,
    } = props;
    const [clientRow, setClientRow] = useState(null);
    const [snackbarMsg, setSnackBarMsg] = useState(null);
    const [currentForm, setCurrentForm] = useState("list");
    const [openSnackbar, SetOpenSnackbar] = useState(false);
    const [responseId, setResponseId] = useState("");
    const [openPopover, setopenPopover] = useState(false);
    const [filterState, setFilterState] = useState(filterobj)
    const [acquirerState, setAcquirerState] = useState(AcquirerObj)
    const currentPage = localStorage.getItem('currentPage')
    const [filterData, setFilterData] = useState([]);
    const [pageNo, setPage] = useState(1)
    const [sortName, setsortName] = useState('createdOn')
    const [sortDir, setsortDir] = useState('DESC');
    const [searchValue, setSearchValue] = useState("");
    const [compInitaited, setCompInitiated] = useState(false);

    let permissions = localStorage.getItem("permissions")
    permissions = permissions && permissions !== null ? JSON.parse(permissions) : []
    const userid = localStorage.getItem("userid")

    const nextDisableToken = currentForm !== "view" && acquirerState && acquirerState.countryName !== "" &&
        acquirerState.name !== "" && acquirerState.name.length <= 50 && nameRegExp.test(acquirerState.name) && 
        acquirerState.displayName !== "" && acquirerState.displayName.length <= 50 && nameRegExp.test(acquirerState.displayName) && 
        // (acquirerState.displayName === "" || (acquirerState.displayName !== "" && acquirerState.displayName.length <= 50 && nameRegExp.test(acquirerState.displayName))) &&
        (acquirerState.description === "" || (descrRegex.test(acquirerState.description)))                               

    let countryIdArr = []
    const countryArr = countryDetails && countryDetails.map(country => {
        if(country.id && country.id !== "") {
            countryIdArr.push(country.id);
        }
        return country.name ? country.name : null
    }).filter(val => val !== null)

    const acquirerArray = acquirerList && acquirerList !== null ? 
        acquirerList.map(obj => {

            let acquirerObj = {
            ...obj,
            createdOn:  obj.createdOn && `${obj.createdOn.slice(0,10)}`, 
            countryName: countryIdArr.indexOf(obj.countryId) !== -1 ? countryDetails[countryIdArr.indexOf(obj.countryId)]['name'] : "",
            }
            return acquirerObj
        }) : []

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    }); 

    const onChangeView = async (inpval, row) => {
        setCurrentForm(inpval);
        if(inpval === "view") {
            setClientRow(row)
            countryDetails && countryDetails.length === 0 && props.fetchCountryDetails({})

        } else if(inpval === "edit") {
            setAcquirerState({...AcquirerObj, ...row})
            
            //fetch details for edit drop down
            countryDetails && countryDetails.length === 0 && props.fetchCountryDetails({})

        } else if(inpval === "create") {
            setAcquirerState(AcquirerObj)
            countryDetails && countryDetails.length === 0 && props.fetchCountryDetails({})

        } else {
        
            await props.fetchAcquirerDetails({...filterState,
                pageNo: 1, sortBy: sortName, sortDirection: sortDir
            });
            countryDetails && countryDetails.length === 0 && props.fetchCountryDetails({})
        }
    }
    
    useEffect(() => {
        if(currentPage && currentPage !== null && currentPage !== undefined && currentPage === "/acquirer" && compInitaited){
        setCurrentForm('list')
        setFilterState({...filterobj});
        setPage(1);
        setsortDir("DESC");
        setsortName("createdOn")
        props.fetchAcquirerDetails({
            pageNo: 1,
            pageSize: 10,
            sortBy:"createdOn",
            sortDirection:"DESC"
        })
        countryDetails && countryDetails.length === 0 && props.fetchCountryDetails({})
        localStorage.removeItem('currentPage')
        }
    }, [currentPage])

    useEffect(() => {
        const handleInitialCall = async () => {
        await setCompInitiated(true);
        await props.fetchAcquirerDetails({...filterState,
            pageNo: pageNo, sortBy: sortName, sortDirection: sortDir
        })
        countryDetails && countryDetails.length === 0 && props.fetchCountryDetails({})
        await localStorage.removeItem('currentPage')
        }
        handleInitialCall();
        
        return () => {
        //add all list reset API here
        
        //reset acquirer response
        props.resetCreateAcqResp()
        }
    }, [])
    
    const handleFilterStateChange = (key, value) => {
        let tempFilter = {
            ...filterState,
            [key]: value
        }
        // if(key === "countryId") {
        //     tempFilter = {
        //         ...tempFilter,
        //         name: ""
        //     }
        // }
        setFilterState(tempFilter);
    }

    const resetFilter = () => {
        setFilterState(filterobj);
        setPage(1);
        setsortDir("DESC");
        setsortName("createdOn")
        props.fetchAcquirerDetails({
        pageNo: 1,
        pageSize: 10,
        sortBy:"createdOn",
        sortDirection:"DESC"
        })
    }

    const FilterApply = () => {
        setPage(1)
        props.fetchAcquirerDetails({ ...filterState, pageNo: 1, sortBy: sortName, sortDirection: sortDir})
        setFilterState({...filterState});
    }

    const handleSnackBarAction = () => {
        SetOpenSnackbar(false)
        setSnackBarMsg(null)
        //reset merchant response
        props.resetCreateAcqResp()
    }

    useEffect(() => {
        if(createAcqRes && createAcqRes !== null && createAcqRes.st) {
            setResponseId(createAcqRes.id)
            if(currentForm === "create" ) {
                setopenPopover(true)
            } else {
                SetOpenSnackbar(true)
                setSnackBarMsg(`Acquirer Modified Successfully!`)
                onChangeView("list")
            }
            //reset Acquirer response
            props.resetCreateAcqResp()
        } else if(createAcqRes && createAcqRes !== null && !createAcqRes.st) {
            SetOpenSnackbar(true)
            setSnackBarMsg(createAcqRes.msg && createAcqRes.msg)
            // onChangeView("list")
        }
    }, [createAcqRes])

    const handleInputChange = (key, value) => {
        
        let updatedAcquirerState = {...acquirerState};
        if(key === "country") {
        
            // adding country details & reseting other fields
            updatedAcquirerState = {
                ...updatedAcquirerState,
                countryName: value[1],
                countryId: value[0],
            }

        } else {
            updatedAcquirerState = {
            ...updatedAcquirerState,
            [key]: value[0]
            }
        }

        setAcquirerState(updatedAcquirerState)
    }

    const handleAcquirerCreateUpdate = () => {
        let req = {
        ...acquirerState,
        "userId": JSON.parse(userid),
        }
        if(acquirerState.id && acquirerState.id !== "") {
            props.updateAcquirer(req)
        } else {
            props.createAcquirer(req)
        }
        setFilterState(filterobj);
        setSearchValue("")
    }

    const handleDialogAction = async (type) => {
        setopenPopover(false)
        setAcquirerState(AcquirerObj)
        if(type === "exit" ){
        onChangeView('list')
        } else {
        await onChangeView('list')
        await onChangeView('create')
        }
    }

    const handleSetFilterData = (filterArray, searchValue) => {
        setFilterData(filterArray)
        setSearchValue(searchValue)
    }

    const handlePageNo = (val) => {
        setPage(val)
        props.fetchAcquirerDetails({...filterState,
        pageNo: val, sortBy: sortName, sortDirection: sortDir
        });
    }

    const handleSortDir = (value) => {
        setsortDir(value)
        setPage(1)
    }

    const handleSortName = (value) => {
        setsortName(value)
        setPage(1)
    }
    
    return (
        <>
        <BasePageToolbar 
            currentForm={currentForm}  create={permissions.includes("59")} onChangeView={onChangeView} resetFilter={resetFilter}
            title={currentForm === 'list' ? "Acquirer Detail" : currentForm === 'view' ? "View Acquirer" : currentForm === 'edit' ? "Modify Acquirer" : "Create Acquirer"}
            handleFilterStateChange={handleFilterStateChange} filterState={filterState} filterCells={filterCells} FilterApply={FilterApply}
            setsortName={handleSortName} setsortDir={handleSortDir} sortDir={sortDir} sortName={sortName} sortArr={sortArr}
            arrayToFilter={acquirerArray} handleSetFilterData={handleSetFilterData} isTableSearchReq={false} searchValue={searchValue}
        ></BasePageToolbar>
        <BasePageContainer>
        {currentForm === 'list' ?
            <Grid container className={classes.container}>
            <TableComponent
                sortOrder="asc"
                showEdit={permissions.includes("60")}
                sortOrderBy="id"
                activeTab={filterState}
                // rows={merchantList}
                rows={searchValue && searchValue !== "" ? filterData : acquirerArray}
                headCells={headCells}
                loading={acquirerLoader}
                onChangeView={onChangeView}
                totalRecord={acquirerListCount}
                handlePageNo={handlePageNo}
            />
            </Grid>
            : currentForm === 'create' ?
            <Grid container className={classes.formContainer}>
                <CreateEditViewAcquirer 
                currentForm={currentForm}
                acquirerState={acquirerState}
                countryDetails={countryDetails}
                countryArr={countryArr}
                nextDisableToken={!nextDisableToken}
                handleInputChange={handleInputChange}
                handleAcquirerCreateUpdate={handleAcquirerCreateUpdate}
                />
            </Grid>
            : currentForm === 'edit' ? <Grid container className={classes.formContainer}>
                <CreateEditViewAcquirer 
                    currentForm={currentForm}
                    acquirerState={acquirerState}
                    countryDetails={countryDetails}
                    countryArr={countryArr}
                    nextDisableToken={!nextDisableToken}
                    handleInputChange={handleInputChange}
                    handleAcquirerCreateUpdate={handleAcquirerCreateUpdate}
                />
                </Grid> 
                : <Grid container className={classes.formContainer}>
                    <CreateEditViewAcquirer 
                    currentForm={currentForm} 
                    acquirerDetails={clientRow}
                    onChangeView={onChangeView}
                    />
                </Grid>
        }
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={() => handleSnackBarAction()}
                action={
                <React.Fragment>
                <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
                    <CloseIcon fontSize="small" />
                </IconButton>
                </React.Fragment>
                }
            >
                <Alert onClose={() => handleSnackBarAction()} severity={
                createAcqRes && createAcqRes !== null && !createAcqRes.st ? "info" : "success"
                }>
                {snackbarMsg}
                </Alert>
            </Snackbar>
            <Dialog
                open={openPopover}
                onClose={() => handleDialogAction("exit")}
                className={"delete_dialog"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                <h2>{`Acquirer ${responseId} Created Successfully!`}</h2>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`Do you want to Create new Acquirer?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button className={classes.color}
                    onClick={() => handleDialogAction("create")} 
                >
                    Create New
                </Button>
                <Button className={classes.color}
                    onClick={() => handleDialogAction("exit")} 
                >
                    Cancel
                </Button>
                </DialogActions>
            </Dialog>
        </BasePageContainer>
        </>
    )
}

const useStyles = tss.create({
    formContainer: {
        paddingTop: theme.spacing(0),
    },
    container: {
        paddingTop: theme.spacing(2),
    },
    color: {
        color: "white",
        backgroundColor: `#1BA785 !important`
    },
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            fetchCountryDetails, fetchAcquirerDetails,
            createAcquirer, updateAcquirer, 
            resetCreateAcqResp, 
        }, dispatch)
    }
}

function mapStateToProps(state) {
    return {
        acquirerList: state.AcquirerReducer.acquirerList !== null &&
            state.AcquirerReducer.acquirerList.acquirerList ?
                state.AcquirerReducer.acquirerList.acquirerList : [],
        acquirerListCount: state.AcquirerReducer.acquirerList !== null && state.AcquirerReducer.acquirerList.totalRecords ?
            state.AcquirerReducer.acquirerList.totalRecords : 0,
        countryDetails: state.SchemeReducer.countryDetails !== null &&
            state.SchemeReducer.countryDetails.countryList ?
                state.SchemeReducer.countryDetails.countryList : [],
        createAcqRes: state.AcquirerReducer.createAcqRes,
        acquirerLoader: state.AcquirerReducer.acquirerLoader
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Acquirer);
