import React from 'react'
import { tss } from "tss-react";
import theme from '../../_theme';
import { 
  Grid, Tooltip, Button, SwipeableDrawer,
  Dialog, DialogTitle, DialogContent, 
  DialogContentText, DialogActions
 } from '@mui/material'
import Typography from '@mui/material/Typography'
import FilterListIcon from '@mui/icons-material/FilterList';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import FilterComponent from './filter';
// import FilterScheme from '../../schemeManagement/filter';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InputBase from '@mui/material/InputBase'; 
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton'

import CloseImgIcon from '../../Assets/close.svg';
import {SpecialCharRegex} from "../../shared/utility"

const BasePageToolbar = props => {
  const {classes, cx} = useStyles()
  const externalClasses = props.classes || {}
  const [state, setState] = React.useState({
    right: false,
  });
  const [searchValue, setSearchValue] = React.useState("");
  const [openPopover, setOpenPopOver] = React.useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const Title = props.title ? (
    <Typography variant="h4" className={classes.titleText} component="h1">
      {props.title}
    </Typography>
  ) : null
  const TitleComponent = props.titleComponent
  const list = (anchor) => (
    <div
      className={cx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation" >
      {props.filterCells && props.filterCells.length > 0 && 
        <FilterComponent onClose={toggleDrawer("right", false)} {...props} />}
    </div>
  );

  // useEffect(() => {
  //   if(props.searchValue !== searchValue) {
  //     setSearchValue(props.searchValue);
  //   }
  // }, [props.searchValue])

  const handleSearch = event => {
    const data = props.arrayToFilter;
    let filteredDatas = []
    filteredDatas = data.filter(e => {
        let searchColumn = e.searchColumn;
        let retVal = false;
        if (typeof searchColumn == 'string' && !retVal && SpecialCharRegex.test(event.target.value)){
          const regex = new RegExp(event.target.value, 'gi')
          retVal = searchColumn.match(regex)
        }
        return retVal;
    })
    props.handleSetFilterData(filteredDatas, event.target.value);
    setSearchValue(event.target.value);
  }
  
  const handleDialogActions = async (value, type) => {
    setOpenPopOver(value)
    if (type === "YES") {
      props.onChangeView('list')
    }
  };

  const handleOpenPopover = () => {
    if(props.currentForm !== "view" ) {
      setOpenPopOver(true)
    } else {
      props.onChangeView('list')
    }
    
  }

  return (
    <Grid
      container
      className={((window.location.pathname === '/schememgmt') && (props.currentForm && props.currentForm !== "list")) ? 
        cx(classes.containerSch, externalClasses.container) : cx(classes.container, externalClasses.container)}
    >
      {props.currentForm ? props.currentForm === "list" ? ''
        : <div onClick={() => handleOpenPopover()} className="backArrow"> <ArrowBackIcon /> </div> : null}
      <Grid
        item
        sm={5}
        md={5}
        alignItems="center"
        container
        className={cx(classes.titleContainer, externalClasses.titleContainer)}
      >
        {Title && Title}
        {TitleComponent && <TitleComponent />}
      </Grid>
      <Grid
        item
        md={7}
        sm={7}
        alignItems="center"
        container
        className={cx(classes.actionsContainer, externalClasses.titleContainer)}
      >
        {props.currentForm && props.currentForm === 'list' ?
          <React.Fragment>
            {props.isTableSearchReq &&
            <div className={classes.searchActions}>
              <InputBase
                className={classes.input}
                placeholder="Search"
                inputProps={{ 'aria-label': 'Search' }}
                value={searchValue}
                onChange={handleSearch}
              />
              <IconButton type="submit" className={`${classes.iconButton} search_form`} aria-label="Search">
                <SearchIcon />
              </IconButton>
            </div>
            }
            <div>
              {props.filterCells && <Tooltip title="Filter">
                <Button color="primary" className="filter_btn" onClick={toggleDrawer("right", true)}>
                  <FilterListIcon className={classes.iconNew} />
                      Filter
                  </Button>
              </Tooltip>}
              {props.create && <Tooltip title="Create">
                <Button color="primary" className="create_btn" onClick={() => props.onChangeView('create')}>
                  <AddCircleOutlineRoundedIcon className={classes.iconNew} />
                  Create
                </Button>
              </Tooltip>}
              <div className="swipeableDrawer">
                <SwipeableDrawer
                  className={cx(classes.listOpen)}
                  anchor={"right"}
                  open={state["right"]}
                  onClose={toggleDrawer("right", false)}
                  onOpen={toggleDrawer("right", true)}
                >
                  {list("right")}
                </SwipeableDrawer>
              </div>
            </div>
          </React.Fragment>
          // : props.currentForm && props.currentForm === 'view' ?
          //   <React.Fragment>
          //     <Tooltip title="Edit">
          //       <Button color="primary" className={"create_btn"} style={{ width: "108px" }} onClick={() => props.onChangeView('edit')}>
          //         <img src={EditIcon} alt="edit" className={classes.iconEditNew} />
          //           Edit
          //       </Button>
          //     </Tooltip>
          //   </React.Fragment>
            : null}
      </Grid>

      <Dialog className={"delete_dialog"}
          open={openPopover}
          onClose={() => handleDialogActions(false,"CLOSE")}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <div className="close_dialog" onClick={() => handleDialogActions(false, "CLOSE")}><img src={CloseImgIcon} alt="close" /></div>
          <DialogTitle id="alert-dialog-title" className="delete_dialog_title">
          <h2>{"If you leave this page your data will be lost!"}</h2>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" >
                <p>Are you sure you want to leave this Page ?</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleDialogActions(false, "NO")} color="primary" className="no" >
                NO
            </Button>
            <Button onClick={() => handleDialogActions(false,"YES")} color="primary" className="yes" autoFocus>
                YES
            </Button>
          </DialogActions>
      </Dialog>
    </Grid>
  )
}

const useStyles = tss.create({
  titleText: {
    color: "#424242",
    fontFamily: "Quicksand",
    // fontSize: "24px",
    fontSize: "28px",
    letterSpacing: 0,
    lineHeight: "30px"
  },
  container: {
    marginBottom: '1rem',
    backgroundColor: '#fff',
    padding: "0px 25px 0px 0px",
    boxShadow: " 0 1px 0 0 rgba(0,0,0,0.06)"
  },
  containerSch: {
    marginBottom: '10px',
    backgroundColor: '#fff',
    padding: "0px 25px 0px 0px",
    boxShadow: " 0 1px 0 0 rgba(0,0,0,0.06)"
  },
  titleContainer: {
    marginTop: '1rem',
    marginBottom: '1rem',
    paddingLeft: '1.5rem',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  listOpen: {
    right: "-20px"
  },
  iconNew: {
    marginRight: 0,
  },
  iconEditNew: {
    marginRight: 4,
  },
  list: {
    width: 400, padding: "30px"
  },
  fullList: {
    width: 'auto',
  },
  searchActions: {
    borderRadius: "3px",
    backgroundColor: "#F5F5F5",
    marginRight: "10px"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1, padding: "3px",
  },
  iconButton: {
    padding: 7,
  },
});

export default BasePageToolbar
