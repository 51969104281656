import React, {useEffect} from 'react';
import {connect} from "react-redux";
// import {reduxForm} from 'redux-form';
import { bindActionCreators } from "redux";
import { Grid, TextField, Button, 
  Paper, MenuItem, Typography 
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../../../_theme'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {EmailRegex, SpecialCharRegex1, addRegex, nameRegExp, descrRegex, numbRegex} from "../../../shared/utility";
import { NO_SPECIAL_CHARACTERS, NO_NUMBERS_AND_SPECIAL_CHARACTERS, ONLY_NUMBERS } from "shared/errorMessages";

const useStyles = tss.create({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    border: "solid 1px #EEEEEE",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(5),
  },
  input: {
    display: 'none',
  },
  menu: {
    fontSize: "15px"
  },
});

function CreateEditViewStore(props) {
  let postCodeToken = false;
  const {
    productDetails, handleInputChange, StoreState, countryDetails, currentForm,
    PSPList, merchantList, handleStoreCreateUpdate, nextDisableToken, onChangeView
  } = props;
  const [valObj, setValObj] = React.useState({
    countryErr: false, pspErr: false, merchantErr: false, storeNameErr: false, storeDescErr: false,
    userNameErr: false, userPhoneErr: false, userMobileErr: false, userEmailErr: false,
    add1Err: false, add2Err: false, add3Err: false, stateErr: false, cityErr: false, pinErr: false, storeDisplayNameErr: false,
  })
  
  const {classes} = useStyles();
  const countryArr = countryDetails && countryDetails.map(country => country.name ? (country.name).toUpperCase() : null).filter(val => val !== null)
  //change merchant with merchantList when API added
  const merchantArr = merchantList && merchantList.map(merchant => merchant.name ? merchant.name : null).filter(val => val !== null)
  //change psp to PSPList when API added
  const pspArr = PSPList && PSPList.map(psp => psp.name ? psp.name: null).filter(val => val !== null)
  
  const handleAddEvent = (e) => {
    if(e.keyCode === 13) {
      e.preventDefault()
    }  
  }

  const handleRemoveEvent = (e) => {
    if(e.keyCode === 13) {
      e.preventDefault()
    }  
  }

  useEffect(() => {
    const country = document.querySelector("#country")
    const psp = document.querySelector("#psp")
    const merchant = document.querySelector("#merchant")
    const elemNum0 = document.querySelector('.number_field0')
    const elemNum1 = document.querySelector('.number_field1')
    const elemNum2 = document.querySelector('.number_field2')

    handleAdEventListner(elemNum0)
    handleAdEventListner(elemNum1)
    handleAdEventListner(elemNum2)
    country.addEventListener('keydown', (e) => handleAddEvent(e))
    psp.addEventListener('keydown', (e) => handleAddEvent(e))
    merchant.addEventListener('keydown', (e) => handleAddEvent(e))

    document.addEventListener("wheel", function(event){
      if(document.activeElement.type === "number"){
        document.activeElement.blur();
      }
    });

    return () => {
      handleRemoveListener(elemNum0)
      handleRemoveListener(elemNum1)
      handleRemoveListener(elemNum2)
      country.removeEventListener('keydown', (e) => handleRemoveEvent(e))
      psp.removeEventListener('keydown', (e) => handleRemoveEvent(e))
      merchant.removeEventListener('keydown', (e) => handleRemoveEvent(e))

      
      document.removeEventListener("wheel", function(event){
        if(document.activeElement.type === "number"){
            document.activeElement.blur();
        }
      });
    }
  })

  const handleAdEventListner = (element) => {
    element && element.addEventListener('keydown', function(e) {
      if (e.keyCode === 38 || e.keyCode === 40) {
          e.preventDefault();
      }
    });
  }

  const handleRemoveListener = (element) => {
    element && element.removeEventListener('keydown', function(e) {
      if (e.keyCode === 38 || e.keyCode === 40) {
          e.preventDefault();
      }
    })
  }

  const handleChange = (type, value, typeErr) => {
    if(type === "country"){
      countryArr.indexOf((value).toUpperCase()) !== -1 && handleInputChange(type, [
        countryDetails[countryArr.indexOf((value).toUpperCase())]['isoCountryCodeNum'],
        value
      ])
      setValObj({...valObj, countryErr: false })
    } else if(type === "psp") {
      pspArr.indexOf(value) !== -1 && handleInputChange(type, [
        // PSPList
        PSPList[pspArr.indexOf(value)]['id'],
        value
      ])
      setValObj({...valObj, pspErr: false })
    } else if(type === "merchant") {
      merchantArr.indexOf(value) !== -1 && handleInputChange(type, [
        // merchantList
        merchantList[merchantArr.indexOf(value)]['id'],
        value
      ])
      setValObj({...valObj, merchantErr: false })
    } else if(type === "storeName") {
      handleInputChange(type, value)
      setValObj({...valObj, storeNameErr: false })
    } else if(type === "storeDisplayName") {
      handleInputChange(type, value)
      setValObj({...valObj, storeDisplayNameErr: false })
    } else {
      handleInputChange(type, value)
      setValObj({...valObj, [typeErr]: false })
    }
  }

  const handleOnBlur = (field, value) => {
    if(value === null || value === ""){
      setValObj({...valObj, [field]: true })
    } else {
      setValObj({...valObj, [field]: false })
    }
  }

  const handlePostCodeCheck = (value) => {
    if(value !== postCodeToken) {
      postCodeToken = value
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form className="action_form merchant_form" noValidate autoComplete="off">
         <Grid container rowSpacing={2} columnSpacing={2}>

          {currentForm === "edit" &&
            <>
              <Grid item xs={12} sm={8} />
              <Grid item xs={12} sm={4}>
                <TextField 
                  InputLabelProps={{ shrink: true }} 
                  name="status" variant="outlined" select required
                  fullWidth id="status" label="Store status"
                  value={StoreState.status && StoreState.status}
                  onChange={(e) => handleInputChange('status', [e.target.value])}
                >
                  {["ACTIVE", "INACTIVE"].map((val, index) => {
                    return <MenuItem key={index} value={val} className={classes.menu}>
                      {val}
                    </MenuItem>
                  })}
                </TextField>
              </Grid>
            </>
          }

          <Grid item xs={12} sm={4}>
            <TextField 
              InputLabelProps={{ shrink: true }} 
              name="country" variant="outlined" select={currentForm === 'create'} required
              fullWidth id="country" label="Country" 
              disabled={currentForm !== 'create'}
              value={currentForm === 'view' ? 
                productDetails !== null && productDetails.countryName && 
                productDetails.countryName : StoreState.countryName ? 
                  StoreState.countryName : null
              } 
              onBlur={(e) => handleOnBlur("countryErr", e.target.value)}
              onChange={(e) => countryArr.length > 0 && handleChange('country', e.target.value)}
            >
              {countryArr && countryArr.length === 0 && 
                <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
              {countryArr && countryArr.map((val, index) => {
                return <MenuItem key={index} value={val} className={classes.menu}>
                  {val}
                </MenuItem>
              })}
            </TextField>
            {valObj.countryErr ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
              </p>
            :null}
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField 
              InputLabelProps={{ shrink: true }} 
              name="psp" variant="outlined" select={currentForm === 'create'} required
              fullWidth id="psp" label="PSP" 
              disabled={currentForm !== 'create'}
              value={currentForm === 'view' ?
                productDetails !== null && productDetails.pspName && 
                productDetails.pspName : StoreState.pspName ? 
                  StoreState.pspName : null
              }
              onBlur={(e) => handleOnBlur("pspErr", e.target.value)}
              onChange={(e) => pspArr.length > 0 && handleChange('psp', e.target.value)}
            >
              {pspArr && pspArr.length === 0 && 
                <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
              {pspArr && pspArr.map((val, index) => {
                return <MenuItem key={index} value={val} className={classes.menu}>
                  {val}
                </MenuItem>
              })}
            </TextField>
            {valObj.pspErr ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
              </p>
            :null}
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField 
              InputLabelProps={{ shrink: true }} 
              name="merchant" variant="outlined" select={currentForm === 'create'} required
              fullWidth id="merchant" label="Merchant" 
              disabled={currentForm !== 'create'}
              value={currentForm === 'view' ?
                productDetails !== null && productDetails.merchantName && 
                productDetails.merchantName : StoreState.merchantName ? 
                  StoreState.merchantName : null
              }
              onBlur={(e) => handleOnBlur("merchantErr", e.target.value)}
              onChange={(e) => merchantArr.length > 0 &&  handleChange('merchant', e.target.value)}
            >
              {merchantArr && merchantArr.length === 0 && 
                <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
              {merchantArr && merchantArr.map((val, index) => {
                return <MenuItem key={index} value={val} className={classes.menu}>
                  {val}
                </MenuItem>
              })}
            </TextField>
            {valObj.merchantErr ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
              </p>
            :null}
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField 
              // className={"name_field"}
              InputLabelProps={{ shrink: true }} required
              name="storeName" id="storeName" fullWidth
              label="Store Name" variant="outlined"
              disabled={currentForm !== 'create'}
              value={currentForm === 'view' ?
                productDetails !== null && productDetails.name && 
                productDetails.name : StoreState.name ?
                StoreState.name : null
              }
              onChange={(e) => e.target.value.length <= 50 && handleChange('storeName', [e.target.value])}
              onBlur={(e) => handleOnBlur("storeNameErr", e.target.value)}
            />
            {StoreState && StoreState.name && StoreState.name !== "" && 
              !nameRegExp.test(StoreState.name) ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
              </p> : valObj.storeNameErr ? 
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                    </p> : null
            }
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField 
              // className={"name_field"}
              InputLabelProps={{ shrink: true }} required
              name="storeDisplayName" id="storeDisplayName" fullWidth
              label="Store Display Name" variant="outlined"
              disabled={currentForm === 'view'}
              value={currentForm === 'view' ?
                productDetails !== null && productDetails.storeDisplayName && 
                productDetails.storeDisplayName : StoreState.storeDisplayName ?
                StoreState.storeDisplayName : null
              }
              onChange={(e) => e.target.value.length <= 50 && handleChange('storeDisplayName', [e.target.value])}
              onBlur={(e) => handleOnBlur("storeDisplayNameErr", e.target.value)}
            />
            {StoreState && StoreState.storeDisplayName && StoreState.storeDisplayName !== "" && 
              !nameRegExp.test(StoreState.storeDisplayName) ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
              </p> : valObj.storeDisplayNameErr ? 
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                    </p> : null
            }
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField 
              // className={"name_field"}
              InputLabelProps={{ shrink: true }}
              name="storeDescription" id="storeDescription" fullWidth
              label="Store Description" variant="outlined"
              disabled={currentForm === 'view'}
              value={currentForm === 'view' ?
                productDetails !== null && productDetails.storeDescription && 
                productDetails.storeDescription : StoreState.storeDescription ? 
                StoreState.storeDescription : null
              }
              onChange={(e) => e.target.value.length <= 100 && handleInputChange('storeDescription', [e.target.value])}
            />
            {StoreState && StoreState.storeDescription && StoreState.storeDescription !== "" && 
              !descrRegex.test(StoreState.storeDescription) ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
              </p>
            :null}
          </Grid>

          <Grid item xs={12} >
              <Typography variant="h5" >Contact Person Details</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField 
              // className={"name_field"}
              InputLabelProps={{ shrink: true }}
              name="personName" id="personName" fullWidth
              label="Name" variant="outlined"
              disabled={currentForm === 'view'}
              value={currentForm === 'view' ?
                productDetails !== null && productDetails['contactPersonDetail'].name && 
                productDetails['contactPersonDetail'].name : StoreState['contactPersonDetail'].name ? 
                StoreState['contactPersonDetail'].name : null
              }
              onChange={(e) => e.target.value.length <= 50 && handleInputChange('user', {name: e.target.value})}
            />
            {StoreState && StoreState['contactPersonDetail'] && StoreState['contactPersonDetail'].name && 
              StoreState['contactPersonDetail'].name !== "" && !SpecialCharRegex1.test(StoreState['contactPersonDetail'].name) ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon />{NO_NUMBERS_AND_SPECIAL_CHARACTERS}</span>
              </p>
            :null}
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField 
              className={"number_field0"} type="text"
              InputLabelProps={{ shrink: true }} 
              name="phoneNo" id="phoneNo" fullWidth
              label="Phone No." variant="outlined"
              disabled={currentForm === 'view'}
              // onKeyDown={(e) => handlePostCodeCheck((e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || e.keyCode === 8)}
              // onBlur={() => handlePostCodeCheck(false)}
              value={currentForm === 'view' ?
                productDetails !== null && productDetails['contactPersonDetail'].phoneNo && 
                productDetails['contactPersonDetail'].phoneNo : StoreState['contactPersonDetail'].phoneNo && 
                StoreState['contactPersonDetail'].phoneNo
              }
              onChange={(e) => e.target.value.length <= 20 && 
                // postCodeToken && 
                handleInputChange('user', {phoneNo: e.target.value})}
            />
              {StoreState && StoreState['contactPersonDetail'].phoneNo && !numbRegex.test(StoreState['contactPersonDetail'].phoneNo) ?
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                </p> : null}
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField 
              className={"number_field1"} type="text"
              InputLabelProps={{ shrink: true }} 
              name="mobileNo" id="mobileNo" fullWidth
              label="Mobile No." variant="outlined"
              disabled={currentForm === 'view'}
              // onKeyDown={(e) => handlePostCodeCheck((e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || e.keyCode === 8)}
              // onBlur={() => handlePostCodeCheck(false)}
              value={currentForm === 'view' ?
                productDetails !== null && productDetails['contactPersonDetail'].mobileNo && 
                productDetails['contactPersonDetail'].mobileNo : StoreState['contactPersonDetail'].mobileNo && 
                StoreState['contactPersonDetail'].mobileNo
              }
              onChange={(e) => e.target.value.length <= 20 && 
                // postCodeToken && 
                handleInputChange('user', {mobileNo: e.target.value})}
            />
              {StoreState && StoreState['contactPersonDetail'].mobileNo && !numbRegex.test(StoreState['contactPersonDetail'].mobileNo) ?
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                </p> : null}
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField 
              // className={"name_field"}
              InputLabelProps={{ shrink: true }} 
              name="emailId" id="emailId" fullWidth
              label="Email Id" variant="outlined"
              disabled={currentForm === 'view'}
              value={currentForm === 'view' ?
                productDetails !== null && productDetails['contactPersonDetail'].emailId && 
                productDetails['contactPersonDetail'].emailId : StoreState['contactPersonDetail'].emailId ? 
                StoreState['contactPersonDetail'].emailId : null
              }
              onChange={(e) => e.target.value.length <= 50 && handleInputChange('user', {emailId: e.target.value})}
            />
            {StoreState && StoreState['contactPersonDetail'] && StoreState['contactPersonDetail'].emailId && 
              StoreState['contactPersonDetail'].emailId !== "" && !EmailRegex.test(StoreState['contactPersonDetail'].emailId) ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                <span className="error_message"><InfoOutlinedIcon /> {"Invalid Email Address!"}</span>
              </p>
            :null}
          </Grid>

          <Grid item xs={12} >
              <Typography variant="h5" >Address Details</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField 
              // className={"name_field"}
              InputLabelProps={{ shrink: true }} required
              name="address1" id="address1" fullWidth
              label="Address Line 1" variant="outlined"
              disabled={currentForm === 'view'}
              value={currentForm === 'view' ?
                productDetails !== null && productDetails['address'].address1 && 
                productDetails['address'].address1 : StoreState['address'].address1 ? 
                StoreState['address'].address1 : null
              }
              onChange={(e) => handleChange('address', {address1: e.target.value}, 'add1Err')}
              onBlur={(e) => handleOnBlur("add1Err", e.target.value)}
            />
            {valObj.add1Err ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
              </p> :  
                StoreState && StoreState['address'] && StoreState['address'].address1 && 
                StoreState['address'].address1 !== "" && !addRegex.test(StoreState['address'].address1) ? 
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon /> {"Invalid Address! No Special Characters Allowed."}</span>
                  </p>
                  : StoreState && StoreState['address'] && StoreState['address'].address1 && 
                    StoreState['address'].address1.length > 50 ? 
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                        <span className="error_message"><InfoOutlinedIcon /> {"Invalid Length! More than 50 characters are not allowed."}</span>
                      </p>
                      : null}
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField 
              // className={"name_field"}
              InputLabelProps={{ shrink: true }} 
              name="address2" id="address2" fullWidth
              label="Address Line 2" variant="outlined"
              disabled={currentForm === 'view'}
              value={currentForm === 'view' ?
                productDetails !== null && productDetails['address'].address2 && 
                productDetails['address'].address2 : StoreState['address'].address2 ? 
                StoreState['address'].address2 : null
              }
              onChange={(e) => e.target.value.length <= 100 && handleInputChange('address', {address2: e.target.value})}
            />
            {StoreState && StoreState['address'] && StoreState['address'].address2 && 
              StoreState['address'].address2 !== "" && !addRegex.test(StoreState['address'].address2) ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                <span className="error_message"><InfoOutlinedIcon /> {"Invalid Address! No Special Characters Allowed."}</span>
              </p>
            :null}
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField 
              // className={"name_field"}
              InputLabelProps={{ shrink: true }} 
              name="address3" id="address3" fullWidth
              label="Address Line 3" variant="outlined"
              disabled={currentForm === 'view'}
              value={currentForm === 'view' ?
                productDetails !== null && productDetails['address'].address3 && 
                productDetails['address'].address3 : StoreState['address'].address3 ? 
                StoreState['address'].address3 : null
              }
              onChange={(e) => e.target.value.length <= 100 && handleInputChange('address', {address3: e.target.value})}
            />
            {StoreState && StoreState['address'] && StoreState['address'].address3 && 
              StoreState['address'].address3 !== "" && !addRegex.test(StoreState['address'].address3) ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                <span className="error_message"><InfoOutlinedIcon /> {"Invalid Address! No Special Characters Allowed."}</span>
              </p>
            :null}
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <TextField 
              // className={"name_field"}
              InputLabelProps={{ shrink: true }} 
              name="state" id="state" fullWidth required
              label="State" variant="outlined"
              disabled={currentForm === 'view'}
              value={currentForm === 'view' ?
                productDetails !== null && productDetails['address'].state && 
                productDetails['address'].state : StoreState['address'].state ? 
                StoreState['address'].state : null
              }
              onChange={(e) => e.target.value.length <= 50 && handleChange('address', {state: e.target.value}, 'stateErr')}
              onBlur={(e) => handleOnBlur("stateErr", e.target.value)}
            />
            {StoreState && StoreState['address'] && StoreState['address'].state && 
              StoreState['address'].state !== "" && !SpecialCharRegex1.test(StoreState['address'].state) ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {NO_NUMBERS_AND_SPECIAL_CHARACTERS}</span>
              </p>
              : valObj.stateErr ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
              </p>
              : null
            }
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField 
              // className={"name_field"}
              InputLabelProps={{ shrink: true }} 
              name="city" id="city" fullWidth required
              label="City" variant="outlined"
              disabled={currentForm === 'view'}
              value={currentForm === 'view' ?
                productDetails !== null && productDetails['address'].city && 
                productDetails['address'].city : StoreState['address'].city ? 
                StoreState['address'].city : null
              }
              onChange={(e) => e.target.value.length <= 50 && handleChange('address', {city: e.target.value}, 'cityErr')}
              onBlur={(e) => handleOnBlur("cityErr", e.target.value)}
            />
            {StoreState && StoreState['address'] && StoreState['address'].city && 
              StoreState['address'].city !== "" && !SpecialCharRegex1.test(StoreState['address'].city) ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                <span className="error_message"><InfoOutlinedIcon /> {NO_NUMBERS_AND_SPECIAL_CHARACTERS}</span>
              </p>
              : valObj.cityErr ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
              </p>
              : null
            }
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField 
              className={"number_field2"} type="text"
              InputLabelProps={{ shrink: true }} required
              name="postalCode" id="postalCode" fullWidth
              label="Postal Code" variant="outlined"
              disabled={currentForm === 'view'}
              // onKeyDown={(e) => handlePostCodeCheck((e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || e.keyCode === 8)}
              // onBlur={() => handlePostCodeCheck(false)}
              value={currentForm === 'view' ?
                productDetails !== null && productDetails['address'].postalCode && 
                productDetails['address'].postalCode : StoreState['address'].postalCode &&
                StoreState['address'].postalCode
              }
              onChange={(e) => e.target.value.length <= 15 && 
                // postCodeToken &&
                handleChange('address', {postalCode: e.target.value}, 'pinErr')}
                onBlur={(e) => handleOnBlur("pinErr", e.target.value)}
            />
              {StoreState && StoreState['address'] && StoreState['address'].postalCode && !numbRegex.test(StoreState['address'].postalCode) ?
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                </p> : valObj.pinErr ? 
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
              </p> : null
            }
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button fullWidth variant="contained"
              color="primary" className={"action_login_button"}
              disabled={nextDisableToken}
              onClick={() => currentForm !== "view" ? handleStoreCreateUpdate() : onChangeView("list")}
            >
              {currentForm === "edit" ? "Modify" : currentForm === "view" ? "Close" : "Create"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  </div>
  )
}

// CreateEditViewStore = reduxForm({
//   form: 'CreateEditViewStore',
//   // validate: validate,
//   enableReinitialize: true
// })(CreateEditViewStore);

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
        
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditViewStore);
