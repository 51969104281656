import React, { useEffect, useState } from 'react'
import {connect} from "react-redux";
// import {reduxForm} from 'redux-form';
import { bindActionCreators } from "redux";
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid, Button, Paper, Snackbar } from '@mui/material';
import { tss } from "tss-react";
import theme from '../../_theme'

import {
    resetPasswordForForgotPassword, clearResetPasswordRes, fetchUserNameById,
    validateForgotPasswordToken
} from "../actions";
import {passRegExp} from "../../shared/utility";

const useStyles = tss.create({
    root: {
        width: '100%',
        //paddingLeft: theme.spacing(6),
        // paddingTop: theme.spacing(10),
    },
    paper: {
        width: '40%',
        border: "solid 1px #EEEEEE",
        //marginBottom: theme.spacing(2),
        padding: theme.spacing(4),
        //paddingTop: "30%",
        marginTop: "10%",
        marginLeft: "auto",
        marginRight: "auto",
    },
    input: {
        display: 'none',
    },
    menu: {
        fontSize: "15px"
    },
    marginTopBottom: {
        margin: theme.spacing(2, 0, 2, 0),
    },
});

function ResetPassword(props) {
    const {classes} = useStyles();
    const {resetPasswordRes, userName} = props;
    const [passwordState, setPasswordState] = useState({
        password: '',
        showPassword: false,
        confirmPassword: "",
        showConfirmPassword: false,
    });
    const [passwordValObj, setPasswordValObj] = useState({
        passwordErr: false,
        confirmPasswordErr: false,
        passwordMatchErr: false,
    });
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [checkedOnce, setCheckedOnce] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [chngPassIncUserName, setchngPassIncUserName] = React.useState(false);
    const [chngCnfPassIncUserName, setchngCnfPassIncUserName] = React.useState(false);
    const inCorrectPassMsg = `Please enter password between 8 to 15 Char, including atleast 1 Capital letter, 
        1 small letter, 1 digit and one special char!`;
    
    const resetPasswordFlag = passwordState && passwordState.password !== "" && 
        passwordState.confirmPassword !== "" && passwordState.password === passwordState.confirmPassword && 
        passRegExp.test(passwordState.password) && passRegExp.test(passwordState.confirmPassword) && 
        !chngPassIncUserName && !chngCnfPassIncUserName;

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    });
        
    // useEffect(() => {
    //     let uId = document.location.pathname && (document.location.pathname).split("/").length > 0 && 
    //         (document.location.pathname).split("/")[2];
    //     // props.fetchUserNameById({ "userId": uId });
    //     props.validateForgotPasswordToken({'token': uId});
    // }, [])

    useEffect(() => {
        if(resetPasswordRes && resetPasswordRes !== null && resetPasswordRes.st && !checkedOnce) {
            setCheckedOnce(true);
            setOpenSnackbar(true)
            setSnackbarMsg("Password Saved Successfully")
            // localStorage.setItem("forgotPasswordToken", true);
        }
         else if (resetPasswordRes && resetPasswordRes !== null && !resetPasswordRes.st && !checkedOnce) {
            setCheckedOnce(true);
            setOpenSnackbar(true)
            setSnackbarMsg(resetPasswordRes.msg && resetPasswordRes.msg)
            // localStorage.setItem("forgotPasswordToken", false);
        } 
        else if (resetPasswordRes && resetPasswordRes !== null && resetPasswordRes.status && !checkedOnce) {
            setCheckedOnce(true);
            setOpenSnackbar(true)
            setSnackbarMsg(resetPasswordRes.error && resetPasswordRes.error)
            // localStorage.setItem("forgotPasswordToken", false);
        }
        
    }, [resetPasswordRes, checkedOnce]);

    const handleClickShowChangePassword = (key) => {
        if(key === "password") {
            setPasswordState({ ...passwordState, showPassword: !passwordState.showPassword });
        } else if(key === "confirmPassword") {
            setPasswordState({ ...passwordState, showConfirmPassword: !passwordState.showConfirmPassword });
        }
    };

    const handleChangePasswordChange = (key, value) => {
        if(key === "password") {
            setPasswordValObj({...passwordValObj, passwordErr: false})
            setPasswordState({ ...passwordState, password: value});
            if(userName && userName !== "") {
                const userArr =  [...(userName.toUpperCase()).split(" ")]
                let flag = false;
                  
                userArr.map(name => {
                  if(((value).toUpperCase()).includes(name) && !flag) {
                    flag = true
                  }
                  return null;
                })
    
                const str = userName.toUpperCase();
                const str1 = value.toUpperCase();
                let i = 0;
                while (i < str.length) {
                    if(str1.includes(`${str}${str}`.slice(i, (i+4)))
                    ) {
                        flag = true
                        break;
                    }
                    i++;
                }
                setchngPassIncUserName(flag)
            }
        } else if(key === "confirmPassword") {
            validateResetConfirmPassword(value)
            setPasswordState({ ...passwordState, confirmPassword: value});
            if(userName && userName !== "") {
                const userArr =  [...(userName.toUpperCase()).split(" ")]
                let flag = false;
                  
                userArr.map(name => {
                  if(((value).toUpperCase()).includes(name) && !flag) {
                    flag = true
                  }
                  return null;
                })
    
                const str = userName.toUpperCase();
                const str1 = value.toUpperCase();
                let i = 0;
                while (i < str.length) {
                    if(str1.includes(`${str}${str}`.slice(i, (i+4)))
                    ) {
                        flag = true
                        break;
                    }
                    i++;
                }
                setchngCnfPassIncUserName(flag)
            }
        }
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const validateResetConfirmPassword = (value) => {
        if(passwordState.password === value){
            setPasswordValObj({...passwordValObj, passwordMatchErr: false, confirmPasswordErr: false})
        } else {
            setPasswordValObj({...passwordValObj, passwordMatchErr: true, confirmPasswordErr: false})
        } 
    };

    const onSubmit = (type) => {
        if(type === "ChangePassword") {
            if(passwordState.password === "") {
                setPasswordValObj({...passwordValObj, passwordErr: true})
            } else if(passwordState.confirmPassword === "") {
                setPasswordValObj({...passwordValObj, confirmPasswordErr: true})
            } else if(passwordState.confirmPassword !== passwordState.password) {
                setPasswordValObj({...passwordValObj, passwordMatchErr: true})
            }else {
                let uId = document.location.pathname && (document.location.pathname).split("/").length > 0 && (document.location.pathname).split("/")[2];
                props.resetPasswordForForgotPassword({
                    "token": uId, "password": passwordState.password, userName: userName
                });
            }
        } else {
            // localStorage.setItem("forgotPasswordToken", true);
            props.clearResetPasswordRes({})
            props.clearResetPasswordRes(null)
        }
    }
   
    const handleSnackBarAction = () => {
        setOpenSnackbar(false)
        //Reset password response
        props.clearResetPasswordRes(null)
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <form className="action_form merchant_form" noValidate autoComplete="off">
                    <div>
                        <Grid container rowSpacing={2} columnSpacing={2}>
                            <Grid item xs={12}>
                                <h2>{"Reset Password"}</h2>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl 
                                    fullWidth 
                                    required
                                    variant="outlined"
                                    // className={cx(classes.marginTopBottom)}
                                    >
                                    <InputLabel htmlFor="outlined-adornment-password1" > Enter New Password </InputLabel>
                                    <OutlinedInput 
                                        name="userPassword" 
                                        id="outlined-adornment-new-password"
                                        type={passwordState.showPassword ? 'text' : 'password'}
                                        labelwidth={175} 
                                        label="Enter New Password"
                                        value={passwordState.password} 
                                        onChange={(e) => handleChangePasswordChange('password', e.target.value)} 
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton 
                                            aria-label="toggle password visibility" className="password_icon"
                                            onClick={() => handleClickShowChangePassword("password")} edge="end"
                                            onMouseDown={(e) => handleMouseDownPassword(e)} 
                                            >
                                            {passwordState.showPassword ? <Visibility /> : <VisibilityOff />} 
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                    />
                                    {passwordValObj.passwordErr ?
                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                            <span className="error_message"><InfoOutlinedIcon /> {"Password is Required!"}</span>
                                        </p>
                                        : passwordState.password && !passRegExp.test(passwordState.password) ?
                                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                                <span className="error_message"><InfoOutlinedIcon /> {inCorrectPassMsg}</span>
                                            </p> : passwordState.password && chngPassIncUserName ?
                                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                                    <span className="error_message"><InfoOutlinedIcon /> {"Password should not contain any part of user name."}</span>
                                                </p> : null 
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl 
                                    fullWidth 
                                    variant="outlined"
                                    required
                                    >
                                    <InputLabel htmlFor="outlined-adornment-password1" > Confirm New Password </InputLabel>
                                    <OutlinedInput 
                                        name="confirmPassword" 
                                        id="outlined-adornment-confirm-new-password"
                                        type={passwordState.showConfirmPassword ? 'text' : 'password'}
                                        labelwidth={195} 
                                        label="Confirm New Password"
                                        value={passwordState.confirmPassword} 
                                        onChange={(e) => handleChangePasswordChange('confirmPassword', e.target.value)} 
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton 
                                            aria-label="toggle password visibility" className="password_icon"
                                            onClick={() => handleClickShowChangePassword("confirmPassword")} edge="end"
                                            onMouseDown={(e) => handleMouseDownPassword(e)} 
                                            >
                                            {passwordState.showConfirmPassword ? <Visibility /> : <VisibilityOff />} 
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                    />
                                    {passwordValObj.confirmPasswordErr  ?
                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                        <span className="error_message"><InfoOutlinedIcon /> {"Confirm Password is Required!"}</span>
                                        </p>
                                        : passwordValObj.passwordMatchErr ? 
                                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                                <span className="error_message"><InfoOutlinedIcon /> {"Confirm password should be same as Password"}</span>
                                            </p>
                                            : passwordState.confirmPassword && !passRegExp.test(passwordState.confirmPassword) ?
                                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                                    <span className="error_message"><InfoOutlinedIcon /> {inCorrectPassMsg}</span>
                                                </p> : passwordState.password && chngCnfPassIncUserName ?
                                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                                        <span className="error_message"><InfoOutlinedIcon /> {"Confirm password should not contain any part of user name."}</span>
                                                    </p> : null 
                                    }
                                </FormControl>
                            </Grid>
                            
                            <Grid style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly"}} item xs={12} alignItems="flex-start">
                                <Button color="primary" className="yes" autoFocus
                                    onClick={() => onSubmit("ChangePassword")} 
                                    disabled={!resetPasswordFlag}
                                >
                                    Submit
                                </Button>
                                <Button color="primary" className="no"
                                    onClick={() => onSubmit("exit")} 
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </Paper>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={() => handleSnackBarAction()}
                action={
                <React.Fragment>
                    <IconButton size="medium" aria-label="close" color="inherit" onClick={() =>handleSnackBarAction()}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            >
                <Alert onClose={() => handleSnackBarAction()} severity={
                    (resetPasswordRes && resetPasswordRes !== null && !resetPasswordRes.st) ? "info" : "success"
                }>
                    {snackbarMsg}
                </Alert>
            </Snackbar>
        </div>
    )
}

// ResetPassword = reduxForm({
//     form: 'ResetPassword',
//     // validate: validate,
//     enableReinitialize: true
// })(ResetPassword);

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            resetPasswordForForgotPassword, clearResetPasswordRes,
            fetchUserNameById, validateForgotPasswordToken
        }, dispatch)
    }
  }
  
  function mapStateToProps(state) {
    return {
        resetPasswordRes: state.AuthReducer.resetPasswordRes,
        // userName: state.AuthReducer.userName !== null ? 
        //     state.AuthReducer.userName.userName : null,
        userName: state.AuthReducer.forPassVal !== null && state.AuthReducer.forPassVal.userName ? 
            state.AuthReducer.forPassVal.userName : null,
    }
  }

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResetPassword);