import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import { Grid } from '@mui/material'
import { tss } from "tss-react";
import theme from '../../_theme'
import moment from 'moment'

import { downloadFileFromBase64Str, PRODUCT_FILE_BASE_NAME } from '../../shared/utility'
import DialogBox from '_common/dialogBox'
import SnackbarToast from '_common/SnackbarToast'
import BasePageContainer from '../../_common/basePageContainer'
import BasePageToolbar from '../../_common/basePageToolbar'
import TableComponent from '../table/index'
import CreateMapping from './createMapping'
import { fetchCountryDetails, fetchBrandDetails, clearBrandDetail } from '../product/actions'
import { fetchMerchantMetadata, resetMerchantMetadata } from "../../manageMerchant/merchant/actions/index";
import { 
    downloadProductFileToCreateMapping, downloadProductFileToUpdateMapping, 
    resetDownloadProductFileResp,
    uploadProductFileCreateMode, uploadProductFileUpdateMode, resetUploadProductFileResp,
    fetchProductMappingFilesDetail, downloadProductMappingFileByStatus,
    fetchProductMappings, deleteProductMappings, resetDeleteMappingResp,
    resetProductMappingListResp, resetDownloadMappingFileByStatusResp
} from "./actions/index"

const headCells = [
    // { id: 'id', type: 'text', numeric: false, disablePadding: false, label: 'Id' },
    { id: 'fileName', type: 'text', numeric: false, disablePadding: false, label: 'File Name' },
    // { id: 'filePath', type: 'text', numeric: false, disablePadding: false, label: 'File Path' },
    { id: 'merchantName', type: 'text', numeric: false, disablePadding: false, label: 'Merchant Name' },
    { id: 'totalRecordCount', type: 'text', numeric: true, disablePadding: false, label: 'Total Records' },
    { id: 'successRecordCount', type: 'text', numeric: true, disablePadding: false, label: 'Success Records' },
    { id: 'failedRecordCount', type: 'text', numeric: true, disablePadding: false, label: 'Failed Records' },
    { id: 'createdOn', type: 'createdOn', numeric: false, disablePadding: false, label: 'Created On' },
    { id: 'mode', type: 'text', numeric: false, disablePadding: false, label: 'Mode' },
    { id: 'status', type: 'text', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];

const downloadFileObj = {
    isoCountryCodeNum: "",
    country: "",
    mode: "",
    brandIds: [],
    brands: [],
    merchantId: "",
    merchant: "",
    allProducts: false,
}

const uploadFileObj = {
    isoCountryCodeNum: "",
    country: "",
    merchantId: "",
    merchant: "",
    mode: "",
    file: null,
}

const deleteFilterObj = {
    isoCountryCodeNum: "",
    country: "",
    merchantId: "",
    merchant: "",
    brandId: "",
    brand: "",
    merchantProductCode: "",
}

function getCurrentTime() {
    return moment(new Date()).format('DDMMYYYYTHH:mm:ss');
}

export function getProductFileName() {
    return `${PRODUCT_FILE_BASE_NAME}_${getCurrentTime()}.xlsx`
}

const ProductCodeMapping = (props) => {
    const { 
        countryList, brandList, merchantMetadata, loader, downloadProductFileResp, uploadProductFileResp,
        mappingFileList, totalRecords, downloadProductMappingFileByStatusResp, deleteProductMappingResp, productMappingList
    } = props

    const {classes} = useStyles();
    const [currentForm, setCurrentForm] = useState("list");
    const [compInitaited, setCompInitiated] = useState(false);
    const [pageNo, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackBarMsg] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogBody, setDialogBody] = useState("");
    const [downloadType, setDownloadType] = useState("");
    const [downloadFileState, setDownloadFileState] = useState(downloadFileObj)
    const [uploadFileState, setUploadFileState] = useState(uploadFileObj)
    const [deleteFilterState, setDeleteFilterState] = useState(deleteFilterObj)
    const [selectedMappings, setSelectedMappings] = useState([])
    const [fileName, setFileName] = useState("")

    const currentPage = localStorage.getItem('currentPage')
    let permissions = localStorage.getItem("permissions")
    permissions = permissions && permissions !== null ? JSON.parse(permissions) : []

    const disableDownloadProductFile = downloadFileState && downloadFileState !== null && downloadFileState.isoCountryCodeNum !== "" &&
        downloadFileState.mode !== "" && downloadFileState.brands && downloadFileState.brands.length > 0 && 
        (downloadFileState.allProducts || downloadFileState.merchantId !== "")
    
    const disableUploadProductFile = uploadFileState && uploadFileState !== null && uploadFileState.isoCountryCodeNum !== "" &&
        uploadFileState.merchantId !== "" && uploadFileState.mode !== "" && uploadFileState.file !== null

    let brandArr = []
    let brandIdArr = []
    const brandArrObj = brandList && brandList !== null &&
        brandList.filter((obj) => {
            if (obj.name && obj.name !== "") {
                brandArr.push(obj.name)
                brandIdArr.push(obj.id)
            }
            return obj.name && obj.name !== ""
        })

    useEffect(() => {
        if(currentPage && currentPage !== null && currentPage !== undefined && currentPage === "/productCodeMapping" && compInitaited){
        setCurrentForm('list')
        setPage(1);
        props.fetchProductMappingFilesDetail({
            pageNo: 1,
            pageSize: 10,
        })
        localStorage.removeItem('currentPage')
        }
    }, [currentPage])

    useEffect(() => {
        const handleInitialCall = async () => {
        await setCompInitiated(true);
        await props.fetchProductMappingFilesDetail({
            pageNo: pageNo, 
            pageSize: pageSize,
        })
        await localStorage.removeItem('currentPage')
        }
        handleInitialCall();
        
        return () => {
        //add all list reset API here
        }
    }, [])

    useEffect(() => {

        if(downloadProductFileResp && downloadProductFileResp !== null && downloadProductFileResp.st) {
            setDownloadType("DOWNLOAD")
            setOpenDialog(true)
            setDialogTitle("File Download Confirmation")
            setDialogBody("Do you want to download the Product File? Please confirm!")
        } else if(downloadProductFileResp && downloadProductFileResp !== null && !downloadProductFileResp.st){
            setOpenSnackbar(true)
            setSnackBarMsg(downloadProductFileResp.msg && downloadProductFileResp.msg)
        }
    }, [downloadProductFileResp])

    useEffect(() => {

        if(downloadProductMappingFileByStatusResp && downloadProductMappingFileByStatusResp !== null && downloadProductMappingFileByStatusResp.st) {
            setOpenDialog(true)
            setDialogTitle("File Download Confirmation")
            setDialogBody(`Do you want to download ${downloadType} Product Code Mappings for selected File? Please confirm!`)
        } else if(downloadProductMappingFileByStatusResp && downloadProductMappingFileByStatusResp !== null && downloadProductMappingFileByStatusResp.st){
            setOpenSnackbar(true)
            setSnackBarMsg(downloadProductMappingFileByStatusResp.msg && downloadProductMappingFileByStatusResp.msg)
        }
    }, [downloadProductMappingFileByStatusResp])

    useEffect(() => {

        if(uploadProductFileResp && uploadProductFileResp !== null && uploadProductFileResp.st) {
            setDownloadType("UPLOAD")
            setOpenDialog(true)
            setDialogTitle("File Upload Confirmation")
            setDialogBody(`File uploaded successfully. Do you want to upload more file? Please confirm!`)
        } else if(uploadProductFileResp && uploadProductFileResp !== null && !uploadProductFileResp.st){
            setOpenSnackbar(true)
            setSnackBarMsg(uploadProductFileResp.msg && uploadProductFileResp.msg)
        }
    }, [uploadProductFileResp])

    useEffect(() => {

        if(deleteProductMappingResp && deleteProductMappingResp !== null && deleteProductMappingResp.st) {
            setOpenSnackbar(true)
            setSnackBarMsg("Selected mappings deleted successfully!")
        } else if(deleteProductMappingResp && deleteProductMappingResp !== null && !deleteProductMappingResp.st) {
            setOpenSnackbar(true)
            setSnackBarMsg(deleteProductMappingResp.msg && deleteProductMappingResp.msg)
        } 
    }, [deleteProductMappingResp])

    const onChangeView = async (inpval, row, downloadType) => {
        if(inpval === "download") {
            setDownloadType(downloadType)
            row && row.fileName && setFileName(row.fileName)
            props.downloadProductMappingFileByStatus({
                merchantPCodeFileUploadId: row.id,
                status: downloadType === "ALL" ? "" : downloadType
            })
        } else {
            setCurrentForm(inpval)

            if(inpval === 'create') {

                (countryList === null || countryList.length === 0) && props.fetchCountryDetails({})
                props.clearBrandDetail()
                props.resetMerchantMetadata()
            } else {
                props.fetchProductMappingFilesDetail({
                    pageNo: 1,
                    pageSize: 10,
                })
            }
        }
    }

    const handlePageNo = (val) => {
        setPage(val)
        props.fetchProductMappingFilesDetail({
            pageNo: val, pageSize: pageSize,
        });
    }

    const handleDownProductFileChange = (key, value) => {

        let updatedDownlFileState = {...downloadFileState}
        if(key === "country") {
            updatedDownlFileState = {
                ...updatedDownlFileState,
                country: value[1],
                isoCountryCodeNum: value[0],
                merchant: "",
                merchantId: "",
                brandIds: [],
                brands: [],
                allProducts: false
            }

            props.fetchBrandDetails({
                "isoCountryCodeNum": value[0], "status":"ACTIVE", 
                "pageNo":1, "pageSize":1000, "sortBy":"name", "sortDirection":"ASC"
            })
            props.fetchMerchantMetadata({
                "isoCountryCodeNum": value[0], "status":"ACTIVE"
            })
        } else if(key === "merchant") {
            updatedDownlFileState = {
                ...updatedDownlFileState,
                merchant: value[1],
                merchantId: value[0],
            }
        } else if(key === "allProducts") {
            updatedDownlFileState = {
                ...updatedDownlFileState,
                allProducts: value,
                merchant: "",
                merchantId: "",
            }
        } else {
            updatedDownlFileState = {
                ...updatedDownlFileState,
                [key]: value
            }
        }

        setDownloadFileState({...updatedDownlFileState})
    }

    const submitProductFileDownload = () => {

        const tempBrandIds = downloadFileState && downloadFileState.brands && downloadFileState.brands.length > 0 ?
            downloadFileState.brands.map(name => {
                const index = brandArr.indexOf(name)
                if(index !== -1) {
                    return brandList[index]["id"]
                } else {
                    return null
                }
            }) : []
        const reqPayload = {
            isoCountryCodeNum: downloadFileState.isoCountryCodeNum,
            brandIds: tempBrandIds,
            merchantId: downloadFileState.merchantId && downloadFileState.merchantId !== "" ? downloadFileState.merchantId : ""
        }

        if(downloadFileState.mode === "CREATE") {
            props.downloadProductFileToCreateMapping(reqPayload)
        } else {
            props.downloadProductFileToUpdateMapping(reqPayload)
        }
    }

    const handleUploadProductFileChange = (key, value) => {

        let updatedUplodFileState = {...uploadFileState}
        if(key === "country") {
            updatedUplodFileState = {
                ...updatedUplodFileState,
                country: value[1],
                isoCountryCodeNum: value[0],
                merchantId: "",
                merchant: "",
                file: null
            }

            props.fetchMerchantMetadata({
                "isoCountryCodeNum": value[0], "status":"ACTIVE"
            })
        } else if(key === "merchant") {
            updatedUplodFileState = {
                ...updatedUplodFileState,
                merchant: value[1],
                merchantId: value[0],
            }
        } else if(key === "upload") {

            if(value && value.length > 0) {
                updatedUplodFileState = {
                    ...updatedUplodFileState,
                    file: value[0],
                }
            }
        } else {
            updatedUplodFileState = {
                ...updatedUplodFileState,
                [key]: value,
            }
        }

        setUploadFileState({...updatedUplodFileState})
    }

    const submitProductFileUpload = async () => {
        
        if(uploadFileState.mode === "CREATE") {
            await props.uploadProductFileCreateMode(uploadFileState)
        } else {
            await props.uploadProductFileUpdateMode(uploadFileState)
        }
    }

    const handleDialogAction = (action) => {

        if(action === 'confirm') {
            if(currentForm !== "list") {
                if(downloadType === "UPLOAD") {
                    // Upload file case
                    setUploadFileState(uploadFileObj)
                } else if(downloadType === "DOWNLOAD") {
                    // Download file case
                    const fileName = getProductFileName()
                    downloadFileFromBase64Str(downloadProductFileResp.base64, fileName)
                    setDownloadFileState(downloadFileObj)
                } else {
                    // Delete mappings case
                    const mappingIds = selectedMappings && selectedMappings.length > 0 ?
                        selectedMappings.map(obj => obj.id) 
                        : []
                    props.deleteProductMappings({
                        productMappingIds: mappingIds
                    })
                }
                
            } else {
                //Download case on list page
                const tempFileName = fileName.replace(".xlsx", `_${downloadType}.xlsx`)
                downloadFileFromBase64Str(downloadProductMappingFileByStatusResp.base64, tempFileName)
                onChangeView("list")
            }
        } else {
            setUploadFileState(uploadFileObj)
            setDownloadFileState(downloadFileObj)
            onChangeView("list")
        }
        setDownloadType("")
        setOpenDialog(false)
        setDialogTitle("")
        setDialogBody("")
        props.resetDownloadProductFileResp()
        props.resetUploadProductFileResp()
        props.resetDownloadMappingFileByStatusResp()
    }
    
    const handleDeletePageChange = (key, value) => {
        let updatedFilterState = {...deleteFilterState}
        if(key === "country") {
            updatedFilterState = {
                ...updatedFilterState,
                country: value[1],
                isoCountryCodeNum: value[0],
                merchantId: "",
                merchant: "",
                brand: "",
                brandId: ""
            }

            setSelectedMappings([])
            props.resetProductMappingListResp()

            props.fetchMerchantMetadata({
                "isoCountryCodeNum": value[0], "status":"ACTIVE"
            })
            props.fetchBrandDetails({
                "isoCountryCodeNum": value[0], "status":"ACTIVE", 
                "pageNo":1, "pageSize":1000, "sortBy":"name", "sortDirection":"ASC"
            })
        } else if(key === "merchant") {
            updatedFilterState = {
                ...updatedFilterState,
                merchant: value[1],
                merchantId: value[0],
            }
            setSelectedMappings([])
            props.resetProductMappingListResp()
        } else if(key === "brand") {

            updatedFilterState = {
                ...updatedFilterState,
                brand: value[1],
                brandId: value[0],
            }
            setSelectedMappings([])
            props.resetProductMappingListResp()
        } else if(key === "checkbox") {
            const found = selectedMappings.some(el => el.id === value.id);
            let tempMappings = [...selectedMappings]
            
            if(!found) {
                tempMappings = [...selectedMappings, value]
            } else {
                tempMappings = tempMappings.filter((obj, indx) => {
                    return obj.id !== value.id
                })
            }
            setSelectedMappings(tempMappings)
      
        } else if(key === "selectAll") {
            if(selectedMappings.length !== value.length) {
                setSelectedMappings([...value])
            } else {
                setSelectedMappings([])
            }
      
        } else if(key === "remove") {
            const tempMappings = selectedMappings.filter((obj) => {
              return value !== obj.id
            })
            setSelectedMappings(tempMappings)
      
        } else if(key === "delete") {
            setOpenDialog(true)
            setDialogTitle("Delete Mapping Confirmation")
            setDialogBody("Do you want to delete the selected mappings? Please confirm!")
        } else {
            updatedFilterState = {
                ...updatedFilterState,
                [key]: value,
            }
        }

        setDeleteFilterState({...updatedFilterState})
    }

    const applyDeleteFilter = () => {
        setSelectedMappings([])
        props.fetchProductMappings({
            isoCountryCodeNum: deleteFilterState.isoCountryCodeNum,
            merchantId: deleteFilterState.merchantId,
            brandId: deleteFilterState.brandId,
            merchantProductCode: deleteFilterState.merchantProductCode, 
            pageNo: 1,
            pageSize: 1000,
        })
    }

    const handleSnackBarAction = () => {
        setOpenSnackbar(false)
        setSnackBarMsg(null)
        //reset responses
        setDeleteFilterState(deleteFilterObj)
        setSelectedMappings([])
        props.resetDeleteMappingResp()
        props.resetProductMappingListResp()
        props.resetUploadProductFileResp()
        props.resetDownloadProductFileResp()
        props.resetDownloadMappingFileByStatusResp()

    }

    return (
        <>
            <BasePageToolbar 
                currentForm={currentForm} create={permissions.includes("71") || permissions.includes("72") || permissions.includes("73")} onChangeView={onChangeView} 
                title={currentForm === 'list' ? "Product Code Mapping Details" : currentForm === 'mapping' ? "Product Code Mapping" : "Product Code Mapping"}
            ></BasePageToolbar>
            <BasePageContainer>
                {currentForm === 'list' ?
                <Grid container className={classes.container}>
                    <TableComponent
                        loading={loader}
                        headCells={permissions.includes("71") ? headCells : headCells.filter(headCell => headCell.id !== "action")}
                        rows={mappingFileList}
                        onChangeView={onChangeView}
                        totalRecord={totalRecords}
                        handlePageNo={handlePageNo}
                        productMapping={permissions.includes("71")}
                    />
                </Grid>
                : <Grid container className={classes.formContainer}>
                    <CreateMapping
                        countryList={countryList}
                        brandArr={brandArr}
                        brandArrObj={brandArrObj}
                        merchantMetadata={merchantMetadata}
                        downloadFileState={downloadFileState}
                        handleDownProductFileChange={handleDownProductFileChange}
                        submitProductFileDownload={submitProductFileDownload}
                        disableDownloadProductFile={!disableDownloadProductFile}
                        uploadFileState={uploadFileState}
                        handleUploadProductFileChange={handleUploadProductFileChange}
                        submitProductFileUpload={submitProductFileUpload}
                        disableUploadProductFile={!disableUploadProductFile}
                        deleteFilterState={deleteFilterState}
                        handleDeletePageChange={handleDeletePageChange}
                        applyDeleteFilter={applyDeleteFilter}
                        productMappingList={productMappingList}
                        selectedMappings={selectedMappings}
                        permissions={permissions}
                    />
                </Grid>
                }
                <DialogBox
                    openDialog={openDialog}
                    handleDialogAction={handleDialogAction}
                    title={dialogTitle} 
                    body={dialogBody}
                />
                <SnackbarToast
                    openSnackbar={openSnackbar}
                    snackbarMsg={snackbarMsg}
                    handleSnackBarAction={handleSnackBarAction}
                    type={"info"}
                />
            </BasePageContainer>
        </>
    )
}

const useStyles = tss.create({
    formContainer: {
        paddingTop: theme.spacing(0),
    },
    container: {
        paddingTop: theme.spacing(2),
    },
    pagination: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
});

function mapDispatchToProps(dispatch) {

    return {
        dispatch,
        ...bindActionCreators({
            fetchCountryDetails, fetchBrandDetails, clearBrandDetail,
            fetchMerchantMetadata, resetMerchantMetadata,
            downloadProductFileToCreateMapping, downloadProductFileToUpdateMapping,
            resetDownloadProductFileResp,
            uploadProductFileCreateMode, uploadProductFileUpdateMode, resetUploadProductFileResp,
            fetchProductMappingFilesDetail, downloadProductMappingFileByStatus,
            fetchProductMappings, deleteProductMappings, resetDeleteMappingResp,
            resetProductMappingListResp, resetDownloadMappingFileByStatusResp
        }, dispatch)
    }
}

function mapStateToProps(state) {
    return {
        countryList: state.ProductReducer.countryDetails && state.ProductReducer.countryDetails !== null 
            && state.ProductReducer.countryDetails.countryList ? state.ProductReducer.countryDetails.countryList : [],
        brandList: state.ProductReducer.brandDetails && state.ProductReducer.brandDetails !== null && state.ProductReducer.brandDetails.brands ? 
            state.ProductReducer.brandDetails.brands : [],
        merchantMetadata: state.MerchantReducer.merchantMetadata !== null &&
            state.MerchantReducer.merchantMetadata.merchantList ?
                state.MerchantReducer.merchantMetadata.merchantList : [],
        loader: state.ProductCodeMappingReducer.loader,
        downloadProductFileResp: state.ProductCodeMappingReducer.downloadProductFileResp,
        uploadProductFileResp: state.ProductCodeMappingReducer.uploadProductFileResp,
        mappingFileList: state.ProductCodeMappingReducer.mappingFileList !== null && state.ProductCodeMappingReducer.mappingFileList.merchantPCodeFileUploadList ? 
            state.ProductCodeMappingReducer.mappingFileList.merchantPCodeFileUploadList : [],
        totalRecords: state.ProductCodeMappingReducer.mappingFileList !== null && state.ProductCodeMappingReducer.mappingFileList.totalRecord ? 
            state.ProductCodeMappingReducer.mappingFileList.totalRecord : 0,
        downloadProductMappingFileByStatusResp: state.ProductCodeMappingReducer.downloadProductMappingFileByStatusResp,
        deleteProductMappingResp: state.ProductCodeMappingReducer.deleteProductMappingResp,
        productMappingList: state.ProductCodeMappingReducer.productMappingList !== null && state.ProductCodeMappingReducer.productMappingList.productMappingList ? 
            state.ProductCodeMappingReducer.productMappingList.productMappingList : [],
        mappingCount: state.ProductCodeMappingReducer.mappingFileList !== null && state.ProductCodeMappingReducer.mappingFileList.totalRecord ? 
            state.ProductCodeMappingReducer.mappingFileList.totalRecord : 0,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductCodeMapping)