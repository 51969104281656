import React, { useState, useEffect } from 'react'
import {connect} from "react-redux";
import Grid from '@mui/material/Grid';
import { bindActionCreators } from "redux";
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Snackbar, Dialog, DialogActions,
  DialogTitle, DialogContentText, DialogContent, Button  } from '@mui/material'
import { tss } from "tss-react";
import theme from '../../_theme'

import TableComponent from "../table/index";
import BasePageToolbar from '../../_common/basePageToolbar';
import BasePageContainer from '../../_common/basePageContainer';
import CreateEditViewMerchant from "./createEditViewMerchant/index";
import {fetchCountryDetails} from "../../manageScheme/actions/index";
import {fetchPSPMetadata, resetPSPMetadata} from "../psp/actions/index";
import {EmailRegex, SpecialCharRegex1, addRegex, nameRegExp, SpecialCharRegex, descrRegex, urlRegex, numbRegex} from "../../shared/utility";
import {
  fetchMerchantDetails, createMerchant, 
  resetCreateMerResp, resetProgramPickList, 
  fetchMerchantMetadata
} from "./actions/index";
import {fetchPspIntegrationModeMasterData} from '../pspAcquirer/actions/index';

const headCells = [
  { id: 'id', type: 'text', numeric: false, disablePadding: false, label: 'Merchant Obj Id' },
  { id: 'merchantId', type: 'text', numeric: false, disablePadding: false, label: 'Merchant Id' },
  { id: 'name', type: 'text', numeric: false, disablePadding: false, label: 'Merchant Name' },
  { id: 'displayName', type: 'text', numeric: false, disablePadding: false, label: 'Display Name' },
  { id: 'legalName', type: 'text', numeric: false, disablePadding: false, label: 'Legal Name' },
  { id: 'description', type: 'description', numeric: true, disablePadding: false, label: 'Description' },
  // { id: 'pspName', type: 'text', numeric: false, disablePadding: false, label: 'PSP Name' },
  { id: 'mcc', type: 'text', numeric: false, disablePadding: false, label: 'Mcc Code' },
  // { id: 'merchantUniqueId', type: 'text', numeric: false, disablePadding: false, label: 'External Id' },
  { id: 'status', type: 'text', numeric: true, disablePadding: false, label: 'Status' },
  { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];
const MerchantObj = {
  "merchantId": null,
  "name": "",
  "displayName": "",
  "legalName": "",
  "description": "",
  "email": "",
  "website": "",
  "countryName" : "",
  "address": { 
    "address1": "", "address2": "",
    "address3": "", "state": "",
    "city": "", "postalCode": ""
  },
  // "merchantUniqueId": "",
  "mcc": "",
  "tcc": "",
  "isoCountryCodeNum": "",
  "contactPersonDetail": {
      "name": "",
      "phoneNo": "",
      "mobileNo": "",
      "emailId": ""
  },
  // "pspName": "",
  // "pspId": "",
  "businessRegistrationNumber": "",
  "status": "ACTIVE",
  "programDetail": {
    "cibDetail": {
        "isEnabled": "0",
        "isDefault": "0",
        "programIds": []
    },
    "mibDetail": {
      "isEnabled": "0",
      "isDefault": "0",
      "programIds": []
    },
    "brandDetail": {
      "isEnabled": "0",
      "programIds": []
    },
  },
  "pspObjList": [],
}

const filterCells = [
  { id: 'id', type: 'text', label: 'Merchant Obj Id' },
  { id: 'isoCountryCodeNum', type: 'select', label: 'Country' },
  { id: 'pspId', type: 'select', label: 'Psp Name' },
  { id: 'name', type: 'select', label: 'Merchant Name' },
  { id: 'city', type: 'text', label: 'City' },
  { id: 'state', type: 'text', label: 'State' },
];

const filterobj = {
  id: "",
  city: "",
  name: "",
  state: "",
  pspId: "",
  postalCode: "",
  isoCountryCodeNum: "",
  pageNo: 1,
  pageSize: 10,
  sortBy:"createdOn",
  sortDirection:"DESC"
}

const sortArr = [
  {key:"createdOn", label: "Create Date"},
  {key:"countryName", label: "Country Name"},
  // {key:"pspName", label: "Psp Name"},
  {key:"name", label: "Merchant Name"},
  {key:"displayName", label: "Display Name"},
  {key:"legalName", label: "Legal Name"},
  {key:"status", label: "Status"},
]

const Merchant = (props) => {
  const {classes} = useStyles();
  const {
    merchantList, countryDetails, pspMetaData, createMerRes, merchantLoader, merchantListCount, 
    pspIntegrationModeList, merchantMetadata
  } = props;
  const [clientRow, setClientRow] = useState(null);
  const [snackbarMsg, setSnackBarMsg] = useState(null);
  const [currentForm, setCurrentForm] = useState("list");
  const [openSnackbar, SetOpenSnackbar] = useState(false);
  const [responseId, setResponseId] = useState("");
  const [openPopover, setopenPopover] = useState(false);
  const [filterState, setFilterState] = useState(filterobj)
  const [merchantState, setMerchantState] = useState(MerchantObj)
  const currentPage = localStorage.getItem('currentPage')
  const [filterData, setFilterData] = useState([]);
  const [pageNo, setPage] = useState(1)
  const [sortName, setsortName] = useState('createdOn')
  const [sortDir, setsortDir] = useState('DESC');
  const [searchValue, setSearchValue] = useState("");
  const [compInitaited, setCompInitiated] = useState(false);
  const [selectedPSPs, setSelectedPSPs] = useState([]);

  let permissions = localStorage.getItem("permissions")
  permissions = permissions && permissions !== null ? JSON.parse(permissions) : []
  const userid = localStorage.getItem("userid")

  const nextDisableToken = currentForm !== "view" && merchantState && merchantState.countryName !== "" &&
    // merchantState.pspName !== "" && 
    merchantState.name !== "" && merchantState.displayName !== "" &&
    nameRegExp.test(merchantState.displayName) && nameRegExp.test(merchantState.name) && //SpecialCharRegex.test(merchantState.legalName) &&
    (merchantState.businessRegistrationNumber === "" ||
      (merchantState.businessRegistrationNumber !== "" && merchantState.businessRegistrationNumber !== null && SpecialCharRegex.test(merchantState.businessRegistrationNumber))) && 
    // merchantState.description !== "" && merchantState.description !== null && descrRegex.test(merchantState.description) && 
    (merchantState.description === "" || descrRegex.test(merchantState.description)) &&
    merchantState.mcc && merchantState.mcc !== "" && merchantState.mcc !== null && 
    (merchantState.email === "" || (merchantState.email !== "" && EmailRegex.test(merchantState.email))) &&
    (merchantState.website === "" || (merchantState.website !== "" && urlRegex.test(merchantState.website))) &&
    // (merchantState.merchantUniqueId !== "" && merchantState.merchantUniqueId !== null && SpecialCharRegex.test(merchantState.merchantUniqueId)) &&
    // (merchantState.address['address1'] === "" || merchantState.address['address1'] === null || 
      (merchantState.address['address1'] !== "" && merchantState.address['address1'] !== null && addRegex.test(merchantState.address['address1'])) && merchantState.address['address1'].length <= 50 &&
      // ) && 
    (merchantState.address['address2'] === "" || merchantState.address['address2'] === null || 
      (merchantState.address['address2'] !== "" && merchantState.address['address2'] !== null && addRegex.test(merchantState.address['address2']))) && 
    (merchantState.address['address3'] === "" || merchantState.address['address3'] === null || 
      (merchantState.address['address3'] !== "" && merchantState.address['address3'] !== null && addRegex.test(merchantState.address['address3']))) &&
    (merchantState.address['state'] === "" || merchantState.address['state'] === null || 
      (merchantState.address['state'] !== null && merchantState.address['state'] !== "" && SpecialCharRegex1.test(merchantState.address['state']))) &&
    (merchantState.address['city'] === "" || merchantState.address['city'] === null || 
      (merchantState.address['city'] !== null && merchantState.address['city'] !== "" && SpecialCharRegex1.test(merchantState.address['city']))) &&
    (merchantState.address['postalCode'] !== null && numbRegex.test(merchantState.address['postalCode'])) &&
    (merchantState.contactPersonDetail['name'] === "" || merchantState.contactPersonDetail['name'] === null || 
      (merchantState.contactPersonDetail['name'] !== null && merchantState.contactPersonDetail['name'] !== "" && 
        SpecialCharRegex1.test(merchantState.contactPersonDetail['name']))) && 
    (!merchantState.contactPersonDetail['phoneNo'] || numbRegex.test(merchantState.contactPersonDetail['phoneNo'])) &&
    (!merchantState.contactPersonDetail['mobileNo'] || numbRegex.test(merchantState.contactPersonDetail['mobileNo'])) &&
    (merchantState.contactPersonDetail['emailId'] === "" || merchantState.contactPersonDetail['emailId'] === null || 
      (merchantState.contactPersonDetail['emailId'] !== null && merchantState.contactPersonDetail['emailId'] !== "" && 
      EmailRegex.test(merchantState.contactPersonDetail['emailId']))) && merchantState.programDetail && 
    (merchantState.programDetail.cibDetail.isEnabled === "0" || merchantState.programDetail.cibDetail.isEnabled === 0 || 
      ((merchantState.programDetail.cibDetail.isEnabled === "1" || merchantState.programDetail.cibDetail.isEnabled === 1) 
      && (merchantState.programDetail.cibDetail.isDefault === "1" || merchantState.programDetail.cibDetail.isDefault === 1)) || 
      ((merchantState.programDetail.cibDetail.isEnabled === "1" || merchantState.programDetail.cibDetail.isEnabled === 1) 
        && (merchantState.programDetail.cibDetail.isDefault === "0" || merchantState.programDetail.cibDetail.isDefault === 0) && 
        (merchantState.programDetail.cibDetail.programIds && merchantState.programDetail.cibDetail.programIds.length > 0))) && 
    (merchantState.programDetail.mibDetail.isEnabled === "0" || merchantState.programDetail.mibDetail.isEnabled === 0 || 
      ((merchantState.programDetail.mibDetail.isEnabled === "1" || merchantState.programDetail.mibDetail.isEnabled === 1) 
      && (merchantState.programDetail.mibDetail.isDefault === "1" || merchantState.programDetail.mibDetail.isDefault === 1)) || 
      ((merchantState.programDetail.mibDetail.isEnabled === "1" || merchantState.programDetail.mibDetail.isEnabled === 1) && 
        (merchantState.programDetail.mibDetail.isDefault === "0" || merchantState.programDetail.mibDetail.isDefault === 0) 
        && (merchantState.programDetail.mibDetail.programIds && merchantState.programDetail.mibDetail.programIds.length > 0))) &&
    (merchantState.programDetail.brandDetail.isEnabled === "0" || merchantState.programDetail.brandDetail.isEnabled === 0 || 
      ((merchantState.programDetail.brandDetail.isEnabled === "1" || merchantState.programDetail.brandDetail.isEnabled === 1) 
      && (merchantState.programDetail.brandDetail.programIds && merchantState.programDetail.brandDetail.programIds.length > 0))) &&
      (merchantState.legalName !== "" && merchantState.legalName !== null && nameRegExp.test(merchantState.legalName)) &&
    merchantState.address['state'] !== "" && merchantState.address['city'] !== ""

  const merchantArray = merchantList && merchantList !== null ? 
    merchantList.map(obj => {

        let schemeObj = {
          ...obj,
          searchColumn: headCells.map((cell, index) => 
            cell.id ==="action" ? "" : obj[cell.id] && obj[cell.id]
          ).join(" ")
        }
        return schemeObj
    }) : []

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  });
  
  const onChangeView = async (inpval, row) => {
    setCurrentForm(inpval);
    if(inpval === "view") {
      setClientRow(row)
      //fetch PSP details
      props.fetchPSPMetadata({
        "isoCountryCodeNum": row.isoCountryCodeNum,
        "status": "ACTIVE"
      })
    } else if(inpval === "edit") {
      const tempPSPList = row.pspObjList && row.pspObjList.length > 0 ?
          row.pspObjList.map((obj) => obj.pspObjId) : []
      setSelectedPSPs(tempPSPList)
      setMerchantState({...MerchantObj, ...row})

      //fetch details for edit drop down
      handleMetaDataCall(row)
      props.fetchPSPMetadata({
        "isoCountryCodeNum": row.isoCountryCodeNum,
        "status": "ACTIVE"
      })
      pspIntegrationModeList && pspIntegrationModeList.length === 0 && props.fetchPspIntegrationModeMasterData()
      props.fetchMerchantMetadata({})

    } else if(inpval === "create") {
      setSelectedPSPs([])
      setMerchantState(MerchantObj)
      props.resetPSPMetadata();
      countryDetails && countryDetails.length === 0 && props.fetchCountryDetails({})
      pspIntegrationModeList && pspIntegrationModeList.length === 0 && props.fetchPspIntegrationModeMasterData()
      props.fetchMerchantMetadata({})
    } else {
      
      await props.resetProgramPickList()
      await props.fetchMerchantDetails({...filterState,
        pageNo: 1, sortBy: sortName, sortDirection: sortDir
      });
    }
  }
  
  useEffect(() => {
    if(currentPage && currentPage !== null && currentPage !== undefined && currentPage === "/merchant" && compInitaited){
      setCurrentForm('list')
      setFilterState({...filterobj});
      setPage(1);
      setsortDir("DESC");
      setsortName("createdOn")
      props.fetchMerchantDetails({
        pageNo: 1,
        pageSize: 10,
        sortBy:"createdOn",
        sortDirection:"DESC"
      })
      props.resetProgramPickList()
      localStorage.removeItem('currentPage')
    }
  }, [currentPage])

  const handleMetaDataCall = (row) => {
    countryDetails && countryDetails.length === 0 && props.fetchCountryDetails({})

    //call psp merchant and store API here  ---------------------------->>>>>>>
    // props.fetchPSPMetadata({
    //   "isoCountryCodeNum": row.isoCountryCodeNum ? row.isoCountryCodeNum : ""
    // })
  }

  useEffect(() => {
    const handleInitialCall = async () => {
      await setCompInitiated(true);
      await props.fetchMerchantDetails({...filterState,
        pageNo: pageNo, sortBy: sortName, sortDirection: sortDir
      })
      await localStorage.removeItem('currentPage')
    }
    handleInitialCall();
    
    return () => {
      //add all list reset API here
      props.resetPSPMetadata()
      //reset merchant response
      props.resetCreateMerResp()
    }
  }, [])
   
  const handleFilterStateChange = (key, value) => {
    let tempFilter = {
        ...filterState,
        [key]: value
    }
    if(key === "isoCountryCodeNum") {
      tempFilter = {
        ...tempFilter,
        pspId: "",
        name: ""
      }
    } else if(key === "pspId") {
      tempFilter = {
        ...tempFilter,
        name: ""
      }
    }
    setFilterState(tempFilter);
  }

  const resetFilter = () => {
    setFilterState(filterobj);
    setPage(1);
    setsortDir("DESC");
    setsortName("createdOn")
    props.fetchMerchantDetails({
      pageNo: 1,
      pageSize: 10,
      sortBy:"createdOn",
      sortDirection:"DESC"
    })
  }

  const FilterApply = () => {
    setPage(1)
    props.fetchMerchantDetails({ ...filterState, pageNo: 1, sortBy: sortName, sortDirection: sortDir})
    setFilterState({...filterState});
  }

  const handleSnackBarAction = () => {
    SetOpenSnackbar(false)
    setSnackBarMsg(null)
    //reset merchant response
    props.resetCreateMerResp()
  }

  useEffect(() => {
    if(createMerRes && createMerRes !== null && createMerRes.st) {
      setResponseId(createMerRes.id)
      if(currentForm === "create" ) {
        setopenPopover(true)
      } else {
        SetOpenSnackbar(true)
        setSnackBarMsg(`Merchant Modified Successfully!`)
        onChangeView("list")
      }
      //reset merchant response
      props.resetCreateMerResp()
    } else if(createMerRes && createMerRes !== null && !createMerRes.st) {
      SetOpenSnackbar(true)
      setSnackBarMsg(createMerRes.msg && createMerRes.msg)
      // onChangeView("list")
    }
  }, [createMerRes])

  const handleInputChange = (key, value) => {
    
    let updatedMerchantState = {...merchantState};
    if(key === "country") {
      
      // adding country details & reseting other fields
      updatedMerchantState = {
        ...updatedMerchantState,
        pspName: "",
        pspId: "",
        countryName: value[1],
        isoCountryCodeNum: value[0],
      }

      //fetch PSP details
      props.fetchPSPMetadata({
        "isoCountryCodeNum": value[0],
        "status": "ACTIVE"
      })

    } 
    // else if(key === "psp") {

    //   //update psp and reset merchant and store
    //   updatedMerchantState = {
    //     ...updatedMerchantState,
    //     pspName: value[1],
    //     pspId: value[0],
    //   }

    // } 
    else if(key === "user") {
      //setting user details
      updatedMerchantState = {
        ...updatedMerchantState,
        contactPersonDetail: {
          ...updatedMerchantState['contactPersonDetail'],
          ...value
        }
      }

    } else if(key === "address") {
      //setting address details
      updatedMerchantState = {
        ...updatedMerchantState,
        address: {
          ...updatedMerchantState['address'],
          ...value
        }
      }

    } else {
      //update other details
      if(key === "isCibEnabled") {
        updatedMerchantState = {
          ...updatedMerchantState,
          programDetail: {
            ...updatedMerchantState.programDetail,
            cibDetail: {
              isEnabled: value[0],
              isDefault: value[0] === "0" ? "0" : "1",
              programIds: value[0] === "0" ? [] : updatedMerchantState.programDetail.cibDetail.programIds,
            }
          }
        }
      } else if(key === "isDefaultCib") {
        updatedMerchantState = {
          ...updatedMerchantState,
          programDetail: {
            ...updatedMerchantState.programDetail,
            cibDetail: {
              ...updatedMerchantState.programDetail.cibDetail,
              isDefault: value[0],
              programIds: value[0] === "1" ? [] : updatedMerchantState.programDetail.cibDetail.programIds
            }
          }
        }
      } else if(key === "isMibEnabled") {
        updatedMerchantState = {
          ...updatedMerchantState,
          programDetail: {
            ...updatedMerchantState.programDetail,
            mibDetail: {
              isEnabled: value[0],
              isDefault: value[0] === "0" ? "0" : "1",
              programIds: value[0] === "0" ? [] : updatedMerchantState.programDetail.mibDetail.programIds,
            }
          }
        }
      } else if(key === "isDefaultMib") {
        updatedMerchantState = {
          ...updatedMerchantState,
          programDetail: {
            ...updatedMerchantState.programDetail,
            mibDetail: {
              ...updatedMerchantState.programDetail.mibDetail,
              isDefault: value[0],
              programIds: value[0] === "1" ? [] : updatedMerchantState.programDetail.mibDetail.programIds
            }
          }
        }
      } else if(key === "isBrandEnabled") {
        updatedMerchantState = {
          ...updatedMerchantState,
          programDetail: {
            ...updatedMerchantState.programDetail,
            brandDetail: {
              isEnabled: value[0],
              programIds: value[0] === "0" ? [] : updatedMerchantState.programDetail.brandDetail.programIds,
            }
          }
        }
      } else if(key === "cibProgramId") {
        updatedMerchantState = {
          ...updatedMerchantState,
          programDetail: {
            ...updatedMerchantState.programDetail,
            cibDetail: {
              ...updatedMerchantState.programDetail.cibDetail,
              programIds: value
            }
          }
        }
      } else if(key === "mibProgramId") {
        updatedMerchantState = {
          ...updatedMerchantState,
          programDetail: {
            ...updatedMerchantState.programDetail,
            mibDetail: {
              ...updatedMerchantState.programDetail.mibDetail,
              programIds: value
            }
          }
        }
      } else if(key === "brandProgramId") {
        updatedMerchantState = {
          ...updatedMerchantState,
          programDetail: {
            ...updatedMerchantState.programDetail,
            brandDetail: {
              ...updatedMerchantState.programDetail.brandDetail,
              programIds: value[0]
            }
          }
        }
      } else {
        updatedMerchantState = {
          ...updatedMerchantState,
          [key]: value[0]
        }
      }

    }
    setMerchantState(updatedMerchantState)
  }

  const handleProgIdReset = (key, value) => {
    let updatedMerchantState = {...merchantState};

    key.map((val, i) => {

      if(val === "cibProgramId") {
        updatedMerchantState = {
          ...updatedMerchantState,
          programDetail: {
            ...updatedMerchantState.programDetail,
            cibDetail: {
              ...updatedMerchantState.programDetail.cibDetail,
              programIds: value[i]
            }
          }
        }
        return updatedMerchantState

      } else if(val === "mibProgramId") {
        updatedMerchantState = {
          ...updatedMerchantState,
          programDetail: {
            ...updatedMerchantState.programDetail,
            mibDetail: {
              ...updatedMerchantState.programDetail.mibDetail,
              programIds: value[i]
            }
          }
        }
        return updatedMerchantState

      } else if(val === "brandProgramId") {
        updatedMerchantState = {
          ...updatedMerchantState,
          programDetail: {
            ...updatedMerchantState.programDetail,
            brandDetail: {
              ...updatedMerchantState.programDetail.brandDetail,
              programIds: value[i]
            }
          }
        }
        return updatedMerchantState
      }
      return null;
    })
    setMerchantState(updatedMerchantState)
  }

  const handleMerchantCreateUpdate = () => {
    const tempList = merchantState.pspObjList;
    var found = false
    for(var i = 0; i < tempList.length; i++) {
      if(tempList[i].pspIntegrationMode === "BOTH" || tempList[i].pspIntegrationMode === "ONLINE") {
        found = true;
        break;
      }
    }
    if((merchantState.businessRegistrationNumber && merchantState.businessRegistrationNumber !== "") || !found) {
      SetOpenSnackbar(false)
      setSnackBarMsg(null)
      let req = {
        "status": "ACTIVE",
        "pspMerchantIdentifier": "",
        // "merchantUniqueId": "",
        "isoCountryCode": "",
        "phoneList": "",
        "emailList": "",
        "languages" : [{"code": ""},{"code": ""}],
        "mobileList": "",
        "merchantParameter": {
          "key": "value",
          "key2": "value2"
        },
        ...merchantState,
        "userId": JSON.parse(userid),
      }
      props.createMerchant(req)
      setFilterState(filterobj);
      setSearchValue("")
    } else {
      SetOpenSnackbar(true)
      setSnackBarMsg("Business Registration Number is mandatory! If you have selected PSP Integration Mode as ONLINE/BOTH for atleast one PSP.")
    }
  }

  const handleDialogAction = async (type) => {
    setopenPopover(false)
    setMerchantState(MerchantObj)
    if(type === "exit" ){
      onChangeView('list')
    } else {
      await onChangeView('list')
      await onChangeView('create')
    }
  }

  const handleSetFilterData = (filterArray, searchValue) => {
    setFilterData(filterArray)
    setSearchValue(searchValue)
  }

  const handlePageNo = (val) => {
    setPage(val)
    props.fetchMerchantDetails({...filterState,
      pageNo: val, sortBy: sortName, sortDirection: sortDir
    });
  }

  const handleSortDir = (value) => {
    setsortDir(value)
    setPage(1)
  }

  const handleSortName = (value) => {
    setsortName(value)
    setPage(1)
  }
  
  const handleAddNewMappedPSP = (pspObjId, pspIntegrationMode, merchantUniqueId) => {
    const tempPSPObj = {
      pspObjId: pspObjId,
      pspIntegrationMode: pspIntegrationMode,
      merchantUniqueId: merchantUniqueId,
    }

    let updatedMerchantState = {...merchantState};

    // Adding new PSP Obj
    updatedMerchantState = {
      ...updatedMerchantState,
      pspObjList: [
        ...updatedMerchantState.pspObjList, 
        tempPSPObj
      ]
    }
    setMerchantState(updatedMerchantState)

    // Adding new psp id in selected list
    setSelectedPSPs([...selectedPSPs, pspObjId])

  }

  const handleDeletePSP = (row) => {

    let updatedMerchantState = {...merchantState};

    // Removing deleted PSP Obj
    const tempPSPObjArr = merchantState.pspObjList && merchantState.pspObjList.length > 0 ? 
      merchantState.pspObjList.map(psp => {
        if(psp.pspObjId !== row.pspObjId) {
          return psp;
        } else 
        return null;
      })
      .filter(obj => obj !== null) : []

    updatedMerchantState = {
      ...updatedMerchantState,
      pspObjList: [
        ...tempPSPObjArr 
      ]
    }
    setMerchantState(updatedMerchantState)

    // Removing the deleted psp id from selected list
    const tempPSPIdList = selectedPSPs && selectedPSPs.length > 0 ? selectedPSPs.filter((pspId) => pspId !== row.pspObjId) : []
    setSelectedPSPs(tempPSPIdList)
  }
  
  return (
    <>
      <BasePageToolbar 
        currentForm={currentForm}  create={permissions.includes("16")} onChangeView={onChangeView} resetFilter={resetFilter}
        title={currentForm === 'list' ? "Merchant Detail" : currentForm === 'view' ? "View Merchant" : currentForm === 'edit' ? "Modify Merchant" : "Create Merchant"}
        handleFilterStateChange={handleFilterStateChange} filterState={filterState} filterCells={filterCells} FilterApply={FilterApply}
        setsortName={handleSortName} setsortDir={handleSortDir} sortDir={sortDir} sortName={sortName} sortArr={sortArr}
        arrayToFilter={merchantArray} handleSetFilterData={handleSetFilterData} isTableSearchReq={false} searchValue={searchValue}
      ></BasePageToolbar>
        <BasePageContainer>
          {currentForm === 'list' ?
            <Grid container className={classes.container}>
              <TableComponent
                sortOrder="asc"
                showEdit={permissions.includes("17")}
                sortOrderBy="id"
                activeTab={filterState}
                // rows={merchantList}
                rows={searchValue && searchValue !== "" ? filterData : merchantArray}
                headCells={headCells}
                loading={merchantLoader}
                requestType="MerchantList"
                onChangeView={onChangeView}
                totalRecord={merchantListCount}
                handlePageNo={handlePageNo}
              />
            </Grid>
            : currentForm === 'create' ?
              <Grid container className={classes.formContainer}>
                <CreateEditViewMerchant 
                  PSPList={pspMetaData}
                  currentForm={currentForm}
                  merchantState={merchantState}
                  countryDetails={countryDetails}
                  nextDisableToken={!nextDisableToken}
                  handleInputChange={handleInputChange}
                  handleMerchantCreateUpdate={handleMerchantCreateUpdate}
                  selectedPSPs={selectedPSPs}
                  pspIntegrationModeList={pspIntegrationModeList}
                  handleAddNewMappedPSP={handleAddNewMappedPSP}
                  handleDeletePSP={handleDeletePSP}
                  mappedPSPList={merchantState.pspObjList}
                  merchantMetadata={merchantMetadata}
                  handleProgIdReset={handleProgIdReset}
                />
              </Grid>
              : currentForm === 'edit' ? <Grid container className={classes.formContainer}>
                  <CreateEditViewMerchant 
                    PSPList={pspMetaData}
                    currentForm={currentForm}
                    merchantState={merchantState}
                    countryDetails={countryDetails}
                    nextDisableToken={!nextDisableToken}
                    handleInputChange={handleInputChange}
                    handleProgIdReset={handleProgIdReset}
                    handleMerchantCreateUpdate={handleMerchantCreateUpdate}
                    selectedPSPs={selectedPSPs}
                    pspIntegrationModeList={pspIntegrationModeList}
                    handleAddNewMappedPSP={handleAddNewMappedPSP}
                    handleDeletePSP={handleDeletePSP}
                    mappedPSPList={merchantState.pspObjList}
                    merchantMetadata={merchantMetadata}
                  />
                </Grid> 
                : <Grid container className={classes.formContainer}>
                    <CreateEditViewMerchant 
                      PSPList={pspMetaData}
                      currentForm={currentForm} 
                      productDetails={clientRow}
                      onChangeView={onChangeView}
                      selectedPSPs={selectedPSPs}
                      mappedPSPList={clientRow.pspObjList}
                    />
                </Grid>
          }
          <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={openSnackbar}
            autoHideDuration={4000}
            onClose={() => handleSnackBarAction()}
            action={
            <React.Fragment>
              <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
            }
          >
            <Alert onClose={() => handleSnackBarAction()} severity={
              createMerRes && createMerRes !== null && !createMerRes.st ? "info" : "success"
            }>
              {snackbarMsg}
            </Alert>
          </Snackbar>
          <Dialog
            open={openPopover}
            onClose={() => handleDialogAction("exit")}
            className={"delete_dialog"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <h2>{`Merchant ${responseId} Created Successfully!`}</h2>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`Do you want to Create new Merchant?`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className={classes.color}
                  onClick={() => handleDialogAction("create")} 
              >
                Create New
              </Button>
              <Button className={classes.color}
                  onClick={() => handleDialogAction("exit")} 
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </BasePageContainer>
    </>)
  }

const useStyles = tss.create({
  formContainer: {
    paddingTop: theme.spacing(0),
  },
  container: {
    paddingTop: theme.spacing(2),
  },
  color: {
    color: "white",
    backgroundColor: `#1BA785 !important`
  },
});

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
        fetchMerchantDetails, fetchPSPMetadata,
        fetchCountryDetails, resetPSPMetadata, 
        resetCreateMerResp, createMerchant,
        resetProgramPickList, fetchPspIntegrationModeMasterData,
        fetchMerchantMetadata
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    merchantList: state.MerchantReducer.merchantList !== null &&
      state.MerchantReducer.merchantList.merchants ?
        state.MerchantReducer.merchantList.merchants : [],
    countryDetails: state.SchemeReducer.countryDetails !== null &&
      state.SchemeReducer.countryDetails.countryList ?
        state.SchemeReducer.countryDetails.countryList : [],
    merchantListCount: state.MerchantReducer.merchantList !== null && state.MerchantReducer.merchantList.totalRecords ?
      state.MerchantReducer.merchantList.totalRecords : 0,
    pspMetaData: state.PspReducer.pspMetaData !== null && state.PspReducer.pspMetaData.pspList ?
      state.PspReducer.pspMetaData.pspList : [],
    createMerRes: state.MerchantReducer.createMerRes,
    merchantLoader: state.MerchantReducer.merchantLoader,
    pspIntegrationModeList:  state.PSPAcquirerReducer.pspIntegrationModeList !== null &&
      state.PSPAcquirerReducer.pspIntegrationModeList.pspIntegrationModes
        ? state.PSPAcquirerReducer.pspIntegrationModeList.pspIntegrationModes : [],
    merchantMetadata: state.MerchantReducer.merchantMetadata !== null &&
      state.MerchantReducer.merchantMetadata.merchantList ?
        state.MerchantReducer.merchantMetadata.merchantList : [],
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Merchant);
