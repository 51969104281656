import React, {useState} from 'react';
import { bindActionCreators } from "redux";
import {connect} from "react-redux";
// import {reduxForm} from 'redux-form';
import {
    Grid, TextField, MenuItem, Button, Paper, 
    Typography, Checkbox,
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../../../_theme'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { SpecialCharRegex1, roleNameRegex, addRegex } from "../../../shared/utility";
import { NO_SPECIAL_CHARACTERS, NO_NUMBERS_AND_SPECIAL_CHARACTERS } from "shared/errorMessages";

const useStyles = tss.create({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        border: "solid 1px #EEEEEE",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(5),
    },
    input: {
        display: 'none',
    },
    menu: {
        fontSize: "15px"
    },
});

// const roleList = ["Manager", "Owner", "Worker"];
// const parentPermissions = [ "View Program", "Create Program", "View Schemes", "Create Scheme", "View Merchant", "Create Merchant", "View Product", "Create Product" ];

function CreateEditViewRole(props) {
    const {classes} = useStyles();
    const { 
        currentForm, onChangeView, handleInputChange, handleRoleCreateUpdate,
        roleState, roleDetails, parentRoleList, createButtonFlag,
        handlePermissionsChange, parentPermissionList, handleSelectAllPermissions, 
        selectAllPerm
    } = props;
    
    const [valObj, setValObj] = useState({
        parentRoleErr: false, roleErr: false, descriptionErr: false, 
    })

    const parentRoleArr = parentRoleList && 
        parentRoleList.map( obj => {
            return obj.name ? 
                currentForm !== "view" ? 
                    obj.name !== roleState.name ? 
                        obj.name 
                        : obj.name 
                    : null 
                : null
        }).filter(val => val !== null)

    const handleChange = (type, value) => {
        if (type === "parentRole") {
            parentRoleArr.indexOf(value) !== -1 && handleInputChange(type, [
                parentRoleList[parentRoleArr.indexOf(value)]['id'],
                value
            ])
            setValObj({...valObj, parentRoleErr: false })
        } else if (type === "roleName") {
            handleInputChange(type, [value])
            setValObj({...valObj, roleErr: false })
        } 
    }

    const handleOnBlur = (field, value) => {
        if(value === null || value === ""){
        setValObj({...valObj, [field]: true })
        } else {
        setValObj({...valObj, [field]: false })
        }
    }

    return (
        <div className={classes.root}>
        <Paper className={classes.paper}>
            <form className="action_form merchant_form" noValidate autoComplete="off">
            <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        InputLabelProps={{ shrink: true }} 
                        required
                        fullWidth
                        variant="outlined"
                        name="parentRole"   
                        id="parentRole" 
                        label="Parent Role" 
                        select={currentForm === 'view' ? false : true}
                        disabled={currentForm === 'view' ? true : false}
                        value={currentForm === 'view' ? 
                            roleDetails !== null && roleDetails.parentName && 
                            roleDetails.parentName : roleState.parentName ? 
                            roleState.parentName : null
                        } 
                        onBlur={(e) => handleOnBlur("parentRoleErr", e.target.value)}
                        onChange={(e) => parentRoleArr.length > 0 && handleChange('parentRole', e.target.value)}
                    >
                        {parentRoleArr && parentRoleArr.length === 0 && 
                        <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                        {parentRoleArr && parentRoleArr.map((val, index) => {
                            return <MenuItem key={index} value={val} className={classes.menu}>
                                {val}
                            </MenuItem>
                        })}
                    </TextField>
                    {valObj.parentRoleErr ? 
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                    </p>
                    :null}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField 
                    InputLabelProps={{ shrink: true }} 
                    required
                    fullWidth 
                    variant="outlined" 
                    name="roleName" 
                    id="roleName" 
                    label={"Role Name"}
                    value={currentForm === 'view' ?
                        roleDetails !== null && roleDetails.name && 
                        roleDetails.name : roleState.name ?
                        roleState.name : null
                    }
                    disabled={currentForm !== 'create' ?  true : false}
                    onChange={(e) => e.target.value.length <= 30 && handleChange('roleName', e.target.value)}
                    onBlur={(e) => handleOnBlur("roleErr", e.target.value)}
                    />
                    {valObj.roleErr ? 
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                    </p>
                    : roleState && roleState.name && roleState.name !== "" && 
                    !roleNameRegex.test(roleState.name) ? 
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                        <span className="error_message"><InfoOutlinedIcon />{NO_NUMBERS_AND_SPECIAL_CHARACTERS}</span>
                    </p>
                    :null}
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                    InputLabelProps={{ shrink: true }} 
                    // required
                    fullWidth 
                    variant="outlined" 
                    name="description" 
                    id="description" 
                    label={"Role Description"}
                    value={currentForm === 'view' ?
                        roleDetails !== null && roleDetails.description && 
                        roleDetails.description : roleState.description ?
                        roleState.description : null
                    }
                    disabled={currentForm === 'view' ?  true : false}
                    onChange={(e) => e.target.value.length <= 50 && handleInputChange('description', [e.target.value])}
                    />
                    {roleState && roleState.description && roleState.description !== "" && 
                    !addRegex.test(roleState.description) ? 
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                        <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                    </p>
                    :null}
                </Grid>
            </Grid>

            {currentForm && currentForm === "view" && roleDetails && roleDetails.permissions && roleDetails.permissions.length > 0 && 
            <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={12} >
                    <Typography variant="h5" >Permissions</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        <Checkbox
                        id="select-all-checkbox"
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        checked={selectAllPerm}
                        disabled
                        // onChange={(e) => {handleSelectAllPermissions(e.target.checked)}}
                        />
                        Select All
                    </Typography>
                </Grid >
                {roleDetails.permissions.map((value, index) => {
                    return (
                        <Grid item xs={4}>
                            <Typography>
                                <Checkbox
                                    id={value.id}
                                    color="primary"
                                    disabled
                                    // disabled={currentForm === "view" ? true : false}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    checked={value.selected}
                                />
                                {value.displayName}
                            </Typography>
                        </Grid >
                    )})
                }
            </Grid>
            }
            {currentForm && currentForm !== "view" && roleState && roleState.parentName && roleState.parentName !== "" &&
            parentPermissionList && parentPermissionList.length > 0 &&
            <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={12} >
                    <Typography variant="h5" >Permissions</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        <Checkbox
                        id="select-all-checkbox"
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        checked={selectAllPerm}
                        onChange={(e) => {handleSelectAllPermissions(e.target.checked)}}
                        />
                        Select All
                    </Typography>
                </Grid >
                {parentPermissionList
                .map((value, index) => {
                    
                    return (
                        <Grid item xs={4}>
                            <Typography>
                                <Checkbox
                                id={value.id}
                                color="primary"
                                // disabled={currentForm === "view" ? true : false}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                checked={value.selected}
                                onChange={(e) => {handlePermissionsChange(index, e.target.checked)}}
                                />
                                {value.displayName}
                            </Typography>
                        </Grid >
                    )})
                }
            </Grid>
            }

            <Grid container justifyContent="flex-end">
                <Grid item>
                <Button fullWidth variant="contained"
                    color="primary" className={"action_login_button"}
                    disabled={createButtonFlag}
                    onClick={() => currentForm !== "view" ? handleRoleCreateUpdate() : onChangeView("list")}
                    >
                    {currentForm === "edit" ? "Modify" : currentForm === "view" ? "Close" : "Create"}
                </Button>
                </Grid>
            </Grid>
            </form>
        </Paper>
        
        </div>
    )
}

// CreateEditViewRole = reduxForm({
//   form: 'CreateEditViewRole',
//   // validate: validate,
//   enableReinitialize: true
// })(CreateEditViewRole);

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({

      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditViewRole);
