import React, {useEffect} from 'react';
import { bindActionCreators } from "redux";
import {connect} from "react-redux";
import {reduxForm} from 'redux-form';
import { 
    Grid, TextField, Button, Paper, MenuItem, Checkbox
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../../../_theme'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';

import { nameRegExp } from "../../../shared/utility";
import { NO_SPECIAL_CHARACTERS } from "shared/errorMessages";
import SchemeDetails from '../schemeDetails';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = tss.create({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        border: "solid 1px #EEEEEE",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(5),
    },
    input: {
        display: 'none',
    },
    menu: {
        fontSize: "15px"
    },
});

const statusArr = ["ACTIVE", "INACTIVE"]

function CreateEditViewProductGroup(props) {
    const {
        currentForm, onChangeView, groupState, groupDetails, countryArr, brandArr, createDisableToken,
        handleInputChange, submitCreateOrUpdateProductGroup, productArr, ...schemeProps
    } = props;
    
    const [valObj, setValObj] = React.useState({
        countryErr: false, brandErr: false, nameErr: false, statusErr: false, productsErr: false,
    })
  
    const {classes} = useStyles();
  
    const handleOnBlur = (field, value) => {
        if(value === null || value === ""){
        setValObj({...valObj, [field]: true })
        } else {
        setValObj({...valObj, [field]: false })
        }
    }

    const handleChange = async (type, value, errType) => {
        
        handleInputChange(type, value)
        setValObj({...valObj, [errType]: false })
    }

    const handleSchemeTablePageNo = (val) => {

        if(currentForm === "edit") {
            schemeProps.handleSchemeTablePageNo(val, groupState.id)
        } else if(currentForm === "view") {
            schemeProps.handleSchemeTablePageNo(val, groupDetails.id)
        }
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <form className="action_form merchant_form" noValidate autoComplete="off">
                    <Grid container rowSpacing={2} columnSpacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                variant="outlined" select={currentForm === 'create'} required fullWidth
                                id="country" label="Country" name="country"
                                disabled={currentForm !== 'create'}
                                value={
                                    currentForm === 'view' ? 
                                    groupDetails !== null && groupDetails.countryName && 
                                    groupDetails.countryName : groupState.countryName ? 
                                    groupState.countryName : null
                                } 
                                onBlur={(e) => handleOnBlur("countryErr", e.target.value)}
                                onChange={(e) => countryArr.length > 0 && handleChange('country', e.target.value, "countryErr")}
                            >
                                {countryArr && countryArr.length === 0 && 
                                <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                                {countryArr && countryArr.map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                                })}
                            </TextField>
                            {valObj.countryErr ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                </p>
                            :null}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                variant="outlined" select={currentForm === 'create'} required fullWidth
                                id="brand" label="Brand" name="brand"
                                disabled={currentForm !== 'create'}
                                value={
                                    currentForm === 'view' ? 
                                    groupDetails !== null && groupDetails.brandName && 
                                    groupDetails.brandName : groupState.brandName ? 
                                    groupState.brandName : null
                                } 
                                onBlur={(e) => handleOnBlur("brandErr", e.target.value)}
                                onChange={(e) => brandArr.length > 0 && handleChange('brand', e.target.value, "brandErr")}
                            >
                                {brandArr && brandArr.length === 0 && 
                                <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                                {brandArr && brandArr.map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                                })}
                            </TextField>
                            {valObj.brandErr ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                </p>
                            :null}
                        </Grid>
                    
                        <Grid item xs={12} sm={6}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                fullWidth variant="outlined" required
                                id="name" name="name" label="Group Name" 
                                disabled={currentForm === 'view'}
                                value={
                                    currentForm === 'view' ?
                                    groupDetails !== null && groupDetails.name && 
                                    groupDetails.name : groupState.name ? 
                                    groupState.name : null
                                }
                                onChange={(e) => e.target.value.length <= 50 && handleChange('name', e.target.value, "nameErr")}
                                onBlur={(e) => handleOnBlur("nameErr", e.target.value)}
                            />
                            {groupState && groupState.name && groupState.name !== "" && 
                                !nameRegExp.test(groupState.name) ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                                </p> : groupState && groupState.name && groupState.name !== "" &&
                                    groupState.name.length > 50 ? 
                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Maximum 50 characters are allowed!"}</span>
                                    </p> : valObj.nameErr ? 
                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                        </p> : null
                            }
                        </Grid>
                        
                        <Grid item xs={12} sm={6}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                variant="outlined" select={currentForm !== 'view'} required fullWidth
                                id="status" label="Status" name="status"
                                disabled={currentForm === 'view'}
                                value={
                                    currentForm === 'view' ? 
                                    groupDetails !== null && groupDetails.status && 
                                    groupDetails.status : groupState.status ? 
                                    groupState.status : null
                                } 
                                onBlur={(e) => handleOnBlur("statusErr", e.target.value)}
                                onChange={(e) => statusArr.length > 0 && handleChange('status', e.target.value, "statusErr")}
                            >
                                {statusArr && statusArr.length === 0 && 
                                <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                                {statusArr && statusArr.map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                                })}
                            </TextField>
                            {valObj.statusErr ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                </p>
                            :null}
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Autocomplete
                                options={productArr} multiple disabled={currentForm === 'view'}
                                value={currentForm === 'view' ? 
                                    groupDetails !== null && groupDetails.productNames ? groupDetails.productNames.map(val => val) : []
                                    : groupState && groupState.productNames ?  groupState.productNames.map(val => val) : []
                                } 
                                getOptionLabel={(option) => {
                                    return option
                                }} 
                                disableCloseOnSelect
                                onChange={(e, newValue) => currentForm !== 'view' && 
                                    handleChange('products', newValue, "productsErr") }
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option}
                                    </li>
                                )}
                                renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Products"
                                    InputLabelProps={{ shrink: true }} required
                                    disabled={currentForm === 'view'} name="products" id="products"
                                />
                                )}
                            />
                            {valObj.productsErr ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                            </p>
                            : null
                            }
                        </Grid>

                    </Grid>

                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Button fullWidth variant="contained"
                                color="primary" className={"action_login_button"}
                                disabled={createDisableToken}
                                onClick={() => currentForm !== "view" ? submitCreateOrUpdateProductGroup() : onChangeView("list")}
                            >
                                {currentForm === "edit" ? "Modify" : currentForm === "view" ? "Close" : "Create"}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
            {currentForm !== "create" &&
            <SchemeDetails
                {...schemeProps}
                handlePageNo={handleSchemeTablePageNo}
            />
            }
        </div>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
        }, dispatch)
    }
}

function mapStateToProps(state) {
    return {
        
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateEditViewProductGroup);
