import React from 'react'
import { 
    Dialog, DialogActions, DialogTitle, DialogContentText, DialogContent, Button  
} from '@mui/material'
import { tss } from "tss-react";

import CloseImgIcon from '../../Assets/close.svg';

const useStyles = tss.create({
    color: {
        color: "white",
        backgroundColor: `#1BA785 !important`
    }
});

export default function DialogBox(props) {

    const {classes} = useStyles();
    const {
        openDialog, handleDialogAction, title, body
    } = props

    return (
        <Dialog
            open={openDialog}
            onClose={() => handleDialogAction("exit")}
            className={"delete_dialog"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div className="close_dialog" onClick={() => handleDialogAction("close")}><img src={CloseImgIcon} alt="close" /></div>
            <DialogTitle id="alert-dialog-title">
                <h2>{title}</h2>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {body}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className={classes.color}
                    onClick={() => handleDialogAction("confirm")} 
                >
                    Confirm
                </Button>
                <Button className={classes.color}
                    onClick={() => handleDialogAction("close")} 
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}