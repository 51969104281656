import { actionCreator, BASE_URL } from '../../shared/utility'
import { RSAActions} from '../consts'

export function fetchRSAKeyList(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(RSAActions.fetchRSAKeyList.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/keypair/getRSAKeyList`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(RSAActions.fetchRSAKeyList.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(RSAActions.fetchRSAKeyList.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetKeyListResp() {
    return(dispatch) => {
        dispatch(actionCreator(RSAActions.fetchRSAKeyList.FAILURE))
    }
}

export function createRSAKey(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(RSAActions.createRSAKey.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/keypair/create`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(RSAActions.createRSAKey.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(RSAActions.createRSAKey.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function reCreateRSAKey(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(RSAActions.createRSAKey.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/keypair/regenerate`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(RSAActions.createRSAKey.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(RSAActions.createRSAKey.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetCreateKeyResp() {
    return(dispatch) => {
        dispatch(actionCreator(RSAActions.createRSAKey.FAILURE))
    }
}

export function downloadRSAPublicKey(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(RSAActions.downloadRSAPublicKey.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/keypair/download/${payload.id}`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(RSAActions.downloadRSAPublicKey.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(RSAActions.downloadRSAPublicKey.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetDownloadPublicKeyResp() {
    return(dispatch) => {
        dispatch(actionCreator(RSAActions.downloadRSAPublicKey.FAILURE))
    }
}

export function expireKeyPair(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(RSAActions.expireKeyPair.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/keypair/expire/${payload.id}`, {
            method: 'POST',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(RSAActions.expireKeyPair.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(RSAActions.expireKeyPair.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetExpireKeyResp() {
    return(dispatch) => {
        dispatch(actionCreator(RSAActions.expireKeyPair.FAILURE))
    }
}