import React, { useState } from 'react'
import {
    Grid, TextField, Button, MenuItem, Typography
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Filter = (props) => {
    const {
        classes, countryArr, pspArr, filterState, handleFilter, handleApplyFilter
    } = props;
    const [valObj, setValObj] = useState({ 
        countryErr: false, pspErr: false
    })

    const disableApplyButton = filterState && filterState.countryName !== "" && filterState.pspName !== ""

    const handleChange = (type, value, typeErr) => {
        
        setValObj({...valObj, [typeErr]: false })
        handleFilter(type, value);
    }

    const handleOnBlur = (type, value) => {
        if(value === null || value === "") {
            setValObj({...valObj, [type]: true })
        }
    }

    return (
        <React.Fragment>
            <div className={"paper_scroll"}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12} sm={12} justify="flex-start" className={classes.addRange}>
                        <Typography className={classes.title} variant="h6" id="filterTitle" component="div">
                            Filter For Key Details
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            InputLabelProps={{ shrink: true }} 
                            variant="outlined" fullWidth required select  
                            id="publicKeyGenerator-filter-country" name="filter-country" label="Country" 
                            value={filterState && filterState.countryName !== null ? filterState.countryName : ""}
                            onChange={(e) => e.target.value !== "NA" && handleChange("countryName", e.target.value, "countryErr")}
                            onBlur={(e) => e.target.value !== "NA" && handleOnBlur("countryErr", e.target.value)}
                        >
                            {countryArr && countryArr.length === 0 && 
                                <MenuItem key={1000} value={"NA"} className={classes.menu}>
                                {"No Country Available!"}</MenuItem>
                            }
                            {(countryArr).map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                            })}
                        </TextField>
                        {valObj.countryErr ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                            </p>
                            :null
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            InputLabelProps={{ shrink: true }} 
                            variant="outlined" fullWidth required select  
                            id="publicKeyGenerator-filter-psp" name="filter-psp" label="Payment Provider" 
                            value={filterState && filterState.pspName !== null ? filterState.pspName : ""}
                            onChange={(e) => e.target.value !== "NA" && handleChange("pspName", e.target.value, "pspErr")}
                            onBlur={(e) => e.target.value !== "NA" && handleOnBlur("pspErr", e.target.value)}
                        >
                            {pspArr && pspArr.length === 0 && 
                                <MenuItem key={1000} value={"NA"} className={classes.menu}>
                                {"No PSP Available!"}</MenuItem>
                            }
                            {(pspArr).map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                            })}
                        </TextField>
                        {valObj.pspErr ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                            </p>
                            :null
                        }
                    </Grid>

                    <Grid item xs={12} sm={8} justify="flex-start" className={classes.addRange}></Grid>
                    <Grid item xs={12} sm={2} style={{ display: "flex", justifyContent: "space-evenly" }} justify="flex-end">
                        <Button 
                            onClick={() => handleApplyFilter("clear")} 
                            disabled={!disableApplyButton}
                            className={`action_login_button`}
                            variant="contained" style={{color: '#ffffff !important'}}
                        >
                            Clear
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ display: "flex", justifyContent: "space-evenly" }} justify="flex-end">
                        <Button 
                            onClick={() => handleApplyFilter("apply")} 
                            disabled={!disableApplyButton}
                            className={`action_login_button`}
                            variant="contained" style={{color: '#ffffff !important'}}
                        >
                            Apply
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}

export default Filter;