import React, { useState } from 'react'
import {
    Grid, Button
} from '@mui/material';

import TableComponent from './mappingTable'
import FilterComponent from './filter'
import SelectedMappings from './selectedMappings'
 
const headCells = [
    { id: 'merchantName', type: 'text', numeric: false, disablePadding: false, label: 'Merchant Name' },
    { id: 'merchantProductCode', type: 'text', numeric: false, disablePadding: false, label: 'Merchant Product Code' },
    { id: 'plProductCode', type: 'text', numeric: false, disablePadding: false, label: 'PL Product Code' },
    { id: 'oemName', type: 'text', numeric: false, disablePadding: false, label: 'Brand Name' },
    { id: 'productSkuCode', type: 'text', numeric: false, disablePadding: false, label: 'Product Sku Code' },
    // { id: 'status', type: 'text', numeric: false, disablePadding: false, label: 'Status' },
];

const DeleteMappings = (props) => {

    const { 
        classes, countryArr, countryArrObj, merchantArr, merchantList, brandArr, brandArrObj, 
        deleteFilterState, handleDeletePageChange, applyFilter, productMappingList, selectedMappings
    } = props
    const [showTable, setShowTable] = useState(false)

    const handleCheckBox = (e, row) => {
        handleDeletePageChange("checkbox", row)
    }

    const handleSelectAll = (e) => {
        handleDeletePageChange("selectAll", productMappingList)
    };

    const handleRemoveMapping = (id) => {
        handleDeletePageChange("remove", id)
    }

    const applyProductMappingFilter = () => {
        applyFilter()
        setShowTable(true)
    }

    return (
        <>
        <Grid container className="step_action">
            <FilterComponent
                classes={classes}
                countryArr={countryArr}
                countryArrObj={countryArrObj}
                merchantArr={merchantArr}
                merchantList={merchantList}
                brandArr={brandArr}
                brandArrObj={brandArrObj}
                handleDeleteFilterChange={handleDeletePageChange}
                applyFilter={applyProductMappingFilter}
                filterState={deleteFilterState}
                setShowTable={setShowTable}
            />
        </Grid>
        {(showTable || (productMappingList && productMappingList.length > 0)) &&
        <Grid constainer className="step_action">
            <TableComponent
                rows={productMappingList}
                headCells={headCells}
                loading={false}
                selectedMappings={selectedMappings}
                handleCheckBox={handleCheckBox}
                handleSelectAll={handleSelectAll}
            />
        </Grid>}
        {selectedMappings && selectedMappings.length > 0 &&
        <Grid constainer className="step_action">
            <SelectedMappings
                selectedMappings={selectedMappings}
                handleRemoveMapping={handleRemoveMapping}
            />
        </Grid>}
        <Grid container className="step_action">
            <Grid item xs={10} justify="flex-start" className={classes.addRange}>
            </Grid>
            <Grid item xs={2} style={{ display: "flex", justifyContent: "space-evenly" }} justify="flex-end">
                <Button fullWidth variant="contained"
                    color="primary" className={"action_login_button"}
                    disabled={!(selectedMappings && selectedMappings.length > 0)}
                    onClick={() => handleDeletePageChange("delete")}
                >
                    {"Submit"}
                </Button>
            </Grid>
        </Grid>
        </>
    )
}

export default DeleteMappings