import React, { useState, useEffect } from 'react'
import {connect} from "react-redux";
import { bindActionCreators } from "redux";
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { 
    Grid, IconButton, Snackbar, Dialog, DialogActions,
    DialogTitle, DialogContentText, DialogContent, Button 
} from '@mui/material'
import { tss } from "tss-react";
import theme from '../_theme'
  
import CloseImgIcon from '../Assets/close.svg';
import BasePageContainer from '../_common/basePageContainer'
import BasePageToolbar from '../_common/basePageToolbar'
import TableComponent from "./table/index"
import CreateEditViewIssuer from "./createEditViewIssuer/index";
import { 
    fetchIssuerDetails,
    createOrEditIssuer, resetIssuerCreateResp,
    uploadIssuerDocument, resetUploadDocumentResp,
} from "./actions/index"
import {fetchCountryDetails} from "../manageScheme/actions/index";
import { SpecialCharRegex2, numbRegex, addRegex, nameRegExp, descrRegex, tNcRegex } from "../shared/utility";

const headCells = [
    { id: 'name', type: 'text', numeric: false, disablePadding: false, label: 'Issuer Name' },
    { id: 'displayName', type: 'text', numeric: false, disablePadding: false, label: 'Display Name' },
    { id: 'description', type: 'description', numeric: false, disablePadding: false, label: 'Description' },
    { id: 'mciId', type: 'text', numeric: false, disablePadding: false, label: 'Issuer Id' },
    { id: 'countryName', type: 'text', numeric: false, disablePadding: false, label: 'Country' },
    { id: 'status', type: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'createdOn', type: 'text', numeric: false, disablePadding: false, label: 'Created On' },
    { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];

const IssuerObj = {
    "id": "",
	"mciId": "",
	"name": "",
	"countryName": "",
	"countryCode":"",
    "interestType": "",
	"isoCountryCodeNum": "",
	"description": "",
	"status": "ACTIVE",
	"displayName": "",
	"termAndConditions": "",
    "isPLManaged": true,
}

const ImageDataObj = {
    "screenType": "ISSUER_IMAGE",
    "screenTypeCode": "100",
    "resourceId": "",
    "originalUrl": "",
    "baseURL": "",
    "file": null,
    "actionKey": "",
}

const   filterCells = [
    { id: 'isoCountryCodeNum', type: 'select', label: 'Country Name' },
    { id: 'name', type: 'text', label: 'Issuer Name' },
    { id: 'displayName', type: 'text', label: 'Display Name' },
  ];
  
const filterobj = {
    name: "",
    pageNo: 1,
    pageSize: 10,
    displayName: "",
    sortBy:"createdOn",
    sortDirection:"DESC",
    isoCountryCodeNum: "",
}

const sortArr = [
    {key:"createdOn", label: "Create Date"},
    {key:"countryName", label: "Country"},
    {key:'displayName', label: "Display Name"},
    {key:"status", label: "Status"},
];

const Issuer = (props) => {
    const {classes} = useStyles();
    const { issuerList, countryList, createIssuerRes, issuerLoader, uploadDocumentRes, totalRecords } = props;

    const [pageNo, setPage] = useState(1);
    const [sortDir, setsortDir] = useState('DESC');
    const [filterData, setFilterData] = useState([]);
    const [clientRow, setClientRow] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [snackbarMsg, setSnackBarMsg] = useState(null);
    const [openPopover, setopenPopover] = useState(false);
    const [sortName, setsortName] = useState('createdOn');
    const [currentForm, setCurrentForm] = useState("list");
    const [openSnackbar, SetOpenSnackbar] = useState(false);
    const [issuerState, setIssuerState] = useState(IssuerObj)
    const [imageData, setImageData] = useState(ImageDataObj);
    const [compInitaited, setCompInitiated] = useState(false);
    const [filterState, setFilterState] = useState(filterobj);
    const [imageAction, setImageActionType] = useState("Upload");
    const [docsSnackbarMsg, setDocsSnackBarMsg] = useState(null);
    const [openDocsSnackbar, SetOpenDocsSnackbar] = useState(false);
    const [openDeletePopover, setOpenDeletePopover] = useState(false);
    
    const currentPage = localStorage.getItem('currentPage');
    let permissions = localStorage.getItem("permissions")
    permissions = permissions && permissions !== null ? JSON.parse(permissions) : []

    const createDisableToken = currentForm !== "view" &&  issuerState && issuerState.countryName !== "" && 
        issuerState.name !== "" && issuerState.displayName !== "" && nameRegExp.test(issuerState.name) && 
        nameRegExp.test(issuerState.displayName) && (issuerState.description === "" || issuerState.description === null ||
        (issuerState.description !== "" && descrRegex.test(issuerState.description))) &&
        (issuerState.termAndConditions === "" || issuerState.termAndConditions === null ||
        (issuerState.termAndConditions !== "" && !tNcRegex.test(issuerState.termAndConditions))) &&
        issuerState.interestType !== ""

    const issuerArray = issuerList && issuerList !== null ? 
        issuerList.map(obj => {

            let issuerObj = {
                ...obj,
                createdOn: obj.createdOn && obj.createdOn !== "" ? `${obj.createdOn.slice(0,10)} ${obj.createdOn.slice(11,19)}` : "",
                searchColumn: headCells.map((cell, index) => 
                    cell.id === "createdOn" && obj.createdOn && obj.createdOn !== "" ? 
                        `${obj.createdOn.slice(0,10)} ${obj.createdOn.slice(11,19)}`
                        : cell.id !== "action" &&  obj[cell.id] &&  obj[cell.id] !== "" ?  
                            obj[cell.id] : "" 
                ).join(" ")
                // searchColumn: headCells.map((cell, index) => 
                //     cell.id !== "action" &&  obj[cell.id] &&  obj[cell.id] !== "" ?  obj[cell.id] : "" 
                // ).join(" ")
            }
            return issuerObj
        }) : []
    
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    });
        
    const onChangeView = (inpval, row) => {
        setCurrentForm(inpval);
        if(inpval === "view") {
            setClientRow(row)
            setImageData({})
            initImageStateForEdit(row);
        } else if(inpval === "edit") {
            // setIssuerState(row)
            setClientRow(row)

            setImageData({})
            // initImageStateForEdit(row);
            props.fetchIssuerDetails({...filterState, id: row.id,
                pageNo: 1, sortBy: sortName, sortDirection: sortDir
              })
            //fetch details for edit drop down
            countryList && countryList.length === 0 && props.fetchCountryDetails({})
    
        } else if(inpval === "create") {
            setIssuerState(IssuerObj)
            countryList && countryList.length === 0 && props.fetchCountryDetails({})
        
        } else {
            props.fetchIssuerDetails({...filterState,
                pageNo: 1, sortBy: sortName, sortDirection: sortDir
              })
        }
    }

    const initImageStateForEdit = (row) => {
        
        if(row && row.images && row.images.length > 0) {
            setImageData({
                "screenType": "ISSUER_IMAGE",
                "screenTypeCode": "100",
                "resourceId": row.images[0].resourceId && row.images[0].resourceId !== "" ? row.images[0].resourceId : "",
                "originalUrl": row.images[0].baseURL && row.images[0].baseURL !== "" ? row.images[0].baseURL : "",
                "baseURL": row.images[0].baseURL && row.images[0].baseURL !== "" ? row.images[0].baseURL : "",
                "file": null,
                "actionKey": "",
            })
        } else {
            setImageData({
                "screenType": "ISSUER_IMAGE",
                "screenTypeCode": "100",
                "resourceId": "",
                "originalUrl": "",
                "baseURL": "",
                "file": null,
                "actionKey": "",
            })
        }
    }

    useEffect(() => {
        if(currentPage && currentPage !== null && currentPage !== undefined && currentPage === "/issuers" && compInitaited){
            setPage(1);
            setsortDir("DESC");
            setCurrentForm('list')
            setsortName("createdOn")
            setFilterState({...filterobj});

            props.fetchIssuerDetails({
                pageNo: 1, pageSize: 10,
                sortBy:"createdOn", sortDirection:"DESC"
              })
            localStorage.removeItem('currentPage')
        }
    }, [currentPage])

    useEffect(() => {
        const handleIntialCall = async () => {
            await setCompInitiated(true);
            await props.fetchIssuerDetails({...filterState,
                pageNo: pageNo, sortBy: sortName, sortDirection: sortDir
            })
            // await props.fetchCountryDetails({})
            await localStorage.removeItem('currentPage')
        }
        handleIntialCall();
    }, [])

    useEffect(() => {
        if(currentForm !== "create" && issuerList && issuerList !== null && issuerList.length > 0) {
            setIssuerState(issuerList[0])
            initImageStateForEdit(issuerList[0]);
        } 
    }, [issuerList])

    useEffect(() => {
        if(createIssuerRes && createIssuerRes !== null && createIssuerRes.st) {
            if(currentForm === "create" ) {
                    setopenPopover(true)
            } else {
                SetOpenSnackbar(true)
                setSnackBarMsg(`Issuer Modified Successfully!`)
                onChangeView("list")
            }
            //reset user response
            props.resetIssuerCreateResp()
        } else if(createIssuerRes && createIssuerRes !== null && !createIssuerRes.st) {
            SetOpenSnackbar(true)
            setSnackBarMsg(createIssuerRes.msg && createIssuerRes.msg)
            onChangeView("list")
            //reset user response
            props.resetIssuerCreateResp()
        }
    }, [createIssuerRes])

    useEffect(() => {
        if(uploadDocumentRes && uploadDocumentRes !== null && uploadDocumentRes.st) {
          SetOpenDocsSnackbar(true)
          setDocsSnackBarMsg(imageAction === "Upload" ? "Image Uploaded Successfully!" :
            "Image Removed Successfully!");
          
          //reset user response
          props.resetUploadDocumentResp(null)
        } else if(uploadDocumentRes && uploadDocumentRes !== null && !uploadDocumentRes.st) {
          SetOpenDocsSnackbar(true)
          setDocsSnackBarMsg(uploadDocumentRes.msg && uploadDocumentRes.msg)
            
          //reset user response
          props.resetUploadDocumentResp(null)
        }  else if(uploadDocumentRes && uploadDocumentRes !== null && uploadDocumentRes.status) {
            SetOpenDocsSnackbar(true)
            setDocsSnackBarMsg(uploadDocumentRes.error && uploadDocumentRes.error)
              
            //reset user response
            props.resetUploadDocumentResp(null)
        } 
        clientRow && clientRow.id && props.fetchIssuerDetails(
            {id: clientRow.id, pageNo: 1, pageSize: 10, sortBy:"createdOn", sortDirection:"DESC"})
            
    }, [uploadDocumentRes])

    const handleInputChange = (key, value) => {
        let updatedIssuerState = {...issuerState};
        if(key === "country") {
      
            updatedIssuerState = {
                ...updatedIssuerState,
                countryName: value[0],
                isoCountryCodeNum: value[1],
                countryCode: value[2],
            }
        } else {
            updatedIssuerState = {
                ...updatedIssuerState,
                [key]: value,
            }
        }

        setIssuerState(updatedIssuerState)
    }

    const handleIssuerCreateUpdate = () => {
        let req = {
            ...issuerState
            //status: "ACTIVE"
        }

        props.createOrEditIssuer(req)
    }

    const handleSnackBarAction = () => {
        SetOpenSnackbar(false)
        setSnackBarMsg(null)
    }

    const handleDialogAction = async (type) => {
        setopenPopover(false)
        setIssuerState(IssuerObj)
        if(type === "exit" ){
          onChangeView('list')
        } else {
          await onChangeView('list')
          await onChangeView('create')
        }
    }
    
    const handleSetFilterData = (filterArray, searchValue) => {
        setFilterData(filterArray)
        setSearchValue(searchValue)
    }

    const imageHandler = (e, key) => {
    
        if(e.target.files && e.target.files[0]) {
            let file = e.target.files[0]
            
            setImageActionType("Upload");

            if(file.size > 2097152) {
                window.alert("Image File Size limit is 2MB!")
                let tempElement = document.getElementById("input");
                if( tempElement && tempElement !== null ) {
                    tempElement.value = null;
                }
            } else {
                let img = new Image()
                img.src = URL.createObjectURL(file)
                img.onload = function () {
                    if(img.width !== 155 || img.height !== 169) {

                        window.alert("Image Resolution should be 169 X 155 Pixel.")
                        let tempElement = document.getElementById("input");
                        tempElement.value = null;

                    } else {
                        const imageUrl = URL.createObjectURL(file)
                        setImageData({
                            ...imageData,
                            "baseURL": imageUrl,
                            "file": file,
                            "actionKey": "UPLOAD",
                        })
                    }
                }
            }
        }
    }
    
    const handleUploadImage = async (type) => {
    
        let formData = new FormData()
        
        if ( type === "UPLOAD" && imageData.resourceId === "") {
            await formData.append('issuerId', issuerState.id)
            await formData.append('operation', "ADD")
            await formData.append('uuid', "")
            await formData.append('documentSubCategory', imageData.screenType)
            await formData.append('file', imageData.file)
            
            await props.uploadIssuerDocument(formData, imageData.screenType)

            setImageData({
                ...imageData,
                "actionKey": "",
            })
        } else if ( type === "UPLOAD" && imageData.resourceId !== "" ) {
            await formData.append('issuerId', issuerState.id)
            await formData.append('operation', "EDIT")
            await formData.append('uuid', imageData.resourceId)
            await formData.append('documentSubCategory', imageData.screenType)
            await formData.append('file', imageData.file)

            await props.uploadIssuerDocument(formData, imageData.screenType)
            
            setImageData({
                ...imageData,
                "actionKey": "",
            })
        } else if( type === "CANCEL" ) {

            let tempElement = document.getElementById("input");
            if( tempElement && tempElement !== null ) {
                tempElement.value = null;
            }
            setImageData({
                ...imageData,
                "resourceId": "",
                "originalUrl": "",
                "baseURL": "",
                "file": null,
                "actionKey": "",
            })
        } else if( type === "DELETE_ACTION" ) {
            let tempElement = document.getElementById("input");
            if( tempElement && tempElement !== null ) {
                tempElement.value = null;
            }
            setOpenDeletePopover(true);
        } 
    }

    const handleDeleteDialogAction = async (type) => {
        setOpenDeletePopover(false)
        if(type === "Delete" ){
            setImageActionType("Delete");
            let formData = new FormData()
            await formData.append('issuerId', issuerState.id)
            await formData.append('operation', "DELETE")
            await formData.append('uuid', imageData.resourceId)
            await formData.append('documentSubCategory', imageData.screenType)
            await formData.append('file', imageData.file)

            await props.uploadIssuerDocument(formData, imageData.screenType)

            setImageData({
                "resourceId": "",
                "originalUrl": "",
                "baseURL": "",
                "file": null,
                "actionKey": "",
            })
        }
    }

    const handleDocsSnackBarAction = () => {
        SetOpenDocsSnackbar(false)
        setDocsSnackBarMsg(null)
    }
    
    const handleFilterStateChange = (key, value) => {
        let tempFilter = {
            ...filterState,
            [key]: value
        }
        setFilterState(tempFilter);
    }
    
    const resetFilter = () => {
        setFilterState(filterobj);
        setPage(1);
        setsortDir("DESC");
        setsortName("createdOn")
        props.fetchIssuerDetails({ pageNo: 1, pageSize: 10, sortBy:"createdOn", sortDirection:"DESC" })
    }

    const FilterApply = () => {
        setPage(1)
        props.fetchIssuerDetails({ ...filterState, pageNo: 1, sortBy: sortName, sortDirection: sortDir})
        setFilterState({...filterState});
    }
    
    const handlePageNo = (val) => {
        setPage(val)
        const payloadObj = { ...filterState, pageNo: val, sortBy: sortName, sortDirection: sortDir}
        props.fetchIssuerDetails(payloadObj)
    }

    const handleSortDir = (value) => {
        setsortDir(value)
        setPage(1)
    }

    const handleSortName = (value) => {
        setsortName(value)
        setPage(1)
    }
    

    return (
        <>
            <BasePageToolbar currentForm={currentForm}  create={permissions.includes("34")} onChangeView={onChangeView} resetFilter={resetFilter} 
                title={currentForm === 'list' ? "Issuers" :  currentForm === 'view' ? "View Issuer" : currentForm === "edit" ? "Modify Issuer" : "Create Issuer"}
                handleFilterStateChange={handleFilterStateChange} filterState={filterState} filterCells={filterCells} FilterApply={FilterApply}
                setsortName={handleSortName} setsortDir={handleSortDir} sortDir={sortDir} sortName={sortName} sortArr={sortArr}
                arrayToFilter={issuerArray} handleSetFilterData={handleSetFilterData} isTableSearchReq={false} searchValue={searchValue}
            ></BasePageToolbar>
            <BasePageContainer>
                {currentForm === 'list' ?
                <Grid container className={classes.container}>
                    <TableComponent
                        sortOrder="desc" sortOrderBy="createdOn"
                        onChangeView={onChangeView} handlePageNo={handlePageNo}
                        activeTab={filterState} showEdit={permissions.includes("35")}
                        rows={searchValue && searchValue !== "" ? filterData : issuerArray}
                        loading={issuerLoader} headCells={headCells} totalRecord={totalRecords} 
                    />
                </Grid>
                : currentForm === 'create' ?
                <Grid container className={classes.formContainer}>
                    <CreateEditViewIssuer 
                        currentForm={currentForm}
                        issuerState={issuerState}
                        countryList={countryList}
                        handleInputChange={handleInputChange}
                        createDisableToken={!createDisableToken}
                        handleIssuerCreateUpdate={handleIssuerCreateUpdate}
                    />
                </Grid>
                : currentForm === "edit" ?
                <Grid container className={classes.formContainer}>
                    <CreateEditViewIssuer 
                        currentForm={currentForm}
                        issuerState={issuerState}
                        countryList={countryList}
                        handleInputChange={handleInputChange}
                        createDisableToken={!createDisableToken}
                        handleIssuerCreateUpdate={handleIssuerCreateUpdate}
                        handleUploadImage={handleUploadImage}
                        imageHandler={imageHandler}
                        imageData={imageData}
                    />
                </Grid> :
                    <Grid container className={classes.formContainer}>
                        <CreateEditViewIssuer 
                            currentForm={currentForm} 
                            issuerDetails={clientRow}
                            onChangeView={onChangeView}
                            imageData={imageData}
                        />
                    </Grid>
                }

                <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => handleSnackBarAction()}
                action={
                    <React.Fragment>
                        <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
                        <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
                >
                    <Alert onClose={() => handleSnackBarAction()} severity={
                        createIssuerRes && createIssuerRes !== null && !createIssuerRes.st ? "error" : "success"
                    }>
                        {snackbarMsg}
                    </Alert>
                </Snackbar>
                <Dialog
                open={openPopover}
                onClose={() => handleDialogAction("exit")}
                className={"delete_dialog"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                    <div className="close_dialog" onClick={() => handleDialogAction("exit")}><img src={CloseImgIcon} alt="close" /></div>
                    <DialogTitle id="alert-dialog-title">
                        <h2>{"Issuer Created Successfully!"}</h2>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`Do you want to Create new Issuer?`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" className="yes" autoFocus
                            onClick={() => handleDialogAction("create")} 
                        >
                            Create New
                        </Button>
                        <Button color="primary" className="no"
                            onClick={() => handleDialogAction("exit")} 
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                open={openDeletePopover}
                onClose={() => handleDeleteDialogAction("exit")}
                className={"delete_dialog"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                    <div className="close_dialog" onClick={() => handleDeleteDialogAction("exit")}><img src={CloseImgIcon} alt="close" /></div>
                    <DialogTitle id="alert-dialog-title">
                        <h2>{"Delete Confirmation!"}</h2>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`Are you sure do you want to delete ?`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" className="yes" autoFocus
                            onClick={() => handleDeleteDialogAction("Delete")} 
                        >
                            Delete
                        </Button>
                        <Button color="primary" className="no"
                            onClick={() => handleDeleteDialogAction("exit")} 
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar key="dsocument-upload"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={openDocsSnackbar}
                autoHideDuration={6000}
                onClose={() => handleDocsSnackBarAction()}
                action={
                    <React.Fragment>
                        <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleDocsSnackBarAction()}>
                        <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
                >
                    <Alert onClose={() => handleDocsSnackBarAction()} severity={
                    uploadDocumentRes && uploadDocumentRes !== null && !uploadDocumentRes.st ? "error" : "success"
                    }>
                    {docsSnackbarMsg}
                    </Alert>
                </Snackbar>

            </BasePageContainer>
        </>
    )
}

const useStyles = tss.create({
    formContainer: {
        paddingTop: theme.spacing(0),
    },
    container: {
        paddingTop: theme.spacing(2),
    },
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            fetchIssuerDetails, fetchCountryDetails,
            createOrEditIssuer, resetIssuerCreateResp,
            uploadIssuerDocument, resetUploadDocumentResp,
        }, dispatch)
    }
}

function mapStateToProps(state) {
  return {
    issuerList: state.IssuerReducer.issuerList !== null && state.IssuerReducer.issuerList.issuer ? 
        state.IssuerReducer.issuerList.issuer : [],
    totalRecords: state.IssuerReducer.issuerList !== null && state.IssuerReducer.issuerList.totalRecords ? 
        state.IssuerReducer.issuerList.totalRecords : 0,
    countryList: state.SchemeReducer.countryDetails && state.SchemeReducer.countryDetails !== null 
        && state.SchemeReducer.countryDetails.countryList ? state.SchemeReducer.countryDetails.countryList : [],
    createIssuerRes: state.IssuerReducer.createIssuerRes,
    issuerLoader: state.IssuerReducer.issuerLoader,
    uploadDocumentRes: state.IssuerReducer.uploadDocumentRes,
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Issuer);
