import React, { useState, useEffect } from 'react'
import { 
  Dialog, DialogActions, Button, Grid,
  DialogTitle, DialogContentText, DialogContent, Snackbar, IconButton, Tabs, Tab, TextField
} from '@mui/material'
import { tss } from "tss-react";
import theme from '../_theme'
import format from "date-fns/format";
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MuiAlert from '@mui/material/Alert';
import BasePageContainer from '../_common/basePageContainer'
import BasePageToolbar from '../_common/basePageToolbar'
import {connect} from "react-redux";
import { bindActionCreators } from "redux";
import CloseImgIcon from '../Assets/close.svg';

import CreateEditViewScheme from "./createEditViewScheme/index"
import {
  deleteBrandScheme, clearDeleteBrandScheme, 
  fetchBankSchemesAsTemplate, fetchSchemeList, 
  resetSchemeListResponse, approveBrandOrMerchantScheme,
  resetBrandOrMerchantSchemeResp
} from "./actions";
import TableComponent from "./table"
import { installmentDescRegex } from "shared/utility";

const headCells = [
  { id: 'installmentConfigID', type: 'number', numeric: true, disablePadding: false, label: 'Installment Config ID' },
  { id: 'installmentConfigDesc', type: 'description', numeric: false, disablePadding: false, label: 'Installment Desc' },
  { id: 'mciID', type: 'number', numeric: true, disablePadding: false, label: 'Issuer ID' },
  { id: 'issuerName', type: 'text', numeric: false, disablePadding: false, label: 'Issuer Name' },
  { id: 'productType', type: 'text', numeric: false, disablePadding: false, label: 'Product Type' },
  { id: 'installmentStartDate', type: 'date', numeric: false, disablePadding: false, label: 'Start Date' },
  { id: 'installmentEndDate', type: 'date', numeric: false, disablePadding: false, label: 'End Date' },
  { id: 'statusCode', type: 'statusCode', numeric: false, disablePadding: true, label: 'Status Code' },
  { id: 'createdOn', type: 'createdOn', numeric: false, disablePadding: false, label: 'Created On' },
  { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];

const pendingHeadCells = [
  { id: 'installmentConfigID', type: 'number', numeric: true, disablePadding: false, label: 'Installment Config ID' },
  { id: 'installmentConfigDesc', type: 'description', numeric: false, disablePadding: false, label: 'Installment Desc' },
  { id: 'mciID', type: 'number', numeric: true, disablePadding: false, label: 'Issuer ID' },
  { id: 'issuerName', type: 'text', numeric: false, disablePadding: false, label: 'Issuer Name' },
  { id: 'productType', type: 'text', numeric: false, disablePadding: false, label: 'Product Type' },
  { id: 'installmentStartDate', type: 'date', numeric: false, disablePadding: false, label: 'Start Date' },
  { id: 'installmentEndDate', type: 'date', numeric: false, disablePadding: false, label: 'End Date' },
  { id: 'statusCode', type: 'statusCode', numeric: false, disablePadding: true, label: 'Status Code' },
  { id: 'createdOn', type: 'createdOn', numeric: false, disablePadding: false, label: 'Created On' },
  { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];

const filterCells = [
  { id: 'installmentConfigId', type: 'text', label: 'Installment Config Id' },
  { id: 'installmentConfigDesc', type: 'text', label: 'Installment Config Desc' },
  { id: 'mciId', type: 'select', label: 'issuer' },
  { id: 'productType', type: 'select', label: 'Product Type' },
  { id: 'installmentStartDate', type: 'date', label: 'Installment Start Date' },
  { id: 'installmentEndDate', type: 'date', label: 'Installment End Date' },
];

const filterobj = {
  mciId: "",
  pageNo: 1,
  pageSize: 10,
  productType: "",
  sortBy:"createdOn",
  sortDirection:"DESC",
  installmentConfigId: "",
  installmentEndDate: null,
  installmentStartDate: null,
  installmentConfigDesc: "",
}

const sortArr = [
  {key:"createdOn", label: "Create Date"},
  {key:"installmentConfigID", label: "Installment Config Id"},
  {key:"mciID", label: "Issuer Id"},
  {key:"productType", label: "Product Type"},
]

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BrandScheme = (props) => {
  const { 
    brandSchemeList, deleteSchemeRes, mibLoader, totalRecords, bankTemplate, approveSchemeResp
  } = props;

  const {classes, cx} = useStyles();
  const [pageNo, setPage] = useState(1);
  const [configId, setConfigId] = useState("");
  const [alertMsg, setAlertMsg] = useState(null);
  const [sortDir, setsortDir] = useState('DESC');
  const [filterData, setFilterData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [deleteReqRow, setDelReqRow] = useState(null);
  const [sortName, setsortName] = useState('createdOn');
  const [deleteAlert, openDeleteAlert] = useState(false);
  const [currentForm, setCurrentForm] = useState("list");
  const [filterState, setFilterState] = useState(filterobj);
  const [compInitaited, setCompInitiated] = useState(false);
  const [delConfrimOpen, setDelConfrimOpen] = useState(false);
  const [rowState, setRowState] = useState(null)
  const [activeTab, setActiveTab] = useState(0);
  const [openPopover, setOpenPopOver] = useState(false);
  const [approveRequest, setApproveRequest] = useState({
      installmentConfigID: "",
      mcID: "",
      configStatus: "",
      declineReason: ""
  });
  const [schemeDeclineMsg, setSchemeDeclineMsg] = useState("");
  const [declineRequest, setDeclineRequest] = useState(false);
  const [declineMsgReq, setDeclineMsgReq] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const enableDeclineButton = schemeDeclineMsg && schemeDeclineMsg !== "" && installmentDescRegex.test(schemeDeclineMsg);

  const currentPage = localStorage.getItem('currentPage')
  let permissions = localStorage.getItem("permissions")
  permissions = permissions && permissions !== null ? JSON.parse(permissions) : []
  const userName = localStorage.getItem('userName')

  useEffect(() => {
    setSchemeDeclineMsg("");
    setDeclineMsgReq(false);
  }, [openPopover]);

  useEffect(() => {
    if(currentPage && currentPage !== null && currentPage !== undefined && currentPage === "/brandscheme" && compInitaited){
      setCurrentForm('list')
      setFilterState(filterobj);
      setPage(1);
      setsortDir("DESC");
      setsortName("createdOn")
      activeTab === 0 && props.fetchSchemeList({...filterState,
        pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeType: "BRAND", schemeStatus: "APPROVED"});
      activeTab === 1 && props.fetchSchemeList({...filterState,
        pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeType: "BRAND", schemeStatus: "PENDING"});
      activeTab === 2 && props.fetchSchemeList({...filterState,
        pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeType: "BRAND", schemeStatus: "REJECTED"});
      localStorage.removeItem('currentPage')
    }
  }, [currentPage])

  const brandSchemeArray = brandSchemeList && brandSchemeList !== null ? 
  brandSchemeList.map(obj => {

      let schemeObj = {
        ...obj,
        searchColumn: headCells.map((cell, index) => 
        cell.type === "date" && obj[cell.id] ? `${obj[cell.id].slice(0,4)}-${obj[cell.id].slice(4,6)}-${obj[cell.id].slice(6)}`
        : cell.id ==="action" ? "" : obj[cell.id] && obj[cell.id]
          ).join(" ")
      }
      return schemeObj
  }) : []

  const onChangeView = async (inpval, row) => {
    const tempFilterState = {
      ...filterState,
      installmentStartDate: filterState['installmentStartDate'] !== null ?
          format(new Date(filterState['installmentStartDate']), 'yyyyMMdd') 
          : null,
      installmentEndDate: filterState['installmentEndDate'] !== null ?
          format(new Date(filterState['installmentEndDate']), 'yyyyMMdd') 
          : null
    }
    const inp = inpval === "delete" ? "list" : inpval

    //call delete API with row as request body and on success show message
    if(inpval === "delete") {
      await setDelReqRow({...row, statusCode: "DELETE", 
        binRangeDetails: row.binRangeDetails && row.binRangeDetails.map(bin => {
          return {
            ...bin,
            isTokenAccRange: bin.isTokenAcc
          }
        }) 
      })
      await setDelConfrimOpen(true)
    }

    inp === "edit" && setConfigId(row.installmentConfigID)
    inp === "edit" && await props.fetchBankSchemesAsTemplate({"mciId": row.mciID, "installmentConfigID": row.bankInstallmentConfigID})

    if(inp === "view" || inp === "edit") {
      setRowState(row)

    }  else if(inp === "list" && inpval !== "delete") {

      await props.resetSchemeListResponse()
      props.fetchSchemeList({ ...tempFilterState, schemeType:"BRAND",
        pageNo: 1, sortBy: sortName, sortDirection: sortDir, schemeStatus: activeTab === 0 ? "APPROVED" : activeTab === 1 ? "PENDING" : "REJECTED"
      });
    }
    setCurrentForm(inp);
  }

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  });
  
  const handleFilterStateChange = (key, value) => {
    const tempFilter = {
        ...filterState,
        [key]: value
    }
    setFilterState(tempFilter);
  }

  const resetFilter = () => {
      setFilterState(filterobj);
      setPage(1);
      setsortDir("DESC");
      setsortName("createdOn")
      activeTab === 0 && props.fetchSchemeList(
          { pageNo: 1, pageSize: 10, sortBy:"createdOn", sortDirection:"DESC", schemeType: "BRAND", schemeStatus: "APPROVED" }
      );
      activeTab === 1 && props.fetchSchemeList(
        { pageNo: 1, pageSize: 10, sortBy:"createdOn", sortDirection:"DESC", schemeType: "BRAND", schemeStatus: "PENDING" }
      );
      activeTab === 2 && props.fetchSchemeList(
        { pageNo: 1, pageSize: 10, sortBy:"createdOn", sortDirection:"DESC", schemeType: "BRAND", schemeStatus: "REJECTED" }
      );
  }

  const FilterApply = () => {
    setPage(1);
    const tempFilterState = {
      ...filterState,
      installmentStartDate: filterState['installmentStartDate'] !== null ?
          format(new Date(filterState['installmentStartDate']), 'yyyyMMdd') 
          : null,
      installmentEndDate: filterState['installmentEndDate'] !== null ?
          format(new Date(filterState['installmentEndDate']), 'yyyyMMdd') 
          : null
    }
    activeTab === 0 && props.fetchSchemeList(
      {...tempFilterState, schemeType:"BRAND", pageNo: 1, sortBy: sortName, sortDirection: sortDir, schemeStatus: "APPROVED"}
    );
    activeTab === 1 && props.fetchSchemeList(
      {...tempFilterState, schemeType:"BRAND", pageNo: 1, sortBy: sortName, sortDirection: sortDir, schemeStatus: "PENDING"}  
    );
    activeTab === 2 && props.fetchSchemeList(
      {...tempFilterState, schemeType:"BRAND", pageNo: 1, sortBy: sortName, sortDirection: sortDir, schemeStatus: "REJECTED"}  
    );
    setFilterState({...filterState});
  }

  useEffect(() => {
    const handleInitialCall = async () => {
      await setCompInitiated(true);
      await props.fetchSchemeList(
        {...filterState, pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeType:"BRAND", schemeStatus: "APPROVED" });
      await localStorage.removeItem('currentPage')
    }
    handleInitialCall();
  }, []);

  useEffect(() => {
    if(deleteSchemeRes && deleteSchemeRes !== null && deleteSchemeRes.st) {
      openDeleteAlert(true)
      setAlertMsg(`Brand Scheme delete request for Config Id: ${deleteReqRow.installmentConfigID} is pending for approval!`)
      //clear deletROw after setting message with key ID
      setDelReqRow(null)

    } else  if(deleteSchemeRes && deleteSchemeRes !== null && !deleteSchemeRes.st) {
      openDeleteAlert(true)
      setAlertMsg(deleteSchemeRes.errors && deleteSchemeRes.errors.length > 0 ?
        deleteSchemeRes.errors.map(err => err.errorDesc).join(", ")
        :`Problem Occured While deleting Scheme Id: ${deleteReqRow.installmentConfigID}.`)
      //clear deletROw after setting message with key ID
      setDelReqRow(null)
    }
    props.clearDeleteBrandScheme()
  }, [deleteSchemeRes,])

  useEffect(() => {
    if(approveSchemeResp && approveSchemeResp !== null) {
        if(!approveSchemeResp.st) {
            if( approveSchemeResp.errors && approveSchemeResp.errors.length >0 ) {
                let resError ="";
                approveSchemeResp.errors.map((error, index) => {
                    resError =[resError, error.errorDesc].join( index === 0 ? "Error " + index + ": " : ", Error " + index + ": ");
                })
                setAlertMsg(resError)
            } else if (approveSchemeResp.msg) {
              setAlertMsg(approveSchemeResp.msg)
            } else {
              setAlertMsg("Unknown error!")
            }
        } else {
            if(declineRequest) {
              setAlertMsg("Scheme is Declined successfully!")
              setDeclineRequest(false)
            } else {
              setAlertMsg(
                approveRequest && approveRequest.requestType === "DELETE" 
                ?   "Scheme is Deleted successfully!" : "Scheme is Approved successfully!"
              )
            }
        }
        
        activeTab === 0 && props.fetchSchemeList( {...filterobj, schemeType: "BRAND", schemeStatus: "APPROVED"}, "list");
        activeTab === 1 && props.fetchSchemeList( {...filterobj, schemeType: "BRAND", schemeStatus: "PENDING"}, "list");
        activeTab === 2 && props.fetchSchemeList( {...filterobj, schemeType: "BRAND", schemeStatus: "REJECTED"}, "list");
        openDeleteAlert(true);
    }
  }, [approveSchemeResp])

  const handleOnBlur = (type) => {
    if(type === "declineMsg") {
        setDeclineMsgReq(schemeDeclineMsg && schemeDeclineMsg !== "" ? false : true)
    } 
  }

  const handleAlertClose = () => {
    openDeleteAlert(false)
    setAlertMsg(null)
    //remove deleteSchemeRes 
    props.clearDeleteBrandScheme()
    props.resetBrandOrMerchantSchemeResp()
  }

  const handleIconActions = (rowData, actionType) => {
    if (actionType === 'approve') {
      setOpenPopOver(true);
      setApproveRequest({
        installmentConfigID: rowData.installmentConfigID,
        mcID: rowData.mciID,
        configStatus: "APPROVED",
        declineReason: "",
        requestType: rowData.statusCode === "DELETE" ? rowData.statusCode : "APPROVE"
      });

      const currentDate = new Date().toISOString().split("T")[0].replaceAll("-","")
      if ( rowData.installmentEndDate && rowData.installmentEndDate !== "" && (currentDate < rowData.installmentEndDate)) {
          setIsExpired(false);
      } else {
        setIsExpired(true);
      }  
    }
  }

  const handleDelConfirmAction = async (type) => {
    if(type === "delete") {
      props.deleteBrandScheme({ ...deleteReqRow, createdBy: userName }, 
        {...filterState, pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeType:"BRAND", schemeStatus: "APPROVED" }
      )
      setDelConfrimOpen(false)
    } else {
      setDelConfrimOpen(false)
      setDelReqRow(null)
      props.fetchSchemeList({...filterState, pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeType:"BRAND", schemeStatus: "APPROVED" });
    }
    await props.resetSchemeListResponse()
  }

  const handleSetFilterData = (filterArray, searchValue) => {
    setFilterData(filterArray)
    setSearchValue(searchValue)
  }

  const handlePageNo = (val) => {
    setPage(val)
    const tempFilterState = {
      ...filterState,
      installmentStartDate: filterState['installmentStartDate'] !== null ?
          format(new Date(filterState['installmentStartDate']), 'yyyyMMdd') 
          : null,
      installmentEndDate: filterState['installmentEndDate'] !== null ?
          format(new Date(filterState['installmentEndDate']), 'yyyyMMdd') 
          : null
    }
    activeTab === 0 && props.fetchSchemeList(
      { ...tempFilterState, pageNo: val, sortBy: sortName, sortDirection: sortDir, schemeType: "BRAND", schemeStatus: "APPROVED"});
    activeTab === 1 && props.fetchSchemeList(
      { ...tempFilterState, pageNo: val, sortBy: sortName, sortDirection: sortDir, schemeType: "BRAND", schemeStatus: "PENDING"});
    activeTab === 2 && props.fetchSchemeList(
      { ...tempFilterState, pageNo: val, sortBy: sortName, sortDirection: sortDir, schemeType: "BRAND", schemeStatus: "REJECTED"});  
  }

  const handleSortDir = (value) => {
      setsortDir(value)
      setPage(1)
  }

  const handleSortName = (value) => {
      setsortName(value)
      setPage(1)
  }
  
  const handleChange = async (event, newValue) => {
    setPage(1);
    setFilterData([]);
    setsortDir("DESC");
    setsortName("createdOn")
    await setActiveTab(newValue);
    await setFilterState(filterobj);
    newValue === 0 && await props.fetchSchemeList({...filterobj,
      pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeType: "BRAND", schemeStatus: "APPROVED"});
    newValue === 1 && await props.fetchSchemeList({...filterobj,
      pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeType: "BRAND", schemeStatus: "PENDING"});
    newValue === 2 && await props.fetchSchemeList({...filterobj,
      pageNo: pageNo, sortBy: sortName, sortDirection: sortDir, schemeType: "BRAND", schemeStatus: "REJECTED"});  
  };
  
  const handleApproveScheme = (str) => {
    if(str === "APPROVED") {
        setOpenPopOver(false);
        setFilterState(filterobj)
        const pendingReqPaylod = {"pageNo": 1, "pageSize": 10, "sortDirection": "DESC", "sortBy": "createdOn", "schemeType": "BRAND", "schemeStatus": "PENDING"};
        props.approveBrandOrMerchantScheme({...approveRequest, configStatus: str, approvedBy: userName }, pendingReqPaylod);
    } else if (str === "DECLINE") {
        setDeclineRequest(true)
        if(schemeDeclineMsg && schemeDeclineMsg !== "") {
            setOpenPopOver(false);
            setFilterState(filterobj)
            const pendingReqPaylod = {"pageNo": 1, "pageSize": 10, "sortDirection": "DESC", "sortBy": "createdOn", "schemeType": "BRAND", "schemeStatus": "PENDING"};
            props.approveBrandOrMerchantScheme({...approveRequest, configStatus: str, declineReason: schemeDeclineMsg, approvedBy: userName }, pendingReqPaylod);
        } else {
            setDeclineMsgReq(true);
        }
    } else {
        setOpenPopOver(false);
    }
  };

  const handleDeclineMsgChange = (e) => {
      setSchemeDeclineMsg(e.target.value);
  };

  return (
    <>
      <BasePageToolbar currentForm={currentForm} create={permissions.includes("9")} onChangeView={onChangeView} resetFilter={resetFilter}
        title={currentForm === 'list' ? "Brand Scheme Detail" : currentForm === 'view' ?  "View Brand Scheme" 
            : currentForm === 'edit' ? "Edit Brand Scheme" : "Create Brand Scheme"}
        handleFilterStateChange={handleFilterStateChange} filterState={filterState} filterCells={filterCells} FilterApply={FilterApply}
        setsortName={handleSortName} setsortDir={handleSortDir} sortDir={sortDir} sortName={sortName} sortArr={sortArr}
        arrayToFilter={brandSchemeArray} handleSetFilterData={handleSetFilterData} isTableSearchReq={false} searchValue={searchValue}
      ></BasePageToolbar>
      <BasePageContainer>
        {currentForm === 'list' ?
        <div>
            <div className={""}>
              <Tabs value={activeTab} onChange={handleChange} className="tabs_wraper" aria-label="simple tabs example">
                  <Tab label="Approved" {...a11yProps(0)} className={classes.tabWidth} />
                  <Tab label="Pending" {...a11yProps(1)} className={classes.tabWidth} />
                  <Tab label="Declined" {...a11yProps(2)} className={classes.tabWidth} />
              </Tabs>
            </div>
            <Grid container className={classes.container}>
              <TableComponent 
                onChangeView={onChangeView}
                handlePageNo={handlePageNo}
                onIconActions={handleIconActions} 
                totalRecord={totalRecords}
                loading={mibLoader} 
                headCells={activeTab !== 0 ? pendingHeadCells : headCells} 
                searchValue={searchValue}
                activeTab={activeTab}
                showApprove={activeTab === 1 && permissions.includes("77")}
                showEdit={activeTab === 0 && permissions.includes("10")} 
                showDelete={activeTab === 0 && permissions.includes("11")}
                rows={searchValue && searchValue !== "" ? filterData : brandSchemeArray} 
                filterState={filterState}
                type={"BRAND"}
              />
            </Grid>

            <Dialog className={"delete_dialog"}
            open={openPopover}
            onClose={() => handleApproveScheme("CLOSE")}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
              <div className="close_dialog" onClick={() => handleApproveScheme("CLOSE")}>
                  <img src={CloseImgIcon} alt="close" />
              </div>
              <DialogTitle id="alert-dialog-title" className="delete_dialog_title">
                  {/* <img src={DeleteLargeIcon} className="delete_large" alt="Delete" /> */}
              </DialogTitle>
              <DialogContent>
              <DialogContentText id="alert-dialog-description" className={cx(classes.approveDialog)}>
                <h2>{isExpired === false ? `${approveRequest.requestType} Confirmation` : "DECLINE Confirmation"}</h2>
                <p>
                    {isExpired === false ? `Are you sure you want to ${approveRequest.requestType} scheme for Installment Config Id ${approveRequest.installmentConfigID}?` :
                        `This scheme has either expired or is expiring today. Kindly decline.`}
                </p>
              </DialogContentText>
              <div className={classes.reasonField}>
                  <Grid container>
                      <Grid item xs={12}>
                          <TextField className={"name_field"}
                          name="declineMsg"
                          id="declineMsg"
                          label="Reason (Mandatory in case of Decline)*"
                          variant="outlined"
                          fullWidth 
                          onBlur={() => handleOnBlur("declineMsg")}
                          onChange={(e) => handleDeclineMsgChange(e)}
                          />
                      </Grid>
                      {schemeDeclineMsg && schemeDeclineMsg !== "" && !installmentDescRegex.test(schemeDeclineMsg) ?
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                          <span className="error_message">
                          <InfoOutlinedIcon />{" "}
                          {"Invalid Entry! Reason can only contain Alphabets, Numbers, Whitespace, and () [] - _"}
                          </span>
                      </p> : declineMsgReq? 
                          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                              <span className="error_message"><InfoOutlinedIcon /> {"Required Field"}</span>
                          </p>
                          : null
                      }
                  </Grid>
              </div>
              </DialogContent>
              <DialogActions>
              <Button onClick={() => handleApproveScheme("DECLINE")} color="primary" className="no" disabled={!enableDeclineButton}>
                  DECLINE
              </Button>
              {isExpired === false &&
                <Button onClick={() => handleApproveScheme("APPROVED")} color="primary" className="yes" autoFocus disabled={!(schemeDeclineMsg === "" || installmentDescRegex.test(schemeDeclineMsg))}>
                  {approveRequest.requestType}
                </Button>
              }
              </DialogActions>
            </Dialog>
          </div>
          : currentForm === 'view' ?
            <Grid container className={classes.formContainer}>
              {rowState && <CreateEditViewScheme 
                disableToken={true} schemeDetail={rowState}
                type="Brand" currentForm={currentForm}
                onChangeView={onChangeView}
              />}
            </Grid>
            :currentForm === 'edit' ?
            <Grid container className={classes.formContainer}>
              {rowState && <CreateEditViewScheme 
                disableToken={false} schemeDetail={rowState}
                type="Brand" currentForm={currentForm} configId={configId}
                onChangeView={onChangeView}
                bankTemplate={bankTemplate}
              />}
            </Grid>
            :
            <Grid container className={classes.formContainer}>
              <CreateEditViewScheme 
                onChangeView={onChangeView}
                type="Brand" disableToken={false} currentForm={currentForm} 
              />
            </Grid>
          }
          <Dialog
            open={delConfrimOpen}
            onClose={() => handleDelConfirmAction("close")}
            className={"delete_dialog"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <h2>{"Scheme Delete Confirmation?"}</h2>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`Please confirm if you want to delete installment config Id: ${deleteReqRow && deleteReqRow.installmentConfigID && deleteReqRow.installmentConfigID}.`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className={classes.color}
                onClick={() => handleDelConfirmAction("delete")} 
              >
                Delete
              </Button>
              <Button autoFocus className={classes.color}
                onClick={() => handleDelConfirmAction("close")} 
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={deleteAlert}
            autoHideDuration={4000}
            onClose={() => handleAlertClose()}
            action={
            <React.Fragment>
              <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleAlertClose()}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
            }
          >
            <Alert onClose={() => handleAlertClose()} severity={
                deleteSchemeRes && deleteSchemeRes !== null && !deleteSchemeRes.st ? "info" : "success"
            }>
              {alertMsg}
            </Alert>
          </Snackbar>
      </BasePageContainer>
    </>)
}
const useStyles = tss.create({
  formContainer: {
    paddingTop: theme.spacing(0),
  },
  container: {
    paddingTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  color: {
    color: "white",
    backgroundColor: `#1BA785 !important`
  },
  tabWidth: {
    minWidth: "103px"
  },
  reasonField: {
    paddingTop: "15px"
  }
});

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
          deleteBrandScheme, fetchSchemeList, 
          resetSchemeListResponse, clearDeleteBrandScheme,
          fetchBankSchemesAsTemplate, approveBrandOrMerchantScheme,
          resetBrandOrMerchantSchemeResp
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    mibLoader: state.SchemeReducer.mibLoader,
    brandSchemeList: state.SchemeReducer.schemeList !== null &&
      state.SchemeReducer.schemeList.brandSchemeList ? 
        state.SchemeReducer.schemeList.brandSchemeList : [],
    totalRecords: state.SchemeReducer.schemeList !== null &&
      state.SchemeReducer.schemeList.totalRecord ? 
        state.SchemeReducer.schemeList.totalRecord : 0,
    deleteSchemeRes: state.SchemeReducer.deleteSchemeRes,
    bankTemplate: state.SchemeReducer.bankSchemeList !== null ? 
      state.SchemeReducer.bankSchemeList.bankSchemeList[0] : undefined,
    approveSchemeResp: state.SchemeReducer.approveSchemeResp,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandScheme);
