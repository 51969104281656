import { createRequestActionTypes } from "../../../shared/utility";

export const ProductCodeMappingActions = {
    downloadProductFileToCreateMapping: createRequestActionTypes('DOWNLOAD_PRODUCT_FILE_TO_CREATE_MAPPING'),
    downloadProductFileToUpdateMapping: createRequestActionTypes('DOWNLOAD_PRODUCT_FILE_TO_UPDATE_MAPPING'),
    uploadProductFileCreateMode: createRequestActionTypes('UPLOAD_PRODUCT_FILE_CREATE_MODE'),
    uploadProductFileUpdateMode: createRequestActionTypes('UPLOAD_PRODUCT_FILE_UPDATE_MODE'),
    fetchProductMappingFilesDetail: createRequestActionTypes('FETCH_PRODUCT_MAPPING_FILES_DETAIL'),
    downloadProductMappingFileByStatus: createRequestActionTypes('DOWNLOAD_PRODUCT_MAPPING_FILE_BY_STATUS'),
    fetchProductMappings: createRequestActionTypes('FETCH_PRODUCT_MAPPINGS'),
    deleteProductMappings: createRequestActionTypes('DELETE_PRODUCT_MAPPINGS'),
}