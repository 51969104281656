import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Paper, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, TableSortLabel
} from '@mui/material'
import { tss } from "tss-react";
import theme from '../../../_theme'
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
       <TableRow  className={classes.leftCell}>
            <React.Fragment>
            {headCells.map((headCell, index) => {
                return <TableCell
                  key={headCell.id}
                  align= 'left'
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                  className={index === 0 ? `${classes.cellPadding} ${classes.headStyle} ${classes.paddingStyle}` 
                    : `${classes.headStyle} ${headCell.id !== "action" ? classes.paddingStyle : classes.actionPadding}`}
                >
                  {headCell.id !== "action" ?
                    <TableSortLabel
                      style={{color: "#ffffff !important"}}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                      className={classes.headStyle}
                    >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                    ) : null}
                    </TableSortLabel> : 
                    <span className={classes.headStyle}> {headCell.label} </span>
                  }
                </TableCell>
              })}
            </React.Fragment>
        </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.array.isRequired,
};

const useStyles = tss.create({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    border: "solid 1px #EEEEEE",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  deleteDialog: {
    width: "100%",
    textAlign: 'center',
  },
  tabWidth: {
    minWidth: "103px"
  },
  leftCell: {
    color: "#ffffff !important",
    backgroundColor: "#1BA785 !important"
    // paddingLeft: theme.spacing(2),
  },
  pagination: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
  cellPadding: {
    padding: "17px 10px 17px 10px !important",
  },
  headStyle: {
    color: "#ffffff !important",
  },
  paddingStyle: {
    paddingLeft: "10px !important",
  },
  actionPadding: {
    paddingRight: "15px !important"
  }
});

function EnhancedTable(props) {
  const {classes} = useStyles();
  const {
    headCells, rows, loading, sortOrder, sortOrderBy, handleDeletePSP, disableDelete, activeTab,
  } = props;
  const userStatus = ["ACTIVE", "BLOCKED", "TERMINATED"]
  const [order, setOrder] = useState(sortOrder);
  const [orderBy, setOrderBy] = useState(sortOrderBy);
  const [page, setPage] = useState(1);
  const [dense] = useState(false);
  const [rowsPerPage] = useState(10);
  const toggleMenu = useRef([]);
  const [activeTop, setactiveTop] = useState(0);
  const [activeLeft, setactiveLeft] = useState(627);

  const totalPages = Math.ceil(rows && (rows.length)/rowsPerPage)
  // Math.ceil(totalRecord/rowsPerPage);
  const ref = useRef(null);

  const onOpenMoreMenu = (e, i) => {
    var element = document.getElementById(i + 'user');
    element.classList.add("active");
    setactiveTop(toggleMenu.current[i].offsetTop + 100);
    setactiveLeft(toggleMenu.current[i].offsetLeft - 130);
  }
  const userName = localStorage.getItem("userName")

  useEffect(() => {
    setPage(1);
  }, [activeTab]);


  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onMouseOut();
      setactiveTop(0);
      setactiveLeft(0);
    }
  };
  const onMouseOut = (e) => {
    var element = document.getElementsByClassName('users_menu');
    for (let i = 0; i < element.length; i++) {
      if (element[i].classList.contains("active")) {
        element[i].classList.remove('active');
      }
    }
    setactiveTop(0)
    setactiveLeft(0)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - (page-1) * rowsPerPage);

  const handleChange = (event, value) => {
    setPage(value);
    // handlePageNo(value);
  };

  return (
    <div className={classes.root}>
        <Paper className={classes.paper}>
            <TableContainer>
            <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table"
            >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {!loading &&  
                  stableSort(rows, getComparator(order, orderBy))
                  .slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage)
                  // rows
                  .map((Row, index) => {
                    const row = {...Row, status: userStatus[Row["status"]]}
                      return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            {headCells.map((headCell, headIndex) => {
                              return (headCell.type !== 'action') ? (
                                row[headCell.id] && row[headCell.id] !== "" ? 
                                  <TableCell 
                                  align="left"
                                  className={headIndex === 0 ? classes.cellPadding : 
                                    headCell.id !== "action" ? classes.paddingStyle : classes.actionPadding}
                                  >
                                      {headCell.type === 'status' &&
                                        <div className={row[headCell.id]  === "ACTIVE" ? "badge badge-success" : row[headCell.id]  === "INACTIVE" ? "badge badge-danger" : null}>
                                            {row[headCell.id]}
                                        </div>
                                      }
                                      {headCell.type === 'description' &&
                                      <Tooltip title={row[headCell.id]}>
                                        <p>{row[headCell.id].length > 20 ? `${row[headCell.id].slice(0,20)}...` : row[headCell.id]}</p>
                                      </Tooltip>
                                      }
                                      {headCell.type !== 'date' && headCell.type !== 'status' && headCell.type !== 'createdOn' && headCell.type !== 'description' &&
                                          (row[headCell.id] !== null && row[headCell.id] !== "" ? row[headCell.id] : "_")
                                      }
                                  </TableCell>
                                  : <TableCell align="left">
                                    _
                                  </TableCell> 
                                  ) : (
                                    <TableCell align="right" className="options" id={index + 'icon'} ref={el => (toggleMenu.current[index] = el)} width="3%">
                                      {<div ref={ref}>
                                        <div className="dropdown-item" onClick={()=> !disableDelete && handleDeletePSP(Row)}><DeleteIcon />&nbsp; Delete</div>
                                      </div>}
                                    </TableCell>
                                  )
                              })}
                          </TableRow>
                          );
                        })}
                            
                        {!loading && rows && rows.length === 0 && (
                        <TableRow style={{ height: (dense ? 33 : 53) * 10 }}>
                          <TableCell colSpan={6} style={{paddingLeft: "47%"}}> 
                            {"No Data Found!"}
                          </TableCell>
                        </TableRow>
                        )}
                        {loading && (
                          <TableRow style={{ height: (dense ? 33 : 53) * 10 }}>
                            <TableCell colSpan={6} style={{paddingLeft: "47%"}}> 
                              <CircularProgress color="secondary" style={{
                                "width": "70px", "height": "70px"}}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                </TableBody>
            </Table>
            </TableContainer>
        </Paper>
        <Grid container justifyContent="center">
          <Grid item>
            <div className={classes.pagination}>
              <Pagination count={totalPages} page={page} onChange={handleChange} color="primary" />
            </div>
          </Grid>
        </Grid>
    </div>
  );
}

export default EnhancedTable;