import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import {connect} from "react-redux";
import { bindActionCreators } from "redux";
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { 
    IconButton, Snackbar, Dialog, DialogActions,
    DialogTitle, DialogContentText, DialogContent, Button
} from '@mui/material'
import { tss } from "tss-react";
import theme from '../../_theme'
  
import CloseImgIcon from '../../Assets/close.svg';
import BasePageContainer from '../../_common/basePageContainer'
import BasePageToolbar from '../../_common/basePageToolbar'
import TableComponent from "../table/index"
import CreateEditViewRole from "./createEditViewRole/index";
import { 
    fetchRoleDetails, fetchPermissionDetails, 
    createOrEditRole, resetRoleCreateResp,
    fetchPermissionDetailsForView
} from "../actions/index"
import { roleNameRegex, addRegex } from "../../shared/utility";

const headCells = [
  { id: 'name', type: 'text', numeric: false, disablePadding: false, label: 'Role Name' },
  { id: 'description', type: 'description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'parentName', type: 'text', numeric: false, disablePadding: false, label: 'Parent Role' },
  { id: 'createdOn', type: 'createdOn', numeric: false, disablePadding: false, label: 'Created On' },
  { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];

const RoleObj = {
  "id" : "",
  "name":"",
	"description":"",
  "parentName":"",
  "parentId":"",
	"permissions": []
}

const filterCells = [
  { id: 'name', type: 'text', label: 'Role Name' },
  { id: 'parentName', type: 'text', label: 'Parent Role Name' },
  { id: 'description', type: "text", label: "Description"},
];

const filterobj = {
  name: "",
  // pageNo: 1,
  // pageSize: 10,
  parentName: "",
  description: "",
  // sortBy:"createdOn",
  // sortDirection:"DESC",
}

// const sortArr = [
  // {key:"createdOn", label: "Create Date"},
  // {key:"name", label: "Role Name"},
  // {key:"parentName", label: "Parent Role Name"},
  // {key:"description", label: "Descriptiom"}
// ];

const Role = (props) => {
    const {classes} = useStyles();
    const { 
        roleList, permissionList, createRoleRes, userLoader, permissionListForView
    } = props;

    // const [pageNo, setPage] = useState(1);
    // const [sortDir, setsortDir] = useState('DESC');
    const [filterData, setFilterData] = useState([]);
    const [clientRow, setClientRow] = useState(null);
    const [viewState, setViewState] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [roleState, setRoleState] = useState(RoleObj);
    const [snackbarMsg, setSnackBarMsg] = useState(null);
    const [openPopover, setopenPopover] = useState(false);
    // const [sortName, setsortName] = useState('createdOn');
    const [currentForm, setCurrentForm] = useState("list");
    const [openSnackbar, SetOpenSnackbar] = useState(false);
    const [compInitaited, setCompInitiated] = useState(false);
    const [selectAllPerm, setSelectAllPerm] = useState(false);
    const [filterState, setFilterState] = useState(filterobj);
    const [parentPermissionList, setParentPermissionList] = useState([]);
    
    const createButtonFlag = currentForm !== "view" && roleState && roleState.name !== "" && roleNameRegex.test(roleState.name) &&
    roleState.parentName !== "" && (roleState.description === "" || addRegex.test(roleState.description))
    
    const currentPage = localStorage.getItem('currentPage')
    let permissions = localStorage.getItem("permissions")
    permissions = permissions && permissions !== null ? JSON.parse(permissions) : []

    const roleArray = roleList && roleList !== null ? 
        roleList.map(obj => {

        let roleObj = {
            ...obj,
            createdOn: obj.createdOn && obj.createdOn,
              //  !== "" ? `${obj.createdOn.slice(0,10)} ${obj.createdOn.slice(11,19)}` : "",
            searchColumn: headCells.map((cell, index) => 
              cell.id === "createdOn" && obj.createdOn && obj.createdOn !== "" ? 
                `${obj.createdOn.slice(0,10)} ${obj.createdOn.slice(11,19)}`
                : cell.id !== "action" &&  obj[cell.id] &&  obj[cell.id] !== "" ?  
                  obj[cell.id] : "" 
            ).join(" ")
        }
        return roleObj
    }) : []

    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    });
  
    const onChangeView = (inpval, row) => {
        setCurrentForm(inpval);
        if(inpval === "view") {
            setClientRow(row)
            !row.parentId && row.parentId === "" ? props.fetchPermissionDetailsForView({"roleId": row.id}) : 
            props.fetchPermissionDetailsForView({"roleId": row.parentId})
        } else if(inpval === "edit") {
            setRoleState(row)
            props.fetchRoleDetails({})
            props.fetchPermissionDetails({"roleId": row.parentId})
        } else if(inpval === "create") {
            setRoleState(RoleObj)
            props.fetchRoleDetails({})
        } else {
            props.fetchRoleDetails({...filterState,
              // pageNo: 1, sortBy: sortName, sortDirection: sortDir
            })
        }
    }

    useEffect(() => {
        if(currentPage && currentPage !== null && currentPage !== undefined && currentPage === "/roles" && compInitaited){
          setCurrentForm('list')
          setFilterState({...filterobj});
          // setPage(1);
          // setsortDir("DESC");
          // setsortName("createdOn")
          // props.fetchRoleDetails({
          //   // pageNo: 1, pageSize: 10,
          //   // sortBy:"createdOn", sortDirection:"DESC"
          // })
          localStorage.removeItem('currentPage')
        }
    }, [currentPage])

    useEffect(() => {
      const handleInitialCall = async () => {
        await setCompInitiated(true);
        await props.fetchRoleDetails({...filterState,
          // pageNo: pageNo, sortBy: sortName, sortDirection: sortDir
        })
        await localStorage.removeItem('currentPage')
      }
      handleInitialCall();
    }, [])

    useEffect(() => {
        if(permissionListForView && currentForm === "view") {
            let selectAllFlag = true;
            const tempPermissions = permissionListForView.map((obj) => {
                if(clientRow.permissions.indexOf(obj.id) === -1) {
                  selectAllFlag = false;
                }
                return {
                    displayName: obj.displayName,
                    id: obj.id,
                    selected: clientRow.permissions.indexOf(obj.id) !== -1
                }
            })
            setViewState({
                id: clientRow.id,
                name: clientRow.name,
                description: clientRow.description,
                parentId: clientRow.parentId,
                parentName: clientRow.parentName,
                hierarchy: clientRow.hierarchy,
                permissions: tempPermissions
            })
            setSelectAllPerm(selectAllFlag)
        }
    }, [permissionListForView])

    useEffect(() => {
        if (roleState && roleState.parentName && roleState.parentName !== "" &&  permissionList && (currentForm === "edit" || currentForm === "create")) {
            const tempPermissions = permissionList.map((obj) => {
                return {
                    displayName: obj.displayName,
                    id: obj.id,
                    selected: roleState.permissions.indexOf(obj.id) !== -1,
                }
            }) 
            setParentPermissionList(tempPermissions)
            // setRoleState({...roleState, permissions: tempPermissions});

            setSelectAllPerm(permissionList.length === roleState.permissions.length);
        }
        
    }, [permissionList])
    
    useEffect(() => {
        if(createRoleRes && createRoleRes !== null && createRoleRes.st) {
            if(currentForm === "create" ) {
                setopenPopover(true)
            } else {
                SetOpenSnackbar(true)
                setSnackBarMsg(`Role Modified Successfully!`)
                onChangeView("list")
            }
            //reset role response
            props.resetRoleCreateResp()
        } else if(createRoleRes && createRoleRes !== null && !createRoleRes.st) {
            SetOpenSnackbar(true)
            setSnackBarMsg(createRoleRes.msg && createRoleRes.msg)
            onChangeView("list")
            //reset role response
            props.resetRoleCreateResp()
        }
    }, [createRoleRes])

    const handleSnackBarAction = () => {
        SetOpenSnackbar(false)
        setSnackBarMsg(null)
    }

    const handleInputChange = (key, value) => {

      let updatedRoleState = {...roleState};
      if(key === "parentRole") {
        
        // adding country details & reseting other fields
          updatedRoleState = {
          ...updatedRoleState,
          permissions: [],
          parentName: value[1],
          parentId: value[0],
        }
        setParentPermissionList([])
        props.fetchPermissionDetails({
          "roleId": value[0],
        })

      } else if(key === "roleName") {
        //update store name and display name
          updatedRoleState = {
          ...updatedRoleState,
          name: value[0],
        }
      } else if(key === "description") {
        //setting user details
        updatedRoleState = {
          ...updatedRoleState,
          description: value[0],
          } 
      }
      setRoleState(updatedRoleState)
    }

    const handlePermissionsChange = (index, value) => {
        let tempId = -1;
        let tempPermissions = []
        parentPermissionList && parentPermissionList.map((obj, indx) => {
          if(index === indx) {
              tempId = obj.id;
              tempPermissions.push({...obj, selected: value})
          } else {
              tempPermissions.push(obj);
          }
          return obj
        })
            
        const indx = roleState.permissions.indexOf(tempId);
        let permissionIdList = roleState.permissions;
        if(indx !== -1) {
            setRoleState({...roleState, permissions: [...permissionIdList.slice(0, indx), ...permissionIdList.slice(indx+1)]});
            setSelectAllPerm(false);
        } else {
            permissionIdList.push(tempId)
            setRoleState({...roleState, permissions: permissionIdList});
            setSelectAllPerm(parentPermissionList.length === roleState.permissions.length)
        }
        setParentPermissionList(tempPermissions);
        
    }

    const handlePermissionCal = (arr) => {
      const temp = arr
      if((temp.includes("2") || temp.includes("3")) && !temp.includes("1")) {
        temp.push("1")
      } 
      if((temp.includes("5") || temp.includes("6") || temp.includes("7")) && !temp.includes("4")) {
        temp.push("4")
      } 
      if((temp.includes("9") || temp.includes("10") || temp.includes("11")) && !temp.includes("8")) {
        temp.push("8")
      } 
      if((temp.includes("13") || temp.includes("14")) && !temp.includes("12") ) {
        temp.push("12")
      } 
      if((temp.includes("16") || temp.includes("17")) && !temp.includes("15") ) {
        temp.push("15")
      } 
      if((temp.includes("19") || temp.includes("20")) && !temp.includes("18") ) {
        temp.push("18")
      } 
      if((temp.includes("22") || temp.includes("23")) && !temp.includes("21") ) {
        temp.push("21")
      } 
      if((temp.includes("25") || temp.includes("26")) && !temp.includes("24")) {
        temp.push("24")
      } 
      if((temp.includes("28") || temp.includes("29")) && !temp.includes("27") ) {
        temp.push("27")
      } 
      if((temp.includes("31") || temp.includes("32")) && !temp.includes("30") ) {
        temp.push("30")
      } 
      if((temp.includes("34") || temp.includes("35")) && !temp.includes("33") ) {
        temp.push("33")
      } 
      if((temp.includes("37") || temp.includes("38")) && !temp.includes("36") ) {
        temp.push("36")
      } 
      if((temp.includes("40") || temp.includes("41")) && !temp.includes("39") ) {
        temp.push("39")
      } 
      if((temp.includes("43") || temp.includes("44")) && !temp.includes("42") ) {
        temp.push("42")
      } 
      if((temp.includes("46") || temp.includes("47")) && !temp.includes("45") ) {
        temp.push("45")
      }
      if((temp.includes("81") || temp.includes("82")) && !temp.includes("80") ) {
        temp.push("80")
      }
      return temp
    }

    const handleSelectAllPermissions = (value) => {

      let tempPermissions = []
      let tempPermissionIdList = parentPermissionList &&
        parentPermissionList.map((obj, indx) => {
          tempPermissions.push({...obj, selected: value})
          return obj.id
        })
          
      setRoleState({...roleState, permissions: !value ? [] : tempPermissionIdList});
      setParentPermissionList(tempPermissions);
      setSelectAllPerm(value);
    }

    const handleRoleCreateUpdate = () => {
        let permissionList = []
        parentPermissionList && parentPermissionList.map(obj => {
                if(obj.selected) {
                    permissionList.push(obj.id);
                }
                return obj
            }) 
        let temp = handlePermissionCal(permissionList);

        let req = {
            "id": currentForm === "edit" ? roleState.id : null,
            "name": roleState.name,
            "description":roleState.description,
            "parentId": roleState.parentId,
            "parentName": roleState.parentName,
            "permissions": temp,
        }
        props.createOrEditRole(req)
    
    }

    const handleDialogAction = async (type) => {
        setopenPopover(false)
        setRoleState(RoleObj)
        if(type === "exit" ){
            onChangeView('list')
        } else {
            await onChangeView('list')
            await onChangeView('create')
        }
    }

    const handleSetFilterData = (filterArray, searchValue) => {
        setFilterData(filterArray)
        setSearchValue(searchValue)
    }

    const handleFilterStateChange = (key, value) => {
      let tempFilter = {
          ...filterState,
          [key]: value
      }
      setFilterState(tempFilter);
  }
  
  const resetFilter = () => {
      setFilterState(filterobj);
      // setPage(1);
      // setsortDir("DESC");
      // setsortName("createdOn");
      const payloadObj = {
          // pageNo: 1,
          // pageSize: 10,
          // sortBy:"createdOn",
          // sortDirection:"DESC"
      }
      props.fetchRoleDetails(payloadObj)
  }

  const FilterApply = () => {
      // setPage(1)
      const payloadObj = { ...filterState, 
        // pageNo: 1, sortBy: sortName, sortDirection: sortDir
      }
      props.fetchRoleDetails(payloadObj)
      setFilterState({...filterState});
  }

  // const handlePageNo = (val) => {
  //     setPage(val)
  //     const payloadObj = { ...filterState, pageNo: val, sortBy: sortName, sortDirection: sortDir}
  //     props.fetchRoleDetails(payloadObj)
  // }

  // const handleSortDir = (value) => {
  //     setsortDir(value)
  //     setPage(1)
  // }

  // const handleSortName = (value) => {
  //     setsortName(value)
  //     setPage(1)
  // }

  return (
    <>
      <BasePageToolbar currentForm={currentForm}  create={permissions.includes("31")} onChangeView={onChangeView} resetFilter={resetFilter} 
        title={currentForm === 'list' ? "Roles" :  currentForm === 'view' ? "View Role" : currentForm === "edit" ? "Modify Role" : "Create Role"}
        handleFilterStateChange={handleFilterStateChange} filterState={filterState} filterCells={filterCells} FilterApply={FilterApply}
        // setsortName={handleSortName} setsortDir={handleSortDir} sortDir={sortDir} sortName={sortName} sortArr={sortArr}
        arrayToFilter={roleArray} handleSetFilterData={handleSetFilterData} isTableSearchReq={false} searchValue={searchValue}
      ></BasePageToolbar>
      <BasePageContainer>
        {currentForm === 'list' ?
          <Grid container className={classes.container}>
            <TableComponent
              sortOrder="desc" sortOrderBy="createdOn" type={"role"}
              onChangeView={onChangeView} 
              // handlePageNo={handlePageNo}
              // totalRecord={totalRecords} 
              // showEdit={permissions.includes("32")} 
              showEdit={false} 
              rows={searchValue && searchValue !== "" ? filterData : roleArray}
              loading={userLoader} activeTab={filterState} headCells={headCells}
            />
          </Grid>
          : currentForm === 'create' ?
            <Grid container className={classes.formContainer}>
              <CreateEditViewRole 
                  currentForm={currentForm}
                  roleState={roleState}
                  handleInputChange={handleInputChange}
                  handleRoleCreateUpdate={handleRoleCreateUpdate}
                  parentRoleList={roleList}
                  permissionList={permissionList}
                  createButtonFlag={!createButtonFlag}
                  handlePermissionsChange={handlePermissionsChange}
                  parentPermissionList={parentPermissionList}
                  handleSelectAllPermissions={handleSelectAllPermissions}
                  selectAllPerm={selectAllPerm}
              />
            </Grid>
            : currentForm === "edit" ?
            <Grid container className={classes.formContainer}>
              <CreateEditViewRole 
                  currentForm={currentForm}
                  roleState={roleState}
                  handleInputChange={handleInputChange}
                  handleRoleCreateUpdate={handleRoleCreateUpdate}
                  parentRoleList={roleList}
                  permissionList={permissionList}
                  createButtonFlag={!createButtonFlag}
                  handlePermissionsChange={handlePermissionsChange}
                  parentPermissionList={parentPermissionList}
                  handleSelectAllPermissions={handleSelectAllPermissions}
                  selectAllPerm={selectAllPerm}
              />
            </Grid> :
              <Grid container className={classes.formContainer}>
                  <CreateEditViewRole 
                    currentForm={currentForm} 
                    roleDetails={viewState}
                    onChangeView={onChangeView}
                    selectAllPerm={selectAllPerm}
                  />
              </Grid>
          }

      <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => handleSnackBarAction()}
        action={
        <React.Fragment>
          <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
        }
      >
        <Alert onClose={() => handleSnackBarAction()} severity={
          createRoleRes && createRoleRes !== null && !createRoleRes.st ? "info" : "success"
        }>
            {snackbarMsg}
        </Alert>
      </Snackbar>
      <Dialog
        open={openPopover}
        onClose={() => handleDialogAction("exit")}
        className={"delete_dialog"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="close_dialog" onClick={() => handleDialogAction("exit")}><img src={CloseImgIcon} alt="close" /></div>
        <DialogTitle id="alert-dialog-title">
          <h2>{"Role Created Successfully!"}</h2>
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {`Do you want to Create new Role?`}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" className="yes" autoFocus
              onClick={() => handleDialogAction("create")} 
          >
            Create New
          </Button>
          <Button color="primary" className="no"
              onClick={() => handleDialogAction("exit")} 
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      </BasePageContainer>
    </>)
}
const useStyles = tss.create({
  formContainer: {
    paddingTop: theme.spacing(0),
  },
  container: {
    paddingTop: theme.spacing(2),
  },
});

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
        fetchRoleDetails, fetchPermissionDetails, 
        createOrEditRole, resetRoleCreateResp,
        fetchPermissionDetailsForView
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    roleList: state.UserReducer.roleList !== null && state.UserReducer.roleList.rolesList ? 
        state.UserReducer.roleList.rolesList : [],
    totalRecords: state.UserReducer.roleList !== null && state.UserReducer.roleList.totalRecords ? 
        state.UserReducer.roleList.totalRecords : [],
    permissionList: state.UserReducer.permissionList !== null && state.UserReducer.permissionList.permissions ? 
        state.UserReducer.permissionList.permissions : [],
    permissionListForView: state.UserReducer.permissionListForView !== null && state.UserReducer.permissionListForView.permissions ? 
        state.UserReducer.permissionListForView.permissions : [],
    createRoleRes: state.UserReducer.createRoleRes,
    userLoader: state.UserReducer.userLoader,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Role);
