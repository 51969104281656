import { TerminalActions } from "../consts/index";

const initialState = {
    terminalList: null,
    terminalLoader: false,
    createTerResponse: null,
    deleteTerResponse: null,
    hardwareTypeList: null
}

export default (state = initialState, {type, payload}) => {
    switch(type) {
        case TerminalActions.fetchTerminalDetails.REQUEST:
            return {
                ...state,
                terminalLoader: true
            }
        case TerminalActions.fetchTerminalDetails.SUCCESS:
            return {
                ...state,
                terminalLoader: false,
                terminalList: payload
            }
        case TerminalActions.fetchTerminalDetails.FAILURE:
            return {
                ...state,
                terminalList: null,
                terminalLoader: false,
            }

        case TerminalActions.createTerminalDetails.REQUEST:
            return {
                ...state
            }
        case TerminalActions.createTerminalDetails.SUCCESS:
            return {
                ...state,
                createTerResponse: payload
            }
        case TerminalActions.createTerminalDetails.FAILURE:
            return {
                ...state,
                createTerResponse: payload
            }

        case TerminalActions.deleteTerminal.REQUEST:
            return {
                ...state
            }
        case TerminalActions.deleteTerminal.SUCCESS:
            return {
                ...state,
                deleteTerResponse: payload
            }
        case TerminalActions.deleteTerminal.FAILURE:
            return {
                ...state,
                deleteTerResponse: payload
            }

            case TerminalActions.fetchHardwareTypes.REQUEST:
                return {
                    ...state,
                    terminalLoader: true
                }
            case TerminalActions.fetchHardwareTypes.SUCCESS:
                return {
                    ...state,
                    terminalLoader: false,
                    hardwareTypeList: payload
                }
            case TerminalActions.fetchHardwareTypes.FAILURE:
                return {
                    ...state,
                    hardwareTypeList: null,
                    terminalLoader: false,
                }

        default:
            return state;
    }
}