import React, { useState } from 'react'
import {
    Grid, TextField, Button, MenuItem, Typography
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Filter = (props) => {
    const {
        classes, currentForm, oemName, groupName, brandArray, onChangeFilter, applyFilter
    } = props;
    const [valObj, setValObj] = useState({ 
        oemError: false
    })

    const onChangeValue = (type, value) => {
        
        if(type === "oemName") {
            setValObj({...valObj, oemError: false })
            onChangeFilter(type, value);
        } else if(type === "groupName") {
            onChangeFilter(type, value);
        }

    }

    const handleOnBlur = (type, value) => {
        if(value === null || value === "") {
            setValObj({...valObj, [type]: true })
        }
    }

    return (
        <React.Fragment>
            <div className={"paper_scroll"}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12} sm={12} justify="flex-start" className={classes.addRange}>
                        <Typography className={classes.title} variant="h6" id="filterTitle" component="div">
                            Filter Product Groups
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            InputLabelProps={{ shrink: true }} 
                            variant="outlined" fullWidth required 
                            disabled={currentForm !== "create"}  select={currentForm === "create"}  
                            id="oemName" name="oemName" label="Brand Name" 
                            value={oemName}
                            onChange={(e) => e.target.value !== "NA" && onChangeValue("oemName", e.target.value)}
                            onBlur={(e) => e.target.value !== "NA" && handleOnBlur("oemError", e.target.value)}
                        >
                            {brandArray && brandArray.length === 0 && 
                                <MenuItem key={1000} value={"NA"} className={classes.menu}>
                                {"No Brands Available!"}</MenuItem>
                            }
                            {(brandArray).map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                            })}
                        </TextField>
                        {valObj.oemError ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                            </p>
                            :null
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            variant="outlined" fullWidth disabled={currentForm === "view"}
                            id="groupName" name="groupName" label="Product Group Name"
                            value={groupName}
                            onChange={(e) => onChangeValue("groupName", e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} justify="flex-start" className={classes.addRange}></Grid>
                    <Grid item xs={12} sm={6} style={{ display: "flex", justifyContent: "space-evenly" }} justify="flex-end">
                        <Button 
                            onClick={() => applyFilter("apply")} 
                            disabled={currentForm === "view" || oemName === null}
                            className={`action_login_button`}
                        >
                            Apply
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}

export default Filter;