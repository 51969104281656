import React from 'react'
import {
    Grid, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../../../_theme'
import {Delete} from '@mui/icons-material'

const useStyles = tss.create({
    root: {
      flexGrow: 1,
      maxWidth: 752,
      border: "solid 1px #EEEEEE",
    },
    demo: {
      backgroundColor: theme.palette.background.paper,
      border: "solid 1px #EEEEEE",
    },
    title: {
      margin: theme.spacing(4, 0, 2),
    },
});

const SelectedGroups = (props) => {
    const {classes} = useStyles();
    const {
        selectedProdGroups, handleRemoveGroup, currentForm
    } = props
    
    return (
        <div className="paper_scroll">
            <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={12} sm={12} justify="flex-start" className={classes.addRange}>
                    <Typography className={classes.title} variant="h6" id="selectedGroupTitle" component="div">
                        Selected Product Groups
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className={classes.demo}>
                        <List dense={false}>
                            {selectedProdGroups.map((obj, index) => {
                                return (
                                    <ListItem>
                                        <ListItemText
                                            primary={obj.name}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="delete" onClick={() => {handleRemoveGroup(obj.id)}} disabled={currentForm === "view"}>
                                                <Delete/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            })
                            }
                        </List>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default SelectedGroups