import { actionCreator, BASE_URL } from "../../../shared/utility";
import { ProductActions } from "../consts/index";

export function fetchProductDetails(payload) {
    const id = payload.id && payload.id !== "" ? `&id=${payload.id}` : ""
    const productCode = payload.productCode && payload.productCode !== "" ? `&productCode=${payload.productCode}` : ""
    const status = payload.status && payload.status !== "" ? `&status=${payload.status}` : ""
    const isoCountryCodeNum = payload.isoCountryCodeNum && payload.isoCountryCodeNum !== "" ? `&isoCountryCodeNum=${payload.isoCountryCodeNum}` : ""
    const brandId = payload.brandId && payload.brandId !== "" ? `&brandId=${payload.brandId}` : ""
    const endURL = `?pageNo=${payload.pageNo}&pageSize=${payload.pageSize}&sortDirection=${payload.sortDirection}&sortBy=${payload.sortBy}` +
        id + status + productCode + isoCountryCodeNum + brandId

    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductActions.handleProductDetailsFetch.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/products${endURL}`, {
            method: 'GET',
            // body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
        .then(response => response.json())
          .then(function (res) {
            if(!res.st) {
                if(res.responseCode && res.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductActions.handleProductDetailsFetch.FAILURE))
                }
            } else {
                dispatch(actionCreator(ProductActions.handleProductDetailsFetch.SUCCESS, res))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchCountryDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductActions.handleCountryDetailsFetchForProduct.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/getCountryListForScheme`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => response.json())
          .then(function (res) {
            if(!res.st) {
                if(res.responseCode && res.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductActions.handleCountryDetailsFetchForProduct.FAILURE))
                }
            } else {
                dispatch(actionCreator(ProductActions.handleCountryDetailsFetchForProduct.SUCCESS, res))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchBrandDetails(payload) {
    return(dispatch,getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductActions.handleBrandDetailsFetchForProduct.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/getBrandlist`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => response.json())
          .then(function (res) {
            if(!res.st) {
                if(res.responseCode && res.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductActions.handleBrandDetailsFetchForProduct.FAILURE))
                }
            } else {
                dispatch(actionCreator(ProductActions.handleBrandDetailsFetchForProduct.SUCCESS, res))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function clearBrandDetail(payload) {
    return(dispatch) => {
        dispatch(actionCreator(ProductActions.handleBrandDetailsFetchForProduct.FAILURE))
    }
}

export function fetchMasterProductCategories(payload) {

    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductActions.fetchMasterProductCategories.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/products/categories`, {
            method: 'GET',
            // body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
        .then(response => response.json())
          .then(function (res) {
            if(!res.st) {
                if(res.responseCode && res.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductActions.fetchMasterProductCategories.FAILURE))
                }
            } else {
                dispatch(actionCreator(ProductActions.fetchMasterProductCategories.SUCCESS, res))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function createProduct(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductActions.handleCreateOrEditProductSubmit.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/products/product`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
        .then(response => response.json())
          .then(function (res) {
            if(!res.st) {
                if(res.responseCode && res.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductActions.handleCreateOrEditProductSubmit.FAILURE, res))
                }
            } else {
                dispatch(actionCreator(ProductActions.handleCreateOrEditProductSubmit.SUCCESS, res))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function modifyProduct(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductActions.handleCreateOrEditProductSubmit.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/products/product`, {
            method: 'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
        .then(response => response.json())
          .then(function (res) {
            if(!res.st) {
                if(res.responseCode && res.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductActions.handleCreateOrEditProductSubmit.FAILURE, res))
                }
            } else {
                dispatch(actionCreator(ProductActions.handleCreateOrEditProductSubmit.SUCCESS, res))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function clearProductRes(payload) {
    return(dispatch) => {
        dispatch(actionCreator(ProductActions.handleCreateOrEditProductSubmit.SUCCESS, payload))
    }
}

