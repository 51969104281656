import React, { useState, useEffect } from 'react'
import {connect} from "react-redux";
import Grid from '@mui/material/Grid';
import { bindActionCreators } from "redux";
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Snackbar, Dialog, DialogActions,
  DialogTitle, DialogContentText, DialogContent, Button } from '@mui/material'
import { tss } from "tss-react";
import theme from '../../_theme'

import TableComponent from "../table/index";
import BasePageToolbar from '../../_common/basePageToolbar'
import CreateEditViewStore from "./createEditViewStore/index";
import {EmailRegex, SpecialCharRegex1, addRegex, nameRegExp, descrRegex, numbRegex} from "../../shared/utility";
import BasePageContainer from '../../_common/basePageContainer';
import {fetchCountryDetails} from "../../manageScheme/actions/index";
import {fetchPSPMetadata, resetPSPMetadata} from "../psp/actions/index";
import {fetchStoreDetails, createStore, resetStoreCreateResp} from "./actions/index";
import {fetchMerchantMetadata, resetMerchantMetadata} from "../merchant/actions/index";

const headCells = [
  { id: 'storeId', type: 'text', numeric: false, disablePadding: false, label: 'Store Id' },
  { id: 'name', type: 'text', numeric: false, disablePadding: false, label: 'Store Name' },
  { id: 'storeDisplayName', type: 'text', numeric: false, disablePadding: false, label: 'Display Name' },
  { id: 'storeDescription', type: 'description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'countryName', type: 'text', numeric: false, disablePadding: false, label: 'Country Name' },
  { id: 'merchantName', type: 'text', numeric: true, disablePadding: false, label: 'Merchant Name' },
  { id: 'status', type: 'text', numeric: true, disablePadding: false, label: 'Status' },
  { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];
const StorObj = {
  "storeId": null,
  "storeDisplayName": "",
  "name": "",
  "countryName" : "",
  "storeDescription": "",
  "address": { 
    "address1": "", "address2": "",
    "address3": "", "state": "",
    "city": "", "postalCode": ""
  },
  "isoCountryCodeNum": "",
  "contactPersonDetail": {
      "name": "",
      "phoneNo": "",
      "mobileNo": "",
      "emailId": ""
  },
  "pspName": "",
  "pspId": "",
  "merchantId": "",
  "merchantName" : "",
  "status": "ACTIVE",
}

const filterCells = [
  { id: 'isoCountryCodeNum', type: 'select', label: 'Country' },
  { id: 'pspId', type: 'select', label: 'Psp Name' },
  { id: 'merchantName', type: 'select', label: 'Merchant Name' },
  { id: 'name', type: 'select', label: 'Store Name' },
  { id: 'city', type: 'text', label: 'City' },
  { id: 'state', type: 'text', label: 'State' },
  { id: 'postalCode', type: 'text', label: 'Postal Code' },
];

const filterobj = {
  city: "",
  name: "",
  state: "",
  pspId: "",
  postalCode: "",
  merchantName: "",
  isoCountryCodeNum: "",
  pageNo: 1,
  pageSize: 10,
  sortBy:"createdOn",
  sortDirection:"DESC"
}

const sortArr = [
  {key:"createdOn", label: "Create Date"},
  {key:"countryName", label: "Country Name"},
  {key:"merchantName", label: "Merchant Name"},
  {key:"name", label: "Store Name"},
  {key:"status", label: "Status"},
]

const Store = (props) => {
  const {classes} = useStyles();
  const {StoreList, countryDetails, pspMetaData, merchantMetadata, createStoreRes, storeLoader, storeListCount } = props;
  const [clientRow, setClientRow] = useState(null);
  const [snackbarMsg, setSnackBarMsg] = useState(null);
  const [currentForm, setCurrentForm] = useState("list");
  const [openSnackbar, SetOpenSnackbar] = useState(false);
  const [responseId, setResponseId] = useState("");
  const [openPopover, setopenPopover] = useState(false);
  const [StoreState, setStoreState] = useState(StorObj)
  const currentPage = localStorage.getItem('currentPage')
  const [filterData, setFilterData] = useState([]);
  const [pageNo, setPage] = useState(1)
  const [sortName, setsortName] = useState('createdOn')
  const [sortDir, setsortDir] = useState('DESC');
  const [searchValue, setSearchValue] = useState("");
  const [compInitaited, setCompInitiated] = useState(false);

  let permissions = localStorage.getItem("permissions")
  const [filterState, setFilterState] = useState(filterobj)
  permissions = permissions && permissions !== null ? JSON.parse(permissions) : []
  const userid = localStorage.getItem("userid")

  const nextDisableToken = currentForm !== "view" && StoreState && StoreState.countryName !== "" &&
    StoreState.pspName !== "" && StoreState.merchantName !== "" && StoreState.name !== "" && nameRegExp.test(StoreState.name) &&
    StoreState.storeDisplayName !== "" && nameRegExp.test(StoreState.storeDisplayName) &&
    //StoreState.storeDescription !== "" && addRegex.test(StoreState.storeDescription) && 
    // (StoreState.address['address1'] === "" || StoreState.address['address1'] === null || 
      (StoreState.address['address1'] !== "" && StoreState.address['address1'] !== null && addRegex.test(StoreState.address['address1'])) && StoreState.address['address1'].length <= 50 &&
      // ) && 
    (StoreState.address['address2'] === "" || StoreState.address['address2'] === null || 
      (StoreState.address['address2'] !== "" && StoreState.address['address2'] !== null && addRegex.test(StoreState.address['address2']))) && 
    (StoreState.address['address3'] === "" || StoreState.address['address3'] === null || 
      (StoreState.address['address3'] !== "" && StoreState.address['address3'] !== null && addRegex.test(StoreState.address['address3']))) &&
    (StoreState.address['state'] === "" || StoreState.address['state'] === null || 
      (StoreState.address['state'] !== "" && StoreState.address['state'] !== null && SpecialCharRegex1.test(StoreState.address['state']))) &&
    (StoreState['address'].postalCode !== "" && StoreState['address'].postalCode !== null && numbRegex.test(StoreState['address'].postalCode)) &&
    (StoreState.address['city'] === "" || StoreState.address['city'] === null || 
      (StoreState.address['city'] !== "" && StoreState.address['city'] !== null && SpecialCharRegex1.test(StoreState.address['city']))) &&
    (StoreState.contactPersonDetail['name'] === "" || StoreState.contactPersonDetail['name'] === null || 
      (StoreState.contactPersonDetail['name'] !== "" && StoreState.contactPersonDetail['name'] !== null && SpecialCharRegex1.test(StoreState.contactPersonDetail['name']))) && 
    (!StoreState.contactPersonDetail['phoneNo'] || numbRegex.test(StoreState.contactPersonDetail['phoneNo'])) &&
    (!StoreState.contactPersonDetail['mobileNo'] || numbRegex.test(StoreState.contactPersonDetail['mobileNo'])) &&
    (StoreState.contactPersonDetail['emailId'] === "" || StoreState.contactPersonDetail['emailId'] === null || 
      (StoreState.contactPersonDetail['emailId'] !== "" && StoreState.contactPersonDetail['emailId'] !== null && EmailRegex.test(StoreState.contactPersonDetail['emailId']))) &&
    ( StoreState.storeDescription === "" || StoreState.storeDescription === null ||
      (StoreState.storeDescription !== "" && StoreState.storeDescription !== null && descrRegex.test(StoreState.storeDescription))) &&
      StoreState.address['state'] !== "" && StoreState.address['city'] !== ""

  const storeArray = StoreList && StoreList !== null ? 
    StoreList.map(obj => {

        let schemeObj = {
          ...obj,
          searchColumn: headCells.map((cell, index) => 
            cell.id ==="action" ? "" : obj[cell.id] && obj[cell.id]
          ).join(" ")
        }
        return schemeObj
    }) : []

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  });
  
  const onChangeView = (inpval, row) => {
    setCurrentForm(inpval);
    if(inpval === "view") {
      setClientRow(row)
    } else if(inpval === "edit") {
      setStoreState(row)

      //fetch details for edit drop down
      handleMetaDataCall(row)

    } else if(inpval === "create") {
      setStoreState(StorObj)
      countryDetails && countryDetails.length === 0 && props.fetchCountryDetails({})

    } else {
      props.fetchStoreDetails({...filterState,
        pageNo: 1, sortBy: sortName, sortDirection: sortDir
      })
    }
  }

  useEffect(() => {
    if(currentPage && currentPage !== null && currentPage !== undefined && currentPage === "/store" && compInitaited){
      setCurrentForm('list')
      setFilterState({...filterobj});
      setPage(1);
      setsortDir("DESC");
      setsortName("createdOn")
      props.fetchStoreDetails({
        pageNo: 1,
        pageSize: 10,
        sortBy:"createdOn",
        sortDirection:"DESC"
      })
      localStorage.removeItem('currentPage')
    }
  }, [currentPage])

  useEffect(() => {
    const handleInitialCall = async () => {
      await setCompInitiated(true);
      await props.fetchStoreDetails({...filterState,
        pageNo: pageNo, sortBy: sortName, sortDirection: sortDir
      })
      await localStorage.removeItem('currentPage')
    }
    handleInitialCall();

    return () => {
      //add all list reset API here
      props.resetPSPMetadata()
      props.resetMerchantMetadata()
      //reset psp response
      props.resetStoreCreateResp()
    }
  }, [])
  
  const handleFilterStateChange = (key, value) => {
    let tempFilter = {
        ...filterState,
        [key]: value
    }
    if(key === "isoCountryCodeNum") {
      tempFilter = {
        ...tempFilter,
        pspId: "",
        merchantName: "",
        name: ""
      }
    } else if(key === "pspId") {
      tempFilter = {
        ...tempFilter,
        merchantName: "",
        name: ""
      }
    } else if(key === "merchantName") {
      tempFilter = {
        ...tempFilter,
        name: ""
      }
    }
    setFilterState(tempFilter);
  }

  const resetFilter = () => {
    setFilterState(filterobj);
    setPage(1);
    setsortDir("DESC");
    setsortName("createdOn")
    props.fetchStoreDetails({
      pageNo: 1,
      pageSize: 10,
      sortBy:"createdOn",
      sortDirection:"DESC"
    })
  }

  const FilterApply = () => {
    setPage(1)
    props.fetchStoreDetails({ ...filterState, pageNo: 1, sortBy: sortName, sortDirection: sortDir})
    setFilterState({...filterState});
  }

  useEffect(() => {
    if(createStoreRes && createStoreRes !== null && createStoreRes.st) {
      setResponseId(createStoreRes.id)
      if(currentForm === "create" ) {
        setopenPopover(true)
      } else {
        SetOpenSnackbar(true)
        setSnackBarMsg(`Store Modified Successfully!`)
        onChangeView("list")
      }
      //reset psp response
      props.resetStoreCreateResp()
    } else if(createStoreRes && createStoreRes !== null && !createStoreRes.st) {
      SetOpenSnackbar(true)
      setSnackBarMsg(createStoreRes.msg && createStoreRes.msg)
      // onChangeView("list")
    }
  }, [createStoreRes])

  const handleMetaDataCall = (row) => {
    countryDetails && countryDetails.length === 0 && props.fetchCountryDetails({})

    //call psp merchant and store API here  ---------------------------->>>>>>>
    // props.fetchPSPMetadata({
    //   "isoCountryCodeNum": row.isoCountryCodeNum ? row.isoCountryCodeNum : ""
    // })
    // props.fetchMerchantMetadata({
    //   "pspId" : row.pspId ? row.pspId : "",
    // })
  }

  const handleSnackBarAction = () => {
    SetOpenSnackbar(false)
    setSnackBarMsg(null)
    //reset psp response
    props.resetStoreCreateResp()
  }

  const handleInputChange = (key, value) => {

    let updatedStoreState = {...StoreState};
    if(key === "country") {
      
      // adding country details & reseting other fields
      updatedStoreState = {
        ...updatedStoreState,
        pspName: "",
        pspId: "",
        merchantId: "",
        merchantName: "",
        countryName: value[1],
        isoCountryCodeNum: value[0],
      }
      props.resetMerchantMetadata()

      //fetch PSP details
      props.fetchPSPMetadata({
        "isoCountryCodeNum": value[0],
        "status": "ACTIVE"
      })

    } else if(key === "psp") {

      //update psp and reset merchant and store
      updatedStoreState = {
        ...updatedStoreState,
        merchantId: "",
        merchantName: "",
        pspName: value[1],
        pspId: value[0],
      }

      //fetch merchant details
      props.fetchMerchantMetadata({
        "pspId" : value[0],
        "status": "ACTIVE"
      })

    } else if(key === "merchant") {
      
      //update merchant and reset store
      updatedStoreState = {
        ...updatedStoreState,
        merchantId: value[0],
        merchantName: value[1],
      }

    } else if(key === "storeName") {
      //update store name and display name
      updatedStoreState = {
        ...updatedStoreState,
        name: value[0],
        // storeDisplayName: value[0],
      }
    } else if(key === "storeDisplayName") {
      //update store name and display name
      updatedStoreState = {
        ...updatedStoreState,
        // name: value[0],
        storeDisplayName: value[0],
      }

    } else if(key === "user") {
      //setting user details
      updatedStoreState = {
        ...updatedStoreState,
        contactPersonDetail: {
          ...updatedStoreState['contactPersonDetail'],
          ...value
        }
      }

    } else if(key === "address") {
      //setting address details
      updatedStoreState = {
        ...updatedStoreState,
        address: {
          ...updatedStoreState['address'],
          ...value
        }
      }

    } else {
      //update other details
      updatedStoreState = {
        ...updatedStoreState,
        [key]: value[0]
      }

    }
    setStoreState(updatedStoreState)
  }

  const handleStoreCreateUpdate = () => {
    let req = {
      "id" : null,
      "isoCountryCode": "",
      "latitude": "",
      "longitude": "",
      "totalAreaSqFeet": "",
      "subArea": "",
      "landMark": "",
      "txnLimit": "",
      "timezoneConfig": {
        "offset": "",
        "timezone": ""
      },
      "language": "",
      "status": "ACTIVE",
      "additionalParam": "",
      "externalReferenceId": "",
      "storeParameters": {
          "key": "value",
          "key2": "value2"
        },
        ...StoreState,
        "userId": JSON.parse(userid),
    }
    props.createStore(req)
    setFilterState(filterobj);
    setSearchValue("")

  }

  const handleDialogAction = async (type) => {
    setopenPopover(false)
    setStoreState(StorObj)
    if(type === "exit" ){
      onChangeView('list')
    } else {
      await onChangeView('list')
      await onChangeView('create')
    }
  }

  const handleSetFilterData = (filterArray, searchValue) => {
    setFilterData(filterArray)
    setSearchValue(searchValue)
  }

  const handlePageNo = (val) => {
    setPage(val)
    props.fetchStoreDetails({...filterState,
      pageNo: val, sortBy: sortName, sortDirection: sortDir
    });
  }

  const handleSortDir = (value) => {
    setsortDir(value)
    setPage(1)
  }

  const handleSortName = (value) => {
    setsortName(value)
    setPage(1)
  }

  return (
    <>
      <BasePageToolbar 
        currentForm={currentForm}  create={permissions.includes("19")} onChangeView={onChangeView} resetFilter={resetFilter}
        title={currentForm === 'list' ? "Store Detail" :  currentForm === 'view' ? "View Store" : currentForm === 'edit' ? "Modify Store" : "Create Store"}
        handleFilterStateChange={handleFilterStateChange} filterState={filterState} filterCells={filterCells} FilterApply={FilterApply}
        setsortName={handleSortName} setsortDir={handleSortDir} sortDir={sortDir} sortName={sortName} sortArr={sortArr}
        arrayToFilter={storeArray} handleSetFilterData={handleSetFilterData} isTableSearchReq={false} searchValue={searchValue}
      ></BasePageToolbar>
      <BasePageContainer>
        {currentForm === 'list' ?
          <Grid container className={classes.container}>
            <TableComponent
              sortOrder="asc"
              sortOrderBy="id"
              loading={storeLoader}
              headCells={headCells}
              activeTab={filterState}
              // rows={StoreList}
              rows={searchValue && searchValue !== "" ? filterData : storeArray}
              onChangeView={onChangeView}
              requestType="StoreList"
              totalRecord={storeListCount}
              handlePageNo={handlePageNo}
              showEdit={permissions.includes("20")}
            />
          </Grid>
        : currentForm === 'create' ?
          <Grid container className={classes.formContainer}>
            <CreateEditViewStore 
              PSPList={pspMetaData}
              StoreState={StoreState}
              currentForm={currentForm}
              countryDetails={countryDetails}
              merchantList={merchantMetadata}
              nextDisableToken={!nextDisableToken}
              handleInputChange={handleInputChange}
              handleStoreCreateUpdate={handleStoreCreateUpdate}
            />
          </Grid>
          : currentForm === 'edit' ? <Grid container className={classes.formContainer}>
              <CreateEditViewStore 
                PSPList={pspMetaData}
                StoreState={StoreState}
                currentForm={currentForm}
                countryDetails={countryDetails}
                merchantList={merchantMetadata}
                nextDisableToken={!nextDisableToken}
                handleInputChange={handleInputChange}
                handleStoreCreateUpdate={handleStoreCreateUpdate}
              />
            </Grid>
            : <Grid container className={classes.formContainer}>
              <CreateEditViewStore 
                currentForm={currentForm} 
                productDetails={clientRow}
                onChangeView={onChangeView}
              />
            </Grid>
        }
        <Snackbar
          anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
          }}
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={() => handleSnackBarAction()}
          action={
          <React.Fragment>
            <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
          }
        >
          <Alert onClose={() => handleSnackBarAction()} severity={
            createStoreRes && createStoreRes !== null && !createStoreRes.st ? "info" : "success"
          }>
              {snackbarMsg}
          </Alert>
        </Snackbar>
        <Dialog
          open={openPopover}
          onClose={() => handleDialogAction("exit")}
          className={"delete_dialog"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <h2>{`Store ${responseId} Created Successfully!`}</h2>
          </DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  {`Do you want to Create new Store?`}
              </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className={classes.color}
                onClick={() => handleDialogAction("create")} 
            >
              Create New
            </Button>
            <Button className={classes.color}
                onClick={() => handleDialogAction("exit")} 
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </BasePageContainer>
    </>)
}
const useStyles = tss.create({
  formContainer: {
    paddingTop: theme.spacing(0),
  },
  container: {
    paddingTop: theme.spacing(2),
  },
  color: {
    color: "white",
    backgroundColor: `#1BA785 !important`
  },
});

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
        fetchStoreDetails, fetchPSPMetadata,
        fetchMerchantMetadata, fetchCountryDetails, 
        resetPSPMetadata, resetMerchantMetadata, 
        resetStoreCreateResp, createStore
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    StoreList: state.StoreReducer.StoreList !== null &&
      state.StoreReducer.StoreList.stores ? 
        state.StoreReducer.StoreList.stores : [],
    countryDetails: state.SchemeReducer.countryDetails !== null &&
      state.SchemeReducer.countryDetails.countryList ?
        state.SchemeReducer.countryDetails.countryList : [],
    storeListCount: state.StoreReducer.StoreList !== null && state.StoreReducer.StoreList.totalRecords ?
      state.StoreReducer.StoreList.totalRecords : 0,
    pspMetaData: state.PspReducer.pspMetaData !== null && state.PspReducer.pspMetaData.pspList ?
      state.PspReducer.pspMetaData.pspList : [],
    merchantMetadata: state.MerchantReducer.merchantMetadata !== null &&
      state.MerchantReducer.merchantMetadata.merchantList ?
        state.MerchantReducer.merchantMetadata.merchantList : [],
    createStoreRes: state.StoreReducer.createStoreRes,
    storeLoader: state.StoreReducer.storeLoader
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Store);
