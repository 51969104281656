import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
// import {reduxForm} from 'redux-form';
import { bindActionCreators } from "redux";
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import OutlinedInput from '@mui/material/OutlinedInput';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Grid, TextField, MenuItem, Button, Paper, Checkbox,
    Dialog, DialogActions, Snackbar, DialogTitle, DialogContent}   from '@mui/material';
import { tss } from "tss-react";
import theme from '../../../_theme'

import {EmailRegex, SpecialCharRegex1, numbRegex, passRegExp, usernameRegex} from "../../../shared/utility";
import { NO_NUMBERS_AND_SPECIAL_CHARACTERS, ONLY_NUMBERS } from "shared/errorMessages";
import CloseImgIcon from '../../../Assets/close.svg';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = tss.create({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        border: "solid 1px #EEEEEE",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(5),
    },
    input: {
        display: 'none',
    },
    menu: {
        fontSize: "15px"
    },
    marginTopBottom: {
        margin: theme.spacing(2, 0, 2, 0),
    },
});

function CreateEditViewUser(props) {
    const {classes, cx} = useStyles();
    const { 
        currentForm, userState, roleList, handleInputChange, createDisableToken, 
        handleUserCreateUpdate, userDetails, onChangeView, handleClickShowPassword,
        resetUserPassword, changePasswordRes, passIncUserName, cnfPassIncUserName
    } = props;

    const [valObj, setValObj] = useState({
        roleErr: false, firstNameErr: false, lastNameErr: false, emailErr: false, 
        userNameErr: false, passwordErr: false, confirmPasswordErr: false, mobileErr: false, passwordMatchErr: false
    })
    const [passwordState, setPasswordState] = useState({
        password: '',
        showPassword: false,
        confirmPassword: "",
        showConfirmPassword: false,
    });
    const [passwordValObj, setPasswordValObj] = useState({
        passwordErr: false,
        confirmPasswordErr: false,
        passwordMatchErr: false,
    });
    const [openPopover, setopenPopover] = useState(false);
    const [snackbarMsg, setSnackBarMsg] = useState(null);
    const [openSnackbar, SetOpenSnackbar] = useState(false);
    const inCorrectPassMsg= `Please enter password between 8 to 15 Char, including atleast 1 Capital letter, 
        1 small letter, 1 digit and one special char!`;
        
    const [chngPassIncUserName, setchngPassIncUserName] = React.useState(false);
    const [chngCnfPassIncUserName, setchngCnfPassIncUserName] = React.useState(false);

    const resetPasswordFlag = passwordState && passwordState.password !== "" && 
        passwordState.confirmPassword !== "" && passwordState.password === passwordState.confirmPassword && 
        passRegExp.test(passwordState.password) && passRegExp.test(passwordState.confirmPassword) && 
        !chngPassIncUserName && !chngCnfPassIncUserName;
    
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    });
        
    let roleArr = []
    const roleArrObj = roleList && 
        roleList.filter((obj) => {
            if (obj.name && obj.name !== "") {
                roleArr.push(obj.name)
            }
            return obj.name && obj.name !== ""
        })

    useEffect(() => {
        if(currentForm === "edit") {
            if(changePasswordRes && changePasswordRes !== null && changePasswordRes.st) {
                SetOpenSnackbar(true)
                setSnackBarMsg(`Password Changed Successfully!`)
                props.resetChangePasswordResp()
            } else if (changePasswordRes && changePasswordRes !== null && !changePasswordRes.st) {
                SetOpenSnackbar(true)
                setSnackBarMsg(changePasswordRes.msg && changePasswordRes.msg)
                props.resetChangePasswordResp()
            }
        }
    }, [changePasswordRes])

    const handleChange = (type, value, errField) => {

        if(type === "role"){
            const roleIdArr = [...value.slice(0,5)].map(val => 
                roleArr.indexOf(val) !== -1 ? roleArrObj[roleArr.indexOf(val)].id : null
            ).filter(val => val !== null);

            handleInputChange(type, [ [...value.slice(0,5)], roleIdArr ])
            if(value.length === 0) {
                setValObj({...valObj, roleErr: true });
            } else if(value.length > 5) {
                setValObj({...valObj, roleErr: "true" });
                setTimeout(() => {
                    setValObj({...valObj, roleErr: false });
                }, 3000)
            } else {
                setValObj({...valObj, roleErr: false });
            }

        } else if(type === "confirmPassword") {
            handleInputChange(type, value)
            if(value === userState.password) {
                setValObj({...valObj, confirmPasswordErr: false })
            } else {
                setValObj({...valObj, confirmPasswordErr: true })
            }

        } else {
            handleInputChange(type, value)
            setValObj({...valObj, [errField]: false })

        } 
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const validateConfirmPassword = (value) => {
        if(userState.password === value){
            setValObj({...valObj, confirmPasswordErr: false })
        } else {
            setValObj({...valObj, confirmPasswordErr: true })
        } 
    };

    const validateResetConfirmPassword = (value) => {
        if(passwordState.password === value){
            setPasswordValObj({...passwordValObj, passwordMatchErr: false, confirmPasswordErr: false})
        } else {
            setPasswordValObj({...passwordValObj, passwordMatchErr: true, confirmPasswordErr: false})
        } 
    };
    
    const handleOnBlur = (field, value) => {
        if(value === null || value === ""){
            setValObj({...valObj, [field]: true })
        } else {
            setValObj({...valObj, [field]: false })
        } 
    }

    const handleChangePasswordPopover = () => {
        setPasswordState({
            password: '',
            showPassword: false,
            confirmPassword: "",
            showConfirmPassword: false,
        })
        setPasswordValObj({
            passwordErr: false,
            confirmPasswordErr: false,
            passwordMatchErr: false,
        })
        setopenPopover(true)
    }

    const handleClickShowChangePassword = (key) => {
        if(key === "password") {
            setPasswordState({ ...passwordState, showPassword: !passwordState.showPassword });
        } else if(key === "confirmPassword") {
            setPasswordState({ ...passwordState, showConfirmPassword: !passwordState.showConfirmPassword });
        }
    };

    const handleChangePasswordChange = (key, value) => {
        if(key === "password") {
            setPasswordValObj({...passwordValObj, passwordErr: false})
            setPasswordState({ ...passwordState, password: value});
            if(userState['userName'] && userState['userName'] !== "") {
                const userArr =  [...(userState['userName'].toUpperCase()).split(" ")]
                let flag = false;
                  
                userArr.map(name => {
                    if(((value).toUpperCase()).includes(name) && !flag) {
                        flag = true
                    }
                    return null;
                })
                
                const str = userState['userName'].toUpperCase();
                const str1 = value.toUpperCase();
                let i = 0;
                while (i < str.length) {
                    if(str1.includes(`${str}${str}`.slice(i, (i+4)))
                    ) {
                        flag = true
                        break;
                    }
                    i++;
                }
                setchngPassIncUserName(flag)
            }

        } else if(key === "confirmPassword") {
            validateResetConfirmPassword(value)
            setPasswordState({ ...passwordState, confirmPassword: value});
            if(userState['userName'] && userState['userName'] !== "") {
                const userArr =  [...(userState['userName'].toUpperCase()).split(" ")]
                let flag = false;
                  
                userArr.map(name => {
                    if(((value).toUpperCase()).includes((name)) && !flag) {
                        flag = true
                    }
                    return null;
                })

                const str = userState['userName'].toUpperCase();
                const str1 = value.toUpperCase();
                let i = 0;
                while (i < str.length) {
                    if(str1.includes(`${str}${str}`.slice(i, (i+4)))
                    ) {
                        flag = true
                        break;
                    }
                    i++;
                }
                setchngCnfPassIncUserName(flag)
            }
        }
    }

    const handleDialogAction = (type) => {
        if(type === "ChangePassword") {
            if(passwordState.password === "") {
                setPasswordValObj({...passwordValObj, passwordErr: true})
            } else if(passwordState.confirmPassword === "") {
                setPasswordValObj({...passwordValObj, confirmPasswordErr: true})
            } else if(passwordState.confirmPassword !== passwordState.password) {
                setPasswordValObj({...passwordValObj, passwordMatchErr: true})
            } else {
                setopenPopover(false)
                resetUserPassword({"userName" : userState.userName, "password": passwordState.password})
            }
        } else {
            setopenPopover(false)
        }
    }

    const handleSnackBarAction = () => {
        SetOpenSnackbar(false)
        setSnackBarMsg(null)
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <form className="action_form merchant_form" noValidate autoComplete="off">
                    <Grid container rowSpacing={2} columnSpacing={2}>

                    {currentForm === "edit" && <>
                        <Grid item xs={12} sm={8} />
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                name="status" variant="outlined" select required
                                fullWidth id="status" label="User status"
                                value={ userState.status && userState.status }
                                onChange={(e) => handleInputChange('status', e.target.value)}
                            >
                                {["ACTIVE", "BLOCKED", "TERMINATED"].map((val, index) => {
                                    return <MenuItem key={index} value={val} className={classes.menu}>
                                        {val}
                                    </MenuItem>
                                })}
                            </TextField>
                        </Grid>
                    </>}

                    {/* <Grid item xs={12} sm={6}>
                        <TextField 
                            InputLabelProps={{ shrink: true }} 
                            required fullWidth label="Select Role" 
                            variant="outlined" name="role" id="role" 
                            select={currentForm !== 'view'} disabled={currentForm === 'view'}
                            value={currentForm === 'view' ? 
                                userDetails !== null && userDetails.roleName && 
                                userDetails.roleName : userState.roleName ? 
                                userState.roleName : null
                            } 
                            onBlur={(e) => handleOnBlur("roleErr", e.target.value)}
                            onChange={e => roleArr.length > 0 && handleChange("role", e.target.value, "roleErr")}
                        >
                            {roleArr && roleArr.length === 0 && 
                                <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>
                            }
                            {roleArr.map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                            })}
                        </TextField>
                        {valObj.roleErr ? 
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                        </p>
                        :null}
                    </Grid> */}

                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            options={roleArr} multiple disabled={currentForm === 'view'}
                            value={currentForm === 'view' ? 
                                userDetails !== null && userDetails.roleName && typeof userDetails.roleName === "string" ? 
                                    [userDetails.roleName].map(val => val) : userDetails.roleName.map(val => val)
                                : userState && userState.roleName ? typeof userState.roleName === "string" ? 
                                    [userState.roleName].map(val => val) : userState.roleName.map(val => val) : []
                            } 
                            getOptionLabel={(option) => {
                                return option
                            }} 
                            disableCloseOnSelect
                            onChange={(e, newValue) => currentForm !== 'view' && 
                                handleChange('role', newValue, "roleErr") }
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                            )}
                            renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Role"
                                InputLabelProps={{ shrink: true }} required
                                disabled={currentForm === 'view'} name="role" id="role"
                            />
                            )}
                        />
                        {valObj.roleErr === "true" ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Maximum 5 Roles can be selected!"}</span>
                            </p> 
                            : valObj.roleErr ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                </p>
                            : null
                        }
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField 
                            InputLabelProps={{ shrink: true }} 
                            required
                            fullWidth 
                            variant="outlined" 
                            name="firstName" 
                            id="firstName" 
                            label={"First Name"}
                            value={currentForm === 'view' ? 
                                userDetails !== null && userDetails.firstName && 
                                userDetails.firstName : userState.firstName ? 
                                userState.firstName : null
                            } 
                            disabled={currentForm === 'view' ?  true : false}
                            onBlur={(e) => handleOnBlur("firstNameErr", e.target.value)}
                            onChange={e => e.target.value.length <= 30 && handleChange("firstName", e.target.value, "firstNameErr")}
                        />
                        {valObj.firstNameErr ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                            </p>
                            :userState && userState.firstName && userState.firstName !== "" && 
                                !SpecialCharRegex1.test(userState.firstName) ? 
                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                        <span className="error_message"><InfoOutlinedIcon />{NO_NUMBERS_AND_SPECIAL_CHARACTERS}</span>
                                    </p>
                                :null
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            InputLabelProps={{ shrink: true }} 
                            required
                            fullWidth 
                            variant="outlined" 
                            name="lastName" 
                            id="lastName" 
                            label={"Last Name"}
                            value={currentForm === 'view' ? 
                                userDetails !== null && userDetails.lastName && 
                                userDetails.lastName : userState.lastName ? 
                                userState.lastName : null
                            } 
                            disabled={currentForm === 'view' ?  true : false}
                            onBlur={(e) => handleOnBlur("lastNameErr", e.target.value)}
                            onChange={e => e.target.value.length <= 30 && handleChange("lastName", e.target.value, "lastNameErr")}
                        />
                        {valObj.lastNameErr ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                            </p>
                            :userState && userState.lastName && userState.lastName !== "" && 
                                !SpecialCharRegex1.test(userState.lastName) ? 
                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                        <span className="error_message"><InfoOutlinedIcon />{NO_NUMBERS_AND_SPECIAL_CHARACTERS}</span>
                                    </p>
                                :null
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            InputLabelProps={{ shrink: true }} 
                            required
                            fullWidth 
                            variant="outlined" 
                            name="emailId" 
                            id="emailId" 
                            label={"Email Id"}
                            value={currentForm === 'view' ? 
                                userDetails !== null && userDetails.email && 
                                userDetails.email : userState.email ? 
                                userState.email : null
                            } 
                            disabled={currentForm === 'view' ?  true : false}
                            onBlur={(e) => handleOnBlur("emailErr", e.target.value)}
                            onChange={e => e.target.value.length <= 50 && handleChange("email", e.target.value, "emailErr")}
                        />
                        {valObj.emailErr ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                            </p>
                            :userState && userState.email && userState.email !== "" && 
                                !EmailRegex.test(userState.email) ? 
                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                        <span className="error_message"><InfoOutlinedIcon /> {"Invalid Email Address!"}</span>
                                    </p>
                                :null
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            InputLabelProps={{ shrink: true }} 
                            required
                            fullWidth 
                            variant="outlined" 
                            name="userName" 
                            id="userName" 
                            label={"Username"}
                            value={currentForm === 'view' ? 
                                userDetails !== null && userDetails.userName && 
                                userDetails.userName : userState.userName ? 
                                userState.userName : null
                            } 
                            disabled={currentForm !== 'create' ?  true : false}
                            onBlur={(e) => handleOnBlur("userNameErr", e.target.value)}
                            onChange={e => e.target.value.length <= 40 && handleChange("userName", e.target.value, "userNameErr")}
                        />
                        {valObj.userNameErr ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                            </p>
                            :userState && userState.userName && userState.userName !== "" && 
                                !usernameRegex.test(userState.userName) ? 
                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                        <span className="error_message"><InfoOutlinedIcon /> {"Invalid Username!"}</span>
                                    </p>
                                :null
                        }
                    </Grid>
                    {currentForm !== "create" &&
                        <Grid item xs={12} sm={6}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} required fullWidth disabled
                                variant="outlined" name="password" id="password" label={"Password"}
                                value={currentForm === 'view' ? 
                                    userDetails !== null && userDetails.password && 
                                    userDetails.password : userState.password ? 
                                    userState.password : ""
                                }
                            />
                            {/* {currentForm === "edit" &&
                                <Button fullWidth //variant="contained"
                                    color="primary" //className={"action_login_button"}
                                    //disabled={createDisableToken}
                                    onClick={() => handleChangePasswordPopover()}
                                    >
                                    Click here to change the password!
                                </Button>} */}
                        </Grid>
                    }
                    {currentForm !== "create" &&
                        <Grid item xs={12} sm={6}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                required
                                fullWidth 
                                variant="outlined" 
                                name="confirmPassword" 
                                id="confirmPassword" 
                                label={"Confirm Password"}
                                value={currentForm === 'view' ? 
                                    userDetails !== null && userDetails.password && 
                                    userDetails.password : userState.password ? 
                                    userState.password : ""
                                } 
                                disabled
                            />
                        </Grid>
                    }

                    {currentForm === "create" && <Grid item xs={12} sm={6}>
                        <FormControl 
                            fullWidth 
                            required
                            variant="outlined"
                        >
                            <InputLabel htmlFor="outlined-adornment-user-password" shrink={true}> Password </InputLabel>
                            <OutlinedInput 
                                name="userPassword" id="outlined-adornment-user-password"
                                type={userState.showPassword ? 'text' : 'password'}
                                labelwidth={65} value={userState.password ? userState.password : ""} 
                                onChange={(e) => handleChange('password', e.target.value)} 
                                endAdornment={ <InputAdornment position="end">
                                    <IconButton 
                                    aria-label="toggle password visibility" className="password_icon"
                                    onClick={() => handleClickShowPassword("password")} edge="end"
                                    onMouseDown={(e) => handleMouseDownPassword(e)} 
                                    >
                                    {userState.showPassword ? <Visibility /> : <VisibilityOff />} 
                                    </IconButton>
                                </InputAdornment> }
                            />
                            {userState.password && userState.password === "" ?
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Password is Required!"}</span>
                                </p>
                                : userState.password && !passRegExp.test(userState.password) ?
                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                        <span className="error_message"><InfoOutlinedIcon /> {inCorrectPassMsg}</span>
                                    </p> 
                                    : userState.password && passIncUserName ?
                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                            <span className="error_message"><InfoOutlinedIcon /> {"Password should not contain any part of username."}</span>
                                        </p> : null 
                            }
                        </FormControl>
                    </Grid> }

                    {currentForm === "create" &&
                    <Grid item xs={12} sm={6}>
                        <FormControl 
                            fullWidth 
                            variant="outlined"
                            required
                        >
                            <InputLabel htmlFor="outlined-adornment-password" shrink={true}> Confirm Password </InputLabel>
                            <OutlinedInput 
                                name="confirmPassword" id="outlined-adornment-confirm-password"
                                type={userState.showConfirmPassword ? 'text' : 'password'}
                                labelwidth={65} value={userState.confirmPassword ? userState.confirmPassword : ""}
                                onBlur={(e) => validateConfirmPassword(e.target.value)} 
                                onChange={(e) => handleChange('confirmPassword', e.target.value, "confirmPasswordErr")} 
                                endAdornment={ <InputAdornment position="end">
                                    <IconButton 
                                        aria-label="toggle password visibility" className="password_icon"
                                        onClick={() => handleClickShowPassword("confirmPassword")} edge="end"
                                        onMouseDown={(e) => handleMouseDownPassword(e)} 
                                    >
                                    {userState.showConfirmPassword ? <Visibility /> : <VisibilityOff />} 
                                    </IconButton>
                                </InputAdornment>}
                            />
                            {userState.confirmPassword && userState.confirmPassword === "" ?
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Confirm Password is Required!"}</span>
                                </p>
                                : valObj.confirmPasswordErr ? 
                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                        <span className="error_message"><InfoOutlinedIcon /> {"Confirm password should be same as Password"}</span>
                                    </p>
                                    : userState.confirmPassword && !passRegExp.test(userState.confirmPassword) ?
                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                            <span className="error_message"><InfoOutlinedIcon /> {inCorrectPassMsg}</span>
                                        </p> : userState.confirmPassword && cnfPassIncUserName ?
                                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                                <span className="error_message"><InfoOutlinedIcon /> {"Confirm password should not contain any part of username."}</span>
                                            </p> : null  
                            }
                        </FormControl>
                    </Grid>
                    }
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            InputLabelProps={{ shrink: true }} 
                            // required
                            fullWidth 
                            variant="outlined" 
                            name="mobile" 
                            id="mobile" 
                            label={"Mobile No"}
                            value={currentForm === 'view' ? 
                                userDetails !== null && userDetails.mobile && 
                                userDetails.mobile : userState.mobile ? 
                                userState.mobile : null
                            } 
                            disabled={currentForm === 'view' ?  true : false}
                            onBlur={() => handleOnBlur("mobileErr")}
                            onChange={e => e.target.value.length <= 20 && handleChange("mobile", e.target.value, "mobileErr")}
                        />
                        {userState && userState.mobile && userState.mobile !== "" && 
                        !numbRegex.test(userState.mobile) ? 
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                        </p>
                        :null}
                    </Grid>
                </Grid>
                
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Button fullWidth variant="contained"
                                color="primary" className={"action_login_button"}
                                disabled={createDisableToken}
                                onClick={() => currentForm !== "view" ? handleUserCreateUpdate() : onChangeView("list")}
                                >
                                {currentForm === "edit" ? "Modify" : currentForm === "view" ? "Close" : "Create"}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>

            <Dialog
                open={openPopover}
                onClose={() => handleDialogAction("exit")}
                className={"delete_dialog"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="close_dialog" onClick={() => handleDialogAction("exit")}><img src={CloseImgIcon} alt="close" /></div>
                <DialogTitle id="alert-dialog-title">
                    <h2>{"Change Password"}</h2>
                </DialogTitle>
                <DialogContent dividers>
                    <div>
                        <Grid container rowSpacing={2} columnSpacing={2}>
                            <Grid item xs={12}>
                                <FormControl 
                                    fullWidth 
                                    required
                                    variant="outlined"
                                    className={cx(classes.marginTopBottom)}
                                    >
                                    <InputLabel htmlFor="outlined-adornment-password1" > Enter New Password </InputLabel>
                                    <OutlinedInput 
                                        name="userPassword" 
                                        id="outlined-adornment-new-password"
                                        type={passwordState.showPassword ? 'text' : 'password'}
                                        labelwidth={145} 
                                        value={passwordState.password} 
                                        onChange={(e) => handleChangePasswordChange('password', e.target.value)} 
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton 
                                                aria-label="toggle password visibility" className="password_icon"
                                                onClick={() => handleClickShowChangePassword("password")} edge="end"
                                                onMouseDown={(e) => handleMouseDownPassword(e)} 
                                            >
                                            {passwordState.showPassword ? <Visibility /> : <VisibilityOff />} 
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                    />
                                    {passwordValObj.passwordErr ? 
                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                            <span className="error_message"><InfoOutlinedIcon /> {"Password is Required!"}</span>
                                        </p>
                                        : passwordState.password && !passRegExp.test(passwordState.password) ?
                                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                                <span className="error_message"><InfoOutlinedIcon /> {inCorrectPassMsg}</span>
                                            </p> : passwordState.password && chngPassIncUserName ?
                                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                                    <span className="error_message"><InfoOutlinedIcon /> {"Password should not contain any part of username."}</span>
                                                </p> : null 
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl 
                                    fullWidth 
                                    variant="outlined"
                                    required
                                    >
                                    <InputLabel htmlFor="outlined-adornment-password1" > Confirm New Password </InputLabel>
                                    <OutlinedInput 
                                        name="confirmPassword" 
                                        id="outlined-adornment-confirm-new-password"
                                        type={passwordState.showConfirmPassword ? 'text' : 'password'}
                                        labelwidth={165} 
                                        value={passwordState.confirmPassword} 
                                        onChange={(e) => handleChangePasswordChange('confirmPassword', e.target.value)} 
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton 
                                            aria-label="toggle password visibility" className="password_icon"
                                            onClick={() => handleClickShowChangePassword("confirmPassword")} edge="end"
                                            onMouseDown={(e) => handleMouseDownPassword(e)} 
                                            >
                                            {passwordState.showConfirmPassword ? <Visibility /> : <VisibilityOff />} 
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                    />
                                    {passwordValObj.confirmPasswordErr  ?
                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                        <span className="error_message"><InfoOutlinedIcon /> {"Confirm Password is Required!"}</span>
                                        </p>
                                        : passwordValObj.passwordMatchErr ? 
                                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                                <span className="error_message"><InfoOutlinedIcon /> {"Confirm password should be same as Password"}</span>
                                            </p>
                                            : passwordState.confirmPassword && !passRegExp.test(passwordState.confirmPassword) ?
                                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                                    <span className="error_message"><InfoOutlinedIcon /> {inCorrectPassMsg}</span>
                                                </p> : passwordState.password && chngCnfPassIncUserName ?
                                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                                        <span className="error_message"><InfoOutlinedIcon /> {"Confirm password should not contain any part of username."}</span>
                                                    </p> : null 
                                    }
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" className="yes" autoFocus
                        onClick={() => handleDialogAction("ChangePassword")} 
                        disabled={!resetPasswordFlag}
                    >
                        Change Password
                    </Button>
                    <Button color="primary" className="no"
                        onClick={() => handleDialogAction("exit")} 
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => handleSnackBarAction()}
            action={
                <React.Fragment>
                    <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
                    <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
            >
                <Alert onClose={() => handleSnackBarAction()} 
                severity={
                    changePasswordRes && changePasswordRes !== null && !changePasswordRes.st ? "error" : "success"
                    }
                >
                    {snackbarMsg}
                </Alert>
            </Snackbar>
        
        </div>
    )
}

// CreateEditViewUser = reduxForm({
//     form: 'CreateEditViewUser',
//     // validate: validate,
//     enableReinitialize: true
// })(CreateEditViewUser);

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            
        }, dispatch)
  }
}

function mapStateToProps(state) {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateEditViewUser);
