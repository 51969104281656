import { SharedActions } from "./consts";

const initialState = {
    sharedLoader: false,
    uploadDocumentResp: null,
    docUploadLoader: null
}

export default (state = initialState, {type, payload}) => {
    switch(type) {
        case SharedActions.uploadDocument.REQUEST:
            return {
                ...state,
                sharedLoader: true,
                docUploadLoader: payload.key ? payload.key : null
            }
        case SharedActions.uploadDocument.SUCCESS:
            return {
                ...state,
                sharedLoader: false,
                uploadDocumentResp: payload,
                docUploadLoader: null
            }
        case SharedActions.uploadDocument.FAILURE:
            return {
                ...state,
                sharedLoader: false,
                uploadDocumentResp: payload,
                docUploadLoader: null
            }

        default:
            return state;
    }
}