import React, { useState } from 'react'
import { Grid, TextField, MenuItem } from "@mui/material";
import { tss } from "tss-react";
import theme from '../../../_theme'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { urlRegex, tNcRegex, spaceRegexp } from "../../../shared/utility";
import { LIMITED_SPECIAL_CHARACTERS } from "shared/errorMessages";

export default function TermsAndConditions(props) {
    const {classes} = useStyles();
    const {
        tncObjData, index, tncType, currentForm,
        languagesArr, issuer, handleTnCChange
    } = props

    const [valObj, setValObj] = useState({
        urlErr: false, textErr: false, languageErr: false,
    })
    const ZERO = 0
    const ENGLISH = "English"

    const tncUrlVal = tncObjData.url && !urlRegex.test(tncObjData.url)
    const tncTextVal = tncObjData.text && tNcRegex.test(tncObjData.text)

    const handleChange = (type, value, index, errorType) => {
        if (type === "language") {
            handleTnCChange(type, languagesArr.find(obj => obj.name === value), index)
            setValObj({ ...valObj, languageErr: false })
        } else {
            handleTnCChange(type, value, index)
            setValObj({ ...valObj, [errorType]: false })
        }
    }

    const handleOnBlur = (field, value) => {
        if (value === null || value === "") {
            setValObj({ ...valObj, [field]: true })
        } else {
            setValObj({ ...valObj, [field]: false })
        }
    }
    
    return (
        <>
            <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        InputLabelProps={{ shrink: true }} required
                        variant="outlined" fullWidth disabled={currentForm === "view" || index === ZERO}
                        id={`language${index}`} name={`language${index}`} label={"Language"}
                        value={tncObjData.name}
                        select={currentForm !== "view" && index !== ZERO}
                        onBlur={(e) => handleOnBlur('languageErr', e.target.value)}
                        onChange={(e) => handleChange('language', e.target.value, index, 'languageErr')}
                    >
                        {languagesArr.map((val, index) => {
                            return <MenuItem key={index} id={val} value={val.name} className={classes.menu} disabled={val.name === ENGLISH}>
                                {val.name}
                            </MenuItem>
                        })}
                    </TextField>
                    {valObj.languageErr ?
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                        </p>
                        : null
                    }
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" fullWidth disabled
                        id={`preference${index}`} name={`preference${index}`} label={"Preference"}
                        value={tncObjData.preference}
                        // select={currentForm !== "view"}
                        // onChange={(e) => handleChange('preference', e.target.value, index)}
                    >
                        {/*["Primary", "Secondary"].map((val, index) => {
                            return <MenuItem key={index} id={val} value={val} className={classes.menu}>
                                {val}
                            </MenuItem>
                        })*/}
                    </TextField>
                </Grid>
                {tncType && tncType === "QR" &&
                    <Grid item xs={12} sm={12}>
                        <TextField
                            InputLabelProps={{ shrink: true }} required
                            variant="outlined" fullWidth inputProps={{ maxLength: 500 }}
                            id={`url${index}`} name={`url${index}`} label={"URL"} disabled={currentForm === "view" || issuer === null}
                            value={tncObjData.url}
                            onBlur={(e) => handleOnBlur('urlErr', e.target.value)}
                            onChange={(e) => handleChange('url', e.target.value, index, 'urlErr')}
                        />
                        {valObj.urlErr ?
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                </p>
                            : tncUrlVal ?
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Invalid URL format! Please enter a valid URL!"}</span>
                                </p> : null
                        }
                    </Grid>
                }
                <Grid item xs={12} sm={12}>
                    <TextField
                        InputLabelProps={{ shrink: true }} required={tncType && tncType === "TEXT"}
                        variant="outlined" fullWidth inputProps={tncType && tncType !== "TEXT" ? { maxLength: 100 } : {maxLength: 255 }}
                        id={`text${index}`} name={`text${index}`} label={"T&C Text"} disabled={currentForm === "view" || issuer === null}
                        value={tncObjData.text}
                        onBlur={(e) => handleOnBlur('textErr', e.target.value)}
                        onChange={(e) => handleChange('text', e.target.value, index, 'textErr')}
                    />
                    {tncType && tncType === "TEXT" && valObj.textErr ?
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                        </p>
                        : tncTextVal ? <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message"><InfoOutlinedIcon />{LIMITED_SPECIAL_CHARACTERS}</span>
                        </p> : tncObjData.text !== null && spaceRegexp.test(tncObjData.text) ?
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Invalid Entry! Terms and Conditions text should not be empty!"}</span>
                            </p> : null
                    }
                </Grid>
            </Grid>
        </>
    )
}

const useStyles = tss.create({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        border: "solid 1px #EEEEEE", backgroundColor: "#FAFAFA",
        marginBottom: theme.spacing(2),
        padding: "20px 30px",
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    gridRadio: {
        padding: "20px!important"
    },
    formRedio: {
        color: "#424242",
        fontFamily: "Quicksand-Medium",
        fontSize: "18px",
        letterSpacing: 0,
        lineHeight: "20px"
    },
    profileMenu: {
        marginLeft: '0px',
        width: "340px", border: "1px solid #EEEEEE",
        borderRadius: "3px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 6px 12px 0 rgba(0,0,0,0.08)"
    },
});