import React, {useEffect} from 'react';
import { bindActionCreators } from "redux";
import {connect} from "react-redux";
import {reduxForm} from 'redux-form';
import { Grid, TextField, Button, Paper, MenuItem
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../../../_theme'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {addRegex, nameRegExp, descrRegex} from "../../../shared/utility";
import { NO_SPECIAL_CHARACTERS } from "shared/errorMessages";

const useStyles = tss.create({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    border: "solid 1px #EEEEEE",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(5),
  },
  input: {
    display: 'none',
  },
  menu: {
    fontSize: "15px"
  },
});

function CreateEditViewAcquirer(props) {
  const {
    acquirerDetails, handleInputChange, acquirerState, countryDetails, countryArr, currentForm,
    handleAcquirerCreateUpdate, nextDisableToken, onChangeView
  } = props;
  
  const [valObj, setValObj] = React.useState({
    countryErr: false, nameErr: false, displayNameErr: false,
  })
  
  const {classes} = useStyles();
  
  // const countryArr = countryDetails && countryDetails.map(country => country.name ? (country.name).toUpperCase() : null).filter(val => val !== null)

  const handleOnBlur = (field, value) => {
    if(value === null || value === ""){
      setValObj({...valObj, [field]: true })
    } else {
      setValObj({...valObj, [field]: false })
    }
  }

  const handleChange = async (type, value) => {
    
    if(type === "country"){
      countryArr.indexOf(value) !== -1 && handleInputChange(type, [
        countryDetails[countryArr.indexOf(value)]['id'],
        value
      ])
      setValObj({...valObj, countryErr: false })
    } else if(type === "name"){
      handleInputChange(type, value)
      setValObj({...valObj, nameErr: false })
    } else if(type === "displayName"){
      handleInputChange(type, value)
      setValObj({...valObj, displayNameErr: false })
    }
  }

  return (
    <div className={classes.root}>
        <Paper className={classes.paper}>
            <form className="action_form merchant_form" noValidate autoComplete="off">
                <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            InputLabelProps={{ shrink: true }} 
                            name="country" variant="outlined" select={currentForm === 'create'} required
                            fullWidth id="country" label="Country" 
                            disabled={currentForm !== 'create'}
                            value={currentForm === 'view' ? 
                                acquirerDetails !== null && acquirerDetails.countryName && 
                                acquirerDetails.countryName : acquirerState.countryName ? 
                                acquirerState.countryName : null
                            } 
                            onBlur={(e) => handleOnBlur("countryErr", e.target.value)}
                            onChange={(e) => countryArr.length > 0 && handleChange('country', e.target.value)}
                        >
                            {countryArr && countryArr.length === 0 && 
                            <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                            {countryArr && countryArr.map((val, index) => {
                            return <MenuItem key={index} value={val} className={classes.menu}>
                                {val}
                            </MenuItem>
                            })}
                        </TextField>
                        {valObj.countryErr ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                            <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                            </p>
                        :null}
                    </Grid>
                
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            // className={"name_field"}
                            InputLabelProps={{ shrink: true }} required
                            name="name" id="name" fullWidth
                            label="Acquirer Name" variant="outlined"
                            disabled={currentForm !== 'create'}
                            value={currentForm === 'view' ?
                                acquirerDetails !== null && acquirerDetails.name && 
                                acquirerDetails.name : acquirerState.name ? 
                                acquirerState.name : null
                            }
                            onChange={(e) => e.target.value.length <= 50 && handleChange('name', [e.target.value])}
                            onBlur={(e) => handleOnBlur("nameErr", e.target.value)}
                        />
                        {acquirerState && acquirerState.name && acquirerState.name !== "" && 
                            !nameRegExp.test(acquirerState.name) ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                            </p> : acquirerState && acquirerState.name && acquirerState.name !== "" &&
                                acquirerState.name.length > 50 ? 
                                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                  <span className="error_message"><InfoOutlinedIcon /> {"Maximum 50 characters are allowed!"}</span>
                                  </p> : valObj.nameErr ? 
                                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                      <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                      </p> : null
                        }
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            // className={"name_field"}
                            InputLabelProps={{ shrink: true }} required
                            name="displayName" id="displayName" fullWidth
                            label="Display Name" variant="outlined"
                            disabled={currentForm === 'view'}
                            value={currentForm === 'view' ?
                                acquirerDetails !== null && acquirerDetails.displayName && 
                                acquirerDetails.displayName : acquirerState.displayName ? 
                                acquirerState.displayName : null
                            }
                            onChange={(e) => e.target.value.length <= 50 && handleChange('displayName', [e.target.value])}
                            onBlur={(e) => handleOnBlur("displayNameErr", e.target.value)}
                        />
                        {acquirerState && acquirerState.displayName && acquirerState.displayName !== "" && 
                            !nameRegExp.test(acquirerState.displayName) ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                              <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                            </p> : acquirerState && acquirerState.displayName && acquirerState.displayName !== "" &&
                                acquirerState.displayName.length > 50 ? 
                                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                  <span className="error_message"><InfoOutlinedIcon /> {"Maximum 50 characters are allowed!"}</span>
                                  </p> : valObj.displayNameErr ? 
                                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                      <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                      </p> : null
                        }
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField 
                            // className={"name_field"}
                            InputLabelProps={{ shrink: true }}
                            name="description" id="description" fullWidth
                            label="Description" variant="outlined"
                            disabled={props.currentForm === 'view'}
                            value={currentForm === 'view' ?
                                acquirerDetails !== null && acquirerDetails.description && 
                                acquirerDetails.description : acquirerState.description ? 
                                acquirerState.description : null
                            }
                            onChange={(e) => e.target.value.length <= 100 && handleInputChange('description', [e.target.value])}
                        />
                        {acquirerState && acquirerState.description && acquirerState.description !== "" && 
                            !descrRegex.test(acquirerState.description) ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                              <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                            </p> : null
                        }
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField 
                        InputLabelProps={{ shrink: true }} 
                        name="status" variant="outlined" select
                        fullWidth id="status" label="Acquirer status"
                        disabled={props.currentForm === 'view'}
                        value={currentForm === 'view' ?
                            acquirerDetails !== null && acquirerDetails.status && 
                            acquirerDetails.status : acquirerState.status ? 
                            acquirerState.status : null
                        }
                        onChange={(e) => handleInputChange('status', [e.target.value])}
                        >
                        {["ACTIVE", "INACTIVE"].map((val, index) => {
                            return <MenuItem key={index} value={val} className={classes.menu}>
                            {val}
                            </MenuItem>
                        })}
                        </TextField>
                    </Grid>

                </Grid>

                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Button fullWidth variant="contained"
                            color="primary" className={"action_login_button"}
                            disabled={nextDisableToken}
                            onClick={() => currentForm !== "view" ? handleAcquirerCreateUpdate() : onChangeView("list")}
                        >
                            {currentForm === "edit" ? "Modify" : currentForm === "view" ? "Close" : "Create"}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    </div>
  )
}

CreateEditViewAcquirer = reduxForm({
  form: 'CreateEditViewAcquirer',
  // validate: validate,
  enableReinitialize: true
})(CreateEditViewAcquirer);

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditViewAcquirer);
