import React, {useState, useEffect} from 'react';
import { bindActionCreators } from "redux";
import {connect} from "react-redux";
import {
  Grid, TextField, MenuItem, 
  Button, Paper, Typography, IconButton
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../../../_theme'
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import { 
  handleProductCategorySelect, handleDisplayNameSelect,
  handleDescriptionSelect, handleParentCategorySelect, 
  submitCreateOrEditProductCategoryForm, clearProductCateogryRes
} from "../actions";
import {fetchMasterProductCategories} from '../../product/actions'
import "../../uploadImage.css"
import { nameRegExp, descrRegex } from "../../../shared/utility";
import { NO_SPECIAL_CHARACTERS } from "shared/errorMessages";

const useStyles = tss.create({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    border: "solid 1px #EEEEEE",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(5),
  },
  input: {
    display: 'none',
  },
  menu: {
    fontSize: "15px"
  },
  titleText: {
    color: "#424242",
    fontFamily: "Quicksand",
    fontSize: "26px",
    letterSpacing: 0,
    lineHeight: "30px"
  },
  color: {
    color: "white",
    backgroundColor: `#1BA785 !important`
  },
});

function CreateEditViewCategory(props) {
  const {classes} = useStyles();
  const { 
    categoryDetails, productCategoryName, displayName, description,
    parentCategory, parentCategoryDetails, docUploadLoader,
    currentForm, onChangeView, categoryID, handleUploadImage, imageHandler, imageData
  } = props;
  
  const [status, setStatus] = useState("ACTIVE");
  const [valObj, setValObj] = useState({
    categoryNameErr: false, parentCategoryErr: false, displayNameErr: false, descriptionErr: false, 
  })

  const productCategoryId = categoryID ? categoryID : ""
    
  const createDisableFlag = currentForm !== "view" &&
    productCategoryName !== null && productCategoryName !== "" && nameRegExp.test(productCategoryName) && 
    displayName !== null && displayName !== "" && nameRegExp.test(displayName) &&  
    description !== null && description !== "" && descrRegex.test(description)

  useEffect(() => {
    props.fetchMasterProductCategories()
    return () => {
      props.clearProductCateogryRes(null)
    }
  }, [])

  useEffect(() => {
    const handleInitialCall = async () => {
      if(categoryDetails && (currentForm === "edit" || currentForm === "view")) {  
        await setStatus(categoryDetails.status)    
        await props.handleProductCategorySelect(categoryDetails.name)
        await props.handleDisplayNameSelect(categoryDetails.displayName);
        await props.handleDescriptionSelect(categoryDetails.description)
        await props.handleParentCategorySelect(categoryDetails.parentName)
      }
    }
    handleInitialCall();
  }, [categoryDetails])

  useEffect(() => {
    resetOnCreateSelect()
  }, [currentForm])

  let parentCategArray = []
  const parentObj = parentCategoryDetails && parentCategoryDetails.length > 0 ?
      parentCategoryDetails.map((obj) => {
        parentCategArray.push(obj.name)
        return {
          id: obj.id,
          name: obj.name
        }
      }) : []

  const resetOnCreateSelect = async () => {
    if(currentForm === "create") {
      await props.handleProductCategorySelect("");
      await props.handleDisplayNameSelect("");
      await props.handleDescriptionSelect("");
      await props.handleParentCategorySelect(null);
    }
  }  
  
  const handleInputChange = (e, type) => {
    if(type === "name") {
        props.handleProductCategorySelect(e.target.value)
        setValObj({...valObj, categoryNameErr: false })
    } else if(type === "displayName") {
        props.handleDisplayNameSelect(e.target.value)
        setValObj({...valObj, displayNameErr: false })
    } else {
        props.handleDescriptionSelect(e.target.value)
        setValObj({...valObj, descriptionErr: false })
    }
  }

  const handleParentCategorySelectChange = (e) => {
    props.handleParentCategorySelect(e)
  }

  const handleCreateProductCategorySubmit = () => {
    const reqPayload = {
      id: currentForm === "edit" ? productCategoryId : null,
      name: productCategoryName,
      displayName: displayName,
      description: description,
      parentId: parentCategory && parentCategory !== "" ? parentObj[parentCategArray.indexOf(parentCategory)].id : "",
      parentName: parentCategory && parentCategory !== "" ? parentCategory : "",
      status: status,
      userId: "1"
    }
    
    props.submitCreateOrEditProductCategoryForm(reqPayload);
    //calling final API for Creating Scheme
  }

  const handleOnBlur = (field, value) => {
    if(value === null || value === ""){
      setValObj({...valObj, [field]: true })
    } else {
      setValObj({...valObj, [field]: false })
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form className="action_form merchant_form" noValidate autoComplete="off">
          <Grid container rowSpacing={2} columnSpacing={2}>
            {currentForm === "edit" &&
                <>
                  <Grid item xs={12} sm={8} />
                  <Grid item xs={12} sm={4}>
                    <TextField 
                        InputLabelProps={{ shrink: true }} 
                        name="status" variant="outlined" select required
                        fullWidth id="status" label="Product Category status"
                        value={ status }
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        {["ACTIVE", "INACTIVE"].map((val, index) => {
                        return <MenuItem key={index} value={val} className={classes.menu}>
                            {val}
                        </MenuItem>
                        })}
                    </TextField>
                  </Grid>
                </>
              }
            <Grid item xs={12} sm={6}>
                <TextField 
                  InputLabelProps={{ shrink: true }} 
                  required
                  fullWidth 
                  variant="outlined" 
                  name="productCategory" 
                  id="productCategory" 
                  label={"Product Category"}
                  value={currentForm === "view" ? categoryDetails && categoryDetails.name && categoryDetails.name : productCategoryName}
                  disabled={currentForm !== 'create' ?  true : false}
                  onChange={e => e.target.value.length <= 50 && handleInputChange(e, 'name')}
                  onBlur={(e) => handleOnBlur("categoryNameErr", e.target.value)}
                />
                {valObj.categoryNameErr ? 
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                  </p>
                :productCategoryName && !nameRegExp.test(productCategoryName) ?
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                  </p>
                  : null}
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField 
                  InputLabelProps={{ shrink: true }} required fullWidth 
                  variant="outlined" name="displayName" id="displayName" 
                  label={"Display Name"} disabled={currentForm === 'view'}
                  value={currentForm === "view" ? categoryDetails && categoryDetails.displayName && categoryDetails.displayName : displayName}
                  onChange={e => e.target.value.length <= 50 && handleInputChange(e, 'displayName')}
                  onBlur={(e) => handleOnBlur("displayNameErr", e.target.value)}
                />
                {valObj.displayNameErr ? 
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                  </p>
                :displayName && !nameRegExp.test(displayName) ?
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                  </p>
                  : null}
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField 
                  required fullWidth variant="outlined" name="description" 
                  InputLabelProps={{ shrink: true }} id="description" label={"Description"}
                  value={currentForm === "view" ? categoryDetails && categoryDetails.description && categoryDetails.description : description}
                  disabled={currentForm === 'view' ?  true : false}
                  onChange={e => e.target.value.length <= 255 && handleInputChange(e, 'description')}
                  onBlur={(e) => handleOnBlur("descriptionErr", e.target.value)}
                />
                {valObj.descriptionErr ? 
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                  </p>
                :description && !descrRegex.test(description) ?
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                  </p>
                  : null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={parentCategArray} disabled={currentForm !== 'create'}
                getOptionLabel={(option) => option} value={parentCategory}
                onChange={(e, newValue) => currentForm !== 'view' && handleParentCategorySelectChange(newValue)}
                // renderOption={(option, { selected }) => option }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Parent Category"
                    disabled={currentForm !== 'create'} id="parentCategory" name="parentCategory"
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" rowSpacing={2} columnSpacing={2}>
            <Grid item>
              <Button fullWidth variant="contained"
                color="primary" className={"action_login_button"}
                disabled={currentForm !== "view" && !createDisableFlag}
                  onClick={() => currentForm !== "view" ? handleCreateProductCategorySubmit() : onChangeView("list")}
                  >
                  {currentForm === "edit" ? "Modify" : currentForm === "view" ? "Close" : "Create"}
               </Button>
            </Grid>
          </Grid>
          
        </form>
      </Paper>

      {currentForm === "edit" && parentCategory === "" &&
        <Paper className={classes.paper}>
          <Grid container>
              <Grid item xs={12} >
                  <Typography variant="h4" className={classes.titleText} component="h1">Image Section</Typography>
              </Grid>
              
              <Grid item xs={12} sm={4}>
                  <div className="page">
                      <div className="container">
                          <h1 className="heading">PRODUCT CATEGORY IMAGE</h1>
                          {!(imageData && imageData.baseURL && imageData.baseURL !== "") &&
                            <h6 className="heading">{`( Image size limit 2mb. 120 H X 120 W Pixel limit)`}</h6>}
                          {imageData && imageData.baseURL && imageData.baseURL !== "" &&
                            <div className={ imageData && imageData.resourceId && imageData.resourceId !== "" ? "img-holder-cross" : "img-holder"}>
                              {docUploadLoader === "PRODUCT_CATEGORY_IMAGE"  ?
                                <CircularProgress className="circleIcon"/>
                              : null}
                              <img src={imageData.baseURL} alt="" id="img" className="img" ></img>
                              {imageData && imageData.resourceId && imageData.resourceId !== "" &&
                                <div className="close-icon">
                                  <IconButton size="small" aria-label="close" color="inherit" onClick={() => handleUploadImage("DELETE_ACTION")}>
                                    <CloseIcon fontSize="small" />
                                  </IconButton>
                                </div>
                              }
                            </div>
                          }
                          <input  type="file" name="image-upload" id="input" accept="image/*" onChange={(e) => imageHandler(e, "PRODUCT_CATEGORY_IMAGE")}/>
                          {imageData && imageData.baseURL && imageData.baseURL !== "" ? null :
                            <div className="label" >
                              <label htmlFor="input" className="contained_label">
                                <Button variant="contained" className="upload_button" color="primary" component="span">
                                  Upload
                                </Button>
                              </label>
                            </div>
                          }
                          {imageData && imageData.actionKey && imageData.actionKey === "UPLOAD" &&
                            <div className="upload-action">
                              <Button color="primary" className="yes" autoFocus
                                style={{margin: "0px 2px"}}
                                onClick={() => handleUploadImage("UPLOAD")} 
                              >
                                  Confirm
                              </Button>
                              <Button color="primary" className="no"
                                style={{margin: "0px 2px"}}
                                onClick={() => handleUploadImage("CANCEL")} 
                              >
                                  Cancel
                              </Button>
                            </div>
                          }
                      </div>
                  </div>  
              </Grid> 
          </Grid>
      </Paper>
      }

      {currentForm === "view" && imageData && imageData.baseURL && imageData.baseURL !== "" &&
        <Paper className={classes.paper}>
          <Grid container>
              <Grid item xs={12} >
                <Typography variant="h4" className={classes.titleText} component="h1">Image Section</Typography>
              </Grid>
              
              <Grid item xs={12} sm={4}>
                <div className="page">
                  <div className="container">
                    <h1 className="heading">PRODUCT CATEGORY IMAGE</h1>
                    <div className="img-holder">
                    <img src={imageData.baseURL} alt="" id="img" className="img" ></img>
                    </div>
                  </div>
                </div>  
              </Grid>
          </Grid> 
        </Paper>
      }

    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
        handleProductCategorySelect, handleDisplayNameSelect,
        handleDescriptionSelect, handleParentCategorySelect,
        submitCreateOrEditProductCategoryForm,
        clearProductCateogryRes, fetchMasterProductCategories
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    productCategoryName: state.ProductCategoryReducer.productCategoryName,
    displayName: state.ProductCategoryReducer.displayName,
    description: state.ProductCategoryReducer.description,
    parentCategory: state.ProductCategoryReducer.parentCategory,
    createProductCategoryRes: state.ProductCategoryReducer.createProductCategoryRes,
    docUploadLoader: state.ProductCategoryReducer.docUploadLoader,
    parentCategoryDetails: state.ProductReducer.productCategoryDetails && state.ProductReducer.productCategoryDetails !== null && state.ProductReducer.productCategoryDetails.category ? 
      state.ProductReducer.productCategoryDetails.category : [],
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditViewCategory);
