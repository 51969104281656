import { createRequestActionTypes } from "../../shared/utility";

export const UserActions = {
    fetchUsersDetails: createRequestActionTypes('FETCH_USERS_DETAILS'),
    createUserDetails: createRequestActionTypes('CREATE_NEW_USER'),
    fetchRoleDetails: createRequestActionTypes('FETCH_ROLE_DETAILS'),
    fetchPermissionDetailsByRole: createRequestActionTypes('FETCH_PERMISSION_DETAILS_BY_ROLE'),
    fetchPermissionDetailsForView: createRequestActionTypes('FETCH_PERMISSION_DETAILS_FOR_VIEW'),
    createRoleDetails: createRequestActionTypes('CREATE_NEW_ROLE'),
    resetUserPassword: createRequestActionTypes('RESET_USER_PASSWORD')
}