import React, { useState } from 'react'
import {
    Grid, TextField, Button, MenuItem, Typography
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Filter = (props) => {
    const {
        classes, countryArr, countryArrObj, brandArr, brandArrObj, merchantArr, merchantList,
        handleDeleteFilterChange, applyFilter, filterState, setShowTable
    } = props;
    const [errObj, setErrObj] = useState({ 
        countryErr: false, merchantErr: false, brandErr: false
    })

    const disableFilterButton = filterState && filterState.isoCountryCodeNum !== "" &&
        filterState.merchantId !== "" && filterState.brandId !== ""

    const handleChange = (key, errKey, value) => {

        if(key === "country") {
            countryArr.indexOf(value) !== -1 && handleDeleteFilterChange(key, [
                countryArrObj[countryArr.indexOf(value)]['isoCountryCodeNum'],
                value
            ])
            setErrObj({...errObj, [errKey]: false })
            setShowTable(false)
        } else if(key === "merchant") {
            merchantArr.indexOf(value) !== -1 && handleDeleteFilterChange(key, [
                merchantList[merchantArr.indexOf(value)]['id'],
                value
            ])
            setErrObj({...errObj, [errKey]: false })
            setShowTable(false)
        } else if(key === "brand") {
            brandArr.indexOf(value) !== -1 && handleDeleteFilterChange(key, [
                brandArrObj[brandArr.indexOf(value)]['id'],
                value
            ])
            setErrObj({...errObj, [errKey]: false })
            setShowTable(false)
        } else {
            handleDeleteFilterChange(key, value)
        }
    }

    const handleOnBlur = (type, value) => {
        if(value === null || value === "") {
            setErrObj({...errObj, [type]: true })
        }
    }

    return (
        <React.Fragment>
            <div className={"paper_scroll"}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12} sm={12} justify="flex-start" className={classes.addRange}>
                        <Typography className={classes.title} variant="h6" id="filterTitle" component="div">
                            Filter Product Mapping
                        </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            InputLabelProps={{ shrink: true }} 
                            variant="outlined" select required fullWidth
                            id='filter-country' name='country' label='Country'
                            value={filterState && filterState.country && filterState.country}
                            onBlur={(e) => handleOnBlur("countryErr", e.target.value)}
                            onChange={(e) => countryArr.length > 0 && handleChange('country', 'countryErr', e.target.value)}
                        >
                            {countryArr && countryArr.length === 0 && 
                            <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                            {countryArr && countryArr.map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                            })}
                        </TextField>
                        {errObj.countryErr ? 
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                        </p>    :   null}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            InputLabelProps={{ shrink: true }} 
                            variant="outlined" select fullWidth required
                            id='filter-merchant' name='merchant' label='Merchant'
                            value={filterState && filterState.merchant && filterState.merchant}
                            onBlur={(e) => handleOnBlur("merchantErr", e.target.value)}
                            onChange={(e) => merchantArr.length > 0 && handleChange('merchant', 'merchantErr', e.target.value)}
                        >
                            {merchantArr && merchantArr.length === 0 && 
                            <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                            {merchantArr && merchantArr.map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                            })}
                        </TextField>
                        {errObj.merchantErr ? 
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                        <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                        </p>    :   null}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            InputLabelProps={{ shrink: true }} 
                            variant="outlined" fullWidth select required
                            id="filter-brand" name="brand" label="Brand" 
                            value={filterState && filterState.brand && filterState.brand}
                            onChange={(e) => e.target.value !== "NA" && handleChange("brand", "brandErr", e.target.value)}
                            onBlur={(e) => e.target.value !== "NA" && handleOnBlur("brandErr", e.target.value)}
                        >
                            {brandArr && brandArr.length === 0 && 
                                <MenuItem key={1000} value={"NA"} className={classes.menu}>
                                {"No Brands Available!"}</MenuItem>
                            }
                            {(brandArr).map((val, index) => {
                                return <MenuItem key={index} value={val} className={classes.menu}>
                                    {val}
                                </MenuItem>
                            })}
                        </TextField>
                        {errObj.brandErr ? 
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                            </p>
                            :null
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            InputLabelProps={{ shrink: true }} 
                            variant="outlined" fullWidth
                            id="filter-merchantProductCode" name="merchantProductCode" label="Merchant Product Code" 
                            value={filterState && filterState.merchantProductCode && filterState.merchantProductCode}
                            onChange={(e) => e.target.value !== "NA" && handleChange("merchantProductCode", "merchantProductCodeErr", e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end" style={{"marginTop": "10px"}}>
                    <Grid item>
                        <Button fullWidth variant="contained"
                            color="primary" className={"action_login_button"}
                            disabled={!disableFilterButton}
                            onClick={() => applyFilter()}
                        >
                            {"Apply"}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}

export default Filter;