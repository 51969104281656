import React, {useState, useEffect} from 'react';
import { bindActionCreators } from "redux";
import {connect} from "react-redux";
// import {reduxForm} from 'redux-form';
import { Grid, TextField, MenuItem, Button, Paper, 
    Typography, Checkbox, IconButton, Snackbar
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../../_theme'
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import {fetchDirectoryPath, fetchLogLevel} from "../actions/index";
import {numbRegex, timeRegex} from "../../shared/utility";
import { ONLY_NUMBERS } from "shared/errorMessages";

const useStyles = tss.create({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        border: "solid 1px #EEEEEE",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(5),
    },
    input: {
        display: 'none',
    },
    menu: {
        fontSize: "15px"
    },
});

function CreateEditViewClientLog(props) {
    const {classes} = useStyles();
    const { currentForm, onChangeView, handleInputChange, clientLogState, createButtonFlag,
        directoryPath, dirPathLoader, logLevelData, createClientLog, createClientLogRes } = props;
    
    const [valObj, setValObj] = useState({
        retenSizeErr: false, clientIdErr: false, levelErr: false, retentionDErr: false,
        uplFreqErr: false, uplRetryCountErr: false, uplRetryIntErr: false,
        uplStartTErr: false, maxFileSizeErr: false, maxFileCountErr: false, fileMoveFreqErr: false,
    })
    const [openSnackbar, SetOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackBarMsg] = useState(null);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    });
    
    const daysArr = new Array(30).fill(0);
    const frequencyArr = new Array(48).fill(0);
    const uploadTimeArr = new Array(24).fill(0);
    const logLevelObj = {}
    const logLevelArr = logLevelData.map(obj => {
        logLevelObj[obj.name] = obj;
        return obj.name
    })

    useEffect(() => {
        if(directoryPath && directoryPath !== null && directoryPath.st && 
            clientLogState && clientLogState.directoryPath === "") {
                handleInputChange("directoryPath", directoryPath.directoryPath)

        } else if(directoryPath && directoryPath !== null && !directoryPath.st) {
            SetOpenSnackbar(true)
            setSnackBarMsg(directoryPath.msg && directoryPath.msg)
        }
    }, [directoryPath])

    useEffect(() => {
        if(createClientLogRes && createClientLogRes !== null && createClientLogRes.st) {
            onChangeView('list')

        } else if(createClientLogRes && createClientLogRes !== null && !createClientLogRes.st) {
            SetOpenSnackbar(true)
            setSnackBarMsg(createClientLogRes.msg && createClientLogRes.msg)
        }
    }, [createClientLogRes])

    useEffect(() => {
        props.fetchLogLevel({})
    }, [])

    const handleDirectorySearch = async () => {
        //call directory API here
        await props.fetchDirectoryPath({
            clientId: clientLogState.clientId
        });
        await handleInputChange('directoryPath', "");
    }

    const handleSnackBarAction = () => {
        SetOpenSnackbar(false)
        setSnackBarMsg(null)
    }

    const handleLogLevelChange = (value, errkey) => {
        handleInputChange('levelName', logLevelObj[value])
        setValObj({...valObj, [errkey]: false })
    }

    const handleOnBlur = (field, value) => {
        if(value === null || value === ""){
            setValObj({...valObj, [field]: true })
        } else {
            setValObj({...valObj, [field]: false })
        } 
    }

    const handleChange = (key, value, errKey) => {
        handleInputChange(key, value)
        setValObj({...valObj, [errKey]: false })
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <form className="action_form merchant_form" noValidate autoComplete="off">
                    
                    <Grid container rowSpacing={2} columnSpacing={2}>
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                required fullWidth variant="outlined" 
                                name="clientId" id="clientId" label={"Client Id"}
                                value={currentForm === 'view' ?
                                    clientLogState !== null && clientLogState.clientId && 
                                    clientLogState.clientId : clientLogState.clientId ?
                                    clientLogState.clientId : null
                                }
                                disabled={currentForm !== 'create'}
                                onChange={(e) => e.target.value.length <= 10 && 
                                    handleChange('clientId', e.target.value, 'clientIdErr')}
                                onBlur={e => handleOnBlur("clientIdErr", e.target.value)}
                            />
                            {valObj.clientIdErr ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                </p>
                            : null}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button fullWidth variant="contained"
                                color="primary" className={"action_login_button"}
                                style={{height: "100%", margin: "0px"}}
                                disabled={currentForm !== 'create' || 
                                    (clientLogState !== null && clientLogState.clientId === "")}
                                onClick={() => handleDirectorySearch()}
                            >
                                {"Search"}
                                {dirPathLoader && 
                                    <CircularProgress color="primary" 
                                        style={{marginLeft: "5px", "width": "20px", "height": "20px", color: "white"}}
                                    />
                                }
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>
                        
                        {clientLogState && clientLogState.directoryPath !== "" && <>
                        <Grid item xs={12} sm={4}>
                            <Typography>
                                <Checkbox
                                    id="select-all-checkbox"
                                    color="primary"
                                    disabled={currentForm === "view"}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    checked={clientLogState.enableLog}
                                    onChange={(e) => {handleInputChange("enableLog", !clientLogState.enableLog)}}
                                />
                                {"Log Enable"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} />

                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} select
                                required fullWidth variant="outlined" 
                                name="levelName" id="levelName" label={"Level Name"}
                                value={currentForm === 'view' ?
                                    clientLogState !== null && clientLogState.levelName && 
                                    clientLogState.levelName : clientLogState.levelName ?
                                    clientLogState.levelName : null
                                }
                                disabled={currentForm === 'view'}
                                onChange={(e) => handleLogLevelChange(e.target.value, 'levelErr')}
                                onBlur={e => handleOnBlur("levelErr", e.target.value)}
                            >
                                {(logLevelArr).map((val, index) => {
                                    return <MenuItem key={index} value={val} className={classes.menu}>
                                        {val}
                                    </MenuItem>
                                })}
                            </TextField>
                            {valObj.levelErr ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                </p>
                            : null}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} select
                                required fullWidth variant="outlined" 
                                name="retentionDays" id="retentionDays" label={"Retention Days"}
                                value={currentForm === 'view' ?
                                    clientLogState !== null && clientLogState.retentionDays && 
                                    clientLogState.retentionDays : clientLogState.retentionDays ?
                                    clientLogState.retentionDays : null
                                }
                                disabled={currentForm !== 'create'}
                                onChange={(e) => handleChange('retentionDays', e.target.value, 'retentionDErr')}
                                onBlur={e => handleOnBlur("retentionDErr", e.target.value)}
                            >
                                {(daysArr).map((val, index) => {
                                    return <MenuItem key={index} value={index + 1} className={classes.menu}>
                                        {index + 1}
                                    </MenuItem>
                                })}
                            </TextField>
                            {valObj.retentionDErr ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                </p>
                            : null}
                        </Grid>
                        
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                required fullWidth variant="outlined" 
                                name="retentionSize" id="retentionSize" label={"Retention Size (MB)"}
                                value={currentForm === 'view' ?
                                    clientLogState !== null && clientLogState.retentionSize && 
                                    clientLogState.retentionSize : clientLogState.retentionSize ?
                                    clientLogState.retentionSize : null
                                }
                                disabled={currentForm === "view"}
                                onChange={(e) => e.target.value.length <= 5 && 
                                    handleChange('retentionSize', e.target.value, 'retenSizeErr')}
                                onBlur={e => handleOnBlur("retenSizeErr", e.target.value)}
                            />
                            {valObj.retenSizeErr ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                </p>
                                :clientLogState && clientLogState.retentionSize && clientLogState.retentionSize !== "" && 
                                    !numbRegex.test(clientLogState.retentionSize) ? 
                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                            <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                                        </p>
                                    :null
                            }
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} select
                                required fullWidth variant="outlined" 
                                name="uploadFrequency" id="uploadFrequency" label={"Upload Frequency"}
                                value={currentForm === 'view' ?
                                    clientLogState !== null && clientLogState.uploadFrequency && 
                                    clientLogState.uploadFrequency : clientLogState.uploadFrequency ?
                                    clientLogState.uploadFrequency : null
                                }
                                disabled={currentForm === "view"}
                                onChange={(e) => handleChange('uploadFrequency', e.target.value, 'uplFreqErr')}
                                onBlur={e => handleOnBlur("uplFreqErr", e.target.value)}
                            >
                                {(frequencyArr).map((val, index) => {
                                    return <MenuItem key={index} value={index + 1} className={classes.menu}>
                                        {index + 1}
                                    </MenuItem>
                                })}
                            </TextField>
                            {valObj.uplFreqErr ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                </p>
                            : null }
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                required fullWidth variant="outlined" 
                                name="uploadFailureRetryCount" id="uploadFailureRetryCount" label={"Upload Failure Retry Count"}
                                value={currentForm === 'view' ?
                                    clientLogState !== null && clientLogState.uploadFailureRetryCount && 
                                    clientLogState.uploadFailureRetryCount : clientLogState.uploadFailureRetryCount ?
                                    clientLogState.uploadFailureRetryCount : null
                                }
                                disabled={currentForm === "view"}
                                onChange={(e) => e.target.value.length <= 2 &&
                                    handleChange('uploadFailureRetryCount', e.target.value, "uplRetryCountErr")}
                                onBlur={e => handleOnBlur("uplRetryCountErr", e.target.value)}
                            />
                            {valObj.uplRetryCountErr ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                </p>
                                :clientLogState && clientLogState.uploadFailureRetryCount && clientLogState.uploadFailureRetryCount !== "" && 
                                    !numbRegex.test(clientLogState.uploadFailureRetryCount) ? 
                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                            <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                                        </p>
                                    :null
                            }
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                required fullWidth variant="outlined" 
                                name="uploadFailureRetryInterval" id="uploadFailureRetryInterval" 
                                label={"Upload Failure Retry Interval (Seconds)"}
                                value={currentForm === 'view' ?
                                    clientLogState !== null && clientLogState.uploadFailureRetryInterval && 
                                    clientLogState.uploadFailureRetryInterval : clientLogState.uploadFailureRetryInterval ?
                                    clientLogState.uploadFailureRetryInterval : null
                                }
                                disabled={currentForm === "view"}
                                onChange={(e) => e.target.value.length <= 2 &&
                                    handleChange('uploadFailureRetryInterval', e.target.value, "uplRetryIntErr")}
                                onBlur={e => handleOnBlur("uplRetryIntErr", e.target.value)}
                            />
                            {valObj.uplRetryIntErr ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                </p>
                                :clientLogState && clientLogState.uploadFailureRetryInterval && clientLogState.uploadFailureRetryInterval !== "" && 
                                    !numbRegex.test(clientLogState.uploadFailureRetryInterval) ? 
                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                            <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                                        </p>
                                    :null
                            }
                        </Grid>

                        {/* <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} select
                                required fullWidth variant="outlined" 
                                name="uploadStartTime" id="uploadStartTime" label={"Upload Start Time"}
                                value={currentForm === 'view' ?
                                    clientLogState !== null && clientLogState.uploadStartTime && 
                                    clientLogState.uploadStartTime : clientLogState.uploadStartTime ?
                                    clientLogState.uploadStartTime : null
                                }
                                disabled={currentForm !== 'create'}
                                onChange={(e) => handleChange('uploadStartTime', e.target.value, 'uplStartTErr')}
                                onBlur={e => handleOnBlur("uplStartTErr", e.target.value)}
                            >
                                {uploadTimeArr.map((val, index) => {
                                    return <MenuItem key={index} value={index + 1} className={classes.menu}>
                                        {`${index + 1}:00`}
                                    </MenuItem>
                                })}
                            </TextField>
                            {valObj.uplStartTErr ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                </p>
                            : null}
                        </Grid> */}
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                required fullWidth variant="outlined" 
                                name="uploadStartTime" id="uploadStartTime" label={"Upload Start Time"}
                                value={currentForm === 'view' ?
                                    clientLogState !== null && clientLogState.uploadStartTime && 
                                    clientLogState.uploadStartTime : clientLogState.uploadStartTime ?
                                    clientLogState.uploadStartTime : null
                                }
                                disabled={currentForm !== 'create'}
                                placeholder={"Enter time in HH:MM:SS format"}
                                onBlur={e => handleOnBlur("uplStartTErr", e.target.value)}
                                onChange={(e) => e.target.value.length <= 8 && handleChange('uploadStartTime', e.target.value, 'uplStartTErr')}
                            />
                                {clientLogState && clientLogState.uploadStartTime && clientLogState.uploadStartTime !== "" && 
                                !timeRegex.test(clientLogState.uploadStartTime) ? 
                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                        <span className="error_message"><InfoOutlinedIcon /> {"Enter time in HH:MM:SS or H:MM:SS format."}</span>
                                    </p> : null
                                }
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                required fullWidth variant="outlined" disabled
                                name="directoryPath" id="directoryPath" label={"Directory Path"}
                                value={currentForm === 'view' ?
                                    clientLogState !== null && clientLogState.directoryPath && 
                                    clientLogState.directoryPath : clientLogState.directoryPath ?
                                    clientLogState.directoryPath : null
                                }
                                onChange={(e) => handleInputChange('directoryPath', e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                required fullWidth variant="outlined" 
                                name="maxFileSize" id="maxFileSize" label={"Max File Size (MB)"}
                                value={currentForm === 'view' ?
                                    clientLogState !== null && clientLogState.maxFileSize && 
                                    clientLogState.maxFileSize : clientLogState.maxFileSize ?
                                    clientLogState.maxFileSize : null
                                }
                                disabled={currentForm === "view"}
                                onChange={(e) => e.target.value.length <= 2 && 
                                    handleChange('maxFileSize', e.target.value, 'maxFileSizeErr')}
                                onBlur={e => handleOnBlur("maxFileSizeErr", e.target.value)}
                            />
                            {valObj.maxFileSizeErr ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                </p>
                                :clientLogState && clientLogState.maxFileSize && clientLogState.maxFileSize !== "" && 
                                    !numbRegex.test(clientLogState.maxFileSize) ? 
                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                            <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                                        </p>
                                    :null
                            }
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                required fullWidth variant="outlined" 
                                name="maxFileCount" id="maxFileCount" label={"Max File Count (Per Day)"}
                                value={currentForm === 'view' ?
                                    clientLogState !== null && clientLogState.maxFileCount && 
                                    clientLogState.maxFileCount : clientLogState.maxFileCount ?
                                    clientLogState.maxFileCount : null
                                }
                                disabled={currentForm === "view"}
                                onChange={(e) =>  e.target.value.length <= 2 && 
                                    handleChange('maxFileCount', e.target.value, 'maxFileCountErr')}
                                onBlur={e => handleOnBlur("maxFileCountErr", e.target.value)}
                            />
                            {valObj.maxFileCountErr ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                </p>
                                :clientLogState && clientLogState.maxFileCount && clientLogState.maxFileCount !== "" && 
                                    !numbRegex.test(clientLogState.maxFileCount) ? 
                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                            <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                                        </p>
                                    :null
                            }
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} select
                                required fullWidth variant="outlined" 
                                name="fileMoveFrequency" id="fileMoveFrequency" label={"fileMoveFrequency"}
                                value={currentForm === 'view' ?
                                    clientLogState !== null && clientLogState.fileMoveFrequency && 
                                    clientLogState.fileMoveFrequency : clientLogState.fileMoveFrequency ?
                                    clientLogState.fileMoveFrequency : null
                                }
                                disabled={currentForm === "view"}
                                onChange={(e) => handleChange('fileMoveFrequency', e.target.value, 'fileMoveFreqErr')}
                                onBlur={e => handleOnBlur("fileMoveFreqErr", e.target.value)}
                            >
                                {(frequencyArr).map((val, index) => {
                                    return <MenuItem key={index} value={index + 1} className={classes.menu}>
                                        {index + 1}
                                    </MenuItem>
                                })}
                            </TextField>
                            {valObj.fileMoveFreqErr ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                </p>
                            : null }
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Typography>
                                <Checkbox
                                    id="select-all-checkbox"
                                    color="primary"
                                    disabled={currentForm === "view"}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    checked={clientLogState.isBlacklist}
                                    onChange={(e) => {handleInputChange("isBlacklist", !clientLogState.isBlacklist)}}
                                />
                                {"Black List"}
                            </Typography>
                        </Grid>
                        {clientLogState && clientLogState.isBlacklist &&
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                required fullWidth variant="outlined" type="time"
                                name="blackListedStartTime" id="blackListedStartTime" label={"Black Listed Start Time"}
                                value={currentForm === 'view' ?
                                    clientLogState !== null && clientLogState.blackListedStartTime && 
                                    clientLogState.blackListedStartTime : clientLogState.blackListedStartTime ?
                                    clientLogState.blackListedStartTime : null
                                }
                                disabled={currentForm === "view"}
                                onChange={(e) =>
                                    handleChange('blackListedStartTime', e.target.value, 'blackListedStartTimeErr')}
                                onBlur={e => handleOnBlur("blackListedStartTimeErr", e.target.value)}
                            />
                            {valObj.blackListedStartTimeErr ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                </p> :null
                            }
                        </Grid>}
                        {clientLogState && clientLogState.isBlacklist &&
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                InputLabelProps={{ shrink: true }} 
                                required fullWidth variant="outlined" type="time"
                                name="blackListedEndTime" id="blackListedEndTime" label={"Black Listed End Time"}
                                value={currentForm === 'view' ?
                                    clientLogState !== null && clientLogState.blackListedEndTime && 
                                    clientLogState.blackListedEndTime : clientLogState.blackListedEndTime ?
                                    clientLogState.blackListedEndTime : null
                                }
                                disabled={currentForm === "view"}
                                onChange={(e) =>
                                    handleChange('blackListedEndTime', e.target.value, 'blackListedEndTimeErr')}
                                onBlur={e => handleOnBlur("blackListedEndTimeErr", e.target.value)}
                            />
                            {valObj.blackListedEndTime ? 
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                                </p> :null
                            }
                        </Grid>}
                        
                        <Grid item xs={12} sm={4}>
                            <Typography>
                                <Checkbox
                                    id="select-all-checkbox"
                                    color="primary"
                                    disabled={currentForm === "view"}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    checked={clientLogState.enableShipping}
                                    onChange={(e) => {handleInputChange("enableShipping", !clientLogState.enableShipping)}}
                                />
                                {"Shipping Enable"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography>
                                <Checkbox
                                    id="select-all-checkbox"
                                    color="primary"
                                    disabled={currentForm === "view"}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    checked={clientLogState.enableCascadingToStore}
                                    onChange={(e) => {handleInputChange("enableCascadingToStore", !clientLogState.enableCascadingToStore)}}
                                />
                                {"Cascading To Store"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography>
                                <Checkbox
                                    id="select-all-checkbox"
                                    color="primary"
                                    disabled={currentForm === "view"}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    checked={clientLogState.enableCascadingToMerchant}
                                    onChange={(e) => {handleInputChange("enableCascadingToMerchant", !clientLogState.enableCascadingToMerchant)}}
                                />
                                {"Cascading To Merchant"}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Typography>
                                <Checkbox
                                    id="select-all-checkbox"
                                    color="primary"
                                    disabled={currentForm === "view"}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    checked={clientLogState.enableFileEncryption}
                                    onChange={(e) => {handleInputChange("enableFileEncryption", !clientLogState.enableFileEncryption)}}
                                />
                                {"Enable File Encryption"}
                            </Typography>
                        </Grid>
                        
                        </>}

                    </Grid>

                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Button fullWidth variant="contained"
                                color="primary" className={"action_login_button"}
                                disabled={createButtonFlag}
                                onClick={() => currentForm !== "view" ? createClientLog() : onChangeView("list")}
                            >
                                {currentForm === "edit" ? "Modify" : currentForm === "view" ? "Close" : "Create"}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={() => handleSnackBarAction()}
                    action={
                        <React.Fragment>
                            <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                >
                    <Alert onClose={() => handleSnackBarAction()} severity={
                        directoryPath && directoryPath !== null && !directoryPath.st ? "error" : "success"
                    }>
                        {snackbarMsg}
                    </Alert>
                </Snackbar>
            </Paper>
        </div>
    )
}

// CreateEditViewClientLog = reduxForm({
//   form: 'CreateEditViewClientLog',
//   // validate: validate,
//   enableReinitialize: true
// })(CreateEditViewClientLog);

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
        fetchDirectoryPath, fetchLogLevel
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    dirPathLoader: state.ClientLogReducer.dirPathLoader,
    directoryPath: state.ClientLogReducer.directoryPath,
    logLevelData: state.ClientLogReducer.logLevelData !== null &&
        state.ClientLogReducer.logLevelData.logLevelList ?
            state.ClientLogReducer.logLevelData.logLevelList : [],
    createClientLogRes: state.ClientLogReducer.createClientLogRes,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditViewClientLog);
