import { CountryActions } from "../consts/index";

const initialState = {
    countryLoader: false,
    countryList: null,
    countryCreatRes: null,
    countryCreateLoader: false,
    masterCountryList: null,
    masterCountryLoader: false,
}

export default (state = initialState, {type, payload}) => {
    switch(type) {
        case CountryActions.fetchCountryList.REQUEST:
            return {
                ...state,
                countryLoader: true
            }
        case CountryActions.fetchCountryList.SUCCESS:
            return {
                ...state,
                countryLoader: false,
                countryList: payload
            }
        case CountryActions.fetchCountryList.FAILURE:
            return {
                ...state,
                countryLoader: false,
                countryList: null,
            }
    
        case CountryActions.createUpdateCountry.REQUEST:
            return {
                ...state,
                countryCreateLoader: true
            }
        case CountryActions.createUpdateCountry.SUCCESS:
            return {
                ...state,
                countryCreateLoader: false,
                countryCreatRes: payload
            }
        case CountryActions.createUpdateCountry.FAILURE:
            return {
                ...state,
                countryCreateLoader: false,
                countryCreatRes: payload,
            }

        case CountryActions.fetchMasterCountryList.REQUEST:
            return {
                ...state,
                masterCountryLoader: true
            }
        case CountryActions.fetchMasterCountryList.SUCCESS:
            return {
                ...state,
                masterCountryLoader: false,
                masterCountryList: payload
            }
        case CountryActions.fetchMasterCountryList.FAILURE:
            return {
                ...state,
                masterCountryLoader: false,
                masterCountryList: null,
            }

        default:
            return state;
    }
}