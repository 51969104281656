import React from 'react'
import Grid from '@mui/material/Grid'
import BasePageContainer from '../_common/basePageContainer'
import BasePageToolbar from '../_common/basePageToolbar'
import ItemList from './itemList';
import { tss } from "tss-react";
import theme from '../_theme'
const Dashboard = () => {
  const {classes} = useStyles();
  return (
    <>
      <BasePageToolbar
        title={'Dashboard'}
      ></BasePageToolbar>
      <BasePageContainer>
        <Grid container rowSpacing={3} columnSpacing={3} className={classes.container}>
          <ItemList />
        </Grid>
      </BasePageContainer>
    </>)
}

const useStyles = tss.create({
  container: {
    paddingTop: theme.spacing(2),
  },
});
export default Dashboard
