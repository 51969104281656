import React, { useState, useEffect } from 'react'
import {connect} from "react-redux";
import { bindActionCreators } from "redux";
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { 
    Grid, IconButton, Snackbar, Dialog, DialogActions,
    DialogTitle, DialogContentText, DialogContent, Button 
} from '@mui/material'
import { tss } from "tss-react";
import theme from '../../_theme'
import format from "date-fns/format";

import CreateEditViewProductGroup from './createEditViewProductGroup/index'
import BasePageContainer from '../../_common/basePageContainer'
import BasePageToolbar from '../../_common/basePageToolbar'
import TableComponent from "../table/index"
import { 
  fetchProductGroups, createProductGroup,
  updateProductGroup, clearProductGroupRes,
  fetchProductsByBrand, fetchSchemesByProductGroup
} from "./actions";
import { fetchCountryDetails, fetchBrandDetails } from '../product/actions' 
import { nameRegExp } from "../../shared/utility";

const headCells = [
    { id: 'name', type: 'text', numeric: false, disablePadding: false, label: 'Group Name' },
    { id: 'countryName', type: 'text', numeric: false, disablePadding: false, label: 'Country' },
    { id: 'brandName', type: 'text', numeric: false, disablePadding: false, label: 'Brand Name' },
    { id: 'productCodes', type: 'text', numeric: false, disablePadding: false, label: 'Product Codes' },
    { id: 'createdOn', type: 'text', numeric: false, disablePadding: false, label: 'Created On' },
    { id: 'status', type: 'text', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'action', type: 'action', numeric: false, disablePadding: true, label: 'Action' },
];

const ProductGroupObj = {
    "name": "",
    "isoCountryCodeNum": "",
    "countryName": "",
    "brandId": "",
    "brandName": "",
    "productCode": [],
    "productNames": [],
    "status": "",
    "createdBy": "",
    "updatedBy": "",
}

const filterCells = [
    { id: 'name', type: 'text', label: 'Group Name' },
    { id: 'isoCountryCodeNum', type: "select", label: "Country Name"},
    { id: 'brandId', type: "select", label: "Brand Name"},
    { id: 'productCode', type: "text", label: "Product Code"},
    { id: 'status', type: "select", label: "Status"},
    { id: 'installmentStartDate', type: "date", label: "Start Date"},
    { id: 'installmentEndDate', type: "date", label: "End Date"}
];
  
const filterobj = {
    name: "",
    isoCountryCodeNum: "",
    brandId: "",
    productCode: "",
    status: "",
    installmentStartDate: null,
    installmentEndDate: null,
    pageNo: 1,
    pageSize: 10,
    sortBy:"createdOn",
    sortDirection:"DESC",
}

const sortArr = [
    {key:"createdOn", label: "Create Date"},
    {key:"name", label: "Name"},
    {key:"status", label: "Status"},
]
  
const schemeObj = {
    pageNo: 1,
    pageSize: 10,
    sortBy:"installmentConfigDesc",
    sortDirection:"ASC",
}

const ProductGroup = (props) => {
    const {
        productGroupLoader, totalRecords, productGroupList, countryList, brandList, prodctGroupResp, productList, brandSchemeList, schemeCount
    } = props;
  
    const {classes} = useStyles();
    const [pageNo, setPage] = useState(1);
    const [sortDir, setsortDir] = useState('DESC');
    const [clientRow, setClientRow] = useState(null);
    const [filterData, setFilterData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [snackbarMsg, setSnackBarMsg] = useState(null);
    const [sortName, setsortName] = useState('createdOn');
    const [openPopover, setopenPopover] = useState(false);
    const [currentForm, setCurrentForm] = useState("list");
    const [openSnackbar, SetOpenSnackbar] = useState(false);
    const [compInitaited, setCompInitiated] = useState(false);
    const [filterState, setFilterState] = useState(filterobj);
    const [groupState, setGroupState] = useState(ProductGroupObj);
    const [schemeRequest, setSchemeRequest] = useState(schemeObj);
    
    const currentPage = localStorage.getItem('currentPage')
    let permissions = localStorage.getItem("permissions")
    permissions = permissions && permissions !== null ? JSON.parse(permissions) : []
    const userName = localStorage.getItem('userName')

    const createDisableToken = currentForm !== "view" && groupState && groupState.isoCountryCodeNum !== "" && groupState.brandId !== "" &&
        groupState.name !== "" && nameRegExp.test(groupState.name) && groupState.status !== "" &&
        groupState.productNames && groupState.productNames.length > 0
    
    let countryIdArr = []
    const countryArr = countryList && countryList.map(country => {
            if(country.isoCountryCodeNum && country.isoCountryCodeNum !== "") {
                countryIdArr.push(country.isoCountryCodeNum);
            }
            return country.name ? country.name : null
        }).filter(val => val !== null)

    let brandIdArr = []
    const brandArr = brandList && brandList.map((obj) => {
            if (obj.name && obj.name !== "") {
                brandIdArr.push(obj.id)
                return obj.name
            }
            return null
        })

    let productCodeArr = []
    const productArr = productList && productList.map((obj) => {
            if (obj.name && obj.name !== "") {
                productCodeArr.push(obj.productCode)
                return obj.name
            }
            return null
        })
        
    const productGroupArr = productGroupList && productGroupList !== null ? 
        productGroupList.map(obj => {
  
            let groupObj = {
                ...obj,
                countryName: countryIdArr.indexOf(obj.isoCountryCodeNum) !== -1 ? countryArr[countryIdArr.indexOf(obj.isoCountryCodeNum)] : "",
                brandName: brandIdArr.indexOf(obj.brandId) !== -1 ? brandArr[brandIdArr.indexOf(obj.brandId)] : "",
                productCodes: obj.productCode && obj.productCode.join(", ")
            }
            return groupObj
        }) : []
  
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    });
    
    useEffect(() => {
        if(currentPage && currentPage !== null && currentPage !== undefined && currentPage === "/productGroup" && compInitaited){
        setCurrentForm('list')
        setFilterState({...filterobj});
        setPage(1);
        setsortDir("DESC");
        setsortName("createdOn")
        props.fetchProductGroups({
            pageNo: 1,
            pageSize: 10,
            sortBy:"createdOn",
            sortDirection:"DESC"
        })
        countryList && countryList.length === 0 && props.fetchCountryDetails({})
        localStorage.removeItem('currentPage')
        }
    }, [currentPage])

    useEffect(() => {
        const handleInitialCall = async () => {
        await setCompInitiated(true);
        await countryList && countryList.length === 0 && props.fetchCountryDetails({})
        await props.fetchBrandDetails({
            "status": "ACTIVE",
            "pageNo": 1, "pageSize":1000, "sortBy": "name", "sortDirection": "ASC"
        })
        await props.fetchProductGroups({...filterState,
            pageNo: pageNo, sortBy: sortName, sortDirection: sortDir
        })
        await localStorage.removeItem('currentPage')
        }
        handleInitialCall();
        
        return () => {
        //add all list reset API here
        
        //reset acquirer response
        props.clearProductGroupRes()
        }
    }, [])

    useEffect(() => {
        if(prodctGroupResp && prodctGroupResp !== null && prodctGroupResp.st) {
            if(currentForm === "create" ) {
                setopenPopover(true)
            } else {
                SetOpenSnackbar(true)
                setSnackBarMsg(`Product Group Modified Successfully!`)
                onChangeView("list")
            }
            //reset Product Group response
            props.clearProductGroupRes()
        } else if(prodctGroupResp && prodctGroupResp !== null && !prodctGroupResp.st) {
            SetOpenSnackbar(true)
            setSnackBarMsg(prodctGroupResp.msg && prodctGroupResp.msg)
            // onChangeView("list")
            props.clearProductGroupRes()
        }
    }, [prodctGroupResp])

    useEffect(() => {
        const handleSetProductNames = async () => {
            if(currentForm === 'edit' && productList && productList.length > 0) {
                const productNames = groupState.productCode && groupState.productCode.length > 0 ?
                    groupState.productCode.map((code) => {
                        const name = productCodeArr.indexOf(code) !== -1 ? productArr[productCodeArr.indexOf(code)] : null
                        return name
                    }) : []

                setGroupState({...groupState, productNames: productNames})
            }

            if(currentForm === 'view' && productList && productList.length > 0) {
                const productNames = clientRow.productCode && clientRow.productCode.length > 0 ?
                    clientRow.productCode.map((code) => {
                        const name = productCodeArr.indexOf(code) !== -1 ? productArr[productCodeArr.indexOf(code)] : null
                        return name
                    }) : []

                setClientRow({...clientRow, productNames: productNames})
            }
        }
        handleSetProductNames(); 
    }, [productList])

    const onChangeView = async (inpval, row) => {
        setCurrentForm(inpval);
        if(inpval === "view") {
            setClientRow(row)
            countryList && countryList.length === 0 && props.fetchCountryDetails({})
            props.fetchBrandDetails({
                "isoCountryCodeNum": row.isoCountryCodeNum, status: "ACTIVE",
                "pageNo": 1, "pageSize":1000, "sortBy": "name", "sortDirection": "ASC"
            })
            props.fetchProductsByBrand({
                "brandId": row.brandId, status: "ACTIVE",
                "pageNo": 1, "pageSize":1000, "sortBy": "name", "sortDirection": "ASC"
            })
            props.fetchSchemesByProductGroup({
                ...schemeRequest, groupId: row.id
            })

        } else if(inpval === "edit") {
            setGroupState({...ProductGroupObj, ...row})
            
            //fetch details for edit drop down
            countryList && countryList.length === 0 && props.fetchCountryDetails({})
            props.fetchBrandDetails({
                "isoCountryCodeNum": row.isoCountryCodeNum, status: "ACTIVE",
                "pageNo": 1, "pageSize":1000, "sortBy": "name", "sortDirection": "ASC"
            })
            props.fetchProductsByBrand({
                "brandId": row.brandId, status: "ACTIVE",
                "pageNo": 1, "pageSize":1000, "sortBy": "name", "sortDirection": "ASC"
            })
            props.fetchSchemesByProductGroup({
                ...schemeRequest, groupId: row.id
            })

        } else if(inpval === "create") {
            setGroupState(ProductGroupObj)
            countryList && countryList.length === 0 && props.fetchCountryDetails({})

        } else {
        
            await props.fetchProductGroups({...filterState,
                pageNo: 1, sortBy: sortName, sortDirection: sortDir
            });
            countryList && countryList.length === 0 && props.fetchCountryDetails({})
            props.fetchBrandDetails({
                status: "ACTIVE",
                "pageNo": 1, "pageSize":1000, "sortBy": "name", "sortDirection": "ASC"
            })
        }
    }
    
    const handleFilterStateChange = (key, value) => {
        
        let tempFilter = {
            ...filterState,
            [key]: value
        }
        
        setFilterState(tempFilter);
    }

    const resetFilter = () => {
        setFilterState(filterobj);
        setPage(1);
        setsortDir("DESC");
        setsortName("createdOn")
        props.fetchProductGroups({
            pageNo: 1,
            pageSize: 10,
            sortBy:"createdOn",
            sortDirection:"DESC"
        })
    }

    const FilterApply = () => {

        const tempFilterState = {
            ...filterState,
            installmentStartDate: filterState['installmentStartDate'] !== null ?
                format(new Date(filterState['installmentStartDate']), `yyyy-MM-dd'T'HH:mm'Z'`) 
                : null,
            installmentEndDate: filterState['installmentEndDate'] !== null ?
                format(new Date(filterState['installmentEndDate']), `yyyy-MM-dd'T'HH:mm'Z'`) 
                : null
        }

        setPage(1)
        props.fetchProductGroups({
             ...tempFilterState, pageNo: 1, sortBy: sortName, sortDirection: sortDir
            })
        setFilterState({...filterState});
    }

    const handleSnackBarAction = () => {
        SetOpenSnackbar(false)
        setSnackBarMsg(null)
        //reset merchant response
        props.clearProductGroupRes()
    }

    const handleInputChange = (key, value) => {
        
        let updatedGroupState = {...groupState};
        if(key === "country") {

            const countryId = countryArr.indexOf(value) !== -1 ? countryList[countryArr.indexOf(value)]["isoCountryCodeNum"] : ""
            updatedGroupState = {
                ...updatedGroupState,
                countryName: value,
                isoCountryCodeNum: countryId,
                brandName: "",
                brandId: "",
                productCode: [],
                productNames: [],
            }

            props.fetchBrandDetails({
                "isoCountryCodeNum": countryId, status: "ACTIVE",
                "pageNo": 1, "pageSize":1000, "sortBy": "name", "sortDirection": "ASC"
            })

        } else if(key === "brand") {
        
            const brandId = brandArr.indexOf(value) !== -1 ? brandList[brandArr.indexOf(value)]["id"] : ""
            updatedGroupState = {
                ...updatedGroupState,
                brandName: value,
                brandId: brandId,
                productCode: [],
                productNames: [],
            }
            props.fetchProductsByBrand({
                "brandId": brandId, status: "ACTIVE",
                "pageNo": 1, "pageSize":1000, "sortBy": "name", "sortDirection": "ASC"
            })

        } else if(key === "products") {

            updatedGroupState = {
                ...updatedGroupState,
                productNames: value,
            }

        } else {
            updatedGroupState = {
                ...updatedGroupState,
                [key]: value
            }
        }

        setGroupState(updatedGroupState)
    }

    const submitCreateOrUpdateProductGroup = () => {

        const tempProductCodes = groupState && groupState.productNames && groupState.productNames.length > 0 ?
            groupState.productNames.map((name) => {
                const index = productArr.indexOf(name)
                if(index !== -1) {
                    return productCodeArr[index]
                }
                return null
            }) 
            : []

        let req = {
            // ...groupState,
            "id": currentForm === 'edit' ? groupState.id : null,
            "isoCountryCodeNum": groupState.isoCountryCodeNum,
            "brandId": groupState.brandId,
            "name": groupState.name,
            "status": groupState.status,
            "productCode": tempProductCodes,
            "createdBy": currentForm === 'create' ? userName : null,
            "modifiedBy": currentForm === 'edit' ? userName : null,
        }
        
        if(currentForm === "create") {
            props.createProductGroup(req)
        } else {
            props.updateProductGroup(req)
        }
        setFilterState(filterobj);
        setSearchValue("")
    }

    const handleDialogAction = async (type) => {
        setopenPopover(false)
        setGroupState(ProductGroupObj)
        if(type === "exit" ){
        onChangeView('list')
        } else {
        await onChangeView('list')
        await onChangeView('create')
        }
    }

    const handleSetFilterData = (filterArray, searchValue) => {
        setFilterData(filterArray)
        setSearchValue(searchValue)
    }

    const handlePageNo = (val) => {
        setPage(val)
        props.fetchProductGroups({
            ...filterState,
            pageNo: val, sortBy: sortName, sortDirection: sortDir,
            installmentStartDate: filterState['installmentStartDate'] !== null ?
                format(new Date(filterState['installmentStartDate']), `yyyy-MM-dd'T'HH:mm'Z'`) 
                : null,
            installmentEndDate: filterState['installmentEndDate'] !== null ?
                format(new Date(filterState['installmentEndDate']), `yyyy-MM-dd'T'HH:mm'Z'`) 
                : null,
        });
    }

    const handleSortDir = (value) => {
        setsortDir(value)
        setPage(1)
    }

    const handleSortName = (value) => {
        setsortName(value)
        setPage(1)
    }
  
    const handleSchemeTablePageNo = (val, groupId) => {
        setSchemeRequest({...schemeRequest, pageNo: val})
        props.fetchSchemesByProductGroup({
            ...schemeRequest, pageNo: val, groupId: groupId
        })
    }

    return (
      <>
        <BasePageToolbar 
          currentForm={currentForm}  create={permissions.includes("68")} onChangeView={onChangeView} resetFilter={resetFilter}
          title={currentForm === 'list' ? "Product Groups" : currentForm === 'view' ? "View Product Group" : currentForm === 'edit' ? "Modify Product Group" : "Create Product Group"}
          handleFilterStateChange={handleFilterStateChange} filterState={filterState} filterCells={filterCells} FilterApply={FilterApply}
          setsortName={handleSortName} setsortDir={handleSortDir} sortDir={sortDir} sortName={sortName} sortArr={sortArr}
          arrayToFilter={productGroupArr} isTableSearchReq={false} handleSetFilterData={handleSetFilterData} searchValue={searchValue}
        ></BasePageToolbar>
          <BasePageContainer>
            {currentForm === 'list' ?
            <Grid container className={classes.container}>
                <TableComponent 
                    sortOrder="desc"
                    sortOrderBy="createdOn"
                    loading={productGroupLoader}
                    headCells={headCells}
                    rows={searchValue && searchValue !== "" ? filterData : productGroupArr}
                    onChangeView={onChangeView}
                    showEdit={permissions.includes("69")}
                    activeTab={filterState}
                    totalRecord={totalRecords}
                    handlePageNo={handlePageNo}
                />
            </Grid>
            : currentForm === 'create' ?
              <Grid container className={classes.formContainer}>
                <CreateEditViewProductGroup 
                    currentForm={currentForm}
                    groupState={groupState}
                    countryArr={countryArr}
                    brandArr={brandArr}
                    createDisableToken={!createDisableToken}
                    handleInputChange={handleInputChange}
                    submitCreateOrUpdateProductGroup={submitCreateOrUpdateProductGroup}
                    productArr={productArr}
                />
              </Grid>
              : currentForm === 'edit' ? <Grid container className={classes.formContainer}>
                    <CreateEditViewProductGroup
                        currentForm={currentForm}
                        groupState={groupState}
                        countryArr={countryArr}
                        brandArr={brandArr}
                        createDisableToken={!createDisableToken}
                        handleInputChange={handleInputChange}
                        submitCreateOrUpdateProductGroup={submitCreateOrUpdateProductGroup}
                        productArr={productArr}
                        loading={productGroupLoader}
                        brandSchemeList={brandSchemeList}
                        schemeCount={schemeCount}
                        handleSchemeTablePageNo={handleSchemeTablePageNo}
                    />
                </Grid>
                : <Grid container className={classes.formContainer}>
                    <CreateEditViewProductGroup
                        currentForm={currentForm} 
                        groupDetails={clientRow}
                        onChangeView={onChangeView}
                        productArr={productArr}
                        loading={productGroupLoader}
                        brandSchemeList={brandSchemeList}
                        schemeCount={schemeCount}
                        handleSchemeTablePageNo={handleSchemeTablePageNo}
                    />
                </Grid>
            }
            
            <Snackbar
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
              }}
              open={openSnackbar}
              autoHideDuration={4000}
              onClose={() => handleSnackBarAction()}
              action={
              <React.Fragment>
                <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
              }
            >
              <Alert onClose={() => handleSnackBarAction()} severity={
                prodctGroupResp && prodctGroupResp !== null && !prodctGroupResp.st ? "info" : "success"
              }>
                {snackbarMsg}
              </Alert>
            </Snackbar>
            <Dialog
              open={openPopover}
              onClose={() => handleDialogAction("exit")}
              className={"delete_dialog"}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <h2>{`Product Group Created Successfully!`}</h2>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`Do you want to Create new Product Group?`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button className={classes.color}
                  onClick={() => handleDialogAction("create")} 
                >
                  Create New
                </Button>
                <Button className={classes.color}
                  onClick={() => handleDialogAction("exit")} 
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          
          </BasePageContainer>
      </>)
}

const useStyles = tss.create({
    formContainer: {
        paddingTop: theme.spacing(0),
    },
    container: {
        paddingTop: theme.spacing(2),
    },
    color: {
        color: "white",
        backgroundColor: `#1BA785 !important`
    },
});
  
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            fetchProductGroups, createProductGroup,
            updateProductGroup, clearProductGroupRes,
            fetchCountryDetails, fetchBrandDetails,
            fetchProductsByBrand, fetchSchemesByProductGroup
        }, dispatch)
    }
}

function mapStateToProps(state) {
    return {
        productGroupList: state.ProductGroupReducer.productGroupList !== null && state.ProductGroupReducer.productGroupList.productGroupList ? 
            state.ProductGroupReducer.productGroupList.productGroupList : [],
        totalRecords: state.ProductGroupReducer.productGroupList !== null && state.ProductGroupReducer.productGroupList.totalRecord ? 
            state.ProductGroupReducer.productGroupList.totalRecord : 0,
        productGroupLoader: state.ProductGroupReducer.productGroupLoader,
        prodctGroupResp: state.ProductGroupReducer.prodctGroupResp,
        countryList: state.ProductReducer.countryDetails && state.ProductReducer.countryDetails !== null 
            && state.ProductReducer.countryDetails.countryList ? state.ProductReducer.countryDetails.countryList : [],
        brandList: state.ProductReducer.brandDetails && state.ProductReducer.brandDetails !== null && state.ProductReducer.brandDetails.brands ? 
            state.ProductReducer.brandDetails.brands : [],
        productList: state.ProductGroupReducer.productList !== null && state.ProductGroupReducer.productList.productList ? 
            state.ProductGroupReducer.productList.productList : [],
        brandSchemeList: state.ProductGroupReducer.schemeDetails !== null && state.ProductGroupReducer.schemeDetails.brandSchemeList ? 
            state.ProductGroupReducer.schemeDetails.brandSchemeList : [],
        schemeCount: state.ProductGroupReducer.schemeDetails !== null && state.ProductGroupReducer.schemeDetails.totalRecord ? 
            state.ProductGroupReducer.schemeDetails.totalRecord : 0,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductGroup);
