import { actionCreator, BASE_URL } from "../../shared/utility";
import { ProgramActions } from "../consts/index";

export function handleCountrySelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(ProgramActions.handleCountryChangeForProgram.SUCCESS, payload))
    }
}

export function handleProgramTypeSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(ProgramActions.handleProgramTypeChangeForProgram.SUCCESS, payload))
    }
}

export function handleStartDateSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(ProgramActions.handleStartDateChangeForProgram.SUCCESS, payload))
    }
}

export function handleEndDateSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(ProgramActions.handleEndDateChangeForProgram.SUCCESS, payload))
    }
}

export function handleDescriptionSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(ProgramActions.handleDescriptionChangeForProgram.SUCCESS, payload))
    }
}

export function handleBrandSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(ProgramActions.handleBrandChangeForProgram.SUCCESS, payload))
    }
}

export function handleProgramNameSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(ProgramActions.handleProgramNameChangeForProgram.SUCCESS, payload))
    }
}

export function handleIsDefaultSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(ProgramActions.handleIsDefaultChangeForProgram.SUCCESS, payload))
    }
}

export function handleShowCrossBorderSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(ProgramActions.handleShowCrossBorderChangeForProgram.SUCCESS, payload))
    }
}

export function handleSchemeSelect(payload) {
    return(dispatch) => {
        dispatch(actionCreator(ProgramActions.handleSchemesChangeForProgram.SUCCESS, payload))
    }
}

export function fetchProgramDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProgramActions.handleProgramDetailsFetchForProgram.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/getProgramList`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
        .then(response => response.json())
        .then(function (res) {
            if(!res.st) {
                if(res.responseCode && res.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProgramActions.handleProgramDetailsFetchForProgram.FAILURE));
                }
            } else {
                dispatch(actionCreator(ProgramActions.handleProgramDetailsFetchForProgram.SUCCESS, res))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchIndividualProgramDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProgramActions.handleIndividualProgramDetailsFetchForProgram.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/getProgramList`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
          .then(response => response.json())
          .then(function (res) {
            if(!res.st) {
                if(res.responseCode && res.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProgramActions.handleIndividualProgramDetailsFetchForProgram.FAILURE));
                }
            } else {
                dispatch(actionCreator(ProgramActions.handleIndividualProgramDetailsFetchForProgram.SUCCESS, res));
            }
          })
          .catch(function (error) {
            console.log("error >>> ", error)
          });
    }
}

export function resetIndividualProgram(payload) {
    return (dispatch) => {
        dispatch(actionCreator(ProgramActions.handleIndividualProgramDetailsFetchForProgram.FAILURE, payload))
    }
}

export function fetchCountryDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProgramActions.handleCountryDetailsFetchForProgram.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/getCountryListForScheme`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProgramActions.handleCountryDetailsFetchForProgram.FAILURE))
                }
            } else {
                dispatch(actionCreator(ProgramActions.handleCountryDetailsFetchForProgram.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchProgramTypeDetails(payload) {
    return(dispatch,getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProgramActions.handlePtogramTypeDetailsFetchForProgram.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/getCountryListForScheme`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProgramActions.handlePtogramTypeDetailsFetchForProgram.FAILURE))
                }
            } else {
                dispatch(actionCreator(ProgramActions.handlePtogramTypeDetailsFetchForProgram.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchSchemeDetails(payload) {
    return(dispatch,getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProgramActions.handleSchemeDetailsFetchForProgram.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/getPicklist`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProgramActions.handleSchemeDetailsFetchForProgram.FAILURE))
                }
            } else {
                dispatch(actionCreator(ProgramActions.handleSchemeDetailsFetchForProgram.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetBrandSchemeDetails(payload) {
    return(dispatch) => {
        dispatch(actionCreator(ProgramActions.handleSchemeDetailsFetchForProgram.FAILURE, payload))
    }
}

export function fetchBankSchemeDetails(payload) {
    return(dispatch,getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProgramActions.handleBankSchemeDetailsFetchForProgram.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/getApprovedBankSchemeList`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProgramActions.handleBankSchemeDetailsFetchForProgram.FAILURE))
                }
            } else {
                dispatch(actionCreator(ProgramActions.handleBankSchemeDetailsFetchForProgram.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetBankSchemeDetails(payload) {
    return(dispatch) => {
        dispatch(actionCreator(ProgramActions.handleBankSchemeDetailsFetchForProgram.FAILURE, payload))
    }
}

export function fetchBrandDetails(payload) {
    return(dispatch,getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProgramActions.handleBrandDetailsFetchForProgram.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/getBrandlist`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProgramActions.handleBrandDetailsFetchForProgram.FAILURE))
                }
            } else {
                dispatch(actionCreator(ProgramActions.handleBrandDetailsFetchForProgram.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchIssuerCountryDetails(payload) {
    return(dispatch,getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProgramActions.handleIssuerCountryDetailsFetchForProgram.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/fetchIssuerCountryList`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProgramActions.handleIssuerCountryDetailsFetchForProgram.FAILURE))
                }
            } else {
                dispatch(actionCreator(ProgramActions.handleIssuerCountryDetailsFetchForProgram.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function submitCreateOrEditProgramForm(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProgramActions.handleCreateOrEditProgramSubmit.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/createOrEditProgram`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProgramActions.handleCreateOrEditProgramSubmit.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(ProgramActions.handleCreateOrEditProgramSubmit.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function clearProgramRes(payload) {
    return(dispatch) => {
        dispatch(actionCreator(ProgramActions.handleCreateOrEditProgramSubmit.SUCCESS, payload))
    }
}

