import { StoreActions } from "../consts/index";

const initialState = {
    StoreList: null,
    storeLoader: false,
    storeMetadata: null,
    createStoreRes: null
}

export default (state = initialState, {type, payload}) => {
    switch(type) {
        case StoreActions.fetchStoreDetails.REQUEST:
            return {
                ...state,
                storeLoader: true
            }
        case StoreActions.fetchStoreDetails.SUCCESS:
            return {
                ...state,
                storeLoader: false,
                StoreList: payload
            }
        case StoreActions.fetchStoreDetails.FAILURE:
            return {
                ...state,
                StoreList: null,
                storeLoader: false,
            }

        case StoreActions.fetchStoreMetadata.REQUEST:
            return {
                ...state
            }
        case StoreActions.fetchStoreMetadata.SUCCESS:
            return {
                ...state,
                storeMetadata: payload
            }
        case StoreActions.fetchStoreMetadata.FAILURE:
            return {
                ...state,
                storeMetadata: null
            }

        case StoreActions.createStoreDetails.REQUEST:
            return {
                ...state
            }
        case StoreActions.createStoreDetails.SUCCESS:
            return {
                ...state,
                createStoreRes: payload
            }
        case StoreActions.createStoreDetails.FAILURE:
            return {
                ...state,
                createStoreRes: payload
            }

        default:
            return state;
    }
}