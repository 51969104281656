import { actionCreator, BASE_URL } from "../../../shared/utility";
import { ProductGroupActions } from "../consts/index";

export function fetchProductGroups(payload) {
    const groupIds = payload.groupIds && payload.groupIds !== "" ? `&id=${payload.groupIds}` : ""
    const isoCountryCodeNum = payload.isoCountryCodeNum && payload.isoCountryCodeNum !== "" ? `&isoCountryCodeNum=${payload.isoCountryCodeNum}` : ""
    const brandId = payload.brandId && payload.brandId !== "" ? `&brandId=${payload.brandId}` : ""
    const name = payload.name && payload.name !== "" ? `&name=${payload.name}` : ""
    const productCode = payload.productCode && payload.productCode !== "" ? `&productCode=${payload.productCode}` : ""
    const status = payload.status && payload.status !== "" ? `&status=${payload.status}` : ""
    const startDate = payload.installmentStartDate && payload.installmentStartDate !== "" ? `&startDate=${payload.installmentStartDate}` : ""
    const endDate = payload.installmentEndDate && payload.installmentEndDate !== "" ? `&endDate=${payload.installmentEndDate}` : ""
    const endURL = `?pageNo=${payload.pageNo}&pageSize=${payload.pageSize}&sortDirection=${payload.sortDirection}&sortBy=${payload.sortBy}` +
        groupIds + isoCountryCodeNum + brandId + name + status + productCode + startDate + endDate

    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductGroupActions.fetchProductGroups.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/productgroups${endURL}`, {
            method: 'GET',
            // body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
        .then(response => response.json())
          .then(function (res) {
            if(!res.st) {
                if(res.responseCode && res.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductGroupActions.fetchProductGroups.FAILURE))
                }
            } else {
                dispatch(actionCreator(ProductGroupActions.fetchProductGroups.SUCCESS, res))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetProductGroupList() {
    return(dispatch) => {
        dispatch(actionCreator(ProductGroupActions.fetchProductGroups.SUCCESS, null))
    }
}

export function createProductGroup(payload) {

    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductGroupActions.createProductGroup.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/productgroup`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
        .then(response => response.json())
          .then(function (res) {
            if(!res.st) {
                if(res.responseCode && res.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductGroupActions.createProductGroup.FAILURE, res))
                }
            } else {
                dispatch(actionCreator(ProductGroupActions.createProductGroup.SUCCESS, res))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function updateProductGroup(payload) {

    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductGroupActions.updateProductGroup.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/productgroup/${payload.id}`, {
            method: 'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
        .then(response => response.json())
          .then(function (res) {
            if(!res.st) {
                if(res.responseCode && res.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductGroupActions.updateProductGroup.FAILURE, res))
                }
            } else {
                dispatch(actionCreator(ProductGroupActions.updateProductGroup.SUCCESS, res))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function clearProductGroupRes() {
    return(dispatch) => {
        dispatch(actionCreator(ProductGroupActions.createProductGroup.SUCCESS, null))
    }
}

export function fetchProductsByBrand(payload) {
    
    const status = payload.status && payload.status !== "" ? `&status=${payload.status}` : ""
    const endURL = `?pageNo=${payload.pageNo}&pageSize=${payload.pageSize}&sortDirection=${payload.sortDirection}&sortBy=${payload.sortBy}` + status

    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductGroupActions.fetchProductsForBrand.REQUEST))
        fetch(`${BASE_URL}/oem/api/data/web/brands/${payload.brandId}/products${endURL}`, {
            method: 'GET',
            // body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
        .then(response => response.json())
          .then(function (res) {
            if(!res.st) {
                if(res.responseCode && res.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductGroupActions.fetchProductsForBrand.FAILURE))
                }
            } else {
                dispatch(actionCreator(ProductGroupActions.fetchProductsForBrand.SUCCESS, res))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchSchemesByProductGroup(payload) {
    
    // const status = payload.status && payload.status !== "" ? `&status=${payload.status}` : ""
    const endURL = `?pageNo=${payload.pageNo}&pageSize=${payload.pageSize}&sortDirection=${payload.sortDirection}&sortBy=${payload.sortBy}`

    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(ProductGroupActions.fetchSchemesByProductGroup.REQUEST))
        fetch(`${BASE_URL}/sch/api/data/web/schemes/productGroups/${payload.groupId}${endURL}`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        )
        .then(response => response.json())
          .then(function (res) {
            if(!res.st) {
                if(res.responseCode && res.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(ProductGroupActions.fetchSchemesByProductGroup.FAILURE))
                }
            } else {
                dispatch(actionCreator(ProductGroupActions.fetchSchemesByProductGroup.SUCCESS, res))
            }
            // dispatch(actionCreator(ProductGroupActions.fetchSchemesByProductGroup.SUCCESS, res))
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}