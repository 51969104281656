import React, {useEffect, useState} from 'react';
import { bindActionCreators } from "redux";
import {connect} from "react-redux";
// import {reduxForm} from 'redux-form';
import { Grid, TextField, Button, 
  Paper, MenuItem, Typography 
} from '@mui/material';
import { tss } from "tss-react";
import theme from '../../../_theme'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

//import {fetchStateNCity} from "../actions/index";
import {EmailRegex, SpecialCharRegex1, addRegex, nameRegExp, numbRegex} from "../../../shared/utility";
import { NO_NUMBERS_AND_SPECIAL_CHARACTERS, ONLY_NUMBERS, NO_SPECIAL_CHARACTERS } from "shared/errorMessages";

const useStyles = tss.create({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    border: "solid 1px #EEEEEE",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(5),
  },
  input: {
    display: 'none',
  },
  menu: {
    fontSize: "15px"
  },
});

function CreateEditViewPSP(props) {
  let postCodeToken = false;
  const {
    productDetails, handleInputChange, PspState, countryDetails, currentForm,
    handlePspCreateUpdate, nextDisableToken, onChangeView
  } = props;

  const [valObj, setValObj] = useState({
    countryErr: false, pspNameErr: false, displayNameErr: false,
    userNameErr: false, userPhoneErr: false, userMobileErr: false, userEmailErr: false,
    add1Err: false, add2Err: false, add3Err: false, stateErr: false, cityErr: false, pinErr: false
  })
  // const [countryCode, setCountryCode] = useState(null);
  // const [openSnackbar, SetOpenSnackbar] = useState(false);
  // const [snackbarMsg, setSnackBarMsg] = useState(null);

  const {classes} = useStyles();
  const countryArr = countryDetails && countryDetails.map(country => country.name ? (country.name).toUpperCase() : null).filter(val => val !== null)

  const handleAddEvent = (e) => {
    if(e.keyCode === 13) {
      e.preventDefault()
    }  
  }

  const handleRemoveEvent = (e) => {
    if(e.keyCode === 13) {
      e.preventDefault()
    }  
  }

  useEffect(() => {
    const country = document.querySelector("#country")
    const elemNum0 = document.querySelector('.number_field0')
    const elemNum1 = document.querySelector('.number_field1')
    const elemNum2 = document.querySelector('.number_field2')

    handleAdEventListner(elemNum0)
    handleAdEventListner(elemNum1)
    handleAdEventListner(elemNum2)
    country.addEventListener('keydown', (e) => handleAddEvent(e))

    document.addEventListener("wheel", function(event){
      if(document.activeElement.type === "number"){
        document.activeElement.blur();
      }
    });

    return () => {
      handleRemoveListener(elemNum0)
      handleRemoveListener(elemNum1)
      handleRemoveListener(elemNum2)
      country.removeEventListener('keydown', (e) => handleRemoveEvent(e))
      
      document.removeEventListener("wheel", function(event){
        if(document.activeElement.type === "number"){
            document.activeElement.blur();
        }
      });
    }
  })

  // useEffect(() => {
  //   if(stateNCityRes && stateNCityRes !== null && stateNCityRes.status && 
  //     stateNCityRes.result && stateNCityRes.result.length === 0
  //   ) {
  //       SetOpenSnackbar(true)
  //       setSnackBarMsg("Invalid Postal Code! Please try again.")
  //     } else if(stateNCityRes && stateNCityRes !== null && stateNCityRes.status && 
  //         stateNCityRes.result && stateNCityRes.result.length > 0
  //       ) {
  //       console.log("stateNCityRes.result >>> ", stateNCityRes.result[stateNCityRes.result.length - 1].province,
  //       stateNCityRes.result[stateNCityRes.result.length - 1].state)
  //       //set state and city from response
  //     }
  // }, [stateNCityRes])

  // const handleSnackBarAction = () => {
  //   SetOpenSnackbar(false)
  //   setSnackBarMsg(null)
  // }

  const handleAdEventListner = (element) => {
    element && element.addEventListener('keydown', function(e) {
      if (e.keyCode === 38 || e.keyCode === 40) {
          e.preventDefault();
      }
    });
  }

  const handleRemoveListener = (element) => {
    element && element.removeEventListener('keydown', function(e) {
      if (e.keyCode === 38 || e.keyCode === 40) {
          e.preventDefault();
      }
    })
  }

  const handleChange = (type, value, typeErr) => {
    if(type === "country"){
      if(countryArr.indexOf((value).toUpperCase()) !== -1){
        handleInputChange(type, [
          countryDetails[countryArr.indexOf((value).toUpperCase())]['isoCountryCodeNum'],
          value
        ])
        // const tempCode = countryDetails[countryArr.indexOf((value).toUpperCase())]['isoCountryCode']
        // tempCode && setCountryCode(tempCode.slice(0,2))
      }
      setValObj({...valObj, countryErr: false })
    } else {
      handleInputChange(type, value)
      setValObj({...valObj, [typeErr]: false })
    }
  }

  const handleOnBlur = (field, value) => {
    if(value === null || value === ""){
      setValObj({...valObj, [field]: true })
    } else {
      setValObj({...valObj, [field]: false })
    }
  }

  const handlePostCodeCheck = (value, type, inpVal) => {
    //console.log("handlePostCodeCheck >>> ", type, inpVal)
    if(value !== postCodeToken) {
      postCodeToken = value
    }
    //if(inpVal && inpVal !== "" && inpVal !== null && type && type === "postalCode"){
      //props.fetchStateNCity({
        //postalCode: inpVal,
       // country: countryCode
      //})
   // }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form className="action_form merchant_form" noValidate autoComplete="off">
          <Grid container rowSpacing={2} columnSpacing={2}>
            
            {/* {currentForm === "edit" &&
              <>
                <Grid item xs={12} sm={8} />
                <Grid item xs={12} sm={4}>
                  <TextField 
                    InputLabelProps={{ shrink: true }} 
                    name="status" variant="outlined" select required
                    fullWidth id="status" label="PSP status"
                    value={ PspState.status && PspState.status }
                    onChange={(e) => handleInputChange('status', [e.target.value])}
                  >
                    {["ACTIVE", "INACTIVE"].map((val, index) => {
                      return <MenuItem key={index} value={val} className={classes.menu}>
                        {val}
                      </MenuItem>
                    })}
                  </TextField>
                </Grid>
              </>
            } */}
            <Grid item xs={12} sm={4}>
              <TextField 
                InputLabelProps={{ shrink: true }} 
                name="country" variant="outlined" select={currentForm === 'create'} required
                fullWidth id="country" label="Country" 
                disabled={currentForm !== 'create'}
                value={currentForm === 'view' ? 
                  productDetails !== null && productDetails.countryName && 
                  productDetails.countryName : PspState.countryName ? 
                    PspState.countryName : null
                } 
                onBlur={(e) => handleOnBlur("countryErr", e.target.value)}
                onChange={(e) => countryArr.length > 0 && handleChange('country', e.target.value)}
              >
                {countryArr && countryArr.length === 0 && 
                  <MenuItem value={"No options available!"} className={classes.menu}> {"No options available!"} </MenuItem>}
                {countryArr && countryArr.map((val, index) => {
                  return <MenuItem key={index} value={val} className={classes.menu}>
                    {val}
                  </MenuItem>
                })}
              </TextField>
              {valObj.countryErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>
              :null}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }} required
                name="name" id="name" fullWidth
                label="PSP Name" variant="outlined"
                disabled={currentForm !== 'create'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails.name && 
                  productDetails.name : PspState.name ? 
                  PspState.name : null
                }
                onChange={(e) => e.target.value.length <= 50 && handleChange('name', [e.target.value], 'pspNameErr')}
                onBlur={(e) => handleOnBlur("pspNameErr", e.target.value)}
              />
              {valObj.pspNameErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p> : PspState && PspState.name && PspState.name !== "" && 
                  !nameRegExp.test(PspState.name) ? 
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                      <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                    </p>
                    : null}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }} required
                name="displayName" id="displayName" fullWidth
                label="Display Name" variant="outlined"
                disabled={currentForm === 'view'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails.displayName && 
                  productDetails.displayName : PspState.displayName ? 
                  PspState.displayName : null
                }
                onChange={(e) => e.target.value.length <= 50 && handleChange('displayName', [e.target.value], 'displayNameErr')}
                onBlur={(e) => handleOnBlur("displayNameErr", e.target.value)}
              />
              {valObj.displayNameErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p> : PspState && PspState.displayName && PspState.displayName !== "" && 
                  !nameRegExp.test(PspState.displayName) ? 
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                      <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                    </p>
                    : null}
            </Grid>
            
            <Grid item xs={12} >
              <Typography variant="h5" >Contact Person Details</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }} required
                name="personName" id="personName" fullWidth
                label="Name" variant="outlined"
                disabled={currentForm === 'view'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails['contactPersonDetail'].name && 
                  productDetails['contactPersonDetail'].name : PspState['contactPersonDetail'].name ? 
                  PspState['contactPersonDetail'].name : null
                }
                onChange={(e) => e.target.value.length <= 50 && handleChange('user', {name: e.target.value}, 'userNameErr')}
                onBlur={(e) => handleOnBlur("userNameErr", e.target.value)}
              />
              {valObj.userNameErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>  : PspState && PspState['contactPersonDetail'] && PspState['contactPersonDetail'].name && 
                  PspState['contactPersonDetail'].name !== "" && !SpecialCharRegex1.test(PspState['contactPersonDetail'].name) ? 
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon />{NO_NUMBERS_AND_SPECIAL_CHARACTERS}</span>
                  </p>
                  : null}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                className={"number_field0"} type={ "text"
                  // currentForm === 'view' ? "text" : "number"
                }
                InputLabelProps={{ shrink: true }} 
                name="phoneNo" id="phoneNo" fullWidth
                label="Phone No." variant="outlined"
                disabled={currentForm === 'view'}
                // onKeyDown={(e) => handlePostCodeCheck((e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || e.keyCode === 8)}
                // onBlur={() => handlePostCodeCheck(false)}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails['contactPersonDetail'].phoneNo && 
                  productDetails['contactPersonDetail'].phoneNo : PspState['contactPersonDetail'].phoneNo && 
                  PspState['contactPersonDetail'].phoneNo 
                }
                onChange={(e) => e.target.value.length <= 20 && 
                  // postCodeToken && 
                  handleInputChange('user',  {phoneNo: e.target.value})}
              />
              {PspState && PspState['contactPersonDetail'].phoneNo && !numbRegex.test(PspState['contactPersonDetail'].phoneNo) ?
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                </p> : null}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                className={"number_field1"} type={"text"
                  // currentForm === 'view' ? "text" : "number"
                }
                InputLabelProps={{ shrink: true }} required
                name="mobileNo" id="mobileNo" fullWidth
                label="Mobile No." variant="outlined"
                disabled={currentForm === 'view'}
                // onKeyDown={(e) => handlePostCodeCheck((e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || e.keyCode === 8)}
                // onBlur={() => handlePostCodeCheck(false)}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails['contactPersonDetail'].mobileNo && 
                  productDetails['contactPersonDetail'].mobileNo : PspState['contactPersonDetail'].mobileNo && 
                  PspState['contactPersonDetail'].mobileNo
                }
                onChange={(e) => e.target.value.length <= 20 && 
                  // postCodeToken && 
                  handleChange('user', {mobileNo: e.target.value}, 'userMobileErr')}
                onBlur={(e) => handleOnBlur("userMobileErr", e.target.value)}
              />
              {valObj.userMobileErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p> : PspState && PspState['contactPersonDetail'].mobileNo && !numbRegex.test(PspState['contactPersonDetail'].mobileNo) ?
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                  </p> : null
              }
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }} required
                name="emailId" id="emailId" fullWidth
                label="Email Id" variant="outlined"
                disabled={currentForm === 'view'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails['contactPersonDetail'].emailId && 
                  productDetails['contactPersonDetail'].emailId : PspState['contactPersonDetail'].emailId ? 
                  PspState['contactPersonDetail'].emailId : null
                }
                onChange={(e) => e.target.value.length <= 50 && handleChange('user', {emailId: e.target.value}, 'userEmailErr')}
                onBlur={(e) => handleOnBlur("userEmailErr", e.target.value)}
              />
              {valObj.userEmailErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>  : PspState && PspState['contactPersonDetail'] && PspState['contactPersonDetail'].emailId && 
                  PspState['contactPersonDetail'].emailId !== "" && !EmailRegex.test(PspState['contactPersonDetail'].emailId) ? 
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                      <span className="error_message"><InfoOutlinedIcon /> {"Invalid Email Address!"}</span>
                    </p>
                    : null}
            </Grid>
            
            <Grid item xs={12} >
              <Typography variant="h5" >Address Details</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }} required
                name="address1" id="address1" fullWidth
                label="Address Line 1" variant="outlined"
                disabled={currentForm === 'view'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails['address'].address1 && 
                  productDetails['address'].address1 : PspState['address'].address1 ? 
                  PspState['address'].address1 : null
                }
                onChange={(e) => e.target.value.length <= 200 && handleChange('address', {address1: e.target.value}, 'add1Err')}
                onBlur={(e) => handleOnBlur("add1Err", e.target.value)}
              />
              {valObj.add1Err ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>  : PspState && PspState['address'] && PspState['address'].address1 && 
                  PspState['address'].address1 !== "" && !addRegex.test(PspState['address'].address1) &&
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                  </p>
              }
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }}
                name="address2" id="address2" fullWidth
                label="Address Line 2" variant="outlined"
                disabled={currentForm === 'view'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails['address'].address2 && 
                  productDetails['address'].address2 : PspState['address'].address2 ? 
                  PspState['address'].address2 : null
                }
                onChange={(e) => e.target.value.length <= 100 && handleInputChange('address', {address2: e.target.value})}
              />
              {PspState && PspState['address'] && PspState['address'].address2 && 
                (PspState['address'].address2 === "" || PspState['address'].address2 === null ||
                (PspState['address'].address2 !== "" && !addRegex.test(PspState['address'].address2))) ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                </p>
              :null}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }}
                name="address3" id="address3" fullWidth
                label="Address Line 3" variant="outlined"
                disabled={currentForm === 'view'}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails['address'].address3 && 
                  productDetails['address'].address3 : PspState['address'].address3 ? 
                  PspState['address'].address3 : null
                }
                onChange={(e) => e.target.value.length <= 100 && handleInputChange('address', {address3: e.target.value})}
              />
              {PspState && PspState['address'] && PspState['address'].address3 && 
                (PspState['address'].address3 === "" || PspState['address'].address3 === null ||
                  (PspState['address'].address3 !== "" && !addRegex.test(PspState['address'].address3))) ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon />{NO_SPECIAL_CHARACTERS}</span>
                </p>
              :null}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }} required
                name="state" id="state" fullWidth
                label="State" variant="outlined"
                disabled={currentForm === 'view' }
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails['address'].state && 
                  productDetails['address'].state : PspState['address'].state ? 
                  PspState['address'].state : null
                }
                onChange={(e) => e.target.value.length <= 20 && handleChange('address', {state: e.target.value}, 'stateErr')}
                onBlur={(e) => handleOnBlur("stateErr", e.target.value)}
              />
              {valObj.stateErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>  : PspState && PspState['address'] && PspState['address'].state && 
                  PspState['address'].state !== "" && !SpecialCharRegex1.test(PspState['address'].state) ? 
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                      <span className="error_message"><InfoOutlinedIcon />{NO_NUMBERS_AND_SPECIAL_CHARACTERS}</span>
                    </p>
                    : null}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                // className={"name_field"}
                InputLabelProps={{ shrink: true }} required
                name="city" id="city" fullWidth
                label="City" variant="outlined"
                disabled={currentForm === 'view' }
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails['address'].city && 
                  productDetails['address'].city : PspState['address'].city ? 
                  PspState['address'].city : null
                }
                onChange={(e) => e.target.value.length <= 20 && handleChange('address', {city: e.target.value}, 'cityErr')}
                onBlur={(e) => handleOnBlur("cityErr", e.target.value)}
              />
              {valObj.cityErr ? 
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                </p>  : PspState && PspState['address'] && PspState['address'].city && 
                  PspState['address'].city !== "" && !SpecialCharRegex1.test(PspState['address'].city) ? 
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                      <span className="error_message"><InfoOutlinedIcon />{NO_NUMBERS_AND_SPECIAL_CHARACTERS}</span>
                    </p>
                    : null}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                className={"number_field2"} type="text"
                InputLabelProps={{ shrink: true }} required
                name="postalCode" id="postalCode" fullWidth
                label="Postal Code" variant="outlined"
                disabled={currentForm === 'view'}
                // onKeyDown={(e) => handlePostCodeCheck((e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || e.keyCode === 8)}
                // onBlur={(e) => handlePostCodeCheck(false, 'postalCode', e.target.value)}
                value={currentForm === 'view' ?
                  productDetails !== null && productDetails['address'].postalCode &&
                  productDetails['address'].postalCode : PspState['address'].postalCode &&
                  PspState['address'].postalCode
                }
                onChange={(e) => e.target.value.length <= 15 &&
                  // postCodeToken && 
                  handleChange('address', { postalCode: e.target.value }, 'pinErr')}
                onBlur={(e) => handleOnBlur("pinErr", e.target.value)}
              />
              {PspState && PspState['address'] && PspState['address'].postalCode && !numbRegex.test(PspState['address'].postalCode) ?
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                  <span className="error_message"><InfoOutlinedIcon />{ONLY_NUMBERS}</span>
                </p> : valObj.pinErr ?
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="password-helper-text">
                    <span className="error_message"><InfoOutlinedIcon /> {"Required Field!"}</span>
                  </p> : null
              }
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button fullWidth variant="contained"
                color="primary" className={"action_login_button"}
                disabled={nextDisableToken}
                onClick={() => currentForm !== "view" ? handlePspCreateUpdate() : onChangeView("list")}
              >
                {currentForm === "edit" ? "Modify" : currentForm === "view" ? "Close" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
        {/* <Snackbar
          anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
          }}
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={() => handleSnackBarAction()}
          action={
          <React.Fragment>
            <IconButton size="medium" aria-label="close" color="inherit" onClick={() => handleSnackBarAction()}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
          }
        >
          <Alert onClose={() => handleSnackBarAction()} severity={"info"}>
            {snackbarMsg}
          </Alert>
        </Snackbar> */}
      </Paper>
    </div>
  )
}

// CreateEditViewPSP = reduxForm({
//   form: 'CreateEditViewPSP',
//   // validate: validate,
//   // enableReinitialize: true
// })(CreateEditViewPSP);

function mapDispatchToProps(dispatch) {
  return {
      dispatch,
      ...bindActionCreators({
       // fetchStateNCity
      }, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    //stateNCityRes: state.PspReducer.stateNCityRes
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditViewPSP);
