import { actionCreator, BASE_URL } from "../../../shared/utility";
import { StoreActions } from "../consts/index";

export function fetchStoreDetails(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(StoreActions.fetchStoreDetails.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/getStoreList`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(StoreActions.fetchStoreDetails.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(StoreActions.fetchStoreDetails.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function fetchStoreMetadata(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(StoreActions.fetchStoreMetadata.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/getStoreSummaryList`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(StoreActions.fetchStoreMetadata.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(StoreActions.fetchStoreMetadata.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetStoreMetadata(payload) {
    return(dispatch) => {
        dispatch(actionCreator(StoreActions.fetchStoreMetadata.FAILURE))
    }
}

export function createStore(payload) {
    return(dispatch, getState) => {
        const token = getState().AuthReducer.authResponse.authtoken
        dispatch(actionCreator(StoreActions.createStoreDetails.REQUEST))
        fetch(`${BASE_URL}/mst/api/data/web/addOrEditStore`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if(!response.st) {
                if(response.responseCode && response.responseCode === "401") {
                    localStorage.setItem("tokenExpired", "true")
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                }  else {
                    dispatch(actionCreator(StoreActions.createStoreDetails.FAILURE, response))
                }
            } else {
                dispatch(actionCreator(StoreActions.createStoreDetails.SUCCESS, response))
            }
        })
        .catch((error) => {
            console.log("error >>> ", error)
        })
    }
}

export function resetStoreCreateResp() {
    return(dispatch) => {
        dispatch(actionCreator(StoreActions.createStoreDetails.FAILURE, null))
    }
}